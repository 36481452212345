import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const MonthSelector = (props) => {
  const { selectedDate, handleDateChange, monthError } = props;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        // inputVariant="outlined"
        style={{ marginRight: '2rem' }}
        label='Select Month'
        views={['year', 'month']}
        openTo='month'
        fullWidth={props.needFullWidth ? props.needFullWidth : false}
        value={selectedDate}
        onChange={(date) => handleDateChange(date, 'selectedDate')}
        InputProps={{
          endAdornment: (
            <ArrowDropDownIcon
              style={{ color: '#0000008a', cursor: 'pointer' }}
            />
          ),
        }}
        error={monthError}
      />
    </MuiPickersUtilsProvider>
  );
};

export default MonthSelector;
