import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import YieldReport from './yield/index';
import POAuditReport from './audit/POAuditIndex';
import POLineAuditReport from './audit/POLineAuditIndex';
import BatchAuditReport from './audit/BatchAuditIndex';
import OTIFReport from './otif/index';

const styles = {
  indicator: {
    backgroundColor: 'white',
  },
  tabContainer: {
    // margin: "-1rem -1.3rem 0rem -1.8rem",
    margin: '-16px -16px 0 -16px',
    backgroundColor: '#1E6EC0',
    color: '#ffffff',
  },
};
class index extends Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <div>
        <Tabs
          value={value}
          onChange={this.handleChange}
          classes={{
            indicator: classes.indicator,
          }}
          className={classes.tabContainer}
        >
          <Tab label='OTIF Report' id={0} />
          <Tab label='Yield Report' id={1} />
          <Tab label='PO Audit  Report' id={2} />
          <Tab label='PO Line Audit  Report' id={3} />
          <Tab label='Batch Audit  Report' id={4} />
        </Tabs>
        {value === 0 && <OTIFReport {...this.props} />}
        {value === 1 && <YieldReport {...this.props} />}
        {value === 2 && <POAuditReport {...this.props} />}
        {value === 3 && <POLineAuditReport {...this.props} />}
        {value === 4 && <BatchAuditReport {...this.props} />}
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(index));
