import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

import usePrevious from '../../../../../../helpers/previousProps';

import ResolveforUR from './ResolveForUR';
import AssignTeam from './editEvent/AssignTeam';
import RiskAssesment from './editEvent/RiskAssesment';
import CapaRequiredSuccess from './CapaRequiredSucessScreen';
import CloseEventSuccess from './CloseEventSuccessResponse';

import {
  eventDetailsByID,
  fetchImpactDetails,
  fetchFrequencyDetails,
  fetchQMSData,
} from '../../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '100%',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
    backgroundColor: '#F5F9FF',
  },
  title: {
    fontWeight: 700,
    padding: '1.5rem 0rem',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '60px',
    cursor: 'pointer',
  },

  steeper: {
    width: '40%',
    margin: '0 auto',
    position: 'relative',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const ResolveQualityEventforUR = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const { openDialog, toggleDialog, eventID } = props;
  const [state, setState] = useState({
    eventData: {},
    eventID: eventID,
    // CMO Information
    CMOMember: '',
    CMOMemberOrgId: '',
    qualityEvent: '',
    // Screen 1
    eventTitle: '',
    eventDescription: '',
    eventOccurredDate: null,
    eventReportedDate: null,
    reportedByOptions: [],
    eventStatusID: '',
    selectedReportedBy: '',
    eventType: '',
    eventTypeID: '',
    priority: '',
    incidentRaisedBy: '',
    incidentRaisedByOrgID: '',
    containmentOrRemedialActions: '',
    // Screen 2 - Event Information
    impactOptions: [],
    selectedImpact: '',
    frequencyOptions: [],
    selectedFrequency: '',
    locationOptions: [],
    selectedLocation: '',
    facilityOptions: [],
    selectedFacility: '',
    productsList: [],
    selectedProduct: '',
    batchOptions: [],
    selectedBatches: [],
    supplierDataList: [],
    supplierNames: '',
    // Assign Events
    teamOptions: [],
    selectedTeamLead: '',
    selectedTeamMembers: [],
    customFiles: [],
    qeCustomFiles: [],
    resolutionDescription: '',
    estimatedResolutionDate: null,
    // Current state
    loading: true,
    updateEventType: '',
    assignTeamScreen: false,
    riskAssesmentScreen: false,
    capaRequiredSuccessScreen: false,
    closeEventSuccessScreen: false,
  });

  const handleChangeData = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggleDialogScreen = (dialogName) => {
    setState((prevState) => ({
      ...prevState,
      [dialogName]: !state[dialogName],
    }));
  };

  const goToDashboard = () => {
    setState((prevState) => ({
      ...prevState,
      capaRequiredSuccessScreen: false,
    }));
    toggleDialog('resolveEventScreenforUR');
  };

  const {
    eventDetails,
    impactDetails,
    frequencyDetails,
    teamMembers,
    updateQualityEvent,
  } = props;
  const prevEventDetails = usePrevious(eventDetails);
  const prevImpactDetails = usePrevious(impactDetails);
  const prevFrequencyDetails = usePrevious(frequencyDetails);
  const prevTeamMembers = usePrevious(teamMembers);
  const prevUpdateQualityEvent = usePrevious(updateQualityEvent);
  useEffect(() => {
    if (didMountRef.current) {
      if (eventDetails !== prevEventDetails) {
        const {
          qualityEventDetails,
          affectedBatchesList,
          supplierDataList,
          investigatingTeamLeadDetails,
          investigatingTeamList,
          eventReportedByDetails,
        } = eventDetails.qualityEventRecordDetails;
        setState((prevState) => ({
          ...prevState,
          eventData: eventDetails,
          CMOMember: qualityEventDetails?.incidentFor,
          CMOMemberOrgId: qualityEventDetails?.incidentForOrgID,
          incidentRaisedBy: qualityEventDetails?.incidentRaisedBy,
          incidentRaisedByOrgID: qualityEventDetails?.incidentRaisedByOrgID,
          eventTitle: qualityEventDetails?.eventTitle || '',
          eventDescription: qualityEventDetails?.eventDescription || '',
          eventOccurredDate: qualityEventDetails?.eventOccurredDate || null,
          eventReportedDate: qualityEventDetails?.eventReportedDate || null,
          eventType: qualityEventDetails?.eventType || '',
          eventTypeID: qualityEventDetails?.eventTypeID || '',
          priority: qualityEventDetails?.priority || '',

          selectedReportedBy: eventReportedByDetails
            ? {
                name: eventReportedByDetails.reportedByName,
                saasUserID: eventReportedByDetails.reportedBySaasUserID,
                emailID: eventReportedByDetails.reportedByEmailID,
                roleName: eventReportedByDetails.reportedByRoleName,
              }
            : '',
          selectedImpact: qualityEventDetails?.impactText
            ? {
                impactId: qualityEventDetails?.impact,
                impactType: qualityEventDetails?.impactText,
              }
            : '',
          selectedFrequency: qualityEventDetails?.frequencyText
            ? {
                frequencyId: qualityEventDetails?.frequency,
                frequencyType: qualityEventDetails?.frequencyText,
              }
            : '',
          selectedLocation: qualityEventDetails?.location
            ? { locationName: qualityEventDetails?.location }
            : '',
          selectedFacility: qualityEventDetails?.facility
            ? { FacilityName: qualityEventDetails?.facility }
            : '',
          selectedProduct: qualityEventDetails?.productCode
            ? {
                productCode: qualityEventDetails?.productCode,
                productDescription: qualityEventDetails?.productName,
              }
            : '',
          selectedBatches: affectedBatchesList ? affectedBatchesList : [],
          supplierDataList: supplierDataList ? supplierDataList : [],
          selectedTeamLead: investigatingTeamLeadDetails
            ? {
                emailAddress: investigatingTeamLeadDetails.teamLeadEmailID,
                userId: investigatingTeamLeadDetails.teamLeadSaasUserID,
                roleName: investigatingTeamLeadDetails.teamLeadRoleName,
                userName: investigatingTeamLeadDetails.teamLeadName,
              }
            : '',
          resolutionDescription:
            qualityEventDetails?.resolutionDescription || '',
          estimatedResolutionDate:
            qualityEventDetails?.estimatedResolutionDate || null,
          qeCustomFiles: eventDetails.qeCustomFiles || [],
          loading: false,
        }));

        if (investigatingTeamList) {
          let selectedTeamMembers = [];
          investigatingTeamList.map((teamMember) => {
            const teamMemberObject = {};
            teamMemberObject.userName = teamMember.name || '';
            teamMemberObject.roleName = teamMember.roleName || '';
            teamMemberObject.emailAddress = teamMember.emailID;
            teamMemberObject.userId = teamMember.saasUserID;

            selectedTeamMembers.push(teamMemberObject);
          });
          setState((prevState) => ({
            ...prevState,
            selectedTeamMembers,
          }));
        }

        props.fetchImpactDetails();
        props.fetchFrequencyDetails();
      }
      if (impactDetails !== prevImpactDetails) {
        setState((prevState) => ({
          ...prevState,
          impactOptions: impactDetails,
        }));
      }
      if (frequencyDetails !== prevFrequencyDetails) {
        setState((prevState) => ({
          ...prevState,
          frequencyOptions: frequencyDetails,
        }));
      }
      if (teamMembers !== prevTeamMembers) {
        setState((prevState) => ({
          ...prevState,
          teamOptions: teamMembers,
        }));
      }
      if (updateQualityEvent !== prevUpdateQualityEvent) {
        if (state.updateEventType === 'capaRequired') {
          setState((prevState) => ({
            ...prevState,
            capaRequiredSuccessScreen: true,
          }));
        }
        if (state.updateEventType === 'closeEvent') {
          setState((prevState) => ({
            ...prevState,
            closeEventSuccessScreen: true,
          }));
        }

        props.fetchQMSData({
          viewByCMO: 'all',
          eventTypeID: 'all',
          priority: 'all',
          eventsOccurredFromDate: null,
          eventsOccurredToDate: null,
          searchBy: '',
          viewByStatus: 'all',
          pageNumber: 1,
          pageSize: 5,
        });
      }
    } else {
      props.eventDetailsByID(eventID);
      didMountRef.current = true;
    }
  }, [
    eventDetails,
    prevEventDetails,
    impactDetails,
    prevImpactDetails,
    frequencyDetails,
    prevFrequencyDetails,
    teamMembers,
    prevTeamMembers,
    updateQualityEvent,
    prevUpdateQualityEvent,
  ]);

  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={() => toggleDialog('resolveEventScreenforUR')}
        TransitionComponent={Transition}
      >
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <Typography variant='h5' className={classes.title}>
              Edit Update Record Details
            </Typography>

            <div
              className={classes.closeBtn}
              onClick={() => toggleDialog('resolveEventScreenforUR')}
            >
              <Typography
                variant='caption'
                style={{
                  lineHeight: 'normal',
                  fontWeight: '700',
                  paddingRight: '10px',
                }}
              >
                Cancel
              </Typography>
              <CloseIcon
                style={{
                  color: 'red',
                  fontSize: '2.5rem',
                  paddingRight: '10px',
                }}
              />
            </div>
          </div>
        </div>
        {state.loading ? (
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress thickness='5' />
          </div>
        ) : (
          <ResolveforUR
            stateData={state}
            handleChangeData={handleChangeData}
            toggleDialogScreen={toggleDialogScreen}
            // qualityEventRecordDetails={state.qualityEventRecordDetails}
            // riskAssesmentScreen={state.riskAssesmentScreen}
            // capaRequiredSuccessScreen={state.capaRequiredSuccessScreen}
            // closeEventScreen={state.closeEventScreen}
            // goToDashboard={goToDashboard}
            // toggleDialogScreen={toggleDialogScreen}
            // customFiles={state.customFiles}
            // handleChangeData={handleChangeData}
            // closeEventSuccessResponseScreen={
            //   state.EventCloseSuccessResponseScreen
            // }
            // assigntTeamScreen={state.assigntTeamScreen}
          />
        )}
      </Dialog>

      {state.assignTeamScreen && (
        <AssignTeam
          openDialog={state.assignTeamScreen}
          toggleDialog={toggleDialogScreen}
          handleChangeData={handleChangeData}
          stateData={state}
        />
      )}

      {state.riskAssesmentScreen && (
        <RiskAssesment
          openDialog={state.riskAssesmentScreen}
          toggleDialog={toggleDialogScreen}
          handleChangeData={handleChangeData}
          stateData={state}
        />
      )}

      {state.capaRequiredSuccessScreen && (
        <CapaRequiredSuccess
          openDialog={state.capaRequiredSuccessScreen}
          goToDashboard={goToDashboard}
        />
      )}

      {state.closeEventSuccessScreen && (
        <CloseEventSuccess
          openDialog={state.closeEventSuccessScreen}
          goToDashboard={goToDashboard}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  eventDetails: state.pcmp.common.QMS.fetchEventDetails,
  reportedByList: state.pcmp.common.QMS.fetchReportedByList,
  impactDetails: state.pcmp.common.QMS.fetchImpactDetails,
  frequencyDetails: state.pcmp.common.QMS.fetchFrequencyDetails,
  localtionDetails: state.pcmp.common.QMS.fetchLocationList,
  facilityDetails: state.pcmp.common.QMS.fetchFacilityList,
  productsList: state.pcmp.common.QMS.fetchProductList,
  batchDetails: state.pcmp.common.QMS.fetchBatchList,
  supplierDetails: state.pcmp.common.QMS.fetchSupplierList,
  teamMembers: state.pcmp.common.QMS.fetchTeamMembers,
  addQualityEvent: state.pcmp.common.QMS.addQualityEvent,
  updateQualityEvent: state.pcmp.common.QMS.updateQualityEvent,
});

export default connect(mapStateToProps, {
  eventDetailsByID,
  fetchImpactDetails,
  fetchFrequencyDetails,
  fetchQMSData,
})(ResolveQualityEventforUR);
