import {
  ALL_CMO_MEMBERS,
  FETCH_QUALITY_EVENTS,
  FETCH_EVENT_TYPES,
  FETCH_QUICK_SUMMARY,
  ADD_QUALITY_EVENT,
  FETCH_IMPACT_DETAILS,
  FETCH_FREQUENCY_DETAILS,
  FETCH_TEAM_MEMBERS,
  FETCH_FACILITY_LIST,
  FETCH_LOCATION_LIST,
  FETCH_PRODUCT_LIST,
  FETCH_BATCH_LIST,
  FETCH_REPORTEDBY_LIST,
  FETCH_SUPPLIER_LIST,
  FETCH_EVENT_DETAILS,
  SAVE_QUALITY_EVENT_PARTIALLY,
  UPDATE_QUALITY_EVENT,
  FETCH_EVENT_TYPES_DATA,
} from '../../actions/types';

const intialState = {
  fetchQMSData: {},

  // fetchQualityEventQuickSummarys: [],
};

export default function (state = intialState, action) {
  switch (action.type) {
    case ALL_CMO_MEMBERS:
      return {
        ...state,
        cmoMembersList: action.payload,
      };
    case FETCH_QUALITY_EVENTS:
      return {
        ...state,
        fetchQMSData: action.payload,
      };
    case FETCH_EVENT_TYPES:
      return {
        ...state,
        fetchEventTypes: action.payload,
      };
    case FETCH_QUICK_SUMMARY:
      return {
        ...state,
        fetchQualityEventQuickSummary: action.payload,
      };
    case ADD_QUALITY_EVENT:
      return {
        ...state,
        addQualityEvent: action.payload,
      };
    case FETCH_IMPACT_DETAILS:
      return {
        ...state,
        fetchImpactDetails: action.payload,
      };
    case FETCH_FREQUENCY_DETAILS:
      return {
        ...state,
        fetchFrequencyDetails: action.payload,
      };
    case FETCH_TEAM_MEMBERS:
      return {
        ...state,
        fetchTeamMembers: action.payload,
      };
    case FETCH_FACILITY_LIST:
      return {
        ...state,
        fetchFacilityList: action.payload,
      };
    case FETCH_LOCATION_LIST:
      return {
        ...state,
        fetchLocationList: action.payload,
      };
    case FETCH_PRODUCT_LIST:
      return {
        ...state,
        fetchProductList: action.payload,
      };
    case FETCH_BATCH_LIST:
      return {
        ...state,
        fetchBatchList: action.payload,
      };
    case FETCH_REPORTEDBY_LIST:
      return {
        ...state,
        fetchReportedByList: action.payload,
      };
    case FETCH_SUPPLIER_LIST:
      return {
        ...state,
        fetchSupplierList: action.payload,
      };
    case FETCH_EVENT_DETAILS:
      return {
        ...state,
        fetchEventDetails: action.payload,
      };
    case SAVE_QUALITY_EVENT_PARTIALLY:
      return {
        ...state,
        partialSaveEvent: action.payload,
      };
    case UPDATE_QUALITY_EVENT:
      return {
        ...state,
        updateQualityEvent: action.payload,
      };
    case FETCH_EVENT_TYPES_DATA:
      return {
        ...state,
        getQualityEventTypes: action.payload,
      };

    default:
      return state;
  }
}
