import React from 'react';
import { connect } from 'react-redux';

import { Box, Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { updateQualityEventData } from '../../../../../../containers/actions/common/qualityEventActions';

const CapaRequired = (props) => {
  const { stateData } = props;

  const handleUpdateEvent = () => {
    props.handleChangeData('updateEventType', 'capaRequired');
    props.handleLoadingScreen();
    const investigatingTeamList = [];
    stateData.selectedTeamMembers.map((teamMember) => {
      const teamMemberObject = {};
      teamMemberObject.name = teamMember.userName || '';
      teamMemberObject.roleName = teamMember.roleName || '';
      teamMemberObject.emailID = teamMember.emailAddress;
      teamMemberObject.saasUserID = teamMember.userId;

      investigatingTeamList.push(teamMemberObject);
    });

    const affectedBatchesList = [];
    stateData.selectedBatches.map((batch) => {
      const batchObject = {};
      batchObject.batchNumber = batch.batchNumber;

      affectedBatchesList.push(batchObject);
    });

    const data = {
      orgName: localStorage.organizationName,
      qualityEventDetails: [
        {
          qualityEventDetails: {
            eventID: stateData.eventID,
            incidentRaisedBy: stateData.incidentRaisedBy,
            incidentRaisedByOrgID: stateData.incidentRaisedByOrgID,
            // CMO Information
            incidentFor: stateData.CMOMember,
            incidentForOrgID: stateData.CMOMemberOrgId,
            eventType: stateData.eventType,
            eventTypeID: stateData.eventTypeID,
            eventTypeAbbreviation: stateData.qualityEvent.eventTypeAbbreviation,
            // Screen 1
            eventTitle: stateData.eventTitle,
            eventDescription: stateData.eventDescription,
            eventOccurredDate: stateData.eventOccurredDate,
            eventReportedDate: stateData.eventReportedDate,

            // Screen 2
            impact: stateData.selectedImpact?.impactId,
            impactText: stateData.selectedImpact?.impactType,
            frequency: stateData.selectedFrequency?.frequencyId,
            frequencyText: stateData.selectedFrequency?.frequencyType,
            location: stateData.selectedLocation?.locationName,
            facility: stateData.selectedFacility?.FacilityName,
            productCode: stateData.selectedProduct?.productCode,
            productName: stateData.selectedProduct?.productDescription,
            // Screen 3
            customFiles: stateData.customFiles,
            resolutionDescription: stateData.resolutionDescription,
            estimatedResolutionDate: stateData.estimatedResolutionDate,
            // General Data
            eventStatusID: 4,
            eventStatusText: 'Under Investigation',
            markForDelete: false,
            priority: stateData.priority,
            containmentOrRemedialActions: '',
          },
          //screen 1
          eventReportedByDetails: {
            reportedByName: stateData.selectedReportedBy?.name || '',
            reportedBySaasUserID: stateData.selectedReportedBy?.saasUserID,
            reportedByEmailID: stateData.selectedReportedBy?.emailID,
            reportedByRoleName: stateData.selectedReportedBy?.roleName || '',
          },
          // Screen 2
          affectedBatchesList: affectedBatchesList,
          supplierDataList: stateData.supplierDataList,
          // Screen 3
          investigatingTeamLeadDetails: {
            teamLeadEmailID: stateData.selectedTeamLead.emailAddress || '',
            teamLeadSaasUserID: stateData.selectedTeamLead.userId || '',
            teamLeadRoleName: stateData.selectedTeamLead.roleName || '',
            teamLeadName: stateData.selectedTeamLead.userName || '',
          },
          investigatingTeamList: investigatingTeamList,
          // New Data
          analysisData: {
            analysisTitle: '',
            analysisStartDate: '',
            analysisEndDate: '',
            rootCause: '',
            analysisLeadDetails: {
              teamLeadName: '',
              teamLeadSaasUserID: '',
              teamLeadEmailID: '',
              teamLeadRoleName: '',
            },
          },
        },
      ],
    };
    props.updateQualityEventData(data);
  };

  return (
    <Box marginTop='3rem'>
      <Button
        style={{
          width: '11rem',
          padding: '0.5rem 0rem',
          borderRadius: '1.5rem',
          textTransform: 'none',
        }}
        variant='contained'
        color='primary'
        disabled={!props.accepted}
        onClick={handleUpdateEvent}
        endIcon={<NavigateNextIcon />}
      >
        CAPA Required
      </Button>
    </Box>
  );
};

export default connect(null, { updateQualityEventData })(CapaRequired);
