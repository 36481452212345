import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';

import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import usePrevious from '../../../../helpers/previousProps';
import {
  cmoMembersList,
  fetchEventTypesData,
} from '../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    width: '40vw',
  },
  closeText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    marginRight: '2rem',
    marginTop: '2rem',
    cursor: 'pointer',
    fontWeight: '600',
  },
  titleText: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 600,
    padding: '0.5rem',
    fontSize: '30px',
  },
  nextBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '2rem',
    borderRadius: '2.5rem',
    textTransform: 'capitalize',
  },
  selectCMO: {
    fontWeight: '600',
    fontSize: '20px',
    marginBottom: '0.5rem',
  },
  selectEvent: {
    fontWeight: '600',
    fontSize: '20px',
    marginBottom: '0.5rem',
  },
  cmoPlaceholder: {
    fontWeight: '500',
  },
  eventTypePlaceholder: {
    fontWeight: '500',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const SelectCMOEventTypes = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const [state, setState] = useState({
    CMOMemberOptions: [],
    qualityEventOptions: [],
  });

  const { collabType, organizationName } = localStorage;

  const { CMOMembers, qualityEventList } = props;
  const prevCMOMembers = usePrevious(CMOMembers);
  const prevqualityEventList = usePrevious(qualityEventList);
  useEffect(() => {
    if (didMountRef.current) {
      if (CMOMembers !== prevCMOMembers) {
        setState((prevState) => ({
          ...prevState,
          CMOMemberOptions: CMOMembers,
        }));
      }
      if (qualityEventList !== prevqualityEventList) {
        setState((prevState) => ({
          ...prevState,
          qualityEventOptions: qualityEventList,
        }));
      }
    } else {
      props.cmoMembersList({
        collabType: 'Pharma Co',
        roleName: 'Admin',
        organizationName: 'Lupin',
        ecosystemId: 1,
      });
      props.fetchEventTypesData();
      didMountRef.current = true;
    }
  }, [CMOMembers, prevCMOMembers, qualityEventList, prevqualityEventList]);

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={props.openDialog}
        onClose={() => props.toggleDialog('cmoEventTypesScreen')}
        TransitionComponent={Transition}
      >
        <Box>
          <Typography
            className={classes.closeText}
            onClick={() => props.toggleDialog('cmoEventTypesScreen')}
          >
            Close <CloseIcon style={{ fontSize: '35px' }} />
          </Typography>
        </Box>
        <Box marginTop='2rem'>
          <Box>
            <Typography variant='h5' className={classes.titleText}>
              Create New Event
            </Typography>
          </Box>
          <Box display='flex' justifyContent='space-evenly' marginTop='2rem'>
            {collabType !== 'CMO' && (
              <Box width='40%'>
                <Typography className={classes.selectCMO}>
                  Select CMO
                </Typography>
                <Autocomplete
                  size='small'
                  variant='outlined'
                  options={state.CMOMemberOptions}
                  getOptionLabel={(option) => option.organizationName || ''}
                  getOptionSelected={(option) => option.organizationName || ''}
                  name='CMOMember'
                  value={props.CMOMember}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id='cmoPlaceholder'
                      variant='outlined'
                      placeholder='All CMOs'
                    />
                  )}
                  onChange={(name, value) =>
                    props.handleChangeData('CMOMember', value)
                  }
                />
              </Box>
            )}
            <Box width='40%'>
              <Typography className={classes.selectEvent}>
                Select Event Type
              </Typography>
              <Autocomplete
                size='small'
                variant='outlined'
                options={state.qualityEventOptions}
                getOptionLabel={(option) => option.eventType || ''}
                getOptionSelected={(option) => option.eventType || ''}
                name='qualityEvent'
                value={props.qualityEvent}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    placeholder='All Events'
                  />
                )}
                onChange={(name, value) => {
                  props.handleChangeData('qualityEvent', value);
                }}
              />
            </Box>
          </Box>
          <Box marginTop='4rem' marginBottom='2rem' textAlign='center'>
            <Button
              className={classes.nextBtn}
              variant='contained'
              color='primary'
              onClick={props.openEventStepperScreen}
              endIcon={<NavigateNextIcon />}
              disabled={
                collabType === 'CMO'
                  ? props.qualityEvent === ''
                  : props.CMOMember === '' || props.qualityEvent === ''
              }
            >
              Next
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  CMOMembers: state.pcmp.common.QMS.cmoMembersList,
  qualityEventList: state.pcmp.common.QMS.getQualityEventTypes,
});

export default connect(mapStateToProps, {
  cmoMembersList,
  fetchEventTypesData,
})(SelectCMOEventTypes);
