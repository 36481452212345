import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../../../../common/models/Button';
import ShowFiles from './ShowFiles';
import Dialog from '@material-ui/core/Dialog';
import DuplicateFilePrompt from './DuplicateFilePrompt';
import { useSelector } from 'react-redux';
import DropFilesAttach from '../models/files/DropFilesAttach';
import InputNumber from '../models/inputs/InputNumber';
import CustomDatePickerMui from '../models/inputs/DateInput';

const useStyles = makeStyles((theme) => ({
  font: {
    fontWeight: 500,
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  padding: {
    paddingBottom: '2rem',
  },
  mandatory: {
    color: 'red',
  },
}));

const InvoiceInfo = (props) => {
  const classes = useStyles();
  const {
    invoiceInformation,
    handleChange,
    handleDateChange,
    isAddRecord,
    //parent
    parentHandlingDeleteFile,
    invoiceTempNumber,
  } = props;

  const [invoiceNumber, setInvoiceNumber] = React.useState(
    invoiceInformation.invoiceNumber
  );
  const [invoiceDate, setInvoiceDate] = React.useState(
    invoiceInformation.invoiceDate
  );
  const [invoiceFiles, setInvoiceFiles] = React.useState(
    invoiceInformation.invoiceFiles
  );

  //file append
  const [tempNumber, setTempNumber] = React.useState(invoiceTempNumber);
  const [localTempNumber, setLocalTempNumber] =
    React.useState(invoiceTempNumber);

  const [dupFilePrompt, setDupFilePrompt] = React.useState(false);

  const invoiceValidations = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.validations
        .invoiceInfo;
    }
    return {};
  });

  const handleChangeInvoiceNumber = (e) => {
    setInvoiceNumber(e.target.value);
  };

  const handleChangeInvoiceDate = (date) => {
    setInvoiceDate(date != null ? date.toISOString() : date);
  };

  const filePrompt = () => {
    setDupFilePrompt(true);
  };

  const handleFileCopy = () => {
    setDupFilePrompt(false);
  };

  const handleClose = () => {
    setDupFilePrompt(false);
  };

  const handleSaveInvoice = () => {
    let data = {
      ...invoiceInformation,
      invoiceNumber,
      invoiceDate,
      invoiceFiles,
    };
    props.handleSaveInvoiceInformationData(data, localTempNumber);
  };

  const localFileHandling = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        let newFileName = file.name;

        let counter = invoiceFiles
          .map((file) => file.grFileName)
          .indexOf(file.name);

        if (counter > -1) {
          filePrompt();
          // counter > -1 means the name is already taken
          // We split with . to deal with the extension
          newFileName = `${file.name.split('.')[0]}_${localTempNumber + 1}.${
            file.name.split('.')[1]
          }`;
          setLocalTempNumber(localTempNumber + 1);
        }
        // Do whatever you want with the file contents
        const fileData = {
          grFileData: reader.result,
          grFileName: newFileName,
          grFileType: 'buffer',
          schrockenGRFileId: '',
          grFileHash: '',
          markForDelete: false,
        };
        setInvoiceFiles((prevFiles) => [...prevFiles, fileData]);
      };
    });
  };

  const fileHandling = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        let newFileName = file.name;

        let counter = invoiceInformation.invoiceFiles
          .map((file) => file.grFileName)
          .indexOf(file.name);

        if (counter > -1) {
          filePrompt();
          // counter > -1 means the name is already taken
          // We split with . to deal with the extension
          newFileName = `${file.name.split('.')[0]}_${tempNumber + 1}.${
            file.name.split('.')[1]
          }`;

          props.handleTempNumber('invoiceTempNumber');
          setTempNumber(tempNumber + 1);
        }
        // Do whatever you want with the file contents
        const newArr = {
          grFileData: reader.result,
          grFileName: newFileName,
          grFileType: 'buffer',
          schrockenGRFileId: '',
          grFileHash: '',
          markForDelete: false,
        };

        props.handlingFiles(newArr, 'InvoiceFiles');
      };
    });
  };

  const handlingDeleteFile = (i) => {
    // for rec fromBE=> mfd as 1, for local filter out
    //check for filetype in file
    //if 'buffer' then follow filter logic
    // if it is not buffer edit that file data sayn mfd 1
    if (
      window.confirm(
        'You are about to delete a file ,would you like to proceed ?'
      )
    ) {
      if (invoiceFiles[i].grFileType === 'buffer') {
        let newdel = invoiceFiles.filter(
          (file) => file.grFileName !== invoiceFiles[i].grFileName
        );
        setInvoiceFiles(newdel);
      } else {
        // invoiceFiles[i].markForDelete=1;
        let markDelFile = invoiceFiles.map((file) =>
          file.grFileHash === invoiceFiles[i].grFileHash
            ? { ...file, markForDelete: true }
            : file
        );
        setInvoiceFiles(markDelFile);
      }
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        justify='center'
        spacing={8}
        className={classes.padding}
        style={{ paddingTop: isAddRecord && '4rem' }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Enter Invoice Number{' '}
            {invoiceValidations.invoiceNumber?.required ? (
              <span className={classes.mandatory}>*</span>
            ) : (
              <span>(optional)</span>
            )}
          </Typography>
          <InputNumber
            name='invoiceNumber'
            type='text'
            value={
              isAddRecord !== true
                ? invoiceInformation.invoiceNumber
                : invoiceNumber
            }
            onChange={
              isAddRecord !== true
                ? (e, name) => handleChange(e, 'invoiceNumber')
                : (e) => handleChangeInvoiceNumber(e)
            }
            className={classes.borders}
            placeholder='2332322'
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Select the Invoice Date{' '}
            {invoiceValidations.invoiceDate?.required ? (
              <span className={classes.mandatory}>*</span>
            ) : (
              <span>(optional)</span>
            )}
          </Typography>

          <CustomDatePickerMui
            name='invoiceDate'
            value={
              isAddRecord !== true
                ? invoiceInformation.invoiceDate
                : invoiceDate
            }
            onChange={
              isAddRecord !== true
                ? (date) => handleDateChange(date, 'invoiceDate')
                : (date) => handleChangeInvoiceDate(date)
            }
            disableFuture={true}
          />
        </Grid>
      </Grid>

      <Grid container justify='center'>
        <Grid item xs={12} sm={12} md={12}>
          <DropFilesAttach
            title='Upload Invoice (optional)'
            handleUploadFiles={
              isAddRecord !== true ? fileHandling : localFileHandling
            }
          />
        </Grid>
      </Grid>

      {!isAddRecord && (
        <Grid container className={classes.padding}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='body2' className={classes.font}>
              Uploaded Document List
            </Typography>

            <ShowFiles
              files={invoiceInformation.invoiceFiles}
              handlingDeleteFile={parentHandlingDeleteFile}
              name='invoiceFiles'
              isUploadFile={true}
            />
          </Grid>
        </Grid>
      )}

      {isAddRecord && (
        <Grid container className={classes.padding}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='body2' className={classes.font}>
              Uploaded Document List
            </Typography>

            <ShowFiles
              files={invoiceFiles}
              handlingDeleteFile={handlingDeleteFile}
              isUploadFile={true}
            />
          </Grid>
        </Grid>
      )}

      {isAddRecord && (
        <Grid container className={classes.padding}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ textAlign: 'center', paddingTop: '1rem' }}
          >
            <StyledButton
              onClick={handleSaveInvoice}
              style={{ padding: '0.25rem 2rem' }}
            >
              Save
            </StyledButton>
          </Grid>
        </Grid>
      )}
      <Dialog open={dupFilePrompt} fullWidth>
        <DuplicateFilePrompt
          handleYesButton={handleFileCopy}
          handleClose={handleClose}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default InvoiceInfo;
