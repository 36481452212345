import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

//files import
import TableWrapper from '../../wrappers/tableWrapper';
import { header } from './header';
import { fetchBatchDetails } from '../../../../containers/actions/common/saslAction';
import SingleRow from './SingleRow';
//
import CustomTableHeaderCell from '../../models/table/CustomTableHeaderCell';

const styles = (theme) => ({
  paper: {
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #C6C6C6',
  },
  table: {
    minWidth: 1200,
    width: '100%',
  },
  hidden: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  inherited: {
    display: 'table-cell',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  labelHeader: {
    flexDirection: 'initial',
    '&:hover': {
      color: 'black',
    },
    '&:active': {
      color: 'black',
    },
  },
});
class ScheduleAgreementBatchTable extends Component {
  state = {
    SLBatchDetails: [],
    // loading: true,
    selected: [],
    checked: false,
    orderBy: '',
    order: 'asc',
    sampleSelect: [],
    totalLength: 0,
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };

  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  selectedArrayValue = (data) => {
    if (Array.isArray(data) === true) {
      data.map((d) => {
        return this.setState((prevState) => ({
          selected: prevState.selected.filter(
            (n, i) => n !== Object.keys(d)[0]
          ),
        }));
      });
    } else {
      if (Object.values(data)[0] === true) {
        if (this.state.selected.indexOf(Object.keys(data)[0]) === -1) {
          this.setState((prevState) => ({
            selected: [...prevState.selected, Object.keys(data)[0]],
          }));
        }
      } else if (Object.values(data)[0] === false) {
        this.setState((prevState) => ({
          selected: this.state.selected.filter(
            (n, i) => n !== Object.keys(data)[0]
          ),
        }));
      }
    }
  };

  render() {
    const {
      classes,
      batchData,
      loading,
      batchDashboard,
      qualityApprovalFlowFlag,
    } = this.props;
    const { orderBy, order } = this.state;
    return (
      <TableWrapper>
        <TableContainer component={Paper} className={classes.paper}>
          <Table
            id='grnreceipts'
            className={classes.table}
            aria-label='collapsible table'
          >
            <TableHead>
              <TableRow>
                {/* <TableCell
                  style={{
                    width: '1%',
                    maxWidth: 0,
                    overflow: 'hidden',
                  }}
                /> */}

                {header(batchDashboard, qualityApprovalFlowFlag).map(
                  (headCell) => (
                    <CustomTableHeaderCell
                      isSortEnable={headCell.isSortEnable}
                      sortingFunction={this.createSortHandler}
                      cellData={headCell}
                      order={order}
                      orderBy={orderBy}
                      key={headCell.id}
                      elementStyle={{
                        verticalAlign: 'top',
                        width: headCell.width ? headCell.width : 'auto',
                      }}
                    />
                  )
                )}
              </TableRow>

              {this.props.tableLoading === true && (
                <TableRow style={{ padding: 0 }}>
                  <TableCell colSpan={16} style={{ padding: 0 }}>
                    <LinearProgress color='secondary' />
                  </TableCell>
                </TableRow>
              )}
            </TableHead>

            <TableBody>
              <React.Fragment>
                {loading === true && (
                  <TableRow style={{ height: 150 }}>
                    <TableCell colSpan={12}>
                      <div style={{ textAlign: 'center' }}>
                        <CircularProgress color='primary' thickness={7} />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {loading === false ? (
                  batchData.length > 0 ? (
                    this.stableSort(
                      batchData,
                      this.getComparator(order, orderBy)
                    ).map((row, i) => (
                      <Row
                        key={i}
                        row={row}
                        keys={i}
                        checked={this.state.checked}
                        selected={this.state.selected}
                        sampleSelect={this.state.sampleSelect}
                        selectedArrayValue={this.selectedArrayValue}
                        schrockenDeliveryScheduleID={
                          this.props.schrockenDeliveryScheduleID ||
                          row.schrockenDeliveryScheduleID
                        }
                        batchDashboard={batchDashboard}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={header(batchDashboard).length}
                        align='center'
                      >
                        No Records Found
                      </TableCell>
                    </TableRow>
                  )
                ) : null}
              </React.Fragment>
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
    );
  }
}

function Row(props) {
  // const { PODetails } = props.row;
  const selectedArrayValue = (data) => {
    props.selectedArrayValue(data);
  };
  return (
    <SingleRow
      row={props.row}
      keys={props.keys}
      checked={props.checked}
      selected={props.selected}
      selectedArrayValue={selectedArrayValue}
      {...props}
    />
  );
}

ScheduleAgreementBatchTable.propTypes = {
  // AddGRNFetchTable: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  // batchData: state.pcmp.common.sasl.batchDetails,
  qualityApprovalFlowFlag:
    state.pcmp.common.reports.rmpmGrnFeatures?.data.features
      .qualityApprovalEnabled,
  tableLoading: state.pcmp.common.files.tableLoading,
});
export default withStyles(styles)(
  connect(mapStateToProps, { fetchBatchDetails })(
    withRouter(ScheduleAgreementBatchTable)
  )
);
