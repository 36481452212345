/* eslint-disable no-console */
/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Harish D
 * -----
 * File Description: Table that shows all purchase orders
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import {
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  TextField,
  Tooltip,
  Grid,
  TableFooter
} from "@material-ui/core";

import TablePagination from "@material-ui/core/TablePagination";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import SearchIcon from "@material-ui/icons/Search";

import orderBy from "lodash/orderBy";

import pgactions from "../../../../common/functionalComponents/PageActions";

import { tableFilter } from "../../../../common/functionalComponents/TableFilter";

import { style } from "../../../../../resources/css/styles";

const tableDataFilter = tableFilter;
const TablePaginationActionsWrapped = pgactions;

const styles = style;

const invertDirection = {
  asc: "desc",
  desc: "asc"
};

const header = [
  { name: "Line Number", id: "lineNumber" },
  { name: "Product Code", id: "productCode" },
  { name: "Product Name", id: "productName" },
  { name: "Plant Code", id: "CMOPlantCode" },
  { name: "Units Of Measurement", id: "unitOfMeasurement" },
  { name: "Quantity", id: "quantity" },
  { name: "Delivery Date", id: "deliveryDate" },
  { name: "Current Status", id: "currentStatus" }
];

class LineItemsTable extends Component {
  state = {
    data: this.props.poLineItems.poPreview.data.tableData
      ? this.props.poLineItems.poPreview.data.tableData
      : [],
    header,
    displayFilter: false,
    query: "",
    columnToQuery: "all",
    columnToSort: "",
    sortDirection: "desc",
    fieldSelected: false,
    page: 0,
    rowsPerPage: 3
  };

  // show search fields
  showFilter = () => {
    this.setState({
      displayFilter: !this.state.displayFilter,
      columnToQuery: "all",
      query: ""
    });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      fieldSelected: false
    });

    if (this.state.columnToQuery === "none") {
      if (event.target.name === "query") {
        this.setState({ fieldSelected: true });
      }
    }
  };

  // handle sort
  handleSort = columName => {
    this.setState({
      columnToSort: columName,
      sortDirection:
        this.state.columnToSort === columName
          ? invertDirection[this.state.sortDirection]
          : "asc"
    });
  };

  // refresh
  onRefresh = () => {
    const getdata = {
      ...this.props.match.params,
      collabType: this.props.collabType
    };
    this.props.getLineItemsInPO(getdata);
  };

  // pagination
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    const value = parseInt(event.target.value);
    this.setState({ rowsPerPage: value });
  };

  render() {
    const { classes } = this.props;
    const { header } = this.state;
    const rowsData = this.state.data;

    const { rowsPerPage, page } = this.state;

    const noRecords = (
      <TableRow>
        <TableCell colSpan={header.length} align="center">
          No Records Found
          </TableCell>
      </TableRow>
    );

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rowsData.length - page * rowsPerPage);

    const queryData = orderBy(
      this.state.query
        ? rowsData.filter(data =>
          tableDataFilter(
            data,
            this.state.columnToQuery,
            this.state.query.toLowerCase(),
            this.state.header
          )
        )
        : rowsData,
      this.state.columnToSort,
      this.state.sortDirection
    );
    return (
      <Grid item xs={12} md={12} className={classes.membersTable}>
        <Paper className={classes.root3} style={{ width: "auto" }}>
          <Toolbar>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <div>
                <Typography variant="subtitle1">
                  Order Information (Order Line Items)
                </Typography>
              </div>
              {this.state.displayFilter && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Select
                    native
                    style={{ marginLeft: "1em", marginRight: "1.5rem" }}
                    value={this.state.columnToQuery}
                    name="columnToQuery"
                    onChange={this.handleChange}
                    error={this.state.fieldSelected}
                    className={classes.fontColor}>
                    <option value="all" className={classes.fontColor}>
                      All
                    </option>
                    {header.map((ele, x) => (
                      <option
                        key={`opt-${x}`}
                        value={ele.id}
                        className={classes.fontColor}
                      >
                        {ele.name}
                      </option>
                    ))}
                  </Select>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start"
                    }}
                  >
                    <SearchIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />

                    <TextField
                      name="query"
                      placeholder="Search... "
                      value={this.state.query}
                      onChange={this.handleChange}
                      className={classes.fontColor}
                      inputProps={{ style: { fontSize: "0.75rem" } }}
                    />
                  </div>
                </div>
              )}
            </div>
          </Toolbar>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {header.map((data, i) => (
                  <TableCell key={i} align="left">
                    <Tooltip title="sort">
                      <div
                        onClick={() => this.handleSort(data.id)}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          cursor: "pointer"
                        }}
                      >
                        <span>{data.name}</span>
                        {this.state.columnToSort === data.id ? (
                          this.state.sortDirection === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                              <ArrowDropDownIcon />
                            )
                        ) : null}
                      </div>
                    </Tooltip>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {searchResults} */}
              {queryData.length > 0 ?
                queryData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((ele, i) => (
                    <React.Fragment key={`fragment-${i}`}>
                      <TableRow key={`row-${i}`}>
                        <TableCell align="left">{ele.lineNumber}</TableCell>
                        <TableCell align="left">{ele.productCode}</TableCell>
                        <TableCell align="left">{ele.productName}</TableCell>
                        <TableCell align="left">{ele.plantCode}</TableCell>
                        <TableCell align="left">
                          {ele.unitOfMeasurement}
                        </TableCell>
                        <TableCell align="right">{ele.POQuantity}</TableCell>
                        <TableCell align="left">{ele.deliveryDate}</TableCell>
                        <TableCell align="left">Open</TableCell>
                      </TableRow>
                    </React.Fragment>
                  )) : noRecords}

              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[3, 5, 10, 15]}
                  colSpan={header.length + 1}
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Grid>
    );
  }
}

LineItemsTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  poLineItems: state.pcmp.pharmaCo.purchaseOrder
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    null
  )(withRouter(LineItemsTable))
);
