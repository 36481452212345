import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cmoMembersList } from '../../../containers/actions/common/qualityEventActions';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStyles } from './qualityEventStyles';

const CMOFilter = (props) => {
  const { handleSelectCMO, CMOMember, toShowAllEvents } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  let cmoMembers = useSelector((state) =>
    state.pcmp.common.QMS.cmoMembersList
      ? state.pcmp.common.QMS.cmoMembersList
      : []
  );

  useEffect(() => {
    dispatch(
      cmoMembersList({
        collabType: 'Pharma Co',
        roleName: 'Admin',
        organizationName: 'Lupin',
        ecosystemId: 1,
      })
    );
  }, [dispatch]);

  return (
    <FormControl className={classes.formControl} fullWidth>
      {/* <InputLabel id='demo-simple-select-label'>CMO</InputLabel> */}
      <Select
        style={{ padding: '0.2rem' }}
        labelId='cmo-filter'
        id='cmo-filter-id'
        value={CMOMember}
        onChange={(data) => {
          handleSelectCMO(data);
        }}
        variant='outlined'
        classes={{
          outlined: classes.outlined,
        }}
        //style={{ padding: "0.8rem" }}
      >
        {/* <MenuItem value='all'>All CMOs</MenuItem> */}
        {toShowAllEvents ? (
          <MenuItem value='all' disabled={true}>
            All CMOs
          </MenuItem>
        ) : (
          <MenuItem value='all'>All CMOs</MenuItem>
        )}

        {cmoMembers.map((cmoMember, i) => (
          <MenuItem
            key={i}
            value={cmoMember}
            disabled={cmoMember === null ? true : false}
          >
            {cmoMember.organizationName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default CMOFilter;
