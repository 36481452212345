import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";

import PurchaseOrdersTable from "../../common/tables/PurchaseOrdersTable";

import { viewAllPurchaseOrders, viewPurchaseOrderSource } from "../../../containers/actions/common/dashboardAction";

class index extends Component {
  state = {
    purchaseOrders: false
  };

  componentDidMount() {
    this.getAllPurchaseOrders();
  }
  componentWillUnmount() {
    if (viewPurchaseOrderSource) {
      viewPurchaseOrderSource.cancel('leaving page')
    }
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.allPurchaseOrders !== this.props.allPurchaseOrders) {
      this.setState({ purchaseOrders: nextProps.allPurchaseOrders });
    }
  }

  getAllPurchaseOrders = () => {
    let data = {
      ...this.props.match.params,
      collabType: "CMO"
    };
    this.props.viewAllPurchaseOrders(data);
    this.setState({ purchaseOrders: false })
  };

  render() {
    const { purchaseOrders } = this.state;
    return (
      <Grid>
        <div
          style={{
            margin: "-1rem -1.3rem 0rem -1.8rem",
            backgroundColor: "#F5F5F5",
            flexDirection: "row",
            padding: "1.2rem 0.5rem"
          }}
        >
          <Typography variant="h6" style={{ marginLeft: "3rem" }}>
            Total Purchase Orders -{" "}
            {!purchaseOrders ? 0 : purchaseOrders.length}
          </Typography>
        </div>
        <PurchaseOrdersTable
          purchaseOrders={purchaseOrders}
          collabType="CMO"
          callBackAPIFunction={this.getAllPurchaseOrders}
        />
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  allPurchaseOrders: state.pcmp.common.dashboard.allPurchaseOrders
});

export default connect(mapStateToProps, {
  viewAllPurchaseOrders
})(withRouter(index));
