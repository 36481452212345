import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  supplied: {
    textDecoration: 'underline',
    color: 'blue',
    paddingTop: '0.2rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  actions: {
    display: 'flex',
    alignItems: 'baseline',
  },
  nameLogo: {
    width: '1.5rem',
    height: 'fit-content',
    borderRadius: '100%',
    // width: '1.1rem',
    // padding: '0.3rem',
    fontSize: '0.875rem',
    textAlign: 'center',
  },
}));

const CheckedAndApproved = (props) => {
  const classes = useStyles();
  const logo = (name) => {
    if (name !== undefined && name !== null) {
      return name
        .split(' ')
        .map((val) => {
          if (val[0] !== undefined) {
            return val[0].toUpperCase();
          }
          return '';
        })
        .join('');
    }
    return '';
  };
  return (
    <span className={classes.actions}>
      <Typography
        variant='body1'
        className={classes.nameLogo}
        style={{
          backgroundColor:
            logo(props.name).length === 0 ? 'inherit' : '#EFEFEF',
          border: logo(props.name).length === 0 ? '0px' : '1px solid #707070',
          width: logo(props.name).length < 2 ? '1.89rem' : '2.5rem',
        }}
      >
        {logo(props.name).length === 0
          ? ''
          : logo(props.name).length <= 2
          ? logo(props.name)
          : logo(props.name).slice(0, 2)}
      </Typography>
      &nbsp;
      <Typography variant='body1' className={classes.supplied}>
        {props.name}
      </Typography>
    </span>
  );
};

export default CheckedAndApproved;
