import React from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useStyles from '../../models/buttons/buttonStyles';
import UpdateScreen from '../batch/updateBatch';
import ResolveDialog from '../../models/ResolveDialog';
import CheckedAndApproved from '../../models/checkedAndApproved';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

// '../../models/ResolveDialog';

const Update = (props) => {
    const classes = useStyles();
    const {
        schrockenDeliveryScheduleBatchID,
        schrockenDeliveryScheduleID,
        SASLBatchStatusID,
        statusDisplayName,
        rejectedBy,
        rejectionText,
        batchDashboard
    } = props;
    const [value, setValue] = React.useState(
        rejectionText === null ||
            typeof rejectionText !== 'string'
            ? ''
            : rejectionText
    );
    const [openDialog, setOpenDialog] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(!open);
        setOpenDialog(false);
    };

    const handleClickRecieved = () => {
        setOpenDialog(true);
    };

    const handleNoButton = () => {
        setOpenDialog(false);
        setOpen(false);
    };
    const handleText = (e) => {
        setValue(e.target.value);
    };

    return (
        <React.Fragment>
            <Button
                disableElevation
                disableFocusRipple
                disableRipple
                className={classes.button}
            // onClick={handleClickRecieved}
            >
                <div className={classes.logo} onClick={handleClickRecieved}>
                    <AutorenewIcon fontSize='small' className={classes.logoHeight} />
                    &nbsp;
                    <Typography variant='body1'>Resolve</Typography>
                </div>
            </Button>

            <UpdateScreen
                open={open}
                handleClose={handleClose}
                schrockenDeliveryScheduleBatchID={schrockenDeliveryScheduleBatchID}
                schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
                SASLBatchStatusID={SASLBatchStatusID}
                statusDisplayName={statusDisplayName}
                batchDashboard={batchDashboard}
            />

            <Dialog open={openDialog} fullWidth>
                <ResolveDialog
                    icons={false}
                    handleClose={handleClose}
                    title='Resolve Issue'
                    subtitle='Issue Comment'
                    hasMultipleButton={true}
                    handleNoButton={handleNoButton}
                >
                    <form className={classes.root} noValidate autoComplete='off'>
                        <TextField
                            id='outlined-multiline-static'
                            label='Issue Comment'
                            multiline
                            rows={6}
                            value={value}
                            onChange={handleText}
                            // width='100%'
                            variant='outlined'
                            style={{
                                width: '100%'
                            }}
                            disabled={true}
                        />
                        <Grid style={{ display: 'flex', paddingTop: '1rem' }}>
                            <Typography
                                variant='body2'
                                style={{ padding: '0.5rem', fontWeight: 500 }}
                            >
                                Rejected By
                            </Typography>
                            <CheckedAndApproved
                                name={rejectedBy ? rejectedBy : ''}
                            />
                        </Grid>
                    </form>
                </ResolveDialog>
            </Dialog>
        </React.Fragment>
    );
};

export default Update;
