import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

import { updateQualityEventData } from '../../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
  },
  yesBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'capitalize',
  },
  noBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'capitalize',
    backgroundColor: 'white',
    color: 'black',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const DeleteRecord = (props) => {
  const classes = useStyles();
  const { openDialog, toggleDialog, stateData } = props;
  const [state, setState] = useState({
    loading: false,
  });

  const deleteEvent = () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    props.handleChangeData('updateEventType', 'deleteRecord');
    const investigatingTeamList = [];
    stateData.selectedTeamMembers.map((teamMember) => {
      const teamMemberObject = {};
      teamMemberObject.name = teamMember.userName || '';
      teamMemberObject.roleName = teamMember.roleName || '';
      teamMemberObject.emailID = teamMember.emailAddress;
      teamMemberObject.saasUserID = teamMember.userId;

      investigatingTeamList.push(teamMemberObject);
    });

    const affectedBatchesList = [];
    stateData.selectedBatches.map((batch) => {
      const batchObject = {};
      batchObject.batchNumber = batch.batchNumber;

      affectedBatchesList.push(batchObject);
    });

    const data = {
      orgName: localStorage.organizationName,
      qualityEventDetails: [
        {
          qualityEventDetails: {
            eventID: stateData.eventID,
            incidentRaisedBy: stateData.incidentRaisedBy,
            incidentRaisedByOrgID: stateData.incidentRaisedByOrgID,
            // CMO Information
            incidentFor: stateData.CMOMember,
            incidentForOrgID: stateData.CMOMemberOrgId,
            eventType: stateData.eventType,
            eventTypeID: stateData.eventTypeID,
            eventTypeAbbreviation: stateData.qualityEvent.eventTypeAbbreviation,
            // Screen 1
            eventTitle: stateData.eventTitle,
            eventDescription: stateData.eventDescription,
            eventOccurredDate: stateData.eventOccurredDate,
            eventReportedDate: stateData.eventReportedDate,
            eventReportedBy: stateData.reportedBy?.emailID,
            // Screen 2
            impact: stateData.selectedImpact?.impactId,
            impactText: stateData.selectedImpact?.impactType,
            frequency: stateData.selectedFrequency?.frequencyId,
            frequencyText: stateData.selectedFrequency?.frequencyType,
            location: stateData.selectedLocation?.locationName,
            facility: stateData.selectedFacility?.FacilityName,
            productCode: stateData.selectedProduct?.productCode,
            productName: stateData.selectedProduct?.productType,
            // Screen 3
            customFiles: stateData.customFiles,
            resolutionDescription: stateData.resolutionDescription,
            estimatedResolutionDate: stateData.estimatedResolutionDate,
            // General Data
            eventStatusID: 2,
            eventStatusText: 'New',
            markForDelete: true,
            priority: stateData.priority,
            containmentOrRemedialActions: '',
          },
          // Screen 2
          affectedBatchesList: affectedBatchesList,
          supplierDataList: stateData.supplierDataList,
          // Screen 3
          investigatingTeamLeadDetails: {
            teamLeadEmailID: stateData.selectedTeamLead.emailAddress || '',
            teamLeadSaasUserID: stateData.selectedTeamLead.userId || '',
            teamLeadRoleName: stateData.selectedTeamLead.roleName || '',
            teamLeadName: stateData.selectedTeamLead.userName || '',
          },
          investigatingTeamList: investigatingTeamList,
          // New Data
          analysisData: {
            analysisTitle: '',
            analysisStartDate: '',
            analysisEndDate: '',
            rootCause: '',
            analysisLeadDetails: {
              teamLeadName: '',
              teamLeadSaasUserID: '',
              teamLeadEmailID: '',
              teamLeadRoleName: '',
            },
          },
        },
      ],
    };
    props.updateQualityEventData(data);
  };

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='sm'
      onClose={() => toggleDialog('deleteRecordScreen')}
    >
      {state.loading && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            width: '100%',
            backdropFilter: 'blur(0.8px)',
            zIndex: 9,
            top: 0,
          }}
        >
          <CircularProgress thickness='5' />
        </div>
      )}
      <div
        className={classes.closeBtn}
        onClick={() => toggleDialog('deleteRecordScreen')}
      >
        <Typography
          variant='caption'
          style={{ lineHeight: 'normal', fontWeight: '700' }}
        >
          Cancel
        </Typography>
        <CloseIcon style={{ color: 'red', fontSize: '2rem' }} />
      </div>

      <Box textAlign='center'>
        <Box marginTop='6rem' marginBottom='4rem'>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>
            Do you want to Delete the Data?
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-evenly' marginBottom='2rem'>
          <Box
            margin='auto'
            marginLeft='8rem'
            marginTop={2}
            marginBottom='2rem'
          >
            <Button
              className={classes.yesBtn}
              variant='contained'
              color='primary'
              onClick={deleteEvent}
            >
              yes
            </Button>
          </Box>
          <Box
            margin='auto'
            marginLeft='5rem'
            marginTop={2}
            marginBottom='2rem'
            marginRight='8rem'
          >
            <Button
              className={classes.noBtn}
              variant='contained'
              color='primary'
              onClick={() => toggleDialog('deleteRecordScreen')}
            >
              No
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default connect(null, { updateQualityEventData })(DeleteRecord);
