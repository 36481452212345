import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const ViewCmoNotes = props => {
  const { CMONotes, toggleNotes } = props;
  return (
    <React.Fragment>
      <DialogTitle
        style={{ textAlign: "center" }}
        id="form-dialog-title"
        variant="h6"
      >
        Important Notes
      </DialogTitle>
      <DialogContent>
        <TextField
          disabled
          multiline
          name="CMONotes"
          value={CMONotes !== "" ? CMONotes : "Notes is not added for this PO!"}
          fullWidth
          rows="7"
          variant="outlined"
          style={{
            minWidth: "50rem"
          }}
        />
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={toggleNotes} color="primary" variant="contained">
          Close Notes
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default ViewCmoNotes;
