import React, { Component } from 'react';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid, Button, Typography, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
//import FilesList from "./FilesList.json";
import Menu from '@material-ui/core/Menu';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  pharmaUpdateBatchItem,
  // pharmaCoFetchLineBatchItems
} from '../../../../containers/actions/pharmaCo/batchItemActions';

import {
  pharmaDownloadQCFile,
  pharmaUploadGRNFile,
} from '../../../../containers/actions/pharmaCo/filesActions';

import { fetchBatchItemStatusList } from '../../../../containers/actions/cmo/addBatchItemActions';
import {
  fetchCustomUploadedFiles,
  downloadCustomUploadedFile,
} from '../../../../containers/actions/common/fileActions';

// Backdrop while updating
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

class ViewEditBatchItem extends Component {
  state = {
    disableSaveButton: false,
    batchDetails: this.props.batchItemDetails,
    batchStatusList: [],
    docType: '',
    fileNamesField: '',

    prevCustomFiles: [],
    errors: {
      maximumRetailPrice: '',
      status: false,
      fileNamesField: '',
    },
    fileSelected: '',
    anchorEl: null,
    selectedFiles: [],
    batchList: [
      {
        fileid: 1,
        fileType: 'reconciliationSheet',
        fileName: 'Reconciliation Sheet',
        label: 'Reconciliation Sheet',
        onStatusCode: 7,
        isChecked: false,
      },
      {
        fileid: 2,
        fileType: 'packInspectionRecord',
        fileName: 'Pack Inspection Sheet',
        label: 'Pack Inspection Sheet',
        onStatusCode: 7,
        isChecked: false,
      },
      {
        fileid: 3,
        fileType: 'shipperWeighingRecord',
        fileName: 'Shipper Weighing Record',
        label: 'Shipper Weighing Record',
        onStatusCode: 7,
        isChecked: false,
      },

      {
        fileid: 4,
        fileType: 'COA',
        fileName: 'COA',
        label: 'COA',
        onStatusCode: 9,
        isChecked: false,
      },
      {
        fileid: 5,
        fileType: 'invoice',
        fileName: 'Invoice/Delivery Challan',
        label: 'Invoice/Delivery Challan',
        onStatusCode: 11,
        isChecked: false,
      },
      {
        fileid: 6,
        fileType: 'LR',
        fileName: 'LR File',
        label: 'LR File',
        onStatusCode: 11,
        isChecked: false,
      },
      {
        fileid: 7,
        fileType: 'EWayBill',
        fileName: 'E Way Bill',
        label: 'E Way Bill',
        onStatusCode: 11,
        isChecked: false,
      },
    ],
  };

  componentDidMount() {
    let data = {
      ...this.props.params,
      collabType: 'Pharma Co',
      schrockenBatchID: this.props.batchItemDetails.schrockenBatchID,
      statusCode: this.props.batchItemDetails.statusCode,
      purchaseOrderNumber: this.props.batchItemDetails.purchaseOrderNumber,
      lineNumber: this.props.batchItemDetails.lineNumber,
      schrockenPOLineID: this.props.batchItemDetails.schrockenPOLineID,
    };
    this.props.fetchBatchItemStatusList(data);
    data.statusCode = -1;
    this.props.fetchCustomUploadedFiles(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.batchUpdated !== this.props.batchUpdated) {
      this.props.toggleViewBatchScreen();
      this.props.callBackAPIFunction();
    }
    if (nextProps.batchStatusList) {
      this.setState({ batchStatusList: nextProps.batchStatusList });
    }

    if (
      nextProps.files.customFiles &&
      nextProps.files.customFiles !== this.props.files.customFiles
    ) {
      nextProps.files.customFiles.forEach((file) => (file.isDisabled = false));
      this.setState({ prevCustomFiles: nextProps.files.customFiles });
    }
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      batchDetails: {
        ...prevState.batchDetails,
        [name]: value,
      },
      errors: {
        maximumRetailPrice: '',
        status: false,
        fileNamesField: '',
      },
    }));
  };

  handleFileListChange = (e) => {
    this.setState({ fileSelected: e.target.value });
  };

  validate = () => {
    let isError = false;

    let { batchDetails } = this.state;

    let errors = {
      maximumRetailPrice: '',
      status: false,
      fileNamesField: '',
    };

    if (batchDetails.maximumRetailPrice <= 0 && batchDetails.statusCode > 6) {
      isError = true;
      errors.maximumRetailPrice = 'Please Enter MRP';
    } else if (batchDetails.statusCode === '') {
      isError = true;
      errors.status = true;
    }
    this.setState({ errors });
    return isError;
  };

  validateDownloadFiles = () => {
    let isError = false;

    let { batchDetails } = this.state;

    let errors = {
      status: false,
      fileNamesField: '',
    };
    if (this.state.fileNamesField === '' && batchDetails.statusCode > 6) {
      isError = true;
      errors.fileNamesField = 'Please select file(s)';
    }
    this.setState({ errors });
    return isError;
  };

  onSubmit = () => {
    let error = this.validate();
    if (!error) {
      this.setState({ disableSaveButton: true });
      let data = {
        ...this.props.params,
        collabType: 'Pharma Co',
        ...this.state.batchDetails,
      };
      const files = new FormData();
      files.append('ecosystemId', this.props.params.ecosystemId);
      files.append('collabType', 'Pharma Co');
      files.append('roleName', this.props.params.roleName);
      files.append('organizationName', this.props.params.organizationName);
      files.append('batchData', JSON.stringify(data));
      this.props.pharmaUpdateBatchItem(files, this.props.params.roleName);
    }
  };

  // downloadFiles = (doc) => {
  //   let getFile = {
  //     ...this.props.params,
  //     collabType: "Pharma Co",
  //     schrockenBatchID: this.props.batchItemDetails.schrockenBatchID,
  //     docType: doc,
  //   };
  //   this.props.pharmaDownloadQCFile(getFile);
  // };

  downloadFiles = (selectedFilesList) => {
    let error = this.validateDownloadFiles();
    if (!error) {
      for (let i = 0; i < selectedFilesList.length; i++) {
        let getFile = {
          ...this.props.params,
          collabType: 'Pharma Co',

          schrockenBatchID: this.props.batchItemDetails.schrockenBatchID,
          docType: selectedFilesList[i].fileType,
        };
        this.props.pharmaDownloadQCFile(getFile);
      }
    }
  };

  downloadCustomFile = (file, i) => {
    if (!this.state.prevCustomFiles[i].isDisabled) {
      const data = {
        ...this.props.params,
        collabType: 'Pharma Co',
        schrockenCustomFileID: file.schrockenCustomFileID,
        customFileDescription: file.customFileDescription,
      };
      this.props.downloadCustomUploadedFile(data);
      let prevCustomFiles = this.state.prevCustomFiles;
      // prevCustomFiles[i].isDisabled = true;
      this.setState({ prevCustomFiles });
    }
  };
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  selectFiles = (val) => {
    let batchList = this.state.batchList;

    /* switches the isOpened in json object */
    function setUsername(ID) {
      for (var i = 0; i < batchList.length; i++) {
        if (batchList[i].fileid === ID) {
          batchList[i].isChecked = !batchList[i].isChecked;
          return;
        }
      }
    }
    setUsername(val.fileid);

    /*  filters isopened true values */
    let filtered = batchList.filter((el) => {
      return el.isChecked === true;
    });
    this.setState({ selectedFiles: filtered });

    /* put filenames in on string */
    let str = '';
    filtered.map(
      (val, i) => (str = str + (str !== '' ? ', ' : '') + val.fileName)
    );
    this.setState({ fileNamesField: str });
  };

  render() {
    const { batchDetails } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';
    return (
      <React.Fragment>
        <Backdrop
          style={{
            zIndex: 2345,
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
          }}
          open={this.state.disableSaveButton}
        >
          <CircularProgress color='primary' />
          <Typography variant='caption'>Please wait...</Typography>
        </Backdrop>

        <Typography
          variant='h6'
          style={{ textAlign: 'center', marginTop: '4rem' }}
        >
          View/Update Batch Item Details
        </Typography>
        <Card
          style={{
            margin: '2rem 4rem 3rem 4rem',
          }}
        >
          <CardContent
            style={{
              margin: '1.25rem',
            }}
          >
            <Grid container spacing={3}>
              <Grid item sm={12} md={3}>
                <TextField
                  label='Batch Number'
                  name='batchNumber'
                  disabled
                  defaultValue={batchDetails.batchNumber}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item sm={3}>
                <TextField
                  label='Batch Size'
                  name='batchSize'
                  disabled
                  defaultValue={batchDetails.batchSize}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item sm={3}>
                <TextField
                  label='Produced Quantity'
                  name='producedQuantity'
                  disabled
                  value={batchDetails.producedQuantity}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item sm={3}>
                <TextField
                  label='Pending Quantity'
                  name='pendingQuantity'
                  value={batchDetails.pendingQuantity}
                  disabled
                  // onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='Start Date'
                  name='batchStartDate'
                  disabled
                  value={
                    batchDetails.batchStartDate !== null
                      ? Moment(batchDetails.batchStartDate).format(dateFormat)
                      : 'NA'
                  }
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='End Date'
                  name='batchEndDate'
                  disabled
                  value={
                    batchDetails.batchEndDate !== null
                      ? Moment(batchDetails.batchEndDate).format(dateFormat)
                      : 'NA'
                  }
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='Units Of Measurement'
                  name='unitOfMeasurement'
                  disabled
                  value={batchDetails.unitOfMeasurement}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='QC Date'
                  name='QCDate'
                  disabled
                  value={
                    batchDetails.QCDate !== null
                      ? Moment(batchDetails.QCDate).format(dateFormat)
                      : 'NA'
                  }
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item sm={3}>
                <TextField
                  label='Shipment Schedule'
                  name='shipmentDate'
                  disabled
                  value={
                    batchDetails.shipmentDate !== null
                      ? Moment(batchDetails.shipmentDate).format(dateFormat)
                      : 'NA'
                  }
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item sm={3}>
                <TextField
                  label='Maximum Retail Price'
                  name='maximumRetailPrice'
                  autoFocus
                  type='number'
                  placeholder='0.00'
                  value={batchDetails.maximumRetailPrice}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={
                    this.state.errors.maximumRetailPrice === '' ? false : true
                  }
                  helperText={this.state.errors.maximumRetailPrice}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='Status'
                  select
                  fullWidth
                  disabled={
                    this.props.batchItemDetails.statusCode >= 11 ? false : true
                  }
                  name='statusCode'
                  value={batchDetails.statusCode}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={this.state.errors.status}
                >
                  <MenuItem value={this.props.batchItemDetails.statusCode}>
                    {this.props.batchItemDetails.statusText}
                  </MenuItem>
                  {this.state.batchStatusList.map((ele, i) =>
                    ele.statusCode !== -1 ? (
                      <MenuItem key={i} value={ele.statusCode}>
                        {ele.statusText}
                      </MenuItem>
                    ) : null
                  )}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={3} alignItems='baseline'>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  aria-controls='customized-menu'
                  aria-haspopup='true'
                  elevation={1}
                  //select
                  disabled={
                    this.props.batchItemDetails.statusCode > 6 ? false : true
                  }
                  name='selectFiles'
                  margin='normal'
                  autoFocus
                  onClick={
                    this.props.batchItemDetails.statusCode > 6
                      ? this.handleClick
                      : null
                  }
                  variant='outlined'
                  label='Select File(s) to Download'
                  //placeholder="Select File(s) to Download"
                  value={this.state.fileNamesField}
                  multiline
                  InputProps={{
                    readOnly: true,
                  }}
                  error={this.state.errors.fileNamesField === '' ? false : true}
                  helperText={this.state.errors.fileNamesField}
                ></TextField>
                <ArrowDropDownIcon
                  style={{ marginLeft: '-2rem', marginTop: '2rem' }}
                />
                <Menu
                  id='customized-menu'
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem></MenuItem>
                  {this.state.batchList.map((val, i) =>
                    val.onStatusCode <=
                    this.props.batchItemDetails.statusCode ? (
                      <MenuItem key={i} primary={val.fileName}>
                        <span>
                          <Checkbox onChange={(e) => this.selectFiles(val)} />
                          {val.fileName}
                        </span>
                      </MenuItem>
                    ) : null
                  )}
                </Menu>
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                <Button
                  variant='contained'
                  component='span'
                  color='primary'
                  onClick={() => this.downloadFiles(this.state.selectedFiles)}
                >
                  <Typography variant='caption' style={{ color: '#ffffff' }}>
                    Download File(s)
                  </Typography>
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <Typography variant='body1' style={{ width: '100%' }}>
                  Custom Files
                </Typography>
                {this.state.prevCustomFiles.map((file, index) => (
                  <Grid item xs={12} key={index} style={{ marginTop: '1rem' }}>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ display: 'flex' }}
                      disabled={file.isDisabled}
                      onClick={() => this.downloadCustomFile(file, index)}
                    > */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <GetAppIcon color='secondary' />
                      <Typography
                        variant='body2'
                        color='secondary'
                        key={index}
                        style={{
                          marginLeft: '0.5rem',
                          textDecoration: file.isDisabled
                            ? 'line-through'
                            : 'underline',
                          cursor: file.isDisabled ? 'default' : 'pointer',
                          whiteSpace: 'nowarp',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        onClick={() => this.downloadCustomFile(file, index)}
                      >
                        {file.customFileDescription}
                      </Typography>
                    </div>

                    {/* </Button> */}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Button
              variant='contained'
              onClick={this.onSubmit}
              style={{
                backgroundColor: this.state.disableSaveButton
                  ? 'grey'
                  : 'green',
                borderRadius: '1rem',
                color: '#ffffff',
              }}
            >
              Save All Changes
            </Button>
            <Typography
              variant='body1'
              onClick={this.props.toggleViewBatchScreen}
              style={{
                marginLeft: '2.5rem',
                cursor: 'pointer',
              }}
            >
              Cancel X
            </Typography>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = (state) => ({
  batchUpdated: state.pcmp.pharmaCo.batchItems.batchUpdated,
  batchStatusList: state.pcmp.cmo.batchItems.batchStatusList,
  files: state.pcmp.common.files,
});

export default connect(mapStatetoProps, {
  pharmaUpdateBatchItem,
  fetchBatchItemStatusList,
  pharmaDownloadQCFile,
  pharmaUploadGRNFile,
  fetchCustomUploadedFiles,
  downloadCustomUploadedFile,
})(ViewEditBatchItem);
