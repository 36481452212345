import React, { useState } from 'react';

import OrgProfile from './orgProfile';
import OrgCompletionStatus from './orgProfileCompletion';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #E5E5E5',
  },
  indicator: {
    backgroundColor: 'black',
  },
  tabsContainer: {
    paddingRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '2rem',
    },
  },
}));

const OrgProfileIndex = () => {
  const classes = useStyles();
  const [value, changeValue] = useState(0);
  const [editField, changeEditField] = useState('');

  const handleChange = (event, value) => {
    changeValue(value);
  };

  const onEditField = (name) => {
    changeEditField(name);
  };

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Grid container>
        <Grid item xs={12} md={9} className={classes.tabsContainer}>
          <Tabs
            value={value}
            indicatorColor='primary'
            textcolor='primary'
            onChange={handleChange}
            classes={{
              flexContainer: classes.flexContainer,
              indicator: classes.indicator,
            }}
          >
            <Tab label='Organization Details' />
          </Tabs>

          <OrgProfile editField={editField} onEditField={onEditField} />
        </Grid>

        <OrgCompletionStatus onEditField={onEditField} />
      </Grid>
    </div>
  );
};

export default OrgProfileIndex;
