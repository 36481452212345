import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
// import NewButton from '../models/buttons/AddBatch';
import ViewBatches from '../models/buttons/ViewBatch';
// import ViewSupplierPO from '../../supplierPoGrn/ViewSupplierPO'
import AssignmentButton from './AssignmentButton';
import { useStyles } from '../../supplierPoGrn/styles'; //'./styles';
import CustomTableCell from '../../models/table/CustomTableCell';
import {
  presentFormatedData,
  presentDate,
} from '../../functionalComponents/dataFormatFunctions';
import ViewButton from './ViewButton';
import SingleRow from './SingleRow';
import StatusTextDispaly from '../../models/status/DeliveryCompleted';
import getColor from '../models/status/statusColors';
import { useSelector } from 'react-redux';

function ExpandableRow(props) {
  const [open, setOpen] = useState(false);
  // const [openAddBatch, setOpenAddBatch] = React.useState(false);
  const { SASLDetails } = props.row;
  const classes = useStyles();
  const [batchData, setBatchData] = useState(
    SASLDetails.deliveryScheduleDetails
  );
  const { searchQuery, loading } = props;
  const featuresAccess = useSelector(
    (state) => state.pcmp.common.accessControl.featuresAccess.fgFeatures
  );
  React.useEffect(() => {
    setBatchData(SASLDetails.deliveryScheduleDetails);
  }, [SASLDetails.deliveryScheduleDetails, props]);

  return (
    <React.Fragment>
      {/** Show the SA Header when there is no search query
       * and maintain the view when search is under process
       */}
      {(searchQuery === '' || (searchQuery !== '' && loading === true)) && (
        <SingleRow
          SASLDetails={SASLDetails}
          setOpen={setOpen}
          open={open}
          keys={props.keys}
        />
      )}
      {/** Open DS view when expand is clicked and when (search is enabled and loading is done) */}
      {(open === true || (searchQuery !== '' && loading === false)) &&
        batchData.map((row, i) => {
          return (
            <TableRow
              key={row.schrockenDeliveryScheduleID}
              style={{
                backgroundColor: props.keys % 2 === 0 ? '#FFF' : '#0000000a',
              }}
            >
              <TableCell
                style={{
                  width: '1%',
                  maxWidth: 0,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              ></TableCell>
              <CustomTableCell
                scope='row'
                align='left'
                elementStyle={{ maxWidth: '7rem' }}
              >
                {presentFormatedData(SASLDetails.scheduleAgreementNumber)}
              </CustomTableCell>

              <CustomTableCell
                scope='row'
                align={
                  row.scheduledDeliveryMonth === '' ||
                  row.scheduledDeliveryMonth === null
                    ? 'center'
                    : 'left'
                }
                elementStyle={{ maxWidth: '0px' }}
              >
                {presentFormatedData(row.scheduledDeliveryMonth)}
              </CustomTableCell>

              <CustomTableCell
                scope='row'
                align={
                  row.deliveryDate === null || row.deliveryDate === ''
                    ? 'center'
                    : 'left'
                }
              >
                {presentDate(row.deliveryDate)}
              </CustomTableCell>

              <CustomTableCell
                scope='row'
                align={
                  row.scheduleLineItemNumber === '' ||
                  row.scheduleLineItemNumber === null
                    ? 'center'
                    : 'right'
                }
                elementStyle={{ maxWidth: '0px' }}
              >
                {presentFormatedData(row.scheduleLineItemNumber)}
              </CustomTableCell>

              <CustomTableCell
                scope='row'
                align='center'
                elementStyle={{ maxWidth: '0px' }}
              >
                {presentFormatedData(row.fgMaterialCode)} /<br />{' '}
                {presentFormatedData(row.fgMaterialName)}
              </CustomTableCell>

              <CustomTableCell
                scope='row'
                align={
                  row.deliveryScheduleUOM === null ||
                  row.deliveryScheduleUOM === ''
                    ? 'center'
                    : 'left'
                }
                elementStyle={{ maxWidth: '0px' }}
              >
                {presentFormatedData(row.deliveryScheduleUOM)}
              </CustomTableCell>

              <CustomTableCell
                scope='row'
                align='right'
                elementStyle={{ maxWidth: '0px' }}
              >
                {row.openTargetQuantity === null ||
                row.openTargetQuantity === ''
                  ? '-'
                  : row.openTargetQuantity}
              </CustomTableCell>

              <CustomTableCell
                scope='row'
                align='right'
                elementStyle={{ maxWidth: '0px' }}
              >
                {presentFormatedData(row.scheduledQuantity)}
              </CustomTableCell>

              <CustomTableCell
                scope='row'
                align='left'
                elementStyle={{ maxWidth: '7rem' }}
              >
                <StatusTextDispaly
                  statusText={row.deliveryScheduleStatusText}
                  bgColor={getColor(row.deliveryScheduleStatusID)}
                />
              </CustomTableCell>

              <CustomTableCell className={classes.changingWidth} align='left'>
                <div className={classes.singleAction}>
                  {featuresAccess?.viewDeliveryScheduleBatches && (
                    <div>
                      <ViewBatches
                        schrockenDeliveryScheduleID={
                          row.schrockenDeliveryScheduleID
                        }
                        scheduleAgreementNumber={
                          SASLDetails.scheduleAgreementNumber
                        }
                        row={row}
                      />
                    </div>
                  )}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {featuresAccess?.viewDeliveryScheduleBatches && (
                      <ViewButton
                        schrockenDeliveryScheduleID={
                          row.schrockenDeliveryScheduleID
                        }
                        scheduleAgreementNumber={
                          SASLDetails.scheduleAgreementNumber
                        }
                        row={row}
                      />
                    )}

                    {featuresAccess?.viewBOM && (
                      <AssignmentButton
                        schrockenSAID={SASLDetails.schrockenSAID}
                        fgMaterialCode={row.fgMaterialCode}
                        scheduleLineItemNumber={row.scheduleLineItemNumber}
                        schrockenSLID={row.schrockenSLID}
                        schrockenDeliveryScheduleID={
                          row.schrockenDeliveryScheduleID
                        }
                      />
                    )}
                  </div>
                </div>
              </CustomTableCell>
            </TableRow>
          );
        })}
    </React.Fragment>
  );
}

export default ExpandableRow;
