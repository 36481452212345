import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Grid,
  Typography,
  TextField,
  Button,
  MenuItem
} from "@material-ui/core";

import {
  cmoUpdatePMAvailability,
  cmoFetchPMAvailability,
  cmoFetchRMStatusList
} from "../../../../../containers/actions/cmo/addRmPmActions";

class UpdatePMAvailability extends Component {
  state = {
    pmAvailabilityDetails: this.props.pmAvailabilityDetails,
    pmStatuses: [],
    errors: {
      packagingMaterialName: "",
      units: "",
      requiredQuantity: "",
      shortage: "",
      statusCode: "",
      expectedDelivery: ""
    }
  };

  componentDidMount() {
    let data = {
      ...this.props.params,
      collabType: "CMO"
    };
    this.props.cmoFetchRMStatusList(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pmUpdated) {
      // this.clearData();
      let data = {
        ...this.props.params,
        collabType: "CMO"
      };
      setTimeout(() => this.props.cmoFetchPMAvailability(data), 2000);
      // this.props.cmoFetchPMAvailability(data);
      this.props.closeUpdate();
    }
    if (nextProps.pmStatuses) {
      this.setState({ pmStatuses: nextProps.pmStatuses });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      pmAvailabilityDetails: {
        ...prevState.pmAvailabilityDetails,
        [name]: value
      },
      errors: {
        packagingMaterialName: "",
        units: "",
        requiredQuantity: "",
        shortage: "",
        statusCode: "",
        expectedDelivery: ""
      }
    }));
  };
  validate = () => {
    let isError = false;

    let { pmAvailabilityDetails } = this.state;

    let errors = {
      packagingMaterialName: "",
      units: "",
      requiredQuantity: "",
      shortage: "",
      statusCode: "",
      expectedDelivery: ""
    };

    if (pmAvailabilityDetails.packagingMaterialName === "") {
      isError = true;
      errors.packagingMaterialName = "Please Enter Material Name";
    } else if (pmAvailabilityDetails.units === "") {
      isError = true;
      errors.units = "Please Enter Units";
    } else if (pmAvailabilityDetails.requiredQuantity === "") {
      isError = true;
      errors.requiredQuantity = "Please Enter Required Quantity";
    } else if (pmAvailabilityDetails.shortage === "") {
      isError = true;
      errors.shortage = "Please Enter Shortage Quantity";
    } else if (pmAvailabilityDetails.statusCode === "") {
      isError = true;
      errors.statusCode = "Please Select Status";
    } else if (pmAvailabilityDetails.expectedDelivery === "") {
      isError = true;
      errors.expectedDelivery = "Please Select Expected Delivery date";
    } else {
      this.setState({ alltrue: true });
    }
    this.setState({ errors });
    return isError;
  };

  onSubmit = () => {
    let error = this.validate();
    if (!error) {
      this.setState({ disableSaveButton: true });
      let data = {
        ...this.props.params,
        collabType: "CMO",
        PMAvailabilityData: this.state.pmAvailabilityDetails
      };
      this.props.cmoUpdatePMAvailability(data);
    }
  };

  clearData = () => {
    this.setState({
      disableSaveButton: false,
      pmAvailabilityDetails: {
        packagingMaterialName: "",
        units: "",
        requiredQuantity: "",
        shortage: "",
        statusCode: "",
        expectedDelivery: ""
      }
    });
  };

  render() {
    const { pmAvailabilityDetails } = this.state;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <TextField
            label="Name"
            name="packagingMaterialName"
            disabled
            fullWidth
            value={pmAvailabilityDetails.packagingMaterialName}
            margin="normal"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={1}>
          <TextField
            label="Batch Size"
            name="units"
            // disabled
            fullWidth
            value={pmAvailabilityDetails.units}
            onChange={this.handleChange}
            margin="normal"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            label="Required Quantity"
            name="requiredQuantity"
            fullWidth
            value={pmAvailabilityDetails.requiredQuantity}
            onChange={this.handleChange}
            margin="normal"
            variant="outlined"
            error={this.state.errors.requiredQuantity === "" ? false : true}
            helperText={this.state.errors.requiredQuantity}
          />
        </Grid>

        <Grid item xs={12} md={1}>
          <TextField
            label="Shortage"
            name="shortage"
            fullWidth
            value={pmAvailabilityDetails.shortage}
            onChange={this.handleChange}
            margin="normal"
            variant="outlined"
            error={this.state.errors.shortage === "" ? false : true}
            helperText={this.state.errors.shortage}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            label="Status"
            select
            fullWidth
            name="statusCode"
            value={pmAvailabilityDetails.statusCode}
            onChange={this.handleChange}
            margin="normal"
            variant="outlined"
          >
            {/* <MenuItem value={pmAvailabilityDetails.status}>
              {pmAvailabilityDetails.status}
            </MenuItem> */}
            {this.state.pmStatuses.map((ele, i) => (
              <MenuItem value={ele.statusCode} key={i}>
                {ele.statusText}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            label={
              <Typography variant="caption" style={{ fontSize: "0.65rem" }}>
                Expected Days for Delivery
              </Typography>
            }
            select
            fullWidth
            name="expectedDelivery"
            value={pmAvailabilityDetails.expectedDelivery}
            onChange={this.handleChange}
            margin="normal"
            variant="outlined"
            error={this.state.errors.expectedDelivery === "" ? false : true}
            helperText={this.state.errors.expectedDelivery}
          >
            <MenuItem value={"0"}>0</MenuItem>
            <MenuItem value={"5"}>5</MenuItem>
            <MenuItem value={"10"}>10</MenuItem>
            <MenuItem value={"20"}>20</MenuItem>
            <MenuItem value={"25"}>25</MenuItem>
            <MenuItem value={"30"}>30</MenuItem>
          </TextField>
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          style={{
            display: "flex",
            justifyContent: "center",
            // marginTop: "1rem",
            alignItems: "center"
          }}
        >
          <Button
            variant="contained"
            // color="primary"
            disabled={this.state.disableSaveButton}
            onClick={this.onSubmit}
            style={{
              color: "#ffffff",
              backgroundColor: this.state.disableSaveButton ? "grey" : "green"
            }}
          >
            Save
          </Button>

          <Typography
            variant="body2"
            color="error"
            onClick={this.props.closeUpdate}
            style={{
              paddingLeft: "1rem",
              cursor: "pointer"
            }}
          >
            Cancel X
          </Typography>
        </Grid>
      </Grid>
    );
  }
}
const mapStatetoProps = state => ({
  pmUpdated: state.pcmp.cmo.rmpm.pmUpdated,
  pmStatuses: state.pcmp.cmo.rmpm.rmStatuses
});
export default connect(mapStatetoProps, {
  cmoUpdatePMAvailability,
  cmoFetchPMAvailability,
  cmoFetchRMStatusList
})(UpdatePMAvailability);
