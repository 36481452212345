import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useStyles } from './updateBatchStyles';
import { presentFormatedData } from '../../../functionalComponents/dataFormatFunctions';
import { useSelector } from 'react-redux';

const General = (props) => {
  const classes = useStyles();
  const { data } = props;
  // FG features check if billing doc is enabled
  const billingDocNumber = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.features.FG.fgBatch
        .billingDocumentNumberEnabled;
    }
    return false;
  });
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            GRN Number
          </Typography>

          <Typography
            variant='body2'
            className={classes.fontPadding}
            style={{ fontSize: '1rem' }}
          >
            {presentFormatedData(data.BOGeneratedGRNNumber, '-Not Generated-')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            QC Number
          </Typography>
          <Typography
            variant='body2'
            className={classes.fontPadding}
            style={{ fontSize: '1rem' }}
          >
            {presentFormatedData(data.QCNumber, '-Not Generated-')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            CC Number
          </Typography>
          <Typography
            variant='body2'
            className={classes.fontPadding}
            style={{ fontSize: '1rem' }}
          >
            {presentFormatedData(data.CCNumber, '-Not Generated-')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            STO Number
          </Typography>

          <Typography
            variant='body2'
            className={classes.fontPadding}
            style={{ fontSize: '1rem' }}
          >
            {presentFormatedData(data.STONumber, '-Not Generated-')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            OBD Number
          </Typography>

          <Typography
            variant='body2'
            className={classes.fontPadding}
            style={{ fontSize: '1rem' }}
          >
            {presentFormatedData(data.OBDNumber, '-Not Generated-')}
          </Typography>
        </Grid>
        {billingDocNumber && (
          <Grid item xs={12} sm={6} md={6} lg={3} className={classes.grid}>
            <Typography variant='body2' className={classes.font}>
              Billing Document Number
            </Typography>

            <Typography
              variant='body2'
              className={classes.fontPadding}
              style={{ fontSize: '1rem' }}
            >
              {presentFormatedData(
                data.billingDocumentNumber,
                '-Not Generated-'
              )}
            </Typography>
          </Grid>
        )}
        {localStorage.collabType !== 'CMO' && (
          <Grid item xs={12} sm={6} md={6} lg={3} className={classes.grid}>
            <Typography variant='body2' className={classes.font}>
              CMO
            </Typography>

            <Typography
              variant='body2'
              className={classes.fontPadding}
              style={{ fontSize: '1rem' }}
            >
              {presentFormatedData(data.cmoMember, '--')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default General;
