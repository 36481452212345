import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
//files
import AddSupplierPoRecord from '../../supplierPoAdd';
import Dialog from '@material-ui/core/Dialog';
import RecievedDialog from '../../supplierPoAdd/RecievedDialog';
import Button from '@material-ui/core/Button';
import useStyles from './buttonStyles';

const RecievedButton = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [recievedDialog, setRecievedDialog] = React.useState(false);

  const handleClickRecieved = () => {
    setRecievedDialog(true);
  };

  const handleCloseRecieved = () => {
    setRecievedDialog(false);
  };

  const handleClickOpen = () => {
    setRecievedDialog(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        disableElevation
        disableFocusRipple
        disableRipple
        onClick={handleClickRecieved}
        className={classes.button}
      >
        <div className={classes.logo}>
          <CheckIcon fontSize='small' className={classes.logoHeight} />
          &nbsp;
          <Typography variant='body1'>Received</Typography>
        </div>
      </Button>
      <AddSupplierPoRecord open={open} handleClose={handleClose} {...props} />
      <Dialog open={recievedDialog} onClose={handleCloseRecieved} maxWidth='lg'>
        <RecievedDialog
          handleClickOpen={handleClickOpen}
          handleCloseRecieved={handleCloseRecieved}
          {...props}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default RecievedButton;
