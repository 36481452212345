import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomTableHeaderCell from '../../../models/table/CustomTableHeaderCell';
import { header } from './header';
import SingleRow from './SingleRow';
import { useStyles } from '../styles';

const AvailabilityTable = (props) => {
  const classes = useStyles();
  let loading = false;
  const { data, handleDataChange, availability, batchCount } = props;

  const [expandTable, setExpandTable] = useState(
    data?.filter((value) => value.materialStatusID === 0).length > 0
      ? true
      : false
  );

  const handleExpand = () => {
    setExpandTable(true);
  };

  useEffect(() => {
    setExpandTable(
      data?.filter((value) => value.materialStatusID === 0).length > 0
        ? true
        : false
    );
  }, [data]);

  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={9} sm={9} md={9}>
        <Typography
          variant='body2'
          style={{ paddingBottom: '1rem', margin: 'auto' }}
          className={classes.font}
        >
          Details of the materials which are in shortage along with the expected
          date of availability post release:
        </Typography>
      </Grid>
      <Grid item xs={9} sm={9} md={9}>
        <TableContainer className={classes.paper}>
          <Table
            id='Table'
            className={classes.table}
            aria-label='collapsible table'
          >
            <TableHead className={classes.head}>
              <TableRow>
                {header(expandTable).map((headCell) => (
                  <CustomTableHeaderCell
                    isSortEnable={headCell.isSortEnable}
                    cellData={headCell}
                    key={headCell.id}
                    elementStyle={{
                      verticalAlign: 'top',
                      width: headCell.width ? headCell.width : 'auto',
                    }}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <React.Fragment>
                {loading === true && (
                  <TableRow style={{ height: 150 }}>
                    <TableCell colSpan={12}>
                      <div style={{ textAlign: 'center' }}>
                        <CircularProgress color='primary' thickness={7} />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {loading === false ? (
                  data.map((row, i) => (
                    <SingleRow
                      key={i}
                      data={row}
                      handleExpand={handleExpand}
                      handleDataChange={handleDataChange}
                      expandTable={expandTable}
                      availability={availability}
                      batchCount={batchCount}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={header.length} align='center'>
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default AvailabilityTable;
