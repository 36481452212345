/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Harish D
 * -----
 * File Description: Grid to separate Cards grid and table grid
 */

import React from "react";
import { Grid } from "@material-ui/core";

import PurchaseOrderTable from "./PurchaseOrderTable";
import PurchaseOrder from "./PurchaseOrder";
import PORejectionComments from "./PORejectionComments";
import Chat from "../chat";

const POLayout = props => (
  <Grid
    style={{
      positiion: "relative"
    }}
  >
    <PurchaseOrder />
    <PurchaseOrderTable collabType="Pharma Co" {...props} />
    <PORejectionComments />
    <Chat collabType="Pharma Co" item="PO"/>
  </Grid>
);

export default POLayout;
