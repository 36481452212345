import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
// import Button from "@material-ui/core/Button";

import { withRouter } from 'react-router-dom';
import MinimizeIcon from '@material-ui/icons/Minimize';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import Moment from 'moment';

import axios from 'axios';

import { appUrl } from '../../../../../../../utils/config';
import { cmoDownloadFiles } from '../../../../../containers/actions/cmo/filesActions';

const styles = {
  resize: {
    fontSize: '0.85rem',
    padding: '16px 10px',
  },
  gridAlign: {
    position: 'fixed',
    bottom: 0,
    width: '20rem',
    right: '1.2rem',
    border: '1px solid #ded5d5',
    backgroundColor: '#E5E5E5',
  },
  chatOpenGrid: {
    width: '100%',
    height: '60vh',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
  },
  chatHeader: {
    padding: '0.75rem',
    color: '#ffffff',
    backgroundColor: '#2196f3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  minimizeIcon: {
    padding: 0,
    marginTop: '-1rem',
    color: '#ffffff',
  },
  chatOpenBottomGrid: {
    width: '20rem',
    // height: "5rem",
    position: 'fixed',
    left: 'auto',
    right: 'auto',
    bottom: 0,
    padding: '1rem 0.5rem',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    border: '1px solid #ded5d5',
  },
  multilinePadding: {
    padding: 0,
  },
  multiline: {
    padding: '12px 8px',
  },
  //test
  card: {
    width: '80%',
    minWidth: 250,
    margin: '0.5rem',
  },
  title: {
    fontSize: 14,
  },
  chatDataText: {
    width: '100%',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
  cardContentRoot: {
    padding: '8px 12px',
    '&:last-child': {
      paddingBottom: 8,
    },
  },
  attachmentGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0.5rem 0.5rem',
  },
  chatFileName: {
    whiteSpace: 'nowrap',
    width: '85%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

class ChatBox extends Component {
  state = {
    chatData: '',
    chatFile: '',
    chatFileName: '',
    chatSenderName: '',
    chatSenderEmail: '',
    chatMessages: [],
    isChatLoading: true,
    disableChatButton: false,
    errors: {
      chatData: false,
    },
  };
  componentDidMount() {
    this.scrollToBottom();
    let data = {
      ...this.props.match.params,
      collabType: this.props.collabType,
    };

    axios
      .post(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/${this.props.collabType}/${data.roleName}/getLoggedInUserDetails`,
        data
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({
            chatSenderName: res.data.LoggedInUserDetails.userName,
            chatSenderEmail: res.data.LoggedInUserDetails.userEmail,
          });
          this.getChatData();
        }
      })
      .catch((err) => console.log(err));
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  // Get all messages
  getChatData = () => {
    let data = {
      ...this.props.match.params,
      collabType: this.props.collabType,
    };

    axios
      .post(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/${this.props.collabType}/${data.roleName}/fetch${this.props.item}ChatData`,
        data
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({
            chatMessages: res.data.chatData,
            isChatLoading: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };
  scrollToBottom = () => {
    this.el.scrollIntoView({ behavior: 'auto' });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errors: {
        chatData: false,
      },
    });
  };
  openChat = () => {
    this.setState({ chatBoxOpen: !this.state.chatBoxOpen });
  };

  handleUploadFile = (event) => {
    const file = event.target.files[0];
    const name = event.target.files[0].name;
    this.setState({
      chatFile: file,
      chatFileName: name,
    });
    event.target.value = null;
  };
  downloadChatFile = (ID) => {
    let getFile = {
      ...this.props.match.params,
      collabType: this.props.collabType,
      schrockenChatID: ID,
      docType: 'chat',
    };
    this.props.cmoDownloadFiles(getFile, 'Chat');
  };

  validate = () => {
    let error = false;
    const { chatData } = this.state;
    let errors = {
      chatData: false,
    };
    if (chatData === '') {
      error = true;
      errors.chatData = true;
    }
    this.setState({ errors });
    return error;
  };
  sendchatData = () => {
    let isError = this.validate();
    if (!isError && !this.state.disableChatButton) {
      this.setState({ disableChatButton: true });
      let chatFile = new FormData();
      const chat = {
        chatData: this.state.chatData,
        chatSenderName: this.state.chatSenderName,
        chatSenderEmail: this.state.chatSenderEmail,
        chatTimestamp: new Date().toISOString(),
      };
      chatFile.append('chatFile', this.state.chatFile);
      chatFile.append('chatFileName', this.state.chatFileName);
      chatFile.append('chatDataObject', JSON.stringify(chat));
      chatFile.append(
        'purchaseOrderNumber',
        this.props.match.params.purchaseOrderNumber
      );
      chatFile.append('ecosystemId', this.props.match.params.ecosystemId);
      chatFile.append('collabType', this.props.collabType);
      chatFile.append('roleName', this.props.match.params.roleName);
      chatFile.append(
        'organizationName',
        this.props.match.params.organizationName
      );
      chatFile.append(
        'lineItemNumber',
        this.props.item === 'POLineItem'
          ? this.props.match.params.lineItemNumber
          : null
      );
      chatFile.append(
        'schrockenPOLineID',
        this.props.item === 'POLineItem'
          ? this.props.match.params.schrockenPOLineID
          : null
      );
      axios
        .post(
          `${appUrl}/${localStorage.appName}/${localStorage.network}/${this.props.collabType}/${this.props.match.params.roleName}/add${this.props.item}ChatData`,
          chatFile
        )
        .then((res) => {
          const { chatMessages, chatData, chatFileName } = this.state;
          chatMessages.push({
            chatSenderEmail: this.state.chatSenderEmail,
            chatSenderName: this.state.chatSenderName,
            chatTimestamp: new Date().toISOString(),
            chatData: chatData,
            chatFileName: chatFileName,
            chatFileHash: chatFileName === '' ? '' : 'notempty',
            schrockenChatID: res.data.schrockenChatID,
          });
          this.setState({
            chatData: '',
            chatFile: '',
            chatFileName: '',
            disableChatButton: false,
          });
          // this.getChatData();
        })
        .catch((err) => console.log(err));
    }
  };
  removechatFile = () => {
    this.setState({
      chatFile: '',
      chatFileName: '',
    });
  };

  render() {
    const { classes, openChat, currentItem } = this.props;
    const {
      chatMessages,
      chatData,
      chatFileName,
      isChatLoading,
      disableChatButton,
      errors,
    } = this.state;
    return (
      <Grid className={classes.chatOpenGrid}>
        <Grid className={classes.chatHeader} onClick={openChat}>
          <Typography variant='body2'>{currentItem} </Typography>

          <MinimizeIcon className={classes.minimizeIcon} />
        </Grid>
        <Grid
          container
          style={{
            marginBottom: '5rem',
            overflowY: 'auto',
          }}
        >
          {isChatLoading ? (
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20vh',
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <React.Fragment>
              {chatMessages.length > 0 ? (
                chatMessages.map((row, i) => (
                  <Grid item xs={12} key={i}>
                    <Card
                      className={classes.card}
                      style={{
                        float:
                          row.chatSenderEmail === this.state.chatSenderEmail
                            ? 'right'
                            : 'left',
                        backgroundColor:
                          row.chatSenderEmail === this.state.chatSenderEmail
                            ? '#dcf8c6'
                            : '#ffffff',
                      }}
                    >
                      <CardContent
                        classes={{
                          root: classes.cardContentRoot,
                        }}
                      >
                        <Typography
                          className={classes.title}
                          style={{
                            color:
                              row.chatSenderEmail === this.state.chatSenderEmail
                                ? '#e36e39'
                                : '#2196F3',
                          }}
                        >
                          {row.chatSenderName}
                        </Typography>
                        <Typography
                          variant='caption'
                          gutterBottom
                          component='p'
                          color='textSecondary'
                          style={{
                            fontSize: '0.51rem',
                          }}
                        >
                          {Moment(row.chatTimestamp).format(
                            'Do MMMM YYYY, h:mm a'
                          )}
                        </Typography>
                        <Typography
                          variant='caption'
                          className={classes.chatDataText}
                        >
                          {row.chatData}
                        </Typography>
                      </CardContent>
                      {row.chatFileHash !== '' ? (
                        <Grid className={classes.attachmentGrid}>
                          <Typography
                            variant='caption'
                            className={classes.chatFileName}
                          >
                            {row.chatFileName}
                          </Typography>
                          <CloudDownloadIcon
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              this.downloadChatFile(row.schrockenChatID)
                            }
                          />
                        </Grid>
                      ) : null}
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '20vh',
                  }}
                >
                  <Typography variant='caption' style={{ color: '#000000' }}>
                    There are no messages added in this chat yet!
                  </Typography>
                </Grid>
              )}
            </React.Fragment>
          )}
          <div
            style={{ float: 'left', clear: 'both' }}
            ref={(el) => {
              this.el = el;
            }}
          />
        </Grid>

        <Grid container className={classes.chatOpenBottomGrid}>
          <Grid item xs={1}>
            <input
              style={{
                display: 'none',
              }}
              id='upload-chatData-chatFile'
              type='file'
              name='chatFile'
              onChange={this.handleUploadFile}
              accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
            />
            <label htmlFor='upload-chatData-chatFile'>
              <AttachFileIcon
                style={{
                  cursor: 'pointer',
                  color: chatFileName ? '#e36e39' : '#000000',
                }}
              />
            </label>
          </Grid>

          <Grid
            item
            xs={10}
            style={{
              padding: '0 0.5rem',
            }}
          >
            <TextField
              variant='outlined'
              fullWidth
              autoFocus
              multiline
              rowsMax={2}
              name='chatData'
              value={chatData}
              onChange={this.handleChange}
              InputProps={{
                classes: {
                  input: classes.resize,
                  inputMultiline: classes.multilinePadding,
                  multiline: classes.multiline,
                },
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && !e.shiftKey) {
                  e.preventDefault();
                  this.sendchatData();
                }
              }}
              error={errors.chatData}
              helperText={errors.chatData ? 'Please Add Message' : null}
            />
            {chatFileName !== '' ? (
              <Grid container>
                <Grid
                  item
                  xs={8}
                  style={{
                    display: 'flex',
                  }}
                >
                  <Typography
                    variant='caption'
                    style={{ color: '#000000' }}
                    className={classes.chatFileName}
                  >
                    {chatFileName}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    cursor: 'pointer',
                  }}
                >
                  <Typography
                    variant='caption'
                    color='error'
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={this.removechatFile}
                  >
                    Remove
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          <Grid item xs={1}>
            <SendIcon
              type='submit'
              // color="primary"
              onClick={this.sendchatData}
              style={{
                cursor: 'pointer',
                color: disableChatButton ? 'gray' : '#e36e39',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStatetoProps = (state) => ({});
export default withStyles(styles)(
  connect(mapStatetoProps, { cmoDownloadFiles })(withRouter(ChatBox))
);
