import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  jcSE: {
    justifyContent: 'space-evenly',
  },
  cardStyle: {
    cursor: 'pointer',
    backgroundColor: '#FFF8F8',
    border: '1px solid black',
    height: '-webkit-fill-available',
    '&:hover': {
      backgroundColor: '#FFD7D7',
    },
  },
  cardSelected: {
    cursor: 'pointer',
    backgroundColor: '#FFD7D7',
    border: '1px solid black',
    height: '-webkit-fill-available',
  },
  G1: {
    width: '275px',
    height: '175px',
  },
  G2: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '149px',
    // flexDirection: 'row',
  },
  G3: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75px',
  },
  cardContent: {
    height: 'inherit',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  count: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '2.5rem',
  },
  title: {
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: 500,
  },
}));
