import { combineReducers } from "redux";

import pharmaCoReducer from "./pharmaCo";
import cmoReducer from "./cmo";
import commonReducer from "./common";

export default combineReducers({
  pharmaCo: pharmaCoReducer,
  cmo: cmoReducer,
  common: commonReducer
});
