import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  Button,
  Typography,
  TablePagination,
  Grid,
} from '@material-ui/core';
import jstz from 'jstz';
import tz from 'moment-timezone';
import Moment from 'moment';

console.log(tz);

const ReportsTable = (props) => {
  const {
    header,
    tableBody,
    downloadReport,
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    length,
    rowsPerPage,
    requestedBy,
    data,
  } = props;

  let date = Moment(new Date().toISOString());

  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD/MMM/YYYY';

  const zoneName = jstz.determine().name();
  let zone = date.tz(zoneName).format('z');
  const reportDate = Moment(date).format(`${dateFormat} HH:mm:ss`);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  return (
    <Paper
      style={{
        width: '100%',
        marginTop: '2rem',
        overflowX: 'auto',
        border: '1px solid #E5E5E5',
        boxShadow: 'none',
      }}
    >
      <Toolbar>
        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
          }}
        >
          <Grid
            item
            xs={10}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography variant='subtitle1' component='span'>
              Report Date: {reportDate} {zone}
            </Typography>
            <Typography variant='subtitle1' component='span'>
              Requested By: {requestedBy}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              variant='contained'
              color='primary'
              component='span'
              disableRipple
              style={{
                maxWidth: '9rem',
                boxShadow: 'none',
              }}
              fullWidth
              onClick={downloadReport}
              disabled={length > 0 ? false : true}
            >
              Download
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        ></div>
      </Toolbar>

      <Table>
        <TableHead style={{ backgroundColor: '#F5F5F5' }}>
          <TableRow>
            {header.map((headCell, i) => (
              <TableCell key={i} align={headCell.align}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBody}
          {emptyRows > 0 && (
            <TableRow style={{ height: 48 * emptyRows }}>
              <TableCell colSpan={header.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component='div'
        count={length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ReportsTable;
