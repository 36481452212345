export const getVariance = (consumptionArray, stdQty) => {
  if (
    !consumptionArray ||
    !stdQty ||
    (consumptionArray.length === 1 &&
      consumptionArray[0]?.BomComponentConsumedQuantity === '')
  ) {
    return 'NA';
  }
  let totalConsumption = 0;
  for (let i = 0; i < consumptionArray.length; i++) {
    totalConsumption =
      totalConsumption + consumptionArray[i].BomComponentConsumedQuantity;
  }
  let variance = parseFloat(Number(stdQty - totalConsumption).toFixed(3));
  return variance;
};

export const getVariancePercentage = (variance, stdQty) => {
  let variancePercentage = Number((variance / stdQty) * 100).toFixed(3);
  return isNaN(variancePercentage) ? 'NA' : parseFloat(variancePercentage);
};

export const getRefQty = ({ materialCode, bomData }) => {
  let refQty = bomData
    ? bomData[bomData.findIndex((x, i) => materialCode === x.BomComponentCode)]
        .BomComponentRefQuantity
    : null;
  return refQty;
};
// To get valuatedUnrestrictedUsedStock value with associated batch

export const getStockValue = (consumptionBatchNumbers, inventoryBatches) => {
  // for (let i = 0; i < consumptionBatchNumbers.length; i++) {
  for (let j = 0; j < inventoryBatches.length; j++) {
    if (
      consumptionBatchNumbers.componentBatchNumber ===
      inventoryBatches[j].inventoryBatchNumber
    ) {
      return inventoryBatches[j].valuatedUnrestrictedUsedStock;
    }
    // }
  }
};
export const getExpiredDate = (consumptionBatchNumbers, inventoryBatches) => {
  for (let i = 0; i < consumptionBatchNumbers.length; i++) {
    for (let j = 0; j < inventoryBatches.length; j++) {
      if (
        consumptionBatchNumbers[i].componentBatchNumber ===
        inventoryBatches[j].inventoryBatchNumber
      ) {
        return inventoryBatches[j].inventoryExpiryDate;
      }
    }
  }
};

// calculating Standard Quantity (As per the batch size)

export const getStdQty = (batchSize, stdBatchSize, refQty) => {
  let proportionalFactor = parseFloat(
    Number(batchSize / stdBatchSize).toFixed(3)
  );
  if (proportionalFactor > 0.01) {
    return parseFloat(Number(refQty * proportionalFactor).toFixed(3));
  } else {
    return refQty;
  }
};
export const getThreshold = ({ materialCode, series }) => {
  if (typeof materialCode === 'number') materialCode = materialCode.toString();
  let threshold = series
    ? series[
        series.findIndex((s, index) => s.series === parseInt(materialCode[0]))
      ]?.threshold
    : -1;

  return threshold;
};
