import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Popper from '@material-ui/core/Popper';
import StyledDialog from '../../../../../../../common/models/Dialog';

//func
import {
  fetchBOMConsumptionDetails,
  updateConsumption,
  fetchDSBatchDetails,
  fetchBatchDetails,
  fetchBOMDetails,
  fetchVarianceThresholdDetails,
} from '../../../../../../containers/actions/common/saslAction';
import {
  presentFormatedData,
  presentDate,
} from '../../../../functionalComponents/dataFormatFunctions';
//files
import StyledButton from '../../../../../common/models/Button';

import { loadTableData } from '../../../../../../containers/actions/common/fileActions';

// Backdrop while updating
import Backdrop from '../../../../models/BackDrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputNumber from '../../../../models/inputs/InputNumber';
import clsx from 'clsx';
import {
  getVariance,
  getVariancePercentage,
  getThreshold,
  getRefQty,
  getStdQty,
  getStockValue,
  getExpiredDate,
} from '../../../common/variance';
import LongTextDisplay from '../../../../models/LongText';

const PopperAutoSelect = function (props) {
  return (
    <Popper
      {...props}
      style={{ width: 'fit-content', minWidth: 300 }}
      placement='bottom-start'
    />
  );
};

class UpdateConsumptionInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      batchData: {},
      isUpdateInfoDialog: false,
      //
      resError: null,
      error: false,
      //using this variable selectedItems to disable options in autocomplete
      selectedItems: [],
      // recipe
      bomData: {},
    };
  }

  componentDidMount() {
    const data = {
      //10566,10540
      schrockenDeliveryScheduleBatchID: parseInt(
        this.props.schrockenDeliveryScheduleBatchID
      ),
      schrockenDeliveryScheduleID: this.props.schrockenDeliveryScheduleID,
      bomConsumptionAddedIndicator:
        this.props.batchDetails.bomConsumptionAddedIndicator,
    };

    let bomRecipeFetch = {
      schrockenDeliveryScheduleID: parseInt(
        this.props.schrockenDeliveryScheduleID
      ),
    };

    this.props.fetchBOMConsumptionDetails(data);

    this.props.fetchBOMDetails(bomRecipeFetch);
    this.props.fetchVarianceThresholdDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.consumptionDetails !== prevProps.consumptionDetails &&
      this.props.consumptionDetails.status === true
    ) {
      this.setState({
        // data: this.props.consumptionDetails.data.bomConsumptionDetails,
        loading: false,
        batchData: this.props.consumptionDetails.data,
      });
      this.formatConsumptionData(
        this.props.consumptionDetails.data.bomConsumptionDetails
      );
    }
    if (
      this.props.updateBOM !== prevProps.updateBOM &&
      this.props.updateBOM.status === true
    ) {
      this.setState({
        isUpdateInfoDialog: true,
        resError: null,
      });
      // Ds table load api call
      let tableData = {
        schrockenDeliveryScheduleID: this.props.schrockenDeliveryScheduleID,
      };
      this.props.fetchBatchDetails(tableData);
      this.props.loadTableData();
    }
    if (
      this.props.updateBOM !== prevProps.updateBOM &&
      this.props.updateBOM.status === false
    ) {
      if (typeof this.props.updateBOM.errorDescription === 'object') {
        let errorKey = Object.keys(this.props.updateBOM.errorDescription)[0];
        this.setState({
          resError: this.props.updateBOM.errorDescription[errorKey],
        });
      } else {
        this.setState({
          resError: this.props.updateBOM.errorDescription,
        });
      }
      this.scrollToTop();
    }
    if (
      this.props.viewBOM !== prevProps.viewBOM &&
      this.props.viewBOM.status === true
    ) {
      this.setState({
        bomData: this.props.viewBOM.data,
        // loading: false,
      });
    }
    if (
      this.props.thresholdValue !== prevProps.thresholdValue &&
      this.props.thresholdValue.status === true
    ) {
      this.setState({
        thresholdData: this.props.thresholdValue.data,
      });
    }
  }

  scrollToTop = () => {
    this.el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  handleCloseDialog = () => {
    this.setState({
      isUpdateInfoDialog: !this.state.isUpdateInfoDialog,
    });
    this.props.handleClose();
    this.props.handleFetchCall();
  };

  addCustomRow = (componentData) => {
    let consumptionData = {
      componentBatchNumber: '',
      BomComponentConsumedQuantity: '',
      newField: true,
    };
    // TO DO: ADD find index as separate func
    let { data } = this.state;
    let indexofComponent = data.findIndex(
      (ele) => ele.BomComponentCode === componentData.BomComponentCode
    );
    let addEmptyRow = true;

    for (
      let j = 0;
      j < data[indexofComponent].consumptionBatchNumbers.length;
      j++
    ) {
      if (
        data[indexofComponent].consumptionBatchNumbers[j]
          .BomComponentConsumedQuantity === '' ||
        data[indexofComponent].consumptionBatchNumbers[j]
          .BomComponentConsumedQuantity === 0 ||
        isNaN(
          data[indexofComponent].consumptionBatchNumbers[j]
            .BomComponentConsumedQuantity
        ) ||
        data[indexofComponent].consumptionBatchNumbers[j]
          .componentBatchNumber === ''
      ) {
        addEmptyRow = false;
      }
    }

    if (addEmptyRow) {
      this.setState(({ data }) => ({
        data: [
          ...data.slice(0, indexofComponent),
          {
            ...data[indexofComponent],
            consumptionBatchNumbers: [
              ...data[indexofComponent].consumptionBatchNumbers,
              consumptionData,
            ],
          },
          ...data.slice(indexofComponent + 1),
        ],
        resError: null,
      }));
    }
  };

  removeBatchConsumption = (
    componentData,
    consumptionData,
    consumptionDataIndex
  ) => {
    let { data } = this.state;

    let indexofComponent = data.findIndex(
      (ele) => ele.BomComponentCode === componentData.BomComponentCode
    );

    // selectedItems.pop(consumptionData.componentBatchNumber);

    let newData = [
      ...data.slice(0, indexofComponent),
      {
        ...data[indexofComponent],
        consumptionBatchNumbers: [
          //TO DO: change the slice approach to proper array removal method
          ...data[indexofComponent].consumptionBatchNumbers.slice(
            0,
            consumptionDataIndex
          ),
          ...data[indexofComponent].consumptionBatchNumbers.slice(
            consumptionDataIndex + 1
          ),
        ],
      },
      ...data.slice(indexofComponent + 1),
    ];
    this.setState({
      data: newData,
      resError: null,
    });
  };

  handleChange = (event, componentData, consumptionDataIndex) => {
    let { name, value } = event.target;
    let { data } = this.state;

    if (value < 0) {
      alert('invalid quantity');
    } else {
      let indexofComponent = data.findIndex(
        (ele) => ele.BomComponentCode === componentData.BomComponentCode
      );

      this.setState(({ data }) => ({
        data: [
          ...data.slice(0, indexofComponent),
          {
            ...data[indexofComponent],
            consumptionBatchNumbers: [
              ...data[indexofComponent].consumptionBatchNumbers.slice(
                0,
                consumptionDataIndex
              ),
              {
                ...data[indexofComponent].consumptionBatchNumbers[
                  consumptionDataIndex
                ],
                [name]: parseFloat(value),
              },
              ...data[indexofComponent].consumptionBatchNumbers.slice(
                consumptionDataIndex + 1
              ),
            ],
          },
          ...data.slice(indexofComponent + 1),
        ],
        error: false,
        resError: null,
      }));
    }
  };

  handleSubmit = () => {
    let { data } = this.state;
    let batchNumberFilled = true;
    let testingArray = [];
    let duplicateVariable = false;

    for (let i = 0; i < data.length; i++) {
      // eslint-disable-next-line
      data[i].consumptionBatchNumbers.forEach((details) => {
        if (
          details.BomComponentConsumedQuantity === '' ||
          details.BomComponentConsumedQuantity === ' ' ||
          isNaN(details.BomComponentConsumedQuantity)
        ) {
          details.BomComponentConsumedQuantity = 0;
        } else if (
          (details.BomComponentConsumedQuantity > 0 &&
            details.componentBatchNumber === '') ||
          (details.BomComponentConsumedQuantity > 0 &&
            details.componentBatchNumber === ' ')
        ) {
          this.setState({
            resError:
              'Please select batch number for respective consumed quantity',
          });
          batchNumberFilled = false;
          this.scrollToTop();
        }
      });
      if (!data[i].remarks) {
        data[i].remarks = '';
      }
      if (!data[i].remarksBO) {
        data[i].remarksBO = '';
      }
      this.setState({ data });
    }

    for (let i = 0; i < data.length; i++) {
      data[i].consumptionBatchNumbers.forEach((details) => {
        if (details.componentBatchNumber !== '') {
          testingArray.push(details.componentBatchNumber);
        } else {
          console.log('push not happening');
        }
      });
    }

    function checkForDuplicates(array) {
      return new Set(array).size !== array.length;
    }

    if (!checkForDuplicates(testingArray)) {
      // this.setState({
      //   resError: 'passed',
      // });
      duplicateVariable = false;
    } else if (batchNumberFilled) {
      duplicateVariable = true;
      this.setState({
        resError:
          'Same Batch Number has been selected for different consumption quantity',
      });
      this.scrollToTop();
    }

    let apiData = {
      bomConsumptionDetails: [
        {
          vendorMosymphonyID: this.props.batchDetails.vendorMosymphonyID,
          vendorBatchNumber: this.state.batchData.vendorBatchNumber,
          schrockenDeliveryScheduleBatchID:
            this.props.schrockenDeliveryScheduleBatchID,
          schrockenDeliveryScheduleID: this.props.schrockenDeliveryScheduleID,
          BOMConsumptionDetails: this.state.data,
        },
      ],
    };

    if (batchNumberFilled === true && duplicateVariable === false) {
      this.props.updateConsumption(apiData);
      //console.log('call', apiData);
    } else {
      console.log('consumption updating error in UI');
    }
  };

  formatConsumptionData = (data) => {
    let consumptionData = [
      {
        componentBatchNumber: '',
        BomComponentConsumedQuantity: '',
        inventoryBatchNumber: '',
        materialCode: '',
        valuatedUnrestrictedUsedStock: '',
        inventoryExpiryDate: '',
      },
    ];

    for (let i = 0; i < data.length; i++) {
      if (
        !data[i].consumptionBatchNumbers ||
        data[i].consumptionBatchNumbers.length === 0
      ) {
        data[i]['consumptionBatchNumbers'] = consumptionData;
      }
    }
    this.setState({ data });
  };
  handleChangeSelect = (dropDownValue, componentData, consumptionDataIndex) => {
    let { data } = this.state;
    let indexofComponent = data.findIndex(
      (ele) => ele.BomComponentCode === componentData.BomComponentCode
    );

    if (dropDownValue) {
      // selectedItems.push(dropDownValue.inventoryBatchNumber);
      this.setState(({ data }) => ({
        data: [
          ...data.slice(0, indexofComponent),
          {
            ...data[indexofComponent],
            consumptionBatchNumbers: [
              ...data[indexofComponent].consumptionBatchNumbers.slice(
                0,
                consumptionDataIndex
              ),
              {
                ...data[indexofComponent].consumptionBatchNumbers[
                  consumptionDataIndex
                ],
                componentBatchNumber: dropDownValue.inventoryBatchNumber,
                materialCode: dropDownValue.materialCode,
                valuatedUnrestrictedUsedStock:
                  dropDownValue.valuatedUnrestrictedUsedStock,
                inventoryExpiryDate: dropDownValue.inventoryExpiryDate,
              },
              ...data[indexofComponent].consumptionBatchNumbers.slice(
                consumptionDataIndex + 1
              ),
            ],
          },
          ...data.slice(indexofComponent + 1),
        ],
        error: false,

        resError: null,
      }));
    }
  };

  /* Need this func to disable few options based on a condition */

  // disbaleAlreadySelectedItems = (disbaledValue) => {
  //   let { selectedItems } = this.state;
  //   return selectedItems.some((x) => x === disbaledValue);
  // };
  handleRemarks = (event, index) => {
    const { data } = this.state;
    data[index]['remarks'] = event.target.value;
    this.setState({ data: data });
  };

  handleBORemarks = (event, index) => {
    const { data } = this.state;
    data[index]['remarksBO'] = event.target.value;
    this.setState({ data: data });
  };
  render() {
    const { handleClose, classes } = this.props;
    const {
      data,
      loading,
      batchData,
      isUpdateInfoDialog,
      resError,
      // error,
      bomData,
      thresholdData,
    } = this.state;

    const { SASLBatchStatusID } = this.props.batchDetails;

    return (
      <React.Fragment>
        <Grid container justify='center' className={classes.contain}>
          <Grid item xs={12} sm={12} md={12} className={classes.mainGrid}>
            <Grid
              ref={(el) => {
                this.el = el;
              }}
              item
              xs={12}
              sm={4}
              md={2}
              className={classes.grid}
            >
              <Typography variant='body2' className={classes.font}>
                Batch Number
              </Typography>

              <Typography variant='body2' className={classes.fontPadding}>
                {presentFormatedData(batchData.vendorBatchNumber)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4} md={2} className={classes.grid}>
              <Typography variant='body2' className={classes.font}>
                Batch Size
              </Typography>

              <Typography variant='body2' className={classes.fontPadding}>
                {presentFormatedData(batchData.batchSize)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4} md={2} className={classes.grid}>
              <Typography variant='body2' className={classes.font}>
                Standard Batch Size
              </Typography>

              <Typography variant='body2' className={classes.fontPadding}>
                {bomData.fgRefQuantity !== undefined
                  ? presentFormatedData(bomData.fgRefQuantity)
                  : '--'}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4} md={4} className={classes.grid}>
              <Typography variant='body2' className={classes.font}>
                Material Code /Description
              </Typography>

              <Typography variant='body2' className={classes.fontPadding}>
                {presentFormatedData(batchData.fgMaterialCode)} /{' '}
                {presentFormatedData(batchData.fgMaterialName)}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            justify='center'
            style={{
              padding: '1rem 0',
              display: resError === null ? 'none' : 'inherit',
            }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant='h6'
                style={{
                  border: '1px solid #FF0000',
                  backgroundColor: '#FFF0F0',
                  opacity: '1',
                  textAlign: 'center',
                  padding: '0.8rem',
                  fontSize: '0.875rem',
                }}
              >
                {resError}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} className={classes.grid}>
            <Typography variant='h6' className={classes.font}>
              Material Consumption Details
            </Typography>
            <span className={classes.borders}></span>
          </Grid>

          <React.Fragment>
            <Backdrop />
            {loading === true ? (
              <Grid container justify='center'>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '10%',
                    minWidth: '60vw',
                    paddingBottom: '8rem',
                  }}
                >
                  <CircularProgress color='primary' thickness={7} />
                </div>
              </Grid>
            ) : (
              <TableContainer component={Paper} style={{ overflowY: 'hidden' }}>
                <Table className={classes.table} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Material Number</TableCell>
                      <TableCell>Material Short Text</TableCell>
                      <TableCell>UOM</TableCell>
                      <TableCell align='right'>Standard Quantity</TableCell>
                      <TableCell align='right'>
                        <p>
                          Standard Quantity <span>(As per Batch Size)</span>
                        </p>
                      </TableCell>
                      <TableCell>Enter Consumed Quantity</TableCell>
                      <TableCell>Batch Number </TableCell>
                      <TableCell align='right'>Variance (&#37;)</TableCell>
                      <TableCell>CMO Remarks</TableCell>
                      <TableCell>BO Remarks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, bomIndex) => {
                      let refQty = getRefQty({
                        materialCode: row.BomComponentCode,
                        bomData: bomData.bomComponentDetails,
                      });

                      let stdQty = getStdQty(
                        batchData.batchSize,
                        presentFormatedData(bomData.fgRefQuantity),
                        refQty
                      );

                      let variance = getVariance(
                        row.consumptionBatchNumbers,
                        stdQty
                      );

                      //let isVarianceAlert = false
                      let variancePercentage = Math.abs(
                        getVariancePercentage(variance, stdQty)
                      );

                      let materialThreshold = getThreshold({
                        materialCode: row.BomComponentCode,
                        series: thresholdData,
                      });
                      // let stockValue = getStockValue(
                      //   row.consumptionBatchNumbers,
                      //   row.inventoryBatches
                      // );
                      let expiredDate = getExpiredDate(
                        row.consumptionBatchNumbers,
                        row.inventoryBatches
                      );

                      let currentDate = new Date().toISOString();

                      return (
                        <React.Fragment key={`consum-row-${bomIndex}`}>
                          <TableRow
                            key={`consum-row-${bomIndex}`}
                            className={
                              variancePercentage > materialThreshold
                                ? classes.rowText
                                : {}
                            }
                          >
                            <TableCell>{row.BomComponentCode}</TableCell>
                            <TableCell>{row.BomComponentName}</TableCell>

                            <TableCell>{row.BomComponentRefUOM}</TableCell>

                            <TableCell align='right'>
                              {presentFormatedData(refQty, 'NA')}
                            </TableCell>
                            <TableCell align='right'>
                              {presentFormatedData(stdQty, 'NA')}
                            </TableCell>
                            <TableCell>
                              {row.consumptionBatchNumbers.map(
                                (consumptionData, index) => {
                                  let stockValue = getStockValue(
                                    consumptionData,
                                    row.inventoryBatches
                                  );
                                  return (
                                    <div
                                      key={`consum-${index}`}
                                      className={classes.tdConsumption}
                                    >
                                      {localStorage.collabType === 'CMO' &&
                                      (SASLBatchStatusID < 6 ||
                                        SASLBatchStatusID === 13 ||
                                        SASLBatchStatusID === 15) ? (
                                        <InputNumber
                                          onWheel={(e) => e.target.blur()}
                                          className={classes.warningStyles}
                                          name='BomComponentConsumedQuantity'
                                          value={
                                            consumptionData.BomComponentConsumedQuantity
                                          }
                                          placeholder='Consumed Quantity'
                                          onChange={(event) =>
                                            this.handleChange(event, row, index)
                                          }
                                          helperText={
                                            consumptionData.BomComponentConsumedQuantity >
                                            stockValue
                                              ? 'Consumed quantity exceeds the available stock '
                                              : ''
                                          }
                                          classes={{
                                            root: classes.inputPlaceholder,
                                          }}
                                        />
                                      ) : (
                                        <>
                                          {
                                            consumptionData.BomComponentConsumedQuantity
                                          }
                                        </>
                                      )}
                                    </div>
                                  );
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              {row.consumptionBatchNumbers.map(
                                (consumptionData, index) => (
                                  <div
                                    key={`Autocomplete-${index}`}
                                    className={clsx(
                                      classes.tdConsumption,
                                      classes.tdAuto
                                    )}
                                  >
                                    {localStorage.collabType === 'CMO' &&
                                    (SASLBatchStatusID < 6 ||
                                      SASLBatchStatusID === 13 ||
                                      SASLBatchStatusID === 15) ? (
                                      <Autocomplete
                                        disableClearable
                                        size='small'
                                        value={consumptionData}
                                        options={
                                          row.inventoryBatches.length > 0
                                            ? [
                                                {
                                                  inventoryBatchNumber:
                                                    'Batch No.',
                                                  inventoryExpiryDate:
                                                    'Exp Date',
                                                  valuatedUnrestrictedUsedStock:
                                                    'Stock',
                                                },
                                                ...row.inventoryBatches,
                                              ]
                                            : []
                                        }
                                        PopperComponent={PopperAutoSelect}
                                        className={classes.autoComplete}
                                        getOptionLabel={(option) =>
                                          option.inventoryBatchNumber
                                            ? option.inventoryBatchNumber
                                            : option.componentBatchNumber
                                        }
                                        getOptionSelected={(option, value) =>
                                          option.inventoryBatchNumber ===
                                          value.componentBatchNumber
                                        }
                                        getOptionDisabled={(option) =>
                                          option.inventoryBatchNumber ===
                                          'Batch No.'
                                        }
                                        noOptionsText='No Batch Numbers Available'
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label='Select Batch From Stock'
                                            variant='outlined'
                                            className={classes.warningStyles}
                                            helperText={
                                              currentDate >= expiredDate
                                                ? 'Expired Batch '
                                                : ''
                                            }
                                            InputLabelProps={{
                                              style: {
                                                fontSize: '0.875rem',
                                              },
                                            }}
                                          />
                                        )}
                                        renderOption={(option) => (
                                          <Grid container>
                                            <Grid
                                              item
                                              xs={5}
                                              className={classes.wrapText}
                                            >
                                              {option.inventoryBatchNumber}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={4}
                                              className={classes.wrapText}
                                            >
                                              {
                                                option.valuatedUnrestrictedUsedStock
                                              }
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              className={classes.wrapText}
                                            >
                                              {presentDate(
                                                option.inventoryExpiryDate,
                                                'Expiry Date'
                                              )}
                                            </Grid>
                                          </Grid>
                                        )}
                                        onChange={(event, newValue) => {
                                          this.handleChangeSelect(
                                            newValue,
                                            row,
                                            index
                                          );
                                        }}
                                      />
                                    ) : (
                                      <>
                                        {consumptionData.componentBatchNumber}
                                      </>
                                    )}
                                    <div
                                      title={
                                        index === 0
                                          ? 'Add Batch'
                                          : 'Remove Batch'
                                      }
                                      placement='right-start'
                                    >
                                      {index === 0
                                        ? ((localStorage.collabType === 'CMO' &&
                                            SASLBatchStatusID < 6) ||
                                            SASLBatchStatusID === 13) && (
                                            <Fab
                                              style={{ left: '1rem' }}
                                              size='small'
                                              fontSize='small'
                                              color='secondary'
                                              aria-label='Add new batch number'
                                              onClick={() =>
                                                this.addCustomRow(row)
                                              }
                                            >
                                              <AddIcon />
                                            </Fab>
                                          )
                                        : ((localStorage.collabType === 'CMO' &&
                                            SASLBatchStatusID < 6) ||
                                            SASLBatchStatusID === 13) && (
                                            <div
                                              style={{ paddingLeft: '1rem' }}
                                            >
                                              <ClearSharpIcon
                                                fontSize='small'
                                                color='error'
                                                className={classes.icon}
                                                onClick={() =>
                                                  this.removeBatchConsumption(
                                                    row,
                                                    consumptionData,
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                    </div>
                                  </div>
                                )
                              )}
                            </TableCell>

                            <TableCell align='right'>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <Typography
                                  variant='body2'

                                  // color={variance < 0 ? 'error' : 'inherit'}
                                >
                                  {isNaN(variance) ? 'NA' : Math.abs(variance)}
                                </Typography>
                                {!isNaN(variance) && variance !== 0 && (
                                  <Typography
                                    variant='body2'
                                    style={{ display: 'inherit' }}
                                  >
                                    &nbsp;
                                    {`(${Math.abs(
                                      getVariancePercentage(variance, stdQty)
                                    )}%)`}
                                    {variance > 0 ? (
                                      <ArrowDropDownIcon
                                        fontSize='small'
                                        color='error'
                                      />
                                    ) : (
                                      <ArrowDropUpIcon
                                        fontSize='small'
                                        color='error'
                                      />
                                    )}
                                  </Typography>
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              {(localStorage.collabType === 'CMO' &&
                                SASLBatchStatusID < 6) ||
                              (localStorage.collabType === 'CMO' &&
                                SASLBatchStatusID === 13) ? (
                                <InputNumber
                                  name='remarks'
                                  type='text'
                                  value={row.remarks || ''}
                                  placeholder='Add Remarks'
                                  onChange={(event) => {
                                    if (event.target.value.length < 100) {
                                      this.handleRemarks(event, bomIndex);
                                    }
                                  }}
                                  classes={{
                                    root: classes.inputPlaceholder,
                                  }}
                                  multiline
                                />
                              ) : (
                                <LongTextDisplay
                                  limit={50}
                                  text={row.remarks || ''}
                                  style={{
                                    width: 100,
                                    whiteSpace: 'break-spaces',
                                    wordBreak: 'break-word',
                                  }}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {
                                //     (localStorage.collabType === 'CMO' &&
                                //   SASLBatchStatusID < 6) ||
                                // (localStorage.collabType === 'CMO' &&
                                //   SASLBatchStatusID === 13)
                                localStorage.collabType === 'Pharma Co' &&
                                SASLBatchStatusID === 6 &&
                                this.props.fgBatchFeatures
                                  ?.updateBOMConsumptionRemarks ? (
                                  <InputNumber
                                    name='remarksBO'
                                    type='text'
                                    value={row.remarksBO || ''}
                                    placeholder='Add Remarks'
                                    onChange={(event) => {
                                      if (event.target.value.length < 100) {
                                        this.handleBORemarks(event, bomIndex);
                                      }
                                    }}
                                    classes={{
                                      root: classes.inputPlaceholder,
                                    }}
                                    multiline
                                  />
                                ) : (
                                  <LongTextDisplay
                                    limit={50}
                                    text={row.remarksBO || ''}
                                    style={{
                                      width: 100,
                                      whiteSpace: 'break-spaces',
                                      wordBreak: 'break-word',
                                    }}
                                  />
                                )
                              }
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </React.Fragment>
        </Grid>

        {(localStorage.collabType === 'CMO' &&
          (SASLBatchStatusID < 6 ||
            SASLBatchStatusID === 13 ||
            SASLBatchStatusID === 15)) ||
        (localStorage.collabType === 'Pharma Co' && SASLBatchStatusID === 6) ? (
          <Grid
            container
            style={{
              paddingLeft: '2rem',
              paddingTop: '2rem',
              paddingBottom: '2rem',
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <StyledButton onClick={this.handleSubmit}>
                Save Changes
              </StyledButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container justify='center'>
            <Grid
              item
              row
              xs={4}
              sm={4}
              md={2}
              style={{
                textAlign: 'center',
                padding: '3rem',
              }}
            >
              <StyledButton onClick={handleClose} fullWidth>
                Close
              </StyledButton>
            </Grid>
          </Grid>
        )}

        <Dialog open={isUpdateInfoDialog} fullWidth>
          <StyledDialog
            icons={true}
            handleClose={this.handleCloseDialog}
            title='Details Saved'
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  consumptionDetails: state.pcmp.common.sasl.consumptionInfo,
  updateBOM: state.pcmp.common.sasl.updateBOM,

  backDropLoading: state.pcmp.common.files.backdropLoading,
  viewBOM: state.pcmp.common.sasl.viewBOM,
  thresholdValue: state.pcmp.common.sasl.fetchVarianceThreshold,
});

export default withStyles(useStyles)(
  connect(mapStateToProps, {
    fetchBOMConsumptionDetails,
    updateConsumption,
    fetchBatchDetails,
    fetchDSBatchDetails,
    fetchBOMDetails,
    loadTableData,
    fetchVarianceThresholdDetails,
  })(UpdateConsumptionInfo)
);
