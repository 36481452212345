/* eslint-disable no-console */
/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Harish D
 * -----
 * File Description: Table that shows Line Item Batch details
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import {
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  TextField,
  IconButton,
  Tooltip,
  Grid,
  TableFooter,
  Dialog,
  Button,
} from '@material-ui/core';

import TablePagination from '@material-ui/core/TablePagination';

import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SearchIcon from '@material-ui/icons/Search';

// import Header from '../../../../common/sasl/models/Header';
// import AddNewRecord from '../../../addNewBatch/AddNewRecord';

import orderBy from 'lodash/orderBy';

import pgactions from '../../../../common/functionalComponents/PageActions';

import Moment from 'moment';

import { tableFilter } from '../../../../common/functionalComponents/TableFilter';

import { style } from '../../../../../resources/css/styles';

import { cmoFetchLineBatchItems } from '../../../../../containers/actions/cmo/addBatchItemActions';

import { getLineItemDetails } from '../../../../../containers/actions/pharmaCo/purchaseOrderActions';

import AddBatchItem from '../batchItems/addBatchItem';
import ViewEditBatch from '../../../batchItems/viewEditBatch';
// import Slide from '@material-ui/core/Slide';

const tableDataFilter = tableFilter;
const TablePaginationActionsWrapped = pgactions;

const styles = style;

const invertDirection = {
  asc: 'desc',
  desc: 'asc',
};

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction='down' ref={ref} {...props} />;
// });

const header = [
  { name: 'Status', id: 'statusText', align: 'left' },
  { name: 'Batch', id: 'batchNumber', align: 'center' },
  { name: 'Line Item', id: 'lineNumber', align: 'center' },
  { name: 'PO Number', id: 'purchaseOrderNumber', align: 'center' },
  { name: 'Product Name', id: 'productName', align: 'center' },
  { name: 'Product Code', id: 'productCode', align: 'center' },
  { name: 'Batch Size', id: 'batchSize', align: 'left' },
  { name: 'Produced Quantity', id: 'producedQuantity', align: 'left' },
  { name: 'Pending Quantity', id: 'pendingQuantity', align: 'left' },
  { name: 'Start Date', id: 'batchStartDate', align: 'right' },
  { name: 'End Date', id: 'batchEndDate', align: 'right' },
  { name: 'MRP', id: 'maximumRetailPrice', align: 'right' },
];

class LineItemBatchTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      header,
      displayFilter: false,
      query: '',
      columnToQuery: 'all',
      columnToSort: '',
      sortDirection: 'desc',
      fieldSelected: false,
      page: 0,
      rowsPerPage: 3,
      lineItemStatus: 0,
      batchItem: {},
      openBatchDialog: false,
      openViewBatchItemDialog: false,
      loading: true,
      openAddBatch: false,
    };
  }

  componentDidMount() {
    this.getBatchItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.lineBatchItems !== this.props.lineBatchItems) {
      this.setState({ data: nextProps.lineBatchItems, loading: false });
    }
    if (nextProps.lineItemStatus) {
      this.setState({ lineItemStatus: nextProps.lineItemStatus.statusCode });
    }
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      fieldSelected: false,
    });

    if (this.state.columnToQuery === 'none') {
      if (event.target.name === 'query') {
        this.setState({ fieldSelected: true });
      }
    }
  };

  // show search fields
  showFilter = () => {
    this.setState({
      displayFilter: !this.state.displayFilter,
      columnToQuery: 'all',
      query: '',
    });
  };

  handleCloseBatch = () => {
    this.setState({
      openAddBatch: false,
    });
  };

  // handle sort
  handleSort = (columName) => {
    this.setState({
      columnToSort: columName,
      sortDirection:
        this.state.columnToSort === columName
          ? invertDirection[this.state.sortDirection]
          : 'asc',
    });
  };

  // refresh
  getBatchItems = () => {
    let getdata = { ...this.props.match.params, collabType: 'CMO' };
    this.setState({ loading: true });
    this.props.cmoFetchLineBatchItems(getdata);
  };

  // pagination
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    this.setState({ rowsPerPage: value });
  };

  openBatchDialog = () => {
    this.setState({ openAddBatch: true });

    // this.setState({ openBatchDialog: true });
  };

  toggleBatchScreen = () => {
    this.setState({ openAddBatch: false });

    // this.setState({ openBatchDialog: false });
  };

  openViewEditBatchItemDialog = (ele) => {
    this.setState({ openViewBatchItemDialog: true, batchItem: ele });
  };

  toggleViewBatchScreen = () => {
    this.setState({ openViewBatchItemDialog: false });
  };

  onRefresh = () => {
    this.getBatchItems();
    this.setState({ columnToSort: '' });
  };
  // call back after batch update
  callBackAPIFunction = () => {
    let data = { ...this.props.match.params, collabType: 'CMO' };
    this.getBatchItems();
    this.props.getLineItemDetails(data);
  };

  render() {
    const { classes } = this.props;
    const { header, data, loading } = this.state;

    const { rowsPerPage, page } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    const queryData = orderBy(
      this.state.query
        ? data.filter((data) =>
            tableDataFilter(
              data,
              this.state.columnToQuery,
              this.state.query.toLowerCase(),
              this.state.header
            )
          )
        : data,
      this.state.columnToSort,
      this.state.sortDirection
    );

    const loadingRow = (
      <TableBody>
        <TableRow>
          <TableCell colSpan={header.length} style={{ textAlign: 'center' }}>
            Loading...
          </TableCell>
        </TableRow>
      </TableBody>
    );

    const noRecords = (
      <TableRow>
        <TableCell colSpan={header.length} align='center'>
          No Records Found
        </TableCell>
      </TableRow>
    );

    return (
      <Grid item xs={12} md={12} className={classes.membersTable}>
        <Paper className={classes.root3}>
          <Toolbar>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    marginRight: '0.75rem',
                  }}
                >
                  Batch Information (Batch Line Items)
                </Typography>
                {/* {addBatchButton} */}
                <Button
                  variant='contained'
                  color='primary'
                  disabled={this.state.lineItemStatus > 2 ? false : true}
                  style={{ fontSize: '0.75rem' }}
                  onClick={this.openBatchDialog}
                >
                  Add Batch Line Item
                </Button>
              </div>
              {this.state.displayFilter && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Select
                    native
                    style={{ marginLeft: '1em', marginRight: '1.5rem' }}
                    value={this.state.columnToQuery}
                    name='columnToQuery'
                    onChange={this.handleChange}
                    error={this.state.fieldSelected}
                    className={classes.fontColor}
                  >
                    <option value='all' className={classes.fontColor}>
                      All
                    </option>
                    {header.map((ele, x) => (
                      <option
                        key={`opt-${x}`}
                        value={ele.id}
                        className={classes.fontColor}
                      >
                        {ele.name}
                      </option>
                    ))}
                  </Select>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />

                    <TextField
                      name='query'
                      placeholder='Search... '
                      value={this.state.query}
                      onChange={this.handleChange}
                      // className={classes.fontColor}
                      InputProps={{
                        classes: {
                          input: classes.resize,
                        },
                      }}
                    />
                  </div>
                </div>
              )}

              <Tooltip title='Filter List'>
                <IconButton aria-label='Filter list' onClick={this.showFilter}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </div>
            <IconButton aria-label='Refresh list' onClick={this.onRefresh}>
              <RefreshIcon />
            </IconButton>
          </Toolbar>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {header.map((data, i) => (
                  <TableCell key={i} align={data.align}>
                    <Tooltip title='sort'>
                      <div
                        onClick={() => this.handleSort(data.id)}
                        style={{
                          display: 'flex',
                          // flexDirection: "row",
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <span>{data.name}</span>
                        {this.state.columnToSort === data.id ? (
                          this.state.sortDirection === 'asc' ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </div>
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              loadingRow
            ) : (
              <TableBody>
                {queryData.length > 0
                  ? queryData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((line, i) => (
                        <TableRow
                          key={`row-${i}`}
                          className={classes.tableRowHover}
                          onClick={() => this.openViewEditBatchItemDialog(line)}
                        >
                          <TableCell align='left'>{line.statusText}</TableCell>
                          <TableCell align='center'>
                            {line.batchNumber}
                          </TableCell>
                          <TableCell align='center'>
                            {line.lineNumber}
                          </TableCell>
                          <TableCell align='center'>
                            {line.purchaseOrderNumber}
                          </TableCell>
                          <TableCell align='center'>
                            {line.productName}
                          </TableCell>
                          <TableCell align='center'>
                            {line.productCode}
                          </TableCell>
                          {/* <TableCell align="left">{line.CMOPlantCode}</TableCell> */}
                          <TableCell align='right'>{line.batchSize}</TableCell>
                          <TableCell align='left'>
                            {line.producedQuantity}
                          </TableCell>
                          <TableCell align='left'>
                            {line.pendingQuantity}
                          </TableCell>
                          <TableCell align='right'>
                            {Moment(new Date(line.batchStartDate)).format(
                              dateFormat
                            )}
                          </TableCell>
                          <TableCell align='right'>
                            {Moment(new Date(line.batchEndDate)).format(
                              dateFormat
                            )}
                          </TableCell>
                          <TableCell align='right'>
                            {line.maximumRetailPrice}
                          </TableCell>
                        </TableRow>
                      ))
                  : noRecords}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={9} />
                  </TableRow>
                )}
              </TableBody>
            )}
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[3, 5, 10, 15]}
                  colSpan={header.length + 1}
                  count={queryData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                  classes={{
                    spacer: classes.TablePaginationAlignleft,
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
        <Dialog fullScreen open={this.state.openAddBatch}>
          <AddBatchItem
            toggleBatchScreen={this.toggleBatchScreen}
            params={this.props.match.params}
          />
        </Dialog>

        {/* <Dialog
          fullScreen
          open={this.state.openAddBatch}
          onClose={this.handleCloseBatch}
          TransitionComponent={Transition}
        >
          <Header
            handleClose={this.handleCloseBatch}
            title='Add New Batch'
            padding={true}
          >
            <AddNewRecord toggleBatchScreen={this.handleCloseBatch} />
          </Header>
        </Dialog> */}

        <Dialog fullScreen open={this.state.openViewBatchItemDialog}>
          <ViewEditBatch
            toggleViewBatchScreen={this.toggleViewBatchScreen}
            params={this.props.match.params}
            batchItemDetails={this.state.batchItem}
            collabType='CMO'
            callBackAPIFunction={this.callBackAPIFunction}
          />
        </Dialog>
      </Grid>
    );
  }
}

LineItemBatchTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  lineBatchItems: state.pcmp.cmo.batchItems.lineBatchItems,
  lineItemStatus: state.pcmp.pharmaCo.purchaseOrder.lineItemDetails,
});
export default withStyles(styles)(
  connect(mapStatetoProps, {
    cmoFetchLineBatchItems,
    getLineItemDetails,
  })(withRouter(LineItemBatchTable))
);
