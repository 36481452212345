import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
//files
import UploadNewRecord from './NewRecord';
import NewHeader from '../models/NewHeader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const UploadRecords = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };
  React.useEffect(() => {
    if (props.open === true) {
      setOpen(true);
    }
  }, [props, open]);

  let title = props.isUploadRMPMAvailability
    ? 'Upload RM/PM Inventory'
    : props.isUploadRMPMGRN
    ? 'Upload New Record'
    : props.isUploadDSBatch
    ? 'Upload New Batch'
    : 'Upload Consumption Information';
  let subTitleText = props.isUploadRMPMAvailability
    ? 'Add/Update RM/PM Inventory through file'
    : props.isUploadRMPMGRN
    ? 'Raw Material (RM) / Packaging Material (PM) Record'
    : props.isUploadDSBatch
    ? 'Add/Update Batches through file'
    : 'Add/Update Consumption through file';
  return (
    <Dialog
      fullScreen
      open={open} //change to false
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <NewHeader
        handleClose={handleClose}
        title={title}
        subtitle={subTitleText}
        padding={true}
      >
        <UploadNewRecord
          handleClose={handleClose}
          isUploadRMPMGRN={props.isUploadRMPMGRN}
          isUploadDSBatch={props.isUploadDSBatch}
          isUploadConsumptionInfo={props.isUploadConsumptionInfo}
          isUploadRMPMAvailability={props.isUploadRMPMAvailability}
          refreshFunc={props.refreshFunc}
        />
      </NewHeader>
    </Dialog>
  );
};
export default UploadRecords;
