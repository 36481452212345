import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const CMOSelector = (props) => {
  const { CMOMember, handleChange, cmoMembersList } = props;
  return (
    <Select
      fullWidth={props.needFullWidth ? props.needFullWidth : false}
      name='CMOMember'
      value={CMOMember} 
      onChange={handleChange}
    >
      <MenuItem value='all'>All</MenuItem>
      {cmoMembersList.map((cmoMember, i) => (
        <MenuItem key={i} value={cmoMember}>
          {cmoMember}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CMOSelector;
