import React from 'react';
import ApproveButton from '../models/buttons/Approve';
import RecievedButton from '../models/buttons/Received';
import ResolveButton from '../models/buttons/Resolve';
import UpdateButton from '../models/buttons/Update';
import UploadFilesButton from '../models/buttons/UploadFiles';

const StatusManager = (status, POLineRecordDetails, row, grnFeatures) => {
  const { collabType } = localStorage;

  if (collabType === 'Pharma Co') {
    if (
      (status === 3 && grnFeatures.approveGr === true) ||
      (status === 13 && grnFeatures.aproveQCQA === true) ||
      (status === 15 && grnFeatures.approveGr === true)
    ) {
      return (
        <ApproveButton
          POLineRecordDetails={POLineRecordDetails}
          row={row}
          status={status}
        />
      );
    }
    return null;
  } else {
    switch (status) {
      case 1:
        if (grnFeatures.receiveGrRecord === true) {
          return (
            <RecievedButton
              POLineRecordDetails={POLineRecordDetails}
              row={row}
              status={status}
            />
          );
        }
        return null;
      case 2:
        if (grnFeatures.updateGrRecord === true) {
          return (
            <UpdateButton
              POLineRecordDetails={POLineRecordDetails}
              row={row}
              status={status}
            />
          );
        }
        return null;
      case 3:
      case 13:
        if (grnFeatures.uploadFilesStatus === true) {
          return (
            <UploadFilesButton
              POLineRecordDetails={POLineRecordDetails}
              row={row}
              status={status}
            />
          );
        }
        return null;
      case 4:
      case 12:
        if (grnFeatures.resolveGrStatus === true) {
          return (
            <ResolveButton
              POLineRecordDetails={POLineRecordDetails}
              row={row}
              status={status}
            />
          );
        }
        return null;
      default:
        return null;
    }
  }
};

export default StatusManager;
