export const QEHeaders = [
  {
    id: 'referenceID',
    numeric: true,
    disablePadding: false,
    label: 'Reference ID',
    varName: 'referenceID ',
    align: 'center',
    width: '5rem',
    paddingTop: '0.7rem',
    paddingLeft: 0,
    paddingRight: 0,
    isSortEnable: true,
  },
  {
    id: 'eventType',
    numeric: true,
    disablePadding: false,
    label: 'Event  Type',
    varName: 'eventType',
    align: 'center',
    // width: "3rem",
    fontWeight: '800',
    paddingTop: '0.7rem',
    paddingLeft: 0,
    paddingRight: 0,
    isSortEnable: true,
    width: '3rem',
  },
  {
    id: 'dateReported',
    numeric: true,
    disablePadding: false,
    label: 'Date Reported',
    varName: 'dateReported',
    align: 'center',
    width: '4rem',
    paddingLeft: 0,
    paddingRight: 0,
    isSortEnable: true,
  },
  // {
  //   id: 'incidentDate',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Incident Date',
  //   align: 'left',
  //   varName: 'incidentDate',
  //   // width: '0%',
  //   isSortEnable: true,
  // },
  {
    id: 'priority',
    numeric: true,
    disablePadding: false,
    label: 'Priority',
    varName: 'priority',
    align: 'center',
    width: '3rem',
    paddingTop: '0.7rem',
    paddingLeft: 0,
    paddingRight: 0,
    isSortEnable: true,
  },

  {
    id: 'productCode',
    numeric: true,
    disablePadding: false,
    label: ' Product Code/Description',
    varName: 'productCode',
    align: 'center',
    width: '5rem',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: '0.7rem',
    fontWeight: 600,
    isSortEnable: true,
  },

  {
    id: 'batchAffected',
    numeric: false,
    disablePadding: false,
    label: 'Batch(s) Affected',
    varName: 'batchAffected',
    align: 'center',
    width: '6rem',
    fontWeight: 600,
    // width: '4%',
    isSortEnable: true,
  },
  // {
  //   id: 'raisedBy',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Raised By',
  //   varName: 'raisedBy',
  //   align: 'left',
  //   // width: '3%',
  //   isSortEnable: true,
  // },
  {
    id: 'assignedTo',
    numeric: true,
    disablePadding: false,
    label: 'Assigned To',
    varName: 'assignedTo',
    align: 'center',
    width: '5rem',
    paddingTop: '0.7rem',
    paddingLeft: 0,
    paddingRight: 0,
    // width: '9%',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    varName: 'status',
    align: 'center',
    width: '3rem',
    paddingTop: '0.7rem',
    // width: '9%',
  },
  {
    id: 'estimatedDateOfClosure',
    numeric: true,
    disablePadding: false,
    label: 'Due Date',
    varName: 'estimatedDateOfClosure',
    width: '3rem',
    paddingTop: '0.7rem',
    align: 'center',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Actions',
    varName: 'actions',
    paddingTop: '0.7rem',
    width: '3rem',
    align: 'center',
  },
];

export const QEHeadersBO = [
  {
    id: 'incidentFor',
    numeric: true,
    //disablePadding: false,
    label: 'Incident For',
    varName: 'incidentFor ',
    align: 'center',
    width: '5rem',
    paddingTop: '0.7rem',
    fontWeight: 'bold',
    isSortEnable: true,
  },
  {
    id: 'referenceID',
    numeric: true,
    disablePadding: false,
    label: 'Reference ID',
    varName: 'referenceID ',
    align: 'center',
    width: '5rem',
    paddingTop: '0.7rem',
    paddingLeft: 0,
    paddingRight: 0,
    isSortEnable: true,
  },
  {
    id: 'eventType',
    numeric: true,
    disablePadding: false,
    label: 'Event Type',
    varName: 'eventType',
    align: 'center',
    width: '3rem',
    fontWeight: '800',
    paddingTop: '0.7rem',
    paddingLeft: 0,
    paddingRight: 0,
    isSortEnable: true,
  },
  {
    id: 'dateReported',
    numeric: true,
    disablePadding: false,
    label: 'Date   Reported',
    varName: 'dateReported',
    align: 'center',
    width: '4rem',
    paddingLeft: 0,
    paddingRight: 0,
    isSortEnable: true,
  },
  // {
  //   id: 'incidentDate',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Incident Date',
  //   align: 'left',
  //   varName: 'incidentDate',
  //   // width: '0%',
  //   isSortEnable: true,
  // },
  {
    id: 'priority',
    numeric: true,
    disablePadding: false,
    label: 'Priority',
    varName: 'priority',
    align: 'center',
    width: '3rem',
    paddingTop: '0.7rem',
    paddingLeft: 0,
    paddingRight: 0,
    isSortEnable: true,
  },

  {
    id: 'productCode',
    numeric: true,
    disablePadding: false,
    label: ' Product Code/Description',
    varName: 'productCode',
    align: 'center',
    width: '5rem',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: '0.7rem',
    fontWeight: 600,
    isSortEnable: true,
  },

  {
    id: 'batchAffected',
    numeric: false,
    disablePadding: false,
    label: 'Batch(s) Affected',
    varName: 'batchAffected',
    align: 'center',
    width: '6rem',
    fontWeight: 600,
    isSortEnable: true,
  },
  // {
  //   id: 'raisedBy',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Raised By',
  //   varName: 'raisedBy',
  //   align: 'left',
  //   // width: '3%',
  //   isSortEnable: true,
  // },
  {
    id: 'assignedTo',
    numeric: true,
    disablePadding: false,
    label: 'Assigned To',
    varName: 'assignedTo',
    align: 'center',
    width: '5rem',
    paddingTop: '0.7rem',
    paddingLeft: 0,
    paddingRight: 0,
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    varName: 'status',
    align: 'center',
    width: '3rem',
    paddingTop: '0.7rem',
  },
  {
    id: 'estimatedDateOfClosure',
    numeric: true,
    disablePadding: false,
    label: 'Due Date',
    varName: 'estimatedDateOfClosure',
    width: '3rem',
    paddingTop: '0.7rem',
    align: 'center',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Actions',
    varName: 'actions',
    paddingTop: '0.7rem',
    width: '3rem',
    align: 'center',
  },
];
