import React from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  accordion: {
    boxShadow: 'none',
    border: '0.1px solid #C6C6C6',
  },
}));

export default function RejectedRecordAccordionModel({ children, ...props }) {
  const classes = useStyles();

  const sortedRejectedRecords = props.validatedRecordData.invalidRecords.sort(
    (firstErr, secErr) => {
      if (firstErr.ROW_ID < secErr.ROW_ID) {
        return -1;
      }
      return 1;
    }
  );
  return (
    <React.Fragment>
      {/* accordion */}

      <Grid container justify='center' style={{ paddingBottom: '4rem' }}>
        <Grid item xs={12} sm={12} md={12}>
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2a-content'
              id='panel2a-header'
            >
              <Typography variant='body1' style={{ fontWeight: 500 }}>
                Rejected record(s)
              </Typography>
            </AccordionSummary>

            {sortedRejectedRecords.map((record) => {
              return (
                <AccordionDetails key={record.ROW_ID}>
                  <Typography variant='body2' style={{ display: 'flex' }}>
                    Row {record.ROW_ID} -
                  </Typography>
                  &nbsp;
                  <Typography variant='body2'>
                    {record.errMessage
                      ? record.errMessage
                      : record.reasonForRejection}
                  </Typography>
                </AccordionDetails>
              );
            })}
          </Accordion>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
