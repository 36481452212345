import React, { useState } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';
//

import UpdatePORecord from '../../common/supplierPOUpdate';

export const useStyles = makeStyles((theme) => ({
  multipleLogo: {
    border: '1px solid #7d7c7c',
    borderRadius: '4px',
    padding: '0.3rem',
    height: 'inherit',
    marginLeft: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
  list: {
    maxWidth: '80vw',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vw',
    },
  },
  fullList: {
    width: 'auto',
  },
  received: {
    textAlign: 'left',
  },
}));

const EditSupplierPO = (props) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <CreateIcon
        fontSize='small' 
        className={classes.multipleLogo}
        onClick={() => setOpen(!open)}
      />
    
        <UpdatePORecord 
           {...props} 
          open={open}
          handleClose={handleClose}
          isRecordDelete={true}
          saveRecord={props.saveRecord}
          />

    </React.Fragment>
  );
};

export default EditSupplierPO;
