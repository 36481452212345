import React, { useState } from 'react';

import moment from 'moment';
import { Box, makeStyles, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import Logo from '../Logo';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    margin: '2rem 0rem 0.5rem 0rem',
  },
  common: {
    textAlign: 'left',
    fontWeight: 500,
    fontSize: '0.9rem',
  },

  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
    marginInlineStart: 'inherit',
  },
  hrLineTitle: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
    marginLeft: '15rem',
  },
  backBtn: {
    height: '2rem',
  },
  hr: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  createBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    backgroundColor: '#ff5500',
    textTransform: 'capitalize',
  },
  deleteIcon: {
    fontSize: 'medium',
    color: 'red',
  },
  borderBox: {
    border: '2px solid grey',
    padding: '1rem',
    margin: 'auto',
    marginTop: '2rem',
  },
  textBorder: {
    border: '2px solid grey',
    padding: '7px',
    margin: 'auto',
  },
}));

const Preview = (props) => {
  const classes = useStyles();

  const [state, setState] = useState({
    qualityEventDetails:
      props.qualityEventRecordDetails.qualityEventRecordDetails
        .qualityEventDetails,
    eventReportedByDetails:
      props.qualityEventRecordDetails.qualityEventRecordDetails
        .eventReportedByDetails,
    investigatingTeamLeadDetails:
      props.qualityEventRecordDetails.qualityEventRecordDetails
        .investigatingTeamLeadDetails,
    investigatingTeamList:
      props.qualityEventRecordDetails.qualityEventRecordDetails
        .investigatingTeamList,
    supplierDataList:
      props.qualityEventRecordDetails.qualityEventRecordDetails
        .supplierDataList,
    affectedBatchesList:
      props.qualityEventRecordDetails.qualityEventRecordDetails
        .affectedBatchesList,
    qeCustomFiles: props.qualityEventRecordDetails.qeCustomFiles,
  });

  const handleLoadingScreen = () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
  };
  return (
    <div style={{ position: 'relative' }}>
      {state.loading && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            width: '100%',
            backdropFilter: 'blur(0.8px)',
            zIndex: 9,
            top: 0,
          }}
        >
          <CircularProgress style={{ marginTop: '60%' }} thickness='5' />
        </div>
      )}
      <Box width='90%' margin='auto'>
        <Box className={classes.borderBox}>
          <Box marginBottom='0.5rem'>
            <Typography variant='body1' style={{ fontWeight: 'bold' }}>
              EVENT DETAILS
            </Typography>
            <hr className={classes.hrLine} />
          </Box>
          <Box width='100%' display='flex' justifyContent='space-between'>
            <Box>
              <Typography className={classes.common}>Event ID</Typography>
              <Typography style={{ fontSize: '0.9rem' }}>
                {state.qualityEventDetails?.eventID}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.common}>Event Type</Typography>
              <Typography style={{ fontSize: '0.9rem' }}>
                {state.qualityEventDetails?.eventType}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.common}>Raised By</Typography>
              <Typography style={{ fontSize: '0.9rem' }}>
                {state.qualityEventDetails?.incidentRaisedBy}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.common}>Raised For</Typography>
              <Typography style={{ fontSize: '0.9rem' }}>
                {state.qualityEventDetails?.incidentFor}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box marginTop='2rem' marginBottom='3rem'>
          <Typography variant='h5' className={classes.title}>
            Event Details
          </Typography>
          <hr className={classes.hrLine} />
        </Box>
        <Box>
          <Typography className={classes.common}>Title</Typography>
          <Typography>
            {state.qualityEventDetails?.eventTitle ? (
              <>{state.qualityEventDetails?.eventTitle}</>
            ) : (
              'NA'
            )}
          </Typography>
        </Box>
        <Box marginTop='1.5rem'>
          <Typography className={classes.common}>Description</Typography>
          <Typography>
            {state.qualityEventDetails?.eventDescription ? (
              <>{state.qualityEventDetails?.eventDescription}</>
            ) : (
              'NA'
            )}
          </Typography>
        </Box>

        <Box
          width='92%'
          marginTop='1.5rem'
          display='flex'
          justifyContent='space-between'
        >
          <Box>
            <Typography className={classes.common}>
              Date event occurred
            </Typography>
            <Typography>
              {state.qualityEventDetails?.eventOccurredDate ? (
                <>
                  {moment(state.qualityEventDetails?.eventOccurredDate).format(
                    'DD-MM-YYYY'
                  )}
                </>
              ) : (
                'NA'
              )}
            </Typography>
          </Box>
          <Box width='18%'>
            <Typography className={classes.common}>
              Date event reported
            </Typography>
            <Typography>
              {state.qualityEventDetails?.eventReportedDate ? (
                <>
                  {moment(state.qualityEventDetails?.eventReportedDate).format(
                    'DD-MM-YYYY'
                  )}
                </>
              ) : (
                'NA'
              )}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.common}>Reported By</Typography>
            {/* <Typography>
              {state.qualityEventDetails?.eventReportedBy}
            </Typography> */}
            <Typography
              style={{
                color: 'dodgerblue',
                display: 'flex',
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  width: '2rem',
                  textAlign: 'center',
                }}
              >
                <Logo
                  name={
                    state.eventReportedByDetails.reportedByName === ''
                      ? '-'
                      : state.eventReportedByDetails.reportedByName
                  }
                />
              </div>
              <u style={{ marginLeft: '1rem' }}>
                {state.eventReportedByDetails.reportedByName ? (
                  <>{state.eventReportedByDetails.reportedByName}</>
                ) : (
                  'NA'
                )}
              </u>
            </Typography>
          </Box>
        </Box>
        <hr className={classes.hr} />
        <Box marginBottom='3rem'>
          <Typography variant='h5' className={classes.title}>
            Risk Assessment
          </Typography>
          <hr className={classes.hrLine} />
        </Box>
        <Box width='87%' display='flex' justifyContent='space-between'>
          <Box>
            <Typography className={classes.common}>Impact</Typography>
            <Typography>
              {state.qualityEventDetails?.impactText ? (
                <>{state.qualityEventDetails?.impactText}</>
              ) : (
                'NA'
              )}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.common}>Frequency</Typography>
            <Typography>
              {state.qualityEventDetails?.frequencyText ? (
                <>{state.qualityEventDetails?.frequencyText}</>
              ) : (
                'NA'
              )}
            </Typography>
          </Box>
          <Box width='8%'>
            <Typography className={classes.common}>Priority</Typography>
            <Typography>
              {state.qualityEventDetails?.priority ? (
                <>{state.qualityEventDetails?.priority}</>
              ) : (
                'NA'
              )}
            </Typography>
          </Box>
        </Box>
        <hr className={classes.hr} />
        <Box marginBottom='3rem'>
          <Typography variant='h5' className={classes.title}>
            Facility and Location Details
          </Typography>
          <hr className={classes.hrLine} />
        </Box>
        <Box width='80%' display='flex' justifyContent='space-between'>
          <Box>
            <Typography className={classes.common}>Facility</Typography>
            <Typography>
              {state.qualityEventDetails?.facility ? (
                <>{state.qualityEventDetails?.facility}</>
              ) : (
                'NA'
              )}
            </Typography>
          </Box>
          <Box width='50%'>
            <Typography className={classes.common}>Location</Typography>
            <Typography>
              {state.qualityEventDetails?.location ? (
                <>{state.qualityEventDetails?.location}</>
              ) : (
                'NA'
              )}
            </Typography>
          </Box>
        </Box>
        <hr className={classes.hr} />
        <Box marginBottom='3rem'>
          <Typography variant='h5' className={classes.title}>
            Effected product and Batches
          </Typography>
          <hr className={classes.hrLine} />
        </Box>
        <Box width='80%' display='flex' justifyContent='space-between'>
          <Box>
            <Typography className={classes.common}>Product effected</Typography>
            <Typography>
              {state.qualityEventDetails?.productCode ? (
                <>
                  {state.qualityEventDetails?.productCode}/
                  {state.qualityEventDetails?.productName}{' '}
                </>
              ) : (
                'NA'
              )}
            </Typography>
          </Box>
          <Box width='50%'>
            <Typography className={classes.common}>Batch effected</Typography>
            <Typography style={{ marginTop: '-0.9rem' }}>
              {state.affectedBatchesList.map((data, i) => (
                <p>{data.batchNumber}</p>
              ))}
            </Typography>
          </Box>
        </Box>
        <hr className={classes.hr} />
        <Box marginBottom='3rem'>
          <Typography variant='h5' className={classes.title}>
            Effected suppliers
          </Typography>
          <hr className={classes.hrLine} />
        </Box>
        <Box>
          <Typography className={classes.common}>Effected suppliers</Typography>
          <Typography>
            {state.supplierDataList.map((data, i) => (
              <p>
                {data.supplierCode ? (
                  <>
                    {data.supplierCode}/{data.supplierName}
                  </>
                ) : (
                  'NA'
                )}
              </p>
            ))}
          </Typography>
        </Box>
        <hr className={classes.hr} />
        <Box marginBottom={'3rem'}>
          <Typography variant='h5' className={classes.title}>
            Assign team for investigation
          </Typography>
          <hr className={classes.hrLine} />
        </Box>
        <Box width='80%' display='flex' justifyContent='space-between'>
          <Box>
            <Typography className={classes.common}>
              Quality assurance team lead
            </Typography>
            <Typography
              style={{
                color: 'dodgerblue',
                display: 'flex',
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  width: '2rem',
                  textAlign: 'center',
                }}
              >
                <Logo
                  name={
                    state.investigatingTeamLeadDetails.teamLeadName === ''
                      ? '-'
                      : state.investigatingTeamLeadDetails.teamLeadName
                  }
                />
              </div>
              <u style={{ marginLeft: '1rem' }}>
                {state.investigatingTeamLeadDetails.teamLeadName ? (
                  <>{state.investigatingTeamLeadDetails.teamLeadName}</>
                ) : (
                  'NA'
                )}
              </u>
            </Typography>
          </Box>
          <Box width='52%'>
            <Typography className={classes.common}>
              Quality assurance team member
              {state.investigatingTeamList.map((data, i) => (
                <Typography style={{ color: 'dodgerblue', display: 'flex' }}>
                  <div
                    style={{
                      alignItems: 'center',
                      width: '2rem',
                      textAlign: 'center',
                    }}
                  >
                    <Logo name={data?.name} />
                  </div>
                  <u style={{ marginLeft: '1rem', marginBottom: '0.5rem' }}>
                    {data?.name}
                  </u>
                </Typography>
              ))}
            </Typography>
          </Box>
        </Box>
        <hr className={classes.hr} />
        <Box marginBottom='3rem'>
          <Typography variant='h5' className={classes.title}>
            Upload related Documents
          </Typography>
          <hr className={classes.hrLine} />
          <Box>
            <Typography className={classes.common}>
              Upload documents list
            </Typography>
            {state.qeCustomFiles.map((qeCustomFiles, i) => (
              <Typography
                className={classes.previewData}
                style={{ color: 'dodgerblue' }}
                key={i}
              >
                <u>{qeCustomFiles.qeFileName}</u>
              </Typography>
            ))}
          </Box>
        </Box>
        <hr className={classes.hr} />
        <Box marginBottom='4rem'>
          <Box marginBottom='3rem'>
            <Typography variant='h5' className={classes.title}>
              Resolution and Action
            </Typography>
            <hr className={classes.hrLine} />
          </Box>
          <Box width='80%' display='flex' justifyContent='space-between'>
            <Box>
              <Typography className={classes.common}>
                Description of Action
              </Typography>
              <Typography>
                {state.qualityEventDetails?.resolutionDescription ? (
                  <>{state.qualityEventDetails?.resolutionDescription}</>
                ) : (
                  'NA'
                )}
              </Typography>
            </Box>
            <Box width='50%'>
              <Typography className={classes.common}>
                Estimate resolution date
              </Typography>
              <Typography>
                {state.qualityEventDetails?.estimatedResolutionDate ? (
                  <>
                    {' '}
                    {moment(
                      state.qualityEventDetails?.estimatedResolutionDate
                    ).format('DD-MM-YYYY')}
                  </>
                ) : (
                  'NA'
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
export default Preview;
