import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PcmpAuth from '../../hoc/PcmpAuth';
import UserDeactivated from '../common/userDeactivation';
// import CmsLayout from './layout';
import Dashboard from './dashboard';
import ExecDashboard from './executiveDashboard';
import ExecDashboardLineItems from './executiveDashboard/lineItems';
import ExecDashboardBatchItems from './executiveDashboard/batchItems';
import LineItems from './lineItems';

import PurchaseOrders from './purchaseOrders';
import BatchItems from './batchItems';
import POBatchItems from './batchItems/poBatchItems';

import ManageMembers from './manageMembers';
import ViewPurchaseOrder from './purchaseOrders/viewPurchaseOrder/purchaseOrder';
import ViewLineItem from './purchaseOrders/viewPurchaseOrder/lineItems';
import ShortFalls from './shortFalls';

import Reports from './reports';
import Settings from './settings';

import NotFound from '../../notFound/NotFound';
import CmsLayout from '../layout/index';

import GRNReceipts from '../common/grnReceipts/GrnAccess';

// import GRNTestReceipts from '../common/grnReceipts/'
//UserProfile
import UserProfile from '../common/profile/user';
import OrgDetails from '../common/profile/organization';

import WIPComponent from '../common/wip';
import DeliveryScheduleDetails from '../common/sasl/deliverScheduleDetails';

import SASLIndex from '../common/sasl/scheduleAgreements/index';
import SAAuditIndex from '../common/reports/saAudit';

/* Quality event import */
import QualityEventIndex from '../common/qualityEvents/index';
//  import EventInformation from '../common/qualityEvents/raiseQualityEvent/addEvent_BO/EventInformation'
import BatchesDetails from '../common/sasl/batchesDetails';

const PcmpCmsRoutes = () => (
  <CmsLayout>
    <Switch>
      <Route
        exact
        name='PCMP Dashboard'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/dashboard'
        component={PcmpAuth(Dashboard, 'Pharma Co')}
        // component={Dashboard}
      />
      <Route
        exact
        name='PCMP Dashboard'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/executivedashboard'
        component={PcmpAuth(ExecDashboard, 'Pharma Co')}
      />
      <Route
        exact
        name='PCMP Dashboard'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/executivedashboard/lineItems'
        component={PcmpAuth(ExecDashboardLineItems, 'Pharma Co')}
      />
      <Route
        exact
        name='PCMP Dashboard'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/executivedashboard/batchItems'
        component={PcmpAuth(ExecDashboardBatchItems, 'Pharma Co')}
      />
      <Route
        exact
        name='PCMP All Line Items'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/lineitems'
        component={PcmpAuth(LineItems, 'Pharma Co')}
      />
      <Route
        exact
        name='PCMP Purchase Orders'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/purchaseorders'
        component={PcmpAuth(PurchaseOrders, 'Pharma Co')}
      />
      <Route
        exact
        name='PCMP All Batch Items'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/batches'
        component={PcmpAuth(BatchItems, 'Pharma Co')}
      />
      <Route
        exact
        name='PCMP All Batch Items for a Purchase Order'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/:purchaseOrderNumber/pobatchitems'
        component={PcmpAuth(POBatchItems, 'Pharma Co')}
      />
      <Route
        exact
        name='Manage Members'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/managemembers'
        component={PcmpAuth(ManageMembers, 'Pharma Co')}
        // component={ManageMembers}
      />

      <Route
        exact
        name='Settings'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/settings'
        component={PcmpAuth(Settings, 'Pharma Co')}
      />

      <Route
        exact
        name='View Purchase Order'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/purchaseorderdetails/:purchaseOrderNumber'
        component={PcmpAuth(ViewPurchaseOrder, 'Pharma Co')}
      />
      <Route
        exact
        name='View Line Item'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/lineitem/:purchaseOrderNumber/:schrockenPOLineID/:lineItemNumber'
        component={PcmpAuth(ViewLineItem, 'Pharma Co')}
      />
      <Route
        exact
        name='Pharma Co Short Falls'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/shortFalls'
        component={PcmpAuth(ShortFalls, 'Pharma Co')}
      />
      <Route
        exact
        name='Pharma Co GRN Receipts'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/GRNReceipts'
        // component={PcmpAuth(GRNReceipts, 'Pharma Co')}
        component={GRNReceipts}
      />
      <Route
        exact
        name='Reports'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/reports'
        // component={PcmpAuth(Reports, 'Pharma Co')}
        component={Reports}
      />
      <Route
        exact
        name='SA Audit Reports'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/sa-audit-reports'
        component={SAAuditIndex}
      />

      <Route
        exact
        name='Schedule Line Details'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/sasl/:schrockenDeliveryScheduleID'
        component={DeliveryScheduleDetails}
      />

      <Route
        exact
        name='Profile Page'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/userProfile'
        component={UserProfile}
      />
      <Route
        exact
        name='Organization Profile Page'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/orgProfile'
        component={OrgDetails}
      />

      <Route
        exact
        name='Schedule Agreements'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/schedule agreements'
        component={SASLIndex}
      />

      {/* Quality Event  */}
      <Route
        exact
        name='Quality Events'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/quality events'
        component={QualityEventIndex}
        //component={EventInformation}
      />

      {/* SA SL test screens route */}

      <Route
        exact
        name='SA SL Screen test'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/userdeactivate'
        component={UserDeactivated}
      />

      <Route
        exact
        name='Help'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/help'
        component={WIPComponent}
      />
      <Route
        exact
        name='Switch Ecosystems'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/switch ecosystem'
        component={WIPComponent}
      />

      {/* Batch Dashboard */}
      <Route
        exact
        name='PCMP All Batches'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/fgBatches'
        component={BatchesDetails}
      />

      <Route
        exact
        name='PCMP All Batches'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/fgBatches/:batchNo'
        component={BatchesDetails}
      />

      <Route
        exact
        name='404 Not Found'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/*'
        component={NotFound}
      />
      {/* route for econd dialougebox */}
      {/* <Route
        exact
        name='Quality Events Dialouge2'
        path='/pcmp/Pharma Co/:roleName/:organizationName/:ecosystemId/quality events/dashboard2'
        component={EventInformation}
      /> */}

      <Route name='404 Not Found' component={NotFound} />
    </Switch>
  </CmsLayout>
);

export default PcmpCmsRoutes;
