import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import EventTwoToneIcon from '@material-ui/icons/Event';
import MomentUtils from '@date-io/moment';

import { useStyles } from './qualityEventStyles';
import CMOFilter from './CMOFilter';
import EventFilter from './EventFilter';
import PriorityFilter from './PriorityFilter';
import { format } from 'date-fns';

const Filter = (props) => {
  const classes = useStyles();

  const {
    cmoFilter,
    selectCMO,
    handleSelectCMO,
    CMOMember,
    qualityEvent,
    handleSelectEvent,
    startDate,
    endDate,
    priority,
    errors,
    setErrors,
    qualityEventName,
    handlePrioritySelector,
    handleStartDateChange,
    handleEndDateChange,
    handleClearStartDate,
    handleClearEndDate,
    setFilters,
  } = props;

  const [enableEndDate, setEnableEndDate] = useState(false);
  const startDateFormat = startDate ? 'DD/MM/YYYY' : startDate;
  const endDateFormat = endDate ? 'DD/MM/YYYY' : endDate;
  //moment(new Date(),'MM/DD/YYYY')
  let date = format(new Date(), 'dd/MM/yyyy');
  return (
    <Grid container spacing={1}>
      {cmoFilter && (
        <Grid item sx={12} sm={2} style={{ paddingTop: '0.25rem' }}>
          <Typography
            variant='body2'
            style={{ paddingBottom: '0.6rem' }}
            className={classes.hgText}
          >
            View By:
          </Typography>
          {/*<p>CMO box here</p>*/}
          <CMOFilter handleSelectCMO={handleSelectCMO} CMOMember={CMOMember} />
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sm={2}
        style={{ paddingTop: '2.2rem', marginLeft: '1.5rem' }}
      >
        <EventFilter
          qualityEvent={qualityEvent}
          handleSelectEvent={handleSelectEvent}
          qualityEventName={qualityEventName}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={2}
        style={{ paddingTop: '2.2rem', marginLeft: '1.5rem' }}
      >
        <PriorityFilter
          priority={priority}
          handlePrioritySelector={handlePrioritySelector}
        />
      </Grid>

      {/** Calendar here */}

      <Grid item xs={12} sm={2} style={{ marginLeft: '6%' }}>
        <Typography
          variant='body2'
          style={{ padding: '0.1rem', paddingBottom: '0.7rem' }}
          className={classes.hgText}
        >
          Period of Time:
        </Typography>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            fullWidth
            variant='inline'
            inputVariant='outlined'
            format={startDateFormat}
            name='startDate'
            value={startDate}
            onChange={(startDateFormat) =>
              handleStartDateChange(startDateFormat)
            }
            error={errors.isErrorForStartDate}
            helperText={errors.errorMessageForStartDate}
            placeholder='DD/MM/YYYY'
            size='small'
            autoOk={true}
            maxDate={new Date()}
            InputProps={{
              endAdornment: (
                <EventTwoToneIcon
                  style={{ color: '#0000008a', cursor: 'pointer' }}
                />
              ),
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Typography variant='body2'></Typography>
      <p
        style={{
          fontFamily: 'Roboto',
          textAlign: 'center',
          //paddingLeft: "0.1rem",
          paddingTop: '2rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
        }}
      >
        To
      </p>
      {/* </Grid> */}

      <Grid item xs={12} sm={2} style={{ paddingTop: '2.2rem' }}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            fullWidth
            variant='inline'
            inputVariant='outlined'
            format={endDateFormat}
            name='endDate'
            value={endDate}
            error={errors.isErrorForEndDate}
            helperText={errors.errorMessageForEndDate}
            onChange={(endDateFormat) => handleEndDateChange(endDateFormat)}
            placeholder='DD/MM/YYYY'
            size='small'
            autoOk={true}
            minDate={startDate}
            maxDate={new Date()}
            disabled={startDate === null}
            InputProps={{
              endAdornment: (
                <EventTwoToneIcon
                  style={{ color: '#0000008a', cursor: 'pointer' }}
                />
              ),
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

export default Filter;
