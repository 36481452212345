import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import ValidateImage from '../../../resources/images/validate.svg';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  saved: {
    textAlign: 'center',
    padding: '0.5rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  text: {
    textAlign: 'center',
    fontSize: '1rem',
    margin: '1rem',
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '5rem',
    color: '#39811D',
  },
  cardContent: {
    padding: '2rem !important',
  },
  buttonGrid: {
    textAlign: 'center',
    padding: '0.5rem',
  },
  noButton: {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
});

const ValidateDialog = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <DialogContent
      classes={{
        root: classes.cardContent,
      }}
    >
      <Grid container justify='flex-end'>
        <Grid item xs={12} className={classes.iconGrid}>
          <img
            src={ValidateImage}
            alt='validating..'
            width='300'
            height='200'
          />
        </Grid>
      </Grid>
      <Typography variant='h5' className={classes.saved}>
        {props.title}
      </Typography>

      <Typography variant='body2' className={classes.text}>
        {props.subtitle}
      </Typography>

      {children}
    </DialogContent>
  );
};

export default ValidateDialog;
