// import { withStyles } from '@material-ui/core/styles';

export const useStyles = (theme) => ({
  warningStyles: {
    '& .MuiFormHelperText-contained': {
      color: '#f44336',
    },
  },
  contain: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  mainGrid: {
    backgroundColor: '#EAEAEA',
    padding: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
  },
  font: {
    fontWeight: 500,
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
  },
  fontPadding: {
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
    overflow: 'auto',
  },
  grid: {
    paddingBottom: '1rem',
    paddingTop: '1rem',
    paddingRight: '1.5rem',
  },
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '1.6rem',
    backgroundColor: 'grey',
  },
  table: {
    minWidth: 650,
  },
  icon: {
    paddingLeft: '0.5rem',
    paddingTop: '0.5rem',
    cursor: 'pointer',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },

  //edit consumption

  list: {
    padding: '1rem',
    paddingLeft: '1.8rem',
  },
  wrapText: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    overflow: 'hidden',
    padding: '0 0.5rem 0 0.5rem',
    fontSize: '0.9rem',
  },
  // consumption table styles
  tdConsumption: {
    paddingBottom: '10px',
    '&:last-child': {
      padding: 0,
    },
  },
  tdAuto: {
    display: 'flex',
  },
  autoComplete: {
    width: 'fit-content',
    minWidth: '200px',
  },
  inputPlaceholder: {
    '& input::placeholder': {
      fontSize: '0.875rem',
    },
  },
  rowText: {
    '& td': {
      color: '#f44336',
    },
    '& input': {
      color: '#f44336',
    },
  },
});
