import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import NoUnderLineTableCell from '../models/table/NoUnderLineTableCell';
import AddContainer from './AddContainer';
import ViewContainerDetails from './ViewContainerDetails';

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6rem',
  },
  font: {
    fontWeight: 500,
    paddingBottom: '1rem',
  },
  padding: {
    paddingBottom: '2rem',
  },
  container: {
    maxWidth: '65%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  root: {
    padding: 8,
    borderBottom: 0,
  },
}));

const ContainerInformation = (props) => {
  const classes = useStyles();
  const {
    containerDetails,
    handleContainersChange,
    handleAddContainer,
    handleRemoveContainer,
    editContainerDetails,
  } = props;

  return (
    <Grid item xs={12} sm={12} md={12} xl={6} style={{ paddingTop: 0 }}>
      <Typography variant='body2' className={classes.font}>
        Container Details
      </Typography>
      {!editContainerDetails && containerDetails.length === 0 ? (
        <Typography
          variant='body2'
          gutterBottom
          style={{ marginTop: '-0.85rem' }}
        >
          -Not Provided-
        </Typography>
      ) : (
        <TableContainer className={classes.container}>
          <Table aria-label='Container table'>
            <TableHead>
              <TableRow>
                <NoUnderLineTableCell>Sr. No</NoUnderLineTableCell>
                <NoUnderLineTableCell>
                  Number of Containers
                </NoUnderLineTableCell>
                <NoUnderLineTableCell>Quantity/Container</NoUnderLineTableCell>
                {editContainerDetails && <NoUnderLineTableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {editContainerDetails &&
                containerDetails.length > 0 &&
                containerDetails.map((container, index) => (
                  <AddContainer
                    key={`Container-${index}`}
                    index={index}
                    container={container}
                    handleChange={handleContainersChange}
                    handleAddContainer={handleAddContainer}
                    handleRemoveContainer={handleRemoveContainer}
                  />
                ))}
              {editContainerDetails && containerDetails.length === 0 && (
                <AddContainer
                  index={0}
                  container={{
                    containerSerialNumber: 1,
                    numberOfContainers: '',
                    quantityPerContainer: '',
                  }}
                  handleChange={handleContainersChange}
                  handleAddContainer={handleAddContainer}
                  handleRemoveContainer={handleRemoveContainer}
                />
              )}

              {editContainerDetails === false &&
                containerDetails.length > 0 &&
                containerDetails.map((container, index) => (
                  <ViewContainerDetails
                    container={container}
                    key={`View-Container-${index}`}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

ContainerInformation.propTypes = {
  containerDetails: PropTypes.array.isRequired,
  handleContainersChange: PropTypes.func,
  handleAddContainer: PropTypes.func,
  handleRemoveContainer: PropTypes.func,
  editContainerDetails: PropTypes.bool,
};

export default ContainerInformation;
