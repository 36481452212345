import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import { Grid, Button, Typography, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';

import {
  cmoAddBatchItem,
  cmoFetchLineBatchItems,
} from '../../../../../../containers/actions/cmo/addBatchItemActions';

import { getLineItemDetails } from '../../../../../../containers/actions/pharmaCo/purchaseOrderActions';

const styles = {
  helperText: {
    '&:error': {
      color: '#000000',
    },
  },
};
class AddBatchItem extends Component {
  state = {
    disableSaveButton: false,
    batchDetails: {
      batchNumber: '',
      batchSize: '',
      producedQuantity: 0,
      pendingQuantity: '',
      unitOfMeasurement: '',
      status: 5,
      batchStartDate: null,
      batchDuration: '',
      batchEndDate: null,
      maximumRetailPrice: 0.0,
      QCDate: null,
      shipmentDate: null,
    },
    errors: {
      batchNumber: '',
      batchSize: '',
      producedQuantity: '',
      pendingQuantity: '',
      unitOfMeasurement: false,
      status: '',
      QCDate: false,
      batchDuration: false,
      batchStartDate: false,
      shipmentDate: false,
    },
    userResponse: false,
    batchError: null,
  };

  componentDidMount() {
    if (this.props.poLineItemDetails) {
      this.setState((prevState) => ({
        batchDetails: {
          ...prevState.batchDetails,
          unitOfMeasurement: this.props.poLineItemDetails.unitOfMeasurement,
        },
      }));
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.batchItemAdded !== this.props.batchItemAdded &&
      nextProps.batchItemAdded.errorDescription
    ) {
      this.setState({
        batchError: nextProps.batchItemAdded.errorDescription,
        disableSaveButton: false,
      });
    }
    if (
      nextProps.batchItemAdded !== this.props.batchItemAdded &&
      nextProps.batchItemAdded.status === true
    ) {
      this.props.toggleBatchScreen();
      let data = { ...this.props.params, collabType: 'CMO' };

      this.props.cmoFetchLineBatchItems(data);

      this.props.getLineItemDetails(data);
    }
    if (nextProps.batchWarning === true) {
      this.setState({ userResponse: true });
    }

    if (nextProps.poLineItemDetails) {
      this.setState((prevState) => ({
        batchDetails: {
          ...prevState.batchDetails,
          unitOfMeasurement: nextProps.poLineItemDetails.unitOfMeasurement,
        },
      }));
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'batchSize') {
      let parsedValue = value !== '' ? parseInt(value) : '';
      this.setState((prevState) => ({
        batchDetails: {
          ...prevState.batchDetails,
          [name]: parsedValue,
          pendingQuantity: parsedValue,
        },
        errors: {
          batchNumber: '',
          batchSize: '',
          producedQuantity: '',
          pendingQuantity: '',
          unitOfMeasurement: false,
          status: '',
          QCDate: false,
          batchDuration: false,
          batchStartDate: false,
          shipmentDate: false,
        },
        batchError: null,
      }));
    } else if (name === 'batchDuration') {
      let { batchStartDate } = this.state.batchDetails;
      let batchEndDate = Moment(batchStartDate).add(value, 'd').toISOString();
      this.setState((prevState) => ({
        batchDetails: {
          ...prevState.batchDetails,
          [name]: value,
          batchEndDate: batchEndDate,
        },
        errors: {
          batchNumber: '',
          batchSize: '',
          producedQuantity: '',
          pendingQuantity: '',
          unitOfMeasurement: false,
          status: '',
          QCDate: false,
          batchDuration: false,
          batchStartDate: false,
          shipmentDate: false,
        },
        batchError: null,
      }));
    } else {
      this.setState((prevState) => ({
        batchDetails: {
          ...prevState.batchDetails,
          [name]: value,
        },
        errors: {
          batchNumber: '',
          batchSize: '',
          producedQuantity: '',
          pendingQuantity: '',
          unitOfMeasurement: false,
          status: '',
          QCDate: false,
          batchDuration: false,
          batchStartDate: false,
          shipmentDate: false,
        },
        batchError: null,
      }));
    }
  };
  // change date
  handleDateChange = (date, name) => {
    this.setState((prevState) => ({
      batchDetails: {
        ...prevState.batchDetails,
        [name]: date != null ? date.toISOString() : date,
      },
      errors: {
        ...prevState.errors,
        [name]: false,
      },
      batchError: null,
    }));
  };
  validate = () => {
    let isError = false;

    let { batchDetails } = this.state;

    let errors = {
      batchNumber: '',
      batchSize: '',
      producedQuantity: '',
      pendingQuantity: '',
      unitOfMeasurement: false,
      status: '',
      QCDate: false,
      batchDuration: false,
      batchStartDate: false,
      shipmentDate: false,
    };

    if (batchDetails.batchNumber === '') {
      isError = true;
      errors.batchNumber = 'Please Enter Batch Number';
    } else if (batchDetails.batchSize === '') {
      isError = true;
      errors.batchSize = 'Please Enter Batch Size';
    } else if (batchDetails.batchStartDate === null) {
      isError = true;
      errors.batchStartDate = true;
    } else if (batchDetails.batchDuration === '') {
      isError = true;
      errors.batchDuration = true;
    } else if (batchDetails.unitOfMeasurement === '') {
      isError = true;
      errors.unitOfMeasurement = 'Please Enter Unit of Measurement';
    } else if (batchDetails.status === '') {
      isError = true;
      errors.status = 'Please Select Batch Status';
    } else if (
      batchDetails.QCDate !== null &&
      batchDetails.QCDate < batchDetails.batchStartDate
    ) {
      isError = true;
      errors.QCDate = true;
    } else if (
      batchDetails.QCDate !== null &&
      batchDetails.QCDate < batchDetails.batchEndDate
    ) {
      isError = true;
      errors.QCDate = true;
    } else if (
      batchDetails.shipmentDate !== null &&
      batchDetails.shipmentDate < batchDetails.batchStartDate
    ) {
      isError = true;
      errors.shipmentDate = true;
    } else if (
      batchDetails.shipmentDate !== null &&
      batchDetails.batchEndDate !== null &&
      batchDetails.shipmentDate <= batchDetails.batchEndDate
    ) {
      isError = true;
      errors.shipmentDate = true;
    }
    this.setState({ errors });
    return isError;
  };

  onSubmit = () => {
    let error = this.validate();

    if (!error) {
      this.setState({ disableSaveButton: true });
      let data = {
        ...this.state.batchDetails,
        ...this.props.params,
        ...this.state.batchDetails,
        collabType: 'CMO',
        userResponse: false,
      };
      this.props.cmoAddBatchItem(data);
    }
  };
  handleClose = () => {
    this.setState({
      userResponse: false,
    });
    this.props.toggleBatchScreen();
    let data = { ...this.props.params, collabType: 'CMO' };
    this.props.cmoFetchLineBatchItems(data);
  };
  handleAccept = () => {
    this.setState({
      userResponse: false,
    });
    let data = {
      ...this.state.batchDetails,
      ...this.props.params,
      ...this.state.batchDetails,
      collabType: 'CMO',
      userResponse: true,
    };
    this.props.cmoAddBatchItem(data);
  };
  handleEditBatch = () => {
    this.setState({ userResponse: false, disableSaveButton: false });
    let data = { ...this.props.params, collabType: 'CMO' };
    this.props.cmoFetchLineBatchItems(data);
  };
  render() {
    const { batchDetails, errors, batchError } = this.state;
    const { classes } = this.props;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    return (
      <React.Fragment>
        <Typography
          variant='h6'
          style={{ textAlign: 'center', marginTop: '5rem' }}
        >
          Add Batch Item Details
        </Typography>
        <Card
          style={{
            margin: '2rem 4rem 4rem 4rem',
          }}
        >
          <CardContent
            style={{
              margin: '1.25rem',
            }}
          >
            <Grid container spacing={2}>
              <Grid item sm={12} md={3}>
                <TextField
                  label='Batch Number'
                  name='batchNumber'
                  value={batchDetails.batchNumber}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={this.state.errors.batchNumber === '' ? false : true}
                  helperText={this.state.errors.batchNumber}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                    },
                  }}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='Batch Size'
                  name='batchSize'
                  type='number'
                  inputProps={{ min: 0, step: 10 }}
                  value={batchDetails.batchSize}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={this.state.errors.batchSize === '' ? false : true}
                  helperText={this.state.errors.batchSize}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='Produced Quantity'
                  name='producedQuantity'
                  type='number'
                  disabled
                  inputProps={{ min: 0, step: 10 }}
                  value={batchDetails.producedQuantity}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={
                    this.state.errors.producedQuantity === '' ? false : true
                  }
                  helperText={this.state.errors.producedQuantity}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='Pending Quantity'
                  name='pendingQuantity'
                  type='number'
                  disabled
                  inputProps={{ min: 0, step: 10 }}
                  value={batchDetails.pendingQuantity}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={
                    this.state.errors.pendingQuantity === '' ? false : true
                  }
                  helperText={this.state.errors.pendingQuantity}
                />
              </Grid>

              <Grid
                item
                sm={12}
                md={3}
                style={{
                  marginTop: '1rem',
                }}
              >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant='inline'
                    InputAdornmentProps={{ position: 'start' }}
                    inputVariant='outlined'
                    label='Start Date'
                    format={dateFormat}
                    placeholder={dateFormat}
                    value={batchDetails.batchStartDate}
                    minDate={new Date()}
                    onChange={(date) =>
                      this.handleDateChange(date, 'batchStartDate')
                    }
                    error={errors.batchStartDate}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item sm={12} md={3}>
                <TextField
                  label='Duration (Days)'
                  name='batchDuration'
                  disabled={batchDetails.batchStartDate === null ? true : false}
                  type='number'
                  inputProps={{ min: 0, step: 5 }}
                  value={batchDetails.duration}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={errors.batchDuration}
                />
              </Grid>

              <Grid
                item
                sm={12}
                md={3}
                style={{
                  marginTop: '1rem',
                }}
              >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disabled
                    disableToolbar
                    variant='inline'
                    InputAdornmentProps={{ position: 'start' }}
                    inputVariant='outlined'
                    label='End Date'
                    format={dateFormat}
                    placeholder={dateFormat}
                    value={batchDetails.batchEndDate}
                    minDate={batchDetails.batchStartDate}
                    onChange={(date) =>
                      this.handleDateChange(date, ' batchEndDate')
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='Units Of Measurement'
                  name='unitOfMeasurement'
                  disabled
                  value={batchDetails.unitOfMeasurement}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={this.state.errors.unitOfMeasurement}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant='inline'
                    InputAdornmentProps={{ position: 'start' }}
                    inputVariant='outlined'
                    label='QC Date'
                    format={dateFormat}
                    placeholder={dateFormat}
                    value={batchDetails.QCDate}
                    minDate={
                      batchDetails.batchEndDate === null
                        ? new Date()
                        : batchDetails.batchEndDate
                    }
                    onChange={(date) => this.handleDateChange(date, 'QCDate')}
                    error={errors.QCDate}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item sm={12} md={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant='inline'
                    InputAdornmentProps={{ position: 'start' }}
                    inputVariant='outlined'
                    label='Shipping Date'
                    format={dateFormat}
                    placeholder={dateFormat}
                    value={batchDetails.shipmentDate}
                    minDate={
                      batchDetails.batchEndDate === null
                        ? new Date()
                        : batchDetails.batchEndDate
                    }
                    onChange={(date) =>
                      this.handleDateChange(date, 'shipmentDate')
                    }
                    error={errors.shipmentDate}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.helperText,
                      },
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='Status'
                  select
                  disabled
                  fullWidth
                  style={{
                    marginTop: '0rem',
                  }}
                  name='status'
                  value={batchDetails.status}
                  margin='normal'
                  variant='outlined'
                >
                  <MenuItem value={5}>Scheduled</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            {batchError && (
              <Grid
                item
                sm={12}
                style={{ textAlign: 'center', marginTop: '1rem' }}
              >
                <Typography variant='body2' color='error'>
                  {batchError}
                </Typography>
              </Grid>
            )}
          </CardContent>
        </Card>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            <Button
              variant='contained'
              onClick={this.onSubmit}
              disabled={this.state.disableSaveButton}
              style={{
                backgroundColor: this.state.disableSaveButton
                  ? 'grey'
                  : 'green',
                borderRadius: '1rem',
                color: '#ffffff',
              }}
            >
              Save All Changes
            </Button>
            <Typography
              variant='body1'
              onClick={this.props.toggleBatchScreen}
              style={{
                marginLeft: '2.5rem',
                cursor: 'pointer',
              }}
            >
              Cancel X
            </Typography>
          </div>
        </div>
        <Dialog
          open={this.state.userResponse}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {'Batch Quantity Warning'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              Are you sure you want to add this batch? You will be exceeding
              required Line quantity.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button onClick={this.handleEditBatch}>
                  <Typography variant='body2' color='secondary'>
                    Edit Batch
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                xs={8}
                style={{
                  textAlign: 'end',
                }}
              >
                <Button
                  onClick={this.handleClose}
                  style={{
                    marginRight: '0.5rem',
                  }}
                >
                  <Typography variant='body2' color='error'>
                    Discard Batch
                  </Typography>
                </Button>
                <Button
                  onClick={this.handleAccept}
                  color='primary'
                  variant='contained'
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => ({
  batchDetails: state.pcmp.cmo.batchItems.batchDates,
  batchItemAdded: state.pcmp.cmo.batchItems.batchItemAdded,
  batchWarning: state.pcmp.cmo.batchItems.batchWarning,
  poLineItemDetails: state.pcmp.pharmaCo.purchaseOrder.lineItemDetails,
});
export default withStyles(styles)(
  connect(mapStatetoProps, {
    cmoAddBatchItem,
    getLineItemDetails,
    cmoFetchLineBatchItems,
  })(AddBatchItem)
);
