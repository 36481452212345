/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Stupid Neetu
 * -----
 * File Description: CMO ALL Line Items Screen
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';

import LineItemsTable from '../../common/tables/LineItemsTable';

import { viewAllLineItems, viewLineItemSource } from '../../../containers/actions/common/dashboardAction';

class index extends Component {
  state = {
    allLineItems: [],
  };

  componentDidMount() {
    this.getLineItems();
  }
  componentWillUnmount() {
    if (viewLineItemSource) {
      viewLineItemSource.cancel('leaving page')
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.allLineItems &&
      nextProps.allLineItems !== prevState.allLineItems
    ) {
      return { allLineItems: nextProps.allLineItems };
    }
    return null;
  }

  getLineItems = () => {
    let data = {
      ...this.props.match.params,
      collabType: 'CMO',
    };
    this.props.viewAllLineItems(data);
    this.setState({ allLineItems: false });
  };

  render() {
    return (
      <Grid>
        <div
          style={{
            // margin: "-1rem -1.3rem 0rem -1.8rem",
            margin: '-16px -16px 0 -16px',
            backgroundColor: '#F5F5F5',
            flexDirection: 'row',
            padding: '1.2rem 0.5rem',
          }}
        >
          <Typography variant='h6' style={{ marginLeft: '3rem' }}>
            Total Line Items - {this.state.allLineItems.length}
          </Typography>
        </div>
        <LineItemsTable
          lineItems={this.state.allLineItems}
          collabType='CMO'
          onRefresh={this.getLineItems}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  allLineItems: state.pcmp.common.dashboard.allLineItems,
});

export default connect(mapStateToProps, {
  viewAllLineItems,
})(withRouter(index));
