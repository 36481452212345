import React, { Component } from "react";
import { connect } from "react-redux";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class PORejectionComments extends Component {
  state = {
    expanded: false,
    PORejectionComments: "",
    statusCode: 0
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.poDetails) {
      this.setState({
        PORejectionComments: nextProps.poDetails.PORejectionComments,
        statusCode: nextProps.poDetails.statusCode
      });
    }
  }

  handleChange = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  render() {
    const { expanded, PORejectionComments, statusCode } = this.state;
    return (
      <div>
        {statusCode === 2 && PORejectionComments !== "" && (
          <ExpansionPanel
            expanded={expanded}
            onChange={this.handleChange}
            style={{
              marginLeft: "-2rem",
              marginRight: "-1.3rem"
            }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              style={{
                backgroundColor: "#FACDCE"
              }}
            >
              <Typography
                variant="h6"
                style={{
                  flexBasis: "85%",
                  fontSize: "1rem"
                }}
              >
                PO Acknowledged &#38; Rejected
              </Typography>
              <Typography
                variant="caption"
                style={{
                  textDecoration: "underline",
                  fontSize: "0.6rem",
                  marginTop: "0.25rem"
                }}
              >
                {expanded === true
                  ? "HIDE REASON FOR REJECTION"
                  : "SHOW REASON FOR REJECTION"}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{
                paddingTop: "1rem",
                paddingBottom: "0.5rem"
              }}
            >
              <Typography variant="subtitle2">Reason for Rejection</Typography>
            </ExpansionPanelDetails>
            <ExpansionPanelDetails>
              <Typography variant="body2">{PORejectionComments}</Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  poDetails: state.pcmp.pharmaCo.purchaseOrder.poDetails
});

export default connect(mapStateToProps)(PORejectionComments);
