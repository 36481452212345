import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  titletext: {
    // fontSize: "0.75rem",
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.65rem',
      whiteSpace: 'nowrap'
    }
  }
});
class DisplayCard extends Component {
  handleClick = () => {
    const { ecosystemId, organizationName, roleName } = this.props.match.params;
    const { startCommitDate, endCommitDate, API } = this.props;
    if (this.props.screen === 'batch') {
      this.props.history.push(
        `/pcmp/Pharma Co/${roleName}/${organizationName}/${ecosystemId}/executivedashboard/batchItems?startCommitDate=${startCommitDate}&endCommitDate=${endCommitDate}`
      );
    } else {
      this.props.history.push(
        `/pcmp/Pharma Co/${roleName}/${organizationName}/${ecosystemId}/executivedashboard/lineItems?startCommitDate=${startCommitDate}&endCommitDate=${endCommitDate}&api=${API}`
      );
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={12} sm={6}>
        <Card
          style={{
            borderLeft: '0.15rem solid #2196f3',
            cursor: this.props.cardCount ? 'pointer' : 'default'
          }}
          onClick={this.props.cardCount ? this.handleClick : null}
        >
          <CardContent
          // style={{
          //   paddingBottom: 26,
          // }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <img
                  src={this.props.imgUrl}
                  alt="Line Item"
                  style={{
                    width: '100%',
                    height: 'auto'
                  }}
                />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2" className={classes.titletext}>
                  {this.props.cardName}
                </Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{
                    textDecoration: this.props.cardCount ? 'underline' : 'none'
                  }}
                  className={classes.titletext}
                >
                  {this.props.cardCount ? this.props.cardCount : 0}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(DisplayCard));
