import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    fontSize: '1.5rem',
    textAlign: 'left',
    paddingBottom: '0.3rem',
  },
  font: {
    fontWeight: 500,
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
  },
  fontPadding: {
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
    overflow: 'auto',
  },
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '1.6rem',
    backgroundColor: 'grey',
  },
  grid: {
    paddingBottom: '2rem',
    paddingTop: '1rem',
    paddingRight: '1.5rem',
  },
  avatar: {
    fontSize: '1rem',
    letterSpacing: '0.2rem',
  },
  action: {
    // display:'flex',
    padding: '0.85rem',
  },
  name: {
    paddingLeft: '0.65rem',
    bottom: '0.5rem',
  },
}));

export default function ReasonForRejection(props) {
  const classes = useStyles();
  const { comments } = props;

  const logo = (name) => {
    if (name !== undefined && name !== null) {
      return name
        .split(' ')
        .map((val) => {
          if (val[0] !== undefined) {
            return val[0].toUpperCase();
          }
          return '';
        })
        .join('');
    }
    return '';
  };

  return (
    <React.Fragment>
      <Grid container className={classes.child}>
        <Grid item xs={8} sm={12} md={12} className={classes.grid}>
          <Typography variant='h4' className={classes.title}>
            Issue Comment
          </Typography>
          <span className={classes.borders}></span>
        </Grid>

        <Grid item xs={12} sm={8} md={8} className={classes.grid}>
          <Typography variant='body2' className={classes.fontPadding}>
            {comments.reasonForRejection === null
              ? '-------'
              : comments.reasonForRejection}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          <Typography
            variant='body2'
            className={classes.fontPadding}
            style={{ paddingRight: '0.5rem' }}
          >
            Rejected by
          </Typography>

          <Avatar className={classes.avatar} style={{ bottom: '0.65rem' }}>
            {comments.rejectedBy === null
              ? '--'
              : logo(comments.rejectedBy).slice(0, 2)}
          </Avatar>

          <Typography variant='body2' className={classes.name}>
            {comments.rejectedBy === null ? '--' : comments.rejectedBy}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
