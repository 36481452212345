import React from 'react';

import { Box, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Autocomplete } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '80%',
    padding: '1rem',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
  },
  title: {
    marginTop: '0.5rem',
    fontWeight: '600',
    fontSize: '1.7rem',
  },
  title2: {
    marginTop: '0.5rem',

    fontWeight: '500',
    color: 'darkgrey',
  },
  title3: {
    marginBottom: '0.5rem',
    fontWeight: '500',
    color: 'darkgrey',
  },
  title4: {
    marginBottom: '1rem',
    fontWeight: '500',
  },
  subTitle: {
    textAlign: 'center',
  },
  dialogPaper: {
    width: '30%',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
  },
  capaBtn: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    borderRadius: '1.5rem',
    textTransform: 'none',
    marginTop: '1rem',
  },
  icon: {
    fontSize: 'xxx-large',
    color: 'green',
    marginLeft: '4rem',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const LaunchCapaSuccess = (props) => {
  const classes = useStyles();
  const { openDialog, toggleDialog } = props;
  let eventDetails =
    props.qualityEventRecordDetails.qualityEventRecordDetails
      ?.qualityEventDetails;

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='md'
      classes={{ paper: classes.dialogPaper }}
    >
      <Box padding='2rem 3rem' textAlign='center'>
        <CheckCircleIcon style={{ fontSize: '4rem', color: 'green' }} />

        <Typography variant='h6' className={classes.title}>
          CAPA Launched
        </Typography>
        <Typography variant='body1' className={classes.title4}>
          Event has been closed
        </Typography>
        <Typography className={classes.title2}>Event ID :{}</Typography>
        <Typography className={classes.title3}>
          Parent ID : {eventDetails?.eventID}
        </Typography>

        <Button
          className={classes.capaBtn}
          variant='contained'
          color='primary'
          endIcon={<NavigateNextIcon />}
          onClick={props.goToDashboard}
        >
          Add CAPA Details
        </Button>
      </Box>
    </Dialog>
  );
};
export default LaunchCapaSuccess;
