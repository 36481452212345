import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import BatchStatus from '../models/BatchStatus';
import Checkbox from '@material-ui/core/Checkbox';

//Icons
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
//files import
import CheckedAndApproved from '../models/checkedAndApproved';
import { useStyles } from './styles';
import ViewSupplierPO from './ViewSupplierPO';
import StatusManager from '../grnReceipts/StatusManager';
import EditSupplierPO from './EditSupplierPO';
import DownloadSupplierPO from './DownloadSupplierPO';

import CustomTableCell from '../../common/models/table/CustomTableCell';
import {
  presentFormatedData,
  presentDate,
} from '../functionalComponents/dataFormatFunctions';

function ExpandableRow(props) {
  const [open, setOpen] = useState(false);
  const { POLineRecordDetails, GRDetails } = props.row;
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const { collabType } = localStorage;
  const { row, grnFeatures } = props;

  let scheduleDateFlag = useSelector(
    (state) =>
      state.pcmp.common.reports.rmpmGrnFeatures?.data.features
        .scheduledDeliveryDate
  );

  const handleSelectAllClick = (event) => {
    const newSelecteds = GRDetails.map((n) => n.schrockenSupplierPOBatchID);
    if (event.target.checked) {
      setSelected(newSelecteds);
      newSelecteds.map((n) => {
        const data = {
          [n]: event.target.checked,
        };
        return props.selectedArrayValue(data);
      });
      return;
    } else if (event.target.checked === false) {
      setSelected([]);
      const dataArray = [];
      newSelecteds.map((n) => {
        return dataArray.push({ [n]: event.target.checked });
      });
      props.selectedArrayValue(dataArray);
      return;
    }
    setSelected([]);
    props.selectedArrayValue([]);
  };

  const handleClick = (event, name) => {
    if (event.target.checked === true) {
      const data = {
        [name]: event.target.checked,
      };
      setSelected([...selected, name]);
      props.selectedArrayValue(data);
    } else if (!event.target.checked) {
      const data = { [name]: event.target.checked };
      setSelected(selected.filter((n) => n !== name));
      props.selectedArrayValue(data);
    }
  };

  const isSelected = (name) => {
    if (props.selected.indexOf(name.toString()) === -1) {
      return false;
    } else if (props.selected.indexOf(name.toString()) !== -1) {
      return true;
    }
  };

  React.useEffect(() => {
    setSelected(
      GRDetails.filter((n) => {
        if (
          props.selected.indexOf(n.schrockenSupplierPOBatchID.toString()) !== -1
        ) {
          return n.schrockenSupplierPOBatchID.toString();
        }
        return 0;
      })
    );
  }, [props.selected, GRDetails]);
  const labelId = `enhanced-table-checkbox-${POLineRecordDetails.schrockenSupplierPOLineID}`;
  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        key={POLineRecordDetails.schrockenSupplierPOLineID}
        style={{
          backgroundColor: props.keys % 2 === 0 ? '#FFF' : '#0000000a',
        }}
      >
        <TableCell
          size='small'
          style={{
            width: '1%',
            maxWidth: '0.2rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          padding='checkbox'
          style={{
            width: '1%',
            maxWidth: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Checkbox
            checked={
              GRDetails.length > 0 && selected.length === GRDetails.length
            }
            indeterminate={
              selected.length > 0 && selected.length < GRDetails.length
            }
            onChange={(event) => handleSelectAllClick(event)}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        <CustomTableCell scope='row' align='left'>
          {presentFormatedData(POLineRecordDetails.supplierPurchaseOrderNumber)}
        </CustomTableCell>
        {scheduleDateFlag && <CustomTableCell />}
        {/** Table cell to skip sch. del date */}

        <CustomTableCell
          align={
            POLineRecordDetails.materialReceivedDate === null ||
            POLineRecordDetails.materialReceivedDate === undefined
              ? 'center'
              : 'left'
          }
        >
          {presentDate(POLineRecordDetails.materialReceivedDate)}
        </CustomTableCell>
        <CustomTableCell
          align={
            POLineRecordDetails.materialType === null ||
            POLineRecordDetails.materialType === ''
              ? 'center'
              : 'left'
          }
          hideCell
        >
          {/* {presentFormatedData(POLineRecordDetails.materialType)} */}
          {presentFormatedData(
            POLineRecordDetails.materialType === 'ROH'
              ? 'RM'
              : POLineRecordDetails.materialType === 'VERP'
              ? 'PM'
              : POLineRecordDetails.materialType
          )}
        </CustomTableCell>
        <CustomTableCell align='left' elementStyle={{ maxWidth: '0px' }}>
          {POLineRecordDetails.materialCode}/
          <br />
          {POLineRecordDetails.materialName}
        </CustomTableCell>
        <CustomTableCell align='left'>
          {presentFormatedData(POLineRecordDetails.unitOfMeasurementOrdered)}
        </CustomTableCell>
        <CustomTableCell align='right' hideCell>
          {presentFormatedData(POLineRecordDetails.quantityOrdered)}
        </CustomTableCell>
        <CustomTableCell
          align='right'
          //  hideCell
        >
          {presentFormatedData(POLineRecordDetails.totalQuantityReceived)}
        </CustomTableCell>
        <CustomTableCell align='right'>
          {presentFormatedData(POLineRecordDetails.quantityYetToReceive)}
        </CustomTableCell>
        <CustomTableCell align='left' hideCell>
          {presentFormatedData(POLineRecordDetails.supplierName)}
        </CustomTableCell>
        <CustomTableCell hideCell align='left'>
          -
        </CustomTableCell>
        <CustomTableCell hideCell align='center'>
          -
        </CustomTableCell>
        <CustomTableCell align='left'>
          {BatchStatus(POLineRecordDetails.statusID)}
        </CustomTableCell>
        <CustomTableCell align='center'>-</CustomTableCell>
        <CustomTableCell className={classes.changingWidth} align='left'>
          <div className={classes.singleAction}>
            <div className={classes.emptyLogo}></div>
            {row.batchStatusID !== 1 &&
              grnFeatures.bulkDownloadGrFiles === true && (
                <DownloadSupplierPO
                  schrockenSupplierPOBatchID={GRDetails}
                  supplierPurchaseOrderNumber={
                    POLineRecordDetails.supplierPurchaseOrderNumber
                  }
                  expandableRow={true}
                />
              )}
          </div>
        </CustomTableCell>
      </TableRow>
      {open === true &&
        GRDetails.map((row, i) => {
          const isItemSelected = isSelected(
            GRDetails[i].schrockenSupplierPOBatchID
          );
          const labelId = `enhanced-table-checkbox-${GRDetails[i].schrockenSupplierPOBatchID}`;
          return (
            <TableRow
              key={GRDetails[i].schrockenSupplierPOBatchID}
              style={{
                backgroundColor: props.keys % 2 === 0 ? '#FFF' : '#0000000a',
              }}
            >
              <TableCell
                style={{
                  width: '1%',
                  maxWidth: '0.2rem',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                <IconButton />
              </TableCell>
              <TableCell
                onClick={(event) =>
                  handleClick(event, GRDetails[i].schrockenSupplierPOBatchID)
                }
                padding='checkbox'
                aria-checked={isItemSelected}
                style={{
                  width: '1%',
                  maxWidth: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <Checkbox
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>
              <CustomTableCell scope='row' align='left'>
                {POLineRecordDetails.supplierPurchaseOrderNumber}
              </CustomTableCell>
              {scheduleDateFlag && (
                <CustomTableCell>
                  {presentDate(POLineRecordDetails.scheduledDeliveryDate)}
                </CustomTableCell>
              )}
              <CustomTableCell
                align={row.materialReceivedDate === null ? 'center' : 'left'}
              >
                {presentDate(row.materialReceivedDate)}
              </CustomTableCell>
              {/* </div> */}
              <CustomTableCell key='hide' align='left' hideCell>
                {/* {presentFormatedData(POLineRecordDetails.materialType)} */}
                {presentFormatedData(
                  POLineRecordDetails.materialType === 'ROH'
                    ? 'RM'
                    : POLineRecordDetails.materialType === 'VERP'
                    ? 'PM'
                    : POLineRecordDetails.materialType
                )}
              </CustomTableCell>

              <CustomTableCell align='left' elementStyle={{ maxWidth: '0px' }}>
                {POLineRecordDetails.materialCode}/
                <br />
                {POLineRecordDetails.materialName}
              </CustomTableCell>
              <CustomTableCell align='left'>
                {presentFormatedData(
                  POLineRecordDetails.unitOfMeasurementOrdered
                )}
              </CustomTableCell>
              <CustomTableCell align='right' hideCell>
                {presentFormatedData(POLineRecordDetails.quantityOrdered)}
              </CustomTableCell>
              <CustomTableCell
                align='right'
                // hideCell
              >
                {row.actualQuantityReceived}
              </CustomTableCell>
              <CustomTableCell align='right'>
                {row.quantityYetToReceive}
              </CustomTableCell>
              <CustomTableCell align='left' hideCell>
                {presentFormatedData(POLineRecordDetails.supplierName)}
              </CustomTableCell>
              <CustomTableCell align='left' hideCell>
                {row.approvedBy === null ||
                row.approvedBy === '' ||
                row.approvedBy === undefined ? (
                  row.rejectedBy === null ||
                  row.rejectedBy === '' ||
                  row.rejectedBy === undefined ? (
                    '-'
                  ) : (
                    <CheckedAndApproved name={row.rejectedBy} />
                  )
                ) : (
                  <CheckedAndApproved name={row.approvedBy} />
                )}
              </CustomTableCell>
              <CustomTableCell hideCell>
                {presentFormatedData(row.numberMRN)}
              </CustomTableCell>
              <CustomTableCell align='left'>
                {BatchStatus(row.batchStatusID)}
              </CustomTableCell>
              <CustomTableCell>
                {presentFormatedData(row.numberGRN)}
              </CustomTableCell>
              <CustomTableCell className={classes.changingWidth} align='left'>
                <div className={classes.actions}>
                  <div>
                    {POLineRecordDetails.statusID === 9 ||
                    POLineRecordDetails.statusID === 10 ||
                    POLineRecordDetails.statusID === 11
                      ? null
                      : StatusManager(
                          row.batchStatusID,
                          POLineRecordDetails,
                          row,
                          grnFeatures
                        )}
                    {/* {null} */}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {collabType !== 'Pharma Co' &&
                      grnFeatures.editGrRecord === true &&
                      POLineRecordDetails.statusID !== 9 &&
                      POLineRecordDetails.statusID !== 10 &&
                      POLineRecordDetails.statusID !== 11 &&
                      (row.batchStatusID === 2 || row.batchStatusID === 4) && (
                        <EditSupplierPO
                          POLineRecordDetails={POLineRecordDetails}
                          row={row}
                          status={row.batchStatusID}
                          saveRecord={true}
                        />
                      )}
                    {grnFeatures.viewGrRecord === true && (
                      <ViewSupplierPO
                        supplierPurchaseOrderNumber={
                          POLineRecordDetails.supplierPurchaseOrderNumber
                        }
                        materialCode={POLineRecordDetails.materialCode}
                        schrockenSupplierPOBatchID={
                          row.schrockenSupplierPOBatchID
                        }
                      />
                    )}
                    {row.batchStatusID !== 1 &&
                      grnFeatures.bulkDownloadGrFiles === true && (
                        <DownloadSupplierPO
                          schrockenSupplierPOBatchID={
                            GRDetails[0].schrockenSupplierPOBatchID
                          }
                          supplierPurchaseOrderNumber={
                            POLineRecordDetails.supplierPurchaseOrderNumber
                          }
                        />
                      )}
                  </div>
                </div>
              </CustomTableCell>
            </TableRow>
          );
        })}
    </React.Fragment>
  );
}

export default ExpandableRow;
