import axios from 'axios';
import { appUrl } from '../../../../../utils/config';

import {
  PHARMA_LINE_BATCH_ITEMS,
  PHARMA_FETCH_RM_AVAILABILITY,
  PHARMA_FETCH_PM_AVAILABILITY,
  PHARMA_BATCH_UPDATE,
} from '../types';

export const pharmaCoFetchLineBatchItems = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${localStorage.roleName}/fetchBatchLineItemsList`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PHARMA_LINE_BATCH_ITEMS,
          payload: res.data.batchLineItems,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const pharmaUpdateBatchItem = (data, roleName) => (dispatch) => {
  // axios
  //   .post(
  //     `${appUrl}/${localStorage.appName}/${
  //       localStorage.network
  //     }/Pharma Co/${data.roleName}/updateMaximumRetailPrice`,
  //     data
  //   )
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${roleName}/updateBatchDetails`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PHARMA_BATCH_UPDATE,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

// rm pm Actions to be separated
export const pharmaCoFetchRMAvailability = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/fetchRMAvailability`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PHARMA_FETCH_RM_AVAILABILITY,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const pharmaCoFetchPMAvailability = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/fetchPMAvailability`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PHARMA_FETCH_PM_AVAILABILITY,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};
