import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MonthSelector from '../../../common/selectors/MonthSelector';
import Grid from '@material-ui/core/Grid';
import DateRangeSelector from '../../../common/selectors/DateRangeSelector';
import CMOSelector from '../../../common/selectors/CMOSelector';

const SelectionBar = (props) => {
  return (
    <div 
      style={{
        // margin: '0rem -1.3rem 0rem -1.8rem',
        margin: '0px -16px 0 -16px',
        backgroundColor: '#F0F0F0',
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: 'row',
        padding: '0rem 0.45rem 0.75rem 0.45rem',
        overflow: 'hidden',
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems='center'
        style={{ marginLeft: '0rem' }}
      >
        {/* <div
        style={{
          margin: "0rem -1.3rem 0rem -1.8rem",
          backgroundColor: "#F5F5F5",
          flexDirection: "row",
          padding: "1.2rem 0.5rem"
        }}
      > */}
        {props.collabType === 'Pharma Co' && (
          <Grid item xs={5} sm={3} md={3} lg={4} style={{ marginTop: '1rem' }}>
            <CMOSelector
              CMOMember={props.CMOMember}
              handleChange={props.handleChange}
              cmoMembersList={props.cmoMembersList}
              needFullWidth={true}
            />
          </Grid>
        )}
        <Grid item xs={7} sm={3} md={3} lg={4}>
          <MonthSelector
            handleDateChange={props.handleDateChange}
            selectedDate={props.selectedDate}
            monthError={props.monthError}
            needFullWidth={true}
          />
        </Grid>
        {/* <Grid item xs={1}> */}
        <Typography
          variant='body2'
          style={{
            marginTop: '1rem',
            marginRight: '0.5rem',
          }}
        >
          (OR)
        </Typography>
        {/* </Grid> */}
        <Grid item xs={6} sm={3} md={3} lg={4}>
          <DateRangeSelector
            handleDateChange={props.handleDateChange}
            reportStartDate={props.reportStartDate}
            reportEndDate={props.reportEndDate}
            startDateError={props.startDateError}
            endDateError={props.endDateError}
            needFullWidth={true}
          />
        </Grid>
        <Grid item xs={5} sm={3} md={3} lg={4}>
          <Button
            // style={{ marginLeft: "-5rem" }}
            variant='contained'
            onClick={props.fetchReport}
            color='secondary'
          >
            View Report
          </Button>
        </Grid>
        {/* </div> */}
      </Grid>
    </div>
  );
};

export default SelectionBar;
