import React from 'react';
// import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from '../../sasl/scheduleAgreements/saslStyles';
import ScheduleAgreementNumber from './ScheduleAgreementNumber';
import DsBatchNumber from './DsBatchNumber';
import { presentDate } from '../../functionalComponents/dataFormatFunctions';

const Parameters = (props) => {
  const classes = useStyles();
  const { auditTrailObj, handleSelectChange, sLOptions, dsOptions } = props;

  const selectionFields = (recordTypeID) => {
    switch (recordTypeID) {
      case 'vendorBatchNumber':
        return <DsBatchNumber {...props} />;
      case 'saDeliverySchedule':
        return (
          <React.Fragment>
            <ScheduleAgreementNumber {...props} />
            <Grid item xs={12} sm={6} md={2}>
              <FormControl className={classes.formControl} fullWidth>
                <Select
                  labelId='sa-line'
                  id='sa-line-id'
                  name='saLine'
                  value={auditTrailObj.saLine}
                  onChange={(e) => handleSelectChange(e)}
                  variant='outlined'
                  classes={{
                    outlined: classes.outlined,
                  }}
                  disabled={auditTrailObj.saNumber === '' ? true : false}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value={'null'} disabled>
                    Select Line
                  </MenuItem>
                  {/* <MenuItem value={'10'}>10</MenuItem> */}

                  {auditTrailObj.saNumber === '' || sLOptions.length === 0 ? (
                    <div
                      style={{
                        textAlign: 'center',
                        color: '#2621f3',
                      }}
                    >
                      <MenuItem value={'null'} disabled>
                        No Scheduled Line Item Found
                      </MenuItem>
                    </div>
                  ) : (
                    sLOptions.map((line, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={line.scheduleLineItemNumber}
                        >
                          {line.scheduleLineItemNumber} - {line.fgMaterialCode}/
                          {line.fgMaterialName}
                        </MenuItem>
                      );
                    })
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl className={classes.formControl} fullWidth>
                <Select
                  labelId='sa-ds'
                  id='sa-ds-id'
                  name='ds'
                  value={auditTrailObj.ds}
                  onChange={(e) => handleSelectChange(e)}
                  variant='outlined'
                  classes={{
                    outlined: classes.outlined,
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  disabled={
                    auditTrailObj.saNumber === '' ||
                    auditTrailObj.saLine === 'null'
                      ? true
                      : false
                  }
                >
                  <MenuItem value={'null'} disabled>
                    Select Ds
                  </MenuItem>

                  {auditTrailObj.saNumber === '' ||
                  auditTrailObj.saLine === '' ||
                  dsOptions.length === 0 ? (
                    <div
                      style={{
                        textAlign: 'center',
                        color: '#2621f3',
                      }}
                    >
                      <MenuItem value={'null'} disabled>
                        No Delivery Schedule Found
                      </MenuItem>
                    </div>
                  ) : (
                    dsOptions.map((option, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={option.schrockenDeliveryScheduleID}
                        >
                          {option.scheduledDeliveryMonth} -
                          {presentDate(option.deliveryDate)}
                          {/* {option.deliveryScheduleLineCounter} */}
                        </MenuItem>
                      );
                    })
                  )}
                </Select>
              </FormControl>
            </Grid>
          </React.Fragment>
        );
      case 'sa':
        return <ScheduleAgreementNumber {...props} />;
      case 'saLine':
        return (
          <React.Fragment>
            <ScheduleAgreementNumber {...props} />
            <Grid item xs={12} sm={6} md={2}>
              <FormControl className={classes.formControl} fullWidth>
                <Select
                  labelId='sa-line'
                  id='sa-line-id'
                  name='saLine'
                  value={auditTrailObj.saLine}
                  onChange={(e) => handleSelectChange(e)}
                  variant='outlined'
                  classes={{
                    outlined: classes.outlined,
                  }}
                  disabled={auditTrailObj.saNumber === '' ? true : false}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value={'null'} disabled>
                    Select Line
                  </MenuItem>
                  {/* <MenuItem value={'10'}>10</MenuItem> */}

                  {auditTrailObj.saNumber === '' || sLOptions.length === 0 ? (
                    <div
                      style={{
                        textAlign: 'center',
                        color: '#2621f3',
                      }}
                    >
                      <MenuItem value={'null'} disabled>
                        No Scheduled Line Item Found
                      </MenuItem>
                    </div>
                  ) : (
                    sLOptions.map((line, index) => {
                      return (
                        <MenuItem key={index} value={line.schrockenSLID}>
                          {line.scheduleLineItemNumber} - {line.fgMaterialCode}/
                          {line.fgMaterialName}
                        </MenuItem>
                      );
                    })
                  )}
                </Select>
              </FormControl>
            </Grid>
          </React.Fragment>
        );
      default:
        return <div>Something went wrong.</div>;
    }
  };
  return selectionFields(props.recordTypeID);
};

export default Parameters;
