import React, { Component } from 'react';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Grid, Button, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';

import {
  fetchBatchItemStatusList,
  updateBatchItem,
  cmoFetchLineBatchItems,
} from '../../../../containers/actions/cmo/addBatchItemActions';

import {
  cmoUploadFiles,
  cmoUploadInvoiceFile,
  cmoDownloadGRNFile,
} from '../../../../containers/actions/cmo/filesActions';

import { getLineItemDetails } from '../../../../containers/actions/pharmaCo/purchaseOrderActions';

import { fetchCustomUploadedFiles } from '../../../../containers/actions/common/fileActions';

import UploadFiles from './UploadFiles';

// Backdrop while updating
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  input: {
    display: 'none',
  },
  remove: {
    display: 'flex',
    alignItems: 'center',
    color: 'red',
    cursor: 'pointer',
  },
  sameRow: {
    display: 'flex',
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
});

class ViewEditBatchItem extends Component {
  state = {
    disableSaveButton: false,
    batchDetails: this.props.batchItemDetails,
    reconciliationSheetFile: '',
    reconciliationSheetFileName: '',
    packInspectionRecordFile: '',
    packInspectionRecordFileName: '',
    COAFile: '',
    COAFileName: '',
    LRFile: '',
    LRFileName: '',
    EWayBillFile: '',
    EWayBillFileName: '',
    invoiceFile: '',
    invoiceFileName: '',
    shipperWeighingRecordFile: '',
    shipperWeighingRecordFileName: '',
    docType: '',
    batchStatusList: [],
    errors: {
      batchNumber: '',
      batchSize: '',
      producedQuantity: '',
      pendingQuantity: '',
      unitOfMeasurement: '',
      status: '',
      batchStartDate: '',
      batchDuration: '',
      batchEndDate: '',
      shipmentDate: '',
      QCDate: '',
      reconciliationSheetFile: false,
      packInspectionRecordFile: false,
      COAFile: false,
      LRFile: false,
      EWayBillFile: false,
      invoiceFile: false,
      shipperWeighingRecordFile: false,
    },
    customFiles: [],
    prevCustomFilesReadOnly: [],
    prevCustomFiles: [],
    prevCustomFilesCount: 0,
    customFilesDescription: [],
    isMaxFiles: false,
  };

  componentDidMount() {
    let data = {
      ...this.props.params,
      collabType: 'CMO',
      schrockenBatchID: this.props.batchItemDetails.schrockenBatchID,
      statusCode: this.props.batchItemDetails.statusCode,
      purchaseOrderNumber: this.props.batchItemDetails.purchaseOrderNumber,
      lineNumber: this.props.batchItemDetails.lineNumber,
      schrockenPOLineID: this.props.batchItemDetails.schrockenPOLineID,
    };
    this.props.fetchBatchItemStatusList(data);
    this.props.fetchCustomUploadedFiles(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.batchStatusList) {
      this.setState({ batchStatusList: nextProps.batchStatusList });
    }

    if (nextProps.batchUpdated !== this.props.batchUpdated) {
      this.props.toggleViewBatchScreen();
      this.props.callBackAPIFunction();
    }

    if (nextProps.files && nextProps.files !== this.props.files) {
      this.setState({
        prevCustomFiles: nextProps.files.customFiles,
        prevCustomFilesReadOnly: nextProps.files.customFiles,
        prevCustomFilesCount: nextProps.files.customFiles.length,
      });
    }
  }

  // Edit input fields
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'batchSize') {
      let parsedValue = value !== '' ? parseInt(value) : '';
      this.setState((prevState) => ({
        batchDetails: {
          ...prevState.batchDetails,
          [name]: parsedValue,
          pendingQuantity: parsedValue,
        },
        errors: {
          batchNumber: '',
          batchSize: '',
          producedQuantity: '',
          pendingQuantity: '',
          unitOfMeasurement: false,
          status: '',
          QCDate: false,
          batchDuration: false,
          batchStartDate: false,
          shipmentDate: false,
        },
      }));
    } else if (name === 'producedQuantity' && this.state.batchSize !== '') {
      let quantity = this.state.batchDetails.batchSize - value;

      this.setState((prevState) => ({
        batchDetails: {
          ...prevState.batchDetails,
          [name]: parseInt(value),
          pendingQuantity: quantity > 0 ? parseInt(quantity) : 0,
        },
        errors: {
          batchNumber: '',
          batchSize: '',
          producedQuantity: '',
          pendingQuantity: '',
          unitOfMeasurement: '',
          status: '',
          batchStartDate: '',
          batchDuration: '',
          batchEndDate: '',
          shipmentDate: '',
          QCDate: '',
        },
      }));
    } else if (name === 'batchDuration') {
      let { batchStartDate } = this.state.batchDetails;
      let batchEndDate = Moment(batchStartDate).add(value, 'd');
      this.setState((prevState) => ({
        batchDetails: {
          ...prevState.batchDetails,
          [name]: value,
          batchEndDate: batchEndDate,
        },
        errors: {
          batchNumber: '',
          batchSize: '',
          producedQuantity: '',
          pendingQuantity: '',
          unitOfMeasurement: '',
          status: '',
          batchStartDate: '',
          batchDuration: '',
          batchEndDate: '',
          shipmentDate: '',
          QCDate: '',
        },
      }));
    }
    this.setState((prevState) => ({
      batchDetails: {
        ...prevState.batchDetails,
        [name]: value,
      },
      errors: {
        batchNumber: '',
        batchSize: '',
        producedQuantity: '',
        pendingQuantity: '',
        unitOfMeasurement: '',
        status: '',
        batchStartDate: '',
        batchDuration: '',
        batchEndDate: '',
        shipmentDate: '',
        QCDate: '',
      },
    }));

    if (e.target.name === 'statusCode') {
      this.setState({
        isMaxFiles: false,
        customFiles: [],
        customFileDescription: [],
      });
      if (this.props.batchItemDetails.statusCode !== e.target.value) {
        this.setState({ prevCustomFilesCount: 0, prevCustomFiles: [] });
      } else {
        this.setState({
          prevCustomFilesCount: this.state.prevCustomFiles.length,
          prevCustomFiles: this.state.prevCustomFilesReadOnly,
        });
      }
    }
  };

  // Edit Date Fields
  handleDateChange = (date, name) => {
    this.setState((prevState) => ({
      batchDetails: {
        ...prevState.batchDetails,
        [name]: date != null ? date.toISOString() : date,
      },
      errors: {
        ...prevState.errors,
        [name]: '',
      },
    }));
  };

  handleUploadFile = (event) => {
    if (event.target.name === 'reconciliationSheetFile') {
      if (event.target.files) {
        let allowedExtensions =
          /(\.csv|\.xls|\.xlsx|\.pdf|\.jpeg|\.png|\.jpg|\.bmp|\.doc|\.docx)$/i;
        let filePath = event.target.value;
        if (!allowedExtensions.exec(filePath)) {
          alert('Invalid file type');
          return false;
        }
        this.setState({
          reconciliationSheetFileName: event.target.files[0].name,
          reconciliationSheetFile: event.target.files[0],
        });
        event.target.value = null;
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            reconciliationSheetFile: false,
          },
        }));
      }
    }
    if (event.target.name === 'packInspectionRecordFile') {
      if (event.target.files) {
        let allowedExtensions =
          /(\.csv|\.xls|\.xlsx|\.pdf|\.jpeg|\.png|\.jpg|\.bmp|\.doc|\.docx)$/i;
        let filePath = event.target.value;
        if (!allowedExtensions.exec(filePath)) {
          alert('Invalid file type');
          return false;
        }
        this.setState({
          packInspectionRecordFileName: event.target.files[0].name,
          packInspectionRecordFile: event.target.files[0],
        });
        event.target.value = null;
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            packInspectionRecordFile: false,
          },
        }));
      }
    }

    if (event.target.name === 'COAFile') {
      if (event.target.files) {
        let allowedExtensions =
          /(\.csv|\.xls|\.xlsx|\.pdf|\.jpeg|\.png|\.jpg|\.bmp|\.doc|\.docx)$/i;
        let filePath = event.target.value;
        if (!allowedExtensions.exec(filePath)) {
          alert('Invalid file type');
          return false;
        }
        this.setState({
          COAFileName: event.target.files[0].name,
          COAFile: event.target.files[0],
        });
        event.target.value = null;
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            COAFile: false,
          },
        }));
      }
    }
    if (event.target.name === 'shipperWeighingRecordFile') {
      if (event.target.files) {
        let allowedExtensions =
          /(\.csv|\.xls|\.xlsx|\.pdf|\.jpeg|\.png|\.jpg|\.bmp|\.doc|\.docx)$/i;
        let filePath = event.target.value;
        if (!allowedExtensions.exec(filePath)) {
          alert('Invalid file type');
          return false;
        }
        this.setState({
          shipperWeighingRecordFileName: event.target.files[0].name,
          shipperWeighingRecordFile: event.target.files[0],
        });
        event.target.value = null;
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            shipperWeighingRecordFile: false,
          },
        }));
      }
    }

    if (event.target.name === 'EWayBillFile') {
      if (event.target.files) {
        let allowedExtensions =
          /(\.csv|\.xls|\.xlsx|\.pdf|\.jpeg|\.png|\.jpg|\.bmp|\.doc|\.docx)$/i;
        let filePath = event.target.value;
        if (!allowedExtensions.exec(filePath)) {
          alert('Invalid file type');
          return false;
        }
        this.setState({
          EWayBillFileName: event.target.files[0].name,
          EWayBillFile: event.target.files[0],
        });
        event.target.value = null;
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            EWayBillFile: false,
          },
        }));
      }
    }
    if (event.target.name === 'LRFile') {
      if (event.target.files) {
        let allowedExtensions =
          /(\.csv|\.xls|\.xlsx|\.pdf|\.jpeg|\.png|\.jpg|\.bmp|\.doc|\.docx)$/i;
        let filePath = event.target.value;
        if (!allowedExtensions.exec(filePath)) {
          alert('Invalid file type');
          return false;
        }
        this.setState({
          LRFileName: event.target.files[0].name,
          LRFile: event.target.files[0],
        });
        event.target.value = null;
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            LRFile: false,
          },
        }));
      }
    }
    if (event.target.name === 'invoiceFile') {
      if (event.target.files) {
        let allowedExtensions =
          /(\.csv|\.xls|\.xlsx|\.pdf|\.jpeg|\.png|\.jpg|\.bmp|\.doc|\.docx)$/i;
        let filePath = event.target.value;
        if (!allowedExtensions.exec(filePath)) {
          alert('Invalid file type');
          return false;
        }
        this.setState({
          invoiceFileName: event.target.files[0].name,
          invoiceFile: event.target.files[0],
        });
        event.target.value = null;
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            invoiceFile: false,
          },
        }));
      }
    }
  };

  removeFile = (file) => {
    let fileName = `${file}Name`;
    this.setState({
      [file]: '',
      [fileName]: '',
    });
  };

  // Form data validation
  validate = () => {
    let isError = false;

    let { batchDetails } = this.state;

    let errors = {
      batchNumber: '',
      batchSize: '',
      producedQuantity: '',
      pendingQuantity: '',
      unitOfMeasurement: '',
      batchStartDate: '',
      batchDuration: '',
      batchEndDate: '',
      shipmentDate: '',
      QCDate: '',
      status: '',
      reconciliationSheetFile: false,
      packInspectionRecordFile: false,
      COAFile: false,
      LRFile: false,
      EWayBillFile: false,
      invoiceFile: false,
      shipperWeighingRecordFile: false,
    };

    if (batchDetails.batchNumber === '') {
      isError = true;
      errors.batchNumber = 'Please Enter Batch Number';
    } else if (batchDetails.batchSize === '') {
      isError = true;
      errors.batchSize = 'Please Enter Batch Size';
    } else if (batchDetails.unitOfMeasurement === '') {
      isError = true;
      errors.unitOfMeasurement = 'Please Enter UOM';
    } else if (batchDetails.batchStartDate === '') {
      isError = true;
      errors.batchStartDate = 'Please Enter Start Date';
    } else if (batchDetails.batchDuration === '') {
      isError = true;
      errors.batchDuration = 'Please Enter Duration';
    } else if (batchDetails.statusCode > 7 && batchDetails.QCDate === null) {
      isError = true;
      errors.QCDate = 'Please Enter QC Date';
    } else if (
      batchDetails.QCDate !== null &&
      batchDetails.QCDate < batchDetails.batchEndDate
    ) {
      isError = true;
      errors.QCDate = 'QC Date should be after Batch end date';
    } else if (
      batchDetails.statusCode > 10 &&
      batchDetails.shipmentDate === null
    ) {
      isError = true;
      errors.shipmentDate = 'Please Enter Shipping Date';
    } else if (batchDetails.statusCode === '') {
      isError = true;
      errors.status = 'Please Select Status';
    } else if (
      batchDetails.shipmentDate !== null &&
      batchDetails.batchEndDate !== null &&
      batchDetails.shipmentDate <= batchDetails.batchEndDate
    ) {
      isError = true;
      errors.shipmentDate = true;
      errors.shipmentDate = 'Shipping date should be greater than end date';
    } else if (
      batchDetails.statusCode === 7 &&
      parseInt(batchDetails.producedQuantity) === 0
    ) {
      isError = true;
      errors.producedQuantity = 'Please Enter Produced Quantity';
    } else if (
      parseInt(batchDetails.producedQuantity) > 0 &&
      parseInt(batchDetails.producedQuantity) > parseInt(batchDetails.batchSize)
    ) {
      isError = true;
      errors.producedQuantity =
        'Produced Quantity can not be greater than Batch Size';
    } else if (
      batchDetails.statusCode === 7 &&
      batchDetails.reconciliationSheetFileName === '' &&
      this.state.reconciliationSheetFile === ''
    ) {
      isError = true;
      errors.reconciliationSheetFile = true;
    } else if (
      batchDetails.statusCode === 7 &&
      batchDetails.packInspectionRecordFileName === '' &&
      this.state.packInspectionRecordFile === ''
    ) {
      isError = true;
      errors.packInspectionRecordFile = true;
    } else if (
      batchDetails.statusCode === 9 &&
      batchDetails.COAFileName === '' &&
      this.state.COAFile === ''
    ) {
      isError = true;
      errors.COAFile = true;
    } else if (
      batchDetails.statusCode === 7 &&
      batchDetails.shipperWeighingRecordFileName === '' &&
      this.state.shipperWeighingRecordFile === ''
    ) {
      isError = true;
      errors.shipperWeighingRecordFile = true;
    } else if (
      batchDetails.statusCode === 11 &&
      batchDetails.invoiceFileName === '' &&
      this.state.invoiceFile === ''
    ) {
      isError = true;
      errors.invoiceFile = true;
    } else if (
      batchDetails.statusCode === 11 &&
      batchDetails.LRFileName === '' &&
      this.state.LRFile === ''
    ) {
      isError = true;
      errors.LRFile = true;
    } else if (
      batchDetails.statusCode === 11 &&
      batchDetails.EWayBillFileName === '' &&
      this.state.EWayBillFile === ''
    ) {
      isError = true;
      errors.EWayBillFile = true;
    }

    for (var i = 0; i < this.state.customFiles.length; i++) {
      if (
        !this.state.customFiles[i].fileName ||
        !this.state.customFilesDescription[i]
      ) {
        isError = true;
      }
    }

    this.setState({ errors });
    return isError;
  };

  onSubmit = () => {
    let error = this.validate();
    if (!error) {
      this.setState({ disableSaveButton: true });
      let data = {
        ...this.props.params,
        ...this.state.batchDetails,
      };
      if (this.state.batchDetails.statusCode === 7) {
        const files = new FormData();
        files.append(
          'shipperWeighingRecordFile',
          this.state.shipperWeighingRecordFile
        );
        files.append(
          'reconciliationSheetFile',
          this.state.reconciliationSheetFile
        );
        files.append(
          'packInspectionRecordFile',
          this.state.packInspectionRecordFile
        );
        files.append('batchData', JSON.stringify(data));
        files.append('ecosystemId', this.props.params.ecosystemId);
        files.append('collabType', 'CMO');
        files.append('roleName', this.props.params.roleName);
        files.append('organizationName', this.props.params.organizationName);
        files.append(
          'customFilesDescription',
          this.state.customFilesDescription
        );

        // Adding custom files if present
        if (this.state.customFiles.length > 0) {
          for (let i = 0; i < this.state.customFiles.length; i++) {
            files.append('customFiles', this.state.customFiles[i].fileData);

            if (i === this.state.customFiles.length - 1) {
              this.props.updateBatchItem(files, this.props.params.roleName);
            }
          }
        } else {
          this.props.updateBatchItem(files, this.props.params.roleName);
        }
      } else if (
        this.state.batchDetails.statusCode === 9 &&
        this.state.COAFileName !== ''
      ) {
        const filesCOA = new FormData();
        filesCOA.append('COAFile', this.state.COAFile);
        filesCOA.append('batchData', JSON.stringify(data));
        filesCOA.append('ecosystemId', this.props.params.ecosystemId);
        filesCOA.append('collabType', 'CMO');
        filesCOA.append('roleName', this.props.params.roleName);
        filesCOA.append('organizationName', this.props.params.organizationName);
        filesCOA.append(
          'customFilesDescription',
          this.state.customFilesDescription
        );

        // Adding custom files if present
        if (this.state.customFiles.length > 0) {
          for (let i = 0; i < this.state.customFiles.length; i++) {
            filesCOA.append('customFiles', this.state.customFiles[i].fileData);

            if (i === this.state.customFiles.length - 1) {
              this.props.updateBatchItem(filesCOA, this.props.params.roleName);
            }
          }
        } else {
          this.props.updateBatchItem(filesCOA, this.props.params.roleName);
        }
      } else if (this.state.batchDetails.statusCode === 11) {
        const filesFive = new FormData();
        filesFive.append('invoiceFile', this.state.invoiceFile);
        filesFive.append('deliveryChallanFile', this.state.deliveryChallanFile);
        filesFive.append('LRFile', this.state.LRFile);
        filesFive.append('EWayBillFile', this.state.EWayBillFile);
        filesFive.append('batchData', JSON.stringify(data));
        filesFive.append('ecosystemId', this.props.params.ecosystemId);
        filesFive.append('collabType', 'CMO');
        filesFive.append('roleName', this.props.params.roleName);
        filesFive.append(
          'organizationName',
          this.props.params.organizationName
        );
        filesFive.append(
          'customFilesDescription',
          this.state.customFilesDescription
        );

        // Adding custom files if present
        if (this.state.customFiles.length > 0) {
          for (let i = 0; i < this.state.customFiles.length; i++) {
            filesFive.append('customFiles', this.state.customFiles[i].fileData);

            if (i === this.state.customFiles.length - 1) {
              this.props.updateBatchItem(filesFive, this.props.params.roleName);
            }
          }
        } else {
          this.props.updateBatchItem(filesFive, this.props.params.roleName);
        }
      } else {
        const updateBatch = new FormData();
        updateBatch.append('batchData', JSON.stringify(data));
        updateBatch.append('ecosystemId', this.props.params.ecosystemId);
        updateBatch.append('collabType', 'CMO');
        updateBatch.append('roleName', this.props.params.roleName);
        updateBatch.append(
          'organizationName',
          this.props.params.organizationName
        );
        updateBatch.append(
          'customFilesDescription',
          this.state.customFilesDescription
        );

        // Adding custom files if present
        if (this.state.customFiles.length > 0) {
          for (let i = 0; i < this.state.customFiles.length; i++) {
            updateBatch.append(
              'customFiles',
              this.state.customFiles[i].fileData
            );

            if (i === this.state.customFiles.length - 1) {
              this.props.updateBatchItem(
                updateBatch,
                this.props.params.roleName
              );
            }
          }
        } else {
          this.props.updateBatchItem(updateBatch, this.props.params.roleName);
        }
      }
    }
  };

  downloadGRN = () => {
    let getGRN = {
      ...this.props.params,
      collabType: 'CMO',
      schrockenBatchID: this.props.batchItemDetails.schrockenBatchID,
      docType: 'GRN File',
    };
    this.props.cmoDownloadGRNFile(getGRN);
  };

  addCustomFile = () => {
    if (this.state.customFiles.length + this.state.prevCustomFilesCount >= 10) {
      this.setState({ isMaxFiles: true });
    } else {
      this.setState({
        customFiles: [
          ...this.state.customFiles,
          { fileData: false, fileName: false },
        ],
        customFilesDescription: [...this.state.customFilesDescription, false],
      });
    }
  };

  updateCustomFileDescription = (e, index) => {
    let customFilesDescription = this.state.customFilesDescription;
    // Set file description
    customFilesDescription[index] = e.target.value;

    // Set changed custom files state
    this.setState({ customFilesDescription });
  };

  uploadCustomFile = (e, index) => {
    if (e.target.name === 'customFile') {
      if (e.target.files) {
        let allowedExtensions =
          /(\.csv|\.xls|\.xlsx|\.pdf|\.jpeg|\.png|\.jpg|\.bmp|\.doc|\.docx)$/i;
        let filePath = e.target.value;
        if (!allowedExtensions.exec(filePath)) {
          alert('Invalid file type');
          return false;
        }

        let customFiles = this.state.customFiles;

        // Set file description
        customFiles[index].fileName = e.target.files[0].name;
        customFiles[index].fileData = e.target.files[0];

        // Set changed custom files state
        this.setState({ customFiles });
        e.target.value = null;
      }
    }
  };

  removeCustomFileRow = (e, index) => {
    let customFiles = this.state.customFiles;
    let customFilesDescription = this.state.customFilesDescription;

    customFiles.splice(index, 1);
    customFilesDescription.splice(index, 1);

    // Set changed custom files state
    this.setState({ isMaxFiles: false, customFiles, customFilesDescription });
  };

  render() {
    const { batchDetails, customFiles, isMaxFiles } = this.state;
    const { classes } = this.props;

    const dateFormat = sessionStorage.selectedDateFormat
      ? sessionStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    return (
      <React.Fragment>
        <Backdrop
          className={classes.backdrop}
          open={this.state.disableSaveButton}
        >
          <CircularProgress color='primary' />
          <Typography variant='caption'>Please wait...</Typography>
        </Backdrop>
        <Typography
          variant='h6'
          style={{ textAlign: 'center', marginTop: '4rem' }}
        >
          View/Update Batch Item Details
        </Typography>
        <Card
          style={{
            margin: '2rem 4rem 3rem 4rem',
          }}
        >
          <CardContent
            style={{
              margin: '1.25rem',
            }}
          >
            <Grid container spacing={2}>
              <Grid item sm={12} md={3}>
                <TextField
                  label='Batch Number'
                  name='batchNumber'
                  value={batchDetails.batchNumber}
                  disabled={true}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='Batch Size'
                  name='batchSize'
                  type='number'
                  disabled={batchDetails.statusCode < 7 ? false : true}
                  value={batchDetails.batchSize}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={this.state.errors.batchSize === '' ? false : true}
                  helperText={this.state.errors.batchSize}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='Produced Quantity'
                  name='producedQuantity'
                  disabled={batchDetails.statusCode === 7 ? false : true}
                  type='number'
                  value={
                    batchDetails.statusCode === 7
                      ? batchDetails.producedQuantity === 0
                        ? ''
                        : batchDetails.producedQuantity
                      : batchDetails.producedQuantity
                  }
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={
                    this.state.errors.producedQuantity === '' ? false : true
                  }
                  helperText={this.state.errors.producedQuantity}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='Pending Quantity'
                  name='pendingQuantity'
                  type='number'
                  disabled
                  value={batchDetails.pendingQuantity}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={
                    this.state.errors.pendingQuantity === '' ? false : true
                  }
                  helperText={this.state.errors.pendingQuantity}
                />
              </Grid>
              <Grid
                item
                sm={12}
                md={3}
                style={{
                  marginTop: '1rem',
                }}
              >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant='inline'
                    InputAdornmentProps={{ position: 'start' }}
                    inputVariant='outlined'
                    label='Start Date'
                    disabled={batchDetails.statusCode > 5 ? true : false}
                    format={dateFormat}
                    placeholder={dateFormat}
                    value={batchDetails.batchStartDate}
                    minDate={new Date()}
                    onChange={(date) =>
                      this.handleDateChange(date, 'batchStartDate')
                    }
                    error={
                      this.state.errors.batchStartDate === '' ? false : true
                    }
                    helperText={this.state.errors.batchStartDate}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item sm={12} md={3}>
                <TextField
                  label='Duration (Days)'
                  disabled={batchDetails.statusCode > 5 ? true : false}
                  name='batchDuration'
                  type='number'
                  inputProps={{ min: 0, step: 5 }}
                  value={batchDetails.batchDuration}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={this.state.errors.batchDuration === '' ? false : true}
                  helperText={this.state.errors.batchDuration}
                />
              </Grid>
              <Grid
                item
                sm={12}
                md={3}
                style={{
                  marginTop: '1rem',
                }}
              >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disabled
                    disableToolbar
                    variant='inline'
                    InputAdornmentProps={{ position: 'start' }}
                    inputVariant='outlined'
                    label='End Date'
                    format={dateFormat}
                    placeholder={dateFormat}
                    value={batchDetails.batchEndDate}
                    onChange={(date) =>
                      this.handleDateChange(date, ' batchEndDate')
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  disabled
                  label='Units Of Measurement'
                  name='unitOfMeasurement'
                  value={batchDetails.unitOfMeasurement}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={
                    this.state.errors.unitOfMeasurement === '' ? false : true
                  }
                  helperText={this.state.errors.unitOfMeasurement}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant='inline'
                    InputAdornmentProps={{ position: 'start' }}
                    inputVariant='outlined'
                    label='QC Date'
                    format={dateFormat}
                    placeholder={dateFormat}
                    value={batchDetails.QCDate}
                    minDate={
                      batchDetails.batchEndDate === null
                        ? new Date()
                        : batchDetails.batchEndDate
                    }
                    onChange={(date) => this.handleDateChange(date, 'QCDate')}
                    error={this.state.errors.QCDate === '' ? false : true}
                    helperText={this.state.errors.QCDate}
                    disabled={
                      this.props.batchItemDetails.statusCode >= 11
                        ? true
                        : false
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item sm={12} md={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant='inline'
                    InputAdornmentProps={{ position: 'start' }}
                    inputVariant='outlined'
                    label='Shipping Date'
                    format={dateFormat}
                    placeholder={dateFormat}
                    value={batchDetails.shipmentDate}
                    minDate={
                      batchDetails.batchEndDate === null
                        ? new Date()
                        : batchDetails.batchEndDate
                    }
                    onChange={(date) =>
                      this.handleDateChange(date, 'shipmentDate')
                    }
                    error={this.state.errors.shipmentDate === '' ? false : true}
                    helperText={this.state.errors.shipmentDate}
                    disabled={
                      this.props.batchItemDetails.statusCode >= 11
                        ? true
                        : false
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='Maximum Retail Price'
                  name='maximumRetailPrice'
                  disabled
                  type='number'
                  placeholder='0.00'
                  value={batchDetails.maximumRetailPrice}
                  onChange={this.handleChange}
                  variant='outlined'
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  label='Status'
                  select
                  fullWidth
                  name='statusCode'
                  disabled={
                    this.props.batchItemDetails.statusCode >= 11 ? true : false
                  }
                  value={batchDetails.statusCode}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                  error={this.state.errors.status === '' ? false : true}
                  helperText={this.state.errors.status}
                  style={{
                    marginTop: '0rem',
                  }}
                >
                  <MenuItem value={this.props.batchItemDetails.statusCode}>
                    {this.props.batchItemDetails.statusText}
                  </MenuItem>
                  {this.state.batchStatusList.map((ele, i) => (
                    <MenuItem key={i} value={ele.statusCode}>
                      {ele.statusText}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            {/* Add Custom File Button */}
            <Grid container>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginBottom: '0.5rem',
                }}
              >
                <Typography variant='body1' style={{ marginRight: '0.5rem' }}>
                  Upload Custom File
                </Typography>
                <Fab
                  size='small'
                  color='secondary'
                  aria-label='Upload File'
                  onClick={this.addCustomFile}
                >
                  <AddIcon />
                </Fab>
              </div>
              {isMaxFiles && (
                <Typography
                  variant='body2'
                  style={{
                    color: 'red',
                    marginLeft: 'auto',
                    marginBottom: '0.5rem',
                  }}
                >
                  Maximum file uploads limit(10) reached
                </Typography>
              )}
            </Grid>

            {/* Upload Mandatory Files */}
            <UploadFiles
              handleUploadFile={this.handleUploadFile}
              reconciliationSheetFileName={
                this.state.reconciliationSheetFileName
              }
              packInspectionRecordFileName={
                this.state.packInspectionRecordFileName
              }
              COAFileName={this.state.COAFileName}
              shipperWeighingRecordFileName={
                this.state.shipperWeighingRecordFileName
              }
              invoiceFileName={this.state.invoiceFileName}
              LRFileName={this.state.LRFileName}
              EWayBillFileName={this.state.EWayBillFileName}
              removeFile={this.removeFile}
              errors={this.state.errors}
              status={batchDetails.statusCode}
              // Existing files
              uploadedReconciliationSheet={
                batchDetails.reconciliationSheetFileName
              }
              uploadedPIR={batchDetails.packInspectionRecordFileName}
              uploadedInvoice={batchDetails.invoiceFileName}
              uploadedCOA={batchDetails.COAFileName}
              uploadedSRF={batchDetails.shipperWeighingRecordFileName}
              uploadedLRFile={batchDetails.LRFileName}
              uploadedEWayFile={batchDetails.EWayBillFileName}
            />

            {/* Previous Custom Files */}
            <Grid container spacing={2} style={{ marginTop: '1rem' }}>
              <Typography variant='body1' style={{ width: '100%' }}>
                Custom Files
              </Typography>
              {this.state.prevCustomFiles.map((file, index) => (
                <Grid item xs={6} md={3} key={index}>
                  <Button
                    variant='contained'
                    color='primary'
                    component='span'
                    disabled
                    style={{ display: 'flex' }}
                  >
                    <Typography
                      variant='caption'
                      style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {file.customFileDescription}
                    </Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>

            {/* Upload Custom Files */}

            {customFiles.map((file, index) => (
              <Grid container spacing={2} key={index}>
                <Grid
                  item
                  xs={6}
                  md={3}
                  className={classes.sameRow}
                  style={{ flexDirection: 'column' }}
                >
                  <TextField
                    label='File Type (Eg: QA)'
                    name='description'
                    value={file.fileDescription}
                    onChange={(e) => {
                      this.updateCustomFileDescription(e, index);
                    }}
                    margin='normal'
                    variant='outlined'
                    error={!this.state.customFilesDescription[index]}
                  />
                </Grid>
                <Grid item xs={6} md={3} className={classes.sameRow}>
                  <input
                    className={classes.input}
                    id={index}
                    type='file'
                    name='customFile'
                    onChange={(e) => {
                      this.uploadCustomFile(e, index);
                    }}
                    accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
                  />
                  <label htmlFor={index}>
                    <Button
                      variant='contained'
                      color='primary'
                      component='span'
                    >
                      <Typography
                        variant='caption'
                        style={{ color: '#ffffff' }}
                      >
                        Upload File
                      </Typography>
                    </Button>
                  </label>
                </Grid>
                <Grid item md={3} className={classes.sameRow}>
                  {!file.fileName ? (
                    <Typography variant='caption' style={{ color: 'red' }}>
                      Upload File
                    </Typography>
                  ) : (
                    <Typography variant='caption'>{file.fileName}</Typography>
                  )}
                </Grid>
                <Grid item md={3} className={classes.sameRow}>
                  <div
                    style={{
                      backgroundColor: 'red',
                      borderRadius: '50%',
                      width: '0.8rem',
                      height: '0.7rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      marginLeft: '0.4rem',
                      paddingBottom: '0.15rem',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => this.removeCustomFileRow(e, index)}
                  >
                    -
                  </div>
                </Grid>
              </Grid>
            ))}
          </CardContent>
        </Card>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Button
              variant='contained'
              onClick={this.onSubmit}
              disabled={this.state.disableSaveButton}
              style={{
                backgroundColor: this.state.disableSaveButton
                  ? 'grey'
                  : 'green',
                borderRadius: '1rem',
                color: '#ffffff',
              }}
            >
              Save All Changes
            </Button>
            <Typography
              variant='body1'
              onClick={this.props.toggleViewBatchScreen}
              style={{
                marginLeft: '2.5rem',
                cursor: 'pointer',
              }}
            >
              Cancel X
            </Typography>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = (state) => ({
  batchStatusList: state.pcmp.cmo.batchItems.batchStatusList,
  batchUpdated: state.pcmp.cmo.batchItems.batchUpdated,
  files: state.pcmp.common.files,
});

export default withStyles(styles)(
  connect(mapStatetoProps, {
    fetchBatchItemStatusList,
    updateBatchItem,
    cmoFetchLineBatchItems,
    getLineItemDetails,
    cmoUploadFiles,
    cmoUploadInvoiceFile,
    cmoDownloadGRNFile,
    fetchCustomUploadedFiles,
  })(ViewEditBatchItem)
);
