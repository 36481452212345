import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
//files
import Header from '../models/NewHeader';
import QuickTable from '../models/QuickViewTable';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Backdrop from '@material-ui/core/Backdrop';

import {
  quickViewAction,
  fetchUOM,
  updateGRN,
  lineSource,
} from '../../../containers/actions/cmo/shortFallsActions';
import StyledDialog from '../../../../common/models/Dialog';
import POItemTable from '../models/POItemTable';
import ReceivedQuantity from '../models/ReceivedQuantity';
import QualityAssurance from '../models/QualityAssuranceInfo';
import ChallanInfo from '../models/ChallanInfo';
import OtherInfo from '../models/OtherInfo';
import InvoiceInfo from '../models/InvoiceInfo';
import StyledButton from '../../../../common/models/Button';
import { SupplierPOContext } from '../grnReceipts/context';

const Styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

class UploadFiles extends Component {
  static contextType = SupplierPOContext;
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      resError: null,

      POLineRecordDetails: {},
      POBatchRecordDetails: {},
      challanInformation: {},
      invoiceInformation: {},
      qualityAssuranceInfo: {},
      receivedQuantityInfo: {},

      //edit dialog states
      openPOItem: false,
      openReceived: false,
      openQuality: false,
      openChallan: false,
      openInvoice: false,
      openOtherInfo: false,
      //consent
      verifyConsent: false,
      //updateGRN
      isSubmitForApproval: false,
      recordSaved: false,
      submitRecord: false,
      loading: true,
      isCommitDisabled: false,
    };
    this.errorRef = React.createRef();
  }

  componentDidMount() {
    // console.log(this.props);
    const data = {
      supplierPurchaseOrderNumber:
        this.props.POLineRecordDetails.supplierPurchaseOrderNumber,
      materialCode: this.props.POLineRecordDetails.materialCode,
      schrockenSupplierPOBatchID: this.props.row.schrockenSupplierPOBatchID,
    };
    this.props.quickViewAction(data);
  }

  componentWillUnmount() {
    if (lineSource) {
      lineSource.cancel('Page not loaded');
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.supplierPOData !== prevProps.supplierPOData &&
      this.props.supplierPOData.status === false
    ) {
      let errorKey = Object.keys(this.props.supplierPOData.errorDescription)[0];
      this.setState({
        resError: this.props.supplierPOData.errorDescription[errorKey],
        errors: {
          [errorKey]: true,
        },
      });
    }
    if (
      this.props.updateGRNData !== prevProps.updateGRNData &&
      this.props.updateGRNData.status === true
    ) {
      this.setState({
        submitRecord: true,
        isCommitDisabled: false,
      });
      this.context.fetchGRLineItems();
    }
    if (
      this.props.updateGRNData !== prevProps.updateGRNData &&
      this.props.updateGRNData.status === false
    ) {
      this.setState({
        isCommitDisabled: false,
      });
      let errorKey = Object.keys(this.props.updateGRNData.errorDescription)[0];
      this.setState({
        resError: this.props.updateGRNData.errorDescription[errorKey],
        errors: {
          [errorKey]: true,
        },
      });
    }
    if (
      this.props.supplierPOData &&
      this.props.supplierPOData !== prevProps.supplierPOData
    ) {
      let { POLineRecordDetails } = this.props.supplierPOData;
      let {
        POBatchRecordDetails,
        challanInformation,
        invoiceInformation,
        qualityAssuranceInfo,
        receivedQuantityInfo,
      } = this.props.supplierPOData.GRDetails;
      this.setState({
        POLineRecordDetails,
        POBatchRecordDetails,
        challanInformation,
        invoiceInformation,
        qualityAssuranceInfo,
        receivedQuantityInfo,
        loading: false,
      });
    }
  }

  handlingDeleteFile = (i, name) => {
    if (name === 'COAFiles') {
      console.log('delete');
      if (this.state.qualityAssuranceInfo.COAFiles[i].grFileType === 'buffer') {
        let newdel = this.state.qualityAssuranceInfo.COAFiles.filter(
          (file) =>
            file.grFileName !==
            this.state.qualityAssuranceInfo.COAFiles[i].grFileName
        );
        this.setState({
          qualityAssuranceInfo: {
            ...this.state.qualityAssuranceInfo,
            COAFiles: newdel,
          },
        });
      } else {
        // COAFiles[i].markForDelete=1;
        let markDelFile = this.state.qualityAssuranceInfo.COAFiles.map((file) =>
          file.grFileHash ===
          this.state.qualityAssuranceInfo.COAFiles[i].grFileHash
            ? { ...file, markForDelete: true }
            : file
        );
        this.setState({
          qualityAssuranceInfo: {
            ...this.state.qualityAssuranceInfo,
            COAFiles: markDelFile,
          },
        });
      }
    }

    //challan files deleting in Parent File
    if (name === 'challanFiles') {
      if (
        this.state.challanInformation.challanFiles[i].grFileType === 'buffer'
      ) {
        let newdel = this.state.challanInformation.challanFiles.filter(
          (file) =>
            file.grFileName !==
            this.state.challanInformation.challanFiles[i].grFileName
        );
        this.setState({
          challanInformation: {
            ...this.state.challanInformation,
            challanFiles: newdel,
          },
        });
      } else {
        let markDelFile = this.state.challanInformation.challanFiles.map(
          (file) =>
            file.grFileHash ===
            this.state.challanInformation.challanFiles[i].grFileHash
              ? { ...file, markForDelete: true }
              : file
        );

        this.setState({
          challanInformation: {
            ...this.state.challanInformation,
            challanFiles: markDelFile,
          },
        });
      }
    }

    //invoice files deleting in Parent File
    if (name === 'invoiceFiles') {
      if (
        this.state.invoiceInformation.invoiceFiles[i].grFileType === 'buffer'
      ) {
        let newdel = this.state.invoiceInformation.invoiceFiles.filter(
          (file) =>
            file.grFileName !==
            this.state.invoiceInformation.invoiceFiles[i].grFileName
        );
        this.setState({
          invoiceInformation: {
            ...this.state.invoiceInformation,
            invoiceFiles: newdel,
          },
        });
      } else {
        let markDelFile = this.state.invoiceInformation.invoiceFiles.map(
          (file) =>
            file.grFileHash ===
            this.state.invoiceInformation.invoiceFiles[i].grFileHash
              ? { ...file, markForDelete: true }
              : file
        );

        this.setState({
          invoiceInformation: {
            ...this.state.invoiceInformation,
            invoiceFiles: markDelFile,
          },
        });
      }
    }

    //invoice files deleting in Parent File
    if (name === 'otherFiles') {
      if (
        this.state.POBatchRecordDetails.otherFiles[i].grFileType === 'buffer'
      ) {
        let newdel = this.state.POBatchRecordDetails.otherFiles.filter(
          (file) =>
            file.grFileName !==
            this.state.POBatchRecordDetails.otherFiles[i].grFileName
        );
        this.setState({
          POBatchRecordDetails: {
            ...this.state.POBatchRecordDetails,
            otherFiles: newdel,
          },
        });
      } else {
        let markDelFile = this.state.POBatchRecordDetails.otherFiles.map(
          (file) =>
            file.grFileHash ===
            this.state.POBatchRecordDetails.otherFiles[i].grFileHash
              ? { ...file, markForDelete: true }
              : file
        );

        this.setState({
          POBatchRecordDetails: {
            ...this.state.POBatchRecordDetails,
            otherFiles: markDelFile,
          },
        });
      }
    }
  };

  handlingFiles = (file, name) => {
    if (name === 'COAFiles') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          COAFiles: [...this.state.qualityAssuranceInfo.COAFiles, file],
        },
      });
    }
    if (name === 'challanFiles') {
      this.setState({
        challanInformation: {
          ...this.state.challanInformation,
          challanFiles: [...this.state.challanInformation.challanFiles, file],
        },
      });
    }
    if (name === 'InvoiceFiles') {
      this.setState({
        invoiceInformation: {
          ...this.state.invoiceInformation,
          invoiceFiles: [...this.state.invoiceInformation.invoiceFiles, file],
        },
      });
    }
    if (name === 'otherFiles') {
      this.setState({
        POBatchRecordDetails: {
          ...this.state.POBatchRecordDetails,
          otherFiles: [...this.state.POBatchRecordDetails.otherFiles, file],
        },
      });
    }
    // this.setState((prevState, props) => ({
    //   COAFiles:[ prevState.COAFiles,file]
    //   // COAFiles:file
    // }));
  };

  handleVerifyConsent = () => {
    this.setState({
      verifyConsent: !this.state.verifyConsent,
      errors: {},
      resError: null,
    });
  };

  handleSubmitRecord = () => {
    const {
      POLineRecordDetails,
      POBatchRecordDetails,
      receivedQuantityInfo,
      invoiceInformation,
      qualityAssuranceInfo,
      challanInformation,
    } = this.state;

    let isError = false; // this.validate();
    this.setState({
      isCommitDisabled: true,
    });
    if (!isError) {
      const data = {
        PODetails: {
          POLineRecordDetails,
          GRDetails: {
            POBatchRecordDetails: {
              ...POBatchRecordDetails,
              isSubmitForApproval: true,
            },
            receivedQuantityInfo,
            qualityAssuranceInfo,
            challanInformation,
            invoiceInformation,
          },
        },
      };
      this.props.updateGRN(data, 'Please wait. New files are being saved');
    }
  };

  handleCloseRecord = () => {
    this.setState({
      submitRecord: false,
      recordSaved: false,
      resError: null,
    });
    this.props.handleClose();
  };
  handleOpenDialog = (name) => {
    this.setState({
      [name]: true,
    });
  };
  handleChange = (e, name) => {
    if (name === 'transporterName') {
      this.setState({
        POBatchRecordDetails: {
          ...this.state.POBatchRecordDetails,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'lorryReceiptNumber') {
      this.setState({
        POBatchRecordDetails: {
          ...this.state.POBatchRecordDetails,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'actualQuantityReceived') {
      this.setState({
        receivedQuantityInfo: {
          ...this.state.receivedQuantityInfo,
          [e.target.name]: Number(e.target.value),
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'unitOfMeasurementQA') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'samplingQuantityQA') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          [e.target.name]: Number(e.target.value),
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'analyticalReportNumber') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'analyticalReportNumber') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'batchNumberQA') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'samplingMaterialNumberQA') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'challanNumber') {
      this.setState({
        challanInformation: {
          ...this.state.challanInformation,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'invoiceNumber') {
      this.setState({
        invoiceInformation: {
          ...this.state.invoiceInformation,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'otherInformationRemarks') {
      let { value } = e.target;
      this.setState((prevState) => ({
        POBatchRecordDetails: {
          ...prevState.POBatchRecordDetails,
          [name]: value,
        },
        resError: null,
        errors: {},
      }));
    }
  };
  handleSavePOItemData = (data) => {
    this.setState({
      POBatchRecordDetails: data,
    });
    this.handleCloseSectionDialog('openPOItem');
  };

  handleSaveReceivedQuantityData = (data) => {
    this.setState({
      receivedQuantityInfo: data,
    });
    this.handleCloseSectionDialog('openReceived');
  };
  handleSaveQualityAssuranceData = (data) => {
    this.setState({
      qualityAssuranceInfo: data,
    });
    this.handleCloseSectionDialog('openQuality');
  };
  handleSaveChallanInformationData = (data) => {
    this.setState({
      challanInformation: data,
    });
    this.handleCloseSectionDialog('openChallan');
  };
  handleSaveInvoiceInformationData = (data) => {
    this.setState({
      invoiceInformation: data,
    });
    this.handleCloseSectionDialog('openInvoice');
  };
  handleSaveOtherInformationData = (data) => {
    this.setState({
      POBatchRecordDetails: data,
    });
    this.handleCloseSectionDialog('openOtherInfo');
  };

  handleCloseSectionDialog = (name) => {
    this.setState({
      [name]: false,
    });
  };
  render() {
    const {
      POLineRecordDetails,
      POBatchRecordDetails,
      receivedQuantityInfo,
      qualityAssuranceInfo,
      challanInformation,
      invoiceInformation,
      errors,
      resError,
      loading,
      isCommitDisabled,
    } = this.state;
    //  classes,
    const { handleClose } = this.props;
    if (resError /*&& errorRef* /* + other conditions */) {
      this.errorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      // window.scrollTo(0, errorRef.current.offsetTop);
    }
    return (
      <React.Fragment>
        <Header
          handleClose={handleClose}
          title='Upload Files'
          subtitle='Raw Material (RM) / Packaging Material (PM) Record'
          padding={true}
        >
          <div ref={this.errorRef}>
            {loading === true ? (
              <Grid container justify='center'>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '25%',
                    minWidth: '60vw',
                  }}
                >
                  <CircularProgress color='primary' thickness={7} />
                </div>
              </Grid>
            ) : (
              <React.Fragment>
                <Grid container justify='center'>
                  <Grid item xs={12} sm={6} md={10}>
                    <Grid container justify='center'>
                      {resError && (
                        <Grid
                          container
                          justify='center'
                          style={{ padding: '1rem 0' }}
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <Typography
                              variant='h6'
                              style={{
                                border: '1px solid #FF0000',
                                backgroundColor: '#FFF0F0',
                                opacity: '1',
                                textAlign: 'center',
                                padding: '0.8rem',
                                fontSize: '0.875rem',
                              }}
                            >
                              {resError}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <QuickTable
                      unitOfMeasurement={
                        receivedQuantityInfo.unitOfMeasurementReceived
                      }
                      quantity={POLineRecordDetails.quantityOrdered}
                      actualQuantityRecieved={
                        receivedQuantityInfo.actualQuantityReceived
                      }
                    />

                    <POItemTable
                      {...this.props}
                      POLineRecordDetails={POLineRecordDetails}
                      POBatchRecordDetails={POBatchRecordDetails}
                      handleOpenDialog={this.handleOpenDialog}
                      isRecievedExits={true}
                      errors={errors}
                    />
                    <hr />

                    <ReceivedQuantity
                      {...this.props}
                      receivedQuantityInfo={receivedQuantityInfo}
                      isEditExist={false}
                      handleOpenDialog={this.handleOpenDialog}
                      errors={errors}
                    />

                    <hr />

                    <QualityAssurance
                      {...this.props}
                      qualityAssuranceInfo={qualityAssuranceInfo}
                      isEditExist={false}
                      handleOpenDialog={this.handleOpenDialog}
                      handlingFiles={this.handlingFiles}
                      errors={errors}
                      parentHandlingDeleteFile={this.handlingDeleteFile}
                      isUploadFile={true}
                    />

                    <hr />

                    <ChallanInfo
                      {...this.props}
                      challanInformation={challanInformation}
                      isEditExist={false}
                      handleChange={this.handleChange}
                      handlingFiles={this.handlingFiles}
                      parentHandlingDeleteFile={this.handlingDeleteFile}
                      isUploadFile={true}
                      // handleOpenDialog={this.handleOpenDialog}
                      errors={errors}
                    />

                    <hr />

                    <InvoiceInfo
                      {...this.props}
                      invoiceInformation={invoiceInformation}
                      isEditExist={false}
                      isUploadFile={true}
                      handleOpenDialog={this.handleOpenDialog}
                      handlingFiles={this.handlingFiles}
                      handleChange={this.handleChange}
                      parentHandlingDeleteFile={this.handlingDeleteFile}
                      errors={errors}
                    />

                    <hr />
                    <OtherInfo
                      {...this.props}
                      POBatchRecordDetails={POBatchRecordDetails}
                      isEditExist={false}
                      handleOpenDialog={this.handleOpenDialog}
                      handlingFiles={this.handlingFiles}
                      parentHandlingDeleteFile={this.handlingDeleteFile}
                      isUploadFile={true}
                      errors={errors}
                    />
                  </Grid>
                </Grid>
                <Grid container justify='center'>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ textAlign: 'center', padding: '2rem' }}
                  >
                    <StyledButton
                      size='large'
                      onClick={this.handleSubmitRecord}
                      style={{
                        paddingLeft: '2rem',
                        paddingRight: '2rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                      }}
                      disabled={isCommitDisabled}
                    >
                      Commit Record
                    </StyledButton>
                    <Dialog
                      open={this.state.submitRecord}
                      onClose={this.handleCloseRecord}
                      fullWidth
                    >
                      <StyledDialog
                        icons={true}
                        handleClose={this.handleCloseRecord}
                        title='The Record has been Saved'
                      />
                    </Dialog>
                    {/* <Backdrop
                    style={{
                      zIndex: 10000,
                      color: '#fff',
                    }}
                    open={
                      this.props.backdropLoading
                        ? this.props.backdropLoading
                        : false
                    }
                    // onClick={handleClose}
                  >
                    <CircularProgress color='primary' thickness={5} />
                  </Backdrop> */}
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </div>
        </Header>
      </React.Fragment>
    );
  }
}

// export default Temp;

const mapStateToProps = (state) => ({
  supplierPOData: state.pcmp.cmo.shortFalls.quickView,
  UOM: state.pcmp.cmo.shortFalls.fetchUOMData,
  updateGRNData: state.pcmp.cmo.shortFalls.updateGRNData,
  // backdropLoading: state.pcmp.common.files.backdropLoading,
});

export default connect(mapStateToProps, {
  quickViewAction,
  fetchUOM,
  updateGRN,
})(withStyles(Styles)(UploadFiles));
