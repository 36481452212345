import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import { Grid, Button, Typography } from "@material-ui/core";

const styles = {
  button: {
    fontSize: "0.6rem",
    padding: "0.7rem 0.5rem"
  },
  input: {
    display: "none"
  },
  centerGrid: {
    display: "flex",
    alignItems: "center"
  },
  remove: {
    display: "flex",
    alignItems: "center",
    color: "red",
    cursor: "pointer"
  },
  start: {
    display: "flex",
    justifyContent: "flex-start"
  },
  gridMargin: {
    marginTop: "1.5rem"
  }
};

class UploadDownloadBatchFiles extends Component {
  state = {
    downloadGRN: false
  };

  render() {
    const {
      classes,
      handleUploadFile,
      errors,
      status,
      removeFile,
      uploadedReconciliationSheet,
      reconciliationSheetFileName,
      uploadedPIR,
      packInspectionRecordFileName,
      COAFileName,
      LRFileName,
      shipperWeighingRecordFileName,
      EWayBillFileName,
      invoiceFileName,
      uploadedCOA,

      uploadedLRFile,
      uploadedSRF,
      uploadedEWayFile,
      uploadedInvoice
    } = this.props;

    return (
      <React.Fragment>
        {status === 7 && (
          <React.Fragment>
            {/* Include new files required---reconciliation file--- */}
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                md={4}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {uploadedReconciliationSheet === "" ? (
                  <>
                    <input
                      className={classes.input}
                      id="upload-rsf-file"
                      type="file"
                      name="reconciliationSheetFile"
                      onChange={handleUploadFile}
                      accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
                    />
                    <label htmlFor="upload-rsf-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ display: "flex" }}
                      >
                        <Typography
                          variant="caption"
                          style={{ color: "#ffffff" }}
                        >
                          Reconciliation Sheet File
                        </Typography>
                      </Button>
                    </label>
                  </>
                ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled
                      style={{ display: "flex" }}
                    >
                      <Typography variant="caption" style={{ color: "#ffffff" }}>
                        Reconciliation Sheet File
                    </Typography>
                    </Button>
                  )}

                {errors.reconciliationSheetFile ? (
                  <Typography variant="body2" color="error">
                    Please upload Reconciliation Sheet File
                  </Typography>
                ) : (
                    reconciliationSheetFileName && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "0.4rem"
                        }}
                      >
                        <Typography variant="caption">
                          {reconciliationSheetFileName}
                        </Typography>
                        <div
                          style={{
                            backgroundColor: "red",
                            borderRadius: "50%",
                            width: "0.8rem",
                            height: "0.7rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            marginLeft: "0.3rem",
                            paddingBottom: "0.15rem",
                            cursor: "pointer"
                          }}
                          onClick={() => removeFile("reconciliationSheetFile")}
                        >
                          -
                      </div>
                      </div>
                    )
                  )}
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {uploadedPIR === "" ? (
                  <>
                    <input
                      className={classes.input}
                      id="upload-pir-file"
                      type="file"
                      name="packInspectionRecordFile"
                      onChange={handleUploadFile}
                      accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
                    />
                    <label htmlFor="upload-pir-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ display: "flex" }}
                      >
                        <Typography
                          variant="caption"
                          style={{ color: "#ffffff" }}
                        >
                          10% Pack Inspection Record File
                        </Typography>
                      </Button>
                    </label>
                  </>
                ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled
                      style={{ display: "flex" }}
                    >
                      <Typography variant="caption" style={{ color: "#ffffff" }}>
                        10% Pack Inspection Record File
                    </Typography>
                    </Button>
                  )}

                {errors.packInspectionRecordFile ? (
                  <Typography variant="body2" color="error">
                    Please upload Pack Inspection Record File
                  </Typography>
                ) : (
                    packInspectionRecordFileName && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "0.4rem"
                        }}
                      >
                        <Typography variant="caption">
                          {packInspectionRecordFileName}
                        </Typography>
                        <div
                          style={{
                            backgroundColor: "red",
                            borderRadius: "50%",
                            width: "0.8rem",
                            height: "0.7rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            marginLeft: "0.3rem",
                            paddingBottom: "0.15rem",
                            cursor: "pointer"
                          }}
                          onClick={() => removeFile("packInspectionRecordFile")}
                        >
                          -
                      </div>
                      </div>
                    )
                  )}
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {uploadedSRF === "" ? (
                  <>
                    <input
                      className={classes.input}
                      id="upload-shipperRecord-file"
                      type="file"
                      name="shipperWeighingRecordFile"
                      onChange={handleUploadFile}
                      accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
                    />
                    <label htmlFor="upload-shipperRecord-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ display: "flex" }}
                      >
                        <Typography
                          variant="caption"
                          style={{ color: "#ffffff" }}
                        >
                          Shipper Weighing Record File
                        </Typography>
                      </Button>
                    </label>
                  </>
                ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled
                      style={{ display: "flex" }}
                    >
                      <Typography variant="caption" style={{ color: "#ffffff" }}>
                        Shipper Weighing Record File
                    </Typography>
                    </Button>
                  )}

                {errors.shipperWeighingRecordFile ? (
                  <Typography variant="body2" color="error">
                    Please upload Shipper Weighing Record file
                  </Typography>
                ) : (
                    shipperWeighingRecordFileName && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "0.4rem"
                        }}
                      >
                        <Typography variant="caption">
                          {shipperWeighingRecordFileName}
                        </Typography>
                        <div
                          style={{
                            backgroundColor: "red",
                            borderRadius: "50%",
                            width: "0.8rem",
                            height: "0.7rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            marginLeft: "0.3rem",
                            paddingBottom: "0.15rem",
                            cursor: "pointer"
                          }}
                          onClick={() => removeFile("shipperWeighingRecordFile")}
                        >
                          -
                      </div>
                      </div>
                    )
                  )}
              </Grid>
            </Grid>
          </React.Fragment>
        )}

        {status === 9 && (
          <React.Fragment>
            {/* Include new files required---COA--- */}
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                md={4}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {uploadedCOA === "" ? (
                  <>
                    <input
                      className={classes.input}
                      id="upload-COA-file"
                      type="file"
                      name="COAFile"
                      onChange={handleUploadFile}
                      accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
                    />
                    <label htmlFor="upload-COA-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ display: "flex" }}
                      >
                        <Typography
                          variant="caption"
                          style={{ color: "#ffffff" }}
                        >
                          Upload COA File
                        </Typography>
                      </Button>
                    </label>
                  </>
                ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled
                    >
                      <Typography variant="caption">COA File</Typography>
                    </Button>
                  )}

                {errors.COAFile ? (
                  <Typography variant="body2" color="error">
                    Please upload COA file
                  </Typography>
                ) : (
                    COAFileName && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "0.4rem"
                        }}
                      >
                        <Typography variant="caption">{COAFileName}</Typography>
                        <div
                          style={{
                            backgroundColor: "red",
                            borderRadius: "50%",
                            width: "0.8rem",
                            height: "0.7rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            marginLeft: "0.3rem",
                            paddingBottom: "0.15rem",
                            cursor: "pointer"
                          }}
                          onClick={() => removeFile("COAFile")}
                        >
                          -
                      </div>
                      </div>
                    )
                  )}
              </Grid>
            </Grid>
          </React.Fragment>
        )}

        {status === 11 && (
          <React.Fragment>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                md={4}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {uploadedInvoice === "" ? (
                  <>
                    <input
                      className={classes.input}
                      id="upload-invoice"
                      multiple
                      type="file"
                      name="invoiceFile"
                      onChange={handleUploadFile}
                      accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
                    />
                    <label htmlFor="upload-invoice">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ display: "flex" }}
                      >
                        <Typography
                          variant="caption"
                          style={{ color: "#ffffff" }}
                        >
                          Invoice/Delivery Challan
                        </Typography>
                      </Button>
                    </label>
                  </>
                ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled
                    >
                      <Typography variant="caption">
                        Invoice/Delivery Challan
                    </Typography>
                    </Button>
                  )}

                {errors.invoiceFile ? (
                  <Typography variant="body2" color="error">
                    Please upload Invoice/Delivery Challan File
                  </Typography>
                ) : (
                    invoiceFileName && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "0.4rem"
                        }}
                      >
                        <Typography variant="caption">
                          {invoiceFileName}
                        </Typography>
                        <div
                          style={{
                            backgroundColor: "red",
                            borderRadius: "50%",
                            width: "0.8rem",
                            height: "0.7rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            marginLeft: "0.3rem",
                            paddingBottom: "0.15rem",
                            cursor: "pointer"
                          }}
                          onClick={() => removeFile("invoiceFile")}
                        >
                          -
                      </div>
                      </div>
                    )
                  )}
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {uploadedLRFile === "" ? (
                  <>
                    <input
                      className={classes.input}
                      id="upload-LRFile"
                      multiple
                      type="file"
                      name="LRFile"
                      onChange={handleUploadFile}
                      accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
                    />
                    <label htmlFor="upload-LRFile">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ display: "flex" }}
                      >
                        <Typography
                          variant="caption"
                          style={{ color: "#ffffff" }}
                        >
                          Upload LR File
                        </Typography>
                      </Button>
                    </label>
                  </>
                ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled
                    >
                      <Typography variant="caption">LR File</Typography>
                    </Button>
                  )}

                {errors.LRFile ? (
                  <Typography variant="body2" color="error">
                    Please upload LR File
                  </Typography>
                ) : (
                    LRFileName && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "0.4rem"
                        }}
                      >
                        <Typography variant="caption">{LRFileName}</Typography>
                        <div
                          style={{
                            backgroundColor: "red",
                            borderRadius: "50%",
                            width: "0.8rem",
                            height: "0.7rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            marginLeft: "0.3rem",
                            paddingBottom: "0.15rem",
                            cursor: "pointer"
                          }}
                          onClick={() => removeFile("LRFile")}
                        >
                          -
                      </div>
                      </div>
                    )
                  )}
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {uploadedEWayFile === "" ? (
                  <>
                    <input
                      className={classes.input}
                      id="upload-EWayFile"
                      multiple
                      type="file"
                      name="EWayBillFile"
                      onChange={handleUploadFile}
                      accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
                    />
                    <label htmlFor="upload-EWayFile">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ display: "flex" }}
                      >
                        <Typography
                          variant="caption"
                          style={{ color: "#ffffff" }}
                        >
                          E-Way Bill File
                        </Typography>
                      </Button>
                    </label>
                  </>
                ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled
                    >
                      <Typography variant="caption">EWay Bill File</Typography>
                    </Button>
                  )}

                {errors.EWayBillFile ? (
                  <Typography variant="body2" color="error">
                    Please upload E-Way Bill
                  </Typography>
                ) : (
                    EWayBillFileName && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "0.4rem"
                        }}
                      >
                        <Typography variant="caption">
                          {EWayBillFileName}
                        </Typography>
                        <div
                          style={{
                            backgroundColor: "red",
                            borderRadius: "50%",
                            width: "0.8rem",
                            height: "0.7rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            marginLeft: "0.3rem",
                            paddingBottom: "0.15rem",
                            cursor: "pointer"
                          }}
                          onClick={() => removeFile("EWayBillFile")}
                        >
                          -
                      </div>
                      </div>
                    )
                  )}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => ({
  grnDownloaded: state.pcmp.cmo.files.grnDownloaded
});
export default withStyles(styles)(
  connect(mapStatetoProps)(UploadDownloadBatchFiles)
);
