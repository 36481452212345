import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
// backdrop
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 10000,
    display: 'flex',
    color: '#fff',
    flexDirection: 'column',
  },
}));

export default function BackDropLoading () {
  const classes = useStyles();
// const { backdropLoading, comment } = props;

  let backdropLoading = useSelector((state) => state.pcmp.common.files.backdropLoading);
  let backdropText = useSelector((state) => state.pcmp.common.files.backdropText);

  return (
    <Backdrop
      className={classes.backdrop}
      open={backdropLoading ? backdropLoading : false}
    >
      <CircularProgress color='primary' thickness={5} />
      <Typography variant='body1'>{backdropText}</Typography>
    </Backdrop>
  );
}