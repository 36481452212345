import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #000000de',
    borderRadius: '0.5rem',
    padding: '0.3rem',
    width: '7rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #000000de',
    },
  },
  logoHeight: {
    height: 'inherit',
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
