import React from 'react';
import { TextField, MenuItem, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

const ShowStatus = (props) => {
  const { collabType } = localStorage;
  const {
    batchDetails,
    handleChange,
    classes,
    previousStatusID,
    previousStatusDisplayName,
    batchStatusList,
  } = props;
  // FG features check if billing doc is enabled
  const billingDocNumber = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.features.FG.fgBatch
        .billingDocumentNumberEnabled;
    }
    return false;
  });

  const SelectStatus = () => (
    <TextField
      select
      className={classes.root}
      name='SASLBatchStatusID'
      value={batchDetails.SASLBatchStatusID}
      onChange={handleChange}
      margin='normal'
      variant='outlined'
      size='small'
      style={{
        marginTop: '0rem',
      }}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
      }}
    >
      <MenuItem value={previousStatusID}>{previousStatusDisplayName}</MenuItem>

      {batchStatusList.length > 0 &&
        batchStatusList.map((ele, i) => (
          <MenuItem key={i} value={ele.nextSASLBatchStatusID}>
            {ele.nextStatusDisplayName}
          </MenuItem>
        ))}
    </TextField>
  );
  const DisplayStatus = () => (
    <Typography
      variant='body2'
      className={classes.fontPadding}
      style={{ fontSize: '1rem' }}
    >
      {previousStatusDisplayName}
    </Typography>
  );

  if (collabType === 'Pharma Co') {
    switch (batchDetails.SASLBatchStatusID) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 14:
      case 18: //BD generated
        return <DisplayStatus />;
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
        return <SelectStatus />;
      default:
        return null;
    }
  } else {
    switch (previousStatusID) {
      case 5: // QC Released
      case 13: // Resubmit Record
      case 15: // Repeat QC/QA
        return <DisplayStatus />;
      case 7:
        if (billingDocNumber) {
          return <DisplayStatus />;
        } else {
          return <SelectStatus />;
        }
      default:
        return <SelectStatus />;
    }
  }
};

export default ShowStatus;
