import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StyledButton from '../../common/models/Button';
import { loginUser } from '../../../containers/actions/authActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MainCards from '../../common/MainCards';

import { isValid, isValidEmail } from '../../common/helperFunctions/helper';

//Analytics
import { trackEvent } from '../../../tracking';
import BottomLink from '../../common/BottomLink';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      email: '',
      password: '',
      resError: null,
      message: '',
      disableLoginButton: false,
      errors: {},
    };
  }
  // recieving the login state from reducer as props
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.login !== prevProps.login &&
      this.props.login.status === false
    ) {
      let errorKey = Object.keys(this.props.login.errorDescription)[0];
      this.setState({
        resError: this.props.login.errorDescription[errorKey],
        disableLoginButton: false,
      });
    }
  }

  // to show the visibility icon
  handleClickPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      resError: null,
      errors: {},
    });
  };

  validate = () => {
    let error = false;
    const { email, password } = this.state;

    let errors = {};
    if (!isValid(email)) {
      error = true;
      errors.email = 'Please enter email';
    } else if (!isValidEmail(email)) {
      error = true;
      errors.email = 'Please enter valid email address';
    } else if (!isValid(password)) {
      error = true;
      errors.password = 'Please enter password';
    }
    this.setState({ errors });
    return error;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let isError = this.validate();
    if (!isError) {
      trackEvent('Login', 'Sign in clicked', null);
      this.setState({ disableLoginButton: true });
      const signInData = {
        email: this.state.email,
        password: this.state.password,
      };
      const { search } = this.props.location;
      const returnPage = search ? search.substring(search.indexOf('/pcmp')) : search;
      if (returnPage) {
        this.props.loginUser(signInData, this.props.history, false,returnPage);
      } else {
        this.props.loginUser(signInData, this.props.history);
      }
    }
  };

  render() {
    const {
      email,
      password,
      showPassword,
      resError,
      disableLoginButton,
      errors,
    } = this.state;
    return (
      <MainCards>
        <CssBaseline />

        <Typography
          variant='h4'
          style={{
            fontWeight: '500',
            textAlign: 'center',
          }}
        >
          Sign In
        </Typography>
        <Grid container justify='center'>
          {resError && <br />}
          {resError && (
            <Grid item xs={12} sm={10} md={9}>
              <Typography
                variant='h6'
                style={{
                  border: '1px solid #FF0000',
                  backgroundColor: '#FFF0F0',
                  opacity: '1',
                  textAlign: 'center',
                  padding: '0.8rem',
                  fontSize: '0.875rem',
                }}
              >
                {resError}
              </Typography>
            </Grid>
          )}
          {resError && <br />}
        </Grid>
        <form onSubmit={this.handleSubmit}>
          <Grid container justify='center'>
            <Grid item xs={12} sm={10} md={9}>
              <TextField
                variant='outlined'
                margin='normal'
                autoFocus
                fullWidth
                size='small'
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                // type='email'
                helperText={
                  errors.email
                    ? errors.email
                    : 'Enter your email address used for this ecosystem'
                }
                onChange={this.handleChange}
                value={email}
                error={errors.email ? true : false}
              />
            </Grid>
          </Grid>
          <Grid container justify='center'>
            <Grid item xs={12} sm={10} md={9}>
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                size='small'
                value={password}
                name='password'
                label='Password'
                helperText={
                  errors.password
                    ? errors.password
                    : 'Password is case sensitive'
                }
                error={errors.password ? true : false}
                type={showPassword === false ? 'password' : 'text'}
                id='password'
                autoComplete='password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='Toggle password visibility'
                        onClick={this.handleClickPassword}
                      >
                        {showPassword === false ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>

          {/*Please compare the changes*/}
          <Grid container justify='center'>
            <Grid
              item
              xs={8}
              sm={8}
              md={4}
              style={{
                textAlign: 'center',
                padding: '1rem',
              }}
            >
              <StyledButton
                type='submit'
                id='signin'
                variant='contained'
                color='primary'
                fullWidth
                disabled={disableLoginButton}
              >
                Sign In
              </StyledButton>
            </Grid>
          </Grid>
          <br />
          {/* <Grid style={{ position: 'relative' }}> */}
          <BottomLink
            typo='Forgot Password?'
            link='Reset your Password'
            to='/resetpassword'
          />
          {/* </Grid> */}
        </form>
      </MainCards>
    );
  }
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
};

// getting access to state through redux
const mapStateToProps = (state) => ({
  login: state.auth.login,
}); 

export default connect(mapStateToProps, { loginUser })(withRouter(Login));
