import React from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import BatchStatus from '../models/BatchStatus';
import Checkbox from '@material-ui/core/Checkbox';

//Icons
//files import
import ViewSupplierPO from './ViewSupplierPO';
import CheckedAndApproved from '../models/checkedAndApproved';
import { useStyles } from './styles';
import StatusManager from '../grnReceipts/StatusManager';
import EditSupplierPO from './EditSupplierPO';
import DownloadSupplierPO from './DownloadSupplierPO';

//Import custom table cell
import CustomTableCell from '../../common/models/table/CustomTableCell';
import {
  presentFormatedData,
  presentDate,
} from '../functionalComponents/dataFormatFunctions';

function SingleRow(props) {
  const classes = useStyles();
  const { POLineRecordDetails, GRDetails } = props.row;
  const { collabType } = localStorage;
  const { grnFeatures } = props;

  let scheduleDateFlag = useSelector(
    (state) =>
      state.pcmp.common.reports.rmpmGrnFeatures?.data.features
        .scheduledDeliveryDate
  );

  const isSelected = (name) => {
    if (props.selected.indexOf(name.toString()) === -1) {
      return false;
    } else if (props.selected.indexOf(name.toString()) !== -1) {
      return true;
    }
  };

  const handleClick = (event, name) => {
    if (event.target.checked === true) {
      const data = { [name]: event.target.checked };
      props.selectedArrayValue(data);
    } else if (!event.target.checked) {
      const data = { [name]: event.target.checked };
      props.selectedArrayValue(data);
    }
  };

  const isItemSelected = isSelected(GRDetails[0].schrockenSupplierPOBatchID);
  const labelId = `enhanced-table-checkbox-${GRDetails[0].schrockenSupplierPOBatchID}`;
  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        style={{
          backgroundColor: props.keys % 2 === 0 ? '#FFF' : '#0000000a',
        }}
        key={GRDetails[0].schrockenSupplierPOBatchID}
      >
        <TableCell
          style={{
            width: '1%',
            maxWidth: 0,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <IconButton aria-label='expand row' size='small' />
        </TableCell>
        <TableCell
          padding='checkbox'
          aria-checked={isItemSelected}
          style={{
            width: '1%',
            maxWidth: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Checkbox
            checked={isItemSelected}
            // size='small'
            inputProps={{ 'aria-labelledby': labelId }}
            onChange={(event) =>
              handleClick(event, GRDetails[0].schrockenSupplierPOBatchID)
            }
          />
        </TableCell>
        <CustomTableCell scope='row' align='left'>
          {presentFormatedData(POLineRecordDetails.supplierPurchaseOrderNumber)}
        </CustomTableCell>
        {scheduleDateFlag && (
          <CustomTableCell>
            {presentDate(POLineRecordDetails.scheduledDeliveryDate)}
          </CustomTableCell>
        )}
        <CustomTableCell
          align={GRDetails[0].materialReceivedDate === null ? 'center' : 'left'}
        >
          {presentDate(GRDetails[0].materialReceivedDate)}
        </CustomTableCell>
        <CustomTableCell
          key='hide'
          align={
            POLineRecordDetails.materialType === null ||
            POLineRecordDetails.materialType === ''
              ? 'center'
              : 'left'
          }
          hideCell
        >
          {presentFormatedData(
            POLineRecordDetails.materialType === 'ROH'
              ? 'RM'
              : POLineRecordDetails.materialType === 'VERP'
              ? 'PM'
              : POLineRecordDetails.materialType
          )}
        </CustomTableCell>
        <CustomTableCell align='left' elementStyle={{ maxWidth: '0px' }}>
          {POLineRecordDetails.materialCode}/
          <br />
          {POLineRecordDetails.materialName}
        </CustomTableCell>
        <CustomTableCell align='left'>
          {presentFormatedData(POLineRecordDetails.unitOfMeasurementOrdered)}
        </CustomTableCell>
        <CustomTableCell align='right' hideCell>
          {presentFormatedData(POLineRecordDetails.quantityOrdered)}
        </CustomTableCell>
        <CustomTableCell
          align='right'
          // hideCell
        >
          {presentFormatedData(POLineRecordDetails.totalQuantityReceived)}
        </CustomTableCell>
        <CustomTableCell align='right'>
          {presentFormatedData(POLineRecordDetails.quantityYetToReceive)}
        </CustomTableCell>
        <CustomTableCell align='left' hideCell>
          {presentFormatedData(POLineRecordDetails.supplierName)}
        </CustomTableCell>
        <CustomTableCell align='left' hideCell>
          {GRDetails[0].approvedBy === null ||
          GRDetails[0].approvedBy === '' ||
          GRDetails[0].approvedBy === undefined ? (
            GRDetails[0].rejectedBy === null ||
            GRDetails[0].rejectedBy === '' ||
            GRDetails[0].rejectedBy === undefined ? (
              '-'
            ) : (
              <CheckedAndApproved name={GRDetails[0].rejectedBy} />
            )
          ) : (
            <CheckedAndApproved name={GRDetails[0].approvedBy} />
          )}
        </CustomTableCell>
        <CustomTableCell align='center' hideCell>
          {presentFormatedData(GRDetails[0].numberMRN)}
        </CustomTableCell>
        <CustomTableCell align='left'>
          {(POLineRecordDetails.statusID === 9 && props.status === 'all') ||
          (props.status === 9 && POLineRecordDetails.statusID === 9) ||
          (props.status === 10 && POLineRecordDetails.statusID === 10) ||
          (POLineRecordDetails.statusID === 10 && props.status === 'all') ||
          (props.status === 11 && POLineRecordDetails.statusID === 11) ||
          (POLineRecordDetails.statusID === 11 && props.status === 'all')
            ? BatchStatus(POLineRecordDetails.statusID)
            : BatchStatus(GRDetails[0].batchStatusID)}
        </CustomTableCell>
        <CustomTableCell align='center'>
          {presentFormatedData(GRDetails[0].numberGRN)}
        </CustomTableCell>
        <CustomTableCell className={classes.changingWidth} align='left'>
          <div className={classes.actions}>
            <div>
              {POLineRecordDetails.statusID === 9 ||
              POLineRecordDetails.statusID === 10 ||
              POLineRecordDetails.statusID === 11
                ? null
                : StatusManager(
                    GRDetails[0].batchStatusID,
                    POLineRecordDetails,
                    GRDetails[0],
                    grnFeatures
                  )}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {collabType !== 'Pharma Co' &&
                grnFeatures.editGrRecord === true &&
                POLineRecordDetails.statusID !== 9 &&
                POLineRecordDetails.statusID !== 10 &&
                POLineRecordDetails.statusID !== 11 &&
                (GRDetails[0].batchStatusID === 2 ||
                  GRDetails[0].batchStatusID === 4) && (
                  <EditSupplierPO
                    POLineRecordDetails={POLineRecordDetails}
                    row={GRDetails[0]}
                    status={GRDetails[0].batchStatusID}
                    saveRecord={true}
                  />
                )}
              {grnFeatures.viewGrRecord === true && (
                <ViewSupplierPO
                  supplierPurchaseOrderNumber={
                    POLineRecordDetails.supplierPurchaseOrderNumber
                  }
                  materialCode={POLineRecordDetails.materialCode}
                  schrockenSupplierPOBatchID={
                    GRDetails[0].schrockenSupplierPOBatchID
                  }
                />
              )}
              {GRDetails[0].batchStatusID !== 1 &&
                grnFeatures.bulkDownloadGrFiles === true && (
                  <DownloadSupplierPO
                    schrockenSupplierPOBatchID={
                      GRDetails[0].schrockenSupplierPOBatchID
                    }
                    supplierPurchaseOrderNumber={
                      POLineRecordDetails.supplierPurchaseOrderNumber
                    }
                  />
                )}
            </div>
          </div>
        </CustomTableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default SingleRow;
