import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CmoNotes from "./CmoNotes";

import {
  Grid,
  TextField,
  Tab,
  Tabs,
  Paper,
  Button,
  label,
  Typography
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

const styles = {
  button: {
    fontSize: "0.6rem",
    padding: "0.7rem 0.5rem"
  },
  resize: {
    fontSize: "0.9rem"
  },
  input: {
    display: "none"
  },
  centerGrid: {
    display: "flex",
    alignItems: "center"
  },
  remove: {
    display: "flex",
    alignItems: "center",
    color: "red",
    cursor: "pointer"
  },
  end: {
    display: "flex",
    justifyContent: "flex-end"
  }
};

class UploadPOFiles extends Component {
  state = {
    tabValue: 0,
    purchaseOrderCSVFileName: "",
    purchaseOrderPdfFileName: ""
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.purchaseOrderCSVFileName) {
      this.setState({
        purchaseOrderCSVFileName: nextProps.purchaseOrderCSVFileName
      });
    }
    if (nextProps.purchaseOrderPdfFileName) {
      this.setState({
        purchaseOrderPdfFileName: nextProps.purchaseOrderPdfFileName
      });
    }
  }
  handleChangeTab = (event, value) => {
    this.setState({ tabValue: value });
  };

  removeFile = fileName => {
    this.props.removeFile(fileName);
    if (fileName === "purchaseOrderCSVFileName") {
      this.setState({ purchaseOrderCSVFileName: "" });
    } else if (fileName === "purchaseOrderPdfFileName") {
      this.setState({ purchaseOrderPdfFileName: "" });
    }
  };

  render() {
    let { tabValue } = this.state;
    const {
      classes,
      handleUploadFile,
      errors,
      handleChange,
      CMONotes,
      clearNotes
    } = this.props;
    const { purchaseOrderCSVFileName, purchaseOrderPdfFileName } = this.state;
    if (errors.purchaseOrderCSV) {
      tabValue = 0;
    }
    return (
      <React.Fragment>
        <Paper>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            centered
            onChange={this.handleChangeTab}
            style={{ marginTop: "1rem" }}
          >
            <Tab label="File Uploads" />
            <Tab label="Important Notes for CMO" />
          </Tabs>
        </Paper>
        {tabValue === 0 && (
          <React.Fragment>
            <Grid container spacing={2} className={classes.centerGrid}>
              <Grid item xs={6} md={3}>
                <TextField
                  disabled
                  label="File Type"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  value="Purchase Order"
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <input
                  className={classes.input}
                  id="upload-purchase-order"
                  type="file"
                  name="purchaseOrderCSVFileName"
                  onChange={handleUploadFile}
                  accept='.csv,.xls,.xlsx'
                />
                <label htmlFor="upload-purchase-order">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                    fullWidth
                  >
                    Upload Purchase Order
                  </Button>
                </label>
              </Grid>
              <Grid item xs={6} md={3}>
                {errors.purchaseOrderCSV ? (
                  <Typography variant="body2" gutterBottom color="error">
                    Please upload purchase order file
                  </Typography>
                ) : (
                  <Typography variant="body2" gutterBottom>
                    {purchaseOrderCSVFileName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} md={3} className={classes.end}>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.remove}
                  onClick={() => this.removeFile("purchaseOrderCSVFileName")}
                >
                  {purchaseOrderCSVFileName !== "" ? "Remove" : null}
                  {purchaseOrderCSVFileName !== "" ? (
                    <ClearIcon fontSize="small" />
                  ) : null}
                </Typography>
              </Grid>
            </Grid>

            {/* upload PO as Pdf*/}
            <Grid container spacing={2} className={classes.centerGrid}>
              <Grid item xs={6} md={3}>
                <TextField
                  disabled
                  label="File Type"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.resize
                    }
                  }}
                  value="Purchase Order Pdf"
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <input
                  className={classes.input}
                  id="upload-purchase-order-pdf"
                  type="file"
                  name="purchaseOrderPdfFileName"
                  onChange={handleUploadFile}
                  accept='.pdf'
                />
                <label htmlFor="upload-purchase-order-pdf">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                    fullWidth
                  >
                    Upload PO Pdf
                  </Button>
                </label>
              </Grid>
              <Grid item xs={6} md={3}>
                {errors.purchaseOrderPdf ? (
                  <Typography variant="body2" gutterBottom color="error">
                    Please upload purchase order pdf file
                  </Typography>
                ) : (
                  <Typography variant="body2" gutterBottom>
                    {purchaseOrderPdfFileName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} md={3} className={classes.end}>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.remove}
                  onClick={() => this.removeFile("purchaseOrderPdfFileName")}
                >
                  {purchaseOrderPdfFileName !== "" ? "Remove" : null}
                  {purchaseOrderPdfFileName !== "" ? (
                    <ClearIcon fontSize="small" />
                  ) : null}
                </Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {tabValue === 1 && (
          <CmoNotes
            handleChange={handleChange}
            CMONotes={CMONotes}
            clearNotes={clearNotes}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(UploadPOFiles);
