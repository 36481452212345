import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const Information = () => {
  return (
    <div>
      <Grid container style={{ margin: '1rem', marginTop: '1rem' }}>
        <Grid item xs={4}>
          <Typography variant='body1'>Release Version</Typography>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          &#160; :&#160;
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body2'>Mosymphony R2.3</Typography>
        </Grid>
      </Grid>
      <Grid container style={{ margin: '1rem' }}>
        <Grid item xs={4}>
          <Typography variant='body1'>Give Feedback</Typography>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          &#160; :&#160;
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body2'>support@schrocken.com</Typography>
        </Grid>
      </Grid>
      <Grid container style={{ margin: '1rem' }}>
        <Grid item xs={4}>
          <Typography variant='body1'>Terms &amp; Conditions</Typography>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          &#160; :&#160;
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body2' style={{ display: 'inline' }}>
            We take data privacy and security seriously. We collect data only
            for visibility, transparency &amp; audit trail in a ecosystem. All
            information is secured using Blockchain and data is never shared
            with any third party. <br /> <br /> View complete&#160;
          </Typography>
          <Typography
            variant='body2'
            component={Link}
            to='/terms'
            color='secondary'
          >
            Terms &amp; Conditions
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Information;
