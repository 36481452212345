import axios from 'axios';
import { appUrl } from '../../../../../utils/config';

import {
  CMO_SHORTFALLS_ITEMS,
  CMO_ADD_SHORTFALLS,
  CMO_ADD_GRNRECEIPT,
  CMO_ADD_GRNCSV,
  CMO_FETCH_GRNRECEIPTS,
  QUICK_VIEW,
  ADD_GRN_FETCHTABLE,
  UPDATE_GRN_LINE_DATA,
  FETCH_UOM,
  APPROVE_GRN_DATA,
  OPEN_BACKDROP,
  DESTROY_BACKDROP,
  CMO_LIST,
  UPLOAD_GRN_FILE,
  UPDATE_GR_RECORD,
  RECORD_TIMELINE,
  RECORD_RESOLUTION,
  EXPORT_TO_EXCEL,
  DOWNLOAD_GR_ZIP,
  DELETE_GR,
  QUANTITY_TOLERANCE_ALERT,
  //to show linear progress indicator on table for background data loading
  TABLE_LOAD,
  DESTROY_TABLE_LOAD,
  ADD_GRN_MODIFIED_FETCHTABLE,
  OFFSET_ADD_GRN_FETCHTABLE
} from '../types';

export let shortFallSource;
export const cmoFetchShortFallsItems = (data) => (dispatch) => {

  const CancelToken = axios.CancelToken;
  shortFallSource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/fetchAllShortFallsList`,
      data, { cancelToken: shortFallSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_SHORTFALLS_ITEMS,
          payload: res.data.shortFallsReportsList,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const cmoAddShortFallsReport = (data, roleName) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${roleName}/addShortFalls`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_ADD_SHORTFALLS,
          payload: res.data.status,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const cmoAddGRNReceipt = (data, roleName) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${roleName}/addGRN`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_ADD_GRNRECEIPT,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      if (err.response.status === 400) {
        dispatch({
          type: CMO_ADD_GRNRECEIPT,
          payload: err.response.data,
        });
      }
    });
};

export const cmoFetchGRNReceipts = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/fetchAllGRNList`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_FETCH_GRNRECEIPTS,
          payload: res.data.grnReceiptsList,
        });
      }
    })
    .catch((err) => console.log(err));
};
//cmoAddGRNCSVFile
export const cmoAddGRNCSVFile = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/addGRNCSV`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_ADD_GRNCSV,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      if (err.response.status === 400) {
        dispatch({
          type: CMO_ADD_GRNCSV,
          payload: err.response.data,
        });
      }
    });
};

export let lineSource;
export const quickViewAction = (data) => (dispatch) => {

  const CancelToken = axios.CancelToken;
  lineSource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchGRNLineDetails`,
      data, { cancelToken: lineSource.token }
    )
    // .post(
    //   `${appUrl}/${localStorage.appName}/n1/sh2/CMO/Admin/fetchGRNLineDetails`,
    //   data
    // )
    .then((res) => {
      dispatch({
        type: QUICK_VIEW,
        payload: res.data.data.PODetails,
      });
      // console.log(res.data.data.GRData)
    })
    .catch((err) => {
      console.log(err);
    });
};
// AddGRNModifiedFetchTable


export let source;

export const AddGRNModifiedFetchTable = (data) => (dispatch) => {
  if (source !== undefined) {
    source.cancel('Request cancelled for new table')
  }

  const CancelToken = axios.CancelToken;
  source = CancelToken.source();
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchGRNListData`,
      data, {
      cancelToken: source.token
    }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ADD_GRN_MODIFIED_FETCHTABLE,
          payload: res.data,
        });
        // destroy indicator
        dispatch({ type: DESTROY_TABLE_LOAD });
      }
    })
    .catch((err) => {
      console.log(err);
    });


};

let offsetSource;

export const offsetAddGRNFetchTable = (data) => (dispatch) => {
  if (offsetSource !== undefined) {
    offsetSource.cancel('Request cancelled')
  }
  const CancelToken = axios.CancelToken;
  offsetSource = CancelToken.source();

  dispatch({
    type: TABLE_LOAD,
  });
  axios
    .post(`${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/pagination/fetchGRNListDetails`,
      data, { cancelToken: offsetSource.token })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: OFFSET_ADD_GRN_FETCHTABLE,
          payload: res.data,
        });
        // destroy indicator
        dispatch({ type: DESTROY_TABLE_LOAD });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const AddGRNFetchTable = (data) => (dispatch) => {
  if (source !== undefined) {
    source.cancel('Request cancelled for GRN fetch Table')
  }

  const CancelToken = axios.CancelToken;
  source = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchGRNListDetails`,
      data, { cancelToken: source.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ADD_GRN_FETCHTABLE,
          payload: res.data,
        });
        // destroy indicator
        dispatch({ type: DESTROY_TABLE_LOAD });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

//update GRN
export const updateGRN = (data, backdropText) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText,
  });
  dispatch({
    type: TABLE_LOAD,
  });

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/updateGRN`,
      data
    )
    .then((res) => {
      if (res.status === 103 || res.status === 101) {
        dispatch({
          type: QUANTITY_TOLERANCE_ALERT,
          payload: res,
        });
      } else if (res.status === 200) {
        dispatch({
          type: UPDATE_GRN_LINE_DATA,
          payload: res.data,
        });
      }
      dispatch({
        type: DESTROY_BACKDROP,
      });
      dispatch({ type: DESTROY_TABLE_LOAD });
    })
    .catch((err) => {
      dispatch({
        type: DESTROY_BACKDROP,
      });
      dispatch({ type: DESTROY_TABLE_LOAD });

      //to handle ngnix response (net::ERR_CONNECTION_REFUSED ) if the file size is greater than 50mb
      if (!err.response) {
        let data = {
          errorDescription: {
            FileSizeError: 'File size is too large to upload, Please verify',
          },
          status: false,
        };
        dispatch({
          type: UPDATE_GRN_LINE_DATA,
          payload: data,
        });
      } else if (err.response.status === 400) {
        dispatch({
          type: UPDATE_GRN_LINE_DATA,
          payload: err.response.data,
        });
      }
      //to handle ngnix response if the file size is greater than 50mb
      else if (err.response.status === 413) {
        let data = {
          errorDescription: {
            FileSizeError: 'File size is too large to upload, Please verify',
          },
          status: false,
        };
        dispatch({
          type: UPDATE_GRN_LINE_DATA,
          payload: data,
        });
      }
      console.log(err);
    });
};

//fetch UOM
export const fetchUOM = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchQAUOM`,
      data
    )
    .then((res) => {
      if (res) {
        dispatch({
          type: FETCH_UOM,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

//Approve GRN

export const approveGRN = (data, backdropText) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText,
  });
  dispatch({
    type: TABLE_LOAD,
  });

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/approveGRN`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: APPROVE_GRN_DATA,
          payload: res.data,
        });
      }
      dispatch({
        type: DESTROY_BACKDROP,
      });
      dispatch({ type: DESTROY_TABLE_LOAD });
    })
    .catch((err) => {
      dispatch({
        type: DESTROY_BACKDROP,
      });
      dispatch({ type: DESTROY_TABLE_LOAD });
      if (err.response.status === 401 || err.response.status === 400) {
        dispatch({
          type: APPROVE_GRN_DATA,
          payload: err.response.data,
        });
      }
    });
};

//download excel
export const downloadExcel = (data, backdropText) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText,
  });

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/exportToExcel`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        let fileDataArray = res.data.fileData.data;
        var byteArray = new Uint8Array(fileDataArray);
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        );
        a.download = res.data.fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      dispatch({
        type: DESTROY_BACKDROP,
      });
      dispatch({
        type: EXPORT_TO_EXCEL,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: DESTROY_BACKDROP,
      });
      console.log(err);
      if (err.response.status === 400) {
        dispatch({
          type: EXPORT_TO_EXCEL,
          payload: err.response.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
      }
    });
};

// CMO Members List
export const CMOmembersList = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchCMOMembers`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_LIST,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const uploadGRNRecord = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/uploadGRFile`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPLOAD_GRN_FILE,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 400) {
        dispatch({
          type: UPLOAD_GRN_FILE,
          payload: err.response.data,
        });
      }
    });
};

export const updateGRNRecords = (data, backdropText) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText,
  });
  dispatch({
    type: TABLE_LOAD,
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/updateFileRecords`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPDATE_GR_RECORD,
          payload: res.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
        dispatch({ type: DESTROY_TABLE_LOAD });
      }
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 400) {
        dispatch({
          type: UPDATE_GR_RECORD,
          payload: err.response.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
        dispatch({ type: DESTROY_TABLE_LOAD });
      }
    });
};
export let timelineSource;
//Record Timelines
export const recordTimelineAction = (data) => (dispatch) => {
  const CancelToken = axios.CancelToken;
  timelineSource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchRecordTimeline`,
      data, { cancelToken: timelineSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: RECORD_TIMELINE,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

//Record Resolution
export let resolutionSource;
export const recordResolutionAction = (data) => (dispatch) => {

  const CancelToken = axios.CancelToken;
  resolutionSource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchRecordResolution`,
      data, { cancelToken: resolutionSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: RECORD_RESOLUTION,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

//download gr zip
export const downloadGRZip = (data, backdropText) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText,
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/downloadGRZip`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        let fileDataArray = res.data.data.data;
        var byteArray = new Uint8Array(fileDataArray);
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        );
        a.download = res.data.fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      dispatch({
        type: DOWNLOAD_GR_ZIP,
        payload: res.data,
      });
      dispatch({
        type: DESTROY_BACKDROP,
      });
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 400 || err.response.status === 401) {
        dispatch({
          type: DOWNLOAD_GR_ZIP,
          payload: err.response.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
        alert(err.response.data.message);
      }
    });
};

//Delete GR Record
export const deleteGRRecord = (data, backdropText) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText,
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/deleteGRRecord`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: DELETE_GR,
          payload: res.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DESTROY_BACKDROP,
      });
      console.log(err);
      if (err.response.status === 400 || err.response.status === 401) {
        dispatch({
          type: DELETE_GR,
          payload: err.response.data,
        });
      }
    });
};
