import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
const LongTextDisplay = (props) => {
  const { text, limit, variant, showControlText, hideControlText } = props;

  const classes = useStyles();
  const [show, readMore] = useState(false);
  if (text.length > limit) {
    return (
      <Typography variant={variant || 'body2'} {...props}>
        {show ? text : `${text.substring(0, limit)}...`}
        <Typography
          variant='caption'
          component={'a'}
          onClick={() => readMore(!show)}
          className={classes.link}
        >
          {show ? hideControlText || ' Hide' : showControlText || 'Learn more'}
        </Typography>
      </Typography>
    );
  }
  return (
    <Typography variant={variant || 'body2'} {...props}>
      {text}
    </Typography>
  );
};

LongTextDisplay.propTypes = {
  text: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
};

export default LongTextDisplay;
