import React, { useState } from 'react';

import { Box, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MomentUtils from '@date-io/moment';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '80%',
    padding: '1rem',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
  },
  headTitle: {
    fontWeight: 700,
  },
  dialogPaper: {
    width: '40%',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
  },

  steeper: {
    width: '40%',
    margin: '0 auto',
    position: 'relative',
  },
  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
  },
  title: {
    textAlign: 'center',
    fontWeight: 500,
    marginTop: '4rem',
  },

  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
  },
  inputLabel: {
    marginBottom: '0.5rem',
    fontSize: '85%',
  },
  requiredStar: {
    color: 'red',
  },
  saveBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'lowercase',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const Resolution = (props) => {
  const classes = useStyles();
  const { openDialog, toggleDialog } = props;
  const [state, setState] = useState({
    errors: {},
  });
  const { resolutionDescription, estimatedResolutionDate, eventReportedDate } =
    props.stateData;

  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD/MM/YYYY';

  const handleOnChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: null },
    }));
    props.handleChangeData(name, value);
  };

  const handleValidations = () => {
    const errorsData = {};
    let isError = false;

    if (estimatedResolutionDate === null) {
      errorsData.estimatedResolutionDate = 'Enter estimate resolution date';
      isError = true;
    }
    if (isError) {
      setState((prevState) => ({
        ...prevState,
        errors: errorsData,
      }));
    } else {
      props.toggleDialog('resolutionScreen');
    }
  };
  return (
    <Dialog
      open={openDialog}
      onClose={() => toggleDialog('resolutionScreen')}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='md'
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.headerContainer}>
        <div
          className={classes.closeBtn}
          onClick={() => toggleDialog('resolutionScreen')}
        >
          <Typography
            variant='caption'
            style={{ lineHeight: 'normal', fontWeight: '700' }}
          >
            Cancel
          </Typography>
          <CloseIcon style={{ color: 'red' }} />
        </div>
      </div>
      <Box width='80%' margin='auto'>
        <Box>
          <Typography variant='h5' className={classes.title}>
            Resolution Action and Date
          </Typography>
          <hr className={classes.hrLine} />
        </Box>
        <Box paddingTop={2}>
          <Typography variant='body2' className={classes.inputLabel}>
            Description of Immidiate actions (Optional)
          </Typography>
          <TextField
            rows={3}
            multiline
            fullWidth
            value={resolutionDescription}
            onChange={(e) => {
              handleOnChange('resolutionDescription', e.target.value);
            }}
            variant='outlined'
            actiondescription
            placeholder='Enter the immediate containment or remedial actions taken for this event'
          />
        </Box>
        <Box width='100%' marginTop={2}>
          <Typography variant='body2' className={classes.inputLabel}>
            Estimated resolution date
            <span className={classes.requiredStar}>*</span>
          </Typography>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              variant='inline'
              inputVariant='outlined'
              format={dateFormat}
              fullWidth
              placeholder={dateFormat}
              size='small'
              autoOk={true}
              minDate={eventReportedDate}
              name='estimatedResolutionDate'
              value={estimatedResolutionDate}
              onChange={(value) =>
                handleOnChange('estimatedResolutionDate', value)
              }
              error={!!state.errors.estimatedResolutionDate}
              helperText={state.errors.estimatedResolutionDate}
              InputProps={{
                endAdornment: (
                  <DateRangeIcon
                    style={{ color: '#0000008a', cursor: 'pointer' }}
                  />
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </Box>

        <Box
          width='70%'
          textAlign='center'
          marginTop={4}
          marginBottom='2rem'
          marginLeft={7}
        >
          <Button
            className={classes.saveBtn}
            variant='contained'
            color='primary'
            endIcon={<NavigateNextIcon />}
            onClick={handleValidations}
          >
            save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default Resolution;
