import React from 'react';
import {
  DatePicker,
  //  KeyboardDatePicker
} from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const MonthSelector = (props) => {
  const {
    reportStartDate,
    reportEndDate,
    handleDateChange,
    startDateError,
    endDateError,
  } = props;

  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD-MMM-YYYY';
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        autoOk
        // disableToolbar
        variant='inline'
        // inputVariant="outlined"
        label='Start Date'
        format={dateFormat}
        placeholder='From'
        value={reportStartDate}
        views={['date', 'month', 'year']}
        // InputAdornmentProps={{ position: "start" }}
        onChange={(date) => handleDateChange(date, 'reportStartDate')}
        error={startDateError}
        // maxDate={reportEndDate === null ? new Date() : reportEndDate}
        InputProps={{
          endAdornment: <ArrowForwardIcon style={{ color: '#0000008a' }} />,
        }}
      />
      <DatePicker
        autoOk
        // disableToolbar
        variant='inline'
        // inputVariant="outlined"
        label='End Date'
        format={dateFormat}
        placeholder='To'
        value={reportEndDate}
        views={['date', 'month', 'year']}
        minDate={reportStartDate === null ? new Date() : reportStartDate}
        // InputAdornmentProps={{ position: "start" }}
        onChange={(date) => handleDateChange(date, 'reportEndDate')}
        error={endDateError}
      />
    </MuiPickersUtilsProvider>
  );
};

export default MonthSelector;
