import React from 'react';
import { Switch, Route } from 'react-router-dom';

import UserDeactivation from '../common/userDeactivation/index.jsx';

import PcmpAuth from '../../hoc/PcmpAuth';
import Dashboard from './dashboard';
import PurchaseOrders from './purchaseOrders';
import LineItems from './lineItems';
import BatchItems from './batchItems';
import POBatchItems from './batchItems/poBatchItems';
import CmoPurchaseOrder from './purchaseOrders/viewPurchaseOrder/purchaseOrder';

import ManageMembers from './manageMembers';
import CmoViewLineItem from './purchaseOrders/viewPurchaseOrder/lineItems';
import ShortFalls from './shortFalls';
import Reports from './reports';
import Settings from './settings';

import NotFound from '../../notFound/NotFound';
import CmoLayout from '../layout';
// import GRNReceipts from '../common/grnReceipts';
import GRNReceipts from '../common/grnReceipts/GrnAccess';

import UserProfile from '../common/profile/user';
import OrgDetails from '../common/profile/organization';
import WIPComponent from '../common/wip';
import DeliveryScheduleDetails from '../common/sasl/deliverScheduleDetails';

import SASLIndex from '../common/sasl/scheduleAgreements/index';
import SAAuditIndex from '../common/reports/saAudit';

/* Quality Event import */
import QualityEventIndex from '../common/qualityEvents/index'

import BatchesDetails from '../common/sasl/batchesDetails';
const PcmpCmsRoutes = () => (
  <CmoLayout>
    <Switch>
      <Route
        exact
        name='PCMP CMO Dashboard'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/dashboard'
        component={PcmpAuth(Dashboard, 'CMO')}
      />
      <Route
        exact
        name='PCMP Purchase Orders'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/purchaseorders'
        component={PcmpAuth(PurchaseOrders, 'CMO')}
      />
      <Route
        exact
        name='PCMP All Line Items'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/lineitems'
        component={PcmpAuth(LineItems, 'CMO')}
      />
      <Route
        exact
        name='PCMP All Batch Items'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/batches'
        component={PcmpAuth(BatchItems, 'CMO')}
      />
      <Route
        exact
        name='CMo Purchase Order'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/purchaseorderdetails/:purchaseOrderNumber'
        component={PcmpAuth(CmoPurchaseOrder, 'CMO')}
      />
      <Route
        exact
        name='PCMP All Batch Items for a Purchase Order'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/:purchaseOrderNumber/pobatchitems'
        component={PcmpAuth(POBatchItems, 'CMO')}
      />
      <Route
        exact
        name='CMO Manage Members'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/managemembers'
        component={PcmpAuth(ManageMembers, 'CMO')}
      />

      <Route
        exact
        name='Settings'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/settings'
        component={PcmpAuth(Settings, 'CMO')}
      />

      <Route
        exact
        name='CMO Line Item'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/lineitem/:purchaseOrderNumber/:schrockenPOLineID/:lineItemNumber'
        component={PcmpAuth(CmoViewLineItem, 'CMO')}
      />
      <Route
        exact
        name='CMO Short Falls'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/shortFalls'
        component={PcmpAuth(ShortFalls, 'CMO')}
      />

      <Route
        exact
        name='CMO GRN Receipts'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/GRNReceipts'
        // component={PcmpAuth(GRNReceipts, 'CMO')}
        component={GRNReceipts}
      />

      <Route
        exact
        name='UserDeactivation'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/userdeactivate'
        // component={PcmpAuth(GRNReceipts, 'CMO')}
        component={UserDeactivation}
      />

      {/* <Route
        exact
        name='SASL Test'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/testSASL'
        // component={PcmpAuth(GRNReceipts, 'CMO')}
        component={SASLIndex}
      /> */}
      <Route
        exact
        name='Reports'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/reports'
        component={PcmpAuth(Reports, 'CMO')}
      />
      <Route
        exact
        name='SA Audit Reports'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/sa-audit-reports'
        component={SAAuditIndex}
      />

      {/* Quality Event  */}
      <Route
        exact
        name='Quality Events'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/quality events'
        component={QualityEventIndex}
      />

      <Route
        exact
        name='Profile Page'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/userProfile'
        // component={PcmpAuth(GRNReceipts, 'CMO')}
        component={UserProfile}
      />

      <Route
        exact
        name='Organization Profile Page'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/orgProfile'
        // component={PcmpAuth(GRNReceipts, 'CMO')}
        component={OrgDetails}
      />
      <Route
        exact
        name='Schedule Agreements'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/schedule agreements'
        component={SASLIndex}
      />

      {/* SA SL test screens route */}
      <Route
        exact
        name='Schedule Line Details'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/sasl/:schrockenDeliveryScheduleID'
        component={DeliveryScheduleDetails}
      />

      <Route
        exact
        name='Help'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/help'
        component={WIPComponent}
      />
      <Route
        exact
        name='Switch Ecosystems'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/switch ecosystem'
        component={WIPComponent}
      />

      {/* Batch Dashboard */}
      <Route
        exact
        name='PCMP All Batches'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/fgBatches'
        component={BatchesDetails}
      />

      <Route
        exact
        name='PCMP All Batches'
        path='/pcmp/cmo/:roleName/:organizationName/:ecosystemId/fgBatches/:batchNo'
        component={BatchesDetails}
      />

      <Route exact name='404 Not Found' component={NotFound} />
    </Switch>
  </CmoLayout>
);

export default PcmpCmsRoutes;
