import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
// import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ViewConsumption from './ViewConsumption';
import Header from '../../models/Header';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  multipleLogo: {
    border: '1px solid #7d7c7c',
    borderRadius: '4px',
    padding: '0.3rem',
    height: 'inherit',
    marginLeft: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const Index = (props) => {
  const classes = useStyles();
  const [openSADetails, setOpenSADetails] = useState(false);
  const handleCloseSA = () => {
    setOpenSADetails(false);
  };

  return (
    <React.Fragment>
      <DynamicFeedIcon
        fontSize='small'
        className={classes.multipleLogo}
        onClick={() => setOpenSADetails(true)} //setOpen(!open)
      />
      <Dialog
        fullScreen
        open={openSADetails}
        onClose={handleCloseSA}
        TransitionComponent={Transition}
      >
        <Header
          handleClose={handleCloseSA}
          title='Consumption Information'
          padding={true}
        >
          <ViewConsumption handleClose={handleCloseSA} {...props} />
        </Header>
      </Dialog>
    </React.Fragment>
  );
};

export default Index;
