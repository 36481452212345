import React from 'react';

import StyledButton from '../../../../../../../common/models/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
// Icons
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  saved: {
    textAlign: 'center',
    padding: '0.5rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  text: {
    textAlign: 'center',
    fontSize: '1rem',
    margin: '1rem',
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '5rem',
    color: 'red',
  },
  cardContent: {
    padding: '2rem !important',
  },
  buttonGrid: {
    textAlign: 'center',
    padding: '0.5rem',
  },
  noButton: {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
});

const VarianceAlertDialog = ({ children, ...props }) => {
  const classes = useStyles();

  const { handleNoButton, handleClose, handleYesButton } = props;

  return (
    <DialogContent
      classes={{
        root: classes.cardContent,
      }}
    >
      <Grid container justify='flex-end'>
        <Typography
          variant='body2'
          className={classes.closeText}
          onClick={handleClose}
        >
          Close <CloseIcon fontSize='large' />{' '}
        </Typography>
        <Grid item xs={12} className={classes.iconGrid}>
          <WarningIcon className={classes.icon} />
        </Grid>
      </Grid>
      <Typography variant='h5' className={classes.saved}>
        {props.title}
      </Typography>

      <Typography variant='body2' className={classes.text}>
        {props.subtitle}
      </Typography>

      {children}

      <Grid
        item
        xs={12}
        className={classes.buttonGrid}
        style={{ display: 'flex', justifyContent: 'space-evenly' }}
      >
        <Button
          variant='contained'
          disableElevation
          disableRipple
          disableFocusRipple
          className={classes.noButton}
          onClick={handleNoButton}
        >
          No
        </Button>
        <StyledButton
          style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
          onClick={handleYesButton}
        >
          Yes
        </StyledButton>
      </Grid>
    </DialogContent>
  );
};

export default VarianceAlertDialog;
