import React from "react";
import { Grid } from "@material-ui/core";
import AddBatchItem from "./AddBatchItem";

const BatchItemIndex = props => {
  return (
    <Grid
      container
      style={{
        justifyContent: "center"
      }}
    >
      <Grid
        item
        xs={12}
        sm={10}
        style={{
          backgroundColor: "#E7ECEE",
          minHeight: "100vh"
        }}
      >
        <AddBatchItem {...props} />
      </Grid>
    </Grid>
  );
};

export default BatchItemIndex;
