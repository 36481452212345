import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import ValidateRecord from './ValidateRecord';
import UploadRecordFiles from './UploadRecordFiles';
import Dialog from '@material-ui/core/Dialog';
import StyledDialog from '../../../../common/models/Dialog';
import ValidateDialog from '../models/ValidateDialog';

import {
  uploadSaSlBatchDatafromFile,
  updateSaSlBatchDatafromFile,
  fetchSASLDetails,
  uploadSaSlConsumptionInfofromFile,
  updateSaSlConsumptionInfofromFile,
  uploadRMPMAvailability,
  updateRMPMAvailability,
} from '../../../containers/actions/common/saslAction';
import {
  uploadGRNRecord,
  updateGRNRecords,
  AddGRNFetchTable,
} from '../../../containers/actions/cmo/shortFallsActions';
import { downloadSampleGRFile } from '../../../containers/actions/common/fileActions';

import BackDropComponent from '../../common/models/BackDrop';

const Styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  backgroundGrid: {
    backgroundColor: '#ffffff',
    borderRadius: '1rem',
  },
  bottomgrid: {
    padding: '2rem 0',
  },
  hidden: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  clear: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2rem',
    },
  },
  step: {
    '& $completed': {
      color: '#008002',
    },
  },

  buttonApprovalGrid: {
    paddingTop: '2rem',
    textAlign: 'center',
  },
  button: {
    textTransform: 'none',
    border: '1px solid #000000',
    borderRadius: '2rem',
    padding: '0.5rem 2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  },
  outerGrid: {
    paddingBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  reUploadButton: {
    textTransform: 'none',
    color: '#313645',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  //the following enables ovverride and add other custom styles
  completed: {},
});

function getSteps() {
  return ['Upload Record(s)', 'Validation Summary'];
}

class NewRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      disableNext: false,
      errors: {},
      uploadedRecordsFileName: '',
      uploadedRecordsFiles: {},
      resError: null,
      verifyConsent: false,
      confirmDialog: false,
      validateRecord: false,
      disableNextButton: false,
      disableSubmitButton: false,
      disableReuploadButton: false,
      validatedRecordData: {},
      errorMessageColor: 'black',
      // submittedRecords: [],
    };
  }

  // uploadDSBatchFile
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.uploadSASLConsumptionFile !==
        prevProps.uploadSASLConsumptionFile &&
      this.props.uploadSASLConsumptionFile.status === false
    ) {
      let error = this.props.uploadSASLConsumptionFile.errorDescription;
      this.setState({
        resError: { errMsg: error },
        validateRecord: false,
        disableNextButton: false,
        validatedRecordData: {},
        errors: {
          label: true,
        },
      });
      error = '';
    }

    if (
      this.props.uploadSASLConsumptionFile !==
        prevProps.uploadSASLConsumptionFile &&
      this.props.uploadSASLConsumptionFile.status === true
    ) {
      this.setState({
        validateRecord: false,
        activeStep: this.state.activeStep + 1,
        resError: null,
        disableNextButton: false,
        errors: {},
        uploadedRecordsFileName: '',
        uploadedRecordsFiles: {},
        validatedRecordData: this.props.uploadSASLConsumptionFile,
      });
      if (this.props.uploadSASLConsumptionFile.validRecordsCount === 0) {
        this.setState({
          disableSubmitButton: true,
        });
      } else {
        this.setState({
          disableSubmitButton: false,
        });
      }
    }

    if (
      this.props.uploadDSBatchFile !== prevProps.uploadDSBatchFile &&
      this.props.uploadDSBatchFile.status === false
    ) {
      let error = this.props.uploadDSBatchFile.errorDescription;
      this.setState({
        resError: { errMsg: error },
        validateRecord: false,
        disableNextButton: false,
        validatedRecordData: {},
        errors: {
          label: true,
        },
      });
      error = '';
    }

    if (
      this.props.uploadDSBatchFile !== prevProps.uploadDSBatchFile &&
      this.props.uploadDSBatchFile.status === true
    ) {
      this.setState({
        validateRecord: false,
        activeStep: this.state.activeStep + 1,
        resError: null,
        disableNextButton: false,
        errors: {},
        uploadedRecordsFileName: '',
        uploadedRecordsFiles: {},
        validatedRecordData: this.props.uploadDSBatchFile,
      });
      if (this.props.uploadDSBatchFile.validRecordsCount === 0) {
        this.setState({
          disableSubmitButton: true,
        });
      } else {
        this.setState({
          disableSubmitButton: false,
        });
      }
    }

    if (
      this.props.uploadRMPMFile !== prevProps.uploadRMPMFile &&
      this.props.uploadRMPMFile.status === false
    ) {
      let error = this.props.uploadRMPMFile.errorDescription;
      this.setState({
        resError: { errMsg: error },
        validateRecord: false,
        disableNextButton: false,
        validatedRecordData: {},
        errors: {
          label: true,
        },
      });
      error = '';
    }

    if (
      this.props.uploadRMPMFile !== prevProps.uploadRMPMFile &&
      this.props.uploadRMPMFile.status === true
    ) {
      this.setState({
        validateRecord: false,
        activeStep: this.state.activeStep + 1,
        resError: null,
        disableNextButton: false,
        errors: {},
        uploadedRecordsFileName: '',
        uploadedRecordsFiles: {},
        validatedRecordData: this.props.uploadRMPMFile,
      });
      if (this.props.uploadRMPMFile.validRecordsCount === 0) {
        this.setState({
          disableSubmitButton: true,
        });
      } else {
        this.setState({
          disableSubmitButton: false,
        });
      }
    }

    if (
      this.props.uploadGRFile !== prevProps.uploadGRFile &&
      this.props.uploadGRFile.status === false
    ) {
      let errorKey = Object.keys(this.props.uploadGRFile.errorDescription);
      let error = '';
      if (
        this.props.uploadGRFile.errorDescription[errorKey[0]].status === true
      ) {
        error = errorKey[0];
      } else if (
        this.props.uploadGRFile.errorDescription[errorKey[1]].status === true
      ) {
        error = errorKey[1];
      } else if (
        this.props.uploadGRFile.errorDescription[errorKey[2]].status === true
      ) {
        error = errorKey[2];
      }
      this.setState({
        resError: this.props.uploadGRFile.errorDescription[error],
        validateRecord: false,
        disableNextButton: false,
        validatedRecordData: {},
        errors: {
          [error]: true,
        },
      });
      error = '';
    }
    if (
      this.props.uploadGRFile !== prevProps.uploadGRFile &&
      this.props.uploadGRFile.status === true
    ) {
      this.setState({
        validateRecord: false,
        activeStep: this.state.activeStep + 1,
        resError: null,
        disableNextButton: false,
        errors: {},
        uploadedRecordsFileName: '',
        uploadedRecordsFiles: {},
        validatedRecordData: this.props.uploadGRFile.data,
      });
      if (this.props.uploadGRFile.data.validRecordsCount === 0) {
        this.setState({
          disableSubmitButton: true,
        });
      } else {
        this.setState({
          disableSubmitButton: false,
        });
      }
    }

    // updateDSBatchFile
    if (
      this.props.updateDSBatchFile !== prevProps.updateDSBatchFile &&
      this.props.updateDSBatchFile.status === true
    ) {
      this.setState({
        confirmDialog: true,
        verifyConsent: false,
        errors: {},
        resError: null,
        disableSubmitButton: false,
        disableReuploadButton: false,
      });
      this.props.refreshFunc();
      // let { collabType, organizationName } = localStorage;
      // this.props.fetchSASLDetails({
      //   vendorName: collabType === 'CMO' ? organizationName : 'all',
      // });
    }

    if (
      this.props.updateDSBatchFile !== prevProps.updateDSBatchFile &&
      this.props.updateDSBatchFile.status === false
    ) {
      this.setState({
        confirmDialog: false,
        disableSubmitButton: false,
        disableReuploadButton: false,
        verifyConsent: false,
        errors: {
          errorDescription: this.props.updateDSBatchFile.errorDescription,
          errorMessage: this.props.updateDSBatchFile.errorMessage,
        },
      });
      let { collabType, organizationName } = localStorage;
      this.props.fetchSASLDetails({
        vendorName: collabType === 'CMO' ? organizationName : 'all',
      });
    }

    if (
      this.props.updateSASLConsumptionFile !==
        prevProps.updateSASLConsumptionFile &&
      this.props.updateSASLConsumptionFile.status === true
    ) {
      this.setState({
        confirmDialog: true,
        verifyConsent: false,
        errors: {},
        resError: null,
        disableSubmitButton: false,
        disableReuploadButton: false,
      });
      this.props.refreshFunc();
      // let { collabType, organizationName } = localStorage;
      // this.props.fetchSASLDetails({
      //   vendorName: collabType === 'CMO' ? organizationName : 'all',
      // });
    }

    if (
      this.props.updateSASLConsumptionFile !==
        prevProps.updateSASLConsumptionFile &&
      this.props.updateSASLConsumptionFile.status === false
    ) {
      this.setState({
        confirmDialog: false,
        disableSubmitButton: false,
        disableReuploadButton: false,
        verifyConsent: false,
        errors: {
          errorDescription:
            this.props.updateSASLConsumptionFile.errorDescription,
          errorMessage: this.props.updateSASLConsumptionFile.errorMessage,
        },
      });
      let { collabType, organizationName } = localStorage;
      this.props.fetchSASLDetails({
        vendorName: collabType === 'CMO' ? organizationName : 'all',
      });
    }

    if (
      this.props.updateRMPMFile !== prevProps.updateRMPMFile &&
      this.props.updateRMPMFile.status === true
    ) {
      this.setState({
        confirmDialog: true,
        verifyConsent: false,
        errors: {},
        resError: null,
        disableSubmitButton: false,
        disableReuploadButton: false,
      });
      this.props.refreshFunc();
      // let { collabType, organizationName } = localStorage;
      // this.props.fetchSASLDetails({
      //   vendorName: collabType === 'CMO' ? organizationName : 'all',
      // });
    }

    if (
      this.props.updateRMPMFile !== prevProps.updateRMPMFile &&
      this.props.updateRMPMFile.status === false
    ) {
      this.setState({
        confirmDialog: false,
        disableSubmitButton: false,
        disableReuploadButton: false,
        verifyConsent: false,
        errors: {
          errorDescription: this.props.updateRMPMFile.errorDescription,
          errorMessage: this.props.updateRMPMFile.errorMessage,
        },
      });
      let { collabType, organizationName } = localStorage;
      this.props.fetchSASLDetails({
        vendorName: collabType === 'CMO' ? organizationName : 'all',
      });
    }

    if (
      this.props.updateGRRecords !== prevProps.updateGRRecords &&
      this.props.updateGRRecords.status === true
    ) {
      this.setState({
        confirmDialog: true,
        verifyConsent: false,
        errors: {},
        resError: null,
        disableSubmitButton: false,
        disableReuploadButton: false,
      });
      this.props.refreshFunc();
    }
    if (
      this.props.updateGRRecords !== prevProps.updateGRRecords &&
      this.props.updateGRRecords.status === false
    ) {
      this.setState({
        confirmDialog: false,
        disableSubmitButton: false,
        disableReuploadButton: false,
        verifyConsent: false,
        errors: {
          errorDescription: this.props.updateGRRecords.errorDescription,
          errorMessage: this.props.updateGRRecords.errorMessage,
        },
      });
      this.props.refreshFunc();
    }
  }
  //---Download sample csv file for gr---
  downloadSampleFile = () => {
    this.props.downloadSampleGRFile();
  };

  // Trigger next step
  handleNext = () => {
    if (Object.keys(this.state.uploadedRecordsFiles).length !== 0) {
      this.setState({ validateRecord: true, disableNextButton: true });
      const data = new FormData();
      data.append('files', this.state.uploadedRecordsFiles);
      if (this.props.isUploadRMPMGRN === true) {
        this.props.uploadGRNRecord(data);
      } else if (this.props.isUploadDSBatch === true) {
        this.props.uploadSaSlBatchDatafromFile(data);
      } else if (this.props.isUploadConsumptionInfo === true) {
        this.props.uploadSaSlConsumptionInfofromFile(data);
      } else if (this.props.isUploadRMPMAvailability === true) {
        this.props.uploadRMPMAvailability(data);
      }
      this.setState({
        errorMessageColor: 'black',
      });
    } else {
      this.setState({
        errorMessageColor: 'red',
      });
    }
  };

  handleSubmitRecords = () => {
    if (this.state.verifyConsent === false) {
      this.setState({
        errors: {
          verifyConsent: true,
        },
      });
    } else if (this.state.verifyConsent === true) {
      this.setState({
        disableSubmitButton: true,
        disableReuploadButton: true,
      });
      let { validatedRecordData } = this.state;
      const data = {
        validRecords: validatedRecordData.validRecords,
        validRecordsCount: validatedRecordData.validRecordsCount,
      };
      // updateDSBatchFile
      if (this.props.isUploadRMPMGRN === true) {
        this.props.updateGRNRecords(data, 'Please wait while saving the data.');
      } else if (this.props.isUploadDSBatch === true) {
        this.props.updateSaSlBatchDatafromFile(
          data,
          'Please wait while saving the data.'
        );
      } else if (this.props.isUploadConsumptionInfo === true) {
        this.props.updateSaSlConsumptionInfofromFile(
          data,
          'Please wait while saving the data.'
        );
      } else if (this.props.isUploadRMPMAvailability === true) {
        this.props.updateRMPMAvailability(
          data,
          'Please wait while saving the data.'
        );
      }
    }
  };

  // Consent
  handleVerifyConsent = () => {
    this.setState({
      verifyConsent: !this.state.verifyConsent,
      errors: {},
    });
  };

  reUpload = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
      errors: {},
      resError: null,
    });
  };

  handlingFiles = (file) => {
    if (file) {
      this.setState({
        uploadedRecordsFileName: file.name,
        uploadedRecordsFiles: file,
        resError: null,
        errors: {},
        errorMessageColor: 'black',
      });
    }
  };

  handleDeleteRecord = () => {
    this.setState({
      uploadedRecordsFiles: {},
      uploadedRecordsFileName: '',
      resError: null,
      errors: {},
      errorMessageColor: 'black',
    });
  };
  //close confirmation dialog
  handleCloseRecord = () => {
    this.setState({
      confirmDialog: false,
    });
    this.props.handleClose();
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const {
      activeStep,
      errors,
      resError,
      verifyConsent,
      uploadedRecordsFileName,
      disableNextButton,
      validatedRecordData,
      disableSubmitButton,
      disableReuploadButton,
      errorMessageColor,
    } = this.state;

    const getStepContent = (stepIndex) => {
      switch (stepIndex) {
        case 0:
          return (
            <UploadRecordFiles
              isUploadRMPMGRN={this.props.isUploadRMPMGRN}
              isUploadDSBatch={this.props.isUploadDSBatch}
              isUploadConsumptionInfo={this.props.isUploadConsumptionInfo}
              isUploadRMPMAvailability={this.props.isUploadRMPMAvailability}
              activeStep={activeStep}
              handlingFiles={this.handlingFiles}
              handleDeleteRecord={this.handleDeleteRecord}
              fileName={uploadedRecordsFileName}
              errors={errors}
              resError={resError}
              handleNext={this.handleNext}
              disableNextButton={disableNextButton}
              downloadSampleFile={this.downloadSampleFile}
              errorMessageColor={errorMessageColor}
            />
          );
        case 1:
          return (
            <ValidateRecord
              isUploadRMPMGRN={this.props.isUploadRMPMGRN}
              isUploadDSBatch={this.props.isUploadDSBatch}
              isUploadConsumptionInfo={this.props.isUploadConsumptionInfo}
              isUploadRMPMAvailability={this.props.isUploadRMPMAvailability}
              handleVerifyConsent={this.handleVerifyConsent}
              verifyConsent={verifyConsent}
              errors={errors}
              resError={resError}
              activeStep={activeStep}
              ref={resError ? 'errorRef' : null}
              validatedRecordData={validatedRecordData}
              disableSubmitButton={disableSubmitButton}
              disableReuploadButton={disableReuploadButton}
              reUpload={this.reUpload}
              handleSubmitRecords={this.handleSubmitRecords}
            />
          );

        default:
          return 'Unknown stepIndex';
      }
    };

    return (
      <React.Fragment>
        <Grid container justify='center'>
          <Grid
            item
            xs={11}
            sm={10}
            md={12}
            lg={12}
            xl={12}
            className={classes.backgroundGrid}
          >
            <Grid container justify='center'>
              <Grid item xs={12} sm={11} md={11} lg={8} xl={7}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step
                      key={label}
                      classes={{
                        root: classes.step,
                      }}
                    >
                      <StepLabel
                        StepIconProps={{
                          classes: {
                            root: classes.step,
                            completed: classes.completed,
                          },
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={12} md={9} lg={8} xl={6}>
                {getStepContent(activeStep)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Dialog open={this.state.confirmDialog} fullWidth>
          <StyledDialog
            handleClose={this.handleCloseRecord}
            icons={true}
            title='Details Saved Successfully'
            subtitle='Your Record has been added/updated.Please Contact the Admin if you have Entered the Wrong Data.'
          />
        </Dialog>

        <Dialog open={this.state.validateRecord} fullWidth>
          <ValidateDialog
            title='Validating file…'
            subtitle='We are in the process of validating your file, please don’t refresh, reload or go back.'
          />
        </Dialog>

        {/* backDrop */}
        <BackDropComponent />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  uploadGRFile: state.pcmp.cmo.shortFalls.uploadGRFile,
  updateGRRecords: state.pcmp.cmo.shortFalls.updateGRRecords,
  backdropLoading: state.pcmp.common.files.backdropLoading,
  uploadDSBatchFile: state.pcmp.common.sasl.uploadDSBatch,
  updateDSBatchFile: state.pcmp.common.sasl.updateDSBatch,

  uploadSASLConsumptionFile: state.pcmp.common.sasl.uploadSASLConsumption,
  updateSASLConsumptionFile: state.pcmp.common.sasl.updateSASLConsumption,
  uploadRMPMFile: state.pcmp.common.sasl.uploadRMPM,
  updateRMPMFile: state.pcmp.common.sasl.updateRMPM,
});
export default connect(mapStateToProps, {
  uploadGRNRecord,
  downloadSampleGRFile,
  updateGRNRecords,
  AddGRNFetchTable,
  fetchSASLDetails,
  uploadSaSlBatchDatafromFile,
  updateSaSlBatchDatafromFile,
  uploadSaSlConsumptionInfofromFile,
  updateSaSlConsumptionInfofromFile,
  uploadRMPMAvailability,
  updateRMPMAvailability,
})(withStyles(Styles)(NewRecord));
