import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { downloadSingleGRFile } from '../../../containers/actions/common/fileActions';

const useStyles = makeStyles((theme) => ({
  closeText: {
    color: 'blue',
    textDecoration: 'underline',
  },
  textDiv: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '0.5rem',
    cursor: 'pointer',
  },
}));

const ShowFiles = (props) => {
  const classes = useStyles();
  const { files, handlingDeleteFile, name, isUploadFile, filesRequired } =
    props;
  const dispatch = useDispatch();

  let grnFeatures = useSelector((state) => {
    return state.pcmp.common.accessControl.featuresAccess.grnFeatures;
  });

  let backdropText = 'Downloading, Please Wait...';

  const downloadFile = (schrockenGRFileId) => {
    if (!isUploadFile && grnFeatures.downloadGrDocument === true) {
      dispatch(downloadSingleGRFile({ schrockenGRFileId }, backdropText));
    }
  };
  if (files.length > 0) {
    return (
      <React.Fragment>
        {files.map((file, i) => {
          return (
            file.markForDelete === false && (
              <div className={classes.textDiv} key={i}>
                <Typography
                  variant='body2'
                  className={classes.closeText}
                  onClick={() => downloadFile(file.schrockenGRFileId)}
                >
                  {file.grFileName}
                </Typography>
                {isUploadFile === true && file.schrockenGRFileId === '' && (
                  <CloseIcon
                    fontSize='small'
                    color='error'
                    style={{ paddingLeft: '0.5rem' }}
                    onClick={() => handlingDeleteFile(i, name)}
                  />
                )}
              </div>
            )
          );
        })}
        {/* <BackDropComponent/> */}
      </React.Fragment>
    );
  } else {
    return (
      <Typography
        variant='body2'
        style={{ paddingBottom: '0.5rem' }}
        color={filesRequired ? 'error' : 'inherit'}
      >
        -No Documents Uploaded-
      </Typography>
    );
  }
};

export default ShowFiles;
