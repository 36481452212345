import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CreateIcon from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#FFFFFF',
    color: 'black',
  },

  hide: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  closeText: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  backText: {
    // display: 'flex',
    // alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  icon: {
    paddingLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      fontSize: '1.7rem',
    },
  },
  grid: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '2px solid red',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'end',
    },
  },
  backGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  title: {
    fontWeight: 500,
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.875rem',
    },
  },
  children: {
    paddingTop: '1rem',
  },
  button: {
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
      borderBottom: '2px solid black',
    },
  },
}));

const Header = ({ children, ...props }) => {
  const classes = useStyles();
  const { handleClose, title, isButtonsExist } = props;

  return (
    <React.Fragment>
      <AppBar
        position='sticky'
        style={{
          backgroundColor: '#FFFFFF',
          color: 'black',
          boxShadow: 'none',
          borderBottom: '1px solid #B4B4B4',
        }}
      >
        <Toolbar>
          <Grid container className={classes.root}>
            {/* <Grid item xs={2} sm={1} md={1} className={classes.backGrid}>
              <Button
                onClick={handleClose}
                disableElevation
                disableRipple
                disableFocusRipple
                className={classes.button}
              >
                <ArrowBackIosIcon
                  fontSize='small'
                  className={classes.icon}
                  onClick={handleClose}
                />
                <Typography variant='body2' className={classes.backText}>
                  Back
                </Typography>
              </Button>
            </Grid> */}

            <Grid item xs={2} sm={1} md={1} className={classes.backGrid}>
              <div style={{ display: 'flex' }} className={classes.button}>
                <ArrowBackIosIcon
                  fontSize='small'
                  className={classes.icon}
                  onClick={handleClose}
                />
                <Typography
                  variant='body2'
                  className={classes.backText}
                  onClick={handleClose}
                >
                  Back{' '}
                </Typography>
              </div>
            </Grid>

            <Grid
              item
              xs={9}
              sm={isButtonsExist ? 9 : 10}
              md={isButtonsExist ? 9 : 10}
            >
              <Typography variant='h5' className={classes.title}>
                {/* Add New Record  */}
                {title}
              </Typography>
            </Grid>
            {!isButtonsExist ? (
              <React.Fragment>
                <Grid item xs={1} sm={1} md={1} className={classes.grid}>
                  <Typography
                    variant='body2'
                    className={classes.closeText}
                    onClick={handleClose}
                  >
                    Cancel{' '}
                  </Typography>
                  <CloseIcon
                    fontSize='large'
                    color='error'
                    className={classes.icon}
                    onClick={handleClose}
                  />
                </Grid>
              </React.Fragment>
            ) : null}

            {isButtonsExist && (
              <React.Fragment>
                <Grid item xs={1} sm={1} md={1}>
                  <Button
                    variant='outlined'
                    size='small'
                    onClick={handleClose}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    className={classes.button}
                  >
                    Close Batch
                  </Button>
                </Grid>

                <Grid item xs={1} sm={1} md={1}>
                  <Button
                    variant='outlined'
                    size='small'
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    // className={classes.button}
                    startIcon={
                      <CreateIcon
                        style={{
                          fontSize: 15,
                        }}
                      />
                    }
                  >
                    Edit Details
                  </Button>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        className={classes.children}
      // style={{ paddingTop: props.padding === true ? 100 : 40 }}
      >
        {children}
      </Grid>
    </React.Fragment>
  );
};

export default Header;
