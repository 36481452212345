import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledButton from '../../../../common/models/Button';
import { StyledCheckbox } from '../../../../common/models/CheckBox';
import { connect } from 'react-redux';

import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import EventTwoToneIcon from '@material-ui/icons/Event';
//func
import { downloadExcel } from '../../../containers/actions/cmo/shortFallsActions';
import { CMOmembersList } from '../../../containers/actions/cmo/shortFallsActions';

// backdrop
import CircularProgress from '@material-ui/core/CircularProgress';
// import BackDropComponent from '../models/BackDrop';
// import moment from 'moment';

const useStyles = (theme) => ({
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    justifyContent: 'flex-end',
    '&:hover': {
      borderBottom: '0.5px solid red',
      color: '#e36e39',
    },
  },
  select: {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    fontWeight: 500,
  },
  font: {
    fontWeight: 500,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  textfield: {
    paddingRight: '1rem',
    paddingBottom: '3rem',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
  textfield2: {
    paddingLeft: '1rem',
    paddingBottom: '3rem',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  button: {
    padding: '1rem',
    paddingBottom: '3rem',
  },
  grid: {
    justifyContent: 'flex-end',
  },
  Totext: {
    paddingTop: '2.5rem',
    paddingLeft: '2rem',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: '2rem',
    },
  }
});

class ExportToExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      membersList: [],
      checkedValues: [],
      materialReceivedStartDate: null,
      materialReceivedEndDate: null,
      resError: null,
    };
  }

  componentDidMount() {
    if (localStorage.collabType !== 'CMO') {
      let {
        collabType,
        ecosystemId,
        organizationName,
        roleName,
      } = localStorage;
      const data = {
        collabType,
        ecosystemId,
        organizationName,
        roleName,
      };
      this.props.CMOmembersList(data);
    }
    if (localStorage.collabType === 'CMO') {
      this.setState({
        loading: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.cmoList !== prevProps.cmoList &&
      this.props.cmoList.status === true
    ) {
      this.setState({
        membersList: this.props.cmoList.CMOMembers,
        loading: false,
      });
    }
    if (
      this.props.exportExcel !== prevProps.exportExcel &&
      this.props.exportExcel.status === true
    ) {
      this.props.handleClose();
    }
    if (
      this.props.exportExcel !== prevProps.exportExcel &&
      this.props.exportExcel.status === false
    ) {
      this.setState({
        resError: this.props.exportExcel.message,
      });
    }
  }

  validate = () => {
    let error = false;
    // let errors = { }
    const {
      materialReceivedStartDate,
      materialReceivedEndDate,
      checkedValues,
    } = this.state;

    if (materialReceivedStartDate === null) {
      error = true;
      this.setState({
        resError: `Please select the 'from' Date range`,
      });
    } else if (materialReceivedEndDate === null) {
      error = true;
      this.setState({
        resError: `Please select the 'to' Date range`,
      });
    } else if (localStorage.collabType !== 'CMO') {
      if (checkedValues.length === 0) {
        error = true;
        this.setState({
          resError: `Please select Members`,
        });
      }
    }

    return error;
  };

  handleCheck(e, x) {
    this.setState((state) => ({
      checkedValues: state.checkedValues.includes(x)
        ? state.checkedValues.filter((c) => c !== x)
        : [...state.checkedValues, x],
    }));
    this.setState({ resError: null });
  }

  handleChange = (name, date) => {
    let d;

    if (name === 'materialReceivedStartDate') {
      // d = moment(date).set('hour', 0).set('minute', 0).set('second', 1).set('milliseconds',1).toISOString();

      d = new Date(date);
      d.setUTCHours(0, 0, 0, 0); //Initializes time at 00:00:00 for date format
      this.setState({
        materialReceivedStartDate: d.toISOString(),
        resError: null,
      });
    }
    if (name === 'materialReceivedEndDate') {
      // d = moment(date).set('hour', 23).set('minute', 59).set('second', 59).set('milliseconds',999);
      // d = new Date(date);
      // let dt = new Date(d);

      // d.setUTCHours(23,59,0,0);   //Initializes time at 23:59:59:999 for date format
      this.setState({
        materialReceivedEndDate: date.toISOString(),
        resError: null,
      });
    }
  };

  findVendor = () => {
    let isExist = this.state.checkedValues.includes('all');
    return isExist;
  };

  handleSubmit = () => {
    this.setState({ resError: null });
    let data;
    let backdropText = 'Please Wait. Excel is being downloaded';

    let isError = this.validate();

    if (!isError) {
      if (localStorage.collabType !== 'CMO') {
        let findVendor = this.findVendor();

        if (findVendor) {
          data = {
            vendorName: ['all'],
            materialReceivedStartDate: this.state.materialReceivedStartDate,
            materialReceivedEndDate: this.state.materialReceivedEndDate,
          };
        } else if (!findVendor) {
          data = {
            vendorName: this.state.checkedValues,
            materialReceivedStartDate: this.state.materialReceivedStartDate,
            materialReceivedEndDate: this.state.materialReceivedEndDate,
          };
        }
      } else {
        data = {
          vendorName: [`${localStorage.organizationName}`],
          materialReceivedStartDate: this.state.materialReceivedStartDate,
          materialReceivedEndDate: this.state.materialReceivedEndDate,
        };
      }

      this.props.downloadExcel(data, backdropText);
    }
  };

  render() {
    const { classes } = this.props;
    const { collabType } = localStorage;
    const {
      loading,
      materialReceivedStartDate,
      materialReceivedEndDate,
      resError,
    } = this.state;
    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    let findVendor = this.findVendor();

    return (
      <DialogContent>
        {loading === true ? (
          <Grid container justify='center' style={{ padding: '10rem' }}>
            <div
              style={{
                textAlign: 'center',
                minWidth: '60vw',
              }}
            >
              <CircularProgress color='primary' thickness={7} />
            </div>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container>
              <Grid
                item
                xs={11}
                sm={collabType === 'CMO' ? 11 : 11}
                md={collabType === 'CMO' ? 11 : 11}
              >
                <Typography variant='h5' style={{ fontWeight: 500,padding:'0.5rem' }}>
                  Select &amp; Download
                </Typography>
              </Grid>

              <Grid
                item
                xs={1}
                sm={collabType === 'CMO' ? 1 : 1}
                md={collabType === 'CMO' ? 1 : 1}
                className={classes.grid}
              >
                <Typography
                  variant='body2'
                  className={classes.closeText}
                  onClick={this.props.handleClose}
                >
                  Close <CloseIcon fontSize='large' />
                </Typography>
              </Grid>

              {resError && (
                <Grid
                  container
                  justify='center'
                  style={{ paddingTop: resError !== null ? '2rem' : 0 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      variant='h6'
                      style={{
                        border: '1px solid #FF0000',
                        backgroundColor: '#FFF0F0',
                        opacity: '1',
                        textAlign: 'center',
                        padding: '0.8rem',
                        fontSize: '0.875rem',
                      }}
                    >
                      {resError}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {collabType !== 'CMO' && (
                <FormGroup>
                  <Grid container >
                    <Typography variant='body1' className={classes.select}>
                      Select Members
                    </Typography>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControlLabel
                        control={
                          <StyledCheckbox
                            // checked={this.state.allCMO}
                            name='all'
                            onChange={(e) => this.handleCheck(e, 'all')}
                          />
                        }
                        label='All CMO Members'
                      />
                    </Grid>

                    {this.state.membersList.map((member, i) => {
                      return (
                        <Grid item xs={12} sm={5} md={5} key={i}>
                          <FormControlLabel
                            control={
                              <StyledCheckbox
                                name={member}
                                onChange={(e) => this.handleCheck(e, member)}
                                // checked={this.state.checkedValues.includes(member) ? true : false}
                                disabled={findVendor === true || member === null ? true : false}
                                checked={
                                  this.state.checkedValues.includes('all')
                                    ? false
                                    : this.state.checkedValues.includes(member)
                                }
                              />
                            }
                            label={member}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </FormGroup>
              )}
            </Grid>
            <Grid container style={{ padding: '0.5rem' }}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant='body1' className={classes.select}>
                Select Mosymphony entry date range and download the related data
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={5}
                md={collabType === 'CMO' ? 4 : 4}
                className={classes.textfield}
              >
                <Typography variant='body2' className={classes.font}>
                  Select from date
                </Typography>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    autoOk={true}
                    // disableToolbar
                    variant='inline'
                    inputVariant='outlined'
                    format={dateFormat}
                    fullWidth
                    name='materialReceivedStartDate'
                    value={materialReceivedStartDate}
                    onChange={(date) =>
                      this.handleChange('materialReceivedStartDate', date)
                    }
                    placeholder={dateFormat}
                    size='small'
                    // views={['date', 'month', 'year']}
                    disableFuture={true}
                    InputProps={{
                      endAdornment: (
                        <EventTwoToneIcon
                          style={{ color: '#0000008a', cursor: 'pointer' }}
                        />
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} sm={1} md={1}>
                <Typography
                  variant='h6'
                  className={classes.Totext}
                  style={{
                    paddingLeft: collabType === 'CMO' ? '2rem' : '2rem',
                  }}
                >
                  to
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={5}
                md={collabType === 'CMO' ? 4 : 4}
                className={classes.textfield2}
              >
                <Typography variant='body2' className={classes.font}>
                  Select to date
                </Typography>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    autoOk={true}
                    // disableToolbar
                    variant='inline'
                    inputVariant='outlined'
                    format={dateFormat}
                    fullWidth
                    name='materialReceivedEndDate'
                    value={materialReceivedEndDate}
                    onChange={(date) =>
                      this.handleChange('materialReceivedEndDate', date)
                    }
                    placeholder={dateFormat}
                    size='small'
                    disableFuture={true}
                    // views={['date', 'month', 'year']}
                    minDate={  materialReceivedStartDate  }
                    disabled={materialReceivedStartDate === null ? true : false}
                    InputProps={{
                      endAdornment: (
                        <EventTwoToneIcon
                          style={{ color: '#0000008a', cursor: 'pointer' }}
                        />
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={12} md={12} className={classes.button}>
                <StyledButton onClick={this.handleSubmit}>
                  Download excel
                </StyledButton>
              </Grid>
            </Grid>

            {/* <BackDropComponent /> */}
          </React.Fragment>
        )}
      </DialogContent>
    );
  }
}

const mapStateToProps = (state) => ({
  // updateGRNData: state.pcmp.cmo.shortFalls.updateGRNData
  backdropLoading: state.pcmp.common.files.backdropLoading,
  comment: state.pcmp.common.files.comment,
  cmoList: state.pcmp.cmo.shortFalls.cmoList,
  exportExcel: state.pcmp.cmo.shortFalls.exportExcel,
});

export default connect(mapStateToProps, { downloadExcel, CMOmembersList })(
  withStyles(useStyles)(ExportToExcel)
);
