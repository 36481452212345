import React from 'react';
import Grid from '@material-ui/core/Grid';

import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';

//components
import Options from './Options';
import SelectTypeRole from './SelectTypeRole';
import InvitationSent from '../../../../common/InvitationSent';
import UserDetails from '../../../../common/UserDetails';

const InsideOrgInvite = (props) => {
  const {
    multiUsers,
    inviteSent,
    collabRoles,
    email,
    fullName,
    phoneNumber,
    changePhoneNumber,
    handleChangeEmailandName,
    errors,
    handleClose,
    sendAnotherInvite,
    collaboratorRoleId,
    handleChangeCollaborator,
    twoStepAuthentication,
    editPhoneNumber,
    azureADSignIn,
    handleChangeOptions,
  } = props;
  return (
    <Grid container alignItems='center' justify='center'>
      <Grid item xs={10} sm={10} md={multiUsers === true ? 9 : 6}>
        <Grid container spacing={2} justify='center'>
          <Grid item xs={12} sm={10}>
            <SelectTypeRole
              collabRoles={collabRoles}
              collaboratorRoleId={collaboratorRoleId}
              errors={errors}
              handleChangeCollaborator={handleChangeCollaborator}
            />
          </Grid>
          <Grid item xs={12} sm={10}>
            <UserDetails
              email={email}
              fullName={fullName}
              phoneNumber={phoneNumber}
              changePhoneNumber={changePhoneNumber}
              handleChangeEmailandName={handleChangeEmailandName}
              errors={errors}
            />
            <Divider style={{ marginTop: '1rem' }} />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Options
              twoStepAuthentication={twoStepAuthentication}
              editPhoneNumber={editPhoneNumber}
              azureADSignIn={azureADSignIn}
              handleChangeOptions={handleChangeOptions}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={inviteSent} onClose={handleClose} email={email}>
        <InvitationSent
          handleClose={handleClose}
          sendAnotherInvite={sendAnotherInvite}
          email={email}
        />
      </Dialog>
    </Grid>
  );
};

export default InsideOrgInvite;
