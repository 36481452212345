import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    body: {
        border: '2px solid #EAEAEA',
        backgroundColor: '#FAFAFA',
        padding: '1rem',
    },
    font: {
        fontWeight: 500,
        textAlign: 'initial',
    },
    subtitle: {
        fontSize: '1.5rem',
    },
    padding: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
}));


const BatchBody = (props) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className={classes.padding}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant='body2' className={classes.font}>
                            {props.title}
                        </Typography>
                    </Grid>
                    <br />
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant='body2' className={classes.subtitle}>
                            {props.subtitle}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment >
    );
};

export default BatchBody;