import React from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import AddSupplierPoRecord from '../../supplierPoAdd';
import UpdatePORecord from '../../supplierPOUpdate';
import useStyles from './buttonStyles';

const Update = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickRecieved = () => {
    setOpen(true);
  };
  return (
    <React.Fragment>
      <Button
        disableElevation
        disableFocusRipple
        disableRipple
        className={classes.button}
        onClick={handleClickRecieved}
      >
        <div className={classes.logo}>
          <AutorenewIcon fontSize='small' className={classes.logoHeight} />
          &nbsp;
          <Typography variant='body1'>Update</Typography>
        </div>
      </Button>
      <UpdatePORecord
        open={open}
        handleClose={handleClose}
        {...props}
        isRecordDelete={false}
        isUpdateRecord={true}
      />
    </React.Fragment>
  );
};

export default Update;
