import React from 'react';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useStyles from '../../../models/buttons/buttonStyles';
import { useHistory } from 'react-router-dom';

const ViewBatch = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const {
    schrockenDeliveryScheduleID,
    scheduleAgreementNumber,
    fgMaterialCode,
    vendorCode,
  } = props;
  // const { deliveryScheduleUOM } = props.row;
  const openScheduleLineDetails = () => {
    history.push(
      `/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${localStorage.ecosystemId}/sasl/${schrockenDeliveryScheduleID}`,
      {
        scheduleAgreementNumber: scheduleAgreementNumber,
        fgMaterialCode: fgMaterialCode,
        vendorCode: vendorCode,
      }
    );
  };
  return (
    <React.Fragment>
      <Button
        disableElevation
        disableFocusRipple
        disableRipple
        className={classes.button}
        onClick={openScheduleLineDetails}
      >
        <div className={classes.logo}>
          <LibraryAddIcon fontSize='small' className={classes.logoHeight} />
          &nbsp;
          <Typography variant='body1' style={{ fontSize: '0.875rem' }}>
            View Batches
          </Typography>
        </div>
      </Button>
    </React.Fragment>
  );
};

export default ViewBatch;
