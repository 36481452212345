import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';


export const useStyles = makeStyles((theme) => ({
    multipleLogo: {
        border: '1px solid #7d7c7c',
        borderRadius: '4px',
        padding: '0.3rem',
        height: 'inherit',
        marginLeft: '0.5rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'black',
            color: 'white',
            border: '1px solid #FFF',
        },
    },
    list: {
        maxWidth: '80vw',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100vw',
        },
    },
    fullList: {
        width: 'auto',
    },
    received: {
        textAlign: 'left',
    },
}));


const ViewButton = (props) => {
    const history = useHistory();
    const classes = useStyles();

    const { schrockenDeliveryScheduleID } = props;
    // const { deliveryScheduleUOM } = props.row;
    const openScheduleLineDetails = () => {
        history.push(
            `/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${localStorage.ecosystemId}/sasl/${schrockenDeliveryScheduleID}`
        );
    };


    return (
        <React.Fragment>
            <VisibilityIcon
                fontSize='small'
                className={classes.multipleLogo}
                onClick={openScheduleLineDetails}
            />
        </React.Fragment>
    );
};

export default ViewButton;