import React, { Component } from "react";

import { Grid, Typography, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import LineItemImage from "../../../resources/images/line-items-icon.svg";

const styles = {
  cardTitle: {
    background: "#253239",
    padding: "0.5rem 1rem",
  },
  cardTitleText: {
    color: "#ffffff",
    fontSize: "1.05rem",
  },
  card: {
    padding: "0.5rem 1rem",
    border: "1px solid #c1c1c1",
    borderTopWidth: 0,
  },
  poImg: {
    width: "100%",
  },
  divider: {
    margin: "0.5rem -1rem",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  mr: {
    // color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  wrapText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      whiteSpace: "normal",
    },
  },
};

class LineItemSummary extends Component {
  render() {
    const { classes, lineItemSummaryData, renderTableData } = this.props;
    return (
      <Grid item xs={12} sm={6} md={4}>
        <Grid item xs={12} className={classes.cardTitle}>
          <Typography variant="h6" className={classes.cardTitleText}>
            Line Item Summary
          </Typography>
        </Grid>
        {lineItemSummaryData === false ? (
          <div
            style={{
              border: "1px solid #c1c1c1",
              minHeight: "13.4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="subtitle1">Loading...</Typography>
          </div>
        ) : (
          <div className={classes.card}>
            <Grid item xs={12}>
              <Grid container spacing={2} className={classes.flex}>
                <Grid item xs={2}>
                  <img
                    src={LineItemImage}
                    alt="Purchase Orders"
                    className={classes.poImg}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: "1.25rem",
                    }}
                    color="secondary"
                  >
                    Line Items
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.flexEnd}>
                  {parseInt(lineItemSummaryData.totalLineItems) === 0 ? (
                    <Typography
                      variant="h5"
                      color="secondary"
                      className={classes.wrapText}
                    >
                      {lineItemSummaryData.totalLineItems}
                    </Typography>
                  ) : (
                    <Typography
                      variant="h5"
                      color="secondary"
                      className={`${classes.mr} ${classes.wrapText}`}
                      onClick={() =>
                        renderTableData(
                          "line",
                          1,
                          0,
                          "Line Item Summary",
                          "Line Items"
                        )
                      }
                    >
                      {lineItemSummaryData.totalLineItems}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />

            <Grid container spacing={2} className={classes.flex}>
              <Grid item xs={10}>
                <Typography variant="body2" className={classes.wrapText}>
                  Created/Accepted/Scheduled
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.flexEnd}>
                {parseInt(lineItemSummaryData.row1) === 0 ? (
                  <Typography
                    variant="h5"
                    color="secondary"
                    className={classes.wrapText}
                  >
                    {lineItemSummaryData.row1}
                  </Typography>
                ) : (
                  <Typography
                    variant="h5"
                    color="secondary"
                    className={`${classes.mr} ${classes.wrapText}`}
                    onClick={() =>
                      renderTableData(
                        "line",
                        1,
                        1,
                        "Line Item Summary",
                        "Created/Accepted/Scheduled"
                      )
                    }
                  >
                    {lineItemSummaryData.row1}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Divider className={classes.divider} />

            <Grid container spacing={2} className={classes.flex}>
              <Grid item xs={10}>
                <Typography variant="body2" className={classes.wrapText}>
                  Under Production
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.flexEnd}>
                {parseInt(lineItemSummaryData.row2) === 0 ? (
                  <Typography
                    variant="h5"
                    color="secondary"
                    className={classes.wrapText}
                  >
                    {lineItemSummaryData.row2}
                  </Typography>
                ) : (
                  <Typography
                    variant="h5"
                    color="secondary"
                    className={`${classes.mr} ${classes.wrapText}`}
                    onClick={() =>
                      renderTableData(
                        "line",
                        1,
                        2,
                        "Line Item Summary",
                        "Under Production"
                      )
                    }
                  >
                    {lineItemSummaryData.row2}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid container spacing={2} className={classes.flex}>
              <Grid item xs={10}>
                <Typography variant="body2" className={classes.wrapText}>
                  Production Complete/WIP/QC/Ready For Dispatch/Dispatch
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.flexEnd}>
                {parseInt(lineItemSummaryData.row3) === 0 ? (
                  <Typography
                    variant="h5"
                    color="secondary"
                    className={classes.wrapText}
                  >
                    {lineItemSummaryData.row3}
                  </Typography>
                ) : (
                  <Typography
                    variant="h5"
                    color="secondary"
                    className={`${classes.mr} ${classes.wrapText}`}
                    onClick={() =>
                      renderTableData(
                        "line",
                        1,
                        3,
                        "Line Item Summary",
                        "Production Complete/WIP/QC/Ready For Dispatch/Dispatch"
                      )
                    }
                  >
                    {lineItemSummaryData.row3}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(LineItemSummary);
