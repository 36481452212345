import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import LoopIcon from '@material-ui/icons/Loop';

export const scheduledHeader = (scheduleDateFlag) => {
  let header = [
    {
      id: 'supplierPurchaseOrderNumber',
      numeric: true,
      disablePadding: false,
      label: 'PO/STO No.',
      varName: 'supplierPurchaseOrderNumber',
      align: 'left',
      width: '2%',
      isSortEnable: true,
    },
    {
      id: 'materialReceivedDate',
      numeric: true,
      disablePadding: false,
      label: 'Received Date',
      varName: 'materialReceivedDate',
      align: 'left',
      width: '5%',
      isSortEnable: true,
    },
    {
      id: 'materialType',
      numeric: true,
      disablePadding: false,
      label: 'Material Type',
      varName: 'materialType',
      align: 'left',
      width: '3%',
      enableOnlyOnLargeScreen: true,
      isSortEnable: true,
    },

    {
      id: 'materialName',
      numeric: false,
      disablePadding: false,
      label: 'Material Code/\nDescription',
      varName: 'materialName',
      align: 'left',
      width: '5%',
      isSortEnable: true,
    },
    {
      id: 'unitOfMeasurementOrdered',
      numeric: true,
      disablePadding: false,
      label: 'UOM',
      varName: 'unitOfMeasurementOrdered',
      align: 'left',
      width: '2%',
      isSortEnable: true,
    },
    {
      id: 'quantityOrdered',
      numeric: true,
      disablePadding: false,
      label: 'Total Qty.',
      varName: 'quantityOrdered',
      align: 'right',
      width: '4%',
      enableOnlyOnLargeScreen: true,
      isSortEnable: true,
    },
    {
      id: 'totalQuantityReceived',
      numeric: true,
      disablePadding: false,
      label: 'Total Received Qty.',
      varName: 'totalQuantityReceived',
      align: 'right',
      width: '4%',
      isSortEnable: true,
    },
    {
      id: 'quantityYetToReceive',
      numeric: false,
      disablePadding: false,
      label: 'Pending Qty.',
      varName: 'quantityYetToReceive',
      align: 'right',
      width: '3%',
      isSortEnable: true,
    },
    {
      id: 'supplierName',
      numeric: true,
      disablePadding: false,
      label: 'Supplier',
      varName: 'supplierName',
      align: 'left',
      width: '6%',
      enableOnlyOnLargeScreen: true,
      isSortEnable: true,
    },
    {
      id: 'lastUpdatedBy',
      numeric: true,
      disablePadding: false,
      label: 'Approved/Rejected By',
      varName: '',
      align: 'left',
      width: '5%',
      enableOnlyOnLargeScreen: true,
      isSortEnable: false,
    },
    {
      // id: 'MRN',
      id: 'numberMRN',
      numeric: true,
      disablePadding: false,
      label: 'MRN Number',
      varName: 'numberMRN',
      align: 'left',
      width: '6%',
      enableOnlyOnLargeScreen: true,
      isSortEnable: true,
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: 'Status',
      varName: '',
      align: 'center',
      width: '9%',
      isSortEnable: false,
    },
    {
      id: 'numberGRN',
      numeric: true,
      disablePadding: false,
      label: 'GRN Number',
      varName: 'numberGRN',
      align: 'left',
      width: '5%',
      isSortEnable: true,
    },
    {
      id: 'Actions',
      numeric: true,
      disablePadding: false,
      label: 'Actions',
      varName: '',
      align: 'left',
      width: '2%',
      isSortEnable: false,
    },
  ];
  if (scheduleDateFlag) {
    header.splice(1, 0, {
      id: 'scheduledDeliveryDate',
      numeric: true,
      disablePadding: false,
      label: 'Sch. Delivery Date',
      varName: 'scheduledDeliveryDate',
      align: 'left',
      width: '5%',
      isSortEnable: true,
    });
    return header;
  }
  return header;
};

export const rejectOptions = [
  {
    value: 'resubmit',
    icon: <LoopIcon fontSize='medium' color='secondary' />,
    text: 'Repeat QC/QA',
    secondaryText: 'Ask CMO to Retest the Material',
  },
  {
    value: 'reject',
    icon: <WarningIcon fontSize='medium' color='error' />,
    text: 'Reject Quality',
    secondaryText: 'This ends lifecycle of the Batch',
  },
];
