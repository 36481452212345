import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

//functions
import {
  quickViewAction,
  recordTimelineAction,
  recordResolutionAction,
} from '../../../containers/actions/cmo/shortFallsActions';
import ReceivedQuantity from '../models/ReceivedQuantity';
import QualityAssurance from '../models/QualityAssuranceInfo';
import ChallanInfo from '../models/ChallanInfo';
import InvoiceInfo from '../models/InvoiceInfo';
import OtherInfo from '../models/OtherInfo';
import GeneralInfo from '../models/GeneralInfo';
import StyledButton from '../models/Button';
import Grid from '@material-ui/core/Grid';
import Header from '../models/NewHeader';
import FullWidthContent from '../wrappers/QuickViewWrapper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReasonForRejection from '../models/ReasonForRejection';
import RecordTimeline from '../Accordion';

const useStyles = (theme) => ({
  list: {
    maxWidth: '80vw',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vw',
    },
  },
  fullList: {
    width: 'auto',
  },
  received: {
    textAlign: 'left',
  },
});

class QuickView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      timeLine: [],
      resolution: [],
    };
  }

  componentDidMount() {
    const { collabType, roleName } = localStorage;
    const data = {
      supplierPurchaseOrderNumber: this.props.supplierPurchaseOrderNumber,
      materialCode: this.props.materialCode,
      schrockenSupplierPOBatchID: this.props.schrockenSupplierPOBatchID,
      collabType: collabType,
      roleName: roleName,
    };
    this.props.quickViewAction(data);

    //record timeline func
    this.props.recordTimelineAction(data);
    //record resolution func
    this.props.recordResolutionAction(data);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.supplierPOData &&
      this.props.supplierPOData !== prevProps.supplierPOData
    ) {
      this.setState({ data: this.props.supplierPOData, loading: false });
    }

    if (
      this.props.recordTimeline &&
      this.props.recordTimeline !== prevProps.recordTimeline
    ) {
      this.setState({
        timeLine: this.props.recordTimeline.data,
      });
    }
    if (
      this.props.recordResolution &&
      this.props.recordResolution !== prevProps.recordResolution
    ) {
      this.setState({
        resolution: this.props.recordResolution.data,
      });
    }
  }

  dialogClosClearData = () => {
    this.props.setDialogOpen();
    this.setState({ loading: true, data: null });
  };

  render() {
    const { classes } = this.props;
    const { loading, data } = this.state;
    return (
      <div className={classes.list} role='presentation'>
        <Header
          title='RM/PM Line Item Details'
          isDrawer={true}
          handleClose={this.dialogClosClearData}
        >
          <FullWidthContent>
            {loading === false ? (
              <React.Fragment>
                <GeneralInfo
                  generalInfo={data.POLineRecordDetails}
                  general={data.GRDetails.POBatchRecordDetails}
                  errors={{}}
                />
                <hr />
                {data.GRDetails.POBatchRecordDetails.batchStatusID === 4 && (
                  <React.Fragment>
                    <ReasonForRejection
                      comments={data.GRDetails.POBatchRecordDetails}
                    />
                    <hr />
                  </React.Fragment>
                )}
                <ReceivedQuantity
                  receivedQuantityInfo={data.GRDetails.receivedQuantityInfo}
                  errors={{}}
                  POLineRecordDetails={data.POLineRecordDetails}
                  status={data.GRDetails.POBatchRecordDetails.batchStatusID}
                />
                <hr />
                <QualityAssurance
                  qualityAssuranceInfo={data.GRDetails.qualityAssuranceInfo}
                  errors={{}}
                  POLineRecordDetails={data.POLineRecordDetails}
                />
                <hr />
                <ChallanInfo
                  challanInformation={data.GRDetails.challanInformation}
                  errors={{}}
                />
                <hr />
                <InvoiceInfo
                  invoiceInformation={data.GRDetails.invoiceInformation}
                  errors={{}}
                />
                <hr />
                <OtherInfo
                  POBatchRecordDetails={data.GRDetails.POBatchRecordDetails}
                  errors={{}}
                />

                <RecordTimeline
                  timeLine={this.state.timeLine}
                  resolution={this.state.resolution}
                />
                <Grid container justify='center'>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={2}
                    style={{
                      textAlign: 'center',
                      padding: '1rem',
                    }}
                  >
                    <StyledButton onClick={this.dialogClosClearData} fullWidth>
                      Close
                    </StyledButton>
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : (
              <Grid container justify='center'>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '25%',
                    minWidth: '60vw',
                  }}
                >
                  <CircularProgress color='primary' thickness={7} />
                </div>
              </Grid>
            )}
          </FullWidthContent>
        </Header>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  supplierPOData: state.pcmp.cmo.shortFalls.quickView,
  recordTimeline: state.pcmp.cmo.shortFalls.recordTimeline,
  recordResolution: state.pcmp.cmo.shortFalls.recordResolution,
});

export default withStyles(useStyles)(
  connect(mapStateToProps, {
    quickViewAction,
    recordTimelineAction,
    recordResolutionAction,
  })(QuickView)
);
