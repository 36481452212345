import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { StyledCheckbox } from '../../../common/models/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledButton from '../../../common/models/Button';
import GroupedButton from './models/buttons/GroupedButton';
import { rejectOptions } from './supplierPoGrn/modules';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    fontSize: '1.2rem',
    textAlign: 'left',
  },
  child: {
    paddingTop: '4rem',
  },
  font: {
    fontWeight: 500,
    padding: '1rem',
  },
  fontPadding: {
    padding: '1rem',
  },
  button: {
    backgroundColor: 'transparent',
    textAlign: 'end',
  },
  error: {
    textAlign: 'initial',
    paddingTop: '1rem',
  },
  grid: {
    padding: '1rem',
    border: '0.5px solid #707070',
  },
  rejectOrIncompleteButton: {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid #000000de',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
}));

export default function OtherInfo({ children, ...props }) {
  const classes = useStyles();
  const {
    errors,
    verifyConsent,
    handleVerifyConsent,
    handleRecordSaved,
    handleSubmitRecord,
    handleApproveRecord,
    handleRecordRejected,
    status,
    action,
    selectOption,
    disableAccept,
  } = props;
  return (
    <React.Fragment>
      <Grid
        container
        className={classes.grid}
        style={{
          border:
            errors.verifyConsent === true
              ? '0.5px solid red'
              : '0.5px solid #707070',
        }}
      >
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={verifyConsent}
              onClick={handleVerifyConsent}
              // color={errors.verifyConsent === true ? 'red' : 'default'}
            />
          }
          color={errors.verifyConsent && 'error'}
          label={
            <Typography variant='body1'>
              I verify that all the details in this record have been verified
              and checked.
            </Typography>
          }
        />
      </Grid>
      <div className={classes.error}>
        {errors.verifyConsent && (
          <Typography variant='body2' color='error'>
            Please Verify that Entered Data is Correct
          </Typography>
        )}
      </div>

      {!props.noButtonsRequired && (
        <Grid container justify='space-between' style={{ padding: '3rem 0' }}>
          {status === 3 || status === 13 || status === 15 ? (
            <React.Fragment>
              <StyledButton
                onClick={handleApproveRecord}
                disabled={disableAccept}
              >
                Approve Record
              </StyledButton>
              {status === 13 && (
                <GroupedButton
                  value={action}
                  name='Reject Record'
                  selectOption={selectOption}
                  options={rejectOptions}
                />
              )}
              {status === 3 && (
                <StyledButton
                  onClick={handleRecordRejected}
                  className={classes.rejectOrIncompleteButton}
                >
                  Reject Record
                </StyledButton>
              )}
            </React.Fragment>
          ) : (
            localStorage.collabType === 'CMO' && (
              <StyledButton onClick={handleSubmitRecord}>
                Submit for Approval
              </StyledButton>
            )
          )}
          {localStorage.collabType === 'CMO' && status !== 4 && status !== 12 && (
            <StyledButton
              className={classes.rejectOrIncompleteButton}
              onClick={handleRecordSaved}
            >
              Record Incomplete,Save record
            </StyledButton>
          )}
        </Grid>
      )}
    </React.Fragment>
  );
}
