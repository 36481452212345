import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const ScheduleAgreementNumber = (props) => {
  const { handleChange, auditTrailObj } = props;
  return (
    <Grid item xs={12} sm={6} md={2}>
      <TextField
        name='saNumber'
        id='standard-basic'
        fullWidth
        variant='outlined'
        size='small'
        placeholder={'Enter SA Number'}
        value={auditTrailObj.saNumber}
        onChange={(e) => handleChange(e)}
      />
    </Grid>
  );
};

export default ScheduleAgreementNumber;
