import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from '../updateBatchStyles';
import InputNumber from '../../../../models/inputs/InputNumber';
import CustomDatePickerMui from '../../../../models/inputs/DateInput';
import { presentDate } from '../../../../functionalComponents/dataFormatFunctions';

const ShippingInformation = (props) => {
  const classes = useStyles();
  const { batchDetails, errors, handleDateChange, handleChange, quickView } =
    props;

  return (
    <React.Fragment>
      <Grid container className={classes.grid}>
        <Grid item xs={12} sm={12} md={12} className={classes.grid}>
          <Typography variant='h4' className={classes.title}>
            Shipping Information
          </Typography>
          <span className={classes.borders}></span>
        </Grid>
        <Grid item xs={12} sm={5} md={3} lg={3}>
          <Typography variant='body2' className={classes.filesFont}>
            Dispatch Quantity
          </Typography>
          {!quickView && localStorage.collabType === 'CMO' ? (
            <InputNumber
              className={classes.fontPadding2}
              name='dispatchQuantity'
              inputProps={{ min: 0, step: 1 }}
              value={batchDetails.dispatchQuantity}
              onChange={handleChange}
              placeholder='Enter Dispatch Quantity'
              disabled={localStorage.collabType !== 'CMO' ? true : false}
              helperText={errors.dispatchQuantity}
              notFullWidth
            />
          ) : (
            <Typography variant='body2' className={classes.fontPadding}>
              {batchDetails.dispatchQuantity}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={5} md={3} lg={3}>
          <Typography variant='body2' className={classes.filesFont}>
            Dispatch Date
          </Typography>

          {!quickView && localStorage.collabType === 'CMO' ? (
            <CustomDatePickerMui
              name='dispatchDate'
              minDate={
                batchDetails.batchEndDate !== null
                  ? batchDetails.batchEndDate
                  : new Date()
              }
              value={batchDetails.dispatchDate}
              onChange={(date) => handleDateChange(date, 'dispatchDate')}
              helperText={errors.dispatchDate}
              disabled={localStorage.collabType !== 'CMO' ? true : false}
              notFullWidth
            />
          ) : (
            <Typography variant='body2' className={classes.fontPadding}>
              {presentDate(batchDetails.dispatchDate)}
            </Typography>
          )}
        </Grid>
      </Grid>
      <hr />
    </React.Fragment>
  );
};

export default ShippingInformation;
