import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { Box, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Autocomplete } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { fetchTeamMembers } from '../../../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '80%',
    padding: '1rem',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
  },
  headTitle: {
    fontWeight: 700,
  },
  dialogPaper: {
    width: '40%',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
  },

  steeper: {
    width: '40%',
    margin: '0 auto',
    position: 'relative',
  },
  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
  },
  title: {
    textAlign: 'center',
    fontWeight: 500,
    marginTop: '4rem',
  },

  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
  },
  inputLabel: {
    marginBottom: '0.5rem',
    fontSize: '85%',
  },
  requiredStar: {
    color: 'red',
  },
  saveBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'lowercase',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const AssignTeam = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const { openDialog, toggleDialog } = props;
  const [state, setState] = React.useState({
    errors: {},
  });

  const { CMOMemberOrgId, teamOptions, selectedTeamLead, selectedTeamMembers } =
    props.stateData;

  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD/MM/YYYY';

  const handleOnChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: null },
    }));
    props.handleChangeData(name, value);
    if (name === 'selectedTeamLead') {
      props.handleChangeData('selectedTeamMembers', []);
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
    } else {
      props.fetchTeamMembers({ organizationId: CMOMemberOrgId });
      didMountRef.current = true;
    }
  }, []);

  const handleValidations = () => {
    const errorsData = {};
    let isError = false;

    if (selectedTeamLead === ' ') {
      errorsData.selectedTeamLead = 'Please select team lead';
      isError = true;
    }

    if (selectedTeamMembers.length === 0) {
      errorsData.selectedTeamMembers = 'Please select team Members';
      isError = true;
    }

    if (isError) {
      setState((prevState) => ({
        ...prevState,
        errors: errorsData,
      }));
    } else {
      props.toggleDialog('assignTeamScreen');
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => toggleDialog('assignTeamScreen')}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='md'
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.headerContainer}>
        <div
          className={classes.closeBtn}
          onClick={() => toggleDialog('assignTeamScreen')}
        >
          <Typography
            variant='caption'
            style={{ lineHeight: 'normal', fontWeight: '700' }}
          >
            Cancel
          </Typography>
          <CloseIcon style={{ color: 'red' }} />
        </div>
      </div>
      <Box width='80%' margin='auto'>
        <Box>
          <Typography variant='h5' className={classes.title}>
            Assign Team for Investigation
          </Typography>
          <hr className={classes.hrLine} />
        </Box>
        <Box justifyContent='space-between'>
          <Box marginTop={2} width='100%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Search or Select a team lead from the list{' '}
              <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              name='selectedTeamLead'
              options={teamOptions}
              getOptionLabel={(option) =>
                option.userName ? `${option.userName}(${option.roleName})` : ''
              }
              value={selectedTeamLead}
              onChange={(event, value) => {
                handleOnChange('selectedTeamLead', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Start typing to search and select'
                  error={!!state.errors.selectedTeamLead}
                  helperText={state.errors.selectedTeamLead}
                />
              )}
            />
          </Box>

          <Box marginTop={2} width='100%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Search or Select a team member from the list{' '}
              <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              name='selectedTeamMembers'
              multiple={true}
              options={teamOptions}
              getOptionLabel={(option) =>
                option.userName ? `${option.userName}(${option.roleName})` : ''
              }
              value={selectedTeamMembers}
              onChange={(event, value) => {
                handleOnChange('selectedTeamMembers', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Start typing to search and select'
                  error={!!state.errors.selectedTeamMembers}
                  helperText={state.errors.selectedTeamMembers}
                />
              )}
            />
          </Box>
        </Box>

        <Box
          width='70%'
          alignItems='center'
          textAlign='center'
          marginTop={4}
          marginBottom='2rem'
          marginLeft={7}
        >
          <Button
            className={classes.saveBtn}
            variant='contained'
            color='primary'
            endIcon={<NavigateNextIcon />}
            onClick={handleValidations}
          >
            save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default connect(null, {
  fetchTeamMembers,
})(AssignTeam);
