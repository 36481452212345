import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useStyles } from './Styles';

import CustomDatePickerMui from '../../../../common/models/inputs/DateInput';

const FromToDateFilter = (props) => {
  const classes = useStyles();
  const { startDate, endDate, handleDateChange } = props;

  return (
    <React.Fragment>
      <Typography variant='body2' className={classes.hgText}>
        Filter By Batch Start Date:
      </Typography>
      <Grid container style={{ alignItems: 'end' }}>
        <Grid item xs={12} sm={5}>
          <CustomDatePickerMui
            name='FromDate'
            onChange={(date) => handleDateChange('startDate', date)}
            value={startDate}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Typography variant='body2' className={classes.centerText}>
            to
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <CustomDatePickerMui
            name='ToDate'
            onChange={(date) => handleDateChange('endDate', date)}
            value={endDate}
            minDate={startDate === null ? new Date() : startDate}
            readOnly={startDate === null ? true : false}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FromToDateFilter;
