import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { Box, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Autocomplete } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import {
  fetchImpactDetails,
  fetchFrequencyDetails,
} from '../../../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '80%',
    padding: '1rem',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
  },
  headTitle: {
    fontWeight: 700,
  },
  dialogPaper: {
    width: '40%',
    minHeight: '45vh',
    maxHeight: '45vh',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
  },

  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
  },
  title: {
    textAlign: 'center',
    fontWeight: 500,
    marginTop: '2rem',
  },

  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
  },
  inputLabel: {
    marginBottom: '0.5rem',
    fontSize: '85%',
  },
  requiredStar: {
    color: 'red',
  },
  saveBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'lowercase',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const RiskAssesment = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const { openDialog, toggleDialog } = props;
  const [state, setState] = useState({
    errors: {},
  });

  const { impactOptions, selectedImpact, frequencyOptions, selectedFrequency } =
    props.stateData;

  const handleOnChangeData = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: null },
    }));

    props.handleChangeData(name, value);
  };

  useEffect(() => {
    if (didMountRef.current) {
    } else {
      props.fetchImpactDetails();
      props.fetchFrequencyDetails();
      didMountRef.current = true;
    }
  }, []);

  const handleValidations = () => {
    const errorsData = {};
    let isError = false;

    if (selectedImpact === '' || selectedImpact === null) {
      errorsData.selectedImpact = 'Please select impact';
      isError = true;
    }

    if (selectedFrequency === '' || selectedFrequency === null) {
      errorsData.selectedFrequency = 'Please select frequency';
      isError = true;
    }

    if (isError) {
      setState((prevState) => ({
        ...prevState,
        errors: errorsData,
      }));
    } else {
      props.toggleDialog('riskAssesmentScreen');
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => toggleDialog('riskAssesmentScreen')}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='sm'
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.headerContainer}>
        <div
          className={classes.closeBtn}
          onClick={() => toggleDialog('riskAssesmentScreen')}
        >
          <Typography
            variant='caption'
            style={{ lineHeight: 'normal', fontWeight: '700' }}
          >
            Cancel
          </Typography>
          <CloseIcon style={{ color: 'red' }} />
        </div>
      </div>
      <Box width='80%' margin='auto' marginBottom='2rem'>
        <Box>
          <Typography variant='h5' className={classes.title}>
            Risk Assessment
          </Typography>
          <hr className={classes.hrLine} />
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Box marginTop={2} width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Impact <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              options={impactOptions}
              getOptionLabel={(option) => option.impactType || ''}
              getOptionSelected={(option) => option.impactType || ''}
              variant='outlined'
              value={selectedImpact}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Start typing to select Impact'
                  error={!!state.errors.selectedImpact}
                  helperText={state.errors.selectedImpact}
                />
              )}
              onChange={(event, value) => {
                handleOnChangeData('selectedImpact', value);
              }}
            />
          </Box>

          <Box marginTop={2} width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Frequency <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              options={frequencyOptions}
              getOptionLabel={(option) => option.frequencyType || ''}
              getOptionSelected={(option) => option.frequencyType || ''}
              value={selectedFrequency}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Start typing to select Frequency'
                  error={!!state.errors.selectedFrequency}
                  helperText={state.errors.selectedFrequency}
                />
              )}
              onChange={(event, value) => {
                handleOnChangeData('selectedFrequency', value);
              }}
            />
          </Box>
        </Box>

        <Box
          width='70%'
          textAlign='center'
          marginTop={4}
          marginBottom='2rem'
          marginLeft={7}
        >
          <Button
            className={classes.saveBtn}
            variant='contained'
            color='primary'
            endIcon={<NavigateNextIcon />}
            onClick={handleValidations}
          >
            save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default connect(null, {
  fetchImpactDetails,
  fetchFrequencyDetails,
})(RiskAssesment);
