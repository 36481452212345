import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import { presentDate } from './functionalComponents/dataFormatFunctions';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ErrorIcon from '@material-ui/icons/Error';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LongTextDisplay from './models/LongText';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    fontSize: '1.2rem',
    textAlign: 'left',
  },
  accordion: {
    boxShadow: 'none',
    border: '0.1px solid #C6C6C6',
    backgroundColor: '#FAFAFA',
  },
  action: {
    // display:'flex',
    padding: '0.85rem',
  },
  avatar: {
    fontSize: '1rem',
    letterSpacing: '0.2rem',
  },
  oppositeContent: {
    flexGrow: 0,
    flexBasis: 'auto',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const logo = (name) => {
  if (name !== undefined && name !== null) {
    return name
      .split(' ')
      .map((val) => {
        if (val[0] !== undefined) {
          return val[0].toUpperCase();
        }
        return '';
      })
      .join('');
  }
  return '';
};

export default function AccordionModel(props) {
  const classes = useStyles();
  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD/MMM/YYYY';

  const { timeLine, resolution } = props;
  const type = (status) => {
    status = status.toLowerCase();
    if (
      status.includes('sent for approval') ||
      status.includes('grn resolved') ||
      status.includes('qc approved')
    )
      return 'approve';
    if (status.includes('qc resolved')) return 'file update';
    if (
      status.includes('error') ||
      status.includes('reject') ||
      status.includes('repeat')
    )
      return 'error';
    if (status.includes('update')) return 'update';
    if (status.includes('sent for')) return 'pending';
    if (status.includes('grn approved')) return 'success';
    return null;
  };

  const getActionIcon = (actionType) => {
    // let actionType = type(action);
    switch (actionType) {
      case 'approve':
        return (
          <TimelineDot variant='outlined' color='primary'>
            <AssessmentIcon color='primary' />
          </TimelineDot>
        );
      case 'file update':
        return (
          <TimelineDot variant='outlined' color='secondary'>
            <AttachFileIcon color='secondary' />
          </TimelineDot>
        );
      case 'error':
        return (
          <TimelineDot variant='outlined' style={{ borderColor: '#f44336' }}>
            <ErrorIcon color='error' />
          </TimelineDot>
        );

      case 'pending':
        return (
          <TimelineDot variant='outlined' color='primary'>
            <HourglassEmptyIcon color='primary' />
          </TimelineDot>
        );

      //GRN Succ
      case 'success':
        return (
          <TimelineDot variant='outlined' style={{ borderColor: '#008000' }}>
            <CheckBoxIcon style={{ color: '#008000' }} />
          </TimelineDot>
        );
      default: // default case 'update':
        return (
          <TimelineDot variant='outlined' color='secondary'>
            <NoteAddIcon color='secondary' />
          </TimelineDot>
        );
    }
  };

  return (
    <React.Fragment>
      {resolution.length > 0 && (
        <Grid container justify='center' style={{ paddingBottom: '4rem' }}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion className={classes.accordion}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='body1' style={{ fontWeight: 500 }}>
                  Record Resolution(s)
                </Typography>
              </AccordionSummary>

              {resolution.map((record, i) => {
                return (
                  <AccordionDetails key={i}>
                    <Typography variant='body2' className={classes.action}>
                      {record.action}
                    </Typography>
                    <Avatar className={classes.avatar}>
                      {record.name === null
                        ? '--'
                        : logo(record.name).slice(0, 2)}
                    </Avatar>
                    {record.name === null ? (
                      <Typography variant='body2' className={classes.action}>
                        --- &nbsp;&nbsp;{'@ '}
                      </Typography>
                    ) : (
                      <Typography variant='body2' className={classes.action}>
                        {record.name}&nbsp;&nbsp;{'@ '}
                      </Typography>
                    )}

                    <Typography
                      variant='body2'
                      className={classes.action}
                      style={{ paddingLeft: '0' }}
                    >
                      {moment(record.updatedTime).format(
                        `${dateFormat},h:mm:ss a`
                      )}
                    </Typography>
                  </AccordionDetails>
                );
              })}
            </Accordion>
          </Grid>
        </Grid>
      )}

      {timeLine.length > 0 && (
        <Grid container style={{ paddingBottom: '4rem' }}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion className={classes.accordion}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='body1' style={{ fontWeight: 500 }}>
                  Record Timeline
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Timeline align='left'>
                  {timeLine.map((record, i) => {
                    return (
                      <TimelineItem key={i}>
                        <TimelineOppositeContent
                          classes={{
                            root: classes.oppositeContent,
                          }}
                        >
                          <Typography variant='body2' color='textSecondary'>
                            {presentDate(record.lastUpdatedDate, 'NA')}
                          </Typography>
                          <Typography variant='caption' color='textSecondary'>
                            {moment(record.lastUpdatedDate).format('h:mm:ss a')}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          {getActionIcon(type(record.action))}
                          {i !== timeLine.length - 1 && (
                            <TimelineConnector
                              className={classes.secondaryTail}
                            />
                          )}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography variant='body2' component='header'>
                            {record.action}
                          </Typography>
                          <Typography
                            variant='caption'
                            style={{ textTransform: 'capitalize' }}
                          >
                            {record.name}
                          </Typography>
                          {type(record.action) === 'error' &&
                            record.rejectionText && (
                              <LongTextDisplay
                                variant='caption'
                                text={`Reason: ${record.rejectionText}`}
                                limit={108}
                                style={{ display: 'block' }}
                              />
                            )}
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                </Timeline>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
