import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { InputLabel } from '@material-ui/core';

import { fetchEventTypes } from '../../../containers/actions/common/qualityEventActions';
import { useStyles } from './qualityEventStyles';

const EventFilter = (props) => {
  const {
    handleSelectEvent,
    qualityEvent,
    handleSelectEventName,
    toShowAllEvents,
    setQualityEventName,
    newEventScreen,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  let eventTypes = useSelector((state) =>
    state.pcmp.common.QMS.fetchEventTypes
      ? state.pcmp.common.QMS.fetchEventTypes
      : []
  );

  useEffect(() => {
    dispatch(fetchEventTypes());
  }, [dispatch]);

  return (
    <FormControl className={classes.formControl} fullWidth>
      {/*<InputLabel id='demo-simple-select-label'>All Events</InputLabel>*/}
      <Select
        style={{ padding: '0.2rem' }}
        defaultValue={'all'}
        labelId='cmo-filter'
        id='cmo-filter-id'
        value={
          newEventScreen
            ? qualityEvent
            : qualityEvent === 'all'
            ? qualityEvent
            : eventTypes[qualityEvent - 1]
        }
        //onChange={handleSelectEvent,handleSelectEventName}
        name={eventTypes}
        onChange={(data) => {
          handleSelectEvent(data);
        }}
        variant='outlined'
        classes={{
          outlined: classes.outlined,
        }}
      >
        {toShowAllEvents ? (
          <MenuItem value='all' disabled={true}>
            All Events
          </MenuItem>
        ) : (
          <MenuItem value='all'>All Events</MenuItem>
        )}

        {eventTypes.map((qualityEvent, i) => (
          <MenuItem
            key={i}
            value={qualityEvent}
            name={qualityEvent}
            disabled={qualityEvent === null ? true : false}
          >
            {qualityEvent.eventType}
          </MenuItem>
        ))}
      </Select>

      {/* <Select
        value={qualityEvent}
        onChange={handleSelectEvent}
        variant="outlined"
        placeholder="All Events"
        classes={{
          outlined: classes.outlined,
        }}
      >
       

        {eventTypes.map((event, i) => (
           <MenuItem
            value={event}
            key={i}
            >
              {event.eventType}
            </MenuItem>
        ))}
        
      </Select> */}
    </FormControl>
  );
};

export default EventFilter;
