import React, { useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SelectCMOEventTypes from './SelectCMOEventTypes';
import CreateEventStepper from './eventStepper';

const useStyles = makeStyles((theme) => ({
  qualityEventBtn: {
    width: '80%',

    backgroundImage: 'linear-gradient(to right, #301232, #1A2D6C,#173072)',
    color: 'white',
    border: '1px solid black',
    textTransform: 'none',
    borderRadius: '2rem',
    paddingLeft: '4px',
    marginLeft: '1rem',
    '&:hover': {
      backgroundColor: '#173072',
      color: 'white',
      border: '1px solid black',
    },
  },
  addIcon: {
    fontSize: '25px',
    color: 'white',
    marginLeft: '10px',
  },
}));

const CreateAnEvent = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({
    cmoEventTypesScreen: false,
    eventStepperScreen: false,
    CMOMember: '',
    qualityEvent: '',
  });

  const toggleDialog = (dialogName) => {
    if (dialogName === 'eventStepperScreen' && state.eventStepperScreen) {
      setState((prevState) => ({
        ...prevState,
        CMOMember: '',
        qualityEvent: '',
      }));
    }
    setState((prevState) => ({
      ...prevState,
      [dialogName]: !state[dialogName],
    }));
  };

  const handleChangeData = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const openEventStepperScreen = () => {
    setState((prevState) => ({
      ...prevState,
      cmoEventTypesScreen: false,
      eventStepperScreen: true,
    }));
  };

  return (
    <>
      <div style={{ width: '12rem' }}>
        <Button
          startIcon={<AddIcon style={{ fontSize: '25px' }} />}
          className={classes.qualityEventBtn}
          onClick={() => toggleDialog('cmoEventTypesScreen')}
        >
          Create Event
        </Button>
      </div>

      {state.cmoEventTypesScreen && (
        <SelectCMOEventTypes
          openDialog={state.cmoEventTypesScreen}
          toggleDialog={toggleDialog}
          handleChangeData={handleChangeData}
          CMOMember={state.CMOMember}
          qualityEvent={state.qualityEvent}
          openEventStepperScreen={openEventStepperScreen}
        />
      )}

      {state.eventStepperScreen && (
        <CreateEventStepper
          openDialog={state.eventStepperScreen}
          toggleDialog={toggleDialog}
          CMOMember={state.CMOMember}
          qualityEvent={state.qualityEvent}
        />
      )}
    </>
  );
};

export default CreateAnEvent;
