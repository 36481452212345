import React from 'react';
import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    borderBottom: 0,
  },
}));

const NoUnderLineTableCell = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <TableCell
      classes={{
        root: classes.root,
      }}
      align={props.align || 'left'}
    >
      {children}
    </TableCell>
  );
};

export default NoUnderLineTableCell;
