import React from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ResolveSupplierPoRecord from '../../supplierPOResolve';
import ResolveDialog from '../../models/ResolveDialog';

import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import CheckedAndApproved from '../../models/checkedAndApproved';
import Grid from '@material-ui/core/Grid';
import useStyles from './buttonStyles';

const Resolve = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(
    props.row.reasonForRejection === null ||
      typeof props.row.reasonForRejection !== 'string'
      ? ''
      : props.row.reasonForRejection
  );
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickRecieved = () => {
    setOpenDialog(true);
  };
  const handleText = (e) => {
    setValue(e.target.value);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpen(true);
  };
  const handleNoButton = () => {
    setOpenDialog(false);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        disableElevation
        disableFocusRipple
        disableRipple
        className={classes.button}
        onClick={handleClickRecieved}
      >
        <div className={classes.logo}>
          <AutorenewIcon fontSize='small' className={classes.logoHeight} />
          &nbsp;
          <Typography variant='body1'>Resolve</Typography>
        </div>
      </Button>
      <ResolveSupplierPoRecord
        open={open}
        handleClose={handleClose}
        isRecordDelete={false}
        isUpdateRecord={true}
        {...props}
      />

      <Dialog open={openDialog} fullWidth>
        <ResolveDialog
          icons={false}
          handleClose={handleCloseDialog}
          title='Resolve Issue'
          subtitle='Issue Comment'
          hasMultipleButton={true}
          handleNoButton={handleNoButton}
        >
          <form className={classes.root} noValidate autoComplete='off'>
            <TextField
              id='outlined-multiline-static'
              label='Issue Comment'
              multiline
              rows={6}
              value={value}
              onChange={handleText}
              // width='100%'
              variant='outlined'
              style={{
                width: '100%'
              }}
              disabled={true}
            />
            <Grid style={{ display: 'flex', paddingTop: '1rem' }}>
              <Typography
                variant='body2'
                style={{ padding: '0.5rem', fontWeight: 500 }}
              >
                Rejected By
              </Typography>
              <CheckedAndApproved
                name={props.row.rejectedBy ? props.row.rejectedBy : ''}
              />
            </Grid>
          </form>
        </ResolveDialog>
      </Dialog>
    </React.Fragment>
  );
};

export default Resolve; 
