import React from 'react';
import Updatebutton from './updateButton';
import ResolveButton from './ResolveButton';
import ApproveButton from './ApproveButton';

const StatusManager = (
  schrockenDeliveryScheduleID,
  schrockenDeliveryScheduleBatchID,
  SASLBatchStatusID,
  statusDisplayName,
  rejectionText,
  rejectedBy,
  // access control
  featuresAccess,
  batchDashboard,
  qualityApprovalFlowFlag,
  qaStatusID
) => {
  const { collabType } = localStorage;
  // Quality Manager

  if (
    collabType === 'Pharma Co' &&
    qualityApprovalFlowFlag === true &&
    (featuresAccess?.approveQA || featuresAccess?.reverseQA)
  ) {
    switch (String(qaStatusID)) {
      case '1': //Pending
        if (
          featuresAccess?.approveQA &&
          [6, 7, 8, 9].includes(SASLBatchStatusID)
        )
          return (
            <ApproveButton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              qaApproval
            />
          );
        break;
      case '2': // Approved
      case '3': // Rejected
        if (featuresAccess?.reverseQA && SASLBatchStatusID !== 13)
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              qaApproval
            />
          );
        break;
      default:
        return null;
    }
  } else if (collabType === 'Pharma Co') {
    switch (SASLBatchStatusID) {
      case 6: // Awaiting GRN
      case 14: // Awaiting QC/QA Approval
        if (
          (featuresAccess?.approveFGGRN && SASLBatchStatusID === 6) ||
          (SASLBatchStatusID === 14 && featuresAccess?.approveFGQCQA)
        )
          return (
            <ApproveButton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
            />
          );
        break;
      case 7:
      case 8:
      case 18: //BD Generated
        if (featuresAccess?.approveFGGRN)
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
            />
          );
        break;
      default:
        return null;
    }
  } else {
    switch (SASLBatchStatusID) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 7:
      case 18: //BD generated
        if (featuresAccess?.updateFGBatch)
          return (
            <Updatebutton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              batchDashboard={batchDashboard}
              featuresAccess={featuresAccess}
            />
          );
        break;
      case 13: // Resubmit Record
      case 15: // Repeat QC/QA
        if (featuresAccess?.updateFGBatch)
          return (
            <ResolveButton
              schrockenDeliveryScheduleBatchID={
                schrockenDeliveryScheduleBatchID
              }
              schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
              SASLBatchStatusID={SASLBatchStatusID}
              statusDisplayName={statusDisplayName}
              rejectionText={rejectionText}
              rejectedBy={rejectedBy}
              batchDashboard={batchDashboard}
            />
          );
        break;
      default:
        return null;
    }
  }
};

export default StatusManager;
