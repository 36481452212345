/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Neetu
 * -----
 * File Description: CMO ALL Line Items Screen
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';

import BatchItemsTable from '../../../common/tables/BatchItemsTable';
import ViewEditBatch from '../viewEditBatch';

import { viewPOBatchItems } from '../../../../containers/actions/common/dashboardAction';

class index extends Component {
  state = {
    poBatchItems: false,
    batchItem: {},
    openBatchDialog: false,
  };

  componentDidMount() {
    this.getPOBatches();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.poBatchItems !== this.props.poBatchItems) {
      this.setState({ poBatchItems: nextProps.poBatchItems });
    }
  }

  getPOBatches = () => {
    let data = {
      ...this.props.match.params,
      collabType: 'CMO',
    };
    this.props.viewPOBatchItems(data);
    this.setState({ poBatchItems: false });
  };

  // Batch dialog methods
  openBatchDialog = (batch) => {
    this.setState({ openBatchDialog: true, batchItem: batch });
  };

  toggleViewBatchScreen = () => {
    this.setState({ openBatchDialog: false });
  };

  render() {
    const { poBatchItems } = this.state;
    return (
      <Grid>
        <div
          style={{
            // margin: '-1rem -1.3rem 0rem -1.8rem',
            margin: '-16px -16px 0 -16px',
            backgroundColor: '#F5F5F5',
            flexDirection: 'row',
            padding: '1.2rem 0.5rem',
          }}
        >
          <Typography variant='h6' style={{ marginLeft: '3rem' }}>
            Total PO Batches - {!poBatchItems ? 0 : poBatchItems.length}
          </Typography>
        </div>
        <BatchItemsTable
          batchItems={this.state.poBatchItems}
          openBatchDialog={this.openBatchDialog}
          refreshList={this.getPOBatches}
        />
        <Dialog fullScreen open={this.state.openBatchDialog}>
          <ViewEditBatch
            toggleViewBatchScreen={this.toggleViewBatchScreen}
            params={this.props.match.params}
            batchItemDetails={this.state.batchItem}
            collabType='CMO'
            callBackAPIFunction={this.getPOBatches}
          />
        </Dialog>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  poBatchItems: state.pcmp.common.dashboard.poBatchItems,
});

export default connect(mapStateToProps, {
  viewPOBatchItems,
})(withRouter(index));
