import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const DsBatchNumber = (props) => {
  const { handleChange, auditTrailObj } = props;
  return (
    <Grid item xs={12} sm={6} md={2}>
      <TextField
        name='vendorBatchNumber'
        id='standard-basic'
        variant='outlined'
        size='small'
        placeholder={'Enter Batch Number'}
        value={auditTrailObj.vendorBatchNumber}
        fullWidth
        onChange={(e) => handleChange(e)}
      />
    </Grid>
  );
};

export default DsBatchNumber;
