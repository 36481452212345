import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const styles = (theme) => ({
  root: {
    width: "100%",
    // marginTop: "1rem",
    overflowX: "auto",
  },
  stickyHeader: {
    backgroundColor: "inherit",
  },
  table: {
    minWidth: 700,
  },
  container: {
    maxHeight: 440,
  },
  inputRoot: {
    color: "inherit",
  },
});

const StyledCell = withStyles((theme) => ({
  root: {
    fontSize: "0.8rem",
    borderLeft: "1px solid #e0e0e0",
  },
  head: {
    lineHeight: "1rem",
  },
}))(TableCell);

class CMOLineTable extends Component {
  handleClick = (statusCode) => {
    const { ecosystemId, organizationName, roleName } = this.props.match.params;
    const { startCommitDate, endCommitDate } = this.props;
    if (statusCode !== undefined) {
      this.props.history.push(
        `/pcmp/Pharma Co/${roleName}/${organizationName}/${ecosystemId}/executivedashboard/lineItems?startCommitDate=${startCommitDate}&endCommitDate=${endCommitDate}&statusCode=${statusCode}&api=lineItemStatusDetails`
      );
    } else {
      this.props.history.push(
        `/pcmp/Pharma Co/${roleName}/${organizationName}/${ecosystemId}/executivedashboard/lineItems?startCommitDate=${startCommitDate}&endCommitDate=${endCommitDate}&api=overviewCardTotalLineItemDetails`
      );
    }
  };
  render() {
    const { classes, data, refreshTable } = this.props;
    return (
      <Paper className={classes.root}>
        <Toolbar style={{ backgroundColor: "#F5F5F5" }}>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={8}>
              <Typography variant="subtitle1">
                CMO's Order Information - All Line Items
              </Typography>
            </Grid>
          </Grid>
          <Tooltip title="Refresh List">
            <IconButton aria-label="Refresh list" onClick={refreshTable}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Divider />
        <TableContainer className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2} className={classes.stickyHeader}>
                  CMO
                </TableCell>
                <StyledCell rowSpan={2} className={classes.stickyHeader}>
                  Total Line Items
                </StyledCell>
                <StyledCell colSpan={3} className={classes.stickyHeader}>
                  Pre-Production
                </StyledCell>
                <StyledCell colSpan={2} className={classes.stickyHeader}>
                  Production
                </StyledCell>
                <StyledCell colSpan={5} className={classes.stickyHeader}>
                  Post-Production
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell className={classes.stickyHeader}>
                  <Typography variant="body2">Not Acknowledged</Typography>
                </StyledCell>
                <StyledCell className={classes.stickyHeader}>
                  <Typography variant="body2">RM/PM Available</Typography>
                </StyledCell>
                <StyledCell className={classes.stickyHeader}>
                  <Typography variant="body2">RM/PM Not Available</Typography>
                </StyledCell>
                <StyledCell className={classes.stickyHeader}>
                  <Typography variant="body2">Under Production</Typography>
                </StyledCell>
                <StyledCell className={classes.stickyHeader}>
                  <Typography variant="body2">Production Completed</Typography>
                </StyledCell>
                <StyledCell className={classes.stickyHeader}>
                  <Typography variant="body2">Work In Progress</Typography>
                </StyledCell>
                <StyledCell className={classes.stickyHeader}>
                  <Typography variant="body2">QC Completed</Typography>
                </StyledCell>
                <StyledCell className={classes.stickyHeader}>
                  <Typography variant="body2">Ready for Dispatch</Typography>
                </StyledCell>
                <StyledCell className={classes.stickyHeader}>
                  <Typography variant="body2">Dispatched </Typography>
                </StyledCell>
                <StyledCell className={classes.stickyHeader}>
                  <Typography variant="body2">Goods Recieved</Typography>
                </StyledCell>
              </TableRow>
            </TableHead>
            {!data ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={14} style={{ textAlign: "center" }}>
                    Loading...
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : data === "empty" ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={14} style={{ textAlign: "center" }}>
                    No data found.
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="center">
                      {Object.values(row)[0].cmoMember}
                    </TableCell>

                    <StyledCell align="right">
                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{
                          textDecoration:
                            parseInt(
                              Object.values(row)[0]["Total Line Items"]
                            ) > 0
                              ? "underline"
                              : "none",
                          cursor:
                            parseInt(
                              Object.values(row)[0]["Total Line Items"]
                            ) > 0
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          parseInt(Object.values(row)[0]["Total Line Items"]) >
                          0
                            ? () => this.handleClick()
                            : null
                        }
                      >
                        {Object.values(row)[0]["Total Line Items"]}
                      </Typography>
                    </StyledCell>

                    <StyledCell
                      align="right"
                      style={{
                        backgroundColor: Object.values(row)[1]["Created/Open"]
                          ? "#f3d5da"
                          : "none",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{
                          textDecoration:
                            parseInt(Object.values(row)[0]["Created/Open"]) > 0
                              ? "underline"
                              : "none",
                          cursor:
                            parseInt(Object.values(row)[0]["Created/Open"]) > 0
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          parseInt(Object.values(row)[0]["Created/Open"]) > 0
                            ? (statusCode) => this.handleClick(0)
                            : null
                        }
                      >
                        {Object.values(row)[0]["Created/Open"]
                          ? Object.values(row)[0]["Created/Open"]
                          : 0}
                      </Typography>
                    </StyledCell>

                    <StyledCell
                      align="right"
                      style={{
                        backgroundColor: Object.values(row)[1][
                          "RM/PM Available"
                        ]
                          ? "#f3d5da"
                          : "none",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{
                          textDecoration:
                            parseInt(Object.values(row)[0]["RM/PM Available"]) >
                            0
                              ? "underline"
                              : "none",
                          cursor:
                            parseInt(Object.values(row)[0]["RM/PM Available"]) >
                            0
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          parseInt(Object.values(row)[0]["RM/PM Available"]) > 0
                            ? (statusCode) =>
                                this.handleClick(
                                  parseInt(
                                    Object.values(row)[0][
                                      "RM/PM Available Code"
                                    ]
                                  )
                                )
                            : null
                        }
                      >
                        {Object.values(row)[0]["RM/PM Available"]
                          ? Object.values(row)[0]["RM/PM Available"]
                          : 0}
                      </Typography>
                    </StyledCell>

                    <StyledCell
                      align="right"
                      style={{
                        backgroundColor: Object.values(row)[1][
                          "RM/PM Not Available"
                        ]
                          ? "#f3d5da"
                          : "none",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{
                          textDecoration:
                            parseInt(
                              Object.values(row)[0]["RM/PM Not Available"]
                            ) > 0
                              ? "underline"
                              : "none",
                          cursor:
                            parseInt(
                              Object.values(row)[0]["RM/PM Not Available"]
                            ) > 0
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          parseInt(
                            Object.values(row)[0]["RM/PM Not Available"]
                          ) > 0
                            ? (statusCode) =>
                                this.handleClick(
                                  parseInt(
                                    Object.values(row)[0][
                                      "RM/PM Not Available Code"
                                    ]
                                  )
                                )
                            : null
                        }
                      >
                        {Object.values(row)[0]["RM/PM Not Available"]
                          ? Object.values(row)[0]["RM/PM Not Available"]
                          : 0}
                      </Typography>
                    </StyledCell>

                    <StyledCell
                      align="right"
                      style={{
                        backgroundColor: Object.values(row)[1][
                          "Under Production"
                        ]
                          ? "#f3d5da"
                          : "none",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{
                          textDecoration:
                            parseInt(
                              Object.values(row)[0]["Under Production"]
                            ) > 0
                              ? "underline"
                              : "none",
                          cursor:
                            parseInt(
                              Object.values(row)[0]["Under Production"]
                            ) > 0
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          parseInt(Object.values(row)[0]["Under Production"]) >
                          0
                            ? (statusCode) =>
                                this.handleClick(
                                  parseInt(
                                    Object.values(row)[0][
                                      "Under Production Code"
                                    ]
                                  )
                                )
                            : null
                        }
                      >
                        {Object.values(row)[0]["Under Production"]
                          ? Object.values(row)[0]["Under Production"]
                          : 0}
                      </Typography>
                    </StyledCell>

                    <StyledCell
                      align="right"
                      style={{
                        backgroundColor: Object.values(row)[1][
                          "Production Complete"
                        ]
                          ? "#f3d5da"
                          : "none",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{
                          textDecoration:
                            parseInt(
                              Object.values(row)[0]["Production Complete"]
                            ) > 0
                              ? "underline"
                              : "none",
                          cursor:
                            parseInt(
                              Object.values(row)[0]["Production Complete"]
                            ) > 0
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          parseInt(
                            Object.values(row)[0]["Production Complete"]
                          ) > 0
                            ? (statusCode) =>
                                this.handleClick(
                                  parseInt(
                                    Object.values(row)[0][
                                      "Production Complete Code"
                                    ]
                                  )
                                )
                            : null
                        }
                      >
                        {Object.values(row)[0]["Production Complete"]
                          ? Object.values(row)[0]["Production Complete"]
                          : 0}
                      </Typography>
                    </StyledCell>

                    <StyledCell
                      align="right"
                      style={{
                        backgroundColor: Object.values(row)[1][
                          "Work in Progress"
                        ]
                          ? "#f3d5da"
                          : "none",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{
                          textDecoration:
                            parseInt(
                              Object.values(row)[0]["Work in Progress"]
                            ) > 0
                              ? "underline"
                              : "none",
                          cursor:
                            parseInt(
                              Object.values(row)[0]["Work in Progress"]
                            ) > 0
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          parseInt(Object.values(row)[0]["Work in Progress"]) >
                          0
                            ? (statusCode) =>
                                this.handleClick(
                                  parseInt(
                                    Object.values(row)[0][
                                      "Work in Progress Code"
                                    ]
                                  )
                                )
                            : null
                        }
                      >
                        {Object.values(row)[0]["Work in Progress"]
                          ? Object.values(row)[0]["Work in Progress"]
                          : 0}
                      </Typography>
                    </StyledCell>

                    <StyledCell
                      align="right"
                      style={{
                        backgroundColor: Object.values(row)[1]["QC Released"]
                          ? "#f3d5da"
                          : "none",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{
                          textDecoration:
                            parseInt(Object.values(row)[0]["QC Released"]) > 0
                              ? "underline"
                              : "none",
                          cursor:
                            parseInt(Object.values(row)[0]["QC Released"]) > 0
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          parseInt(Object.values(row)[0]["QC Released"]) > 0
                            ? (statusCode) =>
                                this.handleClick(
                                  parseInt(
                                    Object.values(row)[0]["QC Released Code"]
                                  )
                                )
                            : null
                        }
                      >
                        {Object.values(row)[0]["QC Released"]
                          ? Object.values(row)[0]["QC Released"]
                          : 0}
                      </Typography>
                    </StyledCell>

                    <StyledCell
                      align="right"
                      style={{
                        backgroundColor: Object.values(row)[1][
                          "Ready for Dispatch"
                        ]
                          ? "#f3d5da"
                          : "none",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{
                          textDecoration:
                            parseInt(
                              Object.values(row)[0]["Ready for Dispatch"]
                            ) > 0
                              ? "underline"
                              : "none",
                          cursor:
                            parseInt(
                              Object.values(row)[0]["Ready for Dispatch"]
                            ) > 0
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          parseInt(
                            Object.values(row)[0]["Ready for Dispatch"]
                          ) > 0
                            ? (statusCode) =>
                                this.handleClick(
                                  parseInt(
                                    Object.values(row)[0][
                                      "Ready for Dispatch Code"
                                    ]
                                  )
                                )
                            : null
                        }
                      >
                        {Object.values(row)[0]["Ready for Dispatch"]
                          ? Object.values(row)[0]["Ready for Dispatch"]
                          : 0}
                      </Typography>
                    </StyledCell>

                    <StyledCell
                      align="right"
                      style={{
                        backgroundColor: Object.values(row)[1]["Dispatch"]
                          ? "#f3d5da"
                          : "none",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{
                          textDecoration:
                            parseInt(Object.values(row)[0]["Dispatch"]) > 0
                              ? "underline"
                              : "none",
                          cursor:
                            parseInt(Object.values(row)[0]["Dispatch"]) > 0
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          parseInt(Object.values(row)[0]["Dispatch"]) > 0
                            ? (statusCode) =>
                                this.handleClick(
                                  parseInt(
                                    Object.values(row)[0]["Dispatch Code"]
                                  )
                                )
                            : null
                        }
                      >
                        {Object.values(row)[0]["Dispatch"]
                          ? Object.values(row)[0]["Dispatch"]
                          : 0}
                      </Typography>
                    </StyledCell>

                    <StyledCell
                      align="right"
                      style={{
                        backgroundColor: Object.values(row)[1]["Goods Received"]
                          ? "#f3d5da"
                          : "none",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{
                          textDecoration:
                            parseInt(Object.values(row)[0]["Goods Received"]) >
                            0
                              ? "underline"
                              : "none",
                          cursor:
                            parseInt(Object.values(row)[0]["Goods Received"]) >
                            0
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          parseInt(Object.values(row)[0]["Goods Received"]) > 0
                            ? (statusCode) =>
                                this.handleClick(
                                  parseInt(
                                    Object.values(row)[0]["Goods Received Code"]
                                  )
                                )
                            : null
                        }
                      >
                        {Object.values(row)[0]["Goods Received"]
                          ? Object.values(row)[0]["Goods Received"]
                          : 0}
                      </Typography>
                    </StyledCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}

export default withRouter(withStyles(styles)(CMOLineTable));
