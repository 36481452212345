import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../../../../common/models/Button';
import ShowFiles from './ShowFiles';
import Dialog from '@material-ui/core/Dialog';
import DuplicateFilePrompt from './DuplicateFilePrompt';
import moment from 'moment';
import InputNumber from '../models/inputs/InputNumber';
import CustomDatePickerMui from '../models/inputs/DateInput';
import DropFilesAttach from '../models/files/DropFilesAttach';

const useStyles = makeStyles((theme) => ({
  font: {
    fontWeight: 500,
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },

  QA: {
    textAlign: 'left',
    fontWeight: 500,
  },
  padding: {
    paddingBottom: '1rem',
  },

  radio: {
    paddingLeft: '2rem',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingTop: '2rem',
    },
  },

  empty: {
    paddingTop: '3rem',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  mandatory: {
    color: 'red',
  },
}));

const EditQualityAssurance = (props) => {
  const classes = useStyles();
  //to restrict wheel scroll on Number type textField
  const textFieldRef = React.useRef(null);

  React.useEffect(() => {
    const handleWheel = (e) => e.preventDefault();
    let WheelScrollRestrict = textFieldRef.current;
    WheelScrollRestrict.addEventListener('wheel', handleWheel);

    return () => {
      WheelScrollRestrict.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const { qualityAssuranceInfo, status } = props;
  const {
    handleChange,
    handleDateChange,
    handleQA,
    isAddRecord,

    parentHandlingDeleteFile,
    qualityTempNumber,
    //mindate
    POLineRecordDetails,
  } = props;

  const [samplingQuantityQA, setQuantityQA] = useState(
    qualityAssuranceInfo.samplingQuantityQA
  );
  const [isQACompleted, setQADONE] = useState(
    qualityAssuranceInfo.isQACompleted
  );
  const [analyticalReportNumber, setAnalyticalReportNumber] = useState(
    qualityAssuranceInfo.analyticalReportNumber
  );
  const [analyticalReportDate, setAnalyticalReportDate] = useState(
    qualityAssuranceInfo.analyticalReportDate
  );
  const [materialManufactureDate, setManufacturingDate] = useState(
    qualityAssuranceInfo.materialManufactureDate
  );
  const [expirationDate, setExpirationDate] = useState(
    qualityAssuranceInfo.expirationDate
  );
  const [batchNumberQA, setBatchNumberQA] = useState(
    qualityAssuranceInfo.batchNumberQA
  );
  const [samplingMaterialNumberQA, seteSamplingMaterialNumberQA] = useState(
    qualityAssuranceInfo.samplingMaterialNumberQA
  );
  const [COAFiles, setCOAFILES] = useState(qualityAssuranceInfo.COAFiles);

  const QAValidations = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.validations.QAInfo;
    }
    return {};
  });

  const handleChangeQuantityQA = (e) => {
    if (e.target.value < 0) {
      setQuantityQA('');
      alert('Invalid QA Sampling Quantity');
    } else {
      setQuantityQA(parseFloat(e.target.value));
    }
  };

  const handleChangeQADone = () => {
    setQADONE(!isQACompleted);
    setAnalyticalReportNumber(qualityAssuranceInfo.analyticalReportNumber);
    setAnalyticalReportDate(qualityAssuranceInfo.analyticalReportDate);
    setManufacturingDate(qualityAssuranceInfo.materialManufactureDate);
    setExpirationDate(qualityAssuranceInfo.expirationDate);
    setBatchNumberQA(qualityAssuranceInfo.batchNumberQA);
    seteSamplingMaterialNumberQA(qualityAssuranceInfo.samplingMaterialNumberQA);
    setCOAFILES(qualityAssuranceInfo.COAFiles);
  };

  const handleChangeARNumber = (e) => {
    setAnalyticalReportNumber(e.target.value);
  };

  const handleChangeARDate = (date) => {
    setAnalyticalReportDate(date != null ? date.toISOString() : date);
  };

  const handleChangeManufacturingDate = (date) => {
    setManufacturingDate(date != null ? date.toISOString() : date);

    if (
      date !== null &&
      expirationDate === null &&
      POLineRecordDetails.materialType === 'VERP' &&
      QAValidations.PM?.autoAdd5YearsToExpiry.required
    ) {
      setExpirationDate(moment(date).add(60, 'months').toISOString());
    }
  };

  const handleChangeExpirationDate = (date) => {
    setExpirationDate(date != null ? date.toISOString() : date);
  };

  const handleChangeBatchNumber = (e) => {
    setBatchNumberQA(e.target.value);
  };

  const handleChangeSamplingMaterial = (e) => {
    seteSamplingMaterialNumberQA(e.target.value);
  };

  //file append
  const [tempNumber, setTempNumber] = React.useState(qualityTempNumber);
  const [localTempNumber, setLocalTempNumber] =
    React.useState(qualityTempNumber);

  const [dupFilePrompt, setDupFilePrompt] = React.useState(false);

  const handleSaveQuality = () => {
    let data = {
      ...qualityAssuranceInfo,
      // unitOfMeasurementQA,
      samplingQuantityQA: samplingQuantityQA
        ? parseFloat(samplingQuantityQA)
        : 0,
      isQACompleted,
      analyticalReportNumber,
      analyticalReportDate,
      materialManufactureDate,
      expirationDate,
      batchNumberQA,
      samplingMaterialNumberQA,
      COAFiles,
    };
    props.handleSaveQualityAssuranceData(data, localTempNumber);
  };

  const filePrompt = () => {
    setDupFilePrompt(true);
  };

  const handleFileCopy = () => {
    setDupFilePrompt(false);
  };

  const handleClose = () => {
    setDupFilePrompt(false);
  };

  const localFileHandling = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        let newFileName = file.name;

        let counter = COAFiles.map((file) => file.grFileName).indexOf(
          file.name
        );

        if (counter > -1) {
          filePrompt();
          // counter > -1 means the name is already taken
          // We split with . to deal with the extension
          newFileName = `${file.name.split('.')[0]}_${localTempNumber + 1}.${
            file.name.split('.')[1]
          }`;
          setLocalTempNumber(localTempNumber + 1);
        }
        // Do whatever you want with the file contents
        const fileData = {
          grFileData: reader.result,
          grFileName: newFileName,
          grFileType: 'buffer',
          schrockenGRFileId: '',
          grFileHash: '',
          markForDelete: false, //0,
        };
        setCOAFILES((prevFiles) => [...prevFiles, fileData]);
      };
    });
  };

  const fileHandling = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        let newFileName = file.name;

        let counter = qualityAssuranceInfo.COAFiles.map(
          (file) => file.grFileName
        ).indexOf(file.name);

        if (counter > -1) {
          filePrompt();
          // counter > -1 means the name is already taken
          // We split with . to deal with the extension
          newFileName = `${file.name.split('.')[0]}_${tempNumber + 1}.${
            file.name.split('.')[1]
          }`;

          props.handleTempNumber('qualityTempNumber');
          setTempNumber(tempNumber + 1);
        }
        // Do whatever you want with the file contents
        const newArr = {
          grFileData: reader.result,
          grFileName: newFileName,
          grFileType: 'buffer',
          schrockenGRFileId: '',
          grFileHash: '',
          markForDelete: false, // 0
        };

        props.handlingFiles(newArr, 'COAFiles');
      };
    });
  };

  const handlingDeleteFile = (i) => {
    // for rec fromBE=> mfd as 1, for local filter out
    //check for filetype in file
    //if 'buffer' then follow filter logic
    // if it is not buffer edit that file data sayn mfd 1
    if (
      window.confirm(
        'You are about to delete a file ,would you like to proceed ?'
      )
    ) {
      if (COAFiles[i].grFileType === 'buffer') {
        let newdel = COAFiles.filter(
          (file) => file.grFileName !== COAFiles[i].grFileName
        );
        setCOAFILES(newdel);
      } else {
        // COAFiles[i].markForDelete=1;
        let markDelFile = COAFiles.map((file) =>
          file.grFileHash === COAFiles[i].grFileHash
            ? { ...file, markForDelete: true }
            : file
        );
        setCOAFILES(markDelFile);
      }
    }
  };
  const getMinExpDate = () => {
    if (isAddRecord && materialManufactureDate) {
      return materialManufactureDate;
    } else if (qualityAssuranceInfo.materialManufactureDate) {
      return qualityAssuranceInfo.materialManufactureDate;
    } else {
      return undefined;
    }
  };
  return (
    <React.Fragment>
      <Grid container justify='center' spacing={8}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Unit of Measurement (UOM)
          </Typography>

          <InputNumber
            type='text'
            className={classes.borders}
            placeholder='Unit of Measurement (UOM)'
            name='unitOfMeasureSKU'
            value={POLineRecordDetails.unitOfMeasureSKU}
            disabled={true}
            notFullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Quantity used for QA Sampling{' '}
            <span className={classes.mandatory}>*</span>
          </Typography>
          <InputNumber
            placeholder='QA Sample Quantity'
            name='samplingQuantityQA'
            value={
              isAddRecord !== true
                ? qualityAssuranceInfo.samplingQuantityQA
                : samplingQuantityQA
            }
            onChange={
              isAddRecord !== true
                ? (e, name) => handleChange(e, 'samplingQuantityQA')
                : (e) => handleChangeQuantityQA(e)
            }
            ref={textFieldRef}
            disabled={status === 3 ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} style={{ paddingBottom: 0 }}>
          <Typography variant='h6' className={classes.QA}>
            Is QA Done ? <span className={classes.mandatory}>*</span>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <RadioGroup
            row
            // value={isQACompleted}
            value={
              isAddRecord !== true
                ? qualityAssuranceInfo.isQACompleted
                : isQACompleted
            }
            onChange={isAddRecord !== true ? handleQA : handleChangeQADone}
          >
            <Grid item xs={12} sm={6} md={6}>
              <FormControlLabel
                value={false}
                control={<Radio color='primary' />}
                label='No, QA is not Done'
                disabled={status === 3 ? true : false}
              />
              <FormHelperText>
                (Material has been Sent for Testing)
              </FormHelperText>
            </Grid>

            <Grid item xs={12} sm={6} md={6} className={classes.radio}>
              <FormControlLabel
                value={true}
                name='QADone'
                control={<Radio color='primary' />}
                label='Yes, QA is Done'
                disabled={status === 3 ? true : false}
              />
              <FormHelperText>
                (QA Done and I have AR Number and CoA Documents)
              </FormHelperText>
            </Grid>
          </RadioGroup>
        </Grid>

        {((isAddRecord !== true &&
          qualityAssuranceInfo.isQACompleted === true) ||
          (isAddRecord === true && isQACompleted === true)) && (
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='body2' className={classes.font}>
              Enter Analytical Report (AR) Number{' '}
              <span className={classes.mandatory}>*</span>
            </Typography>
            <InputNumber
              className={classes.borders}
              type='text'
              placeholder='AER23232'
              name='analyticalReportNumber'
              value={
                isAddRecord !== true
                  ? qualityAssuranceInfo.analyticalReportNumber
                  : analyticalReportNumber
              }
              onChange={
                isAddRecord !== true
                  ? (e) => handleChange(e, 'analyticalReportNumber')
                  : (e) => handleChangeARNumber(e)
              }
              disabled={status === 3 ? true : false}
            />
          </Grid>
        )}

        {((isAddRecord !== true &&
          qualityAssuranceInfo.isQACompleted === true) ||
          (isAddRecord === true && isQACompleted === true)) && (
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='body2' className={classes.font}>
              Select the AR Date
            </Typography>

            <CustomDatePickerMui
              name='analyticalReportDate'
              value={
                isAddRecord !== true
                  ? qualityAssuranceInfo.analyticalReportDate
                  : analyticalReportDate
              }
              onChange={
                isAddRecord !== true
                  ? (date) => handleDateChange(date, 'analyticalReportDate')
                  : (date) => handleChangeARDate(date)
              }
              minDate={POLineRecordDetails.supplierPODate}
              disableFuture
              disabled={status === 3 ? true : false}
            />
          </Grid>
        )}

        {((isAddRecord === true && isQACompleted === true) ||
          (isAddRecord !== true &&
            qualityAssuranceInfo.isQACompleted === true)) && (
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='body2' className={classes.font}>
              QC Sampling Material Number (optional)
            </Typography>
            <InputNumber
              name='samplingMaterialNumberQA'
              type='text'
              placeholder='QC Sampling Material Number'
              value={
                isAddRecord !== true
                  ? qualityAssuranceInfo.samplingMaterialNumberQA
                  : samplingMaterialNumberQA
              }
              onChange={
                isAddRecord !== true
                  ? (e, name) => handleChange(e, 'samplingMaterialNumberQA')
                  : (e) => handleChangeSamplingMaterial(e)
              }
              className={classes.borders}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Batch Number{' '}
            {POLineRecordDetails.materialType === 'ROH' && (
              <span className={classes.mandatory}>*</span>
            )}
          </Typography>
          <InputNumber
            name='batchNumberQA'
            type='text'
            value={
              isAddRecord !== true
                ? qualityAssuranceInfo.batchNumberQA
                : batchNumberQA
            }
            onChange={
              isAddRecord !== true
                ? (e) => handleChange(e, 'batchNumberQA')
                : (e) => handleChangeBatchNumber(e)
            }
            className={classes.borders}
            variant='outlined'
            placeholder='Batch Number'
            disabled={status === 3 ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Date of Manufacturing
            {QAValidations[
              POLineRecordDetails.materialType === 'ROH' ? 'RM' : 'PM'
            ]?.mfgDate.required ? (
              <span className={classes.mandatory}>*</span>
            ) : (
              <span> (optional)</span>
            )}
          </Typography>
          <CustomDatePickerMui
            name='materialManufactureDate'
            value={
              isAddRecord !== true
                ? qualityAssuranceInfo.materialManufactureDate
                : materialManufactureDate
            }
            onChange={
              isAddRecord !== true
                ? (date) => handleDateChange(date, 'materialManufactureDate')
                : (date) => handleChangeManufacturingDate(date)
            }
            disabled={status === 3 ? true : false}
            disableFuture={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Date of Expiration
            {QAValidations[
              POLineRecordDetails.materialType === 'ROH' ? 'RM' : 'PM'
            ]?.expDate.required ? (
              <span className={classes.mandatory}>*</span>
            ) : (
              <span> (optional)</span>
            )}
          </Typography>

          <CustomDatePickerMui
            name='expirationDate'
            // value={expirationDate}
            value={
              isAddRecord !== true
                ? qualityAssuranceInfo.expirationDate
                : expirationDate
            }
            onChange={
              isAddRecord !== true
                ? (date) => handleDateChange(date, 'expirationDate')
                : (date) => handleChangeExpirationDate(date)
            }
            disabled={status === 3 ? true : false}
            minDate={getMinExpDate()}
            minDateMessage='Expiry Date should be after Manufacturing Date.'
          />
        </Grid>

        {isAddRecord !== true &&
        qualityAssuranceInfo.isQACompleted === false ? (
          <Grid item xs={12} sm={6} md={6} display={{ xs: 'none' }}></Grid>
        ) : (
          <Grid item xs={12} sm={6} md={6} display={{ xs: 'none' }}></Grid>
        )}
      </Grid>

      {qualityAssuranceInfo.isQACompleted === false ||
      isQACompleted === false ? (
        <div className={classes.empty}></div>
      ) : null}

      <Grid container justify='center'>
        <Grid item xs={12} sm={12} md={12}>
          <DropFilesAttach
            title='Upload Certificates of Analysis (CoA)'
            required={QAValidations?.coaFile.required}
            handleUploadFiles={
              isAddRecord !== true ? fileHandling : localFileHandling
            }
          />
        </Grid>
      </Grid>

      <Grid container className={classes.padding}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Uploaded Document List
          </Typography>

          <ShowFiles
            name='COAFiles'
            files={isAddRecord ? COAFiles : qualityAssuranceInfo.COAFiles}
            handlingDeleteFile={
              isAddRecord ? handlingDeleteFile : parentHandlingDeleteFile
            }
            isUploadFile={true}
            filesRequired={QAValidations.coaFile?.required}
          />
        </Grid>
      </Grid>

      <Grid container justify='center' className={classes.padding}>
        {isAddRecord && (
          <Grid item xs={12} sm={8} md={8} style={{ textAlign: 'center' }}>
            <StyledButton
              onClick={handleSaveQuality}
              style={{ padding: '0.25rem 2rem' }}
            >
              Save
            </StyledButton>
          </Grid>
        )}
      </Grid>
      <Dialog open={dupFilePrompt}>
        <DuplicateFilePrompt
          handleYesButton={handleFileCopy}
          handleClose={handleClose}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default EditQualityAssurance;
