import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Moment from 'moment';
import orderBy from 'lodash/orderBy';

import {
  Grid,
  InputBase,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  Typography,
  TablePagination,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { withStyles } from '@material-ui/core/styles';
import { tableFilter } from '../functionalComponents/TableFilter';

const tableDataFilter = tableFilter;

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: '2rem',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  POOrderBackground: {
    margin: '-3rem -1.3rem 0rem -1.8rem',
    backgroundColor: '#F5F5F5',
    flexDirection: 'row',
    padding: '1.2rem 0.5rem',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  link: {
    '&:focus': {
      color: '#0000EE',
    },
    '&:hover': {
      color: '#0000EE',
    },
    '&:visited': {
      color: '#0000EE',
    },
    '&:link': {
      color: '#0000EE',
    },
    '&:active': {
      color: '#0000EE',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
});

const header = [
  { name: 'PO ', id: 'purchaseOrderNumber', align: 'left' },
  { name: 'PO Description', id: 'purchaseOrderDescription', align: 'left' },
  { name: 'Creation Date', id: 'creationDate', align: 'right' },
  { name: 'Last Updated', id: 'lastUpdatedDate', align: 'right' },
  { name: 'Status', id: 'statusText', align: 'left' },
  { name: 'Line Items', id: 'lineItemsCount', align: 'right' },
  { name: 'Batches', id: 'batchItemsCount', align: 'right' },
];

class PurchaseOrdersTable extends Component {
  state = {
    page: 0,
    data: [],
    header,
    purchaseOrders: [],
    rowsPerPage: 5,
    displayFilter: false,
    query: '',
    columnToQuery: 'all',
    columnToSort: '',
    fieldSelected: false,
    sortDirection: 'desc',
  };

  openPO = (poNumber) => {
    const { params } = this.props.match;
    this.props.history.push(
      `/pcmp/${this.props.collabType}/${params.roleName}/${params.organizationName}/${params.ecosystemId}/purchaseorderdetails/${poNumber}`
    );
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  refreshTableData = () => {
    this.props.callBackAPIFunction();
  };

  render() {
    const { classes, purchaseOrders } = this.props;
    const { params } = this.props.match;
    const { page, rowsPerPage } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    const noRecords = (
      <TableRow>
        <TableCell colSpan={header.length} align='center'>
          No Records Found
        </TableCell>
      </TableRow>
    );

    const emptyRows = !purchaseOrders
      ? 0
      : rowsPerPage -
        Math.min(rowsPerPage, purchaseOrders.length - page * rowsPerPage);
    const queryData = orderBy(
      this.state.query
        ? purchaseOrders.filter((data) =>
            tableDataFilter(
              data,
              this.state.columnToQuery,
              this.state.query.toLowerCase(),
              header
            )
          )
        : purchaseOrders,
      this.state.columnToSort,
      this.state.sortDirection
    );
    return (
      <Paper className={classes.root}>
        <Toolbar>
          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid item xs={8}>
              <Typography variant='subtitle1'>Purchase Orders List</Typography>
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SearchIcon />
              <InputBase
                placeholder='Search...'
                value={this.state.query}
                name='query'
                onChange={this.handleChange}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </Grid>
          </Grid>

          <Tooltip title='Refresh Table'>
            <IconButton
              aria-label='Refresh Table'
              onClick={this.refreshTableData}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Table className={classes.table}>
          <TableHead style={{ backgroundColor: '#F5F5F5' }}>
            <TableRow>
              {header.map((headCell, i) => (
                <TableCell key={i} align={headCell.align}>
                  {headCell.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!purchaseOrders ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={14} style={{ textAlign: 'center' }}>
                  Loading...
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {queryData.length > 0
                ? queryData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((po, index) => (
                      <TableRow key={index} className={classes.tableRowHover}>
                        <TableCell align='left'>
                          {po.purchaseOrderNumber}
                        </TableCell>
                        <TableCell align='left'>
                          {po.purchaseOrderDescription}
                        </TableCell>
                        <TableCell align='right'>
                          {Moment(new Date(po.creationDate)).format(dateFormat)}
                        </TableCell>
                        <TableCell align='right'>
                          {Moment(new Date(po.lastUpdatedDate)).format(
                            dateFormat
                          )}
                        </TableCell>
                        <TableCell align='left'>{po.statusText}</TableCell>
                        <TableCell align='right'>
                          {parseInt(po.lineItemsCount) === 0 ? (
                            <span style={{ color: '#2196f3' }}>
                              {po.lineItemsCount}
                            </span>
                          ) : (
                            <Link
                              style={{ color: '#2196f3' }}
                              className={classes.link}
                              to={`/pcmp/${this.props.collabType}/${params.roleName}/${params.organizationName}/${params.ecosystemId}/purchaseorderdetails/${po.purchaseOrderNumber}`}
                            >
                              {po.lineItemsCount}
                            </Link>
                          )}
                        </TableCell>
                        <TableCell align='right'>
                          {parseInt(po.batchItemsCount) === 0 ? (
                            <span style={{ color: '#2196f3' }}>
                              {po.batchItemsCount}
                            </span>
                          ) : (
                            <Link
                              style={{ color: '#2196f3' }}
                              className={classes.link}
                              to={`/pcmp/${this.props.collabType}/${params.roleName}/${params.organizationName}/${params.ecosystemId}/${po.purchaseOrderNumber}/pobatchitems`}
                            >
                              {po.batchItemsCount}
                            </Link>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                : noRecords}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component='div'
          count={!purchaseOrders ? 0 : purchaseOrders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}
PurchaseOrdersTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(PurchaseOrdersTable));
