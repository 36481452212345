import React from 'react';
import PropTypes from 'prop-types';
import { Typography, TableRow } from '@material-ui/core';
import NoUnderLineTableCell from '../models/table/NoUnderLineTableCell';

const ViewContainerDetails = ({ container }) => {
  return (
    <TableRow>
      <NoUnderLineTableCell>
        <Typography variant='body2'>
          {container.containerSerialNumber}
        </Typography>
      </NoUnderLineTableCell>
      <NoUnderLineTableCell>
        <Typography variant='body2'>{container.numberOfContainers}</Typography>
      </NoUnderLineTableCell>
      <NoUnderLineTableCell>
        <Typography variant='body2'>
          {container.quantityPerContainer}
        </Typography>
      </NoUnderLineTableCell>
    </TableRow>
  );
};

ViewContainerDetails.propTypes = {
  container: PropTypes.object.isRequired,
};

export default ViewContainerDetails;
