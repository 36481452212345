import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
//files
import Header from '../../../../common/sasl/models/Header';

import ViewEditBatchItem from '../updateBatch/ViewEditBatch';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const UpdateBatch = (props) => {
  const { open, handleClose } = props;
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open} //change to props
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Grid
          container
          // justify='center'
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header
              title='Batch Details'
              isButtonsExist={false}
              handleClose={handleClose}
            >
              <ViewEditBatchItem {...props} />
            </Header>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};
export default UpdateBatch;
