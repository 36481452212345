import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './Styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const DisplayCard = (props) => {
  const classes = useStyles();
  const {
    primaryText,
    secondaryText,
    loading,
    selected,
    handleClick,
    tertiaryText,
  } = props;

  return (
    <Grid item xs={12} sm={6} md={2} className={classes.G1}>
      <Card
        className={selected ? classes.cardSelected : classes.cardStyle}
        onClick={handleClick}
      >
        <CardContent className={classes.cardContent}>
          {loading ? (
            <Grid container className={classes.G2}>
              <CircularProgress color='primary' thickness={7} />
            </Grid>
          ) : (
            <Grid container className={classes.G2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{ alignItems: 'flex-end' }}
                className={classes.G3}
              >
                <Typography className={classes.count}>{primaryText}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.G3}>
                <Typography className={classes.title}>
                  {secondaryText}
                  {tertiaryText && (
                    <React.Fragment>
                      <br /> {tertiaryText}
                    </React.Fragment>
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DisplayCard;
