import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import POItemTable from '../../common/models/POItemTable';
 
import ReceivedQuantity from '../models/ReceivedQuantity';
import QualityAssurance from '../models/QualityAssuranceInfo';
import ChallanInfo from '../models/ChallanInfo';
import InvoiceInfo from '../models/InvoiceInfo';
import OtherInfo from '../models/OtherInfo';

// import Header from '../../common/models/Header';
import NewHeader from '../../common/models/NewHeader';
import Dialog from '@material-ui/core/Dialog';
import Wrapper from '../wrappers/FullWidthContent';
import EditTable from '../updateGrRecord/EditTable';
import EditReceived from '../updateGrRecord/RecievedQuantity';
import EditQuality from '../updateGrRecord/QualityAssurance';
import EditChallan from '../updateGrRecord/ChallanInfo';
import EditInvoice from '../updateGrRecord/InvoiceInfo';
import EditOtherInfo from '../updateGrRecord/OtherInfo';
import EditRecord from './RecordDetails';

const useStyles = makeStyles({
  main: {
    fontWeight: 500,
    fontSize: '1.5rem',
    textAlign: 'center',
    paddingBottom: '0.3rem',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5rem',
    textAlign: 'left',
    paddingBottom: '0.3rem',
  },
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '1.6rem',
  },
  grid: {
    paddingBottom: '2rem',
    paddingTop: '2rem',
  },
});

const ReviewRecord = (props) => {
  const classes = useStyles();
  const errorRef = useRef();
  const {
    openReceived,
    handleCloseReceived,
    openQuality,
    openChallan,

    openInvoice,

    openOtherInfo,

    openPOItem,

    resError,

    //new props
    POLineRecordDetails,
    POBatchRecordDetails,
    handleOpenDialog,
    handleCloseSectionDialog,
    handleSavePOItemData,
    errors,
    receivedQuantityInfo,
    handleSaveReceivedQuantityData,
    //quantity
    qualityAssuranceInfo,
    handleSaveQualityAssuranceData,
    //challan
    challanInformation,
    handleSaveChallanInformationData,
    //invoice
    invoiceInformation,
    handleSaveInvoiceInformationData,
    //other infor
    handleSaveOtherInformationData,
    //quantityYetToReceive
    reviewRecord,
    //appending file
    OtherInfoTempNumber,
    handleTempNumber,
    challanTempNumber,
    invoiceTempNumber,
    qualityTempNumber
  } = props;

  if (resError /*&& errorRef* /* + other conditions */) {
    errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // window.scrollTo(0, errorRef.current.offsetTop);
  }

  return (
    <React.Fragment>
      <Grid container justify='center'>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className={classes.grid}
          ref={errorRef}
        >
          <Typography variant='h4' className={classes.main}>
            Review &amp; Submit Record
          </Typography>
        </Grid>
      </Grid>

      {/* ERROR FIELD to show the errors */}
      <Grid container justify='center'>
        {resError && (
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant='h6'
              style={{
                border: '1px solid #FF0000',
                backgroundColor: '#FFF0F0',
                opacity: '1',
                textAlign: 'center',
                padding: '0.8rem',
                fontSize: '0.875rem',
              }}
            >
              {resError}
            </Typography>
          </Grid>
        )}
      </Grid>

      <POItemTable
        POLineRecordDetails={POLineRecordDetails}
        POBatchRecordDetails={POBatchRecordDetails}
        handleOpenDialog={handleOpenDialog}
        isRecievedExits={true}
        errors={errors}
      />
      <Dialog
        fullScreen
        open={openPOItem}
        onClose={() => handleCloseSectionDialog('openPOItem')}
      >
        <NewHeader
          handleClose={() => handleCloseSectionDialog('openPOItem')}
          title='Edit/Update Additional Information'
          subtitle='Raw Material (RM) / Packaging Material (PM) Record'
          padding={true}
        />
        <Wrapper>
          <EditTable
            title='You are Editing the Following Record'
            POLineRecordDetails={POLineRecordDetails}
          />
          <EditRecord
            POBatchRecordDetails={POBatchRecordDetails}
            isAddRecord
            handleSavePOItemData={handleSavePOItemData}
            POLineRecordDetails={POLineRecordDetails}
          />
        </Wrapper>
      </Dialog>
      <hr />

      <ReceivedQuantity
        receivedQuantityInfo={receivedQuantityInfo}
        isEditExist={true}
        handleOpenDialog={handleOpenDialog}
        errors={errors}
        POLineRecordDetails={POLineRecordDetails}
        reviewRecord={reviewRecord}
        status={false}
      />
      <Dialog
        fullScreen
        open={openReceived}
        onClose={() => handleCloseSectionDialog('openReceived')}
      >
        <NewHeader
          handleClose={() => handleCloseSectionDialog('openReceived')}
          title='Edit/Update Received Quantity Information'
          subtitle='Raw Material (RM) / Packaging Material (PM) Record'
          padding={true}
        />
        <Wrapper>
          <EditTable
            title='You are Editing the Following Record'
            POLineRecordDetails={POLineRecordDetails}
          />
          <EditReceived
            receivedQuantityInfo={receivedQuantityInfo}
            isAddRecord
            handleCloseReceived={handleCloseReceived}
            handleSaveReceivedQuantityData={handleSaveReceivedQuantityData}
            POLineRecordDetails={POLineRecordDetails}
          />
        </Wrapper>
      </Dialog>

      <hr />

      <QualityAssurance
        qualityAssuranceInfo={qualityAssuranceInfo}
        isEditExist
        errors={errors}
        handleOpenDialog={handleOpenDialog}
        handlingDeleteFile={props.handlingDeleteFile}
        POLineRecordDetails={POLineRecordDetails}
      />
      <Dialog
        fullScreen
        open={openQuality}
        onClose={() => handleCloseSectionDialog('openQuality')}
      >
        <NewHeader
          handleClose={() => handleCloseSectionDialog('openQuality')}
          title='Edit/Update Quality Assurance Information'
          subtitle='Raw Material (RM) / Packaging Material (PM) Record'
          padding={true}
        />
        <Wrapper>
          <EditTable
            title='You are Editing the Following Record'
            POLineRecordDetails={POLineRecordDetails}
          />
          <EditQuality
            qualityAssuranceInfo={qualityAssuranceInfo}
            isAddRecord
            handleSaveQualityAssuranceData={handleSaveQualityAssuranceData}
            //appending file
            handleTempNumber={handleTempNumber}
            qualityTempNumber={qualityTempNumber}
            POLineRecordDetails={POLineRecordDetails}
          />
        </Wrapper>
      </Dialog>

      <hr />

      <ChallanInfo
        challanInformation={challanInformation}
        isEditExist
        handleOpenDialog={handleOpenDialog}
        errors={errors}
      />
      <Dialog
        fullScreen
        open={openChallan}
        onClose={() => handleCloseSectionDialog('openChallan')}
      >
        <NewHeader
          handleClose={() => handleCloseSectionDialog('openChallan')}
          title='Edit/Update Challan Information'
          subtitle='Raw Material (RM) / Packaging Material (PM) Record'
          padding={true}
        />
        <Wrapper>
          <EditTable
            title='You are Editing the Following Record'
            POLineRecordDetails={POLineRecordDetails}
          />
          <EditChallan
            challanInformation={challanInformation}
            isAddRecord
            handleSaveChallanInformationData={handleSaveChallanInformationData}
            //appending file
            handleTempNumber={handleTempNumber}
            challanTempNumber={challanTempNumber}
          />
        </Wrapper>
      </Dialog>

      <hr />

      <InvoiceInfo
        invoiceInformation={invoiceInformation}
        isEditExist
        handleOpenDialog={handleOpenDialog}
        errors={errors}
      />
      <Dialog
        fullScreen
        open={openInvoice}
        onClose={() => handleCloseSectionDialog('openInvoice')}
      >
        <NewHeader
          handleClose={() => handleCloseSectionDialog('openInvoice')}
          title='Edit/Update Invoice Information'
          subtitle='Raw Material (RM) / Packaging Material (PM) Record'
          padding={true}
        />
        <Wrapper>
          <EditTable
            title='You are Editing the Following Record'
            POLineRecordDetails={POLineRecordDetails}
          />
          <EditInvoice
            invoiceInformation={invoiceInformation}
            isAddRecord
            handleSaveInvoiceInformationData={handleSaveInvoiceInformationData}
            //appending file
            handleTempNumber={handleTempNumber}
            invoiceTempNumber={invoiceTempNumber}
          />
        </Wrapper>
      </Dialog>

      <hr />

      <OtherInfo
        POBatchRecordDetails={POBatchRecordDetails}
        isEditExist
        handleOpenDialog={handleOpenDialog}
        errors={errors}
      />
      <Dialog
        fullScreen
        open={openOtherInfo}
        onClose={() => handleCloseSectionDialog('openOtherInfo')}
      >
        <NewHeader
          handleClose={() => handleCloseSectionDialog('openOtherInfo')}
          title='Edit/Update Additional Information'
          subtitle='Raw Material (RM) / Packaging Material (PM) Record'
          padding={true}
        />
        <Wrapper>
          <EditTable
            title='You are Editing the Following Record'
            POLineRecordDetails={POLineRecordDetails}
          />
          <EditOtherInfo
            POBatchRecordDetails={POBatchRecordDetails}
            isAddRecord
            handleSaveOtherInformationData={handleSaveOtherInformationData}
            //appending file
            OtherInfoTempNumber={OtherInfoTempNumber}
            handleTempNumber={handleTempNumber}
          />
        </Wrapper>
      </Dialog>
    </React.Fragment>
  );
};

export default ReviewRecord;
