import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  text: {
    fontWeight: 500,
    marginTop: '0.5rem',
  },
  helperTextFontSize: {
    fontSize: '0.75rem',
  },
});

const SelectCollabType = (props) => {
  const classes = useStyles();

  const {
    collaboratorTypes,
    collaboratorTypeId,
    handleChangeCollaborator,
    errors,
  } = props;

  const selectRoles = (roles) => {
    return collaboratorTypes.map((role, index) => (
      <MenuItem key={index} value={role.collaboratorTypeId}>
        {role.collaboratorTypeName}
      </MenuItem>
    ));
  };
  return (
    <Grid container style={{ paddingTop: '2rem' }}>
      <Grid item xs={12}>
        <TextField
          select
          fullWidth
          variant='outlined'
          size='small'
          name='collaboratorTypeId'
          label='Collaborator Type'
          error={errors.collaboratorTypeName ? true : false}
          value={collaboratorTypeId}
          onChange={handleChangeCollaborator}
          helperText={
            <Typography
              variant='caption'
              className={classes.helperTextFontSize}
            >
              Select Collaborator Type
            </Typography>
          }
        >
          {selectRoles(collaboratorTypes)}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default SelectCollabType;
