import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'none',
    fontSize: 11,
    border: '1px solid #000000de',
    borderRadius: 0,
  },
  arrow: {
    '&::before': {
      border: '1px solid #000000de',
      backgroundColor: '#ffffff',
      boxSizing: 'border-box',
    },
  },
}))(Tooltip);

export default LightTooltip;
