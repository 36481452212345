import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import Moment from 'moment';

import SelectionBar from './SelectionBar';
import ReportsTableWithPagination from '../../../tables/ReportsTableWithPagination';
import {
  viewBatchAuditReport,
  downloadReport,
  fetchPOLineBatches,
  fetchPOLines,
} from '../../../../../../pcmp/containers/actions/common/reportsActions';

const header = [
  {
    label: ' PO No',
    id: 'purchaseOrderNumber',
    align: 'left',
    span: 0.2,
  },
  {
    label: 'Line No',
    id: 'purchaseOrderLineNumber',
    align: 'left',
    span: 0.2,
  },
  {
    label: 'Batch No',
    id: 'batchNumber',
    align: 'left',
    span: 0.2,
  },
  {
    label: 'Date/Time',
    id: 'eventDateTime',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'Event by User',
    id: 'userOfEvent',
    align: 'right',
    span: 0.1,
  },
  {
    label: ' Event Type',
    id: 'eventType',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'Batch Status',
    id: 'batchStatus',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'Batch Size',
    id: 'batchSize',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'Start Date',
    id: 'startDate',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'End Date',
    id: 'endDate',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'QC Date',
    id: 'QCDate',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'Shipping Date',
    id: 'shippingDate',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'Produced Quantity',
    id: 'producedQuantity',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'Pending Quantity',
    id: 'pendingQuantity',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'Duration',
    id: 'duration',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'UOM',
    id: 'UOM',
    align: 'right',
    span: 0.1,
  },
];
class BatchAuditReport extends Component {
  state = {
    header: header,
    data: [],
    lineNumbers: [],
    batchNumbers: [],
    purchaseOrderNumber: '',
    purchaseOrderLineNumber: '',
    batchNumber: '',
    CMOMember: '',
    requestDownload: false,
    reportNumber: 4,
    rowsPerPage: 5,
    page: 0,
    open: false,
    batchSelectOpen: false,
    showReport: false,
    reportError: null,
    errors: {
      poError: false,
      lineError: false,
      batchError: false,
    },
  };

  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    if (
      nextProps.batchAuditReport !== this.props.batchAuditReport &&
      nextProps.batchAuditReport.status === true
    ) {
      this.setState({
        data: nextProps.batchAuditReport.reportData,
        requestedBy: nextProps.batchAuditReport.requestedBy,
        showReport: true,
      });
    }
    if (
      nextProps.batchAuditReport !== this.props.batchAuditReport &&
      nextProps.batchAuditReport.status === false
    ) {
      this.setState({
        reportError: nextProps.batchAuditReport.errorDescription,
        showReport: true,
      });
    }
    if (nextProps.lineNumbers !== this.props.lineNumbers) {
      this.setState({ lineNumbers: nextProps.lineNumbers });
    }
    if (nextProps.batchNumbers !== this.props.batchNumbers) {
      this.setState({ batchNumbers: nextProps.batchNumbers });
    }
  }

  componentDidUpdate(nextProps, prevState) {
    if (nextProps.poNumberError !== this.props.poNumberError) {
      this.setState((state) => ({
        ...state,
        data: [],
        lineNumbers: [],
        batchNumbers: [],
        showReport: false,
        errors: {
          ...state.errors,
          poError: true,
        },
      }));
      this.handleClose();
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'purchaseOrderNumber') {
      this.setState({ purchaseOrderLineNumber: '', batchNumber: '' });
    }
    this.setState({
      [name]: value,
      errors: {
        poError: false,
        lineError: false,
        batchError: false,
      },
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
    let data = {
      purchaseOrderNumber: this.state.purchaseOrderNumber,
      collabType: this.props.collabType,
      ...this.props.match.params,
    };
    this.props.fetchPOLines(data);
    this.setState({ batchNumber: '', batchNumbers: [] });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleBatchSelectOpen = () => {
    this.setState({ batchSelectOpen: true });
    let data = {
      purchaseOrderNumber: this.state.purchaseOrderNumber,
      schrockenPOLineID: this.state.purchaseOrderLineNumber,
      collabType: this.props.collabType,
      ...this.props.match.params,
    };
    this.props.fetchPOLineBatches(data);
  };

  handleBatchSelectClose = () => {
    this.setState({ batchSelectOpen: false });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  validate = () => {
    let error = false;
    let errors = {
      poError: false,
      lineError: false,
      batchError: false,
    };
    const {
      purchaseOrderNumber,
      purchaseOrderLineNumber,
      batchNumber,
    } = this.state;
    if (purchaseOrderNumber === '') {
      error = true;
      errors.poError = true;
    } else if (purchaseOrderLineNumber === '') {
      error = true;
      errors.lineError = true;
    } else if (batchNumber === '') {
      error = true;
      errors.batchError = true;
    }
    this.setState({ errors });
    return error;
  };

  fetchReport = () => {
    let err = this.validate();
    if (!err) {
      const {
        reportNumber,
        purchaseOrderNumber,
        purchaseOrderLineNumber,
        batchNumber,
      } = this.state;
      let data = {
        reportNumber,
        purchaseOrderNumber,
        schrockenPOLineID: purchaseOrderLineNumber,
        schrockenBatchID: batchNumber,
        collabType: this.props.collabType,
        CMOMember: this.props.match.params.organizationName,
        ...this.props.match.params,
      };

      this.props.viewBatchAuditReport(data);
      this.setState({ showReport: false });
    }
  };
  downloadReport = () => {
    let err = this.validate();
    if (!err) {
      const {
        reportNumber,
        purchaseOrderNumber,
        purchaseOrderLineNumber,
        batchNumber,
      } = this.state;
      let data = {
        reportNumber,
        purchaseOrderNumber,
        schrockenPOLineID: purchaseOrderLineNumber,
        schrockenBatchID: batchNumber,
        CMOMember: this.props.match.params.organizationName,
        collabType: this.props.collabType,
        ...this.props.match.params,
      };
      this.props.downloadReport(data);
    }
  };

  render() {
    const {
      header,
      data,
      page,
      rowsPerPage,
      open,
      batchSelectOpen,
      purchaseOrderNumber,
      purchaseOrderLineNumber,
      batchNumber,
      lineNumbers,
      batchNumbers,
      showReport,
      requestedBy,
      errors,
      reportError,
    } = this.state;

    // const emptyRows = rowsPerPage -Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    const tableBody =
      data.length > 0 ? (
        data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, i) => (
            <TableRow key={i}>
              <TableCell align='left'>{row.purchaseOrderNumber}</TableCell>
              <TableCell align='right'>{row.lineNumber}</TableCell>
              <TableCell align='right'>{row.batchNumber}</TableCell>
              <TableCell align='right'>
                {Moment(row.lastUpdatedDate).format(`${dateFormat} HH:mm:ss`)}
              </TableCell>
              <TableCell align='right'>{row.lastUpdatedBy}</TableCell>
              <TableCell align='left'>{row.eventType}</TableCell>
              <TableCell align='left'>{row.statusText}</TableCell>
              <TableCell align='right'>{row.batchSize}</TableCell>
              <TableCell align='right'>
                {Moment(row.batchStartDate).format(dateFormat)}
              </TableCell>
              <TableCell align='right'>
                {Moment(row.batchEndDate).format(dateFormat)}
              </TableCell>
              <TableCell align='right'>
                {row.QCDate !== '' && row.QCDate !== null
                  ? Moment(row.QCDate).format(dateFormat)
                  : null}
              </TableCell>
              <TableCell align='right'>
                {row.shipmentDate !== '' && row.shipmentDate !== null
                  ? Moment(row.shipmentDate).format(dateFormat)
                  : null}
              </TableCell>
              <TableCell align='right'>{row.producedQuantity}</TableCell>
              <TableCell align='right'>{row.pendingQuantity}</TableCell>
              <TableCell align='right'>{row.batchDuration}</TableCell>
              <TableCell align='right'>{row.unitOfMeasurement}</TableCell>
            </TableRow>
          ))
      ) : (
        <TableRow>
          <TableCell colSpan={header.length} align='center'>
            {reportError ? (
              <Typography variant='body2' color='error'>
                {reportError}
              </Typography>
            ) : (
              <Typography variant='body2'>No Records Found</Typography>
            )}
          </TableCell>
        </TableRow>
      );

    return (
      <div>
        <SelectionBar
          fetchReport={this.fetchReport}
          purchaseOrderNumber={purchaseOrderNumber}
          purchaseOrderLineNumber={purchaseOrderLineNumber}
          batchNumber={batchNumber}
          open={open}
          batchSelectOpen={batchSelectOpen}
          handleChange={this.handleChange}
          handleOpen={this.handleOpen}
          handleClose={this.handleClose}
          handleBatchSelectOpen={this.handleBatchSelectOpen}
          handleBatchSelectClose={this.handleBatchSelectClose}
          lineNumbers={lineNumbers}
          batchNumbers={batchNumbers}
          lineSelectDisabled={purchaseOrderNumber === '' ? true : false}
          batchSelectDisabled={purchaseOrderLineNumber === '' ? true : false}
          poError={errors.poError}
          lineError={errors.lineError}
          batchError={errors.batchError}
        />
        {showReport === true && (
          <ReportsTableWithPagination
            header={header}
            tableBody={tableBody}
            data={data}
            downloadReport={this.downloadReport}
            length={data.length}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            requestedBy={requestedBy}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  batchAuditReport: state.pcmp.common.reports.batchAuditReport,
  lineNumbers: state.pcmp.common.reports.lineNumbers,
  batchNumbers: state.pcmp.common.reports.batchNumbers,
  poNumberError: state.pcmp.common.reports.poNumberError,
});
export default connect(mapStateToProps, {
  viewBatchAuditReport,
  downloadReport,
  fetchPOLineBatches,
  fetchPOLines,
})(BatchAuditReport);
