import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import {
  presentDate,
  presentFormatedData,
} from '../functionalComponents/dataFormatFunctions';

const useStyles = makeStyles({
  child: {
    paddingTop: '1rem',
  },
  font: {
    fontWeight: 500,
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
  },
  fontPadding: {
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
    overflow: 'auto',
  },
  button: {
    backgroundColor: 'transparent',
    textAlign: 'end',
  },
  headfont: {
    fontWeight: 500,
    // padding: '0rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
    fontSize: '1.25rem',
  },
  headfontPadding: {
    // padding: '0rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
    fontSize: '0.9rem',
  },
  grid: {
    paddingBottom: '2rem',
    paddingRight: '1.5rem',
  },
});

export default function QualityAssurance(props) {
  const classes = useStyles();
  const { generalInfo, general } = props;
  const { minShelfLife, shelfLifeUnitIndicator } = props.generalInfo;

  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD/MMM/YYYY';

  let grnFeatures = useSelector((state) => {
    return state.pcmp.common.accessControl.featuresAccess.grnFeatures;
  });

  const scheduleDateFlag = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.features
        .scheduledDeliveryDate;
    }
    return false;
  });
  const lorryReceiptDateEnabled = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.features
        .lorryReceiptDateEnabled;
    }
    return false;
  });

  return (
    <React.Fragment>
      <Grid container>
        {grnFeatures.viewGrnNumber === true && (
          <Grid item xs={12} sm={3} md={3} className={classes.grid}>
            <Typography variant='body2' className={classes.headfont}>
              GRN Number
            </Typography>
            <Typography variant='body2' className={classes.headfontPadding}>
              {presentFormatedData(general.numberGRN, 'GRN not yet generated')}
            </Typography>
          </Grid>
        )}

        {grnFeatures.viewQaNumber === true && (
          <Grid item xs={12} sm={3} md={3} className={classes.grid}>
            <Typography variant='body2' className={classes.headfont}>
              QC Number
            </Typography>
            <Typography variant='body2' className={classes.headfontPadding}>
              {presentFormatedData(general.numberQC, 'Not yet generated')}
            </Typography>
          </Grid>
        )}

        {grnFeatures.viewCcNumber === true && (
          <Grid item xs={12} sm={3} md={3} className={classes.grid}>
            <Typography variant='body2' className={classes.headfont}>
              Cost Center Number
            </Typography>
            <Typography variant='body2' className={classes.headfontPadding}>
              {presentFormatedData(
                general.costCenterNumber,
                'Not yet generated'
              )}
            </Typography>
          </Grid>
        )}

        {
          <Grid item xs={12} sm={3} md={3} className={classes.grid}>
            <Typography variant='body2' className={classes.headfont}>
              Delivery Challan Number
            </Typography>
            <Typography variant='body2' className={classes.headfontPadding}>
              {presentFormatedData(
                general.deliveryChallanNumber,
                'Not yet generated'
              )}
            </Typography>
          </Grid>
        }
      </Grid>
      <hr />
      <Grid container className={classes.child}>
        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            PO/STO No.
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {generalInfo.supplierPurchaseOrderNumber}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            PO Date
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {moment(generalInfo.supplierPODate).format(dateFormat)}
          </Typography>
        </Grid>
        {scheduleDateFlag && (
          <Grid item xs={12} sm={6} md={3} className={classes.grid}>
            <Typography variant='body2' className={classes.font}>
              Scheduled Delivery Date
            </Typography>
            <Typography variant='body2' className={classes.fontPadding}>
              {presentDate(generalInfo.scheduledDeliveryDate, '-Not Provided-')}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Material Type
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {generalInfo.materialType === 'ROH'
              ? 'RM'
              : generalInfo.materialType === 'VERP'
              ? 'PM'
              : generalInfo.materialType}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Material Name/Description
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {generalInfo.materialName}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Code
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {generalInfo.materialCode}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            UOM
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {generalInfo.unitOfMeasurementOrdered}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Quantity
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {generalInfo.quantityOrdered}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Delivery Tolerance
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {generalInfo.overDeliveryTolerance}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Shelf Life
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {minShelfLife
              ? `${presentFormatedData(
                  minShelfLife
                )}${' '}${presentFormatedData(shelfLifeUnitIndicator)}`
              : '-Not Provided-'}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            CMO
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {presentFormatedData(generalInfo.cmoMember, '--')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Supplier Name
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {generalInfo.supplierName}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Supplier Address
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {generalInfo.supplierAddress}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Bill to &amp; Ship to (Final destination) Address
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {generalInfo.vendorAddress}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            MRN Number
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {general.numberMRN === null || general.numberMRN === ''
              ? '-Not Provided-'
              : general.numberMRN}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Date material was received
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {presentDate(general.materialReceivedDate, '-Not Provided-')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Transporter Name(optional)
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {presentFormatedData(general.transporterName, '-Not Provided-')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Lorry Receipt (LR) Number(optional)
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {presentFormatedData(general.lorryReceiptNumber, '-Not Provided-')}
          </Typography>
        </Grid>
        {lorryReceiptDateEnabled && (
          <Grid item xs={12} sm={6} md={3} className={classes.grid}>
            <Typography variant='body2' className={classes.font}>
              Lorry Receipt (LR) Date
            </Typography>
            <Typography variant='body2' className={classes.fontPadding}>
              {presentDate(general.lorryReceiptDate, '-Not Provided-')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
