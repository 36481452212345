import {
  FETCH_SASL_TABLE,
  VIEW_SL_DETAILS,
  FETCH_BOM_DETAILS,
  FETCH_BATCH_DETAILS,
  FETCH_BOMCONSUMPTION_INFO,
  FETCH_DELIVERY_SCHEDULE_BATCH_DETAILS,
  SASL_BATCH_UPDATE,
  UPDATE_BOM_CONSUMPTION_DETAILS,
  SASL_BATCH_APPROVE,
  NEXT_STATUS_LIST,
  SEND_SASL_BATCH_APPROVAL,
  DS_HEADER,
  UPLOAD_SASL_BATCHDATAFROMFILE,
  UPDATE_SASL_BATCHDATAFROMFILE,
  UPLOAD_SASL_CONSUMPTIONINFOFROMFILE,
  UPDATE_SASL_CONSUMPTIONINFOFROMFILE,
  REJECT_SASL_BATCH_APPROVAL,
  ALL_DS_STATUS,
  SASL_BATCH_AUDIT_REPORT,
  SA_AUDIT_REPORT,
  SL_AUDIT_REPORT,
  FETCH_SL_LINES,
  FETCH_DS,
  SASL_DS_AUDIT_REPORT,
  OPEN_TABLE_LOADING,
  CLOSE_TABLE_LOADING,
  FETCH_THRESHOLD_DETAILS,
  FETCH_DELIVERY_SCHEDULE_BATCHES,
  FETCH_BATCH_PRODUCTS,
  QUICK_SUMMARY_STATUSES,
  BATCH_UPDATED,
  UPDATE_RMPM_AVAILABILITY,
  UPLOAD_RMPM_AVAILABILITY,
  IS_RMPM_DATA_AVAILABLE,
  UPDATE_RMPM,
  EDIT_PM_TABLE_DATA,
  EDIT_RM_TABLE_DATA,
  PM_TABLE_DATA,
  RM_TABLE_DATA,
  MASTER_PM,
  MASTER_RM,
  INITIAL_PM,
  INITIAL_RM,
  ALL_QA_STATUS,
  QA_APPROVAL,
} from '../../actions/types';
const initialState = {
  fetchSASLData: {},
  batchDetails: {},
  allDSStatus: [],
  auditReport: {},
  fetchSLLines: [],
  fetchAllBatches: [],
  quickSummaryStatus: [],
  fetchBatchProducts: [],
  rmTableData: [],
  pmTableData: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SASL_TABLE:
      return {
        ...state,
        fetchSASLData: action.payload,
      };
    case VIEW_SL_DETAILS:
      return {
        ...state,
        slDetails: action.payload,
      };
    case FETCH_BOM_DETAILS:
      return {
        ...state,
        viewBOM: action.payload,
      };
    case FETCH_BATCH_DETAILS:
      return {
        ...state,
        batchDetails: action.payload,
      };
    case FETCH_BOMCONSUMPTION_INFO:
      return {
        ...state,
        consumptionInfo: action.payload,
      };
    case FETCH_DELIVERY_SCHEDULE_BATCH_DETAILS:
      return {
        ...state,
        DsDetails: action.payload,
      };
    case SASL_BATCH_UPDATE:
      return {
        ...state,
        updateBatch: action.payload,
      };
    case SEND_SASL_BATCH_APPROVAL:
      return {
        ...state,
        sendApproval: action.payload,
      };
    case REJECT_SASL_BATCH_APPROVAL:
      return {
        ...state,
        rejectApproval: action.payload,
      };
    case UPLOAD_SASL_BATCHDATAFROMFILE:
      return {
        ...state,
        uploadDSBatch: action.payload,
      };
    case UPDATE_SASL_BATCHDATAFROMFILE:
      return {
        ...state,
        updateDSBatch: action.payload,
      };
    case UPLOAD_SASL_CONSUMPTIONINFOFROMFILE:
      return {
        ...state,
        uploadSASLConsumption: action.payload,
      };
    case UPDATE_SASL_CONSUMPTIONINFOFROMFILE:
      return {
        ...state,
        updateSASLConsumption: action.payload,
      };
    case UPDATE_BOM_CONSUMPTION_DETAILS:
      return {
        ...state,
        updateBOM: action.payload,
      };
    case SASL_BATCH_APPROVE:
      return {
        ...state,
        approveBatch: action.payload,
      };
    case NEXT_STATUS_LIST:
      return {
        ...state,
        nextStatus: action.payload,
      };
    case DS_HEADER:
      return {
        ...state,
        deliveryHeader: action.payload,
      };
    case ALL_DS_STATUS:
      return {
        ...state,
        allDSStatus: action.payload,
      };
    case ALL_QA_STATUS:
      return {
        ...state,
        allQAStatus: action.payload,
      };
    case SASL_BATCH_AUDIT_REPORT:
      return {
        ...state,
        auditReport: action.payload,
      };
    case SA_AUDIT_REPORT:
      return {
        ...state,
        saAuditReport: action.payload,
      };
    case SL_AUDIT_REPORT:
      return {
        ...state,
        slAuditReport: action.payload,
      };
    case FETCH_SL_LINES:
      return {
        ...state,
        fetchSLLines: action.payload,
      };
    case FETCH_DS:
      return {
        ...state,
        fetchDS: action.payload,
      };
    case SASL_DS_AUDIT_REPORT:
      return {
        ...state,
        dsAudit: action.payload,
      };
    case OPEN_TABLE_LOADING:
      return {
        ...state,
        mainTableLoading: true,
      };
    case CLOSE_TABLE_LOADING:
      return {
        ...state,
        mainTableLoading: false,
      };

    case FETCH_THRESHOLD_DETAILS:
      return {
        ...state,
        fetchVarianceThreshold: action.payload,
      };

    case FETCH_DELIVERY_SCHEDULE_BATCHES:
      return {
        ...state,
        fetchAllBatches: action.payload,
      };

    case QUICK_SUMMARY_STATUSES:
      return {
        ...state,
        quickSummaryStatus: action.payload,
      };
    case FETCH_BATCH_PRODUCTS:
      return {
        ...state,
        fetchBatchProducts: action.payload,
      };
    case BATCH_UPDATED:
      return {
        ...state,
        batchUpdated: action.payload,
      };
    case UPLOAD_RMPM_AVAILABILITY:
      return {
        ...state,
        uploadRMPM: action.payload,
      };
    case UPDATE_RMPM_AVAILABILITY:
      return {
        ...state,
        updateRMPM: action.payload,
      };
    case IS_RMPM_DATA_AVAILABLE:
      return {
        ...state,
        rmpmAvailable: action.payload,
      };
    case INITIAL_RM:
      return {
        ...state,
        initialRawMaterials: action.payload,
      };
    case INITIAL_PM:
      return {
        ...state,
        initialPackagingMaterials: action.payload,
      };
    case MASTER_RM:
      return {
        ...state,
        masterRM: action.payload,
      };
    case MASTER_PM:
      return {
        ...state,
        masterPM: action.payload,
      };
    case RM_TABLE_DATA:
      return {
        ...state,
        rmTableData: action.payload,
      };
    case PM_TABLE_DATA:
      return {
        ...state,
        pmTableData: action.payload,
      };
    case EDIT_RM_TABLE_DATA:
      return {
        ...state,
        rmTableData: state.rmTableData.map((val) => {
          if (val.materialCode === action.payload.materialCode) {
            return action.payload;
          }
          return val;
        }),
      };
    case EDIT_PM_TABLE_DATA:
      return {
        ...state,
        pmTableData: state.pmTableData.map((val) => {
          if (val.materialCode === action.payload.materialCode) {
            return action.payload;
          }
          return val;
        }),
      };
    case UPDATE_RMPM:
      return {
        ...state,
        updateRMPM: action.payload,
      };
    case QA_APPROVAL:
      return {
        ...state,
        qaApproval: action.payload,
      };
    default:
      return state;
  }
}
