import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import EventTwoToneIcon from '@material-ui/icons/Event';

const CustomDatePickerMui = (props) => {
  const {
    name,
    value,
    onChange,
    placeholder,
    disableAutoOk,
    notFullWidth,
    variant,
    size,
    inputVariant,
  } = props;
  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD/MMM/YYYY';

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        autoOk={!disableAutoOk}
        variant={variant || 'inline'}
        inputVariant={inputVariant || 'outlined'}
        size={size || 'small'}
        fullWidth={!notFullWidth}
        format={dateFormat}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder || dateFormat}
        InputProps={{
          endAdornment: (
            <EventTwoToneIcon
              style={{ color: '#0000008a', cursor: 'pointer' }}
            />
          ),
        }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

CustomDatePickerMui.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  notFullWidth: PropTypes.bool,
  disableAutoOk: PropTypes.bool,
};

export default CustomDatePickerMui;
