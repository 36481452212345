import React from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';

//components
import Options from './Options';
import SelectCollabType from './selectCollabType';
import InvitationSent from '../../../../common/InvitationSent';
import UserDetails from '../../../../common/UserDetails';
import OrganisationDetails from '../../../../common/OrganisationDetails';

const OutsideOrgInvite = (props) => {
  const {
    inviteSent,
    multiUsers,
    collaboratorTypes,
    organizationName,
    email,
    fullName,
    phoneNumber,
    changePhoneNumber,
    handleChange,
    handleChangeEmailandName,
    errors,
    handleClose,
    sendAnotherInvite,
    collaboratorTypeId,
    handleChangeCollaborator,
    twoStepAuthentication,
    editPhoneNumber,
    azureADSignIn,
    handleChangeOptions,
  } = props;

  return (
    <Grid container alignItems='center' justify='center'>
      <Grid item xs={10} sm={10} md={multiUsers === true ? 9 : 6}>
        <Grid container justify='center' spacing={2}>
          <Grid item xs={12} sm={10}>
            <SelectCollabType
              collaboratorTypes={collaboratorTypes}
              collaboratorTypeId={collaboratorTypeId}
              errors={errors}
              handleChangeCollaborator={handleChangeCollaborator}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <UserDetails
              multiUsers={multiUsers}
              email={email}
              fullName={fullName}
              phoneNumber={phoneNumber}
              changePhoneNumber={changePhoneNumber}
              handleChangeEmailandName={handleChangeEmailandName}
              errors={errors}
            />
            <Divider style={{ marginTop: '1rem' }} />
          </Grid>
          <Grid item xs={12} sm={10}>
            <OrganisationDetails
              multiUsers={multiUsers}
              organizationName={organizationName}
              email={email}
              fullName={fullName}
              phoneNumber={phoneNumber}
              changePhoneNumber={changePhoneNumber}
              handleChange={handleChange}
              errors={errors}
            />
            <Divider style={{ marginTop: '1rem' }} />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Options
              twoStepAuthentication={twoStepAuthentication}
              editPhoneNumber={editPhoneNumber}
              azureADSignIn={azureADSignIn}
              handleChangeOptions={handleChangeOptions}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={inviteSent} onClose={handleClose} email={email}>
        <InvitationSent
          handleClose={handleClose}
          sendAnotherInvite={sendAnotherInvite}
          email={email}
        />
      </Dialog>
    </Grid>
  );
};

export default OutsideOrgInvite;
