import axios from 'axios';
import { smlUrl, appUrl } from '../../../../../utils/config';

import {
  PO_LIST,
  CMO_MEMBERS_LIST,
  CMO_TYPES_LIST,
  MILESTONE_CONFIG_TEMPLATES,
  PO_DETAILS,
  PO_STATUS,
  PO_LINE_ITEMS,
  PO_LINE_ITEM_DETAILS,
  MILESTONE_CONFIG_TEMPLATE_DATA,
  LINE_ITEM_STATUS_LIST,
  UPDATE_LINE_STATUS,
  UPDATE_LINE_STATUS_FAIL,
  PO_PREVIEW,
  PO_ADD,
  CMO_MEMBERS_WITH_ID,
} from '../types';

export const purchaseOrderList = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/fetchAllPurchaseOrdersList`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PO_LIST,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export let cmoMembersSource;
export const cmoMembersList = (data) => (dispatch) => {
  const CancelToken = axios.CancelToken;
  cmoMembersSource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/fetchCMOMembers`,
      data,
      { cancelToken: cmoMembersSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_MEMBERS_LIST,
          payload: res.data.CMOMembers,
        });
        dispatch({
          type: CMO_MEMBERS_WITH_ID,
          payload: res.data.CMOMembersWithId,
        });
      }
    })
    .catch((err) => console.log(err));
};
export const getCMOtypes = (data) => (dispatch) => {
  axios
    .post(`${smlUrl}/user/getCMOtypes`, data)
    .then((res) => {
      dispatch({
        type: CMO_TYPES_LIST,
        payload: res.data.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getMilestoneConfigTemplate = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/fetchExistingMilestoneConfigTemplateName`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: MILESTONE_CONFIG_TEMPLATES,
          payload: res.data.milestoneConfigTemplateNames,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getMilestoneConfigTemplateData = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/fetchExistingMilestoneConfig`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: MILESTONE_CONFIG_TEMPLATE_DATA,
          payload: res.data.milestoneConfigResult,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const previewPurchaseOrder = (fileData, roleName) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${roleName}/previewPurchaseOrder`,
      fileData
    )
    .then((res) => {
      dispatch({
        type: PO_PREVIEW,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: PO_PREVIEW,
        payload: err.response.data,
      });
    });
};

export const addPurchaseOrder = (fileData, roleName) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${roleName}/addNewPurchaseOrder`,
      fileData
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PO_ADD,
          payload: res.data.status,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getPurchaseOrderDetails = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/fetchPurchaseOrderDetails`,
      data
    )
    .then((res) => {
      dispatch({
        type: PO_DETAILS,
        payload: res.data.purchaseOrderDetails,
      });
    })
    .catch((err) => console.log(err));
};

export const getPurchaseOrderStatus = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/purchaseOrderStatus`,
      data
    )
    .then((res) => {
      dispatch({
        type: PO_STATUS,
        payload: res.data.purchaseOrderStatus,
      });
    })
    .catch((err) => console.log(err));
};

export const getLineItemsInPO = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/fetchPurchaseOrderLineItems`,
      data
    )
    .then((res) => {
      dispatch({
        type: PO_LINE_ITEMS,
        payload: res.data.purchaseOrderLineItems,
      });
    })
    .catch((err) => console.log(err));
};

export const getLineItemDetails = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchPurchaseOrderLineItemDetails`,
      data
    )
    .then((res) => {
      dispatch({
        type: PO_LINE_ITEM_DETAILS,
        payload: res.data.purchaseOrderLineItemDetails,
      });
    })
    .catch((err) => console.log(err));
};
export const updatPoStatus = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/purchaseOrderUpdateStatus`,
      data
    )
    .then((res) => {
      dispatch({
        type: PO_STATUS,
        payload: res.data.status,
      });
    })
    .catch((err) => console.log(err));
};

export const getLineItemStatusList = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/POLineStatusList`,
      data
    )
    .then((res) => {
      dispatch({
        type: LINE_ITEM_STATUS_LIST,
        payload: res.data.POLineStatusList,
      });
    })
    .catch((err) => console.log(err));
};

export const updateLineItemStatus = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/purchaseOrderLineUpdateStatus`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPDATE_LINE_STATUS,
          payload: res.data.newStatusValue,
        });
      } else {
        dispatch({
          type: UPDATE_LINE_STATUS_FAIL,
          payload: res.data.message,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const updatePurchaseOrder = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/updatePurchaseOrder`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PO_STATUS,
          payload: res.data.status,
        });
      }
    })
    .catch((err) => console.log(err));
};
