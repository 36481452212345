import React from "react";
import { Grid } from "@material-ui/core";

import ViewEditBatchItem from "./ViewEditBatchItem";
import BatchChat from "./BatchChat";

const ViewBatchItemIndex = props => {
  return (
    <Grid
      container
      style={{
        justifyContent: "center"
      }}
    >
      <Grid
        item
        xs={12}
        sm={9}
        style={{
          backgroundColor: "#E7ECEE",
          minHeight: "100vh"
        }}
      >
        <ViewEditBatchItem {...props} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <BatchChat {...props} collabType="Pharma Co" item="POBatchItem" />
      </Grid>
    </Grid>
  );
};

export default ViewBatchItemIndex;
