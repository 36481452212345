import React from 'react';
import Grid from '@material-ui/core/Grid';

export default function FullWidthContent({ children, ...props }) {
  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={11} sm={10} md={10} lg={8} xl={6}>
        {children}
      </Grid>
    </Grid>
  );
}
