import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import {
  presentDate,
  presentFormatedData,
} from '../../../functionalComponents/dataFormatFunctions';
import { useStyles } from '../styles';

const DSLineItemDetails = (props) => {
  const classes = useStyles();

  const dsData = props.dsDetails;

  return (
    <div style={{ padding: '1rem' }}>
      <Grid container className={classes.body}>
        <Grid item className={classes.flexColSpaceBtwn}>
          <Typography variant='body2' className={classes.headerGridTxt}>
            PO Number
          </Typography>
          <Typography variant='body2' className={classes.wrapGridText}>
            {presentFormatedData(props.scheduleAgreementNumber)}
          </Typography>
        </Grid>
        <Grid item className={classes.flexColSpaceBtwn}>
          <Typography variant='body2' className={classes.headerGridTxt}>
            Item
          </Typography>
          <Typography variant='body2' className={classes.wrapGridText}>
            {presentFormatedData(dsData.scheduleLineItemNumber)}
          </Typography>
        </Grid>
        <Grid item className={classes.flexColSpaceBtwn}>
          <Typography variant='body2' className={classes.headerGridTxt}>
            Material Code / Description
          </Typography>
          <Typography variant='body2' className={classes.wrapGridText}>
            {presentFormatedData(dsData.fgMaterialCode)}
            <br />
            {presentFormatedData(dsData.fgMaterialName)}
          </Typography>
        </Grid>
        <Grid item className={classes.flexColSpaceBtwn}>
          <Typography variant='body2' className={classes.headerGridTxt}>
            Scheduled Quantity
          </Typography>
          <Typography variant='body2' className={classes.wrapGridText}>
            {presentFormatedData(dsData.scheduledQuantity)}
          </Typography>
        </Grid>
        <Grid item className={classes.flexColSpaceBtwn}>
          <Typography variant='body2' className={classes.headerGridTxt}>
            Open Quantity
          </Typography>
          <br />
          <Typography variant='body2' className={classes.wrapGridText}>
            {presentFormatedData(
              parseFloat(Number(dsData.openTargetQuantity).toFixed(3))
            )}
          </Typography>
        </Grid>
        <Grid item className={classes.flexColSpaceBtwn}>
          <Typography variant='body2' className={classes.headerGridTxt}>
            Delivery Date
          </Typography>
          <Typography variant='body2' className={classes.wrapGridText}>
            {presentDate(dsData.deliveryDate, 'NA')}
          </Typography>
        </Grid>
        <Grid item className={classes.flexColSpaceBtwn}>
          <Typography variant='body2' className={classes.headerGridTxt}>
            Committed Date
          </Typography>
          <Typography variant='body2' className={classes.wrapGridText}>
            {presentDate(dsData.cmoCommittedDate, 'NA')}
          </Typography>
        </Grid>
        <Grid item className={classes.flexColSpaceBtwn}>
          <Typography variant='body2' className={classes.headerGridTxt}>
            Total Batches
          </Typography>
          <Typography variant='body2' className={classes.wrapGridText}>
            {presentFormatedData(dsData.batchCount)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default DSLineItemDetails;
