import React from 'react';
import StatusTextDispaly from './status/DeliveryCompleted';

const batchStatus = (statusId) => {
  const getNameAndColor = (id) => {
    let textColor;
    switch (id) {
      case 1:
        textColor = { statusText: 'Awaiting RM/PM', bgColor: '#393939' };
        break;
      case 2:
        textColor = {
          statusText: 'Record Incomplete',
          bgColor: '#E8E8E8',
          fontColor: 'black',
          borderColor: 'black',
        };
        break;
      case 3:
        textColor = { statusText: 'Awaiting GRN', bgColor: '#FF7600' };
        break;
      case 4:
        textColor = { statusText: 'Resubmit Record', bgColor: '#FF0000' };
        break;
      case 5:
        textColor = { statusText: 'GRN Pending', bgColor: '#1B9ABF' };
        break;
      case 6:
        textColor = { statusText: 'GRN Generated', bgColor: '#408E0F' };
        break;
      case 7:
        textColor = { statusText: 'Partially Received', bgColor: '#9B1515' };
        break;
      case 8:
        textColor = { statusText: 'Material Received', bgColor: '#408E0F' };
        break;
      case 9:
        textColor = { statusText: 'Blocked', bgColor: '#388BA6' };
        break;
      case 10:
        textColor = { statusText: 'Marked for Delete', bgColor: '#D53624' };
        break;
      case 11:
        textColor = { statusText: 'Delivery Complete', bgColor: '#2d6a4f' };
        break;
      case 12:
        textColor = { statusText: 'Repeat QC/QA', bgColor: '#5D5F61' };
        break;
      case 13:
        textColor = { statusText: 'Awaiting QC/QA', bgColor: '#2973C7' };
        break;
      case 15:
        textColor = { statusText: 'QC/QA Rejected', bgColor: '#5D5F61' };
        break;
      default:
        textColor = { statusText: '--', bgColor: '#ffffff' };
        break;
    }
    return textColor;
  };

  return <StatusTextDispaly {...getNameAndColor(statusId)} />;
};

export default batchStatus;
