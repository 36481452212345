import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import usePrevious from '../../../../../helpers/previousProps';

import Preview from './Preview';
// import RecordTimeLine from './RecordTimeline';

import { eventDetailsByID } from '../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '100%',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
    backgroundColor: '#F5F9FF',
  },
  title: {
    fontWeight: 700,
    padding: '1.5rem 0rem',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
  },
  dialogPaper: {
    width: '70%',
  },
});

const ViewQualityEventDrawer = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);

  const { openDialog, toggleDialog, eventID } = props;
  const [state, setState] = useState({
    qualityEventRecordDetails: {},
    loading: true,
  });
  const { eventDetails } = props;
  const prevEventDetails = usePrevious(eventDetails);
  useEffect(() => {
    if (didMountRef.current) {
      if (eventDetails !== prevEventDetails) {
        setState((prevState) => ({
          ...prevState,
          qualityEventRecordDetails: eventDetails,
          loading: false,
        }));
      }
    } else {
      props.eventDetailsByID(eventID);
      didMountRef.current = true;
    }
  }, [eventID, eventDetails, prevEventDetails]);

  return (
    <Drawer
      classes={{ paper: classes.dialogPaper }}
      width='70%'
      fullScreen
      anchor='right'
      open={openDialog}
      onClose={() => toggleDialog('viewQualityEventScreen')}
    >
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <Typography variant='h5' className={classes.title}>
            Event Details
          </Typography>

          <div
            className={classes.closeBtn}
            onClick={() => toggleDialog('viewQualityEventScreen')}
          >
            <Typography
              variant='caption'
              style={{ lineHeight: 'normal', fontWeight: '700' }}
            >
              Cancel
            </Typography>
            <CloseIcon style={{ color: 'red', fontSize: '2rem' }} />
          </div>
        </div>
      </div>
      {state.loading ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress thickness='5' />
        </div>
      ) : (
        <Box marginLeft={3}>
          <Preview
            qualityEventRecordDetails={state.qualityEventRecordDetails}
          />
        </Box>
      )}
      {/* <RecordTimeLine /> */}
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  eventDetails: state.pcmp.common.QMS.fetchEventDetails,
});

export default connect(mapStateToProps, { eventDetailsByID })(
  ViewQualityEventDrawer
);
