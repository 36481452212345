/* eslint-disable no-console */
/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Harish D
 * -----
 * File Description: Table that shows Line Item Batch details
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import {
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Grid,
  TableFooter,
} from '@material-ui/core';

import TablePagination from '@material-ui/core/TablePagination';
import InputBase from '@material-ui/core/InputBase';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SearchIcon from '@material-ui/icons/Search';

import orderBy from 'lodash/orderBy';

import pgactions from '../functionalComponents/PageActions';

import Moment from 'moment';

import { tableFilter } from '../functionalComponents/TableFilter';

import { style } from '../../../resources/css/styles';
import { viewLineSummaryDetails } from '../../../containers/actions/common/dashboardAction';

// import ViewEditBatchItems from "../batchItems/viewEditBatchItems";

const tableDataFilter = tableFilter;
const TablePaginationActionsWrapped = pgactions;

const styles = style;

const invertDirection = {
  asc: 'desc',
  desc: 'asc',
};

const header = [
  { name: 'Current Status', id: 'statusText', align: 'left' },
  { name: 'Line Number', id: 'lineNumber', align: 'left' },
  { name: 'PO Number', id: 'purchaseOrderNumber', align: 'left' },
  { name: 'Product Code', id: 'productCode', align: 'left' },
  { name: 'Product Name', id: 'productName', align: 'left' },
  { name: 'Plant Code', id: 'CMOPlantCode', align: 'left' },
  { name: 'UOM', id: 'unitOfMeasurement', align: 'left' },
  { name: 'Quantity', id: 'quantity', align: 'right' },
  { name: 'Delivery Date', id: 'deliveryDate', align: 'right' },
  { name: 'Commit Date', id: 'poLineCommitDate', align: 'right' },
];

class LineItemsTable extends Component {
  state = {
    rowNumber: this.props.rowNumber,
    cardIndex: this.props.cardIndex,
    data: [],
    header,
    displayFilter: false,
    query: '',
    columnToQuery: 'all',
    columnToSort: '',
    sortDirection: 'desc',
    fieldSelected: false,
    page: 0,
    rowsPerPage: localStorage.rowsPerPage
      ? parseInt(localStorage.rowsPerPage)
      : 3,
    lineItemStatus: 0,
    batchItem: {},
    openBatchDialog: false,
    openViewBatchItemDialog: false,
    loading: true,
  };

  componentDidMount() {
    this.getLineItems();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.rowNumber !== prevState.rowNumber) {
      return { rowNumber: nextProps.rowNumber };
    }
    if (nextProps.cardIndex !== prevState.cardIndex) {
      return { cardIndex: nextProps.cardIndex };
    }
    return null;
  }

  componentDidUpdate(nextProps, prevState) {
    if (
      this.state.rowNumber !== prevState.rowNumber ||
      this.state.cardIndex !== prevState.cardIndex
    ) {
      this.getLineItems();
    }
    if (
      this.props.currentMonthNumber !== nextProps.currentMonthNumber ||
      this.props.currentYear !== nextProps.currentYear
    ) {
      this.getLineItems();
    }
    if (this.props.CMOMember !== nextProps.CMOMember) {
      this.getLineItems();
    }
    if (nextProps.lineSummaryDetails !== this.props.lineSummaryDetails) {
      this.setState({ data: this.props.lineSummaryDetails, loading: false });
    }
  }
  getLineItems = () => {
    let data = {
      rowNumber: this.state.rowNumber,
      cardIndex: this.state.cardIndex,
      collabType: this.props.collabType,
      currentMonthNumber: this.props.currentMonthNumber,
      currentYear: this.props.currentYear,
      CMOMember: this.props.CMOMember,
      APIName:
        this.state.cardIndex === 1
          ? 'viewLineItemSummaryDetails'
          : 'viewSummaryDetails',
      ...this.props.match.params,
    };
    this.props.viewLineSummaryDetails(data);
    this.setState({ loading: true });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      fieldSelected: false,
    });

    if (this.state.columnToQuery === 'none') {
      if (event.target.name === 'query') {
        this.setState({ fieldSelected: true });
      }
    }
  };

  // show search fields
  showFilter = () => {
    this.setState({
      displayFilter: !this.state.displayFilter,
      columnToQuery: 'all',
      query: '',
    });
  };

  // handle sort
  handleSort = (columName) => {
    this.setState({
      columnToSort: columName,
      sortDirection:
        this.state.columnToSort === columName
          ? invertDirection[this.state.sortDirection]
          : 'asc',
    });
  };

  // pagination
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    const { page } = this.state;
    if (page > 0) {
      this.setState({ page: 0, rowsPerPage: value });
    } else {
      this.setState({ rowsPerPage: value });
    }
    localStorage.rowsPerPage = value;
  };

  openLineItem = (purchaseOrderNumber, schrockenPOLineID, lineNumber) => {
    const { params } = this.props.match;
    this.props.history.push(
      `/pcmp/${this.props.collabType}/${params.roleName}/${params.organizationName}/${params.ecosystemId}/lineitem/${purchaseOrderNumber}/${schrockenPOLineID}/${lineNumber}`
    );
  };

  onRefresh = () => {
    this.getLineItems();
    this.setState({ columnToSort: '' });
  };

  render() {
    const { classes, tableTitle, tableSubtitle } = this.props;
    const { header, data, rowsPerPage, page, loading } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    const queryData = orderBy(
      this.state.query
        ? data.filter((data) =>
            tableDataFilter(
              data,
              this.state.columnToQuery,
              this.state.query.toLowerCase(),
              this.state.header
            )
          )
        : data,
      this.state.columnToSort,
      this.state.sortDirection
    );

    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, queryData.length - page * rowsPerPage);

    const loadingRow = (
      <TableBody>
        <TableRow>
          <TableCell colSpan={header.length} style={{ textAlign: 'center' }}>
            Loading...
          </TableCell>
        </TableRow>
      </TableBody>
    );

    const noRecords = (
      <TableRow>
        <TableCell colSpan={header.length} align='center'>
          No Records Found
        </TableCell>
      </TableRow>
    );
    return (
      <Grid item xs={12} md={12} className={classes.membersTable}>
        <Paper className={classes.root3}>
          <Toolbar>
            <Grid
              container
              spacing={2}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Grid
                item
                xs={8}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    fontSize: '1.2rem',
                    marginRight: '0.5rem',
                  }}
                >
                  {tableTitle}
                </Typography>

                <Typography
                  variant='caption'
                  style={{
                    fontSize: '0.85rem',
                  }}
                >
                  ({tableSubtitle})
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <SearchIcon />
                <InputBase
                  placeholder='Search Line'
                  value={this.state.query}
                  name='query'
                  onChange={this.handleChange}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                />
              </Grid>
            </Grid>
            <Tooltip title='Refresh List'>
              <IconButton aria-label='Refresh list' onClick={this.onRefresh}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>

          <Table className={classes.table}>
            <TableHead style={{ background: '#E8E8E8' }}>
              <TableRow>
                {header.map((data, i) => (
                  <TableCell key={i} align={data.align}>
                    <Tooltip title='sort'>
                      <div
                        onClick={() => this.handleSort(data.id)}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: `${
                            data.align === 'right' ? 'flex-end' : 'flex-start'
                          }`,
                          cursor: 'pointer',
                        }}
                      >
                        <span>{data.name}</span>
                        {this.state.columnToSort === data.id ? (
                          this.state.sortDirection === 'asc' ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </div>
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              loadingRow
            ) : (
              <TableBody>
                {/* {searchResults} */}
                {queryData.length > 0
                  ? queryData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((ele, i) => (
                        <React.Fragment key={`fragment-${i}`}>
                          <TableRow
                            key={`row-${i}`}
                            className={
                              ele.statusCode !== 4
                                ? classes.tableRowHover
                                : null
                            }
                            onClick={() =>
                              this.openLineItem(
                                ele.purchaseOrderNumber,
                                ele.schrockenPOLineID,
                                ele.lineNumber
                              )
                            }
                          >
                            <TableCell align='left'>{ele.statusText}</TableCell>
                            <TableCell align='left'>{ele.lineNumber}</TableCell>
                            <TableCell align='left'>
                              {ele.purchaseOrderNumber}
                            </TableCell>
                            <TableCell align='left'>
                              {ele.productCode}
                            </TableCell>
                            <TableCell align='left'>
                              {ele.productName}
                            </TableCell>
                            <TableCell align='left'>
                              {ele.CMOPlantCode}
                            </TableCell>
                            <TableCell align='left'>
                              {ele.unitOfMeasurement}
                            </TableCell>
                            <TableCell align='right'>{ele.quantity}</TableCell>
                            <TableCell align='right'>
                              {Moment(ele.deliveryDate).format(dateFormat)}
                            </TableCell>
                            <TableCell align='right'>
                              {ele.poLineCommitDate
                                ? Moment(new Date(ele.poLineCommitDate)).format(
                                    dateFormat
                                  )
                                : 'Not Specified'}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))
                  : noRecords}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={10} />
                  </TableRow>
                )}
              </TableBody>
            )}
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[3, 5, 10, 15]}
                  count={queryData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Grid>
    );
  }
}

LineItemsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  lineSummaryDetails: state.pcmp.common.dashboard.lineSummaryDetails,
});

export default withStyles(styles)(
  connect(mapStateToProps, { viewLineSummaryDetails })(LineItemsTable)
);
