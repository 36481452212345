import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import ScheduleAgreement from './ScheduleAgreement';
import Header from '../models/Header';

export const useStyles = makeStyles((theme) => ({
  multipleLogo: {
    border: '1px solid #7d7c7c',
    borderRadius: '4px',
    padding: '0.3rem',
    height: 'inherit',
    marginLeft: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
  list: {
    maxWidth: '80vw',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vw',
    },
  },
  fullList: {
    width: 'auto',
  },
  received: {
    textAlign: 'left',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});
const ViewSA = (props) => {
  const classes = useStyles();
  const [openSADetails, setOpenSADetails] = React.useState(false);
  const handleCloseSA = () => {
    setOpenSADetails(false);
  };

  return (
    <React.Fragment>
      <VisibilityIcon
        fontSize='small'
        className={classes.multipleLogo}
        onClick={() => setOpenSADetails(true)} //setOpen(!open)
      />
      <Dialog
        fullScreen
        open={openSADetails}
        onClose={handleCloseSA}
        TransitionComponent={Transition}
      >
        <Header
          handleClose={handleCloseSA}
          title='Purchase Order / Scheduling Agreement Details'
          padding={true}
        >
          <ScheduleAgreement
            handleClose={handleCloseSA}
            schrockenSAID={props.schrockenSAID}
            scheduleAgreementNumber={props.scheduleAgreementNumber}
          />
        </Header>
      </Dialog>
    </React.Fragment>
  );
};

export default ViewSA;
