import {
  PHARMA_LINE_BATCH_ITEMS,
  PHARMA_BATCH_UPDATE,
  PHARMA_FETCH_RM_AVAILABILITY,
  PHARMA_FETCH_PM_AVAILABILITY,
} from '../../actions/types';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case PHARMA_LINE_BATCH_ITEMS:
      return {
        ...state,
        lineBatchItems: action.payload,
        batchUpdated: false,
      };
    case PHARMA_BATCH_UPDATE:
      return {
        ...state,
        batchUpdated: action.payload,
      };
    // rm pm
    case PHARMA_FETCH_RM_AVAILABILITY:
      return {
        ...state,
        rmAvailable: action.payload,
      };
    case PHARMA_FETCH_PM_AVAILABILITY:
      return {
        ...state,
        pmAvailable: action.payload,
      };
    default:
      return state;
  }
}
