import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { QEHeaders, QEHeadersBO } from './qualityEventsHeader';
import TableHeaderCell from '../../models/table/CustomTableHeaderCellPagination';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetchQMSData } from '../../../../containers/actions/common/qualityEventActions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableDataForCMO from './TableDataForCMO';
import TableDataForBO from './TableDataForBO';

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import classNames from 'classnames';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: '2rem',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  POOrderBackground: {
    margin: '-1rem -1.3rem 0rem -1.8rem',
    backgroundColor: '#F5F5F5',
    flexDirection: 'row',
    padding: '1.2rem 0.5rem',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      cursor: 'pointer',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
});
class QETable extends Component {
  state = {
    loading: false,
    checked: false,
    totalLength: 0,
    qualityEvents: [],
    orderBy: 'lastUpdatedDate',
    order: 'desc',
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.qualityEventsArray !== prevProps.qualityEventsArray) {
      this.setState({
        loading: this.props.loading,
        qualityEvents: this.props.qualityEventsArray
          ? this.props.qualityEventsArray
          : [],
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { qualityEvents, loading } = this.state;
    return (
      <Paper
        className={classNames.root}
        style={{ display: 'inline-table', width: '100%' }}
      >
        <TableHead stickyHeader>
          <TableRow style={{ height: 15 }}>
            {/* <TableCell
                  style={{
                    width: '1%',
                    maxWidth: 0,
                    overflow: 'hidden',
                  }}
                /> */}
            {localStorage.collabType === 'CMO'
              ? QEHeaders.map((headCell) => (
                  <TableHeaderCell
                    //isSortEnable={headCell.isSortEnable}
                    //sortingFunction={this.createSortHandler}

                    cellData={headCell}
                    //order={order}
                    //orderBy={orderBy}
                    key={headCell.id}
                    elementStyle={{
                      verticalAlign: 'top',
                      fontWeight: '600',
                      paddingBottom: '1.2rem',
                      width: headCell.width ? headCell.width : 'auto',
                    }}
                  />
                ))
              : QEHeadersBO.map((headCell) => (
                  <TableHeaderCell
                    cellData={headCell}
                    key={headCell.id}
                    elementStyle={{
                      verticalAlign: 'top',
                      fontWeight: '600',
                      paddingBottom: '1.2rem',
                      width: headCell.width ? headCell.width : 'auto',
                      whiteSpace: 'normal',
                    }}
                  />
                ))}
          </TableRow>
          {this.props.tableLoading === true && (
            <TableRow style={{ padding: 0 }}>
              <TableCell colSpan={16} style={{ padding: 0 }}>
                <LinearProgress color='secondary' />
              </TableCell>
            </TableRow>
          )}
        </TableHead>

        <TableBody>
          <React.Fragment>
            {(loading === true ||
              (qualityEvents.length === 0 &&
                this.props.qualityEventsArray.length !== 0)) && (
              <TableRow style={{ height: 150 }}>
                <TableCell colSpan={QEHeadersBO.length}>
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress color='primary' thickness={7} />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {(loading === false &&
              this.props.qualityEventsArray.length === 0) ||
            this.props.qualityEventsArray.length === 0 ? (
              <TableRow>
                <TableCell colSpan={QEHeadersBO.length} align='center'>
                  No Records Found
                </TableCell>
              </TableRow>
            ) : localStorage.collabType === 'CMO' ? (
              qualityEvents.map((row, index) => (
                <TableDataForCMO row={row} index={index}></TableDataForCMO>
              ))
            ) : (
              //<TableDataForCMO >{qualityEvents} </TableDataForCMO>
              qualityEvents.map((row, index) => (
                <TableDataForBO row={row} index={index}></TableDataForBO>
              ))
            )}
          </React.Fragment>
        </TableBody>
      </Paper>
    );
  }
}

QETable.propTypes = {
  qualityEvents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  qualityEvents: state.pcmp.common.QMS.fetchQMSData,
  //tableLoading: state.pcmp.common.files.tableLoading,
});

export default withStyles(styles)(
  connect(mapStateToProps, { fetchQMSData })(withRouter(QETable))
);
