const getQAStatusColor = (status) => {
  let bgcolor;
  switch (String(status)) {
    case '1':
      bgcolor = '#EC6535';
      break;
    case '2':
      bgcolor = '#329429';
      break;
    case '3':
      bgcolor = '#FF0000';
      break;
    default:
      bgcolor = '#484848';
  }
  return bgcolor;
};
export default getQAStatusColor;
