import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const styles = (theme) => ({
  titletext: {
    [theme.breakpoints.down("md")]: {
      fontSize: "0.65rem",
    },
  },
});

class SectionCard extends Component {
  handleClick = (statusCode) => {
    const { ecosystemId, organizationName, roleName } = this.props.match.params;
    const { startCommitDate, endCommitDate, API } = this.props;

    this.props.history.push(
      `/pcmp/Pharma Co/${roleName}/${organizationName}/${ecosystemId}/executivedashboard/lineItems?startCommitDate=${startCommitDate}&endCommitDate=${endCommitDate}&statusCode=${statusCode}&api=${API}`
    );
  };
  render() {
    const { classes } = this.props;

    return (
      <Grid
        item
        xs={12}
        sm={4}
        style={{ padding: 0, backgroundColor: "#ffffff" }}
      >
        <Typography
          variant="h6"
          style={{ fontSize: "1rem", padding: "0.25rem" }}
        >
          {this.props.sectionTitle}
        </Typography>
        <Divider />
        {this.props.statusArray.map((item, i) => (
          <React.Fragment key={i}>
            <Grid
              container
              style={{
                padding: "0.5rem",
                backgroundColor: item.background,
                minHeight: "2.25rem",
              }}
            >
              <Grid item xs={8}>
                <Typography variant="body2" className={classes.titletext}>
                  {item.statusName}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {item.count > 0 ? (
                  <Typography
                    variant="body2"
                    color="secondary"
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => this.handleClick(item.statusCode)}
                  >
                    {item.count}
                  </Typography>
                ) : (
                  <Typography variant="body2" color="secondary">
                    {item.count}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {/* <Divider /> */}
          </React.Fragment>
        ))}
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(SectionCard));
