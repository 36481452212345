import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGrStatuses } from "../../../containers/actions/common/reportsActions";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useStyles } from "./grnStyles";

const StatusFilter = (props) => {
  const { handleSelectStatus, status } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  let grnStatuses = useSelector((state) =>
    state.pcmp.common.reports.grnstatuses
      ? state.pcmp.common.reports.grnstatuses.data
      : []
  );
  console.log(grnStatuses, "grnStatuses");

  useEffect(() => {
    dispatch(
      fetchGrStatuses({
        collabType: "Pharma Co",
        roleName: "Admin",
        organizationName: "Lupin",
        ecosystemId: 1,
      })
    );

    // return () => {
    //   if (grStatusSource) {
    //     grStatusSource.cancel('leaving page')
    //   }
    // }
  }, [dispatch]);

  return (
    <FormControl className={classes.formControl} fullWidth>
      {/* <InputLabel id='demo-simple-select-label'>CMO</InputLabel> */}
      <Select
        labelId="cmo-filter"
        id="cmo-filter-id"
        value={status}
        onChange={handleSelectStatus}
        variant="outlined"
        classes={{
          outlined: classes.outlined,
        }}
      >
        <MenuItem value="all">All</MenuItem>
        {grnStatuses.map((status, i) => (
          <MenuItem key={i} value={status.batchStatusID}>
            {status.batchStatusText}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusFilter;
