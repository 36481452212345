import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import { TablePagination } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';

import pgactions from '../../common/functionalComponents/PageActions';
import AddShortFalls from './AddShortFalls';
import { cmoFetchShortFallsItems, shortFallSource } from '../../../containers/actions/cmo/shortFallsActions';
import { cmoDownloadFiles } from '../../../containers/actions/cmo/filesActions';

import { appUrl } from '../../../../../utils/config';
import axios from 'axios';

import Moment from 'moment';

const TablePaginationActionsWrapped = pgactions;

class index extends Component {
  state = {
    month: '',
    year: '',
    CMS: '',
    uploadedDate: '',
    fileName: '',
    shortFallsData: [],
    CMSName: '',
    page: 0,
    rowsPerPage: 3,
    open: false,
    displayDialog: false,
  };

  componentDidMount() {
    let data = {
      ...this.props.match.params,
      collabType: 'CMO',
    };
    axios
      .post(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${data.roleName}/getCMSName`,
        data
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ CMSName: res.data.CMSName });
        }
      });
    this.props.cmoFetchShortFallsItems(data);
  }

  componentWillUnmount() {
    if (shortFallSource) {
      shortFallSource.cancel('leaving page')
    }
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shortFallsData) {
      this.setState({
        shortFallsData: nextProps.shortFallsData.shortFallsReports,
      });
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  downloadReport = (ID) => {
    let getFile = {
      ...this.props.match.params,
      collabType: 'CMO',
      schrockenShortfallsID: ID,
      docType: 'shortfalls',
    };
    this.props.cmoDownloadFiles(getFile, 'ShortFalls');
  };
  render() {
    const { shortFallsData, page, rowsPerPage, open } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, shortFallsData.length - page * rowsPerPage);
    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper>
            <Table style={{ minWidth: 700 }}>
              <TableHead style={{ backgroundColor: '#D0D0D0' }}>
                <TableRow>
                  <TableCell style={{ color: 'black' }}>Month</TableCell>
                  <TableCell align='right' style={{ color: 'black' }}>
                    Year
                  </TableCell>
                  <TableCell align='right' style={{ color: 'black' }}>
                    CMS
                  </TableCell>
                  <TableCell align='right' style={{ color: 'black' }}>
                    Date Uploaded
                  </TableCell>
                  <TableCell align='right' style={{ color: 'black' }}>
                    Report
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shortFallsData.length > 0 ?
                  shortFallsData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <TableRow key={i}>
                        <TableCell component='th' scope='row'>
                          {row.month}
                        </TableCell>
                        <TableCell align='right'>{row.year}</TableCell>
                        <TableCell align='right'>{row.CMSName}</TableCell>
                        <TableCell align='right'>
                          {Moment(new Date(row.dateUploaded)).format(dateFormat)}
                        </TableCell>
                        <TableCell align='right'>
                          {/* {row.fileName} */}
                          {row.shortfallsFileHash !== '' ? (
                            <IconButton
                              onClick={() =>
                                this.downloadReport(row.schrockenShortfallsID)
                              }
                            >
                              <FileCopyIcon style={{ color: 'red' }} />
                            </IconButton>
                          ) : (
                            <Typography variant='caption'>
                              No file attached
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  :
                  <TableRow>
                    <TableCell colSpan={5} align='center'>
                      No Records Found
                    </TableCell>
                  </TableRow>
                }
                {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />

                  <TableCell
                    colSpan={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Fab
                      onClick={this.handleClickOpen}
                      color='secondary'
                      aria-label='add'
                      size='small'
                    >
                      <AddIcon />
                    </Fab>
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[3, 5, 10, 15, 20]}
                    count={shortFallsData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                  />
                </TableRow>
              </TableFooter>
            </Table>
            <Dialog
              // fullWidth
              maxWidth={'sm'}
              open={open}
              onClose={this.handleClose}
            >
              <AddShortFalls
                closeDialog={this.handleClose}
                params={this.props.match.params}
                CMSName={this.state.CMSName}
              />
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  shortFallsData: state.pcmp.cmo.shortFalls,
});
export default connect(mapStateToProps, {
  cmoFetchShortFallsItems,
  cmoDownloadFiles,
})(withRouter(index));
