import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const InputNumber = React.forwardRef((props, ref) => {
  const {
    name,
    value,
    variant,
    type,
    onChange,
    size,
    placeholder,
    notFullWidth,
  } = props;
  return (
    <TextField
      variant={variant || 'outlined'}
      type={type || 'number'}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      size={size || 'small'}
      ref={ref}
      fullWidth={!notFullWidth}
      {...props}
    />
  );
});

InputNumber.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default InputNumber;
