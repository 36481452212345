import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import WIPImage from './wip.png';
 
const WorkInProgress = () => {
  return (
    <Grid
      container
      style={{ width: '100%' }}
      alignItems='center'
      justify='center'
    >
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <img src={WIPImage} alt='wip img' />
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button color='secondary' variant='outlined'>
          Coming Soon
        </Button>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant='body2' style={{ marginTop: '1rem' }}>
          This page is currently Work in Progress. Stay tuned for latest
          updates. Thank you!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default WorkInProgress;
