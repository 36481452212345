import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  text: {
    fontWeight: 500,
    marginTop: '0.5rem',
  },
  helperTextFontSize: {
    fontSize: '0.75rem',
  },
});

const SelectTypeRole = (props) => {
  const classes = useStyles();

  const {
    collabRoles,
    collaboratorRoleId,
    handleChangeCollaborator,
    errors,
  } = props;

  const Roles = collabRoles.filter(
    (role) => role.collaboratorRoleName !== 'Admin'
  );

  const selectRoles = (roles) => {
    return roles.map((role, index) => (
      <MenuItem key={index} value={role.collaboratorRoleId}>
        {role.collaboratorRoleName}
      </MenuItem>
    ));
  };
  return (
    <Grid container style={{ paddingTop: '2rem' }}>
      <Grid item xs={12}>
        <TextField
          select
          fullWidth
          variant='outlined'
          size='small'
          name='collaboratorRoleId'
          label='Collaborator Role'
          error={errors.collaboratorRoleName ? true : false}
          value={collaboratorRoleId}
          onChange={handleChangeCollaborator}
          helperText={
            <Typography
              variant='caption'
              className={classes.helperTextFontSize}
            >
              Select Collaborator Role
            </Typography>
          }
        >
          {selectRoles(Roles)}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default SelectTypeRole;
