import React from 'react';
//import { useStyles } from '../styles';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'flex',
    border: '1px solid #000000',
    borderRadius: '8%',
    padding: '0.3rem',
  },
  logoHeight: {
    height: 'inherit',
  },
  nameLogo: {
    //display: "flex",
    width: '1.5rem',
    height: 'fit-content',
    borderRadius: '100%',
    width: '1.1rem',
    // padding: '0.3rem',
    fontSize: '0.875rem',
    textAlign: 'center',
    cursor: 'pointer',
    color: 'black',

    //width: '1.5rem',
    //padding: '0.5rem'
  },
}));
function Logo(props) {
  const classes = useStyles();
  const logo = (name) => {
    if (name !== undefined && name !== null) {
      return name
        .split(' ')
        .map((val) => {
          if (val[0] !== undefined) {
            return val[0].toUpperCase();
          }
          return '';
        })
        .join('');
    }
    return '';
  };

  return (
    <Typography
      variant='body1'
      className={classes.nameLogo}
      title={props.name}
      style={{
        backgroundColor: '#EFEFEF',
        border: '1px solid #707070',
        width: '1.5rem',
        padding: '0.2rem',
      }}
    >
      {logo(props.name)}
    </Typography>
  );
}

export default Logo;
