/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Harish D
 * -----
 * File Description: Grid to separate Cards grid and table grid
 */

import React from "react";
import Grid from "@material-ui/core/Grid";

import PurchaseOrder from "./PurchaseOrder";
import PurchaseOrderTable from "../../../../pharmaCo/purchaseOrders/viewPurchaseOrder/purchaseOrder/PurchaseOrderTable";
import PORejectionComments from "./PORejectionComments";
import Chat from "../chat";

const POLayout = () => (
  <Grid
    style={{
      positiion: "relative"
    }}
  >
    <PurchaseOrder />
    <PurchaseOrderTable collabType="CMO" />
    <PORejectionComments />
    <Grid container>
      <Chat collabType="CMO" item="PO" />
    </Grid>
  </Grid>
);

export default POLayout;
