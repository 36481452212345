import axios from 'axios';
import { smlUrl, appUrl } from '../../../../../utils/config';

import {
  CUSTOM_FILES,
  CUSTOM_DOWNLOADED_FILE,
  OPEN_BACKDROP,
  DESTROY_BACKDROP,
  DOWNLOAD_EXCEL_FILE,
  TABLE_LOAD,
  OPEN_TABLE_LOADING,
} from '../types';

export const fetchCustomUploadedFiles = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchCustomFileDetails`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CUSTOM_FILES,
          payload: res.data.customFileDetails,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const downloadCustomUploadedFile = (data) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText: 'Please Wait.Downloading the file',
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/downloadCustomFile`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        let fileDataArray = res.data.fileData.data;
        var byteArray = new Uint8Array(fileDataArray);

        var a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        );
        a.download = res.data.fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        dispatch({
          type: CUSTOM_DOWNLOADED_FILE,
          payload: res.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: DESTROY_BACKDROP,
      });
    });
};

export const downloadSampleGRFile = () => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText: 'Please Wait.Downloading the Sample File',
  });
  axios
    .get(`${smlUrl}/user/downloadSampleGRFile`)
    .then((res) => {
      dispatch({
        type: DESTROY_BACKDROP,
      });
      if (res.data.status) {
        let fileDataArray = res.data.fileData.data;
        var byteArray = new Uint8Array(fileDataArray);

        var a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        );
        a.download = res.data.fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: DESTROY_BACKDROP,
      });
    });
};

export const downloadSingleGRFile = (data, backdropText) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText,
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/downloadGRFiles`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        let fileDataArray = res.data.fileData.data;
        var byteArray = new Uint8Array(fileDataArray);

        var a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        );
        a.download = res.data.fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      dispatch({
        type: DESTROY_BACKDROP,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: DESTROY_BACKDROP,
      });
    });
};

export const downloadExcelFile = (data) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/downloadExcelFile`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        let fileDataArray = res.data.fileData.data;
        var byteArray = new Uint8Array(fileDataArray);

        var a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        );
        a.download = res.data.fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        dispatch({
          type: DOWNLOAD_EXCEL_FILE,
          payload: res.data,
        });
      }
      dispatch({
        type: DESTROY_BACKDROP,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: DESTROY_BACKDROP,
      });
    });
};

// Table data load indicator
export const loadTableData = () => (dispatch) => {
  dispatch({ type: TABLE_LOAD });
};

//to show table load for main tables
export const mainTableDataLoading = () => (dispatch) => {
  //open loading spinner for main Table
  dispatch({
    type: OPEN_TABLE_LOADING,
  });
};
