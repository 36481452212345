import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import EditTable from '../updateGrRecord/EditTable';
import RecordDetails from './RecordDetails';
import RecievedQuantity from '../updateGrRecord/RecievedQuantity';
import QualityAssuranceInfo from '../updateGrRecord/QualityAssurance';
import ChallanInfo from '../updateGrRecord/ChallanInfo';
import InvoiceInfo from '../updateGrRecord/InvoiceInfo';
import OtherInfo from '../updateGrRecord/OtherInfo';

import POItemTable from '../../common/models/POItemTable';

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
    fontSize: '1.5rem',
    textAlign: 'left',
    paddingBottom: '0.3rem',
  },
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '1.6rem',
    backgroundColor: 'grey',
  },
  grid: {
    paddingBottom: '2rem',
    paddingTop: '2rem',
  },
});

const EnterRecordDetails = (props) => {
  const classes = useStyles();
  const {
    handleDateChange,
    handleChange,
    handleContainerData,
    POLineRecordDetails,
    POBatchRecordDetails,
    handleSavePOItemData,
    receivedQuantityInfo,
    handleQA,
    qualityAssuranceInfo,
    challanInformation,
    invoiceInformation,
    handlingFiles, //parent state
    parentHandlingDeleteFile, //parent state
    //appending file
    OtherInfoTempNumber,
    handleTempNumber,
    challanTempNumber,
    invoiceTempNumber,
    qualityTempNumber,
  } = props;

  return (
    <React.Fragment>
      <POItemTable
        maintitle='Selected Record'
        POLineRecordDetails={POLineRecordDetails}
      />
      <RecordDetails
        isAddRecord={false}
        handleChange={handleChange}
        handleDateChange={handleDateChange}
        POBatchRecordDetails={POBatchRecordDetails}
        handleSavePOItemData={handleSavePOItemData}
        POLineRecordDetails={POLineRecordDetails}
      />
      <hr />

      <Grid item xs={12} sm={6} md={6} className={classes.grid}>
        <Typography variant='h4' className={classes.title}>
          Received Quantity Information
        </Typography>
        <span className={classes.borders}></span>
      </Grid>

      <RecievedQuantity
        isAddRecord={false}
        handleChange={handleChange}
        receivedQuantityInfo={receivedQuantityInfo}
        POLineRecordDetails={POLineRecordDetails}
        handleContainerData={handleContainerData}
      />

      <hr />
      <Grid item xs={12} sm={6} md={6} className={classes.grid}>
        <Typography variant='h4' className={classes.title}>
          Quality Assurance Information
        </Typography>
        <span className={classes.borders}></span>
      </Grid>

      <QualityAssuranceInfo
        handlingFiles={handlingFiles}
        isAddRecord={false}
        handleChange={handleChange}
        handleDateChange={handleDateChange}
        handleQA={handleQA}
        qualityAssuranceInfo={qualityAssuranceInfo}
        unitOfMeasurementOrdered={POLineRecordDetails.unitOfMeasurementOrdered}
        //parent
        parentHandlingDeleteFile={parentHandlingDeleteFile}
        //appending file
        handleTempNumber={handleTempNumber}
        qualityTempNumber={qualityTempNumber}
        //min date
        POLineRecordDetails={POLineRecordDetails}
      />

      <hr />
      <Grid item xs={12} sm={6} md={6} className={classes.grid}>
        <Typography variant='h4' className={classes.title}>
          Challan Information
        </Typography>
        <span className={classes.borders}></span>
      </Grid>

      <ChallanInfo
        isAddRecord={false}
        handleChange={handleChange}
        handleDateChange={handleDateChange}
        challanInformation={challanInformation}
        handlingFiles={handlingFiles}
        //parent
        parentHandlingDeleteFile={parentHandlingDeleteFile}
        //appending file
        handleTempNumber={handleTempNumber}
        challanTempNumber={challanTempNumber}
      />

      <hr />
      <Grid item xs={12} sm={6} md={6} className={classes.grid}>
        <Typography variant='h4' className={classes.title}>
          Invoice Information
        </Typography>
        <span className={classes.borders}></span>
      </Grid>

      <InvoiceInfo
        isAddRecord={false}
        invoiceInformation={invoiceInformation}
        handleChange={handleChange}
        handleDateChange={handleDateChange}
        handlingFiles={handlingFiles}
        //parent
        parentHandlingDeleteFile={parentHandlingDeleteFile}
        //appending file
        handleTempNumber={handleTempNumber}
        invoiceTempNumber={invoiceTempNumber}
      />

      <hr />
      <Grid item xs={12} sm={6} md={6} className={classes.grid}>
        <Typography variant='h4' className={classes.title}>
          Other Information
        </Typography>
        <span className={classes.borders}></span>
      </Grid>

      <OtherInfo
        isAddRecord={false}
        POBatchRecordDetails={POBatchRecordDetails}
        handleChange={handleChange}
        handlingFiles={handlingFiles}
        //parent
        parentHandlingDeleteFile={parentHandlingDeleteFile}
        //appending file
        OtherInfoTempNumber={OtherInfoTempNumber}
        handleTempNumber={handleTempNumber}
      />
    </React.Fragment>
  );
};

export default EnterRecordDetails;
