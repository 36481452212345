import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { Box, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Autocomplete } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import usePrevious from '../../../../../../../helpers/previousProps';

import {
  fetchProductDetails,
  fetchBatchDetails,
  fetchSupplierDetails,
} from '../../../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '80%',
    padding: '1rem',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
  },
  headTitle: {
    fontWeight: 700,
  },
  dialogPaper: {
    width: '40%',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
  },

  steeper: {
    width: '40%',
    margin: '0 auto',
    position: 'relative',
  },
  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
  },
  title: {
    textAlign: 'center',
    fontWeight: 500,

    marginTop: '4rem',
  },

  inputLabel: {
    marginBottom: '0.5rem',
    fontSize: '85%',
  },
  requiredStar: {
    color: 'red',
  },
  saveBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'lowercase',
  },
  clear: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textAlign: 'end',
    marginTop: '2rem',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const AffectedProduct = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const { openDialog, toggleDialog } = props;
  const [state, setState] = useState({
    errors: {},
    loadingProducts: false,
  });

  const {
    CMOMember,
    productsList,
    selectedProduct,
    batchOptions,
    selectedBatches,
    supplierNames,
  } = props.stateData;

  const handleOnChangeData = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: null },
    }));

    props.handleChangeData(name, value);

    if (name === 'selectedProduct' && value) {
      if (value?.productCode && value?.productType) {
        props.fetchBatchDetails({
          productCode: value.productCode,
          productType: value.productType,
        });
      }
    }
    if (name === 'selectedProduct') {
      props.handleChangeData('selectedBatches', []);
      props.handleChangeData('supplierNames', '');
    }
    if (name === 'selectedBatches') {
      props.handleChangeData('supplierNames', '');
      const supplierData = [];

      value.map((data) => {
        const supplierList = {};
        supplierList.supplierPurchaseOrderNumber =
          data.supplierPurchaseOrderNumber;
        supplierList.batchNumber = data.batchNumber;
        supplierData.push(data);
      });

      props.fetchSupplierDetails({
        productCode: selectedProduct.productCode,
        supplierPOList: supplierData,
      });
    }
  };

  const handleSearchProducts = (value) => {
    if (value !== '') {
      setState((prevState) => ({
        ...prevState,
        loadingProducts: true,
      }));
      props.fetchProductDetails({ searchString: value });
    } else {
      handleOnChangeData('productsList', []);
    }
  };

  const { reduxProductsList } = props;
  const prevReduxProductsList = usePrevious(reduxProductsList);
  useEffect(() => {
    if (didMountRef.current) {
      if (reduxProductsList !== prevReduxProductsList) {
        setState((prevState) => ({
          ...prevState,
          loadingProducts: false,
        }));
      }
    } else {
      didMountRef.current = true;
    }
  }, [reduxProductsList, prevReduxProductsList]);

  const handleValidations = () => {
    const errorsData = {};
    let isError = false;

    if (selectedProduct === '' || selectedProduct === null) {
      errorsData.selectedProduct = 'Please select product';
      isError = true;
    }
    if (selectedBatches.length === 0) {
      errorsData.selectedBatches = 'Please select batch number';
      isError = true;
    }

    if (isError) {
      setState((prevState) => ({
        ...prevState,
        errors: errorsData,
      }));
    } else {
      props.toggleDialog('affectedProductScreen');
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => toggleDialog('affectedProductScreen')}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='sm'
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.headerContainer}>
        <div
          className={classes.closeBtn}
          onClick={() => toggleDialog('affectedProductScreen')}
        >
          <Typography
            variant='caption'
            style={{ lineHeight: 'normal', fontWeight: '700' }}
          >
            Cancel
          </Typography>
          <CloseIcon style={{ color: 'red' }} />
        </div>
      </div>
      <Box width='80%' margin='auto'>
        <Box>
          <Typography variant='h5' className={classes.title}>
            Affected Product and Batches
          </Typography>
          <hr className={classes.hrLine} />
        </Box>

        <Box justifyContent='space-between'>
          <Box marginTop={2} width='100%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Select Product <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              loading={state.loadingProducts}
              value={selectedProduct}
              options={productsList}
              getOptionLabel={(option) =>
                option?.productCode
                  ? `${option.productCode}-${option.productDescription}`
                  : ''
              }
              getOptionSelected={(option) =>
                option?.productCode
                  ? `${option.productCode}-${option.productDescription}`
                  : ''
              }
              loadingText='Getting Products...'
              noOptionsText='No matching records found. Please try different search'
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Search using product code or Description'
                  error={!!state.errors.selectedProduct}
                  helperText={state.errors.selectedProduct}
                  onChange={(e) => {
                    handleSearchProducts(e.target.value);
                  }}
                />
              )}
              onChange={(name, value) => {
                handleOnChangeData('selectedProduct', value);
              }}
            />
          </Box>
          <Box marginTop={2} width='100%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Select Batch <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              options={batchOptions}
              getOptionLabel={(option) => option.batchNumber}
              // getOptionSelected={(option) => option.batchNumber}
              name='batchNumber'
              value={selectedBatches}
              multiple={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  placeholder='Start typing to search and select'
                  error={!!state.errors.selectedBatches}
                  helperText={state.errors.selectedBatches}
                />
              )}
              onChange={(name, value) => {
                handleOnChangeData('selectedBatches', value);
              }}
            />
          </Box>
          <Box marginTop={2} width='100%'>
            <Typography variant='body2' className={classes.inputLabel}>
              RM & PM Supplier Name
            </Typography>
            <TextField
              name='affectedSupplier'
              value={supplierNames}
              disabled
              style={{ width: '100%' }}
              size='small'
              variant='outlined'
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
        </Box>

        <Box
          width='70%'
          textAlign='center'
          marginTop={3}
          marginBottom='2rem'
          marginLeft={7}
        >
          <Button
            className={classes.saveBtn}
            variant='contained'
            color='primary'
            endIcon={<NavigateNextIcon />}
            onClick={handleValidations}
          >
            save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  reduxProductsList: state.pcmp.common.QMS.fetchProductList,
});

export default connect(mapStateToProps, {
  fetchProductDetails,
  fetchBatchDetails,
  fetchSupplierDetails,
})(AffectedProduct);
