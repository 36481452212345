import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";

const CmoNotes = props => {
  const { handleChange, CMONotes, clearNotes } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          style={{
            marginTop: "1rem"
          }}>
          Add optional notes for CMO
        </Typography>
        <TextField
          name="CMONotes"
          value={CMONotes}
          fullWidth
          multiline
          variant="outlined"
          margin="normal"
          //   helperText="Add important notes for CMO"
          rows="5"
          onChange={handleChange}
        />
      </Grid>
      <Typography
        variant="caption"
        onClick={clearNotes}
        style={{
          textDecoration: "underline",
          cursor: "pointer",
          marginLeft: "0.5rem"
        }}>
        Clear Notes
      </Typography>
    </Grid>
  );
};

export default CmoNotes;
