import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { cmoMembersList } from '../../../containers/actions/pharmaCo/purchaseOrderActions';
import { useSelector, useDispatch } from 'react-redux';

const styles = makeStyles((theme) => ({
  marginTopBar: {
    marginTop: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    background: '#E8E8E8',
  },
  topBarContainer: {
    padding: '0.5rem',
    paddingBottom: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    minHeight: '4rem',
  },
  topBarButtonLink: {
    fontSize: '0.6rem',
    padding: '0.35rem 0.65rem',
    borderRadius: '1.05rem',
    marginRight: '1rem',
  },
}));

const TopBar = (props) => {
  const classes = styles();
  const dispatch = useDispatch();

  let membersList = useSelector((state) =>
    state.pcmp.pharmaCo.purchaseOrder.cmoMembersList
      ? state.pcmp.pharmaCo.purchaseOrder.cmoMembersList
      : []
  );

  let screensAccess = useSelector((state) => {
    return state.pcmp.common.accessControl.screensAccess.data;
  });

  useEffect(() => {
    const data = {
      ...props.match.params,
      collabType: 'Pharma Co',
    };

    dispatch(cmoMembersList(data));
  }, [dispatch, props.match.params]);

  const {
    selectedDate,
    handleDateChange,
    openDialog,
    handleChange,
    CMOMember,
  } = props;
  const { roleName, ecosystemId, organizationName } = props.match.params;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <InputLabel shrink>Select CMO</InputLabel>
        <Select
          fullWidth
          name='CMOMember'
          value={CMOMember}
          onChange={handleChange}
        >
          <MenuItem value='all'>All</MenuItem>

          {membersList.map((cmoMember, i) => (
            <MenuItem
              key={i}
              value={cmoMember}
              disabled={cmoMember === null ? true : false}
            >
              {cmoMember}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={2}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            // autoOk
            // variant="inline"
            // openTo="year"
            label='Select Month and Year'
            views={['year', 'month']}
            openTo='month'
            value={selectedDate}
            onChange={(date) => handleDateChange(date)}
            InputProps={{
              endAdornment: (
                <ArrowDropDownIcon
                  style={{ color: '#0000008a', cursor: 'pointer' }}
                />
              ),
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={8}>
        <div className={classes.marginTopBar}>
          <Grid container spacing={2} className={classes.topBarContainer}>
            <Button
              variant='contained'
              color='primary'
              className={classes.topBarButtonLink}
              onClick={openDialog}
              startIcon={<AddCircleIcon />}
            >
              New Purchase Order
            </Button>

            {screensAccess !== undefined &&
              screensAccess.purchaseOrders.screenAccess === true && (
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.topBarButtonLink}
                  component={Link}
                  to={`/pcmp/Pharma Co/${roleName}/${organizationName}/${ecosystemId}/purchaseorders`}
                >
                  All Purchase Orders
                </Button>
              )}

            {screensAccess !== undefined &&
              screensAccess.lineItems.screenAccess === true && (
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.topBarButtonLink}
                  component={Link}
                  to={`/pcmp/Pharma Co/${roleName}/${organizationName}/${ecosystemId}/lineitems`}
                >
                  All Line Items
                </Button>
              )}

            {screensAccess !== undefined &&
              screensAccess.batchItems.screenAccess === true && (
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.topBarButtonLink}
                  component={Link}
                  to={`/pcmp/Pharma Co/${roleName}/${organizationName}/${ecosystemId}/batches`}
                >
                  All Batches
                </Button>
              )}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default TopBar;
