/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Harish D
 * -----
 * File Description: Component to display Line item details
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Button, Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';

import { getLineItemDetails } from '../../../../../containers/actions/pharmaCo/purchaseOrderActions';

import LineItemStatus from './LineItemStatus';
import AddRMAvailability from '../rmAvailability/AddRMAvailability';
import AddPMAvailability from '../pmAvailability/AddPMAvailbility';

import { style } from '../../../../../resources/css/styles';

const styles = style;

class LineItem extends Component {
  state = {
    lineItemDetails: {},
    editLineItemStatus: false,
    openRMAvailabilityDialog: false,
    openPMDialog: false,
  };

  componentDidMount() {
    const data = { ...this.props.match.params, collabType: 'CMO' };
    this.props.getLineItemDetails(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.poLineItemDetails) {
      this.setState({ lineItemDetails: nextProps.poLineItemDetails });
    }
  }

  EditLineItemStatus = () => {
    this.setState({ editLineItemStatus: !this.state.editLineItemStatus });
  };

  openRMAvailabilityDialog = () => {
    this.setState({ openRMAvailabilityDialog: true });
  };

  toggleRMAvailabilityScreen = () => {
    this.setState({ openRMAvailabilityDialog: false });
  };

  openPMDialog = () => {
    this.setState({ openPMDialog: true });
  };
  togglePMScreen = () => {
    this.setState({ openPMDialog: false });
  };
  render() {
    const { classes } = this.props;
    const { params } = this.props.match;
    const { lineItemDetails } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    return (
      <div className={classes.linePoDivBackground}>
        <div className={classes.linePoTitle}>
          <Typography variant='subtitle1'>Purchase Order &gt;</Typography>
          <Typography variant='subtitle1'>
            {params.purchaseOrderNumber} &gt;
          </Typography>
          <Typography variant='subtitle1'>{params.lineItemNumber}</Typography>
        </div>
        <div className={classes.pobackground}>
          <div className={classes.wrapper1}>
            <div className={classes.flexRow}>
              <div className={classes.flexColumn}>
                <Typography variant='subtitle1' className={classes.textTitle}>
                  Purchase Order Number
                </Typography>
                <Typography variant='subtitle2' className={classes.textBold}>
                  {params.purchaseOrderNumber}
                </Typography>
              </div>
              <div className={classes.flexColumn}>
                <Typography variant='subtitle1' className={classes.textTitle}>
                  Purchase Order Date
                </Typography>
                <Typography variant='subtitle2' className={classes.textBold}>
                  {lineItemDetails.poDate
                    ? Moment(new Date(lineItemDetails.poDate)).format(
                        dateFormat
                      )
                    : null}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: '#F2F1F2',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Grid
            item
            md={this.state.editLineItemStatus ? 9 : 10}
            className={classes.wrapper2}
          >
            <Typography
              variant='h6'
              color='secondary'
              style={{ paddingLeft: '1.5rem', fontSize: '1rem' }}
            >
              Product Information
            </Typography>

            <div
              className={classes.flexRow}
              style={{ justifyContent: 'space-between' }}
            >
              <div className={classes.flexColumn}>
                <Typography variant='caption'>Line Number</Typography>
                <Typography variant='body2'>
                  {lineItemDetails.lineNumber}
                </Typography>
              </div>
              <div className={classes.flexColumn}>
                <Typography variant='caption'>Product Code</Typography>
                <Typography variant='body2'>
                  {lineItemDetails.productCode}
                </Typography>
              </div>
              <div className={classes.flexColumn}>
                <Typography variant='caption'>Product Name</Typography>
                <Typography variant='body2'>
                  {lineItemDetails.productName}
                </Typography>
              </div>
              <div className={classes.flexColumn}>
                <Typography variant='caption'>Plant Code</Typography>
                <Typography variant='body2'>
                  {lineItemDetails.CMOPlantCode}
                </Typography>
              </div>
              <div className={classes.flexColumn}>
                <Typography variant='caption'>Units of Measurement</Typography>
                <Typography variant='body2'>
                  {lineItemDetails.unitOfMeasurement}
                </Typography>
              </div>

              <div className={classes.flexColumn}>
                <Typography variant='caption'>Quantity</Typography>
                <Typography variant='body2'>
                  {lineItemDetails.quantity}
                </Typography>
              </div>
              <div className={classes.flexColumn}>
                <Typography variant='caption'>Delivery Date</Typography>
                <Typography variant='body2'>
                  {lineItemDetails.deliveryDate
                    ? Moment(lineItemDetails.deliveryDate).format(dateFormat)
                    : null}
                </Typography>
              </div>
              <div className={classes.flexColumn}>
                <Typography variant='caption'>Commit Date</Typography>
                <Typography variant='body2'>
                  {lineItemDetails.poLineCommitDate
                    ? Moment(new Date(lineItemDetails.poLineCommitDate)).format(
                        dateFormat
                      )
                    : 'Not Specified'}
                </Typography>
              </div>
            </div>
          </Grid>
          <LineItemStatus lineItemStatus={lineItemDetails.statusText} />
        </div>

        <div className={classes.flexRow}>
          <Grid item md={2}>
            <div className={classes.wrapper2}>
              <Typography
                variant='h6'
                color='secondary'
                style={{ paddingLeft: '1.5rem', fontSize: '1rem' }}
              >
                Value Info
              </Typography>

              <div
                className={classes.flexColumn}
                style={{ paddingTop: '0.25rem' }}
              >
                <Typography variant='caption'>Item Value</Typography>
                <Typography variant='body2'>
                  {`${lineItemDetails.currency} ${lineItemDetails.effectiveValue}`}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={classes.wrapper2}>
              <Typography
                variant='h6'
                color='secondary'
                style={{ paddingLeft: '1.5rem', fontSize: '1rem' }}
              >
                Quantities
              </Typography>
              <div className={classes.flexRow}>
                <div className={classes.flexColumn}>
                  <Typography variant='caption'>Produced Quantity</Typography>
                  <Typography variant='body2'>
                    {lineItemDetails.producedQuantity}
                  </Typography>
                </div>
                <div
                  className={classes.flexColumn}
                  style={{ paddingLeft: '2.5rem' }}
                >
                  <Typography variant='caption'>Pending Quantity</Typography>
                  <Typography variant='body2'>
                    {lineItemDetails.pendingQuantity}
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className={classes.wrapper2}>
              <Typography
                variant='h6'
                color='secondary'
                style={{ fontSize: '1rem' }}
              >
                Availability Update
              </Typography>
              <div className={classes.flexRow}>
                <div>
                  <Button
                    variant='contained'
                    color='primary'
                    style={{ fontSize: '0.75rem' }}
                    onClick={this.openRMAvailabilityDialog}
                  >
                    RM Availability
                  </Button>
                </div>
                <div style={{ paddingLeft: '1.5rem' }}>
                  <Button
                    variant='contained'
                    color='primary'
                    style={{ fontSize: '0.75rem' }}
                    onClick={this.openPMDialog}
                  >
                    PM Availability
                  </Button>
                </div>
              </div>
            </div>
            <Dialog fullScreen open={this.state.openRMAvailabilityDialog}>
              <AddRMAvailability
                toggleRMAvailabilityScreen={this.toggleRMAvailabilityScreen}
                params={this.props.match.params}
              />
            </Dialog>
            <Dialog fullScreen open={this.state.openPMDialog}>
              <AddPMAvailability
                togglePMScreen={this.togglePMScreen}
                params={this.props.match.params}
              />
            </Dialog>
          </Grid>
        </div>
      </div>
    );
  }
}
LineItem.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStatetoProps = (state) => ({
  poLineItemDetails: state.pcmp.pharmaCo.purchaseOrder.lineItemDetails,
});
export default withStyles(styles)(
  connect(mapStatetoProps, { getLineItemDetails })(withRouter(LineItem))
);
