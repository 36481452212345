import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CachedIcon from '@material-ui/icons/Cached';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './qualityEventStyles';

import usePrevious from '../../../helpers/previousProps';

import Search from './Search';
import Filters from './Filter';
import QuickSummary from './QuickSummary';
import SelectEventType from './SelectEventType';
import QualityEvents from './QEMDashboardData';
import CreateAnEvent from './createEvent';

import { loadTableData } from '../../../containers/actions/common/fileActions';
import { fetchQMSData } from '../../../containers/actions/common/qualityEventActions';
import { fetchQuickSummary } from '../../../containers/actions/common/qualityEventActions';

const QualityEventIndex = (props) => {
  const classes = useStyles();

  const { collabType, organizationName } = localStorage;
  const [CMOMember, selectCMO] = useState(
    collabType === 'Pharma Co'
      ? //{ organizationName: "All CMOs", organizationId: "all" }
        'all'
      : 'all'
  );

  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [searchQuery, searchQueryInput] = useState('');
  const [tempSearch, tempSearchQuery] = useState('');
  const [qualityEvent, selectQualityEvent] = useState('all');
  //console.log([qualityEvent, selectQualityEvent],"------------------------");
  const [priority, setPriority] = useState('all');
  const [hasBeenSearched, setHasBeenSearched] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState('all');
  const [qualityEventName, setQualityEventName] = useState('All Events');
  // console.log(setQualityEventName,"setQualityEventName")
  //const [limit, setLimit] = useState(5);
  //const [selectedQulaityEvent, setSelectedQualityEvent] = useState('all');
  // console.log(setPriority,"setPriority");
  //for dialogBox in Raise qualityEvent
  const [open, setOpen] = useState(false);
  const [addBOEvent, setAddBOEvent] = React.useState(false);
  const [endPageNumber, setEndPageNumber] = useState(1);
  const [errors, setErrors] = useState({ isError: false, errorMessage: '' });
  //const [openPopup, setOpenPopup] = useState(false);

  const [feedbackDialog, setFeedbackDialog] = useState(false);
  const [eventID, setEventID] = useState('');
  const [assignedTo, setAssignedTo] = useState('');

  let loading = true;
  let [qualityEventsArray, lastPageNumber] = useSelector((state) => {
    if (
      state.pcmp.common.QMS.fetchQMSData.data &&
      state.pcmp.common.QMS.fetchQMSData.status === true
    ) {
      loading = false;
      //setEndPageNumber(state.pcmp.common.QMS.fetchQMSData.numberOfPages);
      return [
        state.pcmp.common.QMS.fetchQMSData.data.qualityEvents,
        state.pcmp.common.QMS.fetchQMSData.data.numberOfPages,
      ];
    }
    if (
      state.pcmp.common.QMS.fetchQMSData.data &&
      state.pcmp.common.QMS.fetchQMSData.status === false
    ) {
      loading = true;
    }
    // if (state.pcmp.common.QMS.addQualityEvent?.status){

    //   setOpenPopup(true);

    // }
    return [[], endPageNumber];
  });

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSelectCMO = (event) => {
    let value =
      event === 'all'
        ? { organizationName: 'All CMOs', organizationId: 'all' }
        : event.target.value;
    selectCMO(value);
    setCurrentPage(1);
    //setEndPageNumber(1)
  };

  const handleSelectEvent = (event) => {
    const eventValue =
      event.target.value === 'all' ? 'all' : event.target.value.eventTypeId;
    selectQualityEvent(eventValue);
    let value =
      event.target.value !== 'all'
        ? event.target.name.find(
            (data) => data.eventTypeId === event.target.value.eventTypeId
          ).eventType
        : 'All Events';
    setQualityEventName(value);
    setCurrentPage(1);
    //setEndPageNumber(1)
  };

  const handlePrioritySelector = (event) => {
    setPriority(event.target.value);
    setCurrentPage(1);
    //setEndPageNumber(1)
  };

  const handleSearchQuery = (event) => {
    let { value } = event.target;
    // tempSearchQury(event.target.value);
    if (value === '' || value === null) {
      tempSearchQuery(value);
      searchQueryInput('');
    } else {
      tempSearchQuery(value);
    }
  };

  const handleClearSearch = (event) => {
    event.preventDefault();
    tempSearchQuery('');
    searchQueryInput('');
    setHasBeenSearched(false);
    setCurrentPage(1);
  };

  const submitSearch = (event) => {
    event.preventDefault();
    if (tempSearch.trim() !== '') {
      searchQueryInput(tempSearch);
      setHasBeenSearched(true);
      setCurrentPage(1);
      //setEndPageNumber(1)
    }
  };

  const handleStartDateChange = (event) => {
    if (event._d.toISOString() > endDate) {
      setErrors({
        ...errors,
        isErrorForStartDate: true,
        errorMessageForStartDate: 'start date should les than end date',
        isErrorForEndDate: false,
        errorMessageForEndDate: null,
      });
    } else {
      setErrors({
        ...errors,
        isErrorForStartDate: false,
        errorMessageForStartDate: null,
        isErrorForEndDate: false,
        errorMessageForEndDate: null,
      });
      setStartDate(event ? event._d.toISOString() : null);
      setCurrentPage(1);
    }
  };

  const handleEndDateChange = (event) => {
    //setEndDate(event._d);
    if (startDate === null || startDate === '') {
      setErrors({
        ...errors,
        isErrorForEndDate: true,
        errorMessageForEndDate: 'Please Enter StartDate',
      });
      setEndDate(event ? event._d.toISOString() : null);
    } else if (startDate > event._d.toISOString()) {
      setErrors({
        ...errors,
        isErrorForEndDate: true,
        errorMessageForEndDate: 'start date should les than end date',
      });
    } else {
      setErrors({
        ...errors,
        isErrorForEndDate: false,
        errorMessageForEndDate: null,
        isErrorForStartDate: false,
        errorMessageForStartDate: null,
      });
      setEndDate(event ? event._d.toISOString() : null);
      setCurrentPage(1);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleClearStartDate = (event) => {
    setStartDate(null);
  };

  const handleClearEndDate = (event) => {
    //setEndDate(event._d);
    setEndDate(null);
  };

  // clear button api calls
  const fetchQUalityEventsDataOnClear = () => {
    // To trigger loading indicator on the table
    dispatch(loadTableData());

    dispatch(
      fetchQMSData({
        viewByCMO: collabType === 'Pharma Co' ? 'all' : organizationName,
        eventTypeID: 'all',
        priority: 'all',
        eventsOccurredFromDate: null,
        eventsOccurredToDate: null,
        searchBy: '',
        viewByStatus: 'all',
        pageNumber: 1,
        pageSize: 5,
      })
    );
    dispatch(
      fetchQuickSummary({
        viewByCMO: collabType === 'Pharma Co' ? 'all' : organizationName,
        eventTypeID: 'all',
        priority: 'all',
        eventsOccurredFromDate: null,
        eventsOccurredToDate: null,
      })
    );

    selectCMO('all');
    setQualityEventName('All Events');
    selectQualityEvent('all');
    setPriority('all');
    setStartDate(null);
    setEndDate(null);
  };

  const closeSelectEventDialog = () => {
    setOpen(false);
  };

  const { addQualityEventResponse } = props;
  const prevAddQualityEventResponse = usePrevious(addQualityEventResponse);
  useEffect(() => {
    if (prevAddQualityEventResponse !== addQualityEventResponse) {
      //setAddBOEvent(false);
      if (addQualityEventResponse.status === true) {
        setFeedbackDialog(true);
        setEventID(addQualityEventResponse.eventID);
        setAssignedTo(addQualityEventResponse.assignedTo);
      }
    }
  }, [addQualityEventResponse, prevAddQualityEventResponse]);

  const feedbackClose = () => {
    setFeedbackDialog(false);
  };
  return (
    <React.Fragment>
      <Grid container alignItems='center'>
        <Grid item xs={12} md={9}>
          {/*<p>Filters over here</p>*/}
          <Filters
            cmoFilter={collabType === 'Pharma Co' ? true : false}
            CMOMember={CMOMember}
            selectCMO={selectCMO}
            qualityEvent={qualityEvent}
            cmoMembersList={[]}
            startDate={startDate}
            endDate={endDate}
            priority={priority}
            qualityEventName={qualityEventName}
            errors={errors}
            setErrors={setErrors}
            handleSelectCMO={handleSelectCMO}
            handleSelectEvent={handleSelectEvent}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            handlePrioritySelector={handlePrioritySelector}
            handleClearStartDate={handleClearStartDate}
            handleClearEndDate={handleClearEndDate}
          />
        </Grid>

        {/*<p>Raise event feature here</p>*/}

        <Grid
          container
          style={{
            paddingTop: '1rem',
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'end',
          }}
          justifyContent='space-between'
          item
          xs={12}
          md={3}
        >
          <div style={{ display: 'flex' }}>
            <div>
              <Button
                onClick={fetchQUalityEventsDataOnClear}
                className={classes.clear}
                style={{
                  height: '2rem',
                  marginTop: '4px',
                  textTransform: 'capitalize',
                  minWidth: '90px',
                  marginRight: '30px',
                }}
              >
                clear
              </Button>
            </div>

            <CreateAnEvent />
          </div>
        </Grid>
      </Grid>

      <Divider className={classes.mgTp} />

      <Grid container alignItems='center'>
        {/*<p>Quick Summary Here</p>*/}
        <Grid item xs={12} sm={6}>
          <Typography
            variant='h6'
            style={{ paddingBottom: '1rem', paddingTop: '1rem' }}
          >
            Quick Summary
          </Typography>
        </Grid>
        {/* Refresh icon */}
        <Grid item xs={12} md={6}>
          <Grid
            container
            className={clsx(
              classes.dpFlx,
              classes.flxEndSmFlxStrt,
              classes.pdTponLr
            )}
          >
            <Typography variant='caption' className={clsx(classes.padR20)}>
              {`Last synced @ ${moment(new Date()).format(
                'HH:mm:ss; DD MMMM, YYYY'
              )}`}
            </Typography>
            <Typography
              variant='caption'
              color='primary'
              /* console to substitute refresh function, need to change */
              className={clsx(
                classes.dpFlx,
                classes.algnCntr,
                classes.pointer,
                classes.onHvrUndrline
              )}
            >
              Refresh
              <CachedIcon color='primary' style={{ fontSize: 15 }} />
            </Typography>
          </Grid>
        </Grid>
        <QuickSummary qualityEventName={qualityEventName} />
      </Grid>

      {/* Search  component here */}
      <Grid container alignItems='center' className={classes.pdTp}>
        <Search
          searchQuery={tempSearch}
          handleSearchQuery={handleSearchQuery}
          handleClearSearch={handleClearSearch}
          submitSearch={submitSearch}
        />
      </Grid>
      {/*<p>Table here</p>*/}
      {/* //<QETable /> */}
      <QualityEvents
        status={status}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handlePageChange={handlePageChange}
        handleStatusChange={handleStatusChange}
        qualityEventsArray={qualityEventsArray}
        lastPageNumber={lastPageNumber}
        event={qualityEvent}
        priority={priority}
        searchQuery={searchQuery}
        CMOMember={CMOMember}
        startDate={startDate}
        endDate={endDate}
        // priority = {priority}
      />
      {/** Add Event */}

      <SelectEventType open={open} onClose={closeSelectEventDialog} />
    </React.Fragment>
  );
};

export default QualityEventIndex;
