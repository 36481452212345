import React from 'react';
import StyledButton from '../../../../common/models/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
// Icons
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  saved: {
    textAlign: 'center',
    padding: '0.5rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  text: {
    textAlign: 'center',
    fontSize: '1rem',
    margin: '1rem',
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '5rem',
    color: '#39811D',
  },
});

const StyledDialog = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <DialogContent>
      <Grid container justify='flex-end'>
        <Typography
          variant='body2'
          className={classes.closeText}
          onClick={props.onClickClose ? props.onClickClose : props.handleClose}
        >
          Close <CloseIcon fontSize='large' />
        </Typography>
        <Grid item xs={12} className={classes.iconGrid}>
          {props.icon === true ? (
            <CancelIcon className={classes.icon} style={{ color: 'red' }} />
          ) : (
            <CheckCircleIcon className={classes.icon} />
          )}
        </Grid>
      </Grid>
      <Typography variant='h5' className={classes.saved}>
        {props.title}
      </Typography>

      <Typography variant='body2' className={classes.text}>
        {props.subtitle}
      </Typography>

      {children}

      <Grid item xs={12} style={{ textAlign: 'center', paddingBottom: '2rem' }}>
        <StyledButton
          style={{ padding: '0.5rem 3rem' }}
          onClick={props.handleClose}
          disabled={props.isButtonDisabled ? props.isButtonDisabled : false}
        >
          OK
        </StyledButton>
      </Grid>
    </DialogContent>
  );
};

export default StyledDialog;
