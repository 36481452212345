import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
//files
import UploadFiles from './UploadFiles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const UpdateGRIndex = (props) => {
  const { open, POLineRecordDetails, row, status, handleClose } = props;
  return (
    <Dialog
      fullScreen
      open={open} //change to false
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <UploadFiles
        POLineRecordDetails={POLineRecordDetails}
        status={status}
        row={row}
        handleClose={handleClose}
      />
    </Dialog>
  );
};
export default UpdateGRIndex;
