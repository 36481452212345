import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Moment from 'moment';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  IconButton,
  Typography,
  Tooltip,
  TablePagination,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import { tableFilter } from '../functionalComponents/TableFilter';

const tableDataFilter = tableFilter;

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: '2rem',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  POOrderBackground: {
    margin: '-1rem -1.3rem 0rem -1.8rem',
    backgroundColor: '#F5F5F5',
    flexDirection: 'row',
    padding: '1.2rem 0.5rem',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      cursor: 'pointer',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
});
const header = [
  { name: 'Status', id: 'statusText' },
  { name: 'Line', id: 'lineNumber' },
  { name: 'PO', id: 'purchaseOrderNumber' },
  { name: 'Product Code', id: 'productCode' },
  { name: 'Product Name', id: 'productName' },
  { name: 'Plant Code', id: 'CMOPlantCode' },
  { name: 'UOM', id: 'unitOfMeasurement' },
  { name: 'Quantity', id: 'quantity' },
  { name: 'Produced Quantity', id: 'producedQuantity' },
  { name: 'Pending Quantity', id: 'pendingQuantity' },
  { name: 'BatchItems Count', id: 'batchItemsCount' },
];

class LineItemsTable extends Component {
  state = {
    lineItems: [],
    page: 0,
    rowsPerPage: 5,
    data: [],
    header,
    displayFilter: false,
    query: '',
    columnToQuery: 'all',
    columnToSort: '',
    sortDirection: 'desc',
    fieldSelected: false,
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  openLineItem = (purchaseOrderNumber, schrockenPOLineID, lineNumber) => {
    const { params } = this.props.match;
    this.props.history.push(
      `/pcmp/${this.props.collabType}/${params.roleName}/${params.organizationName}/${params.ecosystemId}/lineitem/${purchaseOrderNumber}/${schrockenPOLineID}/${lineNumber}`
    );
  };

  reloadLineItems = () => {
    this.props.onRefresh();
    this.setState({ columnToSort: '' });
  };

  render() {
    const { classes, lineItems } = this.props;
    const { page, rowsPerPage } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, lineItems.length - page * rowsPerPage);
    const queryData = orderBy(
      this.state.query
        ? lineItems.filter((data) =>
            tableDataFilter(
              data,
              this.state.columnToQuery,
              this.state.query.toLowerCase(),
              this.state.header
            )
          )
        : lineItems,
      this.state.columnToSort,
      this.state.sortDirection
    );
    return (
      <Paper className={classes.root}>
        <Toolbar>
          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid item xs={8}>
              <Typography variant='subtitle1'>Line Items List</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SearchIcon />
              <InputBase
                placeholder='Search...'
                value={this.state.query}
                name='query'
                onChange={this.handleChange}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </Grid>
          </Grid>
          <Tooltip title='Refresh Table'>
            <IconButton
              aria-label='Refresh Table'
              onClick={this.reloadLineItems}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Table className={classes.table}>
          <TableHead style={{ backgroundColor: '#F5F5F5' }}>
            <TableRow>
              <TableCell>Status </TableCell>
              <TableCell align='left'>Line</TableCell>
              <TableCell align='left'>PO Number</TableCell>
              <TableCell align='left'>Product Code</TableCell>
              <TableCell align='left'>Product Name</TableCell>
              <TableCell align='left'>Plant Code</TableCell>
              <TableCell align='left'>UOM</TableCell>
              <TableCell align='right'>Qty.</TableCell>
              <TableCell align='right'>Qty. Produced</TableCell>
              <TableCell align='right'>Qty. Pending</TableCell>
              <TableCell align='right'>Committed Date</TableCell>
              <TableCell align='right'>Delivery Date</TableCell>
              <TableCell align='right'>Batch (Nos)</TableCell>
            </TableRow>
          </TableHead>
          {!lineItems ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={14} style={{ textAlign: 'center' }}>
                  Loading...
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {queryData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((line, i) => (
                  <TableRow
                    key={i}
                    className={classes.tableRowHover}
                    onClick={() =>
                      this.openLineItem(
                        line.purchaseOrderNumber,
                        line.schrockenPOLineID,
                        line.lineNumber
                      )
                    }
                  >
                    <TableCell align='left'>{line.statusText}</TableCell>
                    <TableCell align='left'>{line.lineNumber}</TableCell>
                    <TableCell align='left'>
                      {line.purchaseOrderNumber}
                    </TableCell>
                    <TableCell align='left'>{line.productCode}</TableCell>
                    <TableCell align='left'>{line.productName}</TableCell>
                    <TableCell align='left'>{line.CMOPlantCode}</TableCell>
                    <TableCell align='left'>{line.unitOfMeasurement}</TableCell>
                    <TableCell align='right'>{line.quantity}</TableCell>
                    <TableCell align='right'>{line.producedQuantity}</TableCell>
                    <TableCell align='right'>{line.pendingQuantity}</TableCell>
                    <TableCell align='right'>
                      {line.poLineCommitDate
                        ? Moment(new Date(line.poLineCommitDate)).format(
                            dateFormat
                          )
                        : 'Not Specified'}
                    </TableCell>
                    <TableCell align='right'>
                      {Moment(line.deliveryDate).format(dateFormat)}
                    </TableCell>
                    <TableCell align='right'>{line.batchItemsCount}</TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={13} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component='div'
          count={!lineItems ? 0 : lineItems.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

LineItemsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(LineItemsTable));
