const StatusMapper = {
    1: 'Scheduled',
    2: 'Under Production',
    3: 'Production Complete',
    4: 'Quarantine',
    5: 'QCReleased',
    6: 'AwaitingGRN',
    7: 'GRNGenerated',
    8: 'Dispatch',
    9: 'GoodsReceived',
    13: 'Record Rejected'
}


export default StatusMapper;