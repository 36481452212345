import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

import usePrevious from '../../../../../../helpers/previousProps';

import Resolve from './Resolve';
import EventDetails from './editEvent/EventDetails';
import RiskAssesment from './editEvent/RiskAssesment';
import FacilityAndLocation from './editEvent/FacilityAndLocation';
import AffectedProduct from './editEvent/AffectedProductAndBatches';
import AssignTeam from './editEvent/AssignTeam';
import UploadDocument from './editEvent/UploadDocument';
import Resolution from './editEvent/Resolution';
import DeleteRecord from './DeleteRecord';
import UpdateEventResponse from './UpdateEventResponse';
import SaveRecord from './SaveRecord';

import {
  eventDetailsByID,
  fetchReportedByList,
  fetchImpactDetails,
  fetchFrequencyDetails,
  fetchLocationDetails,
  fetchFacilityDetails,
  fetchProductDetails,
  fetchBatchDetails,
  fetchSupplierDetails,
  fetchQMSData,
} from '../../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '100%',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
    backgroundColor: '#F5F9FF',
  },
  title: {
    fontWeight: 700,
    padding: '1.5rem 0rem',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
    marginRight: '3rem',
  },

  steeper: {
    width: '40%',
    margin: '0 auto',
    position: 'relative',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const UpdateEventForNew = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const { openDialog, toggleDialog, eventID } = props;
  const [state, setState] = useState({
    eventData: {},
    eventID: eventID,
    // CMO Information
    CMOMember: '',
    CMOMemberOrgId: '',
    qualityEvent: '',
    // Screen 1
    eventTitle: '',
    eventDescription: '',
    eventOccurredDate: null,
    eventReportedDate: null,
    reportedByOptions: [],
    eventStatusID: '',
    selectedReportedBy: '',
    eventType: '',
    eventTypeID: '',
    priority: '',
    incidentRaisedBy: '',
    incidentRaisedByOrgID: '',
    // Screen 2 - Event Information
    impactOptions: [],
    selectedImpact: '',
    frequencyOptions: [],
    selectedFrequency: '',
    locationOptions: [],
    selectedLocation: '',
    facilityOptions: [],
    selectedFacility: '',
    productsList: [],
    selectedProduct: '',
    batchOptions: [],
    selectedBatches: [],
    supplierDataList: [],
    supplierNames: '',
    // Assign Events
    teamOptions: [],
    selectedTeamLead: '',
    selectedTeamMembers: [],
    customFiles: [],
    qeCustomFiles: [],
    resolutionDescription: '',
    estimatedResolutionDate: null,
    // Current state
    loading: true,
    updateEventType: '',
    saveEvent: '',
    updateEventResponseScreen: false,
    eventDeatilsScreen: false,
    riskAssesmentScreen: false,
    facilityAndLocationScreen: false,
    affectedProductScreen: false,
    assignTeamScreen: false,
    uploadDocumentScreen: false,
    resolutionScreen: false,
    deleteRecordScreen: false,
    saveRecordScreen: false,
  });

  const handleChangeData = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggleDialogScreen = (dialogName) => {
    setState((prevState) => ({
      ...prevState,
      [dialogName]: !state[dialogName],
    }));
  };

  const goToDashboard = () => {
    setState((prevState) => ({
      ...prevState,
      updateEventResponseScreen: false,
    }));
    toggleDialog('updateEventForNewScreen');
  };

  const {
    eventDetails,
    reportedByList,
    impactDetails,
    frequencyDetails,
    localtionDetails,
    facilityDetails,
    productsList,
    batchDetails,
    supplierDetails,
    teamMembers,
    updateQualityEvent,
  } = props;
  const prevEventDetails = usePrevious(eventDetails);
  const prevReportedByList = usePrevious(reportedByList);
  const prevImpactDetails = usePrevious(impactDetails);
  const prevFrequencyDetails = usePrevious(frequencyDetails);
  const prevLocaltionDetails = usePrevious(localtionDetails);
  const prevFacilityDetails = usePrevious(facilityDetails);
  const prevProductsList = usePrevious(productsList);
  const prevBatchDetails = usePrevious(batchDetails);
  const prevsupplierDetails = usePrevious(supplierDetails);
  const prevTeamMembers = usePrevious(teamMembers);
  const prevUpdateQualityEvent = usePrevious(updateQualityEvent);
  useEffect(() => {
    if (didMountRef.current) {
      if (eventDetails !== prevEventDetails) {
        const {
          qualityEventDetails,
          affectedBatchesList,
          supplierDataList,
          investigatingTeamLeadDetails,
          investigatingTeamList,
          eventReportedByDetails,
        } = eventDetails.qualityEventRecordDetails;
        setState((prevState) => ({
          ...prevState,
          eventData: eventDetails,
          CMOMember: qualityEventDetails?.incidentFor,
          CMOMemberOrgId: qualityEventDetails?.incidentForOrgID,
          incidentRaisedBy: qualityEventDetails?.incidentRaisedBy,
          incidentRaisedByOrgID: qualityEventDetails?.incidentRaisedByOrgID,
          eventTitle: qualityEventDetails?.eventTitle || '',
          eventDescription: qualityEventDetails?.eventDescription || '',
          eventOccurredDate: qualityEventDetails?.eventOccurredDate || null,
          eventReportedDate: qualityEventDetails?.eventReportedDate || null,
          eventType: qualityEventDetails?.eventType || '',
          eventTypeID: qualityEventDetails?.eventTypeID || '',
          priority: qualityEventDetails?.priority || '',

          selectedReportedBy: eventReportedByDetails
            ? {
                name: eventReportedByDetails.reportedByName,
                saasUserID: eventReportedByDetails.reportedBySaasUserID,
                emailID: eventReportedByDetails.reportedByEmailID,
                roleName: eventReportedByDetails.reportedByRoleName,
              }
            : '',
          selectedImpact: qualityEventDetails?.impactText
            ? {
                impactId: qualityEventDetails?.impact,
                impactType: qualityEventDetails?.impactText,
              }
            : '',
          selectedFrequency: qualityEventDetails?.frequencyText
            ? {
                frequencyId: qualityEventDetails?.frequency,
                frequencyType: qualityEventDetails?.frequencyText,
              }
            : '',
          selectedLocation: qualityEventDetails?.location
            ? { locationName: qualityEventDetails?.location }
            : '',
          selectedFacility: qualityEventDetails?.facility
            ? { FacilityName: qualityEventDetails?.facility }
            : '',
          selectedProduct: qualityEventDetails?.productCode
            ? {
                productCode: qualityEventDetails?.productCode,
                productDescription: qualityEventDetails?.productName,
              }
            : '',
          selectedBatches: affectedBatchesList ? affectedBatchesList : [],
          supplierDataList: supplierDataList ? supplierDataList : [],
          selectedTeamLead: investigatingTeamLeadDetails
            ? {
                emailAddress: investigatingTeamLeadDetails.teamLeadEmailID,
                userId: investigatingTeamLeadDetails.teamLeadSaasUserID,
                roleName: investigatingTeamLeadDetails.teamLeadRoleName,
                userName: investigatingTeamLeadDetails.teamLeadName,
              }
            : '',
          resolutionDescription:
            qualityEventDetails?.resolutionDescription || '',
          estimatedResolutionDate:
            qualityEventDetails?.estimatedResolutionDate || null,
          qeCustomFiles: eventDetails.qeCustomFiles || [],
          loading: false,
        }));

        if (supplierDataList) {
          let supplierNames = '';
          supplierDataList.map((supplier) => {
            supplierNames = supplierNames + supplier.supplierName + ', ';
          });
          supplierNames = supplierNames.slice(0, -2);
          setState((prevState) => ({
            ...prevState,
            supplierNames,
          }));
        }

        if (investigatingTeamList) {
          let selectedTeamMembers = [];
          investigatingTeamList.map((teamMember) => {
            const teamMemberObject = {};
            teamMemberObject.userName = teamMember.name || '';
            teamMemberObject.roleName = teamMember.roleName || '';
            teamMemberObject.emailAddress = teamMember.emailID;
            teamMemberObject.userId = teamMember.saasUserID;

            selectedTeamMembers.push(teamMemberObject);
          });
          setState((prevState) => ({
            ...prevState,
            selectedTeamMembers,
          }));
        }

        props.fetchReportedByList();
        props.fetchImpactDetails();
        props.fetchFrequencyDetails();
        props.fetchLocationDetails({
          organizationId: qualityEventDetails?.incidentForOrgID,
        });

        if (
          qualityEventDetails?.productCode &&
          qualityEventDetails?.productName
        ) {
          props.fetchBatchDetails({
            productCode: qualityEventDetails?.productCode,
            productType: qualityEventDetails?.productName,
          });
        }
      }
      if (reportedByList !== prevReportedByList) {
        setState((prevState) => ({
          ...prevState,
          reportedByOptions: reportedByList,
        }));
      }
      if (impactDetails !== prevImpactDetails) {
        setState((prevState) => ({
          ...prevState,
          impactOptions: impactDetails,
        }));
      }
      if (frequencyDetails !== prevFrequencyDetails) {
        setState((prevState) => ({
          ...prevState,
          frequencyOptions: frequencyDetails,
        }));
      }
      if (localtionDetails !== prevLocaltionDetails) {
        setState((prevState) => ({
          ...prevState,
          locationOptions: localtionDetails,
        }));
      }
      if (facilityDetails !== prevFacilityDetails) {
        setState((prevState) => ({
          ...prevState,
          facilityOptions: facilityDetails,
        }));
      }
      if (productsList !== prevProductsList) {
        setState((prevState) => ({
          ...prevState,
          productsList,
        }));
      }
      if (batchDetails !== prevBatchDetails) {
        setState((prevState) => ({
          ...prevState,
          batchOptions: batchDetails,
        }));
      }
      if (supplierDetails !== prevsupplierDetails) {
        let supplierNames = '';
        supplierDetails.map((supplier) => {
          supplierNames = supplierNames + supplier.supplierName + ', ';
        });
        supplierNames = supplierNames.slice(0, -2);
        setState((prevState) => ({
          ...prevState,
          supplierDataList: supplierDetails,
          supplierNames,
        }));
      }
      if (teamMembers !== prevTeamMembers) {
        setState((prevState) => ({
          ...prevState,
          teamOptions: teamMembers,
        }));
      }
      if (updateQualityEvent !== prevUpdateQualityEvent) {
        setState((prevState) => ({
          ...prevState,
          updateEventResponseScreen: true,
        }));
        if (state.updateEventType === 'deleteRecord') {
          setState((prevState) => ({
            ...prevState,
            deleteRecordScreen: false,
          }));
        }
        if (state.saveEvent === 'saveRecord') {
          setState((prevState) => ({
            ...prevState,
            saveRecordScreen: false,
          }));
        }
        props.fetchQMSData({
          viewByCMO: 'all',
          eventTypeID: 'all',
          priority: 'all',
          eventsOccurredFromDate: null,
          eventsOccurredToDate: null,
          searchBy: '',
          viewByStatus: 'all',
          pageNumber: 1,
          pageSize: 5,
        });
      }
    } else {
      props.eventDetailsByID(eventID);
      didMountRef.current = true;
    }
  }, [
    eventDetails,
    prevEventDetails,
    reportedByList,
    prevReportedByList,
    impactDetails,
    prevImpactDetails,
    frequencyDetails,
    prevFrequencyDetails,
    localtionDetails,
    prevLocaltionDetails,
    facilityDetails,
    prevFacilityDetails,
    productsList,
    prevProductsList,
    batchDetails,
    prevBatchDetails,
    supplierDetails,
    prevsupplierDetails,
    teamMembers,
    prevTeamMembers,
    updateQualityEvent,
    prevUpdateQualityEvent,
  ]);

  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={() => toggleDialog('updateEventForNewScreen')}
        TransitionComponent={Transition}
      >
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <Typography variant='h5' className={classes.title}>
              Edit/Update Record Details
            </Typography>

            <div
              className={classes.closeBtn}
              onClick={() => toggleDialog('updateEventForNewScreen')}
            >
              <Typography
                variant='caption'
                style={{ lineHeight: 'normal', fontWeight: '700' }}
              >
                Cancel
              </Typography>
              <CloseIcon style={{ color: 'red', fontSize: '2.5rem' }} />
            </div>
          </div>
        </div>
        {state.loading ? (
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress thickness='5' />
          </div>
        ) : (
          <Resolve
            stateData={state}
            handleChangeData={handleChangeData}
            toggleDialogScreen={toggleDialogScreen}
          />
        )}
      </Dialog>

      {state.eventDeatilsScreen && (
        <EventDetails
          openDialog={state.eventDeatilsScreen}
          toggleDialog={toggleDialogScreen}
          handleChangeData={handleChangeData}
          stateData={state}
        />
      )}
      {state.riskAssesmentScreen && (
        <RiskAssesment
          openDialog={state.riskAssesmentScreen}
          toggleDialog={toggleDialogScreen}
          handleChangeData={handleChangeData}
          stateData={state}
        />
      )}
      {state.facilityAndLocationScreen && (
        <FacilityAndLocation
          openDialog={state.facilityAndLocationScreen}
          toggleDialog={toggleDialogScreen}
          handleChangeData={handleChangeData}
          stateData={state}
        />
      )}
      {state.affectedProductScreen && (
        <AffectedProduct
          openDialog={state.affectedProductScreen}
          toggleDialog={toggleDialogScreen}
          handleChangeData={handleChangeData}
          stateData={state}
        />
      )}
      {state.assignTeamScreen && (
        <AssignTeam
          openDialog={state.assignTeamScreen}
          toggleDialog={toggleDialogScreen}
          handleChangeData={handleChangeData}
          stateData={state}
        />
      )}
      {state.uploadDocumentScreen && (
        <UploadDocument
          openDialog={state.uploadDocumentScreen}
          toggleDialog={toggleDialogScreen}
          handleChangeData={handleChangeData}
          stateData={state}
        />
      )}
      {state.resolutionScreen && (
        <Resolution
          openDialog={state.resolutionScreen}
          toggleDialog={toggleDialogScreen}
          handleChangeData={handleChangeData}
          stateData={state}
        />
      )}

      {state.deleteRecordScreen && (
        <DeleteRecord
          openDialog={state.deleteRecordScreen}
          toggleDialog={toggleDialogScreen}
          goToDashboard={goToDashboard}
          stateData={state}
          handleChangeData={handleChangeData}
        />
      )}

      {state.updateEventResponseScreen && (
        <UpdateEventResponse
          openDialog={state.updateEventResponseScreen}
          goToDashboard={goToDashboard}
          updateEventType={state.updateEventType}
        />
      )}
      {state.saveRecordScreen && (
        <SaveRecord
          openDialog={state.saveRecordScreen}
          goToDashboard={goToDashboard}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  eventDetails: state.pcmp.common.QMS.fetchEventDetails,
  reportedByList: state.pcmp.common.QMS.fetchReportedByList,
  impactDetails: state.pcmp.common.QMS.fetchImpactDetails,
  frequencyDetails: state.pcmp.common.QMS.fetchFrequencyDetails,
  localtionDetails: state.pcmp.common.QMS.fetchLocationList,
  facilityDetails: state.pcmp.common.QMS.fetchFacilityList,
  productsList: state.pcmp.common.QMS.fetchProductList,
  batchDetails: state.pcmp.common.QMS.fetchBatchList,
  supplierDetails: state.pcmp.common.QMS.fetchSupplierList,
  teamMembers: state.pcmp.common.QMS.fetchTeamMembers,
  addQualityEvent: state.pcmp.common.QMS.addQualityEvent,
  updateQualityEvent: state.pcmp.common.QMS.updateQualityEvent,
});

export default connect(mapStateToProps, {
  eventDetailsByID,
  fetchReportedByList,
  fetchImpactDetails,
  fetchFrequencyDetails,
  fetchLocationDetails,
  fetchFacilityDetails,
  fetchProductDetails,
  fetchBatchDetails,
  fetchSupplierDetails,
  fetchQMSData,
})(UpdateEventForNew);
