import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { scheduledHeader } from './modules';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import CustomTableHeaderCell from '../models/table/CustomTableHeaderCellPagination';
import LinearProgress from '@material-ui/core/LinearProgress';

const SupplierPoTableHeader = (props) => {
  const {
    order,
    orderBy,
    totalLength,
    selected,
    sortingFunction,
    tableLoading,
    handleChange,
  } = props;

  let scheduleDateFlag = useSelector(
    (state) =>
      state.pcmp.common.reports.rmpmGrnFeatures?.data.features
        .scheduledDeliveryDate
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{
            width: '1%',
            maxWidth: 0,
            overflow: 'hidden',
          }}
        />
        <TableCell
          padding='checkbox'
          style={{
            width: '1%',
          }}
        >
          <Checkbox
            checked={totalLength > 0 && selected.length === totalLength}
            onChange={(event) => handleChange(event)}
            indeterminate={selected.length > 0 && selected.length < totalLength}
            inputProps={{ 'aria-labelledby': 'select all' }}
          />
        </TableCell>
        {scheduledHeader(scheduleDateFlag).map((headCell) => (
          <CustomTableHeaderCell
            isSortEnable={headCell.isSortEnable}
            sortingFunction={sortingFunction}
            cellData={headCell}
            order={order}
            orderBy={orderBy}
            key={headCell.id}
            elementStyle={{
              verticalAlign: 'top',
              width: headCell.width ? headCell.width : 'auto',
            }}
          />
        ))}
      </TableRow>
      {tableLoading === true && (
        <TableRow style={{ padding: 0 }}>
          <TableCell colSpan={16} style={{ padding: 0 }}>
            <LinearProgress color='secondary' />
          </TableCell>
        </TableRow>
      )}
    </TableHead>
  );
};

SupplierPoTableHeader.prototypes = {
  tableLoading: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  scheduleDateFlag: PropTypes.bool.isRequired,
};

export default SupplierPoTableHeader;
