import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography, Button } from '@material-ui/core';
import StyledButton from '../../../../common/models/Button';
import Avatar from '@material-ui/core/Avatar';
import ClearIcon from '@material-ui/icons/Clear';

const styles = (theme) => ({
  desc: {
    fontWeight: '500',
  },
  logos: {
    margin: '0.75rem',
  },
  texts: {
    margin: '0.75rem',
    textAlign: 'left',
  },
  outside: {
    border: '1px solid #707070',
  },
  notExist: {
    padding: '1rem',
    border: '2px dotted #707070',
  },
  avatars: {
    color: 'white',
    fontWeight: '600',
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  textPadding: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  textDisplay: {
    display: 'inline',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
});

const colorScheme = {
  0: '#FF0000',
  1: '#008002',
  2: '#D0AA2B',
  3: '#1572A3',
};

const MultipleUsers = (props) => {
  const { classes, usersList, sendInvitationToAll, disableInvite } = props;
  return (
    <Fragment>
      {usersList.length ? (
        usersList.map((users, keyVal) => (
          <Grid container key={keyVal} className={classes.textPadding}>
            <Grid container className={classes.outside} alignItems='center'>
              <Grid item xs={4} sm={2} md={1} lg={1} className={classes.logos}>
                <Avatar
                  className={classes.avatars}
                  style={{
                    backgroundColor: colorScheme[keyVal]
                      ? colorScheme[keyVal % 3 ? 2 : 0]
                      : colorScheme[keyVal % 5 ? 1 : 3],
                  }}
                >
                  {users.fullName
                    .split(' ')
                    .map((val) => {
                      if (val[0] !== undefined) {
                        return val[0].toUpperCase();
                      }
                      return '';
                    })
                    .join('')}
                </Avatar>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8} className={classes.texts}>
                <Typography variant='body2'>({users.userOrgType})</Typography>
                <Typography variant='body2' className={classes.desc}>
                  {users.fullName}
                  {/* {users.name} */}
                </Typography>
                <Typography
                  variant='body2'
                  className={`${classes.desc} ${classes.textDisplay}`}
                >
                  Email:&nbsp;
                </Typography>
                <Typography variant='body2' style={{ display: 'inline' }}>
                  {users.email}&nbsp;|&nbsp;
                </Typography>
                <Typography
                  variant='body2'
                  className={`${classes.desc} ${classes.textDisplay}`}
                >
                  Type:
                </Typography>
                <Typography variant='body2' style={{ display: 'inline' }}>
                  &nbsp;{users.collaboratorTypeName}&nbsp;|&nbsp;
                  {/* {users.type} | */}
                </Typography>
                <Typography
                  variant='body2'
                  className={`${classes.desc} ${classes.textDisplay}`}
                >
                  Role:
                </Typography>
                <Typography variant='body2' style={{ display: 'inline' }}>
                  &nbsp;{users.collaboratorRoleName}
                </Typography>
                {users.organizationName && (
                  <React.Fragment>
                    <Typography
                      variant='body2'
                      className={`${classes.desc} ${classes.textDisplay}`}
                    >
                      &nbsp;|&nbsp;Organization:
                    </Typography>
                    <Typography variant='body2' style={{ display: 'inline' }}>
                      &nbsp;{users.organizationName}
                    </Typography>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <Button
                  onClick={() => props.removeUser(users.email)}
                  disableRipple={true}
                  style={{ backgroundColor: 'transparent' }}
                >
                  <ClearIcon fontSize='large' color='error' />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid container className={classes.textPadding}>
          <Grid container className={classes.notExist}>
            <Typography variant='body2' style={{ display: 'inline' }}>
              No one added to the list yet. Please add at least 1 person’s
              details to send invitation(s).
            </Typography>
          </Grid>
        </Grid>
      )}
      {usersList.length > 0 && (
        <StyledButton
          style={{
            backgroundColor: disableInvite === true ? '#0000001f' : '#1572A3',
            margin: '1rem',
          }}
          onClick={sendInvitationToAll}
          disabled={disableInvite}
        >
          Send Invitation to All
        </StyledButton>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(MultipleUsers);
