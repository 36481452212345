import axios from 'axios';
import { appUrl } from '../../../../../utils/config';

import {
  CMO_ADD_RM_AVAILABILITY,
  CMO_FETCH_RM_AVAILABILITY,
  CMO_RM_STATUS_LIST,
  CMO_ADD_PM_AVAILABILITY,
  CMO_FETCH_PM_AVAILABILITY,
  // CMO_FETCH_PM_STATUSES,
  CMO_UPDATE_PM,
  CMO_UPDATE_RM,
} from '../types';

export const cmoAddRMAvailability = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${data.roleName}/addRMAvailability`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_ADD_RM_AVAILABILITY,
          payload: res.data.status,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const cmoFetchRMAvailability = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${data.roleName}/fetchRMAvailability`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_FETCH_RM_AVAILABILITY,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const cmoFetchRMStatusList = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${data.roleName}/fetchRMPMStatus`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_RM_STATUS_LIST,
          payload: res.data.RMPMStatus,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const cmoUpdateRMAvailability = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${data.roleName}/updateRMAvailability`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_UPDATE_RM,
          payload: res.data.status,
        });
      }
    })
    .catch((err) => console.log(err));
};

// Package material actions
export const cmoAddPMAvailability = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${data.roleName}/addPMAvailability`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_ADD_PM_AVAILABILITY,
          payload: res.data.status,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const cmoFetchPMAvailability = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${data.roleName}/fetchPMAvailability`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_FETCH_PM_AVAILABILITY,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

// export const cmoFetchPMStatuses = data => dispatch => {
//   axios
//     .post(
//       `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${
//         data.roleName
//       }/fetchPMStatus`,
//       data
//     )
//     .then(res => {
//       if (res.data.status) {
//         dispatch({
//           type: CMO_FETCH_PM_STATUSES,
//           payload: res.data.PMStatus
//         });
//       }
//     })
//     .catch(err => console.log(err));
// };

export const cmoUpdatePMAvailability = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${data.roleName}/updatePMAvailability`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_UPDATE_PM,
          payload: res.data.status,
        });
      }
    })
    .catch((err) => console.log(err));
};
