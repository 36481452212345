import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../../../../common/models/Button';
import ShowFiles from './ShowFiles';
import Dialog from '@material-ui/core/Dialog';
import DuplicateFilePrompt from './DuplicateFilePrompt';
import DropFilesAttach from '../models/files/DropFilesAttach';
import InputNumber from '../models/inputs/InputNumber';

const useStyles = makeStyles((theme) => ({
  font: {
    fontWeight: 500,
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  padding: {
    paddingBottom: '2rem',
  },
}));

const OtherInfo = (props) => {
  const classes = useStyles();
  const {
    POBatchRecordDetails,

    handleChange,
    isAddRecord,
    //parent
    parentHandlingDeleteFile,
    OtherInfoTempNumber,
  } = props;

  const [otherInformationRemarks, setOtherInformation] = React.useState(
    POBatchRecordDetails.otherInformationRemarks
  );
  const [otherFiles, setOtherFiles] = React.useState(
    POBatchRecordDetails.otherFiles
  );

  //file append
  const [tempNumber, setTempNumber] = React.useState(OtherInfoTempNumber);
  const [localTempNumber, setLocalTempNumber] =
    React.useState(OtherInfoTempNumber);

  const [dupFilePrompt, setDupFilePrompt] = React.useState(false);

  const handleChangeInfo = (e) => {
    setOtherInformation(e.target.value);
  };

  const filePrompt = () => {
    setDupFilePrompt(true);
  };

  const handleFileCopy = () => {
    setDupFilePrompt(false);
  };

  const handleClose = () => {
    setDupFilePrompt(false);
  };

  const localFileHandling = (files) => {
    console.log(files);
    files.forEach((file) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        let newFileName = file.name;

        let counter = otherFiles
          .map((file) => file.grFileName)
          .indexOf(file.name);

        if (counter > -1) {
          filePrompt();
          // counter > -1 means the name is already taken
          // We split with . to deal with the extension
          newFileName = `${file.name.split('.')[0]}_${localTempNumber + 1}.${
            file.name.split('.')[1]
          }`;
          setLocalTempNumber(localTempNumber + 1);
        }

        // Do whatever you want with the file contents
        const fileData = {
          grFileData: reader.result,
          grFileName: newFileName,
          grFileType: 'buffer',
          schrockenGRFileId: '',
          grFileHash: '',
          markForDelete: false,
        };
        setOtherFiles((prevFiles) => [...prevFiles, fileData]);
      };
    });
  };

  const handleSaveInfo = () => {
    let data = {
      ...POBatchRecordDetails,
      otherInformationRemarks,
      otherFiles,
    };
    props.handleSaveOtherInformationData(data, localTempNumber);
  };

  const fileHandling = (files) => {
    console.log(files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        let newFileName = file.name;

        let counter = POBatchRecordDetails.otherFiles
          .map((file) => file.grFileName)
          .indexOf(file.name);

        if (counter > -1) {
          filePrompt();
          // counter > -1 means the name is already taken
          // We split with . to deal with the extension
          newFileName = `${file.name.split('.')[0]}_${tempNumber + 1}.${
            file.name.split('.')[1]
          }`;

          props.handleTempNumber('OtherInfoTempNumber');
          setTempNumber(tempNumber + 1);
        }

        // Do whatever you want with the file contents
        const newArr = {
          grFileData: reader.result,
          grFileName: newFileName,
          grFileType: 'buffer',
          schrockenGRFileId: '',
          grFileHash: '',
          markForDelete: false,
        };

        props.handlingFiles(newArr, 'otherFiles');
      };
    });
  };

  const handlingDeleteFile = (i) => {
    // for rec fromBE=> mfd as 1, for local filter out
    //check for filetype in file
    //if 'buffer' then follow filter logic
    // if it is not buffer edit that file data sayn mfd 1
    if (
      window.confirm(
        'You are about to delete a file ,would you like to proceed ?'
      )
    ) {
      if (otherFiles[i].grFileType === 'buffer') {
        let newdel = otherFiles.filter(
          (file) => file.grFileName !== otherFiles[i].grFileName
        );
        setOtherFiles(newdel);
      } else {
        // otherFiles[i].markForDelete=1;
        let markDelFile = otherFiles.map((file) =>
          file.grFileHash === otherFiles[i].grFileHash
            ? { ...file, markForDelete: true }
            : file
        );
        setOtherFiles(markDelFile);
      }
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        justify='flex-start'
        spacing={8}
        className={classes.padding}
        style={{ paddingTop: isAddRecord && '4rem' }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant='body2' className={classes.font}>
            Additional Remarks (optional)
          </Typography>
          <br />
          <InputNumber
            multiline
            rows={10}
            type='text'
            style={{ width: '100%' }}
            name='otherInformation'
            value={
              isAddRecord !== true
                ? POBatchRecordDetails.otherInformationRemarks
                : otherInformationRemarks
            }
            onChange={
              isAddRecord !== true
                ? (e) => handleChange(e, 'otherInformationRemarks')
                : (e) => handleChangeInfo(e)
            }
            placeholder='Enter Additional Remarks, if any'
          />
        </Grid>
      </Grid>

      <Grid container justify='center'>
        <Grid item xs={12} sm={12} md={12}>
          <DropFilesAttach
            // title='Additional File(s) (optional)'
            handleUploadFiles={
              isAddRecord !== true
                ? (files) => fileHandling(files)
                : (files) => localFileHandling(files)
            }
          />
          <Typography variant='body2' className={classes.font}>
            Additional File(s) (optional)
          </Typography>
        </Grid>
      </Grid>

      {!isAddRecord && (
        <Grid container className={classes.padding}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='body2' className={classes.font}>
              Uploaded Document List
            </Typography>

            <ShowFiles
              files={POBatchRecordDetails.otherFiles}
              handlingDeleteFile={parentHandlingDeleteFile}
              name='otherFiles'
              isUploadFile={true}
            />
          </Grid>
        </Grid>
      )}

      {isAddRecord && (
        <Grid container className={classes.padding}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='body2' className={classes.font}>
              Uploaded Document List
            </Typography>

            <ShowFiles
              files={otherFiles}
              handlingDeleteFile={handlingDeleteFile}
              isUploadFile={true}
            />
          </Grid>
        </Grid>
      )}

      {isAddRecord && (
        <Grid container className={classes.padding}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ textAlign: 'center', paddingTop: '1rem' }}
          >
            <StyledButton
              onClick={handleSaveInfo}
              style={{ padding: '0.25rem 2rem' }}
            >
              Save
            </StyledButton>
          </Grid>
        </Grid>
      )}
      <Dialog open={dupFilePrompt} fullWidth>
        <DuplicateFilePrompt
          handleYesButton={handleFileCopy}
          handleClose={handleClose}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default OtherInfo;
