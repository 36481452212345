import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreateIcon from '@material-ui/icons/Create';
import usePrevious from '../../../../../helpers/previousProps';

import { addQualityEvent } from '../../../../../containers/actions/common/qualityEventActions';
import Logo from '../../QEMDashboardData/Logo';
const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    fontWeight: 500,
  },
  common: {
    fontSize: '15px',
    textAlign: 'left',
    fontWeight: 500,
    marginBottom: '0.4rem',
    marginTop: '0.2rem',
  },

  hrLine: {
    backgroundColor: 'grey',
    width: '3rem',
    height: '2px',
    marginInlineStart: 'inherit',
  },
  backBtn: {
    height: '2rem',
  },
  hr: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  createBtn: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    borderRadius: '2.5rem',
    backgroundColor: 'green',
    textTransform: 'capitalize',
  },
  arrowIcon: {
    fontSize: 'small',
    paddingTop: '0.4rem',
  },
  previewData: {
    fontSize: '450',
    fontSize: '18px',
  },
}));

const Review = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const { stateData } = props;
  const [state, setState] = useState({
    loading: false,
  });

  const handleCreateEvent = () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const { collabType } = localStorage;
    const tokenData = jwtDecode(localStorage.getItem('authToken'));

    const investigatingTeamList = [];
    stateData.selectedTeamMembers.map((teamMember) => {
      const teamMemberObject = {};
      teamMemberObject.name = teamMember.userName || '';
      teamMemberObject.roleName = teamMember.roleName || '';
      teamMemberObject.emailID = teamMember.emailAddress;
      teamMemberObject.saasUserID = teamMember.userId;

      investigatingTeamList.push(teamMemberObject);
    });

    const affectedBatchesList = [];
    stateData.selectedBatches.map((batch) => {
      const batchObject = {};
      batchObject.batchNumber = batch.batchNumber;

      affectedBatchesList.push(batchObject);
    });

    const data = {
      qualityEventDetails: [
        {
          qualityEventDetails: {
            incidentRaisedBy: localStorage.organizationName,
            incidentRaisedByOrgID:
              collabType === 'CMO'
                ? tokenData.organization_id
                : stateData.CMOMember.organizationId,
            // CMO Information
            incidentFor: stateData.CMOMember.organizationName,
            incidentForOrgID: stateData.CMOMember.organizationId,
            eventType: stateData.qualityEvent.eventType,
            eventTypeID: stateData.qualityEvent.eventTypeId,
            eventTypeAbbreviation: stateData.qualityEvent.eventTypeAbbreviation,
            // Screen 1
            eventTitle: stateData.eventTitle,
            eventDescription: stateData.eventDescription,
            eventOccurredDate: stateData.eventOccurredDate,
            eventReportedDate: stateData.eventReportedDate,

            // Screen 2
            impact: stateData.selectedImpact?.impactType,
            frequency: stateData.selectedFrequency?.frequencyType,
            location: stateData.selectedLocation?.locationName,
            facility: stateData.selectedFacility?.FacilityName,
            productCode: stateData.selectedProduct?.productCode,
            productName: stateData.selectedProduct?.productDescription,
            // Screen 3
            customFiles: stateData.customFiles,
            resolutionDescription: stateData.resolutionDescription,
            estimatedResolutionDate: stateData.estimatedResolutionDate,
            // General Data
            eventStatusID: 2,
            eventStatusText: 'New',
          },
          eventReportedByDetails: {
            reportedByName: stateData.selectedReportedBy?.name || '',
            reportedBySaasUserID: stateData.selectedReportedBy?.saasUserID,
            reportedByEmailID: stateData.selectedReportedBy?.emailID,
            reportedByRoleName: stateData.selectedReportedBy?.roleName || '',
          },

          // Screen 2
          affectedBatchesList: affectedBatchesList,
          supplierDataList: stateData.supplierDataList,
          // Screen 3
          investigatingTeamLeadDetails: {
            teamLeadEmailID: stateData.selectedTeamLead.emailAddress,
            teamLeadSaasUserID: stateData.selectedTeamLead.userId,
            teamLeadRoleName: stateData.selectedTeamLead.roleName || '',
            teamLeadName: stateData.selectedTeamLead.userName || '',
          },
          investigatingTeamList: investigatingTeamList,
        },
      ],
    };
    props.addQualityEvent(data);
  };

  return (
    <div style={{ position: 'relative' }}>
      {state.loading && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            width: '100%',
            backdropFilter: 'blur(0.8px)',
            zIndex: 9,
            top: 0,
          }}
        >
          <CircularProgress style={{ marginTop: '60%' }} thickness='5' />
        </div>
      )}
      <Box width='65%' margin='auto'>
        <Box
          display='flex'
          justifyContent='space-between'
          marginBottom='1.5rem'
        >
          <Box>
            <Typography variant='h5' className={classes.title}>
              {stateData.qualityEvent.eventType + ' ' + `Details`}
            </Typography>
            <hr className={classes.hrLine} />
          </Box>

          <Box display='flex'>
            <Button
              variant='outlined'
              onClick={() => props.handleScreenChange(0)}
              className={classes.backBtn}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </Box>
        </Box>
        <Box>
          <Typography className={classes.common}>Title</Typography>
          <Typography className={classes.previewData}>
            {stateData?.eventTitle}
          </Typography>
        </Box>
        <Box marginTop='1.5rem'>
          <Typography className={classes.common}>Description</Typography>
          <Typography className={classes.previewData}>
            {stateData?.eventDescription}
          </Typography>
        </Box>

        <Box
          width='91%'
          marginTop='1.5rem'
          display='flex'
          justifyContent='space-between'
        >
          <Box>
            <Typography className={classes.common}>
              Date event occurred
            </Typography>
            <Typography className={classes.previewData}>
              {moment(stateData?.eventOccurredDate).format('DD-MM-YYYY')}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.common}>
              Date event reported
            </Typography>
            <Typography className={classes.previewData}>
              {moment(stateData?.eventReportedDate).format('DD-MM-YYYY')}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.common}>Reported By</Typography>

            <Typography
              style={{
                color: 'dodgerblue',
                display: 'flex',
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  width: '2rem',
                  textAlign: 'center',
                }}
              >
                <Logo name={stateData.selectedReportedBy?.name} />
              </div>
              <u style={{ marginLeft: '1rem' }}>
                {stateData.selectedReportedBy?.name}
              </u>
            </Typography>
          </Box>
        </Box>
        <hr className={classes.hr} />
        <Box>
          <Box
            display='flex'
            justifyContent='space-between'
            marginBottom='1.5rem'
          >
            <Box>
              <Typography variant='h5' className={classes.title}>
                Risk Assessment
              </Typography>
              <hr className={classes.hrLine} />
            </Box>
            <Box display='flex'>
              <Button
                variant='outlined'
                onClick={() => props.handleScreenChange(1)}
                className={classes.backBtn}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Box>
          </Box>
          <Box width='80%' display='flex' justifyContent='space-between'>
            <Box>
              <Typography className={classes.common}>Impact</Typography>
              <Typography className={classes.previewData}>
                {stateData.selectedImpact?.impactType}
              </Typography>
            </Box>
            <Box width='50%'>
              <Typography className={classes.common}>Frequency</Typography>
              <Typography className={classes.previewData}>
                {stateData.selectedFrequency?.frequencyType}
              </Typography>
            </Box>
          </Box>
        </Box>
        <hr className={classes.hr} />
        <Box>
          <Box
            display='flex'
            justifyContent='space-between'
            marginBottom='1.5rem'
          >
            <Box>
              <Typography variant='h5' className={classes.title}>
                Facility and location details
              </Typography>
              <hr className={classes.hrLine} />
            </Box>

            <Box display='flex'>
              <Button
                variant='outlined'
                onClick={() => props.handleScreenChange(1)}
                className={classes.backBtn}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Box>
          </Box>
          <Box width='80%' display='flex' justifyContent='space-between'>
            <Box>
              <Typography className={classes.common}>Facility</Typography>
              <Typography className={classes.previewData}>
                {stateData.selectedFacility?.FacilityName}
              </Typography>
            </Box>
            <Box width='50%'>
              <Typography className={classes.common}>Location</Typography>
              <Typography className={classes.previewData}>
                {stateData.selectedLocation?.locationName}
              </Typography>
            </Box>
          </Box>
        </Box>
        <hr className={classes.hr} />

        <Box
          display='flex'
          justifyContent='space-between'
          marginBottom='1.5rem'
        >
          <Box>
            <Typography variant='h5' className={classes.title}>
              Affected product and batches
            </Typography>
            <hr className={classes.hrLine} />
          </Box>
          <Box display='flex'>
            <Button
              variant='outlined'
              onClick={() => props.handleScreenChange(1)}
              className={classes.backBtn}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </Box>
        </Box>

        <Grid display='flex' justifyContent='space-between' container>
          <Box>
            <Typography className={classes.common}>Product effected</Typography>
            <Typography className={classes.previewData}>
              {stateData.selectedProduct?.productCode}/
              {stateData.selectedProduct?.productDescription}
            </Typography>
          </Box>

          <Box width='18%'>
            <Typography className={classes.common}>Batch effected</Typography>
            <Typography className={classes.previewData}>
              {stateData.selectedBatches.map((data, i) => (
                <p>{data.batchNumber}</p>
              ))}
            </Typography>
          </Box>
          <Box width='20%'>
            <Typography className={classes.common}>
              Effected supplier
            </Typography>
            <Typography className={classes.previewData}>
              {stateData.supplierDataList.length > 0 ? (
                <>
                  {stateData.supplierDataList.map((data, i) => (
                    <p>{data.supplierName}</p>
                  ))}
                </>
              ) : (
                'NA'
              )}
            </Typography>
          </Box>
        </Grid>
        <hr className={classes.hr} />
        <Box>
          <Box
            display='flex'
            justifyContent='space-between'
            marginBottom='1.5rem'
          >
            <Box>
              <Typography variant='h5' className={classes.title}>
                Assign team for investigation
              </Typography>
              <hr className={classes.hrLine} />
            </Box>

            <Box display='flex'>
              <Button
                variant='outlined'
                onClick={() => props.handleScreenChange(2)}
                className={classes.backBtn}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Box>
          </Box>
          <Box width='80%' display='flex' justifyContent='space-between'>
            <Box width='50%'>
              <Typography className={classes.common}>
                Assigned team lead
              </Typography>
              <Typography
                className={classes.previewData}
                style={{
                  color: 'dodgerblue',
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    width: '2rem',
                    textAlign: 'center',
                  }}
                >
                  <Logo name={stateData.selectedTeamLead.userName} />
                </div>
                <u style={{ marginLeft: '1rem' }}>
                  {stateData.selectedTeamLead.userName}(
                  {stateData.selectedTeamLead.roleName})
                </u>
              </Typography>
            </Box>
            <Box width='50%'>
              <Typography className={classes.common}>
                Assigned team member
                {stateData.selectedTeamMembers.map((name, i) => (
                  <Typography
                    className={classes.previewData}
                    style={{ color: 'dodgerblue', display: 'flex' }}
                  >
                    <div
                      style={{
                        alignItems: 'center',
                        width: '2rem',
                        textAlign: 'center',
                      }}
                    >
                      <Logo name={name?.userName} />
                    </div>
                    <u style={{ marginLeft: '1rem', marginBottom: '0.5rem' }}>
                      {name?.userName}
                    </u>
                  </Typography>
                ))}
              </Typography>
            </Box>
          </Box>
        </Box>
        <hr className={classes.hr} />
        <Box>
          <Box
            display='flex'
            justifyContent='space-between'
            marginBottom='1.5rem'
          >
            <Box>
              <Typography variant='h5' className={classes.title}>
                Upload related documents
              </Typography>
              <hr className={classes.hrLine} />
            </Box>

            <Box display='flex'>
              <Button
                variant='outlined'
                onClick={() => props.handleScreenChange(2)}
                className={classes.backBtn}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Box>
          </Box>
          <Box>
            <Typography className={classes.common}>
              Upload documents list
            </Typography>
            {stateData.customFiles.length > 0 ? (
              <>
                {stateData.customFiles.map((customFile, i) => (
                  <Typography
                    className={classes.previewData}
                    style={{ color: 'blue' }}
                    key={i}
                  >
                    <u>{customFile.name}</u>
                  </Typography>
                ))}
              </>
            ) : (
              'NA'
            )}
          </Box>
        </Box>
        <hr className={classes.hr} />
        <Box>
          <Box
            display='flex'
            justifyContent='space-between'
            marginBottom='1.5rem'
          >
            <Box>
              <Typography variant='h5' className={classes.title}>
                Resolution and action
              </Typography>
              <hr className={classes.hrLine} />
            </Box>

            <Box display='flex'>
              <Button
                variant='outlined'
                onClick={() => props.handleScreenChange(2)}
                className={classes.backBtn}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Box>
          </Box>

          <Box width='80%' display='flex' justifyContent='space-between'>
            <Box>
              <Typography className={classes.common}>
                Description of the action
              </Typography>
              <Typography className={classes.previewData}>
                {stateData.resolutionDescription ? (
                  <> {stateData.resolutionDescription}</>
                ) : (
                  'NA'
                )}
              </Typography>
            </Box>
            <Box width='50%'>
              <Typography className={classes.common}>
                Estimate resolution date
              </Typography>
              <Typography>
                {moment(stateData?.estimatedResolutionDate).format(
                  'DD-MM-YYYY'
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          marginTop='6rem'
          width='60%'
          marginBottom='4rem'
        >
          <Box display='flex'>
            <Typography
              variant='subtitle1'
              onClick={() => props.handleScreenChange(2)}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontWeigh: '700',
                display: 'inline-flex',
              }}
            >
              <ArrowBackIosIcon className={classes.arrowIcon} />{' '}
              <span> Back</span>
            </Typography>
          </Box>
          <Box width='40%' textAlign='center'>
            <Button
              className={classes.createBtn}
              variant='contained'
              color='primary'
              endIcon={<NavigateNextIcon />}
              onClick={handleCreateEvent}
            >
              Create event
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  reportedByList: state.pcmp.common.QMS.fetchReportedByList,
});

export default connect(mapStateToProps, {
  addQualityEvent,
})(Review);
