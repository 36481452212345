import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStyles } from '../../grnReceipts/grnStyles';

const StatusFilter = (props) => {
  const { handleSelectStatus, status, statusArray } = props;
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} fullWidth>
      <Select
        labelId='cmo-filter'
        id='cmo-filter-id'
        value={status}
        onChange={handleSelectStatus}
        variant='outlined'
        classes={{
          outlined: classes.outlined,
        }}
        MenuProps={{
          PaperProps: {
            style: {
              height: statusArray.length > 1 ? '27%' : '8%',
              overflowY: statusArray.length > 1 ? 'scroll' : 'hidden',
            },
          },
        }}
      >
        <MenuItem value='all'>All</MenuItem>
        {statusArray.map((dsStatus, index) => {
          return (
            <MenuItem key={index} value={dsStatus.statusID}>
              {dsStatus.statusDisplayName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default StatusFilter;
