import { Grid, Radio, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { editPMTableData } from '../../../../../containers/actions/common/saslAction';
import { useStyles } from '../styles';
import AvailabilityTable from './AvailabilityTable';

const PMSection = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { initialPM, pmAvailability, handlePMChange, pmTableData, batchCount } =
    props;

  const handlePMDataChange = (value) => {
    if (pmAvailability === 'no') {
      dispatch(editPMTableData(value));
    }
  };

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        // style={{ paddingTop: '1rem' }}
        // className={classes.paddingBottom}
      >
        <Typography variant='body2' className={classes.title}>
          Packaging Material Availability
        </Typography>
        <span className={classes.span}></span>
      </Grid>
      {localStorage.collabType !== 'Pharma Co' && initialPM ? (
        <Grid container>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            // className={classes.sidePadding}
          >
            <Typography variant='body2' className={classes.font}>
              Is the required quantity of packaging materials (Primary &
              Secondary) available for this line item?
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            // className={classes.sidePadding}
          >
            <Radio
              className={classes.radio}
              checked={pmAvailability === 'yes'}
              onChange={handlePMChange}
              value='yes'
              name='pm-radio-button'
            />
            <span style={{ fontWeight: 500, paddingRight: '3rem' }}>Yes</span>
            <Radio
              className={classes.radio}
              checked={pmAvailability === 'no'}
              onChange={handlePMChange}
              value='no'
              name='pm-radio-button'
            />
            <span style={{ fontWeight: 500 }}>No</span>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            className={classes.sidePadding}
          >
            <Typography variant='caption' color='primary'>
              Note: Click "No" to update the availability status
            </Typography>
          </Grid>
          {pmAvailability && (
            <AvailabilityTable
              batchCount={batchCount}
              data={pmTableData}
              handleDataChange={handlePMDataChange}
              availability={pmAvailability}
            />
          )}
        </Grid>
      ) : initialPM ? (
        <Grid container>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            className={classes.sidePadding}
          >
            <Typography
              variant='body2'
              className={classes.font}
              style={{ paddingRight: '10rem' }}
            >
              RM/PM Availability status has not been updated yet.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <AvailabilityTable
          batchCount={batchCount}
          data={pmTableData}
          handleDataChange={handlePMDataChange}
          availability={pmAvailability}
        />
      )}
    </React.Fragment>
  );
};

export default PMSection;
