import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import TopBar from './TopBar';
import CardOne from '../../common/dashboard/BatchSummery';
import CardTwo from '../../common/dashboard/LineItemSummery';
import CardThree from '../../common/dashboard/Summery';
import BatchTable from '../../common/dashboard/BatchTable';
import LineTable from '../../common/dashboard/LineTable';

import axios from 'axios';
import { appUrl } from '../../../../../utils/config';

import {
  getBatchSummery,
  lineSummery,
  summery,
  batchSummarySource,
  lineSummarySource,
  getBatchSummarySource,
  summarySource
} from '../../../containers/actions/common/dashboardAction';

const date = new Date();

class index extends Component {
  state = {
    selectedDate: date,
    currentYear: date.getFullYear(),
    currentMonthNumber: date.getMonth() + 1,
    tableType: 'batch',
    cardIndex: 0,
    rowNumber: 0,
    tableTitle: '',
    tableSubtitle: 'Total Batches',
    CMSName: '',
    summaryData: false,
    lineItemSummaryData: false,
    batchSummeryData: false,
    batchTableData: false,
  };

  componentDidMount() {
    this.getCardsDetails();
    let data = {
      ...this.props.match.params,
      collabType: 'CMO',
    };
    axios
      .post(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${data.roleName}/getCMSName`,
        data
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ CMSName: res.data.CMSName });
        }
      });
    // this.props.viewApplicationSettings(data);
  }

  componentWillUnmount() {
    if (batchSummarySource) {
      batchSummarySource.cancel('leaving page')
    }
    if (lineSummarySource) {
      lineSummarySource.cancel('leaving page')
    }
    if (getBatchSummarySource) {
      getBatchSummarySource.cancel('leaving page')
    }
    if (summarySource) {
      summarySource.cancel('leaving page')
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.summaryData !== this.props.summaryData) {
      this.setState({ summaryData: nextProps.summaryData });
    }
    if (nextProps.lineItemSummaryData !== this.props.lineItemSummaryData) {
      this.setState({ lineItemSummaryData: nextProps.lineItemSummaryData });
    }
    if (nextProps.batchSummeryData !== this.props.batchSummeryData) {
      this.setState({ batchSummeryData: nextProps.batchSummeryData });
    }
    if (nextProps.batchTableData !== this.props.batchTableData) {
      this.setState({ batchTableData: nextProps.batchTableData });
    }
  }

  componentDidUpdate(nextProps, prevState) {
    if (
      this.state.currentMonthNumber !== prevState.currentMonthNumber ||
      this.state.currentYear !== prevState.currentYear
    ) {
      this.getCardsDetails();
      this.setState({
        summaryData: false,
        lineItemSummaryData: false,
        batchSummeryData: false,
      });
    }
  }

  getCardsDetails = () => {
    let data = {
      ...this.props.match.params,
      collabType: 'CMO',
      currentYear: this.state.currentYear,
      currentMonthNumber: this.state.currentMonthNumber,
      CMOMember: this.props.match.params.organizationName,
    };
    this.props.getBatchSummery(data);
    this.props.lineSummery(data);
    this.props.summery(data);
  };

  handleDateChange = (date) => {
    this.setState({
      selectedDate: new Date(date),
      currentYear: new Date(date).getFullYear(),
      currentMonthNumber: new Date(date).getMonth() + 1,
    });
  };

  renderTableData = (
    tableType,
    cardIndex,
    rowNumber,
    tableTitle,
    tableSubtitle
  ) => {
    this.setState({
      tableType,
      cardIndex,
      rowNumber,
      tableTitle,
      tableSubtitle,
    });
  };
  render() {
    const {
      selectedDate,
      tableType,
      rowNumber,
      cardIndex,
      currentMonthNumber,
      currentYear,
      tableTitle,
      tableSubtitle,
      CMSName,
      summaryData,
      lineItemSummaryData,
      batchSummeryData,
    } = this.state;
    const month = selectedDate.toLocaleString('default', { month: 'long' });

    return (
      <Grid>
        <TopBar
          selectedDate={selectedDate}
          handleDateChange={this.handleDateChange}
          CMSName={CMSName}
          {...this.props}
        />
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
            <CardOne
              renderTableData={this.renderTableData}
              batchSummeryData={batchSummeryData}
            />
            <CardTwo
              renderTableData={this.renderTableData}
              lineItemSummaryData={lineItemSummaryData}
            />
            <CardThree
              renderTableData={this.renderTableData}
              month={month}
              summaryData={summaryData}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {tableType === 'batch' ? (
              <BatchTable
                rowNumber={rowNumber}
                cardIndex={cardIndex}
                currentMonthNumber={currentMonthNumber}
                currentYear={currentYear}
                collabType='CMO'
                getCardsDetails={this.getCardsDetails}
                CMOMember={this.props.match.params.organizationName}
                tableSubtitle={tableSubtitle}
                {...this.props}
              />
            ) : (
              <LineTable
                rowNumber={rowNumber}
                cardIndex={cardIndex}
                currentMonthNumber={currentMonthNumber}
                currentYear={currentYear}
                collabType='CMO'
                CMOMember={this.props.match.params.organizationName}
                tableTitle={tableTitle}
                tableSubtitle={tableSubtitle}
                {...this.props}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  summaryData: state.pcmp.common.dashboard.summary,
  lineItemSummaryData: state.pcmp.common.dashboard.lineItemSummary,
  batchSummeryData: state.pcmp.common.dashboard.batchSummery,
  batchTableData: state.pcmp.common.dashboard.batchSummaryDetails,
});
export default connect(mapStateToProps, {
  getBatchSummery,
  lineSummery,
  summery,
  // viewApplicationSettings,
})(withRouter(index));
