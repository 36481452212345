const getColor = (status) => {
  let bgcolor;
  switch (status) {
    case 0:
      bgcolor = '#797979';
      break;
    case 1:
      bgcolor = '#484848';
      break;
    case 3:
      bgcolor = '#770843';
      break;
    case 4:
      bgcolor = '#C66D21';
      break;
    case 5:
      bgcolor = '#3b5b7c';
      break;
    case 6:
      bgcolor = '#43952A';
      break;
    case 16:
      bgcolor = '#62A352';
      break;
    case 7:
      bgcolor = '#337857';
      break;
    case 8:
      bgcolor = '#000000';
      break;
    case 9:
      bgcolor = '#390A23';
      break;
    case 10:
      bgcolor = '#797979';
      break;
    case 11:
      bgcolor = '#FF7600';
      break;
    case 12:
      bgcolor = '#1B9ABF';
      break;
    case 13:
      bgcolor = '#FF0000';
      break;
    case 14:
      bgcolor = '#EE6666';
      break;
    case 15:
      bgcolor = '#173072';
      break;
    case 17:
      bgcolor = '#601010';
      break;
    case 18:
      bgcolor = '#EC6535';
      break;
    default:
      bgcolor = '#484848';
  }
  return bgcolor;
};

export default getColor;
