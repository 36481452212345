import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mandatory: {
    color: 'red',
  },
}));

const MandatorySpan = () => {
  const classes = useStyles();
  return <span className={classes.mandatory}>*</span>;
};

export default MandatorySpan;
