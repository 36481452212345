import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
//files
// import Header from '../../common/models/Header';
import NewHeader from '../models/NewHeader';
import Wrapper from '../wrappers/FullWidthContent';
import {
  quickViewAction,
  fetchUOM,
  updateGRN,
  recordTimelineAction,
  recordResolutionAction,
  deleteGRRecord,
  lineSource,
  resolutionSource,
  timelineSource,
} from '../../../containers/actions/cmo/shortFallsActions';
import CheckBoxButton from '../CheckboxAndButton';
import StyledDialog from '../../../../common/models/Dialog';
import POItemTable from '../models/POItemTable';
import EditTable from '../updateGrRecord/EditTable';
import EditRecord from '../supplierPoAdd/RecordDetails';
import ReceivedQuantity from '../models/ReceivedQuantity';
import EditReceived from '../updateGrRecord/RecievedQuantity';
import QualityAssurance from '../models/QualityAssuranceInfo';
import EditQuality from '../updateGrRecord/QualityAssurance';
import ChallanInfo from '../models/ChallanInfo';
import EditChallan from '../updateGrRecord/ChallanInfo';
import InvoiceInfo from '../models/InvoiceInfo';
import EditInvoice from '../updateGrRecord/InvoiceInfo';
import OtherInfo from '../models/OtherInfo';
import EditOtherInfo from '../updateGrRecord/OtherInfo';

import QuickTable from '../models/QuickViewTable';
//test backdrop
// import BackdropComponent from '../models/BackDrop';
import CircularProgress from '@material-ui/core/CircularProgress';

//rec resolutions
import RecordTimeline from '../Accordion';

// delete Record
import DeleteIcon from '@material-ui/icons/Delete';
import StyledButton from '../../../../common/models/Button';
import DeletePromptDialog from './DeletePromptDialog';

import ReasonForRejection from '../models/ReasonForRejection';
import moment from 'moment';
import { SupplierPOContext } from '../grnReceipts/context';

const styles = (theme) => ({
  deleteRecord: {
    backgroundColor: 'transparent',
    color: '#FF0000',
    border: '1px solid #FF0000',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: '#cc4949',
      color: 'white',
      border: '1px solid #cc4949',
    },
  },
});

class UpdateRecord extends Component {
  static contextType = SupplierPOContext;
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      resError: null,
      tempResponseRecordSaved: false,
      tempResonseRecordSubmit: false,

      POLineRecordDetails: this.props.POLineRecordDetails,
      POBatchRecordDetails: {},
      challanInformation: {},
      invoiceInformation: {},
      qualityAssuranceInfo: {},
      receivedQuantityInfo: {},

      //edit dialog states
      openPOItem: false,
      openReceived: false,
      openQuality: false,
      openChallan: false,
      openInvoice: false,
      openOtherInfo: false,
      //consent
      verifyConsent: false,
      //updateGRN

      recordSaved: false,
      submitRecord: false,
      loading: true,
      deletePrompt: false,
      recordDeleted: false,
      //timeline
      timeLine: [],
      resolution: [],
      //appending file for duplicates
      OtherInfoTempNumber: 0,
      challanTempNumber: 0,
      invoiceTempNumber: 0,
      qualityTempNumber: 0,
      //new backdrop
      deleteLoading: false,
      //popup dialog to alert user for quantity > ordered quantity
      quantityAlert: false,
    };
    this.errorRef = React.createRef();
  }

  componentDidMount() {
    const data = {
      supplierPurchaseOrderNumber:
        this.props.POLineRecordDetails.supplierPurchaseOrderNumber,
      materialCode: this.props.POLineRecordDetails.materialCode,
      schrockenSupplierPOBatchID: this.props.row.schrockenSupplierPOBatchID,
    };
    this.props.quickViewAction(data);
    //record timeline func
    this.props.recordTimelineAction(data);
    //record resolution func
    this.props.recordResolutionAction(data);
  }
  componentWillUnmount() {
    if (lineSource) {
      lineSource.cancel('cancelling');
    }
    if (resolutionSource) {
      resolutionSource.cancel('cancelling');
    }
    if (timelineSource) {
      timelineSource.cancel('cancelled');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.updateGRNData !== prevProps.updateGRNData &&
      this.props.updateGRNData.status === false
    ) {
      let errorKey = Object.keys(this.props.updateGRNData.errorDescription)[0];
      this.setState({
        resError: this.props.updateGRNData.errorDescription[errorKey],
        errors: {
          [errorKey]: true,
        },
        deleteLoading: false,
      });
    }
    if (
      this.props.updateGRNData !== prevProps.updateGRNData &&
      this.props.updateGRNData.status === true &&
      this.state.tempResonseRecordSubmit === true
      // this.state.POBatchRecordDetails.isSubmitForApproval === true
    ) {
      this.setState({
        submitRecord: true,
        recordSaved: false,
        deleteLoading: false,
      });
    }
    if (
      this.props.updateGRNData !== prevProps.updateGRNData &&
      this.props.updateGRNData.status === true &&
      this.state.tempResponseRecordSaved === true
      // this.state.POBatchRecordDetails.isSubmitForApproval === false
    ) {
      this.setState({
        recordSaved: true,
        submitRecord: false,
        deleteLoading: false,
      });
    }
    if (
      this.props.supplierPOData &&
      this.props.supplierPOData !== prevProps.supplierPOData
    ) {
      // let { POLineRecordDetails } = this.props.POLineRecordDetails;
      let {
        POBatchRecordDetails,
        challanInformation,
        invoiceInformation,
        qualityAssuranceInfo,
        receivedQuantityInfo,
      } = this.props.supplierPOData.GRDetails;
      this.setState({
        // POLineRecordDetails,
        POBatchRecordDetails,
        challanInformation,
        invoiceInformation,
        qualityAssuranceInfo,
        receivedQuantityInfo,
        loading: false,
      });
    }
    if (
      this.props.recordTimeline &&
      this.props.recordTimeline !== prevProps.recordTimeline
    ) {
      this.setState({
        timeLine: this.props.recordTimeline.data,
      });
    }
    if (
      this.props.recordResolution &&
      this.props.recordResolution !== prevProps.recordResolution
    ) {
      this.setState({
        resolution: this.props.recordResolution.data,
      });
    }
    if (
      this.props.deleteGR !== prevProps.deleteGR &&
      this.props.deleteGR.status === true
    ) {
      this.setState({
        resError: null,
        deletePrompt: false,
        recordDeleted: true,
        deleteLoading: false,
      });
    }
    if (
      this.props.deleteGR !== prevProps.deleteGR &&
      this.props.deleteGR.status === false
    ) {
      this.setState({
        resError: this.props.deleteGR.errorDescription,
        deletePrompt: false,
        recordDeleted: false,
        deleteLoading: false,
      });
    }
  }

  handleDeleteRecordPrompt = () => {
    this.setState({
      deletePrompt: true,
      resError: null,
      recordDeleted: false,
      deleteLoading: false,
    });
  };
  handleCloseDeletePrompt = () => {
    this.setState({
      resError: null,
      deletePrompt: false,
      recordDeleted: false,
      deleteLoading: false,
    });
  };
  handleRecordDelete = () => {
    let backdropText = 'Please wait. Deleting the record';
    this.setState({
      resError: null,
      deletePrompt: false,
      // recordDeleted: true,
      deleteLoading: true,
    });
    let data = {
      supplierPurchaseOrderNumber:
        this.props.POLineRecordDetails.supplierPurchaseOrderNumber,
      materialCode: this.props.POLineRecordDetails.materialCode,
      schrockenSupplierPOBatchID: this.props.row.schrockenSupplierPOBatchID,
    };
    this.props.deleteGRRecord(data, backdropText);
  };
  handleCloseRecordDelete = () => {
    this.setState({
      resError: null,
      deletePrompt: false,
      recordDeleted: false,
      deleteLoading: false,
    });
    this.context.fetchGRLineItems();
    this.props.handleClose();
  };

  handleCloseRecord = () => {
    this.setState({
      submitRecord: false,
      recordSaved: false,
      // resError: null,
      deletePrompt: false,
    });
    this.context.fetchGRLineItems();

    this.props.handleClose();
  };

  handleOpenDialog = (name) => {
    this.setState({
      [name]: true,
    });
  };

  handleSavePOItemData = (data) => {
    this.setState({
      POBatchRecordDetails: data,
      resError: null,
      errors: {},
    });
    this.handleCloseSectionDialog('openPOItem');
  };

  handleSaveReceivedQuantityData = (data, exceed) => {
    this.setState({
      receivedQuantityInfo: data,
      resError: null,
      errors: {},
    });
    if (exceed) {
      this.setState({
        receivedQuantityInfo: data,
        POBatchRecordDetails: {
          ...this.state.POBatchRecordDetails,
          isExceedQuantityAllowed: true,
        },
        resError: null,
        errors: {},
      });
    }
    this.handleCloseSectionDialog('openReceived');
  };
  handleSaveQualityAssuranceData = (data, number) => {
    this.setState({
      qualityAssuranceInfo: data,
      resError: null,
      errors: {},
      qualityTempNumber: number,
    });
    this.handleCloseSectionDialog('openQuality');
  };
  handleSaveChallanInformationData = (data, number) => {
    this.setState({
      challanInformation: data,
      resError: null,
      errors: {},
      challanTempNumber: number,
    });
    this.handleCloseSectionDialog('openChallan');
  };
  handleSaveInvoiceInformationData = (data, number) => {
    this.setState({
      invoiceInformation: data,
      resError: null,
      errors: {},
      invoiceTempNumber: number,
    });
    this.handleCloseSectionDialog('openInvoice');
  };
  handleSaveOtherInformationData = (data, number) => {
    this.setState({
      POBatchRecordDetails: data,
      resError: null,
      errors: {},
      OtherInfoTempNumber: number,
    });
    this.handleCloseSectionDialog('openOtherInfo');
  };

  handleCloseSectionDialog = (name) => {
    this.setState({
      [name]: false,
    });
  };

  handlingFiles = (file, name) => {
    if (name === 'COAFiles') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          COAFiles: [...this.state.qualityAssuranceInfo.COAFiles, file],
        },
      });
    }
    if (name === 'challanFiles') {
      this.setState({
        challanInformation: {
          ...this.state.challanInformation,
          challanFiles: [...this.state.challanInformation.challanFiles, file],
        },
      });
    }
    if (name === 'InvoiceFiles') {
      this.setState({
        invoiceInformation: {
          ...this.state.invoiceInformation,
          invoiceFiles: [...this.state.invoiceInformation.invoiceFiles, file],
        },
      });
    }
    if (name === 'otherFiles') {
      this.setState({
        POBatchRecordDetails: {
          ...this.state.POBatchRecordDetails,
          otherFiles: [...this.state.POBatchRecordDetails.otherFiles, file],
        },
      });
    }
  };

  getContainers = () => {
    const { receivedQuantityInfo } = this.state;
    const { rmPmCustomFeaturesAndValidations } = this.props;
    if (receivedQuantityInfo.containerDetails?.length > 0) {
      /* when container data feature is not available for ecosystem or 
  when there is no data to be added in selected container 
  then remove the object from state array */
      if (
        !rmPmCustomFeaturesAndValidations.features.containerDetailsEnabled ||
        (receivedQuantityInfo.containerDetails.length === 1 &&
          !receivedQuantityInfo.containerDetails[0].numberOfContainers &&
          !receivedQuantityInfo.containerDetails[0].quantityPerContainer)
      ) {
        return [];
      } else {
        return receivedQuantityInfo.containerDetails;
      }
    } else {
      return [];
    }
  };
  getPendingQty = () => {
    if (
      this.state.POLineRecordDetails.quantityYetToReceive <
      this.state.receivedQuantityInfo.actualQuantityRecieved
    ) {
      return 0;
    } else {
      return parseFloat(
        Number(
          this.state.POLineRecordDetails.quantityYetToReceive -
            this.state.receivedQuantityInfo.actualQuantityReceived
        ).toFixed(3)
      );
    }
  };
  validate = () => {
    let error = false;
    const {
      POBatchRecordDetails,
      qualityAssuranceInfo,
      receivedQuantityInfo,
      invoiceInformation,
    } = this.state;
    const { rmPmCustomFeaturesAndValidations } = this.props;
    let errors = {};

    if (POBatchRecordDetails.materialReceivedDate === null) {
      error = true;
      errors.materialReceivedDate = true;
      this.setState({ resError: 'Please enter material received Date' });
    } else if (POBatchRecordDetails.numberMRN === '' || null) {
      error = true;
      errors.numberMRN = true;
      this.setState({ resError: 'Please enter MRN Number' });
    } else if (receivedQuantityInfo.actualQuantityReceived === '' || null) {
      error = true;
      errors.actualQuantityReceived = true;
      this.setState({ resError: 'Please enter actual quantity received' });
    } else if (qualityAssuranceInfo.samplingQuantityQA === '' || null) {
      error = true;
      errors.samplingQuantityQA = true;
      this.setState({ resError: 'Please enter sampling quantity QA' });
    } else if (qualityAssuranceInfo.isQACompleted === false) {
      error = true;
      errors.isQACompleted = true;
      this.setState({ resError: 'Please select if QA is done' });
    } else if (
      qualityAssuranceInfo.analyticalReportNumber === '' ||
      qualityAssuranceInfo.analyticalReportNumber === null
    ) {
      error = true;
      errors.analyticalReportNumber = true;
      this.setState({ resError: 'Please enter analytical report number' });
    } else if (
      qualityAssuranceInfo.materialManufactureDate === null &&
      rmPmCustomFeaturesAndValidations.validations.QAInfo[
        this.props.POLineRecordDetails.materialType === 'ROH' ? 'RM' : 'PM'
      ].mfgDate.required
    ) {
      error = true;
      errors.manufacturingDate = true;
      this.setState({ resError: 'Please enter Material Manufacturing Date' });
    } else if (
      qualityAssuranceInfo.expirationDate === null &&
      rmPmCustomFeaturesAndValidations.validations.QAInfo[
        this.props.POLineRecordDetails.materialType === 'ROH' ? 'RM' : 'PM'
      ].expDate.required
    ) {
      error = true;
      errors.expirationDate = true;
      this.setState({ resError: 'Please enter Expiration Date' });
    } else if (
      qualityAssuranceInfo.expirationDate &&
      (qualityAssuranceInfo.materialManufactureDate ===
        qualityAssuranceInfo.expirationDate ||
        qualityAssuranceInfo.expirationDate <
          qualityAssuranceInfo.materialManufactureDate)
    ) {
      error = true;
      errors.expirationDate = true;
      this.setState({
        resError: 'Please enter valid Expiration Date',
      });
    }
    // //shelfLife
    else if (
      qualityAssuranceInfo.materialManufactureDate &&
      qualityAssuranceInfo.expirationDate &&
      this.props.POLineRecordDetails.shelfLifeUnitIndicator &&
      moment(qualityAssuranceInfo.expirationDate).diff(
        qualityAssuranceInfo.materialManufactureDate,
        this.props.POLineRecordDetails.shelfLifeUnitIndicator
      ) < this.props.POLineRecordDetails.minShelfLife
    ) {
      error = true;
      errors.expirationDate = true;
      this.setState({
        resError: 'Please enter valid Expiration Date',
        addRecord: false,
      });
    }
    //coa file validation
    else if (
      qualityAssuranceInfo.COAFiles.length === 0 &&
      rmPmCustomFeaturesAndValidations.validations.QAInfo.coaFile.required
    ) {
      error = true;
      errors.COAFiles = true;
      this.setState({
        resError: 'Please attach COA file',
      });
    }
    // check re-upload of COA file in case of Repeat QC/QA
    else if (
      this.state.POBatchRecordDetails.batchStatusID === 12 &&
      this.state.qualityAssuranceInfo.COAFiles.length <=
        this.props.supplierPOData.GRDetails?.qualityAssuranceInfo.COAFiles
          .length
    ) {
      error = true;
      errors.COAFiles = true;
      this.setState({
        resError: 'Please Re-upload COA file.',
      });
    }
    // RAW MAterial validation
    else if (
      this.props.POLineRecordDetails.materialType === 'ROH' &&
      qualityAssuranceInfo.batchNumberQA === ''
    ) {
      error = true;
      errors.batchNumberQA = true;
      this.setState({
        resError: 'Please enter Batch Number QA',
      });
    } else if (
      rmPmCustomFeaturesAndValidations.validations.invoiceInfo.invoiceNumber
        .required &&
      invoiceInformation.invoiceNumber.trim() === ''
    ) {
      error = true;
      errors.invoiceNumber = true;
      this.setState({
        resError: 'Please enter Invoice Number',
      });
    } else if (
      rmPmCustomFeaturesAndValidations.validations.invoiceInfo.invoiceDate
        .required &&
      !invoiceInformation.invoiceDate
    ) {
      error = true;
      errors.invoiceDate = true;
      this.setState({
        resError: 'Please enter Invoice Date',
      });
    }
    // if needed add container validations here
    this.setState({ errors });
    return error;
  };

  handleVerifyConsent = () => {
    this.setState({
      verifyConsent: !this.state.verifyConsent,
      errors: {},
      resError: null,
      // deleteLoading: false,
    });
  };

  handleSaveRecord = async (type) => {
    let backdropText = 'Please Wait. Saving the updated record';
    this.setState({
      tempResponseRecordSaved: true,
      tempResonseRecordSubmit: false,
      resError: null,
      errors: {},
      deleteLoading: type === 'submit' ? false : true,
    });
    const {
      POLineRecordDetails,
      POBatchRecordDetails,
      receivedQuantityInfo,
      invoiceInformation,
      qualityAssuranceInfo,
      challanInformation,
    } = this.state;

    let data = {
      PODetails: {
        POLineRecordDetails,
        GRDetails: {
          POBatchRecordDetails: {
            ...POBatchRecordDetails,
            isSubmitForApproval: type === 'submit' ? true : false,
            isExceedQuantityAllowed:
              this.state.POLineRecordDetails.quantityYetToReceive <
              receivedQuantityInfo.actualQuantityReceived
                ? true
                : false,
          },
          receivedQuantityInfo: {
            ...receivedQuantityInfo,
            actualQuantityReceived: parseFloat(
              this.state.receivedQuantityInfo.actualQuantityReceived
            ),
            quantityYetToReceive: this.getPendingQty(),
            containerDetails: this.getContainers(),
          },
          qualityAssuranceInfo: {
            ...qualityAssuranceInfo,
            samplingQuantityQA: parseFloat(
              this.state.qualityAssuranceInfo.samplingQuantityQA
            ),
          },
          challanInformation,
          invoiceInformation,
        },
      },
    };
    if (type === 'submit') {
      let isError = this.validate();
      if (this.state.verifyConsent === false) {
        this.setState({
          errors: {
            verifyConsent: true,
          },
          deleteLoading: false,
        });
      } else if (!isError && this.state.verifyConsent === true) {
        backdropText = 'Please wait. Sending record for Approval';
        this.setState({ deleteLoading: true });
        this.props.updateGRN(data, backdropText);
      }
    } else {
      this.props.updateGRN(data, backdropText);
    }
  };

  handleTempNumber = (name) => {
    if (name === 'OtherInfoTempNumber') {
      this.setState({
        OtherInfoTempNumber: this.state.OtherInfoTempNumber + 1,
      });
    }
    if (name === 'challanTempNumber') {
      this.setState({ challanTempNumber: this.state.challanTempNumber + 1 });
    }
    if (name === 'invoiceTempNumber') {
      this.setState({ invoiceTempNumber: this.state.invoiceTempNumber + 1 });
    }
    if (name === 'qualityTempNumber') {
      this.setState({ qualityTempNumber: this.state.qualityTempNumber + 1 });
    }
  };

  //to show dialog if actual quantity is greater than total received quantity
  checkQuantity = (e) => {
    const { actualQuantityReceived } = this.state.receivedQuantityInfo;
    const { quantityOrdered } = this.state.POLineRecordDetails;

    if (actualQuantityReceived > quantityOrdered) {
      this.setState({
        quantityAlert: true,
        resError: null,
        errors: {},
      });
    } else {
      return this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };

  closeQuantityAlert = () => {
    this.setState({
      quantityAlert: false,
    });
  };

  handleYesQuantity = () => {
    this.setState({
      quantityAlert: false,
      POBatchRecordDetails: {
        ...this.state.POBatchRecordDetails,
        isExceedQuantityAllowed: true,
      },
      receivedQuantityInfo: {
        ...this.state.receivedQuantityInfo,
        quantityYetToReceive: 0,
      },
    });
  };

  render() {
    const { classes } = this.props;
    const {
      POLineRecordDetails,
      POBatchRecordDetails,
      receivedQuantityInfo,
      qualityAssuranceInfo,
      challanInformation,
      invoiceInformation,
      errors,
      resError,
    } = this.state;
    const {
      openPOItem,
      openReceived,
      openQuality,
      openChallan,
      openInvoice,
      openOtherInfo,
      loading,
    } = this.state;
    const { handleClose, status, isRecordDelete } = this.props;
    if (resError /*&& errorRef* /* + other conditions */) {
      this.errorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      // window.scrollTo(0, errorRef.current.offsetTop);
    }

    return (
      <React.Fragment>
        <NewHeader
          handleClose={handleClose}
          title='Edit/Update Record Details'
          subtitle='Raw Material (RM) / Packaging Material (PM) Record'
          padding={true}
        >
          {loading === true ? (
            <Grid container justify='center'>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '25%',
                  minWidth: '60vw',
                }}
              >
                <CircularProgress color='primary' thickness={7} />
              </div>
            </Grid>
          ) : (
            <Grid container justify='center' ref={this.errorRef}>
              <Grid item xs={11} sm={11} md={10} lg={9} xl={8}>
                <QuickTable
                  unitOfMeasurement={
                    receivedQuantityInfo.unitOfMeasurementReceived
                  }
                  quantity={POLineRecordDetails.quantityOrdered}
                  actualQuantityRecieved={
                    receivedQuantityInfo.actualQuantityReceived
                  }
                />

                {resError && (
                  <Grid
                    container
                    justify='center'
                    style={{ padding: '1rem 0' }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        variant='h6'
                        style={{
                          border: '1px solid #FF0000',
                          backgroundColor: '#FFF0F0',
                          opacity: '1',
                          textAlign: 'center',
                          padding: '0.8rem',
                          fontSize: '0.875rem',
                        }}
                      >
                        {resError}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {/* RECORD DELETE BUTTON */}
                {isRecordDelete === false ||
                status === 3 ||
                status === 6 ||
                status === 8 ? null : (
                  <Grid
                    container
                    justify='center'
                    style={{ padding: '1rem', paddingBottom: '3rem' }}
                  >
                    <StyledButton
                      startIcon={<DeleteIcon />}
                      className={classes.deleteRecord}
                      endIcon={null}
                      onClick={this.handleDeleteRecordPrompt}
                    >
                      Delete this record
                    </StyledButton>
                  </Grid>
                )}

                <POItemTable
                  maintitle='PO ITEM DETAILS'
                  POLineRecordDetails={POLineRecordDetails}
                  POBatchRecordDetails={POBatchRecordDetails}
                  handleOpenDialog={this.handleOpenDialog}
                  isRecievedExits={true}
                  errors={errors}
                  resError={resError}
                />
                <Dialog
                  fullScreen
                  open={openPOItem}
                  onClose={this.props.handlePOIClose}
                >
                  <NewHeader
                    handleClose={() =>
                      this.handleCloseSectionDialog('openPOItem')
                    }
                    title='Edit Record Details'
                    subtitle='Raw Material (RM) / Packaging Material (PM) Record'
                    padding={true}
                  />
                  <Wrapper>
                    <EditTable
                      title='You are Editing the Following Record'
                      POLineRecordDetails={POLineRecordDetails}
                    />
                    <EditRecord
                      POLineRecordDetails={POLineRecordDetails}
                      POBatchRecordDetails={POBatchRecordDetails}
                      isAddRecord
                      handleSavePOItemData={this.handleSavePOItemData}
                      status={status}
                    />
                  </Wrapper>
                </Dialog>
                <hr />

                {POBatchRecordDetails.batchStatusID === 4 && (
                  <React.Fragment>
                    <ReasonForRejection comments={POBatchRecordDetails} />
                    <hr />
                  </React.Fragment>
                )}
                <ReceivedQuantity
                  receivedQuantityInfo={receivedQuantityInfo}
                  POLineRecordDetails={POLineRecordDetails}
                  isEditExist={true}
                  handleOpenDialog={this.handleOpenDialog}
                  errors={errors}
                  resError={resError}
                  status={status}
                />
                <Dialog
                  fullScreen
                  open={openReceived}
                  onClose={() => this.handleCloseSectionDialog('openReceived')}
                >
                  <NewHeader
                    handleClose={() =>
                      this.handleCloseSectionDialog('openReceived')
                    }
                    title='Edit/Update Received Quantity Information'
                    subtitle='Raw Material (RM) / Packaging Material (PM) Record'
                    padding={true}
                  />
                  <Wrapper>
                    <EditTable
                      title='You are Editing the Following Record'
                      POLineRecordDetails={POLineRecordDetails}
                    />
                    <EditReceived
                      receivedQuantityInfo={receivedQuantityInfo}
                      isAddRecord={true}
                      handleSaveReceivedQuantityData={
                        this.handleSaveReceivedQuantityData
                      }
                      POLineRecordDetails={this.state.POLineRecordDetails}
                    />
                  </Wrapper>
                </Dialog>

                <hr />

                <QualityAssurance
                  qualityAssuranceInfo={qualityAssuranceInfo}
                  isEditExist={true}
                  handleOpenDialog={this.handleOpenDialog}
                  resError={resError}
                  errors={errors}
                  POLineRecordDetails={POLineRecordDetails}
                />
                <Dialog
                  fullScreen
                  open={openQuality}
                  onClose={() => this.handleCloseSectionDialog('openQuality')}
                >
                  <NewHeader
                    handleClose={() =>
                      this.handleCloseSectionDialog('openQuality')
                    }
                    title='Edit/Update Quality Assurance Information'
                    subtitle='Raw Material (RM) / Packaging Material (PM) Record'
                    padding={true}
                  />
                  <Wrapper>
                    <EditTable
                      title='You are Editing the Following Record'
                      POLineRecordDetails={POLineRecordDetails}
                    />
                    <EditQuality
                      qualityAssuranceInfo={qualityAssuranceInfo}
                      isAddRecord={true}
                      handleSaveQualityAssuranceData={
                        this.handleSaveQualityAssuranceData
                      }
                      handlingFiles={this.handlingFiles}
                      unitOfMeasurementOrdered={
                        receivedQuantityInfo.unitOfMeasurementReceived
                      }
                      status={status}
                      //appending file
                      OtherInfoTempNumber={this.state.OtherInfoTempNumber}
                      handleTempNumber={this.handleTempNumber}
                      challanTempNumber={this.state.challanTempNumber}
                      invoiceTempNumber={this.state.invoiceTempNumber}
                      qualityTempNumber={this.state.qualityTempNumber}
                      POLineRecordDetails={POLineRecordDetails}
                    />
                  </Wrapper>
                </Dialog>

                <hr />

                <ChallanInfo
                  challanInformation={challanInformation}
                  isEditExist={true}
                  handleOpenDialog={this.handleOpenDialog}
                  errors={errors}
                  resError={resError}
                />
                <Dialog
                  fullScreen
                  open={openChallan}
                  onClose={() => this.handleCloseSectionDialog('openChallan')}
                >
                  <NewHeader
                    handleClose={() =>
                      this.handleCloseSectionDialog('openChallan')
                    }
                    title='Edit/Update Challan Information'
                    subtitle='Raw Material (RM) / Packaging Material (PM) Record'
                    padding={true}
                  />
                  <Wrapper>
                    <EditTable
                      title='You are Editing the Following Record'
                      POLineRecordDetails={POLineRecordDetails}
                    />
                    <EditChallan
                      challanInformation={challanInformation}
                      isAddRecord={true}
                      handleSaveChallanInformationData={
                        this.handleSaveChallanInformationData
                      }
                      handlingFiles={this.handlingFiles}
                      //appending file
                      OtherInfoTempNumber={this.state.OtherInfoTempNumber}
                      handleTempNumber={this.handleTempNumber}
                      challanTempNumber={this.state.challanTempNumber}
                      invoiceTempNumber={this.state.invoiceTempNumber}
                      qualityTempNumber={this.state.qualityTempNumber}
                    />
                  </Wrapper>
                </Dialog>

                <hr />

                <InvoiceInfo
                  invoiceInformation={invoiceInformation}
                  isEditExist={true}
                  handleOpenDialog={this.handleOpenDialog}
                  errors={errors}
                  resError={resError}
                />
                <Dialog
                  fullScreen
                  open={openInvoice}
                  onClose={() => this.handleCloseSectionDialog('openInvoice')}
                >
                  <NewHeader
                    handleClose={() =>
                      this.handleCloseSectionDialog('openInvoice')
                    }
                    title='Edit/Update Invoice Information'
                    subtitle='Raw Material (RM) / Packaging Material (PM) Record'
                    padding={true}
                  />
                  <Wrapper>
                    <EditTable
                      title='You are Editing the Following Record'
                      POLineRecordDetails={POLineRecordDetails}
                    />
                    <EditInvoice
                      invoiceInformation={invoiceInformation}
                      isAddRecord={true}
                      handleSaveInvoiceInformationData={
                        this.handleSaveInvoiceInformationData
                      }
                      handlingFiles={this.handlingFiles}
                      //appending file
                      OtherInfoTempNumber={this.state.OtherInfoTempNumber}
                      handleTempNumber={this.handleTempNumber}
                      challanTempNumber={this.state.challanTempNumber}
                      invoiceTempNumber={this.state.invoiceTempNumber}
                      qualityTempNumber={this.state.qualityTempNumber}
                    />
                  </Wrapper>
                </Dialog>

                <hr />

                <OtherInfo
                  POBatchRecordDetails={POBatchRecordDetails}
                  isEditExist={true}
                  handleOpenDialog={this.handleOpenDialog}
                  errors={errors}
                  resError={resError}
                />
                <Dialog
                  fullScreen
                  open={openOtherInfo}
                  onClose={() => this.handleCloseSectionDialog('openOtherInfo')}
                >
                  <NewHeader
                    handleClose={() =>
                      this.handleCloseSectionDialog('openOtherInfo')
                    }
                    title='Edit/Update Additional Information'
                    subtitle='Raw Material (RM) / Packaging Material (PM) Record'
                    padding={true}
                  />
                  <Wrapper>
                    <EditTable
                      title='You are Editing the Following Record'
                      POLineRecordDetails={POLineRecordDetails}
                    />
                    <EditOtherInfo
                      POBatchRecordDetails={POBatchRecordDetails}
                      isAddRecord={true}
                      handleSaveOtherInformationData={
                        this.handleSaveOtherInformationData
                      }
                      handlingFiles={this.handlingFiles}
                      //appending file
                      OtherInfoTempNumber={this.state.OtherInfoTempNumber}
                      handleTempNumber={this.handleTempNumber}
                      challanTempNumber={this.state.challanTempNumber}
                      invoiceTempNumber={this.state.invoiceTempNumber}
                      qualityTempNumber={this.state.qualityTempNumber}
                    />
                  </Wrapper>
                </Dialog>
                {this.props.isUpdateRecord && (
                  <CheckBoxButton
                    errors={this.state.errors}
                    verifyConsent={this.verifyConsent}
                    handleVerifyConsent={this.handleVerifyConsent}
                    handleRecordSaved={this.handleSaveRecord}
                    handleSubmitRecord={() => this.handleSaveRecord('submit')}
                    status={status}
                  />
                )}

                {this.props.saveRecord && (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    style={{ paddingTop: '1rem', paddingBottom: '4rem' }}
                  >
                    <StyledButton onClick={this.handleSaveRecord}>
                      Save Record
                    </StyledButton>
                  </Grid>
                )}

                <Dialog
                  open={this.state.recordSaved}
                  // onClose={this.handleCloseRecord}
                  fullWidth
                >
                  <StyledDialog
                    handleClose={this.handleCloseRecord}
                    title='The Record has been Saved'
                    icons={true}
                  />
                </Dialog>

                <Dialog
                  open={this.state.submitRecord}
                  // onClose={this.handleCloseRecord}
                  fullWidth
                >
                  <StyledDialog
                    handleClose={this.handleCloseRecord}
                    icons={true}
                    title='Details Saved &amp; Sent for Approval'
                    subtitle='Your Record has been added and sent for approval.Please Contact the Admin if you have Entered the Wrong Data.'
                  />
                </Dialog>

                {/* Delete Record ? */}
                <Dialog open={this.state.deletePrompt} fullWidth>
                  <DeletePromptDialog
                    handleClose={this.handleCloseDeletePrompt}
                    title={`Are you sure you want to 'Delete' this record ?`}
                    subtitle={`Caution. All data will be deleted. This process can't be reversed.`}
                    hasMultipleButton={true}
                    icons={false}
                    handleYesButton={this.handleRecordDelete}
                  />
                </Dialog>

                {/* Record has been DELETED */}
                <Dialog open={this.state.recordDeleted} fullWidth>
                  <DeletePromptDialog
                    handleClose={this.handleCloseRecordDelete}
                    title='The record has been deleted'
                    hasMultipleButton={false}
                    icons={true}
                  />
                </Dialog>

                {/* Record Timeline / Resolutions - Accordion */}
                <RecordTimeline
                  timeLine={this.state.timeLine}
                  resolution={this.state.resolution}
                />

                {/* Quantity alert Dialog */}

                <Dialog open={this.state.quantityAlert} fullWidth>
                  <DeletePromptDialog
                    handleClose={this.closeQuantityAlert}
                    title={`Actual quantity received is greater than total quantity ordered`}
                    subtitle={`Would you like to proceed ?`}
                    hasMultipleButton={true}
                    icons={false}
                    alert={true}
                    handleYesButton={this.handleYesQuantity}
                  />
                </Dialog>
              </Grid>
            </Grid>
          )}
        </NewHeader>

        {/* <BackdropComponent/> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  supplierPOData: state.pcmp.cmo.shortFalls.quickView,
  UOM: state.pcmp.cmo.shortFalls.fetchUOMData,
  updateGRNData: state.pcmp.cmo.shortFalls.updateGRNData,
  // backdropLoading: state.pcmp.common.files.backdropLoading,
  recordTimeline: state.pcmp.cmo.shortFalls.recordTimeline,
  recordResolution: state.pcmp.cmo.shortFalls.recordResolution,
  deleteGR: state.pcmp.cmo.shortFalls.deleteGR,
  rmPmCustomFeaturesAndValidations:
    state.pcmp.common.reports.rmpmGrnFeatures?.data,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    quickViewAction,
    fetchUOM,
    updateGRN,
    recordTimelineAction,
    recordResolutionAction,
    deleteGRRecord,
  })(UpdateRecord)
);
