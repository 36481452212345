import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import { TableRow } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Button from '@material-ui/core/Button';
import { useStyles } from '../styles';

import Logo from './Logo';

import CustomTableCell from '../../models/table/CustomTableCell';
import {
  presentFormatedData,
  presentDate,
} from '../../functionalComponents/dataFormatFunctions';
import qualityEventStatus from '../../qualityEvents/models/qualityEventsStatus';
import UpdateQualityEventForNew from './eventStatusFlow/updateEventForNew';
import AddInfoQualityEvent from './eventStatusFlow/underInvestigationEventForAdd';
import ResolveQualityEventforUR from './eventStatusFlow/resolveEventForUnderReview';
import ViewQualityEventDrawer from './viewEvent';
import RecordIncompleteDialog from './eventStatusFlow/recordIncomplete';

function TableDataForBO(props) {
  const classes = useStyles();
  let index = props.index;
  let row = props.row;

  const [state, setState] = useState({
    recordIncompleteDialog: false,
    updateEventForNewScreen: false,
    addInfoScreen: false,
    resolveEventScreenforUR: false,
    viewQualityEventScreen: false,
    eventID: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  const toggleDialogScreen = (dialogName) => {
    setState((prevState) => ({
      ...prevState,
      [dialogName]: !state[dialogName],
    }));
  };

  const toggleUpdateEventDialog = (eventStatusID, eventID) => {
    setState((prevState) => ({
      ...prevState,
      eventID,
    }));

    if (eventStatusID === 1) {
      toggleDialogScreen('recordIncompleteDialog');
    } else if (eventStatusID === 2) {
      toggleDialogScreen('updateEventForNewScreen');
    } else if (eventStatusID === 3) {
      toggleDialogScreen('resolveEventScreenforUR');
    } else if (eventStatusID === 4) {
      toggleDialogScreen('addInfoScreen');
    } else {
      toggleDialogScreen('viewQualityEventScreen');
    }
  };
  const toggleDialogViewScreen = (eventID) => {
    setState((prevState) => ({
      ...prevState,
      eventID,
    }));
    if (eventID) {
      toggleDialogScreen('viewQualityEventScreen');
    }
  };

  return (
    <>
      <TableRow
        key={index}
        style={{
          backgroundColor: index % 2 === 0 ? '#FFF' : '#0000000a',
        }}
      >
        <CustomTableCell
          scope='row'
          align='center'
          elementStyle={{
            width: '5rem',
            fontSize: '0.85rem',
          }}
        >
          {row.incidentFor}
        </CustomTableCell>
        <CustomTableCell
          scope='row'
          align='center'
          elementStyle={{
            fontSize: '0.85rem',
            width: '3rem',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {row.eventID}
        </CustomTableCell>
        <CustomTableCell
          scope='row'
          align='center'
          elementStyle={{
            fontSize: '0.85rem',
            width: '4rem',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {row.eventReportedDate ? (
            <>{presentDate(row.eventReportedDate)}</>
          ) : (
            'NA'
          )}
        </CustomTableCell>
        <CustomTableCell
          scope='row'
          align='center'
          elementStyle={{
            fontSize: '0.85rem',
            width: '3rem',
            padingLeft: 0,
            paddingRight: 0,
          }}
        >
          {presentDate(row.eventReportedDate)}
        </CustomTableCell>
        <CustomTableCell
          scope='row'
          align='center'
          elementStyle={{
            fontSize: '0.85rem',
            width: '3rem',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {row.priority ? <>{row.priority}</> : 'NA'}
        </CustomTableCell>
        <CustomTableCell
          scope='row'
          align='center'
          elementStyle={{
            fontSize: '0.85rem',
            width: 'rem',
            whiteSpace: 'pre-line',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
          }}
        >
          {row.productCode ? (
            <>
              {row.productCode}/{row.productName}
            </>
          ) : (
            'NA'
          )}
        </CustomTableCell>
        <CustomTableCell
          scope='row'
          align='center'
          elementStyle={{
            fontSize: '0.85rem',
            width: '6rem',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <div
            style={{
              width: '5rem',
              textOverflow: 'ellipsis',
            }}
          >
            {row.batchesAffected ? <>{row.batchesAffected}</> : 'NA'}
          </div>
        </CustomTableCell>

        <CustomTableCell
          scope='row'
          align='center'
          elementStyle={{ width: '2rem', paddingLeft: '2rem', paddingRight: 0 }}
        >
          <div
            style={{ alignItems: 'center', width: '2rem', textAlign: 'center' }}
          >
            <Logo name={row.assignedTo === '' ? '-' : row.assignedTo} />
          </div>
        </CustomTableCell>
        <CustomTableCell
          scope='row'
          align='left'
          elementStyle={{ width: '7rem', paddingLeft: '1rem' }}
        >
          {presentFormatedData(qualityEventStatus(row.eventStatusID))}
        </CustomTableCell>
        <CustomTableCell
          scope='row'
          align='center'
          elementStyle={{ fontSize: '0.85rem', width: '3rem', paddingRight: 0 }}
        >
          {row.estimatedDateOfClosure ? (
            <> {presentDate(row.estimatedDateOfClosure)}</>
          ) : (
            'NA'
          )}
        </CustomTableCell>
        <CustomTableCell
          className={classes.changingWidth}
          align='left'
          elementStyle={{ width: '5rem' }}
        >
          <div
            className={classes.actions}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            {row.action && (
              <Button
                onClick={() =>
                  toggleUpdateEventDialog(row.eventStatusID, row.eventID)
                }
                disabled={isLoading}
                className={classes.updateButton}
              >
                <AutorenewIcon fontSize='small' />
                &nbsp;
                <Typography
                  variant='body2'
                  style={{ textTransform: 'capitalize' }}
                >
                  {/* {row.eventStatusID === 1 && 'Update'}
                  {row.eventStatusID === 2 && 'Update'}
                  {row.eventStatusID === 3 && 'Resolve'}
                  {row.eventStatusID === 4 && 'Add Info'}
                  {row.eventStatusID === 5 && 'Reopen'} */}
                  {/* {row.eventStatusID === 1 ? "Update" : "Add Info"} */}
                  {row.action}
                </Typography>
              </Button>
            )}
            <div style={{ alignItems: 'right', paddingTop: '0.2rem' }}>
              <VisibilityIcon
                fontSize='small'
                className={classes.multipleLogo}
                onClick={() => toggleDialogViewScreen(row.eventID)}
              />
            </div>
          </div>
        </CustomTableCell>
      </TableRow>

      {state.recordIncompleteDialog && (
        <RecordIncompleteDialog
          openDialog={state.recordIncompleteDialog}
          toggleDialog={toggleDialogScreen}
          eventID={state.eventID}
        />
      )}
      {state.updateEventForNewScreen && (
        <UpdateQualityEventForNew
          openDialog={state.updateEventForNewScreen}
          toggleDialog={toggleDialogScreen}
          eventID={state.eventID}
          setIsLoading={setIsLoading}
        />
      )}
      {state.addInfoScreen && (
        <AddInfoQualityEvent
          openDialog={state.addInfoScreen}
          toggleDialog={toggleDialogScreen}
          eventID={state.eventID}
        />
      )}
      {state.resolveEventScreenforUR && (
        <ResolveQualityEventforUR
          openDialog={state.resolveEventScreenforUR}
          toggleDialog={toggleDialogScreen}
          eventID={state.eventID}
        />
      )}
      {state.viewQualityEventScreen && (
        <ViewQualityEventDrawer
          openDialog={state.viewQualityEventScreen}
          toggleDialog={toggleDialogViewScreen}
          eventID={state.eventID}
        />
      )}
    </>
  );
}

export default TableDataForBO;
