import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
//files
import AddRecord from './AddRecord';
// import Header from '../../common/models/Header';
import TitleObj from '../../common/models/TitleObject';
import NewHeader from '../models/NewHeader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const SupplierPOAdd = (props) => {
  const { open, handleClose } = props;

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <NewHeader
          handleClose={handleClose}
          title={TitleObj[props.status]}
          subtitle='Raw Material (RM) / Packaging Material (PM) Record'
          padding={true}
        >
          <AddRecord
            POLineRecordDetails={props.POLineRecordDetails}
            GRData={props.row}
            handleClose={handleClose}
          />
        </NewHeader>
      </Dialog>
    </React.Fragment>
  );
};

export default SupplierPOAdd;
