import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';



const useStyles = makeStyles({
    grid:{
      display:'flex',
      justifyContent:'space-between',
      flexWrap:'wrap'
    },
    borders: {
      border: '2px solid #707070',
      display: 'flex',
      width: '1.6rem',
      backgroundColor: 'grey',
    }, 
    title: {
      fontWeight: 500,
      textAlign: 'left',
      paddingBottom: '0.3rem',
    },
  });

const QuickViewTable = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container justify='center' style={{paddingBottom:'3rem'}}>
 
      <Grid item xs={12} sm={12} md={6} className={classes.grid}
         style={{border:'2px solid #C6C6C6',padding:'1rem'}}
        >
        <Grid item xs={12} sm={6} md={12} style={{paddingBottom:'1rem'}}>
          <Typography variant='body2' className={classes.title}>
            ITEM QUANTITY
          </Typography>
          <span className={classes.borders}></span>
          </Grid >

          <Grid item xs={4} sm={7} md={4} >
          <Typography variant='body2' style={{fontWeight: 500}}>
            Unit of Measurement (UOM)
          </Typography>
          <Typography variant='body2'>
          {props.unitOfMeasurement}
          </Typography>
          </Grid>

          <Grid item xs={4} sm={4} md={4} >
          <Typography variant='body2' style={{fontWeight: 500}}>
            Quantity
          </Typography>
          <Typography variant='body2'>
          {props.quantity}
          </Typography>
          </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.grid}
        style={{border:'2px solid #C6C6C6',padding:'1rem'}}
        >
        <Grid item xs={12} sm={6} md={12} style={{paddingBottom:'1rem'}}>
          <Typography variant='body2' className={classes.title}>
            RECEIVED QUANTITY
          </Typography>
          <span className={classes.borders}></span>
          </Grid >

          <Grid item xs={4} sm={7} md={4} >
          <Typography variant='body2' style={{fontWeight: 500}}>
            Unit of Measurement (UOM)
          </Typography>
          <Typography variant='body2'>
            {props.unitOfMeasurement}
          </Typography>
          </Grid>

          <Grid item xs={4} sm={4} md={4} >
          <Typography variant='body2' style={{fontWeight: 500}}>
            Actual Quantity Received
          </Typography>
          <Typography variant='body2'>
          {props.actualQuantityRecieved}
          </Typography>
          </Grid>
      </Grid>

      </Grid>

      {children}
    </React.Fragment>
  );
};

export default QuickViewTable;
