/* eslint-disable no-console */
/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Harish D
 * -----
 * File Description: Table that shows Line Item Batch details
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import {
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Grid,
  TableFooter,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import InputBase from '@material-ui/core/InputBase';
import TablePagination from '@material-ui/core/TablePagination';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SearchIcon from '@material-ui/icons/Search';
import orderBy from 'lodash/orderBy';
import Moment from 'moment';

import pgactions from '../functionalComponents/PageActions';

import { tableFilter } from '../functionalComponents/TableFilter';

import { style } from '../../../resources/css/styles';

import { viewBatchSummaryDetails} from '../../../containers/actions/common/dashboardAction';

import ViewEditBatchItems from '../../common/sasl/batch/updateBatch';

import PharmaCoViewEditBatchItems from '../../pharmaCo/batchItems/viewEditBatch';

const tableDataFilter = tableFilter;
const TablePaginationActionsWrapped = pgactions;

const styles = style;

const invertDirection = {
  asc: 'desc',
  desc: 'asc',
};

const header = [
  { name: 'Status', id: 'statusText', align: 'left' },
  { name: 'Batch', id: 'batchNumber', align: 'left' },
  { name: 'PO Line Item', id: 'lineNumber', align: 'left' },
  { name: 'PO Number', id: 'purchaseOrderNumber', align: 'left' },
  { name: 'Batch Size', id: 'batchSize', align: 'right' },
  { name: 'Produced Quantity', id: 'producedQuantity', align: 'right' },
  { name: 'Pending Quantity', id: 'pendingQuantity', align: 'right' },
  { name: 'Start Date', id: 'batchStartDate', align: 'right' },
  { name: 'End Date', id: 'batchEndDate', align: 'right' },
  { name: 'MRP', id: 'maximumRetailPrice', align: 'right' },
];

class BatchSummeryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowNumber: this.props.rowNumber,
      cardIndex: this.props.cardIndex,
      data: [],
      header,
      displayFilter: false,
      query: '',
      columnToQuery: 'all',
      columnToSort: '',
      sortDirection: 'desc',
      fieldSelected: false,
      page: 0,
      rowsPerPage: localStorage.rowsPerPage
        ? parseInt(localStorage.rowsPerPage)
        : 3,
      lineItemStatus: 0,
      batchItem: {},
      openBatchDialog: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.getBatchItemsSummery();
  }
 

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.rowNumber !== prevState.rowNumber) {
      return { rowNumber: nextProps.rowNumber };
    }
    return null;
  }
  componentDidUpdate(nextProps, prevState) {
    if (this.state.rowNumber !== prevState.rowNumber) {
      this.getBatchItemsSummery();
    }
    if (
      this.props.currentMonthNumber !== nextProps.currentMonthNumber ||
      this.props.currentYear !== nextProps.currentYear
    ) {
      this.getBatchItemsSummery();
    }
    if (this.props.CMOMember !== nextProps.CMOMember) {
      this.getBatchItemsSummery();
    }
    if (nextProps.batchSummaryDetails !== this.props.batchSummaryDetails) {
      this.setState({ data: this.props.batchSummaryDetails, loading: false });
    }
  }

  getBatchItemsSummery = () => {
    let data = {
      rowNumber: this.state.rowNumber,
      cardIndex: this.state.cardIndex,
      currentMonthNumber: this.props.currentMonthNumber,
      currentYear: this.props.currentYear,
      collabType: this.props.collabType,
      CMOMember: this.props.CMOMember,
      ...this.props.match.params,
    };
    this.props.viewBatchSummaryDetails(data);
    this.setState({ loading: true });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      fieldSelected: false,
    });

    if (this.state.columnToQuery === 'none') {
      if (event.target.name === 'query') {
        this.setState({ fieldSelected: true });
      }
    }
  };

  // show search fields
  showFilter = () => {
    this.setState({
      displayFilter: !this.state.displayFilter,
      columnToQuery: 'all',
      query: '',
    });
  };

  // handle sort
  handleSort = (columName) => {
    this.setState({
      columnToSort: columName,
      sortDirection:
        this.state.columnToSort === columName
          ? invertDirection[this.state.sortDirection]
          : 'asc',
    });
  };

  // pagination
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    const { page } = this.state;
    if (page > 0) {
      this.setState({ page: 0, rowsPerPage: value });
    } else {
      this.setState({ rowsPerPage: value });
    }
    localStorage.rowsPerPage = value;
  };

  // Batch dialog methods
  openBatchDialog = (ele) => {
    this.setState({ openBatchDialog: true, batchItem: ele });
  };

  toggleViewBatchScreen = () => {
    this.setState({ openBatchDialog: false });
  };

  // call back after batch update
  callBackAPIFunction = () => {
    this.getBatchItemsSummery();
    this.props.getCardsDetails();
  };

  onRefresh = () => {
    this.getBatchItemsSummery();
    this.setState({ columnToSort: '' });
  };

  render() {
    const { classes, tableSubtitle } = this.props;
    const { header, data, rowsPerPage, page, loading } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    const queryData = orderBy(
      this.state.query
        ? data.filter((data) =>
          tableDataFilter(
            data,
            this.state.columnToQuery,
            this.state.query.toLowerCase(),
            this.state.header
          )
        )
        : data,
      this.state.columnToSort,
      this.state.sortDirection
    );

    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, queryData.length - page * rowsPerPage);

    const loadingRow = (
      <TableBody>
        <TableRow>
          <TableCell colSpan={header.length} style={{ textAlign: 'center' }}>
            Loading...
          </TableCell>
        </TableRow>
      </TableBody>
    );

    const noRecords = (
      <TableRow>
        <TableCell colSpan={header.length} align='center'>
          No Records Found
        </TableCell>
      </TableRow>
    );

    return (
      <Grid item xs={12} md={12} className={classes.membersTable}>
        <Paper className={classes.root3}>
          <Toolbar>
            <Grid
              container
              spacing={2}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Grid
                item
                xs={8}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant='subtitle1'
                  style={{
                    fontSize: '1.2rem',
                    marginRight: '0.5rem',
                  }}
                >
                  Batch Summary
                </Typography>

                <Typography
                  variant='caption'
                  style={{
                    fontSize: '0.85rem',
                  }}
                >
                  ({tableSubtitle})
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <SearchIcon />
                <InputBase
                  placeholder='Search Batch'
                  value={this.state.query}
                  name='query'
                  onChange={this.handleChange}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                />
              </Grid>
            </Grid>

            <Tooltip title='Refresh List'>
              <IconButton aria-label='Refresh list' onClick={this.onRefresh}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>

          <Table className={classes.table}>
            <TableHead style={{ background: '#E8E8E8' }}>
              <TableRow>
                {header.map((data, i) => (
                  <TableCell key={i} align={data.align}>
                    <Tooltip title='sort'>
                      <div
                        onClick={() => this.handleSort(data.id)}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: `${data.align === 'right' ? 'flex-end' : 'flex-start'
                            }`,
                          cursor: 'pointer',
                        }}
                      >
                        <span>{data.name}</span>
                        {this.state.columnToSort === data.id ? (
                          this.state.sortDirection === 'asc' ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </div>
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              loadingRow
            ) : (
              <TableBody>
                {/* {searchResults} */}
                {queryData.length > 0
                  ? queryData
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((ele, i) => (
                      <TableRow
                        key={`row-${i}`}
                        className={classes.tableRowHover}
                        onClick={() => this.openBatchDialog(ele)}
                      >
                        <TableCell align='left'>{ele.statusText}</TableCell>
                        <TableCell align='left'>{ele.batchNumber}</TableCell>

                        <TableCell align='left'>{ele.lineNumber}</TableCell>
                        <TableCell align='left'>
                          {ele.purchaseOrderNumber}
                        </TableCell>
                        <TableCell align='right'>{ele.batchSize}</TableCell>
                        <TableCell align='right'>
                          {ele.producedQuantity}
                        </TableCell>
                        <TableCell align='right'>
                          {ele.pendingQuantity}
                        </TableCell>
                        <TableCell align='right'>
                          {ele.batchStartDate
                            ? Moment(new Date(ele.batchStartDate)).format(
                              dateFormat
                            )
                            : null}
                        </TableCell>
                        <TableCell align='right'>
                          {ele.batchEndDate
                            ? Moment(new Date(ele.batchEndDate)).format(
                              dateFormat
                            )
                            : null}
                        </TableCell>
                        <TableCell align='right'>
                          {ele.maximumRetailPrice === ''
                            ? 'NA'
                            : ele.maximumRetailPrice}
                        </TableCell>
                      </TableRow>
                    ))
                  : noRecords}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={header.length} />
                  </TableRow>
                )}
              </TableBody>
            )}
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[3, 5, 10, 15]}
                  count={queryData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
        <Dialog fullScreen open={this.state.openBatchDialog}>
          {this.props.collabType === 'CMO' ? (
            <ViewEditBatchItems
              toggleViewBatchScreen={this.toggleViewBatchScreen}
              params={this.props.match.params}
              batchItemDetails={this.state.batchItem}
              collabType={this.props.collabType}
              callBackAPIFunction={this.callBackAPIFunction}
              open={this.state.openBatchDialog}
            />
          ) : (
            <PharmaCoViewEditBatchItems
              toggleViewBatchScreen={this.toggleViewBatchScreen}
              params={this.props.match.params}
              batchItemDetails={this.state.batchItem}
              collabType={this.props.collabType}
              callBackAPIFunction={this.callBackAPIFunction}
            />
          )}
        </Dialog>
      </Grid>
    );
  }
}

BatchSummeryTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  batchSummaryDetails: state.pcmp.common.dashboard.batchSummaryDetails,
});
export default withStyles(styles)(
  connect(mapStateToProps, { viewBatchSummaryDetails })(BatchSummeryTable)
);
