import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  statusBox: {
    height: '1.5rem',
    textAlign: 'center',
    padding: '0.25rem',
    borderRadius: '0.2rem',
    fontSize: '0.875rem',
    backgroundColor: 'brown',
    color: 'white',
    border: '2px solid brown',
  },
}));
const RecordIncomplete = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant='body1' className={classes.statusBox}>
        Record Incomplete
        {/* {props.statusDescription} */}
      </Typography>
    </div>
  );
};

export default RecordIncomplete;
