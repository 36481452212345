import React from 'react';
import Grid from '@material-ui/core/Grid';

const TableWrapper = ({ children, ...props }) => {
  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={12} sm={12} md={12}>
        {children}
      </Grid>
    </Grid>
  );
};
export default TableWrapper;
