import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { deactivateUsers } from '../../../containers/actions/pharmaCo/manageUsersActions';
import { useDispatch } from 'react-redux';
import StyledButton from '../../../../common/models/Button';
// useSelector,
const useStyles = makeStyles((theme) => ({
    styledBtn: {
        textTransform: 'none',
        borderRadius: '2rem',
        paddingRight: '3rem',
        paddingLeft: '3rem'
    },
}));

const UserDeactivation = (props) => {
    const classes = useStyles();
    const [disableButton, setDisableButton] = useState(false);
    const dispatch = useDispatch();
    // let userDeactivated = useSelector((state) => {
    //     return state.pcmp.pharmaCo.manageUsers.deactivatedUser;
    // });
    // React.useEffect(() => {
    //     if (userDeactivated !== undefined && disableButton === true) {
    //         userDeactivated = undefined
    //     }
    // }, [userDeactivated, disableButton])
    const handleConfirm = () => {
        dispatch(deactivateUsers({
            emailAddress: props.deactivateUserData.email,
            saasUserId: props.deactivateUserData.id
        }));
        setDisableButton(true);
        props.handleConfirmation(true)
        props.onClose()
        // if (userDeactivated !== undefined && userDeactivated.status === true) {
        //     setDisableButton(true);
        //     props.handleConfirmation(true)
        //     props.onClose()
        // }
    }
    return (
        <Fragment>
            <Grid container justify='center' >
                <Grid item xs={10} sm={9} md={9} style={{ display: 'flex', justifyContent: 'center', padding: '1.5rem' }}>
                    <Typography variant='body1' style={{ fontSize: '1.5rem', fontWeight: 500 }}>
                        Are you sure you want to deactivate the following user?
                    </Typography>
                </Grid>

                <Grid item xs={10} sm={9} md={9} style={{ display: 'flex', justifyContent: 'center', border: '1px solid grey', backgroundColor: 'whitesmoke', padding: '1rem', marginBottom: '4rem' }}>
                    {props.deactivateUserData.email}
                </Grid>

                <Grid item xs={10} sm={9} md={9} style={{ display: 'flex', justifyContent: 'center', border: '1px solid grey', backgroundColor: 'whitesmoke', padding: '2.5rem', marginBottom: '2rem' }}>
                    Please note: Actions performed by the deactivated user will still show under their username.
                </Grid>

                <Grid item xs={6} sm={4} md={4} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1.5rem' }}>
                    {/* <Button
                        variant='contained'
                        color='primary'
                        disableElevation
                        disableRipple
                        disableFocusRipple
                        className={classes.styledBtn}
                        onClick={handleConfirm}
                        disabled={disableButton}
                    >Yes</Button> */}

                    <StyledButton
                        className={classes.styledBtn}
                        onClick={handleConfirm}
                        disabled={disableButton}>Yes</StyledButton>
                </Grid>

                <Grid item xs={6} sm={4} md={4} style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1.5rem' }}>
                    {/* <Button
                        variant='contained'
                        color='primary'
                        disableElevation
                        disableRipple
                        disableFocusRipple
                        onClick={props.onClose}
                        disabled={disableButton}
                        // style={{ backgroundColor: 'green' }}
                        className={classes.styledBtn}>No</Button> */}

                    <StyledButton
                        className={classes.styledBtn}
                        onClick={props.onClose}
                        disabled={disableButton}>No</StyledButton>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default UserDeactivation