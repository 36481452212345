import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
//files
import ResolveRecord from '../supplierPOUpdate/UpdateRecord';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const Resolve = (props) => {
  const {
    open,
    POLineRecordDetails,
    row,
    status,
    handleClose,
    isRecordDelete,
    isUpdateRecord,
    saveRecord,
  } = props;
  return (
    <Dialog
      fullScreen
      open={open} //change to false
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <ResolveRecord
        POLineRecordDetails={POLineRecordDetails}
        status={status}
        row={row}
        handleClose={handleClose}
        isRecordDelete={isRecordDelete}
        isUpdateRecord={isUpdateRecord}
        saveRecord={saveRecord}
      />
    </Dialog>
  );
};
export default Resolve;
