import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';

export default function (ComposedClass) {
  const ProtectedRoute = (props) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (localStorage.getItem('authToken')) {
        let user = localStorage.getItem('userType');
        if (props.location.pathname === '/' && user === 'schrockenAdmin') {
          // Redirect to Admin dashboard
          props.history.push(`/${user}/dashboard`);
        } else if (props.location.pathname === '/' && user === 'user') {
          // Get app details
          let { collabType, roleName, organizationName, ecosystemId } =
            localStorage;

          // for redirect SML user of Clinical Trials (Trier Health ) to regular route
          if (collabType === 'SMO') {
            props.history.push(
              `/pcmp/Pharma Co/${roleName}/${organizationName}/${ecosystemId}/dashboard`
            );
          } else {
            // get the user access points from db
            props.history.push(
              `/pcmp/mosymphony/useraccess?role=${roleName}`
            );
          }
        } else if (
          user !== 'schrockenAdmin' &&
          (localStorage.userType === undefined ||
            localStorage.collabType === null ||
            localStorage.collabType === undefined ||
            localStorage.userType === null)
        ) {
          localStorage.removeItem('authToken');
          sessionStorage.clear();
          props.history.push('/login');
        }
        setLoading(false);
      } else {
        props.history.push('/login');
      }
    }, [props.history, props.location]);

    if (loading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
          }}
        >
          <CircularProgress style={{ color: 'primary' }} thickness={7} />
        </div>
      );
    } else {
      return <ComposedClass {...props} />;
    }
  };
  return ProtectedRoute;
}
