import React from 'react';
import StyledButton from '../../../../common/models/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Icons
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import POItemTable from '../models/POItemTable';

const useStyles = makeStyles((theme) => ({
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '1rem',
    '&:hover': {
      borderBottom: '0.5px solid red',
      color:'#e36e39'
    },
  },
  saved: {
    textAlign: 'center',
    padding: '0.5rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  text: {
    textAlign: 'center',
    margin: '1rem',
  },
  button: {
    padding: '3rem',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
    },
  },
  noButton: {
    width: '50%',
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid #000000de',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
}));

const StyledDialog = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container justify='flex-end'>
        <Typography
          variant='body2'
          className={classes.closeText}
          onClick={props.handleCloseRecieved}
        >
          Close <CloseIcon fontSize='large' />
        </Typography>

        <Grid container justify='center'>
          <Grid item xs={12} sm={9} md={9}>
            <Typography variant='h4' className={classes.saved}>
              Did you receive the material(s)?
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='h6' className={classes.text}>
              Please confirm if you have received the following material
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justify='center'>
        <Grid item xs={12} sm={11} md={11}>
          <POItemTable
            errors={{}}
            isTableExists={true}
            {...props}
            row={props.row}
            POBatchRecordDetails={props.row}
          />
        </Grid>
      </Grid>

      <Grid container justify='center'>
        <Grid item xs={12} sm={4} md={4} className={classes.button}>
          <StyledButton
            className={classes.noButton}
            onClick={props.handleCloseRecieved}
          >
            No
          </StyledButton>
          <FormHelperText style={{ textAlign: 'center' }}>
            Material not yet received
          </FormHelperText>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          style={{ padding: '3rem', textAlign: 'center' }}
        >
          <StyledButton
            style={{ width: '50%' }}
            onClick={props.handleClickOpen}
          >
            Yes
          </StyledButton>
          <FormHelperText style={{ textAlign: 'center' }}>
            Add new record
          </FormHelperText>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default StyledDialog;
