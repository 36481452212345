export const style = theme => ({
  // Purchase Order Styles
  pobackground: {
    backgroundColor: "#1E6EC0"
  },
  wrapper1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // marginLeft: "1.5rem",
    marginRight: "1.5rem",
    paddingTop: "1rem",
    paddingBottom: "1rem"
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "0.25rem"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1.5rem"
  },
  textTitle: {
    color: "white",
    fontSize: "0.85rem"
  },
  textBold: {
    color: "white"
  },
  divider: {
    marginLeft: "1.5rem",
    marginRight: "1rem",
    borderBottom: "1px solid #E5E5E5"
  },
  wrapper2: {
    marginRight: "1.5rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem"
  },
  linePoDivBackground: {
    margin: "-1rem -1.3rem 0 -2rem"
  },
  linePoTitle: {
    backgroundColor: "#D5F1FF",
    padding: "0.25rem 1.5rem 0.25rem 1.5rem",
    display: "flex",
    flexDirection: "row"
  },
  // Tables style
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      cursor: "pointer"
    }
  },
  membersTable: {
    margin: "0.5rem 0rem 1rem 0rem"
  },
  root3: {
    width: "100%",
    marginTop: "1rem",
    overflowX: "auto",
    border: "1px solid #E5E5E5",
    boxShadow: "none"
  },
  fontColor: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.85rem"
  },
  table: {
    minWidth: 700
  },
  // test
  bgColor: {
    backgroundColor: "#45B116",
    color: "white",
    padding: "0.2rem",
    cursor: "pointer",
    "&:hover": {
      // this is backgroundColor on hover
      backgroundColor: "#438427"
    }
  },
  saveText: {
    color: "white",
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
    marginLeft: "0.5rem",
    borderRadius: "0.25rem"
  },
  resize: {
    fontSize: "0.85rem"
  },
  cssLabel: {
    "&$cssFocused": {
      color: "#ffffff"
    },
    color: "#ffffff"
  },
  cssOutlinedInput: {
    $notchedOutline: {
      borderColor: "#ffffff"
    },
    borderColor: "#ffffff",
    color: "#ffffff"
  },
  cssFocused: {
    "&$cssFocused": {
      color: "#ffffff"
    },
    color: "#ffffff",
    borderColor: "#ffffff"
  },
  // align TablePagination component to left
  TablePaginationAlignleft: {
    flex: 0
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  }
});
