import React, { useState } from 'react';
import Dropzone from 'react-dropzone';

import {
  Box,
  Typography,
  makeStyles,
  TextField,
  InputAdornment,
  Button,
} from '@material-ui/core';

import MomentUtils from '@date-io/moment';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import EventTwoToneIcon from '@material-ui/icons/Event';
import PublishIcon from '@material-ui/icons/Publish';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    marginBottom: '0.5rem',
  },
  common: {
    fontWeight: 500,
  },
  requiredStar: {
    color: 'red',
  },
  inputLabel: {
    marginBottom: '0.5rem',
    fontSize: '85%',
    fontWeight: '500',
  },
  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
    marginInlineStart: 'inherit',
  },
  nextBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'capitalize',
  },
  clear: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textAlign: 'end',
    marginTop: '2rem',
  },
  fileIcon: {
    cursor: 'pointer',
    marginLeft: '24rem',
    fontSize: 'larger',
  },
  borderBox: {
    width: '100%',
    border: '2px solid grey',
    padding: '5px',
    margin: 'auto',
    marginTop: '2rem',
  },
  files: {
    boder: '1px solid #707070',
    backgroundColor: '#F7F7F7',
    textAlign: 'center',
    padding: '1rem',
  },
}));

const Action = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({
    loadingProducts: false,
  });

  const {
    analysisTitle,
    analysisStartDate,
    analysisEndDate,
    teamOptions,
    selectedAnalysisLead,
    rootCause,
    customFiles,
    errors,
  } = props.stateData;

  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD/MM/YYYY';

  let eventDetails =
    props.qualityEventRecordDetails.qualityEventRecordDetails
      ?.qualityEventDetails;

  const handleFileUpload = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        let fileObject = {
          name: file.name,
          data: reader.result,
          customFileType: 'buffer',
          schrockenFileID: '',
          schrockenCustomFileID: '',
          customFileHash: '',
        };
        props.handleOnChangeData('customFiles', [...customFiles, fileObject]);
      };
    });
  };

  const handleFileDelete = (index) => {
    const edit_newFiles = customFiles.filter((file, i) => i !== index);
    props.handleOnChangeData('customFiles', edit_newFiles);
  };

  return (
    <>
      <Box width='40%' margin='auto'>
        <Box className={classes.borderBox}>
          <Box>
            <Typography variant='h5' className={classes.title}>
              Event Details
            </Typography>
            <hr className={classes.hrLine} />
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <Box>
              <Typography className={classes.common}>Event ID</Typography>
              <Typography>{eventDetails?.eventID}</Typography>
            </Box>{' '}
            <Box>
              <Typography className={classes.common}>Event Type</Typography>
              <Typography>{eventDetails?.eventType}</Typography>
            </Box>{' '}
            <Box>
              <Typography className={classes.common}>Raised By</Typography>
              <Typography>{eventDetails?.incidentRaisedBy}</Typography>
            </Box>{' '}
            <Box>
              <Typography className={classes.common}>Raised For</Typography>
              <Typography>{eventDetails?.incidentFor}</Typography>
            </Box>
          </Box>
        </Box>
        <Box marginTop={3}>
          <Typography variant='body2' className={classes.inputLabel}>
            Analysis Title <span className={classes.requiredStar}>*</span>
          </Typography>
          <TextField
            fullWidth
            name='analysisTitle'
            value={analysisTitle}
            variant='outlined'
            size='small'
            placeholder='Give this event a title'
            onChange={(e) =>
              props.handleOnChangeData('analysisTitle', e.target.value)
            }
          />
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          paddingTop={2}
        >
          <Box width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Start Date
              <span className={classes.requiredStar}>*</span>
            </Typography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                fullWidth
                variant='inline'
                inputVariant='outlined'
                format={dateFormat}
                placeholder='Select Date'
                name='analysisStartDate'
                value={analysisStartDate}
                size='small'
                autoOk={true}
                maxDate={new Date()}
                onChange={(value) =>
                  props.handleOnChangeData('analysisStartDate', value)
                }
                InputProps={{
                  endAdornment: (
                    <EventTwoToneIcon
                      style={{ color: '#0000008a', cursor: 'pointer' }}
                    />
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>

          <Box width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              End Date <span className={classes.requiredStar}>*</span>
            </Typography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                fullWidth
                variant='inline'
                inputVariant='outlined'
                format={dateFormat}
                placeholder='Select Date'
                size='small'
                autoOk={true}
                minDate={analysisStartDate}
                name='analysisEndDate'
                value={analysisEndDate}
                onChange={(value) =>
                  props.handleOnChangeData('analysisEndDate', value)
                }
                InputProps={{
                  endAdornment: (
                    <EventTwoToneIcon
                      style={{ color: '#0000008a', cursor: 'pointer' }}
                    />
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Box>
        <Box marginTop='2rem'>
          <Typography variant='body2' className={classes.inputLabel}>
            Analysis Lead <span className={classes.requiredStar}>*</span>
          </Typography>
          <Autocomplete
            size='small'
            fullWidth
            variant='outlined'
            name='teamLead'
            options={teamOptions}
            getOptionLabel={(option) =>
              option.userName ? `${option.userName}(${option.roleName})` : ''
            }
            value={selectedAnalysisLead}
            onChange={(event, value) => {
              props.handleOnChangeData('selectedAnalysisLead', value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label=''
                placeholder='Start typing to search and select'
                error={!!errors.teamLead}
                helperText={errors.teamLead}
              />
            )}
          />
        </Box>
        <Box paddingTop={2}>
          <Typography variant='body2' className={classes.inputLabel}>
            Description <span className={classes.requiredStar}>*</span>
          </Typography>
          <TextField
            rows={4}
            multiline
            fullWidth
            variant='outlined'
            name='rootCause'
            value={rootCause}
            onChange={(e) =>
              props.handleOnChangeData('rootCause', e.target.value)
            }
            placeholder='Enter root cause of the event as much as you can.'
          />
        </Box>

        <Box marginTop={2} width='100%'>
          <Typography variant='body2' className={classes.inputLabel}>
            Upload necessary files for this event
          </Typography>
          <Dropzone
            onDrop={(files) => {
              handleFileUpload(files);
            }}
            accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps({
                  className: 'dropzone',
                  onDrop: (event) => event.preventDefault(),
                })}
                className={classes.files}
                style={{ border: '1px solid #707070' }}
              >
                <input {...getInputProps()} />
                <Typography variant='body2' style={{ cursor: 'pointer' }}>
                  Drag &amp; drop or click here to upload files
                </Typography>
                <InputAdornment position='end' className={classes.fileIcon}>
                  <PublishIcon
                    fontSize='large'
                    style={{ marginBottom: '1rem', marginLeft: '7rem' }}
                  />
                </InputAdornment>
              </div>
            )}
          </Dropzone>

          <Typography variant='body' className={classes.font}>
            {customFiles.map((file, i) => (
              <p style={{ color: 'blue' }}>
                <u>{file.name}</u>
                <span style={{ verticalAlign: 'sub' }}>
                  <CloseIcon
                    fontSize='small'
                    color='error'
                    style={{
                      paddingLeft: '0.5rem',
                      paddingTop: '0.5rem',
                      cursor: 'pointer',
                      width: '19px',
                    }}
                    onClick={() => handleFileDelete(i)}
                  />
                </span>
              </p>
            ))}
          </Typography>
        </Box>

        <Box
          width='90%'
          textAlign='center'
          marginTop='2rem'
          marginBottom='2rem'
        >
          <Button
            className={classes.nextBtn}
            variant='contained'
            color='primary'
            endIcon={<NavigateNextIcon />}
            onClick={props.handleLaunchCapa}
          >
            Launch CAPA
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default Action;
