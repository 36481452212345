import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

//files
import CMOFilter from './CMOFilter';
import StatusFilter from './StatusFilter';
import { useStyles } from './saslStyles';

const Filter = (props) => {
    const classes = useStyles();
    const {
        cmoFilter,
        handleSelectCMO,
        CMOMember,
        status,
        handleSelectStatus,
        statusArray
    } = props;

    return (
        <Grid container spacing={2}>
            {cmoFilter && (
                <Grid item xs={12} sm={6}>
                    <Typography variant='body2' className={classes.hgText}>
                        Filter by:
                    </Typography>
                    <CMOFilter handleSelectCMO={handleSelectCMO} CMOMember={CMOMember} />
                </Grid>
            )}
            <Grid item xs={12} sm={6}>
                <Typography variant='body2' className={classes.hgText}>
                    View by:
                </Typography>
                <StatusFilter status={status} handleSelectStatus={handleSelectStatus} statusArray={statusArray} />
            </Grid>
        </Grid>
    );
};

export default Filter;
