import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import StyledButton from '../../../../common/models/Button';

import EnterRecordDetails from './EnterRecordDetails';
import ReviewRecord from './ReviewRecord';
import CheckBoxButton from '../CheckboxAndButton';

import Dialog from '@material-ui/core/Dialog';
import StyledDialog from '../../../../common/models/Dialog';
//functions
import {
  updateGRN,
  fetchUOM,
} from '../../../containers/actions/cmo/shortFallsActions';
import { SupplierPOContext } from '../grnReceipts/context';
//test backdrop
// import BackDropComponent from '../models/BackDrop';

//alert dialog
import QuantityAlertDialog from '../supplierPOUpdate/DeletePromptDialog';
import DeleteAlertDialog from '../supplierPOUpdate/DeletePromptDialog';

import moment from 'moment';

const Styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  backgroundGrid: {
    backgroundColor: '#ffffff',
    borderRadius: '1rem',
  },
  bottomgrid: {
    padding: '2rem 0',
  },
  hidden: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  clear: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2rem',
    },
  },
  step: {
    '& $completed': {
      color: '#008002',
    },
  },
  //the following enables ovverride and add other custom styles
  completed: {},
});

function getSteps() {
  return ['Enter Record Details', 'Review & Submit Record'];
}

class AddRecord extends Component {
  static contextType = SupplierPOContext;
  constructor(props) {
    super(props);
    this.state = {
      //for conditional render
      activeStep: 0,
      disableNext: false,
      disableBack: false,
      errors: {},
      //new state
      POBatchRecordDetails: {
        schrockenSupplierPOBatchID:
          this.props.GRData.schrockenSupplierPOBatchID,
        supplierPOBatchID: '',
        numberGRN: '',
        numberQC: '',
        costCenterNumber: '',
        numberMRN: '',
        transporterName: '',
        lorryReceiptNumber: '',
        lorryReceiptDate: null,
        approvedBy: '',
        batchStatusID: this.props.GRData.batchStatusID,
        batchStatusText: this.props.GRData.batchStatusText,
        materialReceivedDate: this.props.GRData.materialReceivedDate,
        otherInformationRemarks: '',
        otherFiles: [],
        isSubmitForApproval: false,
        isExceedQuantityAllowed: false,
      },
      POLineRecordDetails: this.props.POLineRecordDetails,
      qualityAssuranceInfo: {
        unitOfMeasurementQA: this.props.POLineRecordDetails.unitOfMeasureSKU,
        samplingQuantityQA: '',
        isQACompleted: true,
        analyticalReportNumber: '',
        analyticalReportDate: null,
        materialManufactureDate: null,
        expirationDate: null,
        batchNumberQA: '',
        samplingMaterialNumberQA: '',
        COAFiles: [],
      },
      receivedQuantityInfo: {
        actualQuantityReceived: '',
        unitOfMeasurementReceived:
          this.props.POLineRecordDetails.unitOfMeasurementOrdered,
        quantityYetToReceive: 0,
        containerDetails: [],
      },
      challanInformation: {
        challanNumber: '',
        challanDate: null,
        challanFiles: [],
        ewayBillNumber: '',
        ewayBillDate: null,
      },
      invoiceInformation: {
        invoiceNumber: '',
        invoiceDate: null,
        invoiceFiles: [],
      },
      //record
      tempQACompleted: false,
      quantityYetToReceive: '',

      //Edit Dialog
      openPOItem: false,
      openReceived: false,
      openQuality: false,
      openChallan: false,
      openInvoice: false,
      openOtherInfo: false,
      //consent
      verifyConsent: false,
      //record saved
      recordSaved: false,
      submitRecord: false,

      resError: null,
      //appending file for duplicates
      OtherInfoTempNumber: 0,
      challanTempNumber: 0,
      invoiceTempNumber: 0,
      qualityTempNumber: 0,
      //new Backdrop
      addRecord: false,
      //popup dialog to alert user for quantity > ordered quantity
      quantityAlert: false,
      resQuantityAlert: false,
      //popup dialog to alert user of deleting the file
      deletePrompt: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.updateGRNData !== prevProps.updateGRNData &&
      this.props.updateGRNData.status === false
    ) {
      let errorKey = Object.keys(this.props.updateGRNData.errorDescription)[0];
      this.setState({
        resError: this.props.updateGRNData.errorDescription[errorKey],
        errors: {
          [errorKey]: true,
        },
        addRecord: false,
      });
    }
    if (
      this.state.POBatchRecordDetails.isSubmitForApproval === true &&
      this.props.updateGRNData !== prevProps.updateGRNData &&
      this.props.updateGRNData.status === true
    ) {
      this.setState({
        submitRecord: true,
        recordSaved: false,
        resError: null,
        errors: {},
        addRecord: false,
      });
    }
    if (
      this.state.POBatchRecordDetails.isSubmitForApproval === false &&
      this.props.updateGRNData !== prevProps.updateGRNData &&
      this.props.updateGRNData.status === true
    ) {
      this.setState({
        recordSaved: true,
        submitRecord: false,
        resError: null,
        errors: {},
        addRecord: false,
      });
    }
    //quantity Alert
    if (
      this.props.updateGRNData !== prevProps.updateGRNData &&
      this.props.updateGRNData.status === 103
    ) {
      this.setState({
        resError: null,
        errors: {},
        resQuantityAlert: this.props.QuantityResponseAlert,
      });
    }
  }

  handleNext = () => {
    this.checkQuantity();
    // return this.setState({
    //   activeStep: this.state.activeStep + 1,
    // });
  };

  handleChange = (e, name) => {
    if (name === 'numberMRN') {
      this.setState({
        POBatchRecordDetails: {
          ...this.state.POBatchRecordDetails,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'transporterName') {
      this.setState({
        POBatchRecordDetails: {
          ...this.state.POBatchRecordDetails,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'lorryReceiptNumber') {
      this.setState({
        POBatchRecordDetails: {
          ...this.state.POBatchRecordDetails,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'actualQuantityReceived') {
      if (e.target.value < 0) {
        alert('Invalid Quantity');
        this.setState({
          receivedQuantityInfo: {
            ...this.state.receivedQuantityInfo,
            [e.target.name]: '',
          },
          resError: null,
          errors: {},
        });
      } else {
        this.setState({
          receivedQuantityInfo: {
            ...this.state.receivedQuantityInfo,
            [e.target.name]: e.target.value,
          },
          resError: null,
          errors: {},
        });
      }
    }
    if (name === 'samplingQuantityQA') {
      if (e.target.value < 0) {
        alert('Invalid QA Sampling Quantity');
        this.setState({
          qualityAssuranceInfo: {
            ...this.state.qualityAssuranceInfo,
            [e.target.name]: '',
          },
          resError: null,
          errors: {},
        });
      } else {
        this.setState({
          qualityAssuranceInfo: {
            ...this.state.qualityAssuranceInfo,
            [e.target.name]: parseFloat(e.target.value),
          },
          resError: null,
          errors: {},
        });
      }
    }
    if (name === 'analyticalReportNumber') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'analyticalReportNumber') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'batchNumberQA') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'samplingMaterialNumberQA') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'challanNumber' || name === 'ewayBillNumber') {
      this.setState({
        challanInformation: {
          ...this.state.challanInformation,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'invoiceNumber') {
      this.setState({
        invoiceInformation: {
          ...this.state.invoiceInformation,
          [e.target.name]: e.target.value,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'otherInformationRemarks') {
      let { value } = e.target;
      this.setState((prevState) => ({
        POBatchRecordDetails: {
          ...prevState.POBatchRecordDetails,
          [name]: value,
        },
        resError: null,
        errors: {},
      }));
    }
  };

  handleDateChange = (date, name) => {
    if (name === 'materialReceivedDate' || name === 'lorryReceiptDate') {
      this.setState({
        POBatchRecordDetails: {
          ...this.state.POBatchRecordDetails,
          [name]: date != null ? date.toISOString() : date,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'analyticalReportDate') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          analyticalReportDate: date != null ? date.toISOString() : date,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'materialManufactureDate') {
      const { expirationDate } = this.state.qualityAssuranceInfo;
      let autoAddExpiry =
        this.props.rmPmCustomFeaturesAndValidations.validations?.QAInfo.PM
          .autoAdd5YearsToExpiry.required;
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          materialManufactureDate: date != null ? date.toISOString() : date,
          // update expiry date for PM and when it is enabled in ecosystem config
          // VERP - materialType of PACKAGING MATERIAL
          expirationDate:
            expirationDate !== null
              ? expirationDate
              : date !== null &&
                this.props.POLineRecordDetails.materialType === 'VERP' &&
                autoAddExpiry === true
              ? moment(date).add(60, 'months').toISOString()
              : null,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'expirationDate') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          expirationDate: date != null ? date.toISOString() : date,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'challanDate' || name === 'ewayBillDate') {
      this.setState({
        challanInformation: {
          ...this.state.challanInformation,
          [name]: date != null ? date.toISOString() : date,
        },
        resError: null,
        errors: {},
      });
    }
    if (name === 'invoiceDate') {
      this.setState({
        invoiceInformation: {
          ...this.state.invoiceInformation,
          invoiceDate: date != null ? date.toISOString() : date,
        },
        resError: null,
        errors: {},
      });
    }
  };

  handleQA = (event) => {
    this.setState((prevState) => ({
      qualityAssuranceInfo: {
        ...prevState.qualityAssuranceInfo,
        isQACompleted: !prevState.qualityAssuranceInfo.isQACompleted,
        // unitOfMeasurementQA: '',
        // samplingQuantityQA: '',
        // isQACompleted: true,
        analyticalReportNumber: '',
        analyticalReportDate: null,
        materialManufactureDate: null,
        expirationDate: null,
        batchNumberQA: '',
        samplingMaterialNumberQA: '',
        COAFiles: [],
      },

      resError: null,
      errors: {},
    }));
  };

  handleClearData = () => {
    this.setState({
      //for conditional render
      activeStep: 0,
      disableNext: false,
      disableBack: false,
      errors: {},
      //new state
      POBatchRecordDetails: {
        schrockenSupplierPOBatchID:
          this.props.GRData.schrockenSupplierPOBatchID,
        supplierPOBatchID: '',
        numberGRN: '',
        numberQC: '',
        costCenterNumber: '',
        numberMRN: '',
        transporterName: '',
        lorryReceiptNumber: '',
        lorryReceiptDate: null,
        approvedBy: '',
        batchStatusID: this.props.GRData.batchStatusID,
        batchStatusText: this.props.GRData.batchStatusText,
        materialReceivedDate: this.props.GRData.materialReceivedDate,
        otherInformationRemarks: '',
        otherFiles: [],
        //for submission
        isSubmitForApproval: false,
        isExceedQuantityAllowed: false,
      },
      qualityAssuranceInfo: {
        unitOfMeasurementQA: '',
        samplingQuantityQA: '',
        isQACompleted: true,
        analyticalReportNumber: '',
        analyticalReportDate: null,
        materialManufactureDate: null,
        expirationDate: null,
        batchNumberQA: '',
        samplingMaterialNumberQA: '',
        COAFiles: [],
      },
      receivedQuantityInfo: {
        actualQuantityReceived: '',
        containerDetails: [],
      },
      challanInformation: {
        challanNumber: '',
        challanDate: null,
        challanFiles: [],
        ewayBillNumber: '',
        ewayBillDate: null,
      },
      invoiceInformation: {
        invoiceNumber: '',
        invoiceDate: null,
        invoiceFiles: [],
      },
      //record
      tempQACompleted: false,
      //recieved
      unitOfMeasurementReceived: '',
      actualQuantityReceived: '',
      quantityYetToReceive: '',

      //Edit Dialog
      openPOItem: false,
      openReceived: false,
      openQuality: false,
      openChallan: false,
      openInvoice: false,
      openOtherInfo: false,
      //consent
      verifyConsent: false,
      //record saved
      recordSaved: false,
      submitRecord: false,

      resError: null,
      //appending file for duplicates
      OtherInfoTempNumber: 0,
      challanTempNumber: 0,
      invoiceTempNumber: 0,
      qualityTempNumber: 0,
    });
  };

  handleVerifyConsent = () => {
    this.setState({
      verifyConsent: !this.state.verifyConsent,
      errors: {},
      resError: null,
    });
  };

  getContainers = () => {
    const { receivedQuantityInfo } = this.state;
    const { rmPmCustomFeaturesAndValidations } = this.props;
    if (receivedQuantityInfo.containerDetails?.length > 0) {
      /* when container data feature is not available for ecosystem or 
  when there is no data to be added in selected container 
  then remove the object from state array */
      if (
        !rmPmCustomFeaturesAndValidations.features.containerDetailsEnabled ||
        (receivedQuantityInfo.containerDetails.length === 1 &&
          !receivedQuantityInfo.containerDetails[0].numberOfContainers &&
          !receivedQuantityInfo.containerDetails[0].quantityPerContainer)
      ) {
        return [];
      } else {
        return receivedQuantityInfo.containerDetails;
      }
    } else {
      return [];
    }
  };
  getPendingQty = () => {
    if (
      this.state.POLineRecordDetails.quantityYetToReceive <
      this.state.receivedQuantityInfo.actualQuantityRecieved
    ) {
      return 0;
    } else {
      return parseFloat(
        Number(
          this.state.POLineRecordDetails.quantityYetToReceive -
            this.state.receivedQuantityInfo.actualQuantityReceived
        ).toFixed(3)
      );
    }
  };
  validate = () => {
    let error = false;
    const {
      POBatchRecordDetails,
      qualityAssuranceInfo,
      receivedQuantityInfo,
      invoiceInformation,
    } = this.state;
    const { rmPmCustomFeaturesAndValidations } = this.props;

    let errors = {};

    if (POBatchRecordDetails.materialReceivedDate === null) {
      error = true;
      errors.materialReceivedDate = true;
      this.setState({
        resError: 'Please enter material received Date',
        addRecord: false,
      });
    } else if (POBatchRecordDetails.numberMRN === '' || null) {
      error = true;
      errors.numberMRN = true;
      this.setState({ resError: 'Please enter MRN Number', addRecord: false });
    } else if (receivedQuantityInfo.actualQuantityReceived === '' || null) {
      error = true;
      errors.actualQuantityReceived = true;
      this.setState({
        resError: 'Please enter actual quantity received',
        addRecord: false,
      });
    } else if (qualityAssuranceInfo.samplingQuantityQA === '' || null) {
      error = true;
      errors.samplingQuantityQA = true;
      this.setState({
        resError: 'Please enter sampling quantity QA',
        addRecord: false,
      });
    } else if (qualityAssuranceInfo.isQACompleted === false) {
      error = true;
      errors.isQACompleted = true;
      this.setState({
        resError: 'Please select if QA is done',
        addRecord: false,
      });
    } else if (
      qualityAssuranceInfo.analyticalReportNumber === '' ||
      qualityAssuranceInfo.analyticalReportNumber === null
    ) {
      error = true;
      errors.analyticalReportNumber = true;
      this.setState({
        resError: 'Please enter analytical report number',
        addRecord: false,
      });
    } else if (
      qualityAssuranceInfo.materialManufactureDate === null &&
      rmPmCustomFeaturesAndValidations.validations.QAInfo[
        this.props.POLineRecordDetails.materialType === 'ROH' ? 'RM' : 'PM'
      ].mfgDate.required
    ) {
      error = true;
      errors.manufacturingDate = true;
      this.setState({
        resError: 'Please enter Material Manufacturing Date',
        addRecord: false,
      });
    } else if (
      qualityAssuranceInfo.expirationDate === null &&
      rmPmCustomFeaturesAndValidations.validations.QAInfo[
        this.props.POLineRecordDetails.materialType === 'ROH' ? 'RM' : 'PM'
      ].expDate.required
    ) {
      error = true;
      errors.expirationDate = true;
      this.setState({
        resError: 'Please enter Expiration Date',
        addRecord: false,
      });
    } else if (
      qualityAssuranceInfo.expirationDate &&
      (qualityAssuranceInfo.materialManufactureDate ===
        qualityAssuranceInfo.expirationDate ||
        qualityAssuranceInfo.expirationDate <
          qualityAssuranceInfo.materialManufactureDate)
    ) {
      error = true;
      errors.expirationDate = true;
      this.setState({
        resError: 'Please enter valid expiration Date',
        addRecord: false,
      });
    }
    // //shelfLife
    else if (
      qualityAssuranceInfo.materialManufactureDate &&
      qualityAssuranceInfo.expirationDate &&
      this.props.POLineRecordDetails.shelfLifeUnitIndicator &&
      moment(qualityAssuranceInfo.expirationDate).diff(
        qualityAssuranceInfo.materialManufactureDate,
        this.props.POLineRecordDetails.shelfLifeUnitIndicator
      ) < this.props.POLineRecordDetails.minShelfLife
    ) {
      error = true;
      errors.expirationDate = true;
      this.setState({
        resError: 'Please enter valid Expiration Date',
        addRecord: false,
      });
    }
    //coa file validation
    else if (
      qualityAssuranceInfo.COAFiles.length === 0 &&
      rmPmCustomFeaturesAndValidations.validations.QAInfo.coaFile.required
    ) {
      error = true;
      errors.COAFiles = true;
      this.setState({
        resError: 'Please attach COA file',
        addRecord: false,
      });
    }
    // RAW MAterial validation
    else if (
      this.props.POLineRecordDetails.materialType === 'ROH' &&
      qualityAssuranceInfo.batchNumberQA === ''
    ) {
      error = true;
      errors.batchNumberQA = true;
      this.setState({
        resError: 'Please enter Batch Number QA',
        addRecord: false,
      });
    } else if (
      rmPmCustomFeaturesAndValidations.validations.invoiceInfo.invoiceNumber
        .required &&
      !invoiceInformation.invoiceNumber.trim()
    ) {
      error = true;
      errors.invoiceNumber = true;
      this.setState({
        resError: 'Please enter Invoice Number',
        addRecord: false,
      });
    } else if (
      rmPmCustomFeaturesAndValidations.validations.invoiceInfo.invoiceDate
        .required &&
      !invoiceInformation.invoiceDate
    ) {
      error = true;
      errors.invoiceDate = true;
      this.setState({
        resError: 'Please enter Invoice Date',
        addRecord: false,
      });
    }

    //if needed  add container validations here
    this.setState({ errors });
    return error;
  };

  handleCloseRecord = () => {
    this.setState({
      submitRecord: false,
      recordSaved: false,
      resError: null,
      errors: {},
    });
    this.context.fetchGRLineItems();
    this.props.handleClose();
  };

  handleSaveRecord = (type) => {
    let backdropText = 'Please Wait. New record is being saved';
    this.setState({
      resError: null,
      errors: {},
      addRecord: true,
    });
    const {
      POLineRecordDetails,
      POBatchRecordDetails,
      receivedQuantityInfo,
      invoiceInformation,
      qualityAssuranceInfo,
      challanInformation,
    } = this.state;

    let data = {
      PODetails: {
        POLineRecordDetails,
        GRDetails: {
          POBatchRecordDetails: {
            ...POBatchRecordDetails,
            isSubmitForApproval: type === 'submit' ? true : false,
          },
          receivedQuantityInfo: {
            ...receivedQuantityInfo,
            actualQuantityReceived: parseFloat(
              this.state.receivedQuantityInfo.actualQuantityReceived
            ),
            quantityYetToReceive: this.getPendingQty(),
            containerDetails: this.getContainers(),
          },
          qualityAssuranceInfo: {
            ...qualityAssuranceInfo,
            samplingQuantityQA: this.state.qualityAssuranceInfo
              .samplingQuantityQA
              ? parseFloat(this.state.qualityAssuranceInfo.samplingQuantityQA)
              : 0,
          },
          challanInformation,
          invoiceInformation,
        },
      },
    };
    if (type === 'submit') {
      backdropText = 'Please Wait. New record is being submitted for approval';

      let isError = this.validate();
      if (!isError && this.state.verifyConsent === false) {
        this.setState({
          errors: {
            verifyConsent: true,
          },
          addRecord: false,
        });
      } else if (!isError && this.state.verifyConsent) {
        this.props.updateGRN(data, backdropText);
      }
    } else {
      this.props.updateGRN(data, backdropText);
    }
  };

  handlingFiles = (file, name) => {
    if (name === 'COAFiles') {
      this.setState({
        qualityAssuranceInfo: {
          ...this.state.qualityAssuranceInfo,
          COAFiles: [...this.state.qualityAssuranceInfo.COAFiles, file],
        },
      });
    }
    if (name === 'challanFiles') {
      this.setState({
        challanInformation: {
          ...this.state.challanInformation,
          challanFiles: [...this.state.challanInformation.challanFiles, file],
        },
      });
    }
    if (name === 'InvoiceFiles') {
      this.setState({
        invoiceInformation: {
          ...this.state.invoiceInformation,
          invoiceFiles: [...this.state.invoiceInformation.invoiceFiles, file],
        },
      });
    }
    if (name === 'otherFiles') {
      this.setState({
        POBatchRecordDetails: {
          ...this.state.POBatchRecordDetails,
          otherFiles: [...this.state.POBatchRecordDetails.otherFiles, file],
        },
      });
    }
    // this.setState((prevState, props) => ({
    //   COAFiles:[ prevState.COAFiles,file]
    //   // COAFiles:file
    // }));
  };

  handlingDeleteFile = (i, name) => {
    if (name === 'COAFiles') {
      if (
        window.confirm(
          'You are about to delete a file ,would you like to proceed ?'
        )
      ) {
        if (
          this.state.qualityAssuranceInfo.COAFiles[i].grFileType === 'buffer'
        ) {
          let newdel = this.state.qualityAssuranceInfo.COAFiles.filter(
            (file) =>
              file.grFileName !==
              this.state.qualityAssuranceInfo.COAFiles[i].grFileName
          );
          this.setState({
            qualityAssuranceInfo: {
              ...this.state.qualityAssuranceInfo,
              COAFiles: newdel,
              deletePrompt: false,
            },
          });
        }
      }
    }

    //challan files deleting in Parent File
    if (name === 'challanFiles') {
      if (
        window.confirm(
          'You are about to delete a file ,would you like to proceed ?'
        )
      ) {
        if (
          this.state.challanInformation.challanFiles[i].grFileType === 'buffer'
        ) {
          let newdel = this.state.challanInformation.challanFiles.filter(
            (file) =>
              file.grFileName !==
              this.state.challanInformation.challanFiles[i].grFileName
          );
          this.setState({
            challanInformation: {
              ...this.state.challanInformation,
              challanFiles: newdel,
              deletePrompt: false,
            },
          });
        }
      }
    }

    //invoice files deleting in Parent File
    if (name === 'invoiceFiles') {
      if (
        window.confirm(
          'You are about to delete a file ,would you like to proceed ?'
        )
      ) {
        if (
          this.state.invoiceInformation.invoiceFiles[i].grFileType === 'buffer'
        ) {
          let newdel = this.state.invoiceInformation.invoiceFiles.filter(
            (file) =>
              file.grFileName !==
              this.state.invoiceInformation.invoiceFiles[i].grFileName
          );
          this.setState({
            invoiceInformation: {
              ...this.state.invoiceInformation,
              invoiceFiles: newdel,
              deletePrompt: false,
            },
          });
        }
      }
    }

    //invoice files deleting in Parent File
    if (name === 'otherFiles') {
      if (
        window.confirm(
          'You are about to delete a file ,would you like to proceed ?'
        )
      ) {
        if (
          this.state.POBatchRecordDetails.otherFiles[i].grFileType === 'buffer'
        ) {
          let newdel = this.state.POBatchRecordDetails.otherFiles.filter(
            (file) =>
              file.grFileName !==
              this.state.POBatchRecordDetails.otherFiles[i].grFileName
          );
          this.setState({
            POBatchRecordDetails: {
              ...this.state.POBatchRecordDetails,
              otherFiles: newdel,
              deletePrompt: false,
            },
          });
        }
      }
    }
  };

  handleSavePOItemData = (data) => {
    this.setState({
      POBatchRecordDetails: data,
      resError: null,
      errors: {},
    });
    this.handleCloseSectionDialog('openPOItem');
  };

  handleSaveReceivedQuantityData = (data, exceed) => {
    this.setState({
      receivedQuantityInfo: data,
      resError: null,
      errors: {},
    });
    if (exceed) {
      this.setState({
        receivedQuantityInfo: data,
        POBatchRecordDetails: {
          ...this.state.POBatchRecordDetails,
          isExceedQuantityAllowed: true,
        },
        resError: null,
        errors: {},
      });
    }

    this.handleCloseSectionDialog('openReceived');
  };
  handleSaveQualityAssuranceData = (data, number) => {
    this.setState({
      qualityAssuranceInfo: data,
      resError: null,
      errors: {},
      qualityTempNumber: number,
    });
    this.handleCloseSectionDialog('openQuality');
  };
  handleSaveChallanInformationData = (data, number) => {
    this.setState({
      challanInformation: data,
      resError: null,
      errors: {},
      challanTempNumber: number,
    });
    this.handleCloseSectionDialog('openChallan');
  };
  handleSaveInvoiceInformationData = (data, number) => {
    this.setState({
      invoiceInformation: data,
      resError: null,
      errors: {},
      invoiceTempNumber: number,
    });
    this.handleCloseSectionDialog('openInvoice');
  };
  handleSaveOtherInformationData = (data, number) => {
    this.setState({
      POBatchRecordDetails: data,
      resError: null,
      errors: {},
      OtherInfoTempNumber: number,
    });
    this.handleCloseSectionDialog('openOtherInfo');
  };

  handleOpenDialog = (name) => {
    this.setState({
      [name]: true,
    });
  };

  handleCloseSectionDialog = (name) => {
    this.setState({
      [name]: false,
    });
  };

  handleTempNumber = (name) => {
    if (name === 'OtherInfoTempNumber') {
      this.setState({
        OtherInfoTempNumber: this.state.OtherInfoTempNumber + 1,
      });
    }
    if (name === 'challanTempNumber') {
      this.setState({ challanTempNumber: this.state.challanTempNumber + 1 });
    }
    if (name === 'invoiceTempNumber') {
      this.setState({ invoiceTempNumber: this.state.invoiceTempNumber + 1 });
    }
    if (name === 'qualityTempNumber') {
      this.setState({ qualityTempNumber: this.state.qualityTempNumber + 1 });
    }
  };

  //to show dialog if actual quantity is greater than total received quantity
  checkQuantity = (e) => {
    const { actualQuantityReceived } = this.state.receivedQuantityInfo;
    const { quantityOrdered } = this.props.POLineRecordDetails;

    if (actualQuantityReceived > quantityOrdered) {
      this.setState({
        // quantityAlert: true,
        resError: null,
        errors: {},
        activeStep: this.state.activeStep + 1,
      });
    } else {
      return this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };

  closeQuantityAlert = () => {
    this.setState({
      quantityAlert: false,
      resQuantityAlert: false,
    });
  };

  handleYesQuantity = () => {
    this.setState({
      activeStep: this.state.activeStep + 1,
      quantityAlert: false,
      POBatchRecordDetails: {
        ...this.state.POBatchRecordDetails,
        isExceedQuantityAllowed: true,
      },
      receivedQuantityInfo: {
        ...this.state.receivedQuantityInfo,
        quantityYetToReceive: 0,
      },
    });
  };

  handleDeletePrompt = () => {
    this.setState({
      deletePrompt: true,
    });
  };

  handleCloseDeletePrompt = () => {
    this.setState({
      deletePrompt: false,
    });
  };
  //handle contianer info
  handleContainerData = (data) => {
    this.setState((prevState) => ({
      receivedQuantityInfo: {
        ...prevState.receivedQuantityInfo,
        containerDetails: data,
      },
    }));
  };
  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const {
      activeStep,
      errors,
      POBatchRecordDetails,
      POLineRecordDetails,
      receivedQuantityInfo,
      qualityAssuranceInfo,
      challanInformation,
      invoiceInformation,

      unitOfMeasurementQA,
      //edit dialog
      openPOItem,
      openReceived,
      openQuality,
      openChallan,
      openInvoice,
      openOtherInfo,
      //consent
      verifyConsent,
      //record saved
      recordSaved,
      //record submitted
      submitRecord,
      resError,
    } = this.state;

    const getStepContent = (stepIndex) => {
      switch (stepIndex) {
        case 0:
          return (
            <EnterRecordDetails
              activeStep={activeStep}
              //section props
              POLineRecordDetails={POLineRecordDetails}
              POBatchRecordDetails={POBatchRecordDetails}
              //received
              receivedQuantityInfo={receivedQuantityInfo}
              qualityAssuranceInfo={qualityAssuranceInfo}
              //challan
              challanInformation={challanInformation}
              //invoice
              invoiceInformation={invoiceInformation}
              //fn
              handleSavePOItemData={this.handleSavePOItemData}
              handleSaveReceivedQuantityData={
                this.handleSaveReceivedQuantityData
              }
              handleContainerData={this.handleContainerData}
              handleChange={this.handleChange}
              handleDateChange={this.handleDateChange}
              handlingFiles={this.handlingFiles}
              parentHandlingDeleteFile={this.handlingDeleteFile}
              handleQA={this.handleQA}
              //appending file
              OtherInfoTempNumber={this.state.OtherInfoTempNumber}
              handleTempNumber={this.handleTempNumber}
              challanTempNumber={this.state.challanTempNumber}
              invoiceTempNumber={this.state.invoiceTempNumber}
              qualityTempNumber={this.state.qualityTempNumber}
            />
          );
        case 1:
          return (
            <ReviewRecord
              activeStep={activeStep}
              unitOfMeasurementReceived={this.props.unitOfMeasurementReceived}
              handleChange={this.handleChange}
              handleDateChange={this.handleDateChange}
              unitOfMeasurementQA={unitOfMeasurementQA}
              handleQA={this.handleQA}
              openPOItem={openPOItem}
              openReceived={openReceived}
              openQuality={openQuality}
              openChallan={openChallan}
              openInvoice={openInvoice}
              openOtherInfo={openOtherInfo}
              resError={resError}
              quantityYetToReceive={this.props.quantityYetToReceive}
              //files
              handlingFiles={this.handlingFiles}
              handlingDeleteFile={this.handlingDeleteFile}
              //new Props
              POLineRecordDetails={POLineRecordDetails}
              POBatchRecordDetails={POBatchRecordDetails}
              handleOpenDialog={this.handleOpenDialog}
              handleCloseSectionDialog={this.handleCloseSectionDialog}
              handleSavePOItemData={this.handleSavePOItemData}
              errors={errors}
              //recieved
              receivedQuantityInfo={receivedQuantityInfo}
              handleSaveReceivedQuantityData={
                this.handleSaveReceivedQuantityData
              }
              //quality
              handleSaveQualityAssuranceData={
                this.handleSaveQualityAssuranceData
              }
              qualityAssuranceInfo={qualityAssuranceInfo}
              //challan
              challanInformation={challanInformation}
              handleSaveChallanInformationData={
                this.handleSaveChallanInformationData
              }
              //invoice
              invoiceInformation={invoiceInformation}
              handleSaveInvoiceInformationData={
                this.handleSaveInvoiceInformationData
              }
              //other
              handleSaveOtherInformationData={
                this.handleSaveOtherInformationData
              }
              //ref
              ref={resError ? 'errorRef' : null}
              //quantityYetToReceive
              reviewRecord={true}
              //appending file
              OtherInfoTempNumber={this.state.OtherInfoTempNumber}
              handleTempNumber={this.handleTempNumber}
              challanTempNumber={this.state.challanTempNumber}
              invoiceTempNumber={this.state.invoiceTempNumber}
              qualityTempNumber={this.state.qualityTempNumber}
            />
          );

        default:
          return 'Unknown stepIndex';
      }
    };
    return (
      <React.Fragment>
        <Grid container justify='center'>
          <Grid item xs={11} sm={10} md={12} className={classes.backgroundGrid}>
            <Grid container justify='center'>
              <Grid item xs={12} md={11} lg={8} xl={7}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step
                      key={label}
                      classes={{
                        root: classes.step,
                      }}
                    >
                      <StepLabel
                        StepIconProps={{
                          classes: {
                            root: classes.step,
                            completed: classes.completed,
                          },
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={12} md={9} lg={8} xl={6}>
                {getStepContent(activeStep)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justify='center'
          style={{ textAlign: 'center', paddingBottom: '2rem' }}
        >
          <Grid item xs={12} sm={12} md={4}>
            {' '}
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            {activeStep === 0 ? (
              <React.Fragment>
                <StyledButton
                  // size='small'
                  onClick={this.handleNext}
                  style={{
                    paddingLeft: '4rem',
                    paddingRight: '4rem',
                  }}
                >
                  Next
                </StyledButton>
              </React.Fragment>
            ) : (
              <Typography> </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={4} className={classes.clear}>
            {activeStep === 0 ? (
              <React.Fragment>
                <Typography
                  variant='caption'
                  onClick={this.handleClearData}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  className={classes.clear}
                >
                  Clear all Data
                </Typography>
                <br />

                <Typography variant='caption'>
                  (This will remove all data entered)
                </Typography>
              </React.Fragment>
            ) : null}
          </Grid>

          <Grid item xs={10} sm={10} md={9} lg={8} xl={6}>
            {activeStep === 1 ? (
              <CheckBoxButton
                errors={this.state.errors}
                verifyConsent={verifyConsent}
                handleVerifyConsent={this.handleVerifyConsent}
                handleRecordSaved={this.handleSaveRecord}
                handleSubmitRecord={() => this.handleSaveRecord('submit')}
              />
            ) : null}
          </Grid>
        </Grid>

        <Dialog open={recordSaved} fullWidth>
          <StyledDialog
            icons={true}
            handleClose={this.handleCloseRecord}
            title='The Record has been Saved'
          />
        </Dialog>

        <Dialog open={submitRecord} fullWidth>
          <StyledDialog
            icons={true}
            handleClose={this.handleCloseRecord}
            title='Details Saved &amp; Sent for Approval'
            subtitle='Your Record has been added and sent for approval.Please Contact the Admin if you have Entered the Wrong Data.'
          />
        </Dialog>
        {/* Quantity Alert */}
        <Dialog open={this.state.resQuantityAlert} fullWidth>
          <QuantityAlertDialog
            handleClose={this.closeQuantityAlert}
            title={`Actual quantity received is greater than 'Pending quantity'`}
            subtitle={`Would you like to proceed ?`}
            hasMultipleButton={true}
            icons={false}
            alert={true}
            handleYesButton={this.handleYesQuantity}
          />
        </Dialog>
        {/* Are You Sure to Delete File Prompt  */}
        <Dialog open={this.state.deletePrompt} fullWidth>
          <DeleteAlertDialog
            handleClose={this.handleCloseDeletePrompt}
            title={`You are about to delete a file !`}
            subtitle={`Would you like to proceed ?`}
            hasMultipleButton={true}
            icons={false}
            alert={true}
            handleYesButton={this.handlingDeleteFile}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  updateGRNData: state.pcmp.cmo.shortFalls.updateGRNData,
  UOM: state.pcmp.cmo.shortFalls.fetchUOMData,
  // backdropLoading: state.pcmp.common.files.backdropLoading,
  QuantityResponseAlert: state.pcmp.cmo.shortFalls.quantityAlert,
  rmPmCustomFeaturesAndValidations:
    state.pcmp.common.reports.rmpmGrnFeatures?.data,
});
export default connect(mapStateToProps, {
  updateGRN,
  fetchUOM,
})(withStyles(Styles)(AddRecord));
