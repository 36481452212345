import React from 'react';

import { Box, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Autocomplete } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '80%',
    padding: '1rem',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
  },
  title: {
    textAlign: 'center',
    fontWeight: 500,
    marginTop: '1rem',
    fontSize: '1.5rem',
  },
  title3: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
    fontWeight: '500',
  },
  subTitle: {
    textAlign: 'center',
  },
  dialogPaper: {
    width: '32%',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
  },

  saveBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'capitalize',
  },
  icon: {
    fontSize: 'xxx-large',
    color: 'green',
    marginLeft: '2rem',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const EventClosedSuccess = (props) => {
  const classes = useStyles();
  const { openDialog } = props;

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='md'
      classes={{ paper: classes.dialogPaper }}
    >
      <Box padding='2rem 3rem' textAlign='center'>
        <CheckCircleIcon style={{ fontSize: '4rem', color: 'green' }} />

        <Typography className={classes.title}>Event Closed</Typography>
        <Typography variant='body1' className={classes.title3}>
          Your record has been successfully saved
        </Typography>

        <Button
          className={classes.saveBtn}
          variant='contained'
          color='primary'
          endIcon={<NavigateNextIcon />}
          onClick={props.goToDashboard}
        >
          ok
        </Button>
      </Box>
    </Dialog>
  );
};
export default EventClosedSuccess;
