import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Moment from 'moment';
import SelectionBar from './SelectionBar';
import Typography from '@material-ui/core/Typography';

import ReportsTableWithPagination from '../../../../common/tables/ReportsTableWithPagination';
import {
  viewAuditReport,
  downloadReport,
} from '../../../../../../pcmp/containers/actions/common/reportsActions';

const header = [
  {
    label: ' PO No',
    id: 'purchaseOrderNumber',
    align: 'left',
    span: 0.2,
  },
  {
    label: 'Date/Time',
    id: 'reportDate',
    align: 'left',
    span: 0.2,
  },
  {
    label: 'Event by User',
    id: 'lastUpdatedBy',
    align: 'left',
    span: 0.2,
  },
  {
    label: ' Event Type',
    id: 'eventType',
    align: 'left',
    span: 0.1,
  },
  {
    label: '   PO Status',
    id: 'POStatus',
    align: 'left',
    span: 0.1,
  },
  {
    label: ' Created On',
    id: 'createdOn',
    align: 'right',
    span: 0.1,
  },
  {
    label: ' Vendor Code',
    id: 'vendorCode',
    align: 'right',
    span: 0.1,
  },
  {
    label: ' Vendor Name',
    id: 'vendorName',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'Vendor Tax No',
    id: 'vendorTaxNumber',
    align: 'right',
    span: 0.1,
  },
  {
    label: ' Vendor City',
    id: 'vendorCity',
    align: 'right',
    span: 0.1,
  },
  {
    label: ' Vendor State',
    id: 'vendorState',
    align: 'right',
    span: 0.1,
  },
  {
    label: '  Vendor Country',
    id: 'vendorCountry',
    align: 'right',
    span: 0.1,
  },
  {
    label: '  Vendor Address',
    id: 'vendorAddressLineOne',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'PO Date Changed on',
    id: 'dispatchMonth',
    align: 'right',
    span: 0.1,
  },
  {
    label: ' Site Manager',
    id: 'siteManager',
    align: 'right',
    span: 0.1,
  },
  {
    label: '  Means Of Transport',
    id: 'meansOfTransport',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'Delivery State',
    id: 'deliveryLocationState',
    align: 'right',
    span: 0.1,
  },
  {
    label: '  Delivery Country',
    id: 'deliveryLocationCountry',
    align: 'right',
    span: 0.1,
  },
  {
    label: ' Delivery Address',
    id: 'deliveryLocationLineOne',
    align: 'right',
    span: 0.1,
  },
  // {
  //   label: "  Net Price",
  //   id: "netValue",
  //   align: "right",
  //   // width: 120,
  //   span: 0.1
  // },
  // {
  //   label: "Net Value",
  //   id: "netValue",
  //   align: "right",
  //   // width: 120,
  //   span: 0.1
  // },
  // {
  //   label: " Effective Value Currency",
  //   id: "Currency",
  //   align: "right",
  //   // width: 120,
  //   span: 0.1
  // }
];

class POAuditReport extends Component {
  state = {
    header: header,
    data: [],
    requestedBy: '',
    purchaseOrderNumber: '',
    requestDownload: false,
    reportNumber: 2,
    rowsPerPage: 5,
    page: 0,
    poError: false,
    showReport: false,
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.poAuditReport) {
  //     return {
  //       data: nextProps.poAuditReport.reportData,
  //       requestedBy: nextProps.poAuditReport.requestedBy,
  //       showReport: true
  //     };
  //   }
  //   return null;
  // }

  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.poAuditReport !== this.props.poAuditReport) {
      this.setState({
        data: nextProps.poAuditReport.reportData,
        requestedBy: nextProps.poAuditReport.requestedBy,
        showReport: true,
      });
    }
  }

  componentDidUpdate(nextProps, prevState) {
    if (nextProps.poNumberError !== this.props.poNumberError) {
      this.setState({ poError: true, data: [], showReport: false });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      poError: false,
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  validate = () => {
    let error = false;
    const { purchaseOrderNumber } = this.state;
    if (purchaseOrderNumber === '') {
      error = true;
      this.setState({
        poError: true,
      });
    }
    return error;
  };

  fetchReport = () => {
    let err = this.validate();
    if (!err) {
      const { purchaseOrderNumber, reportNumber } = this.state;
      let data = {
        purchaseOrderNumber,
        reportNumber,
        collabType: this.props.collabType,
        ...this.props.match.params,
      };
      this.props.viewAuditReport(data);
    }
  };
  downloadReport = () => {
    let err = this.validate();
    if (!err) {
      const { reportNumber, purchaseOrderNumber } = this.state;
      let data = {
        reportNumber,
        purchaseOrderNumber,
        collabType: this.props.collabType,
        ...this.props.match.params,
      };
      this.props.downloadReport(data);
    }
  };

  render() {
    const {
      header,
      data,
      requestedBy,
      purchaseOrderNumber,
      page,
      rowsPerPage,
      poError,
      showReport,
    } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    const tableBody =
      data.length > 0 ? (
        data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, i) => (
            <TableRow key={i}>
              <TableCell align='left'>{row.purchaseOrderNumber}</TableCell>
              <TableCell align='right'>
                {Moment(row.lastUpdatedDate).format(`${dateFormat} HH:mm:ss`)}
              </TableCell>
              <TableCell align='right'>{row.lastUpdatedBy}</TableCell>
              <TableCell align='left'>{row.eventType}</TableCell>
              <TableCell align='left'>{row.statusText}</TableCell>
              <TableCell align='right'>
                {Moment(row.creationDate).format(dateFormat)}
              </TableCell>
              <TableCell align='right'>{row.vendorCode}</TableCell>
              <TableCell align='right'>{row.vendorName}</TableCell>
              <TableCell align='right'>{row.vendorTaxNumber}</TableCell>
              <TableCell align='right'>{row.vendorCity}</TableCell>
              <TableCell align='right'>{row.vendorState}</TableCell>
              <TableCell align='right'>{row.vendorCountry}</TableCell>
              <TableCell>
                <div
                  style={{
                    maxWidth: '5rem',
                    maxHeight: '1.5rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  title={row.vendorAddressLineOne}
                >
                  <Typography variant='caption'>
                    {row.vendorAddressLineOne}
                  </Typography>
                </div>
              </TableCell>
              <TableCell align='right'>
                {Moment(row.PODate).format(dateFormat)}
              </TableCell>
              <TableCell align='right'>{row.siteManager}</TableCell>
              <TableCell align='right'>{row.meansOfTransport}</TableCell>
              <TableCell align='right'>{row.deliveryLocationState}</TableCell>
              <TableCell align='right'>{row.deliveryLocationCountry}</TableCell>
              <TableCell>
                <div
                  style={{
                    maxWidth: '5rem',
                    maxHeight: '1.5rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  title={row.deliveryLocationLineOne}
                >
                  <Typography variant='caption'>
                    {row.deliveryLocationLineOne}
                  </Typography>
                </div>
              </TableCell>
              {/* <TableCell align="right">{row.netValue}</TableCell>
        <TableCell align="right">{row.Currency}</TableCell>
        <TableCell align="right">{row.Currency}</TableCell> */}
            </TableRow>
          ))
      ) : (
        <TableRow>
          <TableCell colSpan={header.length} align='center'>
            No Records Found
          </TableCell>
        </TableRow>
      );
    return (
      <div>
        <SelectionBar
          handleChange={this.handleChange}
          purchaseOrderNumber={purchaseOrderNumber}
          fetchReport={this.fetchReport}
          poError={poError}
        />
        {showReport === true && (
          <ReportsTableWithPagination
            header={header}
            tableBody={tableBody}
            downloadReport={this.downloadReport}
            length={data.length}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            requestedBy={requestedBy}
            data={data}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poAuditReport: state.pcmp.common.reports.poAuditReport,
  poNumberError: state.pcmp.common.reports.poNumberError,
});
export default connect(mapStateToProps, { viewAuditReport, downloadReport })(
  POAuditReport
);
