import React, { useState } from 'react';

import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { Autocomplete } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import EventTwoToneIcon from '@material-ui/icons/Event';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  dialogPaper: {
    minHeight: '70vh',
    maxHeight: '70vh',
  },
  header: {
    width: '80%',
    padding: '1rem',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
  },
  mainTitle: {
    fontWeight: 700,
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
  },

  steeper: {
    width: '40%',
    margin: '0 auto',
    position: 'relative',
  },
  title: {
    textAlign: 'center',
    fontWeight: 500,
    marginTop: '2rem',
  },
  requiredStar: {
    color: 'red',
  },
  inputLabel: {
    marginBottom: '0.5rem',
    fontSize: '80%',
  },
  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
  },
  saveBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'lowercase',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const EventDetails = (props) => {
  const classes = useStyles();
  const { openDialog, toggleDialog } = props;
  const [state, setState] = useState({
    errors: {},
  });
  const {
    eventTitle,
    eventDescription,
    eventOccurredDate,
    eventReportedDate,
    reportedByOptions,
    selectedReportedBy,
  } = props.stateData;

  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD/MM/YYYY';

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: null },
    }));
    props.handleChangeData(name, value);
  };

  const handleOnChangeData = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: null },
    }));
    props.handleChangeData(name, value);
  };

  const handleValidations = () => {
    const errorsData = {};
    let isError = false;
    if (eventTitle === '') {
      errorsData.eventTitle = 'Please add a title for the event';
      isError = true;
    }
    if (eventDescription === '') {
      errorsData.eventDescription = 'Please add event description';
      isError = true;
    }
    if (eventOccurredDate === null) {
      errorsData.eventOccurredDate = 'Please provide date of occurence.';
      isError = true;
    }
    if (eventReportedDate === null) {
      errorsData.eventReportedDate = 'Please provide the reported date  ';
      isError = true;
    }
    if (selectedReportedBy === '' || selectedReportedBy === null) {
      errorsData.selectedReportedBy =
        'Please select the user who reported the event.';
      isError = true;
    }
    if (isError) {
      setState((prevState) => ({
        ...prevState,
        errors: errorsData,
      }));
    } else {
      props.toggleDialog('eventDeatilsScreen');
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => toggleDialog('eventDeatilsScreen')}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='sm'
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.headerContainer}>
        <div
          className={classes.closeBtn}
          onClick={() => toggleDialog('eventDeatilsScreen')}
        >
          <Typography
            variant='caption'
            style={{ lineHeight: 'normal', fontWeight: '700' }}
          >
            Cancel
          </Typography>
          <CloseIcon style={{ color: 'red' }} />
        </div>
      </div>
      <Box width='100%'>
        <Box width='80%' margin='auto'>
          <Typography variant='h5' className={classes.title}>
            Deviation Details
          </Typography>
          <hr className={classes.hrLine} />
          <Box>
            <Typography variant='body2' className={classes.inputLabel}>
              Title <span className={classes.requiredStar}>*</span>
            </Typography>
            <TextField
              fullWidth
              name='eventTitle'
              value={eventTitle}
              onChange={handleOnChange}
              variant='outlined'
              size='small'
              placeholder='Add a title for the event (150 characters)'
              error={!!state.errors.eventTitle}
              helperText={state.errors.eventTitle}
            />
          </Box>
          <Box paddingTop={2}>
            <Typography variant='body2' className={classes.inputLabel}>
              Description <span className={classes.requiredStar}>*</span>
            </Typography>
            <TextField
              minRows={4}
              multiline
              fullWidth
              name='eventDescription'
              value={eventDescription}
              onChange={handleOnChange}
              variant='outlined'
              placeholder='Enter the event description in as much details as possible (1250 characters)'
              error={!!state.errors.eventDescription}
              helperText={state.errors.eventDescription}
            />
          </Box>

          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            paddingTop={2}
          >
            <Box width='47%'>
              <Typography variant='body2' className={classes.inputLabel}>
                Select when the event occurred
                <span className={classes.requiredStar}>*</span>
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  variant='inline'
                  inputVariant='outlined'
                  format={dateFormat}
                  name='eventOccurredDate'
                  value={eventOccurredDate}
                  onChange={(value) =>
                    handleOnChangeData('eventOccurredDate', value)
                  }
                  error={!!state.errors.eventOccurredDate}
                  helperText={state.errors.eventOccurredDate}
                  placeholder={dateFormat}
                  size='small'
                  autoOk={true}
                  maxDate={new Date()}
                  InputProps={{
                    endAdornment: (
                      <EventTwoToneIcon
                        style={{ color: '#0000008a', cursor: 'pointer' }}
                      />
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Box width='47%'>
              <Typography variant='body2' className={classes.inputLabel}>
                Select when the event was reported{' '}
                <span className={classes.requiredStar}>*</span>
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  variant='inline'
                  inputVariant='outlined'
                  format={dateFormat}
                  name='eventReportedDate'
                  value={eventReportedDate}
                  onChange={(value) =>
                    handleOnChangeData('eventReportedDate', value)
                  }
                  error={!!state.errors.eventReportedDate}
                  helperText={state.errors.eventReportedDate}
                  placeholder={dateFormat}
                  size='small'
                  autoOk={true}
                  minDate={eventOccurredDate}
                  maxDate={new Date()}
                  disabled={eventOccurredDate === null}
                  InputProps={{
                    endAdornment: (
                      <EventTwoToneIcon
                        style={{ color: '#0000008a', cursor: 'pointer' }}
                      />
                    ),
                  }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Box>

          <Box marginTop={2} width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Reported By <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              getOptionLabel={(option) =>
                option.name
                  ? `${option.name} (${option.roleName})`
                  : option.emailID
                  ? option.emailID
                  : ''
              }
              name='selectedReportedBy'
              value={selectedReportedBy}
              getOptionSelected={(option) =>
                option.name
                  ? `${option.name} (${option.roleName})`
                  : option.emailID
                  ? option.emailID
                  : ''
              }
              options={reportedByOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  placeholder='Start Typing'
                  error={!!state.errors.selectedReportedBy}
                  helperText={state.errors.selectedReportedBy}
                />
              )}
              onChange={(event, value) => {
                handleOnChangeData('selectedReportedBy', value);
              }}
            />
          </Box>

          <Box
            width='70%'
            textAlign='center'
            marginTop={4}
            marginBottom='2rem'
            marginLeft={7}
          >
            <Button
              className={classes.saveBtn}
              variant='contained'
              color='primary'
              endIcon={<NavigateNextIcon />}
              onClick={handleValidations}
            >
              save
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default EventDetails;
