import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Dialog,
} from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import clsx from 'clsx';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Pagination from '@material-ui/lab/Pagination';
//files
import Search from './Search';
import TableDetails from '../common/DeliveryScheduleDetails';
import DeliveryTable from '../deliveryScheduleBatchTable';
import { useStyles } from './styles';
import {
  batchTableSource,
  isMasterRMPMDataAvailable,
  getBatchQAStatus,
  newbatchTable,
  removeUpdateRMPM,
} from '../../../../containers/actions/common/saslAction';
import {
  fetchBatchDetails,
  getAllDSBatchStatusSequence,
} from '../../../../containers/actions/common/saslAction';
import AddBatchButton from './AddBatchButton';
import { filterByQAStatus, searchItems } from './filterSearchAlgo';
import StatusFilter from './StatusFilter';
import { filterItems } from './filterSearchAlgo';
import {
  loadTableData,
  mainTableDataLoading,
} from '../../../../containers/actions/common/fileActions';
import RowsPerPage from '../../models/inputs/RowsPerPage';
import { getFeaturesAccess } from '../../../../containers/actions/common/accessControl';
import NotFound from '../../../../notFound/NotFound';
import featuresAndValidations from '../../models/featuresAccess/FeaturesAndValidations';
import Header from '../models/Header';
import RMPMAvailability from './RMPMAvailability/RMPMAvailability';

const DeliveryScheduleDetails = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { params } = props.match;

  const [noRowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, searchQueryInput] = useState('');
  const [tempSearch, tempSearchQury] = useState('');
  const [deliveryScheduleUOM, setDeliveryScheduleUOM] = useState('');
  const [uomQA, setuomQA] = useState('');
  //view by status
  const [status, selectStatus] = useState('all');
  const [qaStatus, selectQAStatus] = useState('all');

  const screenAccess = useSelector(
    (state) =>
      state.pcmp.common.accessControl.screensAccess?.data.deliverySchedule
        ?.screenAccess
  );

  const featuresAccess = useSelector(
    (state) =>
      state.pcmp.common.accessControl.featuresAccess.deliverySchedulesFeatures
  );

  const deliveryHeader = useSelector((state) => {
    return state.pcmp.common.sasl.deliveryHeader;
  });

  const getDeliveryScheduleUOM = (uomData) => {
    setDeliveryScheduleUOM(uomData.deliveryScheduleUOM);
    setuomQA(uomData.uomQA);
  };

  useEffect(() => {
    if (screenAccess && !featuresAccess)
      dispatch(getFeaturesAccess(10, 'deliverySchedulesFeatures')); //10 is DS screen ID
    // We can pick up DS screen Id from access screensAccess.data.deliverySchedule.screenId
  }, [dispatch, screenAccess, featuresAccess]);

  useEffect(() => {
    let data = {
      schrockenDeliveryScheduleID: parseInt(params.schrockenDeliveryScheduleID),
    };
    // dispatch(deliveryScheduleHeader(data));

    if (screenAccess) {
      dispatch(fetchBatchDetails(data));
      dispatch(getAllDSBatchStatusSequence());
      dispatch(mainTableDataLoading());
      dispatch(getBatchQAStatus());
    }
    return () => {
      if (batchTableSource) {
        batchTableSource.cancel('Api cancelled during unmount');
      }
      if (newbatchTable) {
        newbatchTable.cancel('api uncalled');
      }
    };
  }, [dispatch, params.schrockenDeliveryScheduleID, screenAccess]);

  useEffect(() => {
    if (deliveryHeader && screenAccess) {
      let data = {
        productCode: deliveryHeader.data.fgMaterialCode,
        vendorCode: deliveryHeader.data.vendorNumber,
        materialType: 'all',
      };
      dispatch(isMasterRMPMDataAvailable(data));
    }
  }, [dispatch, deliveryHeader, screenAccess]);

  let statusArray = [];
  let qaStatusArray = useSelector((state) => {
    if (state.pcmp.common.sasl.allQAStatus) {
      return state.pcmp.common.sasl.allQAStatus;
    } else {
      return [];
    }
  });
  statusArray = useSelector((state) => {
    return state.pcmp.common.sasl.allDSStatus;
  });

  const qualityApprovalFlowFlag = useSelector((state) => {
    return state.pcmp.common.reports.rmpmGrnFeatures?.data?.features
      .qualityApprovalEnabled;
  });

  //loading
  let loading = useSelector((state) => {
    return state.pcmp.common.sasl.mainTableLoading;
  });

  let rmpmAvailability = useSelector((state) => {
    return state.pcmp.common.sasl.rmpmAvailable;
  });

  let batchData = useSelector((state) => {
    if (
      state.pcmp.common.sasl.batchDetails.data &&
      state.pcmp.common.sasl.batchDetails.status === true &&
      state.pcmp.common.sasl.batchDetails.data.message !== undefined
      // && deliveryHeader!== undefined && deliveryHeader.status === true
    ) {
      // loading = false;
      return [];
    }
    if (
      state.pcmp.common.sasl.batchDetails.data &&
      state.pcmp.common.sasl.batchDetails.status === true
      // deliveryHeader!== undefined && deliveryHeader.status === true
    ) {
      // loading = false;
      return state.pcmp.common.sasl.batchDetails.data;
    }
    if (
      state.pcmp.common.sasl.batchDetails.data &&
      state.pcmp.common.sasl.batchDetails.status === true &&
      state.pcmp.common.sasl.batchDetails.data.length === 0
      // deliveryHeader!== undefined && deliveryHeader.status === true
    ) {
      // loading = false;
      return state.pcmp.common.sasl.batchDetails.data;
    }
    return [];
  });

  const fetchDeliveryScheduleBatchDetails = () => {
    let data = {
      schrockenDeliveryScheduleID: parseInt(params.schrockenDeliveryScheduleID),
    };

    dispatch(fetchBatchDetails(data));
    // To trigger loading indicator on the table
    dispatch(loadTableData());
  };

  const handleSetRows = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  // let filteredDSBatch =
  //   status !== 'all'
  //     ? filterItems(batchData, 'SASLBatchStatusID', status)
  //     : batchData;
  let filteredDSBatch =
    status !== 'all' && qaStatus !== 'all'
      ? filterItems(
          filterByQAStatus(batchData, 'QAStatusID', qaStatus),
          'SASLBatchStatusID',
          status
        )
      : status === 'all' && qaStatus !== 'all'
      ? filterByQAStatus(batchData, 'QAStatusID', qaStatus)
      : status !== 'all' && qaStatus === 'all'
      ? filterItems(batchData, 'SASLBatchStatusID', status)
      : batchData;

  let showPagination = filteredDSBatch
    ? filteredDSBatch.length > 0
      ? true
      : false
    : false;

  let records = currentPage * noRowsPerPage;

  let filteredBatchData = filteredDSBatch; //yet To filter
  if (searchQuery !== '') {
    filteredBatchData = searchItems(
      filteredBatchData,
      ['vendorBatchNumber'],
      searchQuery
    );
  }

  let filteredBatch = filteredBatchData
    ? filteredBatchData.slice(records - noRowsPerPage, records)
    : [];

  let pages = filteredBatchData
    ? Math.ceil(filteredBatchData.length / noRowsPerPage)
    : null;

  let noPages = pages ? pages : 1;

  const handleSearchQuery = (event) => {
    let { value } = event.target;
    // tempSearchQury(event.target.value);
    if (value === '' || value === null) {
      tempSearchQury(value);
      searchQueryInput('');
    } else {
      tempSearchQury(value);
    }
  };

  const handleClearSearch = (event) => {
    tempSearchQury('');
    searchQueryInput('');
  };

  const submitSearch = (event) => {
    event.preventDefault();
    setCurrentPage(1);
    searchQueryInput(tempSearch.trim());
  };

  const redirectfunc = () => {
    if (location.state?.batchDashboard) {
      history.push(
        `/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${localStorage.ecosystemId}/fgBatches`
      );
    } else {
      history.push(
        `/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${localStorage.ecosystemId}/schedule agreements`
      );
    }
  };

  const handleSelectStatus = (event) => {
    selectStatus(event.target.value);
  };

  const setDialogOpen = () => {
    dispatch(removeUpdateRMPM());
    setOpen(!open);
  }
  
  const handleSelectQAStatus = (event) => {
    selectQAStatus(event.target.value);
  };

  if (!screenAccess) {
    return <NotFound />;
  }
  return (
    <React.Fragment>
      <Grid container>
        <Grid container alignItems='center' style={{ paddingBottom: '1rem' }}>
          <Grid item xs={2} sm={6} md={6}>
            <Button
              className={classes.backBtn}
              startIcon={<ArrowBackIosIcon fontSize='small' />}
              size='small'
              onClick={redirectfunc}
            >
              Back
            </Button>
          </Grid>
          {localStorage.collabType === 'CMO' ? (
            <Grid item xs={12} md={6}>
              <Grid
                container
                className={clsx(
                  classes.dpFlx,
                  classes.flxEndSmFlxStrt,
                  classes.pdTponLr
                )}
              >
                {featuresAccess?.updateRMPMFile && rmpmAvailability && (
                  <Grid item>
                    <Grid container className={classes.flxEnd}>
                      <Button
                        onClick={() => setDialogOpen(!open)}
                        className={clsx(classes.viewRMPM)}
                      >
                        Update RM/PM Availability
                      </Button>
                    </Grid>
                  </Grid>
                )}

                {featuresAccess?.updateFGBatch && (
                  <AddBatchButton
                    deliveryScheduleUOM={deliveryScheduleUOM}
                    uomQA={uomQA}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            featuresAccess?.viewRMPMFile &&
            rmpmAvailability && (
              <Grid item xs={12} md={6} className={classes.flxEnd}>
                <Button
                  onClick={() => setDialogOpen(!open)}
                  className={clsx(
                    classes.dpFlx,
                    classes.pdTponLr,
                    classes.viewRMPM
                  )}
                >
                  View RM/PM Availability
                </Button>
              </Grid>
            )
          )}
          <Dialog open={open} fullScreen>
            {' '}
            {/*//onOpen={setDialogOpen}*/}
            <Header title='RM/PM Availability' handleClose={setDialogOpen} />
            <RMPMAvailability
              schrockenDeliveryScheduleID={params.schrockenDeliveryScheduleID}
              scheduleAgreementNumber={location.state?.scheduleAgreementNumber}
              vendorNumber={deliveryHeader?.data.vendorNumber}
              handleClose={setDialogOpen}
            />
          </Dialog>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TableDetails
            schrockenDeliveryScheduleID={params.schrockenDeliveryScheduleID}
            getUOM={getDeliveryScheduleUOM}
          />
        </Grid>

        <Grid
          container
          alignItems='center'
          className={classes.pdTp}
          style={{ paddingBottom: '2rem', paddingTop: '2rem' }}
        >
          <Search
            searchQuery={tempSearch}
            handleSearchQuery={handleSearchQuery}
            handleClearSearch={handleClearSearch}
            submitSearch={submitSearch}
          />
          <Grid item xs={12} md={6}>
            <Grid
              container
              className={clsx(
                classes.dpFlx,
                classes.flxEndSmFlxStrt,
                classes.pdTponLr
              )}
            >
              <Typography variant='caption' className={clsx(classes.padR20)}>
                {`Last synced @ ${moment(new Date()).format(
                  'HH:mm:ss; DD MMMM, YYYY'
                )}`}
              </Typography>

              <Typography
                variant='caption'
                color='primary'
                onClick={fetchDeliveryScheduleBatchDetails}
                className={clsx(
                  classes.dpFlx,
                  classes.algnCntr,
                  classes.pointer,
                  classes.onHvrUndrline
                )}
              >
                Refresh
                <CachedIcon color='primary' style={{ fontSize: 15 }} />
              </Typography>
            </Grid>
          </Grid>

          <Grid container alignItems='center' className={classes.pdTp}>
            <Grid item xs={4} sm={3}>
              <Typography variant='body2' className={classes.hgText}>
                View by Status:
              </Typography>
              <StatusFilter
                status={status}
                handleSelectStatus={handleSelectStatus}
                statusArray={statusArray}
              />
            </Grid>
            {qualityApprovalFlowFlag && (
              <Grid item xs={4} sm={3} style={{ paddingLeft: '10px' }}>
                <Typography variant='body2' className={classes.hgText}>
                  View by QA Status:
                </Typography>
                <FormControl style={{ minWidth: 120 }} fullWidth>
                  <Select
                    labelId='qa-status-filter'
                    id='qa-status-filter-id'
                    value={qaStatus}
                    onChange={handleSelectQAStatus}
                    variant='outlined'
                    classes={{
                      outlined: classes.outlined,
                    }}
                    // MenuProps={{
                    //   PaperProps: {
                    //     style: {
                    //       height: qaStatusArray.length > 1 ? '27%' : '8%',
                    //       overflowY:
                    //         qaStatusArray.length > 1 ? 'scroll' : 'hidden',
                    //     },
                    //   },
                    // }}
                  >
                    <MenuItem value='all'>All</MenuItem>
                    {qaStatusArray.map((dsStatus, index) => {
                      return (
                        <MenuItem key={index} value={dsStatus.statusID}>
                          {dsStatus.statusDisplayName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {/* <StatusFilter
                  status={qaStatus}
                  handleSelectStatus={handleSelectQAStatus}
                  statusArray={qaStatusArray}
                /> */}
              </Grid>
            )}

            <Grid
              item
              xs={4}
              sm={6}
              className={clsx(classes.searchFld, classes.flxEd)}
            >
              <RowsPerPage value={noRowsPerPage} onChange={handleSetRows} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DeliveryTable
        batchData={filteredBatch}
        loading={loading}
        schrockenDeliveryScheduleID={params.schrockenDeliveryScheduleID}
      />

      {showPagination === true && (
        <Grid
          container
          alignItems='center'
          justify='center'
          style={{ paddingTop: '1rem' }}
        >
          <Pagination
            page={currentPage}
            name='currentPage'
            onChange={(event, page) => handlePageChange(event, page)}
            count={noPages}
            variant='outlined'
            shape='rounded'
          />
        </Grid>
      )}
    </React.Fragment>
  );
};

export default featuresAndValidations(DeliveryScheduleDetails);
