import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { DatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useSelector } from 'react-redux';

const styles = (theme) => ({
  marginTopBar: {
    // margin: " -1rem -1.3rem 0rem 0",
    margin: '-16px -16px 0 0',
    background: '#E8E8E8',
  },
  topBarContainer: {
    padding: '0.5rem',
    paddingBottom: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    minHeight: '4rem',
  },
  topBarButtonLink: {
    fontSize: '0.6rem',
    padding: '0.35rem 0.65rem',
    borderRadius: '1.05rem',
    marginRight: '1rem',
  },
});

const TopBar = (props) => {
  let screensAccess = useSelector((state) => {
    return state.pcmp.common.accessControl.screensAccess.data;
  });

  const { classes, selectedDate, handleDateChange, CMSName } = props;
  const { roleName, ecosystemId, organizationName } = props.match.params;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          label='CMS'
          name='CMOMember'
          title={CMSName}
          value={CMSName}
          disabled
          // inputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            // autoOk
            // variant="inline"
            // openTo="year"
            label='Select Month and Year'
            views={['year', 'month']}
            openTo='month'
            value={selectedDate}
            onChange={(date) => handleDateChange(date)}
            InputProps={{
              endAdornment: (
                <ArrowDropDownIcon
                  style={{ color: '#0000008a', cursor: 'pointer' }}
                />
              ),
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={8}>
        <div className={classes.marginTopBar}>
          <Grid container spacing={2} className={classes.topBarContainer}>
            {screensAccess.purchaseOrders.screenAccess === true && (
              <Button
                variant='contained'
                color='primary'
                className={classes.topBarButtonLink}
                component={Link}
                to={`/pcmp/cmo/${roleName}/${organizationName}/${ecosystemId}/purchaseorders`}
              >
                All Purchase Orders
              </Button>
            )}

            {screensAccess.lineItems.screenAccess === true && (
              <Button
                variant='contained'
                color='primary'
                className={classes.topBarButtonLink}
                component={Link}
                to={`/pcmp/cmo/${roleName}/${organizationName}/${ecosystemId}/lineitems`}
              >
                All Line Items
              </Button>
            )}

            {screensAccess.batchItems.screenAccess === true && (
              <Button
                variant='contained'
                color='primary'
                className={classes.topBarButtonLink}
                component={Link}
                to={`/pcmp/cmo/${roleName}/${organizationName}/${ecosystemId}/batches`}
              >
                All Batches
              </Button>
            )}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(TopBar);