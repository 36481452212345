import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cmoMembersList } from '../../../containers/actions/pharmaCo/purchaseOrderActions';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStyles } from './grnStyles';

const CMOFilter = (props) => {
  const { handleSelectCMO, CMOMember } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  let cmoMembers = useSelector((state) =>
    state.pcmp.pharmaCo.purchaseOrder.cmoMembersList
      ? state.pcmp.pharmaCo.purchaseOrder.cmoMembersList
      : []
  );

  useEffect(() => {
    dispatch(
      cmoMembersList({
        collabType: 'Pharma Co',
        roleName: 'Admin',
        organizationName: 'Lupin',
        ecosystemId: 1,
      })
    );
  }, [dispatch]);

  return (
    <FormControl className={classes.formControl} fullWidth>
      {/* <InputLabel id='demo-simple-select-label'>CMO</InputLabel> */}
      <Select
        labelId='cmo-filter'
        id='cmo-filter-id'
        value={CMOMember}
        onChange={handleSelectCMO}
        variant='outlined'
        classes={{
          outlined: classes.outlined,
        }}
      >
        <MenuItem value='all'>All CMOs</MenuItem>
        {cmoMembers.map((cmoMember, i) => (
          <MenuItem key={i} value={cmoMember} disabled={cmoMember === null ? true : false}>
            {cmoMember}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CMOFilter;
