import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';
import ContainerInformation from '../containerInformation';
import { useSelector } from 'react-redux';
import { presentFormatedData } from '../functionalComponents/dataFormatFunctions';

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
    fontSize: '1.5rem',
    textAlign: 'left',
    paddingBottom: '0.3rem',
  },
  font: {
    fontWeight: 500,
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
  },
  fontPadding: {
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
    overflow: 'auto',
  },
  button: {
    backgroundColor: 'transparent',
    textAlign: 'end',
    paddingTop: '1rem',
  },
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '1.6rem',
    backgroundColor: 'grey',
  },
  grid: {
    paddingBottom: '2rem',
    paddingTop: '1rem',
    paddingRight: '1.5rem',
  },
});

export default function ReceivedQuantity(props) {
  const classes = useStyles();
  const { errors, handleOpenDialog, reviewRecord } = props;
  const {
    actualQuantityReceived,
    // quantityYetToReceive,
    unitOfMeasurementReceived,
    containerDetails,
  } = props.receivedQuantityInfo;

  const { quantityYetToReceive } = props.POLineRecordDetails;

  const { status } = props;
  const containerFeatureFlag = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.features
        .containerDetailsEnabled;
    }
    return false;
  });

  return (
    <React.Fragment>
      <Grid container className={classes.child}>
        <Grid item xs={8} sm={6} md={6} className={classes.grid}>
          <Typography variant='h4' className={classes.title}>
            Received Quantity Information
          </Typography>
          <span className={classes.borders}></span>
        </Grid>

        {props.isEditExist === true &&
        status !== 3 &&
        status !== 6 &&
        status !== 8 ? (
          <Grid item xs={4} sm={6} md={6} className={classes.button}>
            <Button
              variant='contained'
              startIcon={<CreateIcon fontSize='small' />}
              style={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                border: '1px solid #000000de',
              }}
              size='small'
              onClick={() => handleOpenDialog('openReceived')}
            >
              Edit
            </Button>
          </Grid>
        ) : (
          <Grid item xs={4} sm={6} md={6}></Grid>
        )}

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Unit of Measurement (UOM)
          </Typography>
          <Typography variant='body2' className={classes.fontPadding}>
            {presentFormatedData(unitOfMeasurementReceived, '-Not provided-')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Actual Quantity Received
          </Typography>
          <Typography
            variant='body2'
            className={classes.fontPadding}
            color={
              errors.actualQuantityReceived || !actualQuantityReceived
                ? 'error'
                : 'inherit'
            }
          >
            {presentFormatedData(actualQuantityReceived, '-Not provided-')}
          </Typography>
        </Grid>

        {status === true ||
        status === 1 ||
        status === 9 ||
        status === 10 ||
        reviewRecord === true ? (
          <Grid item xs={12} sm={6} md={4} className={classes.grid}>
            <Typography variant='body2' className={classes.font}>
              Quantity yet to Receive
            </Typography>
            {reviewRecord === true ? (
              quantityYetToReceive === '' ||
              quantityYetToReceive === null ||
              quantityYetToReceive === undefined ? (
                <Typography
                  variant='body2'
                  className={classes.fontPadding}
                  // color='error'
                >
                  -Not provided-
                </Typography>
              ) : quantityYetToReceive > actualQuantityReceived ? (
                <Typography
                  variant='body2'
                  className={classes.fontPadding}
                  // color={errors.quantityYetToReceive ? 'error' : 'inherit'}
                >
                  {parseFloat(
                    Number(
                      quantityYetToReceive - actualQuantityReceived
                    ).toFixed(3)
                  )}
                </Typography>
              ) : (
                0
              )
            ) : null}

            {status === true || status === 1 ? (
              <Typography variant='body2' className={classes.fontPadding}>
                {parseFloat(
                  Number(
                    props.receivedQuantityInfo.quantityYetToReceive -
                      actualQuantityReceived
                  ).toFixed(3)
                )}
              </Typography>
            ) : status === false ? null : (
              <Typography variant='body2' className={classes.fontPadding}>
                {' --- '}
              </Typography>
            )}
          </Grid>
        ) : null}

        {containerFeatureFlag && (
          <ContainerInformation
            containerDetails={containerDetails}
            editContainerDetails={false}
          />
        )}
      </Grid>
    </React.Fragment>
  );
}
