import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Grid, Typography } from '@material-ui/core';
import { useState, useEffect } from 'react';

const PaginationInput = (props) => {
  const { totalPages, handlePageChange, page } = props;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const handleBack = (event, page) => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      handlePageChange(currentPage - 1);
    }
  };

  const handleNext = (event, page) => {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
      handlePageChange(currentPage + 1);
    }
  };

  return (
    <Grid
      container
      alignItems='center'
      justify='center'
      style={{ paddingTop: '1rem' }}
    >
      <ArrowBackIosIcon
        page={currentPage}
        size='small'
        titleAccess='Previous'
        onClick={(event, currentPage) => handleBack(event, currentPage)}
        color={currentPage === 1 ? 'disabled' : 'action'}
        style={{ cursor: 'pointer' }}
      />
      <Typography variant='body1' style={{ padding: '0 0.5rem 0 0.5rem' }}>
        {currentPage} out of {totalPages} pages
      </Typography>
      <ArrowForwardIosIcon
        size='small'
        titleAccess='Next'
        page={currentPage}
        onClick={(event, page) => handleNext(event, page)}
        color={currentPage === totalPages ? 'disabled' : 'action'}
        style={{ cursor: 'pointer' }}
      />
    </Grid>
  );
};

export default PaginationInput;
