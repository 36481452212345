import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import { style } from "../../../../../resources/css/styles";

import { Grid, Button, Typography, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import LineCommitDate from "./LineCommitDate";

const styles = style;

class EditPurchaseOrderStatus extends Component {
  state = {
    tempEditStatus: this.props.poStatusCode,
    poAccepted: false,
    poRejected: false,
    PORejectionComments: "",
    errors: {
      PORejectionComments: "",
    },
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errors: {
        PORejectionComments: "",
      },
    });
  };

  selectStatus = (e) => {
    this.setState({
      tempEditStatus: e.target.value,
      poRejected: e.target.value === 2 ? true : false,
      poAccepted: e.target.value === 1 ? true : false,
    });
  };

  validate = () => {
    let isError = false;
    const { tempEditStatus, PORejectionComments } = this.state;
    let errors = {
      PORejectionComments: "",
    };
    if (tempEditStatus === 2 && PORejectionComments === "") {
      isError = true;
      errors.PORejectionComments = "Please Provide Reason for Rejecting PO";
    }
    this.setState({ errors });
    return isError;
  };

  savePoStatus = () => {
    let error = this.validate();
    if (!error) {
      this.props.saveStatus(
        this.state.tempEditStatus,
        this.state.PORejectionComments
      );
    }
  };
  handleClose = () => {
    this.setState({
      poRejected: false,
    });
    this.props.cancelEdit();
  };

  handleCloseLineCommitDates = () => {
    this.setState({
      poAccepted: false,
    });
    this.props.cancelEdit();
  };

  render() {
    const { classes, poLines } = this.props;
    return (
      <div className={classes.flexRow} style={{ alignItems: "center" }}>
        <TextField
          select
          label="Edit Status"
          name="poStatus"
          autoFocus
          value={this.state.tempEditStatus}
          onChange={this.selectStatus}
          margin="normal"
          variant="outlined"
          style={{
            width: "10rem",
            color: "#ffffff",
          }}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
            },
          }}
        >
          <MenuItem value={0}>Created/Open</MenuItem>
          <MenuItem value={1}>Acknowledge and Accept</MenuItem>
          <MenuItem value={2}>Acknowledge and Reject</MenuItem>
        </TextField>
        <Typography
          variant="caption"
          className={`${classes.saveText} ${classes.bgColor}`}
          onClick={this.savePoStatus}
        >
          SAVE
        </Typography>
        <Typography
          variant="caption"
          style={{
            color: "white",
            textDecoration: "underline",
            cursor: "pointer",
            paddingLeft: "0.5rem",
          }}
          onClick={this.props.cancelEdit}
        >
          Cancel X
        </Typography>
        <Dialog
          open={this.state.poRejected}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="poRejection"
          aria-describedby="poRejectionDescription"
          maxWidth="md"
        >
          <Grid
            container
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#000000",
            }}
          >
            <DialogTitle
              id="poRejection"
              style={{
                color: "#ffffff",
              }}
            >
              {"Reason for Rejecting PO"}
            </DialogTitle>
            <Typography
              variant="caption"
              style={{
                color: "#ffffff",
                textDecoration: "underline",
                cursor: "pointer",
                marginRight: "0.5rem",
              }}
              onClick={this.handleClose}
            >
              Cancel X
            </Typography>
          </Grid>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={10}>
                <Typography>Enter your reason for rejection</Typography>
                <TextField
                  name="PORejectionComments"
                  value={this.state.PORejectionComments}
                  fullWidth
                  multiline
                  variant="outlined"
                  margin="normal"
                  rows="5"
                  onChange={this.handleChange}
                  style={{
                    minWidth: "40rem",
                  }}
                  error={
                    this.state.errors.PORejectionComments === "" ? false : true
                  }
                  helperText={this.state.errors.PORejectionComments}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              style={{
                justifyContent: "center",
              }}
            >
              <Button
                onClick={this.savePoStatus}
                variant="contained"
                style={{
                  backgroundColor: "green",
                  color: "#ffffff",
                  borderRadius: "1.5rem",
                }}
              >
                Acknowledge &#38; Reject PO
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog maxWidth={"md"} open={this.state.poAccepted}>
          <LineCommitDate
            poLines={poLines}
            handleClose={this.handleCloseLineCommitDates}
            savePoStatus={this.savePoStatus}
            cancelEdit={this.props.cancelEdit}
          />
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(EditPurchaseOrderStatus);
