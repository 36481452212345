import React from 'react';
import Dropzone from 'react-dropzone';

import { Box, Button, Typography, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '80%',
    padding: '1rem',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
  },
  headTitle: {
    fontWeight: 700,
  },
  dialogPaper: {
    width: '40',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
  },

  steeper: {
    width: '40%',
    margin: '0 auto',
    position: 'relative',
  },
  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
  },
  title: {
    textAlign: 'center',
    fontWeight: 500,
    marginTop: '4rem',
  },

  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
  },
  inputLabel: {
    marginBottom: '0.5rem',
    fontSize: '85%',
  },
  requiredStar: {
    color: 'red',
  },
  saveBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'lowercase',
  },

  arrowIcon: {
    fontSize: 'small',
    paddingTop: '0.4rem',
  },
  fileIcon: {
    cursor: 'pointer',
    marginLeft: '25rem',
    fontSize: 'larger',
    marginTop: '0.6rem',
  },
  clear: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textAlign: 'end',
  },
  files: {
    boder: '1px solid #707070',
    backgroundColor: '#F7F7F7',
    textAlign: 'center',
    padding: '1rem',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const UploadDocument = (props) => {
  const classes = useStyles();
  const { openDialog, toggleDialog } = props;
  const { customFiles, qeCustomFiles } = props.stateData;

  const handleFileUpload = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        let fileObject = {
          name: file.name,
          data: reader.result,
          customFileType: 'buffer',
          schrockenFileID: '',
          schrockenCustomFileID: '',
          customFileHash: '',
        };
        props.handleChangeData('customFiles', [...customFiles, fileObject]);
      };
    });
  };

  const handleFileDelete = (index) => {
    const edit_newFiles = customFiles.filter((file, i) => i !== index);
    props.handleChangeData('customFiles', edit_newFiles);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => toggleDialog('uploadDocumentScreen')}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='sm'
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.headerContainer}>
        <div
          className={classes.closeBtn}
          onClick={() => toggleDialog('uploadDocumentScreen')}
        >
          <Typography
            variant='caption'
            style={{ lineHeight: 'normal', fontWeight: '700' }}
          >
            Cancel
          </Typography>
          <CloseIcon style={{ color: 'red' }} />
        </div>
      </div>
      <Box width='80%' margin='auto' marginTop={2}>
        <Box>
          <Typography variant='h5' className={classes.title}>
            Upload related Documents
          </Typography>
          <hr className={classes.hrLine} />
        </Box>

        <Box marginTop={2} width='100%'>
          <Typography variant='body2' className={classes.inputLabel}>
            Upload necesarry files for this event
          </Typography>
          <Dropzone
            onDrop={(files) => {
              handleFileUpload(files);
            }}
            accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps({
                  className: 'dropzone',
                  onDrop: (event) => event.preventDefault(),
                })}
                className={classes.files}
                style={{ border: '1px solid #707070' }}
              >
                <input {...getInputProps()} />
                <Typography variant='body2' style={{ cursor: 'pointer' }}>
                  Drag &amp; drop or click here to upload files
                </Typography>
                <InputAdornment position='end' className={classes.fileIcon}>
                  <PublishIcon
                    fontSize='large'
                    style={{ marginBottom: '2rem' }}
                  />
                </InputAdornment>
              </div>
            )}
          </Dropzone>

          <Typography variant='body' className={classes.font}>
            {qeCustomFiles.map((file, i) => (
              <p style={{ color: 'blue' }}>
                <u>{file.qeFileName}</u>
                <span style={{ verticalAlign: 'sub' }}>
                  {/* <CloseIcon
                    fontSize="small"
                    color="error"
                    style={{
                      paddingLeft: '0.5rem',
                      paddingTop: '0.5rem',
                      cursor: 'pointer',
                      width: '19px'
                    }}
                    onClick={() => handleFileDelete(i)}
                  /> */}
                </span>
              </p>
            ))}
            {customFiles.map((file, i) => (
              <p style={{ color: 'blue' }}>
                <u>{file.name}</u>
                <span style={{ verticalAlign: 'sub' }}>
                  <CloseIcon
                    fontSize='small'
                    color='error'
                    style={{
                      paddingLeft: '0.5rem',
                      paddingTop: '0.5rem',
                      cursor: 'pointer',
                      width: '19px',
                    }}
                    onClick={() => handleFileDelete(i)}
                  />
                </span>
              </p>
            ))}
          </Typography>
        </Box>

        <Box
          width='70%'
          textAlign='center'
          marginTop={4}
          marginBottom='2rem'
          marginLeft={7}
        >
          <Button
            className={classes.saveBtn}
            variant='contained'
            color='primary'
            endIcon={<NavigateNextIcon />}
            onClick={() => toggleDialog('uploadDocumentScreen')}
          >
            save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UploadDocument;
