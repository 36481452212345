import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { useStyles } from './qualityEventStyles';

const PriorityFilter = (props) => {
  const classes = useStyles();
  const { priority, handlePrioritySelector } = props;
  return (
    <FormControl className={classes.formControl} fullWidth>
      <Select
        style={{ padding: '0.2rem' }}
        labelId='cmo-filter'
        id='cmo-filter-id'
        defaultValue={'all'}
        value={priority}
        onChange={(data) => {
          handlePrioritySelector(data);
        }}
        variant='outlined'
        classes={{
          outlined: classes.outlined,
        }}
      >
        <MenuItem value='all'>Priority</MenuItem>
        <MenuItem value='High'>High</MenuItem>
        <MenuItem value='Medium'>Medium</MenuItem>
        <MenuItem value='Low'>Low</MenuItem>
      </Select>
    </FormControl>
  );
};

export default PriorityFilter;
