export const header = (Available) => {
  let headerData = [
    {
      id: 'materialName',
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSortEnable: false,
      label: 'Material Name',
      varName: 'materialName',
    },
    {
      id: 'materialType',
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSortEnable: false,
      label: 'Material Type',
      varName: 'materialType',
    },
    {
      id: 'materialStatusID',
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSortEnable: false,
      label: 'Material Status',
      varName: 'materialStatusID',
    },
    {
      id: 'lastUpdatedDate',
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSortEnable: false,
      label: 'Last Updated On',
      varName: 'lastUpdatedDate',
    },
  ];
  if (Available) {
    headerData.splice(3, 0, {
      id: 'expectedDateOfAvailability',
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSortEnable: false,
      label: 'Expected Date of Availability',
      varName: 'expectedDateOfAvailability',
    });
    headerData.splice(4, 0, {
      id: 'noOfBatchesShortage',
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSortEnable: false,
      label: 'No. of Batches facing Shortage',
      varName: 'noOfBatchesShortage',
    });
    return headerData;
  }
  return headerData;
};
