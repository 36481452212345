import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from '../Button';
import {
  MenuItem,
  MenuList,
  ButtonGroup,
  ClickAwayListener,
  Paper,
  Grow,
  Popper,
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const GroupedButton = (props) => {
  const { name, options, value, selectOption } = props;

  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState('NA');

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <React.Fragment>
      <ButtonGroup
        variant='contained'
        // color='primary'
        ref={anchorRef}
        aria-label='split button'
        disableElevation
        disableRipple
        disableFocusRipple
      >
        <StyledButton
          onClick={handleToggle}
          style={{
            textTransform: 'none',
            background: 'transparent',
            color: 'black',
            border: '1px solid #000000de',
            borderRadius: '10px 0 0 10px',
          }}
        >
          {selectedIndex === 'NA' ? name : options[selectedIndex].text}
        </StyledButton>
        <StyledButton
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
          style={{
            textTransform: 'none',
            background: 'transparent',
            color: 'black',
            border: '1px solid #000000de',
            borderLeft: 0,
            borderRadius: '0 10px 10px 0',
          }}
        >
          {props.endIcon || <ArrowDropDownIcon />}
        </StyledButton>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu'>
                  {options.map((option, index) => (
                    <MenuItem
                      key={`menu-${index}`}
                      selected={value === option.value}
                      onClick={(event) => {
                        setSelectedIndex(index);
                        selectOption(event, option.value);
                        setOpen(false);
                      }}
                    >
                      {option.icon && (
                        <ListItemIcon>{option.icon}</ListItemIcon>
                      )}
                      <ListItemText
                        primary={option.text}
                        secondary={option.secondaryText || null}
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

GroupedButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      icon: PropTypes.element,
      secondaryText: PropTypes.string,
    })
  ).isRequired,
};

export default GroupedButton;
