import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

class Loading extends Component {
  componentDidMount() {
    if (this.props.screensAccess.status === true) {
      const {
        dashboard,
        grnReceipts,
        purchaseOrders,
        reports,
      } = this.props.screensAccess.data;
      if (dashboard.screenAccess === true) {
        this.props.history.push(
          `/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${localStorage.ecosystemId}/dashboard`
        );
      } else if (purchaseOrders.screenAccess === true) {
        this.props.history.push(
          `/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${localStorage.ecosystemId}/purchaseorders`
        );
      } else if (grnReceipts.screenAccess === true) {
        this.props.history.push(
          `/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${localStorage.ecosystemId}/GRNReceipts`
        );
      } else if (reports.screenAccess === true) {
        this.props.history.push(
          `/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${localStorage.ecosystemId}/reports`
        );
      } else {
        this.props.history.push(
          `/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${localStorage.ecosystemId}/managemembers`
        );
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
          }}
        >
          <CircularProgress style={{ color: 'primary' }} thickness={7} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  screensAccess: state.pcmp.common.accessControl.screensAccess,
});

export default connect(mapStateToProps, {})(withRouter(Loading));
