import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const POBatchSelector = (props) => {
  const {
    batchNumber,
    handleChange,
    batchSelectOpen,
    handleBatchSelectOpen,
    handleBatchSelectClose,
    batchNumbers,
    batchSelectDisabled,
    batchError,
  } = props;
  return (
    <FormControl fullWidth={props.needFullWidth ? props.needFullWidth : false}>
      <InputLabel id='select-batch'>Select Batch</InputLabel>
      <Select
        labelId='select-batch'
        variant='standard'
        name='batchNumber'
        value={batchNumber}
        open={batchSelectOpen}
        onOpen={handleBatchSelectOpen}
        onClose={handleBatchSelectClose}
        onChange={handleChange}
        disabled={batchSelectDisabled}
        error={batchError}
      >
        {batchNumbers.map((ele) => (
          <MenuItem key={ele.schrockenBatchID} value={ele.schrockenBatchID}>
            {ele.batchNumber}
          </MenuItem>
        ))}
      </Select>
      {batchError && <FormHelperText error> Select Batch</FormHelperText>}
    </FormControl>
  );
};

export default POBatchSelector;
