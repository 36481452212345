import React from 'react';
// import moment from 'moment';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ViewSA from './ViewSA';
import { useStyles } from '../../supplierPoGrn/styles'; //'./styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CustomTableCell from '../../models/table/CustomTableCell';
import IconButton from '@material-ui/core/IconButton';
import { presentFormatedData } from '../../functionalComponents/dataFormatFunctions';
//Icons
//files import
import StatusTextDispaly from '../../models/status/DeliveryCompleted';
import getColor from '../models/status/statusColors';
import { useSelector } from 'react-redux';

function SingleRow(props) {
  const classes = useStyles();
  const { SASLDetails, setOpen, open } = props;
  const { deliveryScheduleDetails } = SASLDetails;
  const access = useSelector(
    (state) => state.pcmp.common.accessControl.featuresAccess.fgFeatures?.viewSA
  );
  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        style={{
          backgroundColor: props.keys % 2 === 0 ? '#FFF' : '#0000000a',
        }}
        key={SASLDetails.schrockenSAID}
      >
        <TableCell
          size='small'
          style={{
            maxWidth: '0.4rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            padding: 0,
          }}
        >
          {deliveryScheduleDetails.length > 0 ? (
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : null}
        </TableCell>

        <CustomTableCell
          scope='row'
          align='left'
          elementStyle={{ maxWidth: '0px' }}
        >
          {presentFormatedData(SASLDetails.scheduleAgreementNumber)}
        </CustomTableCell>

        <CustomTableCell
          scope='row'
          align='center'
          elementStyle={{ maxWidth: '0px' }}
          colSpan={7}
        ></CustomTableCell>

        <CustomTableCell
          scope='row'
          align='left'
          elementStyle={{ maxWidth: '7rem' }}
        >
          <StatusTextDispaly
            statusText={SASLDetails.scheduleAgreementStatusText}
            bgColor={getColor(SASLDetails.scheduleAgreementStatusID)}
          />
        </CustomTableCell>

        <CustomTableCell className={classes.changingWidth} align='left'>
          {access && (
            <div className={classes.singleAction}>
              <ViewSA
                schrockenSAID={SASLDetails.schrockenSAID}
                scheduleAgreementNumber={SASLDetails.scheduleAgreementNumber}
              />
            </div>
          )}
        </CustomTableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default SingleRow;
