export const header = (batchDashboard, qualityApprovalFlowFlag) => {
  let batchHeader = [
    // {
    //     id: 'item',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Item',
    //     varName: 'scheduleLineItemNumber',
    // },
    {
      id: 'vendorBatchNumber',
      align: 'left',
      numeric: true,
      disablePadding: false,
      isSortEnable: true,
      label: 'Batch Number',
      varName: 'vendorBatchNumber',
    },

    {
      id: 'fgMaterialCode',
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSortEnable: true,
      label: 'Material Code / Description',
      varName: 'fgMaterialCode',
    },

    {
      id: 'batchSize',
      align: 'right',
      numeric: true,
      disablePadding: false,
      isSortEnable: true,
      label: 'Batch Size',
      varName: 'batchSize',
    },
    // {
    //     id: 'producedQuantity',
    //     align:'right',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Produced Quantity',
    //     varName: 'producedQuantity',
    // },
    {
      id: 'readyForDispatchQuantity',
      align: 'right',
      numeric: true,
      disablePadding: false,
      isSortEnable: true,
      label: 'Ready for Dispatch Qty',
      varName: 'readyForDispatchQuantity',
    },
    // {
    //     id: 'pendingQuantity',
    //     align:'right',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Pending Quantity',
    //     varName: 'pendingQuantity',
    // },

    {
      id: 'batchStartDate',
      align: 'center',
      numeric: true,
      disablePadding: false,
      isSortEnable: true,
      label: 'Start Date',
      varName: 'batchStartDate',
    },
    {
      id: 'batchEndDate',
      align: 'center',
      numeric: true,
      disablePadding: false,
      isSortEnable: true,
      label: 'End Date',
      varName: 'batchEndDate',
    },
    {
      id: 'BOGeneratedGRNNumber',
      align: 'center',
      numeric: true,
      disablePadding: false,
      isSortEnable: true,
      label: 'GRN Number',
      varName: 'BOGeneratedGRNNumber',
    },
    {
      id: 'status',
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSortEnable: false,
      label: 'Status',
      varName: 'statusID',
    },
    {
      id: '5',
      numeric: true,
      disablePadding: false,
      isSortEnable: false,
      label: 'Actions',
      varName: 'actions',
    },
  ];
  if (qualityApprovalFlowFlag) {
    batchHeader.splice(8, 0, {
      id: 'qaStatus',
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSortEnable: false,
      label: 'QA Status',
      varName: 'qaStatusID',
    });
  }
  if (batchDashboard) {
    batchHeader.splice(2, 0, {
      id: 'scheduleAgreementNumber',
      align: 'center',
      numeric: true,
      disablePadding: false,
      isSortEnable: true,
      label: 'PO/SA Number',
      varName: 'scheduleAgreementNumber',
    });
    batchHeader.splice(3, 0, {
      id: 'batchUOM',
      align: 'right',
      numeric: false,
      disablePadding: false,
      isSortEnable: true,
      label: 'UoM',
      varName: 'batchUOM',
    });
    batchHeader.splice(6, 0, {
      id: 'Yield',
      align: 'right',
      numeric: true,
      disablePadding: false,
      isSortEnable: true,
      label: 'Yield(%)',
      varName: 'Yield',
    });
    return batchHeader;
  }
  return batchHeader;
};
