import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

import StyledButton from '../../../../../../common/models/Button';
import TableWrapper from '../../../wrappers/tableWrapper';
import { ViewConsumptionHeader } from './header';
import CustomTableCell from '../../../models/table/CustomTableCell';
import CustomTableHeaderCell from '../../../models/table/CustomTableHeaderCell';
import {
  fetchBOMConsumptionDetails,
  fetchBOMDetails,
  fetchVarianceThresholdDetails,
} from '../../../../../containers/actions/common/saslAction';
import { presentFormatedData } from '../../../functionalComponents/dataFormatFunctions';
import LongTextDisplay from '../../../models/LongText';
import {
  getVariance,
  getVariancePercentage,
  getRefQty,
  getThreshold,
  getStdQty,
  // getStockValue,
} from '../../common/variance';

const useStyles = (theme) => ({
  contain: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  mainGrid: {
    backgroundColor: '#EAEAEA',
    padding: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
  },
  font: {
    fontWeight: 500,
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
  },
  fontPadding: {
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
    overflow: 'auto',
  },
  grid: {
    paddingBottom: '1rem',
    paddingTop: '1rem',
    paddingRight: '1.5rem',
  },
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '1.6rem',
    backgroundColor: 'grey',
  },
  table: {
    minWidth: 650,
  },
  list: {
    padding: '1rem',
  },
  rowText: {
    '& td': {
      color: '#f44336',
    },
    '& input': {
      color: '#f44336',
    },
  },
});

class ViewConsumption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      batchData: {},
      orderBy: '',
      order: 'asc',
      bomData: {},
    };
  }

  componentDidMount() {
    const data = {
      schrockenDeliveryScheduleBatchID: parseInt(
        this.props.schrockenDeliveryScheduleBatchID
      ),
      schrockenDeliveryScheduleID: this.props.schrockenDeliveryScheduleID,
      bomConsumptionAddedIndicator: this.props.bomConsumptionAddedIndicator,
    };

    let bomRecipeFetch = {
      schrockenDeliveryScheduleID: parseInt(
        this.props.schrockenDeliveryScheduleID
      ),
    };

    this.props.fetchBOMConsumptionDetails(data);
    this.props.fetchBOMDetails(bomRecipeFetch);
    this.props.fetchVarianceThresholdDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.consumptionDetails !== prevProps.consumptionDetails &&
      this.props.consumptionDetails.status === true
    ) {
      this.setState({
        data: this.props.consumptionDetails.data.bomConsumptionDetails,
        loading: false,
        batchData: this.props.consumptionDetails.data,
      });
    }
    if (
      this.props.viewBOM !== prevProps.viewBOM &&
      this.props.viewBOM.status === true
    ) {
      this.setState({
        bomData: this.props.viewBOM.data,
        // loading: false,
      });
    }
    if (
      this.props.thresholdValue !== prevProps.thresholdValue &&
      this.props.thresholdValue.status === true
    ) {
      this.setState({
        thresholdData: this.props.thresholdValue.data,
      });
    }
  }

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };

  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  dialogClosClearData = () => {
    this.props.setDialogOpen();
    this.setState({ loading: true, data: null });
  };

  render() {
    const { classes, handleClose } = this.props;
    const { loading, data, order, orderBy, batchData, bomData, thresholdData } =
      this.state;
    // const dateFormat = localStorage.selectedDateFormat
    //   ? localStorage.selectedDateFormat
    //   : 'DD/MMM/YYYY';
    return (
      <div className={classes.list}>
        {loading === false ? (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={12} className={classes.mainGrid}>
              <Grid item xs={12} sm={4} md={2} className={classes.grid}>
                <Typography variant='body2' className={classes.font}>
                  Batch Number
                </Typography>

                <Typography variant='body2' className={classes.fontPadding}>
                  {batchData.vendorBatchNumber}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} md={2} className={classes.grid}>
                <Typography variant='body2' className={classes.font}>
                  Batch Size
                </Typography>

                <Typography variant='body2' className={classes.fontPadding}>
                  {batchData.batchSize}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} md={2} className={classes.grid}>
                <Typography variant='body2' className={classes.font}>
                  Standard Batch Size
                </Typography>

                <Typography variant='body2' className={classes.fontPadding}>
                  {bomData.fgRefQuantity !== undefined
                    ? presentFormatedData(bomData.fgRefQuantity)
                    : '--'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} md={4} className={classes.grid}>
                <Typography variant='body2' className={classes.font}>
                  Material Code /Description
                </Typography>

                <Typography variant='body2' className={classes.fontPadding}>
                  {batchData.fgMaterialCode} / {batchData.fgMaterialName}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.grid}
              style={{ paddingLeft: '0' }}
            >
              <Typography variant='h6' className={classes.font}>
                Material Consumption Details
              </Typography>
              <span className={classes.borders}></span>
            </Grid>

            <TableWrapper>
              <TableContainer component={Paper} className={classes.paper}>
                <Table className={classes.table} aria-label='collapsible table'>
                  <TableHead>
                    <TableRow>
                      {ViewConsumptionHeader.map((headCell) => (
                        <CustomTableHeaderCell
                          sortingFunction={this.createSortHandler}
                          cellData={headCell}
                          order={order}
                          orderBy={orderBy}
                          key={headCell.id}
                          elementStyle={{
                            verticalAlign: 'top',
                            width: headCell.width ? headCell.width : 'auto',
                          }}
                        />
                      ))}
                      <CustomTableHeaderCell
                        cellData={{ align: 'right', label: 'Variance (%)' }}
                      />
                      <CustomTableHeaderCell
                        cellData={{ align: 'left', label: 'CMO Remarks' }}
                      />
                      <CustomTableHeaderCell
                        cellData={{ align: 'left', label: 'BO Remarks' }}
                      />
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {loading === true ? (
                      <Grid container justify='center'>
                        <div
                          style={{
                            textAlign: 'center',
                            marginTop: '25%',
                            minWidth: '60vw',
                          }}
                        >
                          <CircularProgress color='primary' thickness={7} />
                        </div>
                      </Grid>
                    ) : (
                      <React.Fragment>
                        {data.length > 0 &&
                          this.stableSort(
                            data,
                            this.getComparator(order, orderBy)
                          ).map((item, i) => {
                            let refQty = getRefQty({
                              materialCode: item.BomComponentCode,
                              bomData: bomData.bomComponentDetails,
                            });
                            let stdQty = getStdQty(
                              batchData.batchSize,
                              presentFormatedData(bomData.fgRefQuantity),
                              refQty
                            );

                            let variance = getVariance(
                              item.consumptionBatchNumbers,
                              stdQty
                            );

                            let variancePercentage = Math.abs(
                              getVariancePercentage(variance, stdQty)
                            );

                            let materialThreshold = getThreshold({
                              materialCode: item.BomComponentCode,
                              series: thresholdData,
                            });

                            return (
                              <React.Fragment key={i}>
                                <TableRow
                                  className={
                                    variancePercentage > materialThreshold
                                      ? classes.rowText
                                      : {}
                                  }
                                >
                                  <CustomTableCell scope='row' align='left'>
                                    {item.BomComponentCode}
                                  </CustomTableCell>
                                  <CustomTableCell align='left'>
                                    {item.BomComponentName}
                                  </CustomTableCell>
                                  <CustomTableCell align='left'>
                                    {item.BomComponentRefUOM}
                                  </CustomTableCell>
                                  <CustomTableCell align='right'>
                                    {presentFormatedData(refQty, 'NA')}
                                  </CustomTableCell>
                                  <CustomTableCell align='right'>
                                    {presentFormatedData(stdQty, 'NA')}
                                  </CustomTableCell>
                                  <CustomTableCell>
                                    <Grid container>
                                      {item.consumptionBatchNumbers.map(
                                        (row, i) => (
                                          <Grid
                                            item
                                            xs={12}
                                            key={i}
                                            style={{ textAlign: 'right' }}
                                          >
                                            <Typography variant='body2'>
                                              {row.BomComponentConsumedQuantity}
                                            </Typography>
                                          </Grid>
                                        )
                                      )}
                                    </Grid>
                                  </CustomTableCell>
                                  <CustomTableCell align='left'>
                                    <Grid container>
                                      {item.consumptionBatchNumbers.map(
                                        (row, i) => (
                                          <Grid
                                            item
                                            xs={12}
                                            key={i}
                                            style={{ textAlign: 'center' }}
                                          >
                                            <Typography variant='body2'>
                                              {presentFormatedData(
                                                row.componentBatchNumber,
                                                'NA'
                                              )}
                                            </Typography>
                                          </Grid>
                                        )
                                      )}
                                    </Grid>
                                  </CustomTableCell>
                                  <CustomTableCell align='right'>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                      }}
                                    >
                                      <Typography variant='body2'>
                                        {isNaN(variance)
                                          ? 'NA'
                                          : Math.abs(variance)}
                                      </Typography>
                                      {!isNaN(variance) && variance !== 0 && (
                                        <Typography
                                          variant='body2'
                                          style={{ display: 'inherit' }}
                                        >
                                          &nbsp;
                                          {`(${Math.abs(
                                            getVariancePercentage(
                                              variance,
                                              stdQty
                                            )
                                          )}%)`}
                                          {variance > 0 ? (
                                            <ArrowDropDownIcon
                                              fontSize='small'
                                              color='error'
                                            />
                                          ) : (
                                            <ArrowDropUpIcon
                                              fontSize='small'
                                              color='error'
                                            />
                                          )}
                                        </Typography>
                                      )}
                                    </div>
                                  </CustomTableCell>
                                  <CustomTableCell align='left'>
                                    <LongTextDisplay
                                      limit={50}
                                      text={item.remarks || ''}
                                      style={{
                                        textAlign: 'left',
                                        width: 100,
                                        whiteSpace: 'break-spaces',
                                        wordBreak: 'break-word',
                                      }}
                                    />
                                  </CustomTableCell>
                                  <CustomTableCell align='left'>
                                    <LongTextDisplay
                                      limit={50}
                                      text={item.remarksBO || ''}
                                      style={{
                                        textAlign: 'left',
                                        width: 100,
                                        whiteSpace: 'break-spaces',
                                        wordBreak: 'break-word',
                                      }}
                                    />
                                  </CustomTableCell>{' '}
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                      </React.Fragment>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableWrapper>

            <Grid container justify='center'>
              <Grid
                item
                xs={4}
                sm={4}
                md={2}
                style={{
                  textAlign: 'center',
                  padding: '1rem',
                }}
              >
                <StyledButton onClick={handleClose} fullWidth>
                  Close
                </StyledButton>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid container justify='center'>
            <div
              style={{
                textAlign: 'center',
                marginTop: '25%',
                minWidth: '60vw',
              }}
            >
              <CircularProgress color='primary' thickness={7} />
            </div>
          </Grid>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  consumptionDetails: state.pcmp.common.sasl.consumptionInfo,
  viewBOM: state.pcmp.common.sasl.viewBOM,
  thresholdValue: state.pcmp.common.sasl.fetchVarianceThreshold,
});

export default withStyles(useStyles)(
  connect(mapStateToProps, {
    fetchBOMConsumptionDetails,
    fetchBOMDetails,
    fetchVarianceThresholdDetails,
  })(ViewConsumption)
);
