import axios from 'axios';
import { appUrl } from '../../../../../utils/config';

import {
  CMO_UPLOAD_QC_FILE,
  CMO_UPLOAD_INVOICE_FILE,
  CMO_DOWNLOAD_GRN_FILE,
} from '../types';

export const cmoUploadFiles = (data, roleName) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${roleName}/uploadFile`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_UPLOAD_QC_FILE,
          payload: res.data.status,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const cmoUploadInvoiceFile = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${data.roleName}/uploadFile`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_UPLOAD_INVOICE_FILE,
          payload: res.data.status,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const cmoDownloadGRNFile = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${data.roleName}/downloadFile`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        var a = window.document.createElement('a');

        a.href = res.data.file.docs[0].fileData;
        a.download = res.data.file.docs[0].fileName;

        // Append anchor to body.
        document.body.appendChild(a);
        a.click();

        // Remove anchor from body
        document.body.removeChild(a);

        dispatch({
          type: CMO_DOWNLOAD_GRN_FILE,
          payload: res.data.status,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const cmoDownloadFiles = (data, fileType) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/download${fileType}File`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        let fileDataArray = res.data.fileData.data;
        var byteArray = new Uint8Array(fileDataArray);

        var a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        );
        a.download = res.data.fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    })
    .catch((err) => console.log(err));
};
