import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  presentFormatedData,
  presentDate,
} from '../../../functionalComponents/dataFormatFunctions';
import MandatorySpan from '../../models/MandatorySpan';
import CustomDatePickerMui from '../../../models/inputs/DateInput';
import InputNumber from '../../../models/inputs/InputNumber';

const useStyles = makeStyles((theme) => ({
  font: {
    fontWeight: 500,
    textAlign: 'initial',
    paddingBottom: '0.25rem',
    fontSize: '1rem',
  },
  fontPadding: {
    textAlign: 'initial',
    overflow: 'auto',
    paddingTop: '0.25rem',
    fontSize: '1rem',
  },

  grid: {
    paddingBottom: '2rem',
    paddingTop: '1rem',
    paddingRight: '1.5rem',
  },
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '1.6rem',
    backgroundColor: 'grey',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5rem',
    textAlign: 'left',
    paddingBottom: '0.3rem',
  },
}));

const BatchInformation = (props) => {
  const classes = useStyles();
  const { batchDetails, handleChange, errors, handleDateChange, quickView } =
    props;
  const { collabType } = localStorage;

  const isEditable = (type) => {
    let edit;
    switch (type) {
      case 'batchSize':
        if (
          !quickView &&
          collabType === 'CMO' &&
          (batchDetails.SASLBatchStatusID <= 5 ||
            batchDetails.SASLBatchStatusID === 13 ||
            batchDetails.SASLBatchStatusID === 15)
        ) {
          edit = true;
        }
        break;
      case 'producedQuantity':
        if (
          !quickView &&
          collabType === 'CMO' &&
          (batchDetails.SASLBatchStatusID <= 5 ||
            batchDetails.SASLBatchStatusID === 13 ||
            batchDetails.SASLBatchStatusID === 15)
        ) {
          edit = true;
        }
        break;
      case 'batchStartDate':
        if (
          !quickView &&
          collabType === 'CMO' &&
          batchDetails.SASLBatchStatusID <= 5
        ) {
          edit = true;
        }
        break;
      case 'batchEndDate':
        if (
          !quickView &&
          collabType === 'CMO' &&
          batchDetails.SASLBatchStatusID <= 5
        ) {
          edit = true;
        }
        break;
      case 'manufacturingDate':
        if (
          !quickView &&
          collabType === 'CMO' &&
          ((batchDetails.SASLBatchStatusID > 1 &&
            batchDetails.SASLBatchStatusID < 6) ||
            batchDetails.SASLBatchStatusID === 13 ||
            batchDetails.SASLBatchStatusID === 15)
        ) {
          edit = true;
        }
        break;
      case 'expiryDate':
        if (
          !quickView &&
          collabType === 'CMO' &&
          ((batchDetails.SASLBatchStatusID > 1 &&
            batchDetails.SASLBatchStatusID < 6) ||
            batchDetails.SASLBatchStatusID === 13 ||
            batchDetails.SASLBatchStatusID === 15)
        ) {
          edit = true;
        }
        break;
      case 'readyForDispatchQuantity':
        if (
          !quickView &&
          collabType === 'CMO' &&
          (batchDetails.SASLBatchStatusID === 5 ||
            batchDetails.SASLBatchStatusID === 13 ||
            batchDetails.SASLBatchStatusID === 15)
        ) {
          edit = true;
        }
        break;
      default:
        edit = false;
    }
    return edit;
  };
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={8} sm={12} md={12} className={classes.grid}>
          <Typography variant='h4' className={classes.title}>
            Batch Information
          </Typography>
          <span className={classes.borders}></span>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Batch Number
          </Typography>

          <Typography variant='body2' className={classes.fontPadding}>
            {presentFormatedData(batchDetails.vendorBatchNumber)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Batch Size {isEditable('batchSize') && <MandatorySpan />}
          </Typography>

          {isEditable('batchSize') ? (
            <InputNumber
              className={classes.fontPadding}
              name='batchSize'
              placeholder='Size'
              value={batchDetails.batchSize || ''}
              style={{ width: '50%' }}
              onKeyPress={(event) => {
                if (event.key === '.') {
                  // disable decimal values
                  event.preventDefault();
                }
              }}
              inputProps={{
                min: 0,
                step: 1,
              }}
              onChange={handleChange}
              error={errors.batchSize ? true : false}
            />
          ) : (
            <Typography variant='body2' className={classes.fontPadding}>
              {presentFormatedData(batchDetails.batchSize)}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Produced Quantity
          </Typography>

          {isEditable('producedQuantity') ? (
            <InputNumber
              className={classes.fontPadding}
              name='producedQuantity'
              placeholder='Qty Produced'
              value={batchDetails.producedQuantity || ''}
              onChange={handleChange}
              inputProps={{
                min: 0,
                step: 1,
                max: batchDetails.batchSize,
              }}
              style={{ width: '55%' }}
              error={errors.producedQuantity ? true : false}
            />
          ) : (
            <Typography variant='body2' className={classes.fontPadding}>
              {presentFormatedData(batchDetails.producedQuantity)}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Unit of Measurement(UOM)
          </Typography>

          <Typography variant='body2' className={classes.fontPadding}>
            {presentFormatedData(batchDetails.batchUOM, '-Not Provided-')}
          </Typography>
        </Grid>

        {/* Ready for dispatch  */}
        {batchDetails.SASLBatchStatusID >= 5 && (
          <Grid item xs={12} sm={5} md={3} lg={3} className={classes.grid}>
            <Typography
              variant='body2'
              className={classes.font}
              style={{ fontSize: '0.9rem' }}
            >
              Ready for Dispatch Quantity{' '}
              {isEditable('readyForDispatchQuantity') && <MandatorySpan />}
            </Typography>

            {isEditable('readyForDispatchQuantity') ? (
              <InputNumber
                className={classes.fontPadding}
                name='readyForDispatchQuantity'
                placeholder='Enter Quantity'
                inputProps={{ min: 0, step: 1 }}
                value={batchDetails.readyForDispatchQuantity || ''}
                onChange={handleChange}
                notFullWidth
              />
            ) : (
              <Typography variant='body2' className={classes.fontPadding}>
                {presentFormatedData(
                  batchDetails.readyForDispatchQuantity,
                  batchDetails.readyForDispatchQuantity === 0
                    ? '0'
                    : '-Not Provided-'
                )}
              </Typography>
            )}
          </Grid>
        )}

        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Start Date{' '}
            {batchDetails.SASLBatchStatusID > 1 &&
              isEditable('batchStartDate') && <MandatorySpan />}
          </Typography>
          {isEditable('batchStartDate') ? (
            <CustomDatePickerMui
              name='batchStartDate'
              value={batchDetails.batchStartDate}
              error={errors.batchStartDate ? true : false}
              onChange={(date) => handleDateChange(date, 'batchStartDate')}
              notFullWidth
              className={classes.fontPadding}
            />
          ) : (
            <Typography variant='body2' className={classes.fontPadding}>
              {presentDate(batchDetails.batchStartDate)}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            End Date{' '}
            {isEditable('batchEndDate') &&
              batchDetails.SASLBatchStatusID >= 3 && <MandatorySpan />}
          </Typography>

          {isEditable('batchEndDate') ? (
            <CustomDatePickerMui
              name='batchEndDate'
              className={classes.fontPadding}
              disabled={batchDetails.batchStartDate === null ? true : false}
              minDate={
                batchDetails.batchStartDate !== null
                  ? batchDetails.batchStartDate
                  : new Date()
              }
              value={batchDetails.batchEndDate}
              onChange={(date) => handleDateChange(date, 'batchEndDate')}
              {...(errors.batchEndDate ? { error: true } : {})}
              notFullWidth
              minDateMessage={
                'Batch End Date should be after Batch Start Date.'
              }
            />
          ) : (
            <Typography variant='body2' className={classes.fontPadding}>
              {presentDate(batchDetails.batchEndDate)}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Duration
          </Typography>

          <Typography variant='body2' style={{ paddingTop: '1rem' }}>
            {batchDetails.batchEndDate !== null
              ? Math.floor(
                  (Date.parse(batchDetails.batchEndDate) -
                    Date.parse(batchDetails.batchStartDate)) /
                    86400000
                )
              : '-NA-'}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Yield
          </Typography>

          <Typography variant='body2' style={{ paddingTop: '1rem' }}>
            {batchDetails.yield === 0
              ? batchDetails.yield
              : presentFormatedData(batchDetails.yield, '-NA-')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Max. Retail Price (MRP)
          </Typography>

          {!quickView &&
          collabType === 'CMO' &&
          batchDetails.SASLBatchStatusID > 1 ? (
            <InputNumber
              className={classes.fontPadding}
              name='MRP'
              inputProps={{ min: 0, step: 1 }}
              value={batchDetails.MRP || ''}
              onChange={handleChange}
              error={errors.MRP ? true : false}
              placeholder='Enter MRP'
              notFullWidth
            />
          ) : (
            <Typography variant='body2' className={classes.fontPadding}>
              {presentFormatedData(batchDetails.MRP)}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Manufacturing Date{' '}
            {isEditable('manufacturingDate') &&
              (batchDetails.SASLBatchStatusID === 5 ||
                batchDetails.SASLBatchStatusID === 13) && <MandatorySpan />}
          </Typography>
          {isEditable('manufacturingDate') ? (
            <CustomDatePickerMui
              value={batchDetails.manufacturingDate}
              onChange={(date) => handleDateChange(date, 'manufacturingDate')}
              name='Mfg date'
              className={classes.fontPadding}
              notFullWidth
              minDate={
                batchDetails.batchStartDate !== null
                  ? batchDetails.batchStartDate
                  : new Date()
              }
              {...(errors.manufacturingDate ? { error: true } : {})}
              minDateMessage={
                'Manufacturing Date should be after Batch Start Date.'
              }
            />
          ) : (
            <Typography variant='body2' className={classes.fontPadding}>
              {presentDate(batchDetails.manufacturingDate, '-Not Provided-')}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Expiry Date{' '}
            {isEditable('expiryDate') &&
              (batchDetails.SASLBatchStatusID === 5 ||
                batchDetails.SASLBatchStatusID === 13) && <MandatorySpan />}
          </Typography>
          {isEditable('expiryDate') ? (
            <CustomDatePickerMui
              name='expiryDate'
              value={batchDetails.expiryDate}
              disabled={batchDetails.manufacturingDate === null ? true : false}
              minDate={
                batchDetails.manufacturingDate !== null
                  ? batchDetails.manufacturingDate
                  : new Date()
              }
              onChange={(date) => handleDateChange(date, 'expiryDate')}
              minDateMessage={'Expiry Date should be after Manufacturing Date.'}
              {...(errors.expiryDate ? { error: true } : {})}
              className={classes.fontPadding}
              notFullWidth
            />
          ) : (
            <Typography variant='body2' className={classes.fontPadding}>
              {presentDate(batchDetails.expiryDate, '-Not Provided-')}
            </Typography>
          )}
        </Grid>
      </Grid>
      <hr />
    </React.Fragment>
  );
};

export default BatchInformation;
