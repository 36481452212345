import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React from 'react';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { useStyles } from '../styles';
const AvailabilityButton = (props) => {
  const { value, handleToggle } = props;
  const classes = useStyles();

  return (
    <div>
      <ToggleButtonGroup
        value={value}
        exclusive
        color='primary'
        onChange={handleToggle}
        aria-label='text alignment'
      >
        <ToggleButton className={classes.availableToggle} value={1}>
          <CheckOutlinedIcon />
          Available
        </ToggleButton>
        <ToggleButton className={classes.shortageToggle} value={0}>
          <ReportProblemOutlinedIcon />
          Shortage
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default AvailabilityButton;
