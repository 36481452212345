import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  //1
  paper: {
    width: '100%',
    boxShadow: 'none',
  },
  //2
  table: {
    minWidth: 1200,
    border: '1px solid #C6C6C6',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  supplied: {
    textDecoration: 'underline',
    color: 'blue',
    paddingTop: '0.2rem',
    whiteSpace: 'normal',
  },
  statusBox: {
    textAlign: 'center',
    padding: '0.25rem',
    borderRadius: '0.45rem',
    fontSize: '0.875rem',
  },
  logo: {
    display: 'flex',
    border: '1px solid #000000',
    borderRadius: '8%',
    padding: '0.3rem',
  },
  logoHeight: {
    height: 'inherit',
  },
  multipleLogo: {
    border: '1px solid #7d7c7c',
    borderRadius: '4px',
    padding: '0.3rem',
    height: 'inherit',
    marginLeft: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
  emptyLogo: {
    padding: '0.3rem',
    height: 'inherit',
    marginLeft: '0.5rem',
    paddingLeft: '4rem',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  singleAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  nameLogo: {
    width: '1.5rem',
    height: 'fit-content',
    borderRadius: '100%',
    // width: '1.1rem',
    // padding: '0.3rem',
    fontSize: '0.875rem',
    textAlign: 'center',
    cursor: 'pointer',
  },
  hidden: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  response: {
    width: '5%',
    maxWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  changingWidth: {
    width: '6%',
    // maxWidth: 0,
    [theme.breakpoints.between('sm', 'xl')]: {
      width: '1%',
    },
    // [theme.breakpoints.down('sm')]: {
    //   width: '6%',
    //   maxWidth: 0,
    // },
  },
  changeStatusWidth: {
    width: '5%',
    // maxWidth: 0,
    [theme.breakpoints.between('sm', 'xl')]: {
      width: '2%',
      maxWidth: '6.3rem',
    },
    // [theme.breakpoints.down('sm')]: {
    //   width: '6%',
    //   maxWidth: 0,
    // },
  },

  //for hover cell with ellipses
  test: {
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&hover': {
      display: 'block',
      width: '100%',
      overflow: 'visible',
      whiteSpace: 'normal',
      backgroundColor: 'black',
      wordBreak: 'break-all',
    },
  },

  wrapText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      whiteSpace: 'normal',
    },
  },

  flexContainer: {
    display: 'flex',
    backgroundColor: 'DodgerBlue',
  },

  childFlexContainer: {
    backgroundColor: '#f1f1f1',
    margin: '10px',
    padding: '20px',
    fontSize: '30px',
  },
  updateButton: {
    border: '1px solid #000000de',
    borderRadius: '0.5rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #000000de',
    },
  },
  multipleLogo: {
    border: '1px solid #7d7c7c',
    borderRadius: '4px',
    padding: '0.3rem',
    height: '1.5rem',
    marginLeft: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
}));
