import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Button, Dialog, Slide } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import UserDetails from './UserDetails';
import InviteUsers from '../../common/inviteusers/InviteUsers';

const styles = {
  button: {
    borderRadius: '1rem',
    fontSize: '0.7rem',
  },
  flexend: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  text: {
    fontSize: '0.7rem',
    marginRight: '1.2rem',
    fontWeight: 'bold',
  },
};

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='down' {...props} ref={ref} />
));

class index extends Component {
  state = {
    dialogOpen: false,
  };

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    const { classes } = this.props;
    const { params } = this.props.match;
    const { dialogOpen } = this.state;
    return (
      <Grid container>
        {params.roleName === 'Admin' && (
          <Grid item xs={12} className={classes.flexend}>
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={this.openDialog}
            >
              Invite New Members
            </Button>
            <Dialog
              fullScreen
              open={dialogOpen}
              TransitionComponent={Transition}
            >
              <InviteUsers
                closeInvite={this.closeDialog}
                collabType={'Pharma Co'}
              />
            </Dialog>
          </Grid>
        )}
        <UserDetails />
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(index));
