import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Settings from '../../common/settings/Settings';

import {
  viewApplicationSettings,
  updateApplicationSettings,
} from '../../../containers/actions/common/dashboardAction';

class index extends Component {
  state = {
    selectedDateFormat: localStorage.selectedDateFormat,
    disableSaveButton: false,
  };

  componentDidMount() {
    const data = {
      ...this.props.match.params,
      collabType: 'Pharma Co',
    };
    this.props.viewApplicationSettings(data);
  }

  componentDidUpdate(nextProps, prevState) {
    if (nextProps.dateFormatUpdated !== this.props.dateFormatUpdated) {
      this.setState({
        disableSaveButton: false,
        selectedDateFormat: localStorage.selectedDateFormat,
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = () => {
    const update = {
      ...this.props.match.params,
      collabType: 'Pharma Co',
      selectedDateFormat: this.state.selectedDateFormat,
    };
    this.props.updateApplicationSettings(update);
  };

  onCancel = () => {
    const update = {
      ...this.props.match.params,
      collabType: 'Pharma Co',
      selectedDateFormat: 'DD-MMM-YYYY',
    };

    this.props.updateApplicationSettings(update);

    this.setState({ disableSaveButton: true });
  };

  render() {
    const { selectedDateFormat, disableSaveButton } = this.state;
    const { params } = this.props.match;

    return (
      <Settings
        selectedDateFormat={selectedDateFormat}
        params={params}
        onCancel={this.onCancel}
        onSubmit={this.onSubmit}
        handleFormatChange={this.handleChange}
        disableSaveButton={disableSaveButton}
      />
    );
  }
}

const mapStatetoProps = (state) => ({
  dateFormatUpdated: state.pcmp.common.dashboard.dateFormatUpdated,
});

export default connect(mapStatetoProps, {
  viewApplicationSettings,
  updateApplicationSettings,
})(withRouter(index));
