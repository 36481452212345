import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles({
  statusBox: {
    textAlign: 'center',
    padding: '0.25rem',
    borderRadius: '0.45rem',
    fontSize: '0.875rem',
    color: (props) => (props.fontColor ? props.fontColor : 'white'),
    backgroundColor: (props) => props.bgColor,
    border: (props) =>
      `2px solid ${props.borderColor ? props.borderColor : props.bgColor}`,
  },
});

const StatusTextDispaly = (props) => {
  const classes = useStyles(props);

  return (
    <div>
      <Typography variant='body1' className={classes.statusBox}>
        {props.statusText}
      </Typography>
    </div>
  );
};

StatusTextDispaly.prototypes = {
  statusText: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
};
export default StatusTextDispaly;
