import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import BOMDetails from './BOMDetails'
import Header from '../models/Header';

export const useStyles = makeStyles((theme) => ({
    multipleLogo: {
        border: '1px solid #7d7c7c',
        borderRadius: '4px',
        padding: '0.3rem',
        height: 'inherit',
        marginLeft: '0.5rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'black',
            color: 'white',
            border: '1px solid #FFF',
        },
    },
    list: {
        maxWidth: '80vw',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100vw',
        },
    },
    fullList: {
        width: 'auto',
    },
    received: {
        textAlign: 'left',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />;
});

const AssignmentButton = (props) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const setDialogOpen = () => {
        setOpen(!open);
        // handleClose()
    };

    return (
        <React.Fragment>
            <AssignmentIcon
                fontSize='small'
                className={classes.multipleLogo}
                // onClick={() => setOpen(!open)}
                onClick={() => {
                    setOpen(true)
                }}

            />

            <Dialog
                fullScreen
                open={open}
                onClose={setDialogOpen}
                TransitionComponent={Transition}

            >
                <Header handleClose={setDialogOpen} title='Bill of Materials Details' padding={true}>
                    <BOMDetails {...props} />
                </Header>
            </Dialog>
        </React.Fragment>
    );
};

export default AssignmentButton;

