import React from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import UpdateFileSupplierPoRecord from '../../supplierPOUploadFiles';
import useStyles from './buttonStyles';
const UploadFiles = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickRecieved = () => {
    setOpen(true);
    console.log(props.status);
  };
  return (
    <React.Fragment>
      <Button
        disableElevation
        disableFocusRipple
        disableRipple
        onClick={handleClickRecieved}
        className={classes.button}
      >
        <div className={classes.logo}>
          <PublishIcon fontSize='small' className={classes.logoHeight} /> &nbsp;
          <Typography variant='body1'>Upload Files</Typography>
        </div>
      </Button>
      <UpdateFileSupplierPoRecord
        open={open}
        handleClose={handleClose}
        {...props}
      />
    </React.Fragment>
  );
};

export default UploadFiles;
