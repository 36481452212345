import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import StyledSwitch from '../../../../common/models/Switch';

const useStyles = makeStyles({
  optionsGrid: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  textSmall: {
    fontSize: '0.75rem',
  },
  switchGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const Options = (props) => {
  const classes = useStyles();
  const {
    twoStepAuthentication,
    editPhoneNumber,
    azureADSignIn,
    handleChangeOptions,
  } = props;
  return (
    <Grid container className={classes.optionsGrid}>
      <Typography variant='body1' style={{ fontWeight: 500 }}>
        Options &amp; Permissions (default)
      </Typography>

      <Grid container className={classes.optionsGrid} alignItems='center'>
        <Grid item xs={10}>
          <Typography variant='body1'>
            Should be 2-Step Authenticated
          </Typography>
          <Typography variant='caption' className={classes.textSmall}>
            Authenticate the person using code sent to the person's device
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.switchGrid}>
          <StyledSwitch
            name='twoStepAuthentication'
            checked={twoStepAuthentication}
            onChange={handleChangeOptions}
            color='primary'
          />
        </Grid>
      </Grid>
      <Grid container className={classes.optionsGrid} alignItems='center'>
        <Grid item xs={10}>
          <Typography variant='body1'>Cannot Edit Phone Number</Typography>
          <Typography variant='caption' className={classes.textSmall}>
            Do not allow the person to edit the phone number. This requires you
            to enter the phone number.
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.switchGrid}>
          <StyledSwitch
            name='editPhoneNumber'
            checked={editPhoneNumber}
            onChange={handleChangeOptions}
            color='primary'
          />
        </Grid>
      </Grid>
      <Grid container className={classes.optionsGrid} alignItems='center'>
        <Grid item xs={10}>
          <Typography variant='body1'>Use Azure AD Sign-In</Typography>
          <Typography variant='caption' className={classes.textSmall}>
            Enable this option to use Microsoft credentials to verify the
            person.
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.switchGrid}>
          <StyledSwitch
            name='azureADSignIn'
            checked={azureADSignIn}
            onChange={handleChangeOptions}
            color='primary'
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Options;
