import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';
import { useStyles } from './Styles';
import SearchIcon from '@material-ui/icons/Search';
import {
  fetchBatcheProducts,
  newbatchProducts,
} from '../../../../../containers/actions/common/saslAction';
const SearchSelect = (props) => {
  const { searchQuery, handleSearchQuery, CMOMember } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const [tempValue, setTempValue] = useState('');
  const [loading, setLoading] = useState(false);
  // let loading = true;

  useEffect(() => {
    // console.log("Inside useEffect search select.");

    let data = {
      searchProduct: tempValue.trim(),
      vendorMosymphonyID: CMOMember,
    };
    if (tempValue !== '') {
      dispatch(fetchBatcheProducts(data));
    }

    return () => {
      if (newbatchProducts) {
        newbatchProducts.cancel('leaving page!');
      }
    };
  }, [dispatch, tempValue, CMOMember]);

  useEffect(() => {
    if (searchQuery === '') {
      setValue(null);
      setTempValue('');
    }
  }, [searchQuery]);

  const Products = useSelector((state) => {
    if (
      state.pcmp.common.sasl.fetchBatchProducts.status === true &&
      state.pcmp.common.sasl.fetchBatchProducts.data
    ) {
      if (loading === true) setLoading(false);
      return state.pcmp.common.sasl.fetchBatchProducts.data;
    }
    if (
      state.pcmp.common.sasl.fetchBatchProducts.status === false &&
      state.pcmp.common.sasl.fetchBatchProducts.data
    ) {
      if (loading === true) setLoading(false);
      return [];
    }
    return [];
  });

  const onProductChange = (event, value) => {
    handleSearchQuery(value);
    setValue(value);
  };

  //debounce
  const debounce = (callback, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    };
  };

  const debouncedLog = debounce((value) => {
    setTempValue(value);
  }, 1000);

  const onInputChange = (event) => {
    // debouncedLog(value);
    if (loading === false && value !== '') setLoading(true);
    if (event.target.value === '') {
      console.log(event);
      handleSearchQuery(event.target.value);
    }
    debouncedLog(event.target.value);
  };

  return (
    <React.Fragment>
      <Grid>
        <Typography variant='body2' className={classes.hgText}>
          Select Product:
        </Typography>
        <Autocomplete
          className={classes.root}
          id='SearchProduct'
          options={Products}
          value={value}
          // disableClearable
          onChange={onProductChange}
          // onInputChange={onInputChange}
          getOptionLabel={(option) =>
            `${option.fgMaterialCode} / ${option.fgMaterialName}`
          }
          clearOnBlur={false}
          loading={loading}
          loadingText='Loading....'
          noOptionsText={
            tempValue
              ? 'No match found. Please try different search.'
              : 'Please Enter Material Code/Description'
          }
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ fontSize: '0.875rem' }}
              margin='normal'
              placeholder='Search using Material Code/Description'
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchIcon />,
              }}
              onChange={onInputChange}
            />
          )}
        />
      </Grid>
    </React.Fragment>
  );
};

export default SearchSelect;
