import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../../../../common/models/Button';
import ContainerInformation from '../containerInformation';
import { useSelector } from 'react-redux';
import InputNumber from '../models/inputs/InputNumber';

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6rem',
  },
  font: {
    fontWeight: 500,
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  padding: {
    paddingBottom: '2rem',
  },
  mandatory: {
    color: 'red',
  },
}));

const RecievedQuantity = (props) => {
  const classes = useStyles();
  const {
    receivedQuantityInfo,
    handleChange,
    isAddRecord,
    handleContainerData,
    // POBatchRecordDetails
  } = props;

  const textFieldRef = React.useRef(null);

  React.useEffect(() => {
    const handleWheel = (e) => e.preventDefault();
    let WheelScrollRestrict = textFieldRef.current;
    WheelScrollRestrict.addEventListener('wheel', handleWheel);

    return () => {
      WheelScrollRestrict.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const [actualQuantityReceived, setActualQuantityReceived] = useState(
    receivedQuantityInfo.actualQuantityReceived
  );
  const containerFeatureFlag = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.features
        .containerDetailsEnabled;
    }
    return false;
  });

  const [containerDetails, handleContainers] = useState(
    receivedQuantityInfo.containerDetails
  );

  const handleChangeReceived = (e) => {
    if (e.target.value < 0) {
      setActualQuantityReceived('');
      alert('Invalid Quantity');
    } else {
      setActualQuantityReceived(e.target.value);
    }
  };

  const handleSaveRecieved = () => {
    checkQuantity();
  };

  //to show dialog if actual quantity is greater than total received quantity
  const checkQuantity = (e) => {
    let data = {
      ...receivedQuantityInfo,
      actualQuantityReceived,
      containerDetails,
    };
    props.handleSaveReceivedQuantityData(data);
  };

  // Edit container information
  const handleContainersChange = (event, contianer, index) => {
    if (containerDetails.length === 0) {
      let tempContainer = {
        containerSerialNumber: 1,
        numberOfContainers: '',
        quantityPerContainer: '',
      };
      tempContainer[event.target.name] = parseFloat(
        Number(event.target.value).toFixed(3)
      );

      handleContainers([tempContainer]);
      if (!isAddRecord) {
        handleContainerData([tempContainer]);
      }
    } else {
      handleContainers((prevState) => {
        let containers = [...prevState];
        containers[index][event.target.name] = parseFloat(
          Number(event.target.value).toFixed(3)
        );
        if (!isAddRecord) {
          handleContainerData(containers);
        }
        return containers;
      });
    }
  };
  //  add new container data
  const handleAddContainer = () => {
    if (
      containerDetails[containerDetails.length - 1]?.numberOfContainers &&
      containerDetails[containerDetails.length - 1]?.quantityPerContainer
    ) {
      handleContainers((prevState) => {
        let tempAddContainer = [
          ...prevState,
          {
            containerSerialNumber: prevState.length + 1,
            numberOfContainers: '',
            quantityPerContainer: '',
          },
        ];
        if (!isAddRecord) {
          handleContainerData(tempAddContainer);
        }
        return tempAddContainer;
      });
    }
  };
  //remove container data
  const handleRemoveContainer = (index) => {
    handleContainers((prevState) => {
      let tempContainers = [...prevState];
      tempContainers.splice(index, 1);
      let i = index;
      while (i < tempContainers.length) {
        tempContainers[i].containerSerialNumber--;
        i++;
      }
      if (!isAddRecord) {
        handleContainerData(tempContainers);
      }
      return tempContainers;
    });
  };

  return (
    <React.Fragment>
      <Grid container justify='center' spacing={8} className={classes.padding}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Unit of Measurement (UOM)
          </Typography>
          <InputNumber
            type='text'
            className={classes.borders}
            name='unitOfMeasurementReceived'
            value={receivedQuantityInfo.unitOfMeasurementReceived}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Enter Actual Quantity Received{' '}
            <span className={classes.mandatory}>*</span>
          </Typography>
          <InputNumber
            fullWidth
            placeholder='Actual Quantity'
            name='actualQuantityReceived'
            value={
              isAddRecord !== true
                ? receivedQuantityInfo.actualQuantityReceived
                : actualQuantityReceived
            }
            onChange={
              isAddRecord !== true
                ? (e, name) => handleChange(e, 'actualQuantityReceived')
                : (e) => handleChangeReceived(e)
            }
            ref={textFieldRef}
          />
        </Grid>
        {/** Container information is Configured per each ecosystem.
         * Check feature flag for ecosytem
         */}
        {containerFeatureFlag && (
          <ContainerInformation
            containerDetails={containerDetails}
            handleContainersChange={handleContainersChange}
            handleAddContainer={handleAddContainer}
            handleRemoveContainer={handleRemoveContainer}
            editContainerDetails
            isAddRecord
            handleContainerData={handleContainerData}
          />
        )}
        {isAddRecord && (
          <Grid item xs={12} sm={8} md={8} style={{ textAlign: 'center' }}>
            <StyledButton
              onClick={handleSaveRecieved}
              style={{ padding: '0.25rem 2rem' }}
            >
              Save
            </StyledButton>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default RecievedQuantity;
