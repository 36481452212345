import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  body: {
    border: '2px solid #EAEAEA',
    backgroundColor: '#FAFAFA',
    padding: '1rem',
  },
  bodySA: {
    border: '2px solid #EAEAEA',
    backgroundColor: '#006EA8',
    padding: '1rem',
  },
  font: {
    fontWeight: 500,
    textAlign: 'initial',
  },
  subtitle: {
    fontSize: '1.25rem',
  },
  fontSA: {
    fontWeight: 500,
    textAlign: 'initial',
    color: 'white',
  },
  subtitleSA: {
    fontSize: '1.25rem',
    color: 'white',
  },
  padding: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  schedulePadding: {
    paddingBottom: '1rem',
  },
  responsiveSchedulePadding: {
    paddingBottom: '1rem',
  },
  responsiveSchedulePadding2: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: '1rem',
    },
  },
  title: {
    fontSize: '1rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  span: {
    width: '4rem',
    border: '2px solid #B4B4B4',
    display: 'flex',
    backgroundColor: '#B4B4B4',
  },
}));

const dateFormat = localStorage.selectedDateFormat
  ? localStorage.selectedDateFormat
  : 'DD/MMM/YYYY';

const SABody = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.padding}>
        {/* {props.isBOMDetails && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingBottom: '1rem' }}
          >
            <Typography variant='body2' className={classes.title}>
              BOM Recipes
            </Typography>
            <span className={classes.span}></span>
          </Grid>
        )} */}

        <Grid
          container
          className={props.isScheduleAgreement ? classes.bodySA : classes.body}
        >
          {/* {!props.isScheduleAgreement && (
            <Grid item xs={12} sm={3} md={2} lg={2}>
              <Typography variant='body2' className={classes.font}>
                Item
              </Typography>
              <br />
              <Typography variant='body2' className={classes.subtitle}>
                {props.Details.scheduleLineItemNumber}
              </Typography>
            </Grid>
          )} */}
          {props.isBOMDetails && (
            <React.Fragment>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                style={{ paddingRight: '2rem' }}
                className={classes.schedulePadding}
              >
                <Typography variant='body2' className={classes.font}>
                  Material Code / Description
                </Typography>
                <br />
                <Typography variant='body2' className={classes.subtitle}>
                  {props.Details.fgMaterialCode} /{' '}
                  {props.Details.fgMaterialName}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                md={4}
                lg={4}
                className={classes.schedulePadding}
              >
                <Typography variant='body2' className={classes.font}>
                  UOM
                </Typography>
                <br />
                <Typography variant='body2' className={classes.subtitle}>
                  {props.Details.fgRefUom === null
                    ? '--'
                    : props.Details.fgRefUom}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={3} md={4} lg={4}>
                <Typography variant='body2' className={classes.font}>
                  Standard Batch Size
                </Typography>
                <br />
                <Typography variant='body2' className={classes.subtitle}>
                  {props.Details.fgRefQuantity}
                </Typography>
              </Grid>

              {/* <Grid item xs={12} sm={3} md={2} lg={2}>
                <Typography variant='body2' className={classes.font}>
                  Committed Date
                </Typography>
                <br />
                <Typography variant='body2' className={classes.subtitle}>
                  {props.Details.cmoCommittedDate === null
                    ? '-Not Provided-'
                    : moment(props.Details.cmoCommittedDate).format(dateFormat)}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Typography variant='body2' className={classes.font}>
                  Delivery Date
                </Typography>
                <br />
                <Typography variant='body2' className={classes.subtitle}>
                  {props.Details.deliveryDate === null
                    ? '-Not Provided-'
                    : moment(props.Details.deliveryDate).format(dateFormat)}
                </Typography>
              </Grid> */}
            </React.Fragment>
          )}

          {props.isScheduleAgreement && (
            <React.Fragment>
              {/* <div className={classes.schedulePadding}> */}
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                className={classes.schedulePadding}
              >
                <Typography variant='body2' className={classes.fontSA}>
                  PO Number
                </Typography>

                <Typography variant='body2' className={classes.subtitleSA}>
                  {props.Details.scheduleAgreementNumber === ''
                    ? '-Not Provided'
                    : props.Details.scheduleAgreementNumber}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                className={classes.schedulePadding}
              >
                <Typography variant='body2' className={classes.fontSA}>
                  Agreement Type
                </Typography>

                <Typography variant='body2' className={classes.subtitleSA}>
                  {props.Details.agreementType === ''
                    ? '-Not Provided'
                    : props.Details.agreementType}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                className={classes.schedulePadding}
              >
                <Typography variant='body2' className={classes.fontSA}>
                  Agreement Date
                </Typography>

                <Typography variant='body2' className={classes.subtitleSA}>
                  {props.Details.agreementDate === null
                    ? '-Not Provided-'
                    : moment(props.Details.agreementDate).format(dateFormat)}
                </Typography>
              </Grid>
              {/* </div> */}

              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                className={classes.schedulePadding}
              >
                <Typography variant='body2' className={classes.fontSA}>
                  Validity Start Date
                </Typography>

                <Typography variant='body2' className={classes.subtitleSA}>
                  {props.Details.validityStartPeriod === null
                    ? '-Not Provided-'
                    : moment(props.Details.validityStartPeriod).format(
                        dateFormat
                      )}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                className={classes.responsiveSchedulePadding}
              >
                <Typography variant='body2' className={classes.fontSA}>
                  Validity End Date
                </Typography>

                <Typography variant='body2' className={classes.subtitleSA}>
                  {props.Details.validityEndPeriod === null
                    ? '-Not Provided-'
                    : moment(props.Details.validityEndPeriod).format(
                        dateFormat
                      )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                className={classes.responsiveSchedulePadding}
              >
                <Typography variant='body2' className={classes.fontSA}>
                  Vendor Number
                </Typography>

                <Typography variant='body2' className={classes.subtitleSA}>
                  {props.Details.vendorNumber === ''
                    ? '-Not Provided-'
                    : props.Details.vendorNumber}
                </Typography>
              </Grid>

              <Grid item xs={6} sm={3} md={3} lg={3}>
                <Typography variant='body2' className={classes.fontSA}>
                  Vendor Name
                </Typography>

                <Typography variant='body2' className={classes.subtitleSA}>
                  {props.Details.vendorName === ''
                    ? '-Not Provided-'
                    : props.Details.vendorName}
                </Typography>
              </Grid>

              <Grid item xs={6} sm={3} md={3} lg={3}>
                <Typography variant='body2' className={classes.fontSA}>
                  Plant
                </Typography>

                <Typography variant='body2' className={classes.subtitleSA}>
                  {props.Details.vendorPlant === ''
                    ? '-Not Provided-'
                    : props.Details.vendorPlant}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                className={classes.responsiveSchedulePadding2}
              >
                <Typography variant='body2' className={classes.fontSA}>
                  Currency
                </Typography>

                <Typography variant='body2' className={classes.subtitleSA}>
                  {props.Details.currency === ''
                    ? '-Not Provided-'
                    : props.Details.currency}
                </Typography>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default SABody;
