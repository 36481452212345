import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import InputBase from '@material-ui/core/InputBase';

import Moment from 'moment';

import {
  Grid,
  Table,
  Tooltip,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  IconButton,
  Typography,
  TablePagination,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import { tableFilter } from '../functionalComponents/TableFilter';

const tableDataFilter = tableFilter;

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: '2rem',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  POOrderBackground: {
    margin: '-1rem -1.3rem 0rem -1.8rem',
    backgroundColor: '#F5F5F5',
    flexDirection: 'row',
    padding: '1.2rem 0.5rem',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      cursor: 'pointer',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
});
const header = [
  { name: 'Status', id: 'statusText', align: 'left' },
  { name: 'Batch', id: 'batchNumber', align: 'left' },
  { name: 'PO Line Item', id: 'lineNumber', align: 'left' },
  { name: 'PO Number', id: 'purchaseOrderNumber', align: 'center' },
  { name: 'Product Name', id: 'productName', align: 'left' },
  { name: 'Product Code', id: 'productCode', align: 'center' },
  { name: 'Batch Size', id: 'batchSize', align: 'right' },
  { name: 'Produced Quantity', id: 'producedQuantity', align: 'right' },
  { name: 'Pending Quantity', id: 'pendingQuantity', align: 'right' },
  { name: 'Yield%', id: 'yield', align: 'right' },
  { name: 'Start Date', id: 'batchStartDate', align: 'left' },
  { name: 'End Date', id: 'batchEndDate', align: 'left' },
  { name: 'MRP', id: 'maximumRetailPrice', align: 'right' },
];

class BatchItemsTable extends Component {
  state = {
    batchItems: [],
    page: 0,
    rowsPerPage: 10,
    data: [],
    header,
    displayFilter: false,
    query: '',
    columnToQuery: 'all',
    columnToSort: '',
    sortDirection: 'desc',
    fieldSelected: false,
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { classes, batchItems, openBatchDialog, refreshList } = this.props;
    const { page, rowsPerPage } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    const noRecords = (
      <TableRow>
        <TableCell colSpan={header.length} align='center'>
          No Records Found
        </TableCell>
      </TableRow>
    );

    const emptyRows =
      batchItems !== false
        ? rowsPerPage -
          Math.min(rowsPerPage, batchItems.length - page * rowsPerPage)
        : 0;
    const queryData = orderBy(
      this.state.query
        ? batchItems.filter((data) =>
            tableDataFilter(
              data,
              this.state.columnToQuery,
              this.state.query.toLowerCase(),
              this.state.header
            )
          )
        : batchItems,
      this.state.columnToSort,
      this.state.sortDirection
    );
    return (
      <Paper className={classes.root}>
        <Toolbar>
          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid item xs={8}>
              <Typography variant='subtitle1'>Batch List</Typography>
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SearchIcon />
              <InputBase
                placeholder='Search Batch'
                value={this.state.query}
                name='query'
                onChange={this.handleChange}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </Grid>
          </Grid>
          <Tooltip title='Refresh List'>
            <IconButton aria-label='Refresh list' onClick={refreshList}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Table className={classes.table}>
          <TableHead style={{ backgroundColor: '#F5F5F5' }}>
            <TableRow>
              {header.map((data, i) => (
                <TableCell key={i} align={data.align}>
                  {data.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!batchItems ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={14} style={{ textAlign: 'center' }}>
                  Loading...
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {queryData.length > 0
                ? queryData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((batch, i) => (
                      <TableRow
                        key={i}
                        className={classes.tableRowHover}
                        onClick={() => openBatchDialog(batch)}
                      >
                        <TableCell align='left'>{batch.statusText}</TableCell>
                        <TableCell align='left'>{batch.batchNumber}</TableCell>
                        <TableCell align='left'>{batch.lineNumber}</TableCell>
                        <TableCell align='left'>
                          {batch.purchaseOrderNumber}
                        </TableCell>
                        <TableCell align='left'>{batch.productName}</TableCell>
                        <TableCell align='center'>
                          {batch.productCode}
                        </TableCell>
                        <TableCell align='right'>{batch.batchSize}</TableCell>
                        <TableCell align='right'>
                          {batch.producedQuantity}
                        </TableCell>
                        <TableCell align='right'>
                          {batch.pendingQuantity}
                        </TableCell>
                        <TableCell align='right'>{batch.yield}</TableCell>
                        <TableCell align='left'>
                          {Moment(new Date(batch.batchStartDate)).format(
                            dateFormat
                          )}
                        </TableCell>
                        <TableCell align='left'>
                          {Moment(new Date(batch.batchEndDate)).format(
                            dateFormat
                          )}
                        </TableCell>
                        <TableCell align='right'>
                          {batch.maximumRetailPrice}
                        </TableCell>
                      </TableRow>
                    ))
                : noRecords}

              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={14} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        <TablePagination
          colSpan={12}
          rowsPerPageOptions={[5, 10, 15, 20]}
          component='div'
          count={!batchItems ? 0 : batchItems.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

BatchItemsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(BatchItemsTable));
