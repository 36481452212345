import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import usePrevious from '../../../../../../helpers/previousProps';

import LaunchCapaResponseScreen from './LaunchCapaSuccessResponse';

import Preview from './Preview';
import Action from './Action';

import {
  eventDetailsByID,
  fetchTeamMembers,
  fetchQMSData,
  updateQualityEventData,
} from '../../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '100%',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
  },
  title: {
    fontWeight: 700,
    padding: '1.5rem 0rem',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
  },

  steeper: {
    width: '40%',
    margin: '0 auto',
    position: 'relative',
  },
  // root: {
  //   flexGrow: 1,
  //   backgroundColor: theme.palette.background.paper,
  // },
  root: {
    // flexGrow: 1,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const AddInfoQualityEvent = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);

  const { openDialog, toggleDialog, eventID } = props;
  const [state, setState] = useState({
    qualityEventRecordDetails: {},
    analysisTitle: '',
    analysisStartDate: null,
    analysisEndDate: null,
    teamOptions: [],
    selectedAnalysisLead: '',
    rootCause: '',
    customFiles: [],
    loading: true,
    launchCapaSuccessResponseScreen: false,
    errors: {},
    loadingUpdate: false,
  });
  const [value, setValue] = React.useState(1);

  const handleOnChangeData = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { collabType } = localStorage;
  const tokenData = jwtDecode(localStorage.getItem('authToken'));
  const { eventDetails, teamMembers, updateQualityEvent } = props;
  const prevEventDetails = usePrevious(eventDetails);
  const prevTeamMembers = usePrevious(teamMembers);
  const prevUpdateQualityEvent = usePrevious(updateQualityEvent);
  useEffect(() => {
    if (didMountRef.current) {
      if (eventDetails !== prevEventDetails) {
        setState((prevState) => ({
          ...prevState,
          qualityEventRecordDetails: eventDetails,
          loading: false,
        }));
        props.fetchTeamMembers({
          organizationId:
            collabType === 'CMO'
              ? tokenData.organization_id
              : eventDetails?.qualityEventDetails?.incidentForOrgID,
        });
      }
      if (teamMembers !== prevTeamMembers) {
        setState((prevState) => ({
          ...prevState,
          teamOptions: teamMembers,
        }));
      }
      if (updateQualityEvent !== prevUpdateQualityEvent) {
        setState((prevState) => ({
          ...prevState,
          launchCapaSuccessResponseScreen: true,
        }));

        props.fetchQMSData({
          viewByCMO: 'all',
          eventTypeID: 'all',
          priority: 'all',
          eventsOccurredFromDate: null,
          eventsOccurredToDate: null,
          searchBy: '',
          viewByStatus: 'all',
          pageNumber: 1,
          pageSize: 5,
        });
      }
    } else {
      props.eventDetailsByID(eventID);

      didMountRef.current = true;
    }
  }, [
    eventID,
    eventDetails,
    prevEventDetails,
    teamMembers,
    prevTeamMembers,
    updateQualityEvent,
    prevUpdateQualityEvent,
  ]);

  const goToDashboard = () => {
    toggleDialog('addInfoScreen');
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLaunchCapa = () => {
    const data = state.qualityEventRecordDetails.qualityEventRecordDetails;

    data.analysisData = {
      analysisTitle: state.analysisTitle,
      analysisStartDate: state.analysisStartDate,
      analysisEndDate: state.analysisEndDate,
      rootCause: state.rootCause,
      analysisLeadDetails: {
        teamLeadName: state.selectedAnalysisLead?.userName,
        teamLeadSaasUserID: state.selectedAnalysisLead?.userId,
        teamLeadEmailID: state.selectedAnalysisLead?.emailAddress,
        teamLeadRoleName: state.selectedAnalysisLead?.roleName,
      },
    };
    data.qualityEventDetails.eventStatusID = 5;
    data.qualityEventDetails.eventStatusText = 'Closed';
    data.qualityEventDetails.customFiles = state.customFiles;
    data.qualityEventDetails.markForDelete = false;

    setState((prevState) => ({
      ...prevState,
      loadingUpdate: true,
    }));

    props.updateQualityEventData({
      orgName: localStorage.organizationName,
      qualityEventDetails: [data],
    });
  };

  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={() => toggleDialog()}
        TransitionComponent={Transition}
      >
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <Typography variant='h5' className={classes.title}>
              Investigation
            </Typography>
            <div
              className={classes.closeBtn}
              onClick={() => toggleDialog('addInfoScreen')}
            >
              <Typography
                variant='caption'
                style={{ lineHeight: 'normal', fontWeight: '700' }}
              >
                Cancel
              </Typography>
              <CloseIcon style={{ color: 'red' }} />
            </div>
          </div>
        </div>
        <div className={classes.root}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label='Preview' />
            <Tab label='Action' />
          </Tabs>
          <div style={{ position: 'relative' }}>
            {state.loadingUpdate && (
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  width: '100%',
                  backdropFilter: 'blur(0.8px)',
                  zIndex: 9,
                  top: 0,
                }}
              >
                <CircularProgress style={{ marginTop: '10%' }} thickness='5' />
              </div>
            )}
            {value === 0 && (
              <Box marginLeft={3}>
                <Preview
                  qualityEventRecordDetails={state.qualityEventRecordDetails}
                />
              </Box>
            )}

            {value === 1 && (
              <Action
                qualityEventRecordDetails={state.qualityEventRecordDetails}
                handleOnChangeData={handleOnChangeData}
                stateData={state}
                handleLaunchCapa={handleLaunchCapa}
              />
            )}
          </div>
        </div>
      </Dialog>
      {state.launchCapaSuccessResponseScreen && (
        <LaunchCapaResponseScreen
          openDialog={state.launchCapaSuccessResponseScreen}
          goToDashboard={goToDashboard}
          qualityEventRecordDetails={state.qualityEventRecordDetails}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  eventDetails: state.pcmp.common.QMS.fetchEventDetails,
  teamMembers: state.pcmp.common.QMS.fetchTeamMembers,
  updateQualityEvent: state.pcmp.common.QMS.updateQualityEvent,
});

export default connect(mapStateToProps, {
  eventDetailsByID,
  fetchTeamMembers,
  fetchQMSData,
  updateQualityEventData,
})(AddInfoQualityEvent);
