import React, { Component } from "react";
import { connect } from "react-redux";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MenuItem from "@material-ui/core/MenuItem";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { Grid, Button, Typography, TextField } from "@material-ui/core";
import ViewAddedRM from "./ViewAddedRM";
import {
  cmoAddRMAvailability,
  cmoFetchRMAvailability,
  cmoFetchRMStatusList
} from "../../../../../containers/actions/cmo/addRmPmActions";

class AddRMAvailability extends Component {
  state = {
    disableSaveButton: false,
    rmAvailabilityDetails: {
      rawMaterialName: "",
      units: "",
      requiredQuantity: "",
      shortage: "",
      statusCode: "",
      expectedDelivery: ""
    },
    rmStatuses: [],
    errors: {
      rawMaterialName: "",
      units: "",
      requiredQuantity: "",
      shortage: "",
      statusCode: "",
      expectedDelivery: ""
    }
  };

  componentDidMount() {
    let data = { ...this.props.params, collabType: "CMO" };
    this.props.cmoFetchRMStatusList(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rmAdded === true) {
      let data = { ...this.props.params, collabType: "CMO" };
      setTimeout(() => this.props.cmoFetchRMAvailability(data), 2000);
      // this.props.cmoFetchRMAvailability(data);
      this.clearData();
    }
    if (nextProps.rmStatuses) {
      this.setState({ rmStatuses: nextProps.rmStatuses });
    }
  }
  handleChange = e => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      rmAvailabilityDetails: {
        ...prevState.rmAvailabilityDetails,
        [name]: value
      },
      errors: {
        rawMaterialName: "",
        units: "",
        requiredQuantity: "",
        shortage: "",
        statusCode: "",
        expectedDelivery: ""
      }
    }));
  };

  validate = () => {
    let isError = false;

    let { rmAvailabilityDetails } = this.state;

    let errors = {
      rawMaterialName: "",
      units: "",
      requiredQuantity: "",
      shortage: "",
      statusCode: "",
      expectedDelivery: ""
    };

    if (rmAvailabilityDetails.rawMaterialName === "") {
      isError = true;
      errors.rawMaterialName = "Please Enter Material rawMaterialName";
    } else if (rmAvailabilityDetails.units === "") {
      isError = true;
      errors.units = "Please Enter Units";
    } else if (rmAvailabilityDetails.requiredQuantity === "") {
      isError = true;
      errors.requiredQuantity = "Please Enter Required Quantity";
    } else if (rmAvailabilityDetails.shortage === "") {
      isError = true;
      errors.shortage = "Please Enter Shortage Quantity";
    } else if (rmAvailabilityDetails.statusCode === "") {
      isError = true;
      errors.statusCode = "Please Select Status";
    } else if (rmAvailabilityDetails.expectedDelivery === "") {
      isError = true;
      errors.expectedDelivery = "Please Select Expected Delivery date";
    } else {
      this.setState({ alltrue: true });
    }
    this.setState({ errors });
    return isError;
  };

  onSubmit = () => {
    let error = this.validate();
    if (!error) {
      this.setState({ disableSaveButton: true });
      let data = {
        ...this.props.params,
        collabType: "CMO",
        ...this.state.rmAvailabilityDetails
      };
      this.props.cmoAddRMAvailability(data);
    }
  };

  clearData = () => {
    this.setState({
      disableSaveButton: false,
      rmAvailabilityDetails: {
        rawMaterialName: "",
        units: "",
        requiredQuantity: "",
        shortage: "",
        statusCode: "",
        expectedDelivery: ""
      }
    });
  };

  render() {
    const { rmAvailabilityDetails } = this.state;
    return (
      <Grid
        container
        style={{
          justifyContent: "center"
        }}
      >
        <Grid
          item
          xs={12}
          sm={11}
          style={{
            backgroundColor: "#E7ECEE"
          }}
        >
          <Typography
            variant="h5"
            style={{ textAlign: "center", marginTop: "2rem" }}
          >
            Add/Update RM Availability Details
          </Typography>
          <Card
            style={{
              margin: "2rem 4rem 0rem 4rem",
              border: "1px solid #ABABAB",
              boxShadow: ""
            }}
          >
            <CardContent
              style={{
                margin: "1rem",
                marginBottom: 0
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={2}>
                  <TextField
                    label="Name"
                    name="rawMaterialName"
                    fullWidth
                    value={rmAvailabilityDetails.rawMaterialName}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    error={
                      this.state.errors.rawMaterialName === "" ? false : true
                    }
                    helperText={this.state.errors.rawMaterialName}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <TextField
                    label="Batch Size"
                    name="units"
                    fullWidth
                    value={rmAvailabilityDetails.units}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    error={this.state.errors.units === "" ? false : true}
                    helperText={this.state.errors.units}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <TextField
                    label="Required Quantity"
                    name="requiredQuantity"
                    fullWidth
                    value={rmAvailabilityDetails.requiredQuantity}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    error={
                      this.state.errors.requiredQuantity === "" ? false : true
                    }
                    helperText={this.state.errors.requiredQuantity}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <TextField
                    label="Shortage"
                    name="shortage"
                    fullWidth
                    value={rmAvailabilityDetails.shortage}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    error={this.state.errors.shortage === "" ? false : true}
                    helperText={this.state.errors.shortage}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <TextField
                    label="Status"
                    select
                    fullWidth
                    name="statusCode"
                    value={rmAvailabilityDetails.statusCode}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    error={this.state.errors.statusCode === "" ? false : true}
                    helperText={this.state.errors.statusCode}
                  >
                    <MenuItem value="" disabled>
                      Select Status
                    </MenuItem>
                    {this.state.rmStatuses.map((ele, i) => (
                      <MenuItem value={ele.statusCode} key={i}>
                        {ele.statusText}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value="open">Open</MenuItem>
                    <MenuItem value="Acknowledge">Acknowledge</MenuItem> */}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={2}>
                  <TextField
                    label={
                      <Typography
                        variant="caption"
                        style={{ fontSize: "0.65rem" }}
                      >
                        Expected Days for Delivery
                      </Typography>
                    }
                    select
                    fullWidth
                    name="expectedDelivery"
                    value={rmAvailabilityDetails.expectedDelivery}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    error={
                      this.state.errors.expectedDelivery === "" ? false : true
                    }
                    helperText={this.state.errors.expectedDelivery}
                  >
                    <MenuItem value={"0"}>0</MenuItem>
                    <MenuItem value={"5"}>5</MenuItem>
                    <MenuItem value={"10"}>10</MenuItem>
                    <MenuItem value={"15"}>15</MenuItem>
                    <MenuItem value={"20"}>20</MenuItem>
                    <MenuItem value={"25"}>25</MenuItem>
                    <MenuItem value={"30"}>30</MenuItem>
                  </TextField>
                </Grid>

                {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "1rem",
                    justifyContent: "center"
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={this.onSubmit}
                    disabled={this.state.disableSaveButton}
                    style={{
                      backgroundColor: this.state.disableSaveButton
                        ? "grey"
                        : "green",
                      //   borderRadius: "1rem",
                      color: "#ffffff"
                    }}
                  >
                    Add This Item
                  </Button>
                  <Typography
                    variant="body2"
                    onClick={this.clearData}
                    style={{
                      marginLeft: "2.5rem",
                      cursor: "pointer",
                      textDecoration: "underline"
                    }}
                  >
                    Clear Data
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          style={{
            marginTop: "1rem"
          }}
        >
          <Tabs
            value={0}
            // onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            style={{
              borderBottom: "1px solid #ABABAB"
            }}
          >
            <Tab label="Availability" />
          </Tabs>
        </Grid>
        <ViewAddedRM
          toggleRMAvailabilityScreen={this.props.toggleRMAvailabilityScreen}
          params={this.props.params}
        />
      </Grid>
    );
  }
}
const mapStatetoProps = state => ({
  rmAdded: state.pcmp.cmo.rmpm.rmAdded,
  rmStatuses: state.pcmp.cmo.rmpm.rmStatuses
});
export default connect(mapStatetoProps, {
  cmoAddRMAvailability,
  cmoFetchRMAvailability,
  cmoFetchRMStatusList
})(AddRMAvailability);
