import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';

import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import PublishIcon from '@material-ui/icons/Publish';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CircularProgress from '@material-ui/core/CircularProgress';

import { updateQualityEventData } from '../../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  header: {
    padding: '1rem',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    backgroundColor: '#F5F9FF',
    position: 'relative',
  },
  title: {
    fontWeight: 700,
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '10px',
    cursor: 'pointer',
  },
  requiredStar: {
    color: 'red',
  },
  inputLabel: {
    marginBottom: '0.5rem',
    fontSize: '85%',
  },
  files: {
    boder: '1px solid #707070',
    backgroundColor: '#F7F7F7',
    textAlign: 'center',
    height: '40px',
  },
  saveBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'capitalize',
    marginTop: '4rem',
    marginLeft: '7rem',
    marginBottom: '2rem',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const CloseEventScreen = (props) => {
  const classes = useStyles();
  const { openDialog, toggleDialog, stateData } = props;
  const [state, setState] = useState({
    loading: false,
    errors: {},
  });

  const handleOnChangeData = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: null },
    }));

    props.handleChangeData(name, value);
  };

  const handleFileUpload = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        let fileObject = {
          name: file.name,
          data: reader.result,
          customFileType: 'buffer',
          schrockenFileID: '',
          schrockenCustomFileID: '',
          customFileHash: '',
        };
        props.handleChangeData('customFiles', [
          ...stateData.customFiles,
          fileObject,
        ]);
      };
    });
  };
  const handleFileDelete = (index) => {
    const edit_newFiles = stateData.customFiles.filter(
      (file, i) => i !== index
    );
    props.handleChangeData('customFiles', edit_newFiles);
  };

  const handleCloseEvent = () => {
    props.handleChangeData('updateEventType', 'closeEvent');
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const investigatingTeamList = [];
    stateData.selectedTeamMembers.map((teamMember) => {
      const teamMemberObject = {};
      teamMemberObject.name = teamMember.userName || '';
      teamMemberObject.roleName = teamMember.roleName || '';
      teamMemberObject.emailID = teamMember.emailAddress;
      teamMemberObject.saasUserID = teamMember.userId;

      investigatingTeamList.push(teamMemberObject);
    });

    const affectedBatchesList = [];
    stateData.selectedBatches.map((batch) => {
      const batchObject = {};
      batchObject.batchNumber = batch.batchNumber;

      affectedBatchesList.push(batchObject);
    });

    const data = {
      orgName: localStorage.organizationName,
      qualityEventDetails: [
        {
          qualityEventDetails: {
            eventID: stateData.eventID,
            incidentRaisedBy: stateData.incidentRaisedBy,
            incidentRaisedByOrgID: stateData.incidentRaisedByOrgID,
            // CMO Information
            incidentFor: stateData.CMOMember,
            incidentForOrgID: stateData.CMOMemberOrgId,
            eventType: stateData.eventType,
            eventTypeID: stateData.eventTypeID,
            eventTypeAbbreviation: stateData.qualityEvent.eventTypeAbbreviation,
            // Screen 1
            eventTitle: stateData.eventTitle,
            eventDescription: stateData.eventDescription,
            eventOccurredDate: stateData.eventOccurredDate,
            eventReportedDate: stateData.eventReportedDate,
            eventReportedBy: stateData.reportedBy?.emailID,
            // Screen 2
            impact: stateData.selectedImpact?.impactId,
            impactText: stateData.selectedImpact?.impactType,
            frequency: stateData.selectedFrequency?.frequencyId,
            frequencyText: stateData.selectedFrequency?.frequencyType,
            location: stateData.selectedLocation?.locationName,
            facility: stateData.selectedFacility?.FacilityName,
            productCode: stateData.selectedProduct?.productCode,
            productName: stateData.selectedProduct?.productDescription,
            // Screen 3
            customFiles: stateData.customFiles,
            resolutionDescription: stateData.resolutionDescription,
            estimatedResolutionDate: stateData.estimatedResolutionDate,
            // General Data
            eventStatusID: 5,
            eventStatusText: 'Closed',
            markForDelete: false,
            priority: stateData.priority,
            containmentOrRemedialActions:
              stateData.containmentOrRemedialActions,
          },
          // Screen 2
          affectedBatchesList: affectedBatchesList,
          supplierDataList: stateData.supplierDataList,
          // Screen 3
          investigatingTeamLeadDetails: {
            teamLeadEmailID: stateData.selectedTeamLead.emailAddress || '',
            teamLeadSaasUserID: stateData.selectedTeamLead.userId || '',
            teamLeadRoleName: stateData.selectedTeamLead.roleName || '',
            teamLeadName: stateData.selectedTeamLead.userName || '',
          },
          investigatingTeamList: investigatingTeamList,
          // New Data
          analysisData: {
            analysisTitle: '',
            analysisStartDate: '',
            analysisEndDate: '',
            rootCause: '',
            analysisLeadDetails: {
              teamLeadName: '',
              teamLeadSaasUserID: '',
              teamLeadEmailID: '',
              teamLeadRoleName: '',
            },
          },
        },
      ],
    };
    props.updateQualityEventData(data);
  };
  const handleValidations = () => {
    const errorsData = {};
    let isError = false;

    if (
      state.containmentOrRemedialActions === '' ||
      state.containmentOrRemedialActions === null
    ) {
      errorsData.containmentOrRemedialActions = 'Please select description';
      isError = true;
    }

    if (isError) {
      setState((prevState) => ({
        ...prevState,
        errors: errorsData,
      }));
    } else {
      handleCloseEvent();
    }
  };

  return (
    <Dialog
      open={openDialog}
      //   classes={{ paper: classes.dialogPaper }}
      onClose={() => toggleDialog('closeEventDialog')}
      TransitionComponent={Transition}
      //   style={{ overflow: 'hidden' }}
      fullWidth
      //   maxWidth="md"
    >
      {state.loading && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            width: '100%',
            backdropFilter: 'blur(0.8px)',
            zIndex: 9,
            top: 0,
          }}
        >
          <CircularProgress thickness='5' />
        </div>
      )}
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <Typography variant='h5' className={classes.title}>
            Close Event
          </Typography>
          <Typography className={classes.title}>
            Quality Management System(QMS) Record
          </Typography>

          <div
            className={classes.closeBtn}
            onClick={() => toggleDialog('closeEventDialog')}
          >
            <Typography
              variant='caption'
              style={{
                lineHeight: 'normal',
                fontWeight: '700',
                paddingRight: '10px',
              }}
            >
              Cancel
            </Typography>
            <CloseIcon
              style={{
                color: 'red',
                fontSize: '2.5rem',
                paddingRight: '10px',
              }}
            />
          </div>
        </div>
      </div>
      <Box>
        <Box margin='auto' width='70%' marginTop='2rem'>
          <Typography variant='body2' className={classes.inputLabel}>
            Containment/Remedial Actions
            <span className={classes.requiredStar}>*</span>
          </Typography>
          <TextField
            minRows={4}
            multiline
            fullWidth
            onChange={(e) => {
              handleOnChangeData(
                'containmentOrRemedialActions',
                e.target.value
              );
            }}
            value={state.containmentOrRemedialActions}
            variant='outlined'
            placeholder='Enter the event description in as much details as possible (1250 characters)'
            error={!!state.errors.containmentOrRemedialActions}
            helperText={state.errors.containmentOrRemedialActions}
          />
        </Box>
        <Box margin='auto' marginTop='2rem' width='70%'>
          <Typography variant='body2' className={classes.inputLabel}>
            Upload necessary files for this event
          </Typography>
          <Dropzone
            onDrop={(files) => {
              handleFileUpload(files);
            }}
            accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps({
                  className: 'dropzone',
                  onDrop: (event) => event.preventDefault(),
                })}
                className={classes.files}
                style={{ border: '1px solid #707070' }}
              >
                <input {...getInputProps()} />
                <Typography
                  variant='body2'
                  style={{ cursor: 'pointer', marginTop: '0.6rem' }}
                >
                  Drag &amp; drop or click here to upload files
                </Typography>
                <InputAdornment position='end'>
                  {
                    <PublishIcon
                      fontSize='large'
                      style={{ marginLeft: '21.5rem', marginTop: '-1rem' }}
                    />
                  }
                </InputAdornment>
              </div>
            )}
          </Dropzone>

          <Typography variant='body' className={classes.font}>
            {stateData.customFiles.map((file, i) => (
              <p style={{ color: 'blue' }}>
                <u>{file.name}</u>
                <span style={{ verticalAlign: 'sub' }}>
                  <CloseIcon
                    fontSize='small'
                    color='error'
                    style={{
                      paddingLeft: '0.5rem',
                      paddingTop: '0.5rem',
                      cursor: 'pointer',
                      width: '19px',
                    }}
                    onClick={() => handleFileDelete(i)}
                  />
                </span>
              </p>
            ))}
          </Typography>
          <Button
            className={classes.saveBtn}
            variant='contained'
            color='primary'
            endIcon={<NavigateNextIcon />}
            disabled={stateData.containmentOrRemedialActions === ''}
            // onClick={handleCloseEvent}
            onClick={handleValidations}
          >
            ok
          </Button>
        </Box>
      </Box>

      {/* </DialogContent> */}
    </Dialog>
  );
};

export default connect(null, { updateQualityEventData })(CloseEventScreen);
