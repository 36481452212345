import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  //back and addbatch
  back: {
    textAlign: 'start',
  },
  addBatch: {
    textAlign: 'flex-end',
  },
  backBtn: {
    textTransform: 'capitalize',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      borderBottom: '2px solid black',
    },
  },
  //upload export
  export: {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid black',
    },
  },
  upload: {
    background:
      'transparent linear-gradient(251deg, #173072 0%, #301232 100%) 0% 0% no-repeat padding-box;',
    color: 'white',
    textTransform: 'none',
    borderRadius: '2rem',
    marginRight: 20,
    padding: '5px 20px',
    '&:hover': {
      backgroundColor: 'green',
      background: 'green',
      color: 'white',
    },
  },
  viewRMPM: {
    background: '#FF7600',
    color: 'white',
    textTransform: 'none',
    borderRadius: '2rem',
    marginRight: 20,
    padding: '5px 20px',
    '&:hover': {
      backgroundColor: 'green',
      background: 'green',
      color: 'white',
    },
  },
  flxEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  //Search button
  search: {
    padding: '2px 16px',
    backgroundColor: '#e36e39',
    color: 'white',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid black',
    },
  },

  agreement: {
    textDecoration: 'underline',
  },
  mainGrid: {
    backgroundColor: '#EAEAEA',
    padding: '0.5rem',
  },
  pdTp: { paddingTop: '1.5rem' },
  mgTp: { marginTop: '1.5rem' },
  dpFlx: { display: 'flex' },
  algnCntr: { alignItems: 'center' },
  flxEndSmFlxStrt: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      paddingTop: '1rem',
    },
  },
  pdTponLr: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: '1rem',
    },
  },
  padR20: {
    paddingRight: 20,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
  pointer: { cursor: 'pointer' },
  onHvrUndrline: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  contain: {
    paddingLeft: '2rem',
  },

  hgText: {
    fontWeight: 500,
    paddingRight: theme.spacing(1),
  },
  outlined: {
    padding: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    fontSize: '0.875rem',
  },
  formPerPage: {
    minWidth: 60,
    fontSize: '0.875rem',
  },

  searchFld: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  flxEd: {
    justifyContent: 'flex-end',
  },
  body: {
    border: '2px solid #EAEAEA',
    backgroundColor: '#FAFAFA',
    padding: '0.5rem',
    justifyContent: 'center',
  },
  flexColSpaceBtwn: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: 170,
  },
  headerGridTxt: {
    fontWeight: 550,
    paddingBottom: '1rem',
  },
  wrapGridText: {
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      whiteSpace: 'normal',
    },
  },
  padding: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },
  title: {
    fontSize: '1rem',
    paddingTop: '2rem',
    paddingBottom: '0.5rem',
    fontWeight: 500,
  },
  span: {
    width: '4rem',
    border: '2px solid #B4B4B4',
    display: 'flex',
    backgroundColor: '#B4B4B4',
  },
  sidePadding: {
    paddingBottom: '1rem',
    paddingRight: '3rem',
  },
  font: {
    paddingTop: '1rem',
    paddingBottom: '0.25rem',
    // width: 'max-content',
  },
  radio: {
    '&.MuiRadio-root': {
      color: '#FF7600',
      '&.MuiRadio-colorSecondary.Mui-checked': {
        color: '#FF7600',
      },
    },
  },
  availableToggle: {
    '&.MuiToggleButton-root': {
      borderRadius: '2rem',
      color: 'rgba(0, 0, 0, 0.87)',
      textTransform: 'none',
      lineHeight: 'normal',
      '&.Mui-selected': {
        color: '#EAEAEA',
        backgroundColor: '#53D533',
        '&:hover': {
          color: '#EAEAEA',
          backgroundColor: '#53D533',
        },
      },
      '&:hover': {
        color: '#EAEAEA',
        backgroundColor: '#53D533',
      },
    },
  },
  shortageToggle: {
    '&.MuiToggleButton-root': {
      borderRadius: '2rem',
      color: 'rgba(0, 0, 0, 0.87)',
      textTransform: 'none',
      lineHeight: 'normal',
      '&.Mui-selected': {
        color: '#EAEAEA',
        backgroundColor: '#FF1919',
        '&:hover': {
          color: '#EAEAEA',
          backgroundColor: '#FF1919',
        },
      },
      '&:hover': {
        color: '#EAEAEA',
        backgroundColor: '#FF1919',
      },
    },
  },
  paper: {
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #C6C6C6',
  },
  table: {
    minWidth: 1000,
    width: '100%',
  },
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: '#0000000a',
    },
  },
  head: {
    '&.MuiTableHead-root': {
      backgroundColor: '#0000000a',
    },
  },
  helperText: {
    '&.MuiFormControl-root ': {
      '&.MuiFormHelperText-root': {
        width: '200px',
        whiteSpace: 'normal',
      },
    },
  },
}));
