import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import clsx from 'clsx';
import moment from 'moment';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';

//files
import { useStyles } from '../deliverScheduleDetails/styles';
import { loadTableData } from '../../../../containers/actions/common/fileActions';
import RowsPerPage from '../../models/inputs/RowsPerPage';
import Filter from './batchFilter/Filter';
import {
  fetchBatches,
  newbatchesTable,
  getAllDSBatchStatus,
  batchStatusSource,
  getBatchQAStatus,
} from '../../../../containers/actions/common/saslAction';
import { getFeaturesAccess } from '../../../../containers/actions/common/accessControl';
import DeliveryScheduleBatchTable from '../deliveryScheduleBatchTable';
import Block from './QuickSummary/Block';
import Search from '../../grnReceipts/Search';
import StatusFilter from '../deliverScheduleDetails/StatusFilter';
import usePrevious from '../../../helpers/previousProps';
import PaginationInput from '../../models/pagination/PaginationInput';
import featuresAndValidations from '../../models/featuresAccess/FeaturesAndValidations';

const DSBIndex = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { params } = props.match;

  const { collabType, organizationName } = localStorage;
  const [CMOMember, selectCMO] = useState(
    collabType === 'Pharma Co' ? 'all' : organizationName
  );
  const [productSearch, setProductSearch] = useState('');
  const [searchQuery, searchQueryInput] = useState('');
  const [tempSearch, tempSearchQury] = useState('');
  const [status, selectStatus] = useState(['all']);
  const [qaStatus, selectQAStatus] = useState('all');
  const [viewByStatus, setViewByStatus] = useState('all');
  const [quickStatus, setQuickStatus] = useState(['all']);
  const [noRowsPerPage, setRowsPerPage] = useState(5);
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('Y-MM-DDTHH:mm:ss.SSS')
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('month').format('Y-MM-DDTHH:mm:ss.SSS')
  );
  const [filter, setFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  let loading = true;

  const getBatches = useCallback(() => {
    let data = {
      statusID: status,
      vendorMosymphonyID: CMOMember,
      searchByNumber: searchQuery,
      searchByProductCode: productSearch,
      BSDStartDate: startDate,
      BSDEndDate: endDate,
      pageSize: noRowsPerPage,
      pageNumber: currentPage,
      qaStatusID: String(qaStatus),
    };

    dispatch(loadTableData());
    dispatch(fetchBatches(data));
  }, [
    dispatch,
    CMOMember,
    productSearch,
    startDate,
    endDate,
    status,
    searchQuery,
    noRowsPerPage,
    currentPage,
    qaStatus,
  ]);

  // useEffect's
  useEffect(() => {
    getBatches();

    if (
      JSON.stringify(status) === JSON.stringify(['all']) &&
      JSON.stringify(quickStatus) === JSON.stringify(['all']) &&
      (CMOMember === 'all' || CMOMember === organizationName) &&
      productSearch === '' &&
      searchQuery === '' &&
      startDate === null &&
      endDate == null &&
      qaStatus === 'all'
    ) {
      setFilter(false);
    } else {
      setFilter(true);
    }

    return () => {
      if (newbatchesTable) {
        newbatchesTable.cancel('leaving page!');
      }
    };
  }, [
    dispatch,
    collabType,
    CMOMember,
    productSearch,
    startDate,
    endDate,
    status,
    quickStatus,
    qaStatus,
    searchQuery,
    noRowsPerPage,
    currentPage,
    getBatches,
    organizationName,
  ]);

  let totalPages = useSelector(
    (state) => state.pcmp.common.sasl.fetchAllBatches.data?.totalPages
  );

  const screenAccess = useSelector(
    (state) =>
      state.pcmp.common.accessControl.screensAccess?.data.fgBatch?.screenAccess
  );

  const featuresAccess = useSelector(
    (state) =>
      state.pcmp.common.accessControl.featuresAccess.deliverySchedulesFeatures
  );

  useEffect(() => {
    if (screenAccess && !featuresAccess)
      dispatch(getFeaturesAccess(10, 'deliverySchedulesFeatures')); //10 is DS screen ID
    // We can pick up DS screen Id from access screensAccess.data.deliverySchedule.screenId
  }, [dispatch, screenAccess, featuresAccess]);

  useEffect(() => {
    if (screenAccess) {
      dispatch(getAllDSBatchStatus());
      dispatch(getBatchQAStatus());
    }
    return () => {
      if (batchStatusSource) {
        batchStatusSource.cancel('leaving page!');
      }
    };
  }, [dispatch, screenAccess]);

  // useSelector's

  let filteredBatches = useSelector((state) => {
    if (
      state.pcmp.common.sasl.fetchAllBatches.data &&
      state.pcmp.common.sasl.fetchAllBatches.status === true
    ) {
      loading = false;
      return state.pcmp.common.sasl.fetchAllBatches.data
        .deliveryScheduleBatches;
    }
    if (
      state.pcmp.common.sasl.fetchAllBatches.data &&
      state.pcmp.common.sasl.fetchAllBatches.status === false
    ) {
      loading = false;
      return [];
    }
    return [];
  });

  let statusArray = useSelector((state) => {
    if (state.pcmp.common.sasl?.allDSStatus) {
      return state.pcmp.common.sasl.allDSStatus;
    }
    return [];
  });

  let qaStatusArray = useSelector((state) => {
    if (state.pcmp.common.sasl.allQAStatus) {
      return state.pcmp.common.sasl.allQAStatus;
    } else {
      return [];
    }
  });

  const quickStatuses = useSelector((state) => {
    if (state.pcmp.common.sasl?.quickSummaryStatus) {
      return state.pcmp.common.sasl.quickSummaryStatus;
    }
    return [];
  });

  let updated = useSelector((state) => {
    return state.pcmp.common.sasl?.batchUpdated;
  });

  let previousUpdate = usePrevious(updated);

  useEffect(() => {
    if (updated && updated !== previousUpdate && updated.status) {
      handleClean();
      getBatches();
    }
  }, [updated, previousUpdate, getBatches]);

  useEffect(() => {
    if (params.batchNo) {
      // let data = {
      //   statusID: status,
      //   vendorMosymphonyID: CMOMember,
      //   searchByNumber: params.batchNo.trim(),
      //   searchByProductCode: productSearch,
      //   BSDStartDate: null,
      //   BSDEndDate: null,
      //   pageSize: noRowsPerPage,
      //   pageNumber: currentPage,
      // };

      // dispatch(loadTableData());
      // dispatch(fetchBatches(data));
      setStartDate(null);
      setEndDate(null);
      tempSearchQury(params.batchNo);
      searchQueryInput(params.batchNo.trim());
      setFilter(true);
    }
  }, [params.batchNo]);

  let cmoMemberWithId = useSelector((state) =>
    state.pcmp.pharmaCo.purchaseOrder.cmoMemberWithId
      ? state.pcmp.pharmaCo.purchaseOrder.cmoMemberWithId
      : []
  );

  // function for CMO Fliter
  const handleSelectCMO = (event) => {
    event.preventDefault();
    let val = String(event.target.value);
    if (val === 'all') {
      selectCMO('all');
    } else {
      let cmo = cmoMemberWithId.filter(
        (member) => member.organization_name === val
      );
      selectCMO(String(cmo[0].organization_id));
    }
    setFilter(true);
    setCurrentPage(1);
    loading = true;
  };

  const selectedCMO = () => {
    if (CMOMember === 'all') {
      return CMOMember;
    } else {
      let cmo = cmoMemberWithId.filter(
        (member) => member.organization_id === parseInt(CMOMember)
      );
      return cmo[0].organization_name;
    }
  };

  // functions for Product Search Filter
  const handleProductSearchQuery = (value) => {
    if (value === '' || value === null) {
      setProductSearch('');
      setCurrentPage(1);
    } else {
      setProductSearch(value.fgMaterialCode);
      setFilter(true);
      setCurrentPage(1);
    }
  };

  // function for Date Filter
  const handleDateChange = (name, date) => {
    let d = new Date(date);

    if (name === 'startDate') {
      d = moment(date)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('milliseconds', 0)
        .format('Y-MM-DDTHH:mm:ss.SSS');

      setStartDate(d);
    }
    if (name === 'endDate') {
      d = moment(date)
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .set('milliseconds', 999)
        .format('Y-MM-DDTHH:mm:ss.SSS');

      setEndDate(d);
      setCurrentPage(1);
    }
    setFilter(true);
  };

  // functions for Search By Batch/ (PO/SA)/ GRN Number
  const handleSearchQuery = (event) => {
    let { value } = event.target;
    if (value === '' || value === null) {
      tempSearchQury(value);
      searchQueryInput('');
      setFilter(false);
    } else {
      tempSearchQury(value);
      setFilter(true);
    }
  };

  const handleClearSearch = (event) => {
    event.preventDefault();
    tempSearchQury('');
    searchQueryInput('');
  };

  const submitSearch = (event) => {
    event.preventDefault();
    setCurrentPage(1);
    searchQueryInput(tempSearch.trim());
  };

  // functions for the Status Filter
  // handleViewStatus
  const handleViewStatus = (event) => {
    setQuickStatus(['all']);
    let status = String(event.target.value);
    setViewByStatus(status);
    selectStatus([status]);
    setFilter(true);
    setCurrentPage(1);
  };

  // handle Quick Summary Status
  const handleQuickStatus = (value) => {
    setViewByStatus('all');
    // integer array to string array
    let status = value.map(String);
    setQuickStatus(status);
    selectStatus(status);
    setFilter(true);
    setCurrentPage(1);
  };

  const handleCleanQuickSummary = () => {
    setQuickStatus(['all']);
    selectStatus(['all']);
    setRowsPerPage(5);
    setCurrentPage(1);
  };

  // Clean function to clean filters
  const handleClean = () => {
    selectCMO('all');
    setProductSearch('');
    setStartDate(null);
    setEndDate(null);
    searchQueryInput('');
    selectStatus(['all']);
    setRowsPerPage(5);
    setCurrentPage(1);
    tempSearchQury('');
    setFilter(false);
    setViewByStatus('all');
    setQuickStatus(['all']);
    selectQAStatus('all');
  };

  //debounce
  const debounce = (callback, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    };
  };

  const debouncedLog = debounce((data) => {
    setCurrentPage(data);
  }, 600);

  const getCurrentPageNumber = (data) => {
    debouncedLog(data);
  };

  // functions for pagination
  const handleSetRows = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleSelectQAStatus = (event) => {
    selectQAStatus(event.target.value);
  };

  const qualityApprovalFlowFlag = useSelector((state) => {
    return state.pcmp.common.reports.rmpmGrnFeatures?.data?.features
      .qualityApprovalEnabled;
  });

  let showPagination = filteredBatches
    ? filteredBatches.length > 0
      ? true
      : false
    : false;

  return (
    <React.Fragment>
      <Grid container justifyContent='space-between' alignItems='flex-end'>
        <Grid item xs={12} md={10}>
          <Filter
            CMOMember={collabType === 'Pharma Co' && selectedCMO()}
            CMOMemberId={CMOMember}
            cmoFilter={collabType === 'Pharma Co' ? true : false}
            handleSelectCMO={handleSelectCMO}
            productSearch={productSearch}
            handleProductSearchQuery={handleProductSearchQuery}
            startDate={startDate}
            endDate={endDate}
            handleDateChange={handleDateChange}
          />
        </Grid>
        {filter && (
          <Grid item style={{ alignItems: 'end' }}>
            <Typography
              variant='caption'
              style={{ justifyContent: 'end', fontSize: 12 }}
              className={clsx(
                classes.dpFlx,
                classes.algnCntr,
                classes.pointer,
                classes.onHvrUndrline
              )}
              onClick={handleClean}
            >
              Clear All Filters
              <ClearIcon color='primary' style={{ fontSize: 15 }} />
            </Typography>
          </Grid>
        )}
      </Grid>

      <Divider style={{ marginTop: '1rem' }} />
      <Grid
        container
        alignItems='center'
        className={classes.pdTp}
        style={{ paddingBottom: '1rem', paddingTop: '1rem' }}
      >
        <Grid
          container
          style={{ paddingBottom: '1rem', justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              variant='body2'
              style={{ paddingBottom: 'inherit', fontWeight: 600 }}
            >
              Quick Summary:
            </Typography>
          </Grid>
          {status === quickStatus && (
            <Grid item>
              <Typography
                variant='caption'
                color='primary'
                onClick={handleCleanQuickSummary}
                className={clsx(
                  classes.dpFlx,
                  classes.algnCntr,
                  classes.pointer,
                  classes.onHvrUndrline
                )}
              >
                Clear Quick Summary
                <ClearIcon color='primary' style={{ fontSize: 15 }} />
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3} style={{ justifyContent: 'space-evenly' }}>
          {quickStatuses.map((statuses, i) => (
            <Block
              key={i}
              data={statuses}
              handleQuickStatus={handleQuickStatus}
              status={status}
              CMOMember={CMOMember}
              productSearch={productSearch}
              startDate={startDate}
              endDate={endDate}
            />
          ))}
        </Grid>
      </Grid>
      <Divider />

      <Grid
        container
        alignItems='center'
        className={classes.pdTp}
        style={{ paddingBottom: '2rem', paddingTop: '2rem' }}
      >
        <Grid item xs={12} sm={10} md={6}>
          <Search
            searchQuery={tempSearch}
            handleSearchQuery={handleSearchQuery}
            handleClearSearch={handleClearSearch}
            submitSearch={submitSearch}
            placeholder={'Search using Batch, PO/SA, GRN Number'}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid
            container
            className={clsx(
              classes.dpFlx,
              classes.flxEndSmFlxStrt,
              classes.pdTponLr
            )}
          >
            <Typography variant='caption' className={clsx(classes.padR20)}>
              {`Last synced @ ${moment(new Date()).format(
                'HH:mm:ss; DD MMMM, YYYY'
              )}`}
            </Typography>

            <Typography
              variant='caption'
              color='primary'
              onClick={getBatches}
              className={clsx(
                classes.dpFlx,
                classes.algnCntr,
                classes.pointer,
                classes.onHvrUndrline
              )}
            >
              Refresh
              <CachedIcon color='primary' style={{ fontSize: 15 }} />
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems='center' className={classes.pdTp}>
          <Grid item xs={4} sm={3}>
            <Typography variant='body2' className={classes.hgText}>
              View by:
            </Typography>
            <StatusFilter
              status={viewByStatus}
              handleSelectStatus={handleViewStatus}
              statusArray={statusArray}
            />
          </Grid>
          {qualityApprovalFlowFlag && (
            <Grid item xs={4} sm={3} style={{ paddingLeft: '10px' }}>
              <Typography variant='body2' className={classes.hgText}>
                View by QA Status:
              </Typography>
              <FormControl style={{ minWidth: 120 }} fullWidth>
                <Select
                  labelId='qa-status-filter'
                  id='qa-status-filter-id'
                  value={qaStatus}
                  onChange={handleSelectQAStatus}
                  variant='outlined'
                  classes={{
                    outlined: classes.outlined,
                  }}
                  // MenuProps={{
                  //   PaperProps: {
                  //     style: {
                  //       height: qaStatusArray.length > 1 ? '27%' : '8%',
                  //       overflowY:
                  //         qaStatusArray.length > 1 ? 'scroll' : 'hidden',
                  //     },
                  //   },
                  // }}
                >
                  <MenuItem value='all'>All</MenuItem>
                  {qaStatusArray.map((dsStatus, index) => {
                    return (
                      <MenuItem key={index} value={dsStatus.statusID}>
                        {dsStatus.statusDisplayName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {/* <StatusFilter
                status={qaStatus}
                handleSelectStatus={handleSelectQAStatus}
                statusArray={qaStatusArray}
              /> */}
            </Grid>
          )}

          <Grid
            item
            xs={4}
            sm={6}
            className={clsx(classes.searchFld, classes.flxEd)}
          >
            <RowsPerPage value={noRowsPerPage} onChange={handleSetRows} />
          </Grid>
        </Grid>
      </Grid>

      <DeliveryScheduleBatchTable
        batchData={filteredBatches}
        loading={loading}
        batchDashboard
      />

      {showPagination === true && (
        <PaginationInput
          totalPages={totalPages}
          handlePageChange={getCurrentPageNumber}
          page={currentPage}
        />
      )}
    </React.Fragment>
  );
};

export default featuresAndValidations(DSBIndex);
