import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import Moment from 'moment';

import SelectionBar from './SelectionBar';
import ReportsTableWithPagination from '../../../../common/tables/ReportsTableWithPagination';
import {
  viewLineAuditReport,
  fetchPOLines,
  downloadReport,
} from '../../../../../containers/actions/common/reportsActions';

const header = [
  {
    label: ' PO No',
    id: 'purchaseOrderNumber',
    align: 'left',
    span: 0.2,
  },
  {
    label: ' Line No',
    id: 'purchaseOrderLineNumber',
    align: 'left',
    span: 0.2,
  },
  {
    label: ' Date/Time',
    id: 'eventDateTime',
    align: 'left',
    span: 0.2,
  },
  {
    label: '  Event By User',
    id: 'userOfEvent',
    align: 'left',
    span: 0.2,
  },
  {
    label: ' Event Type',
    id: 'eventType',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'PO Line Status',
    id: 'POLineStatus',
    align: 'left',
    span: 0.1,
  },
  // {
  //   label: "Material",
  //   id: "material",
  //   align: "right",
  //   span: 0.1
  // },
  // {
  //   label: "Description",
  //   id: "materialDescription",
  //   align: "right",
  //   span: 0.1
  // },
  {
    label: ' Product Code',
    id: 'productCode',
    align: 'right',
    span: 0.1,
  },
  {
    label: ' Product Name',
    id: 'productName',
    align: 'left',
    span: 0.1,
  },
  {
    label: ' Plant Code',
    id: 'plantCode',
    align: 'right',
    span: 0.1,
  },
  {
    label: '  PO Quantity',
    id: 'POLineQuantity',
    align: 'right',
    span: 0.1,
  },
  {
    label: '  UOM',
    id: 'UOM',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'Scheduled Delivery',
    id: 'scheduledDeliveryDate',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'Commit Date',
    id: 'commitDate',
    align: 'right',
    span: 0.1,
  },
  {
    label: 'Currency',
    id: 'currency',
    align: 'right',
    // width: 120,
    span: 0.1,
  },
  {
    label: ' Effective Value',
    id: 'Currency',
    align: 'right',
    // width: 120,
    span: 0.1,
  },
];
class POLineAuditReport extends Component {
  state = {
    header: header,
    data: [],
    lineNumbers: [],
    CMOMember: '',
    purchaseOrderNumber: '',
    purchaseOrderLineNumber: '',
    requestDownload: false,
    reportNumber: 3,
    rowsPerPage: 5,
    page: 0,
    open: false,
    showReport: false,
    reportError: null,
    errors: {
      poError: false,
      lineError: false,
    },
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.poLineAuditReport) {
  //     return { data: nextProps.poLineAuditReport };
  //   }
  //   if (nextProps.lineNumbers) {
  //     return { lineNumbers: nextProps.lineNumbers };
  //   }
  //   return null;
  // }
  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    if (
      nextProps.poLineAuditReport !== this.props.poLineAuditReport &&
      nextProps.poLineAuditReport.status === true
    ) {
      this.setState({
        data: nextProps.poLineAuditReport.reportData,
        requestedBy: nextProps.poLineAuditReport.requestedBy,
        showReport: true,
        reportError: null,
      });
    }

    if (
      nextProps.poLineAuditReport !== this.props.poLineAuditReport &&
      nextProps.poLineAuditReport.status === false
    ) {
      this.setState({
        reportError: nextProps.poLineAuditReport.errorDescription,
        showReport: true,
      });
    }

    if (nextProps.lineNumbers) {
      this.setState({ lineNumbers: nextProps.lineNumbers });
    }
  }
  componentDidUpdate(nextProps, prevState) {
    if (nextProps.poNumberError !== this.props.poNumberError) {
      this.setState((state) => ({
        ...state,
        data: [],
        lineNumbers: [],
        showReport: false,
        errors: {
          ...state.errors,
          poError: true,
        },
      }));
      this.handleClose();
    }
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'purchaseOrderNumber') {
      this.setState({ purchaseOrderLineNumber: '' });
    }
    this.setState({
      [name]: value,
      errors: {
        poError: false,
        lineError: false,
      },
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
    let data = {
      purchaseOrderNumber: this.state.purchaseOrderNumber,
      collabType: this.props.collabType,
      ...this.props.match.params,
    };
    this.props.fetchPOLines(data);
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  validate = () => {
    let error = false;
    let errors = {
      poError: false,
      lineError: false,
    };
    const { purchaseOrderNumber, purchaseOrderLineNumber } = this.state;
    if (purchaseOrderNumber === '') {
      error = true;
      errors.poError = true;
    } else if (purchaseOrderLineNumber === '') {
      error = true;
      errors.lineError = true;
    }
    this.setState({ errors });
    return error;
  };

  fetchReport = () => {
    let err = this.validate();
    if (!err) {
      const { purchaseOrderNumber, purchaseOrderLineNumber } = this.state;
      let data = {
        purchaseOrderNumber,
        schrockenPOLineID: purchaseOrderLineNumber,
        collabType: this.props.collabType,
        CMOMember: this.props.match.params.organizationName,
        ...this.props.match.params,
      };

      this.props.viewLineAuditReport(data);
      this.setState({ showReport: false });
    }
  };
  downloadReport = () => {
    let err = this.validate();
    if (!err) {
      const {
        reportNumber,
        purchaseOrderNumber,
        purchaseOrderLineNumber,
      } = this.state;
      let data = {
        reportNumber,
        purchaseOrderNumber,
        schrockenPOLineID: purchaseOrderLineNumber,
        CMOMember: this.props.match.params.organizationName,
        collabType: this.props.collabType,
        ...this.props.match.params,
      };
      this.props.downloadReport(data);
    }
  };

  render() {
    const {
      header,
      data,
      purchaseOrderNumber,
      purchaseOrderLineNumber,
      page,
      rowsPerPage,
      open,
      lineNumbers,
      showReport,
      requestedBy,
      errors,
      reportError,
    } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    const tableBody =
      data.length > 0 ? (
        data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, i) => (
            <TableRow key={i}>
              <TableCell align='left'>{row.purchaseOrderNumber}</TableCell>
              <TableCell align='right'>{row.lineNumber}</TableCell>
              <TableCell align='right'>
                {Moment(row.lastUpdatedDate).format(`${dateFormat} HH:mm:ss`)}
              </TableCell>
              <TableCell align='right'>{row.lastUpdatedBy}</TableCell>
              <TableCell align='left'>{row.eventType}</TableCell>
              <TableCell align='left'>{row.statusText}</TableCell>
              <TableCell align='right'>{row.productCode}</TableCell>
              <TableCell align='left'>{row.productName}</TableCell>
              <TableCell align='right'>{row.plantCode}</TableCell>
              <TableCell align='right'>{row.quantity}</TableCell>
              <TableCell align='right'>{row.unitOfMeasurement}</TableCell>
              <TableCell align='right'>
                {Moment(row.deliveryDate).format(dateFormat)}
              </TableCell>
              <TableCell align='right'>
                {row.poLineCommitDate !== '' && row.poLineCommitDate !== null
                  ? Moment(row.poLineCommitDate).format(dateFormat)
                  : null}
              </TableCell>
              <TableCell align='right'>{row.currency}</TableCell>
              <TableCell align='right'>{row.effectiveValue}</TableCell>
            </TableRow>
          ))
      ) : (
        <TableRow>
          <TableCell colSpan={header.length} align='center'>
            {reportError ? (
              <Typography variant='body2' color='error'>
                {reportError}
              </Typography>
            ) : (
              <Typography variant='body2'>No Records Found</Typography>
            )}
          </TableCell>
        </TableRow>
      );

    return (
      <div>
        <SelectionBar
          fetchReport={this.fetchReport}
          purchaseOrderNumber={purchaseOrderNumber}
          purchaseOrderLineNumber={purchaseOrderLineNumber}
          open={open}
          handleChange={this.handleChange}
          handleOpen={this.handleOpen}
          handleClose={this.handleClose}
          lineNumbers={lineNumbers}
          lineSelectDisabled={purchaseOrderNumber === '' ? true : false}
          poError={errors.poError}
          lineError={errors.lineError}
        />

        {showReport === true && (
          <ReportsTableWithPagination
            header={header}
            tableBody={tableBody}
            downloadReport={this.downloadReport}
            length={data.length}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            requestedBy={requestedBy}
            data={data}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poLineAuditReport: state.pcmp.common.reports.poLineAuditReport,
  lineNumbers: state.pcmp.common.reports.lineNumbers,
  poNumberError: state.pcmp.common.reports.poNumberError,
});
export default connect(mapStateToProps, {
  viewLineAuditReport,
  fetchPOLines,
  downloadReport,
})(POLineAuditReport);
