import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Grid, Typography, Button } from '@material-ui/core/';
// dialog
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import StyledButton from '../../../../../common/models/Button';
// Icons
import CloseIcon from '@material-ui/icons/Close';

// import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import EditTextField from './editTextfield';
import {
  userProfileData,
  updateUserProfile,
} from '../../../../../../containers/actions/userActions';
import { resetPassword } from '../../../../../../containers/actions/userActions';
import defaultImg from '../../../../../../resources/images/profile2.png';

//image crop
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import BackDrop from '../../models/BackDrop';
import DataSavedDialog from '../../supplierPOUpdate/DeletePromptDialog';
//reset password
import ResetPwdBackdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem 3rem 0 0',
  },
  paper: {
    width: '100%',
  },
  borderBottom: {
    borderBottom: '1px solid #E5E5E5',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  textBottom: {
    borderBottom: '1px solid #E5E5E5',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    textAlign: 'center',
  },
  profileDiv: {
    textAlign: 'center',
  },
  editField: {
    display: 'flex',
    flexDirection: 'column',
  },
  editDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paddingTop: {
    paddingTop: '1rem',
  },
  divPadding: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  profilePicDiv: {
    width: '200px',
    height: '200px',
    border: '1px solid #E3E3E3',
    borderRadius: '50%',
  },
  buttonText: {
    paddingRight: '1rem',
    paddingLeft: '1rem',
  },

  //
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '0.5px solid red',
      color: '#e36e39',
    },
  },
  saved: {
    textAlign: 'center',
    padding: '0.5rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  text: {
    textAlign: 'center',
    fontSize: '1rem',
    margin: '1rem',
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
  },
  icon: {
    fontSize: '5rem',
    color: '#348feb',
  },
  buttonGrid: {
    textAlign: 'center',
    padding: '0.5rem',
    paddingBottom: '2rem',
  },
  noButton: {
    margin: '1.5rem',
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
  backdrop: {
    zIndex: 10000,
    display: 'flex',
    color: '#fff',
    flexDirection: 'column',
  },
});

class MyProfile extends Component {
  state = {
    editField: '',
    open: false,
    email: '',
    userProfile: this.props.userDetails,
    file: '',
    error: '',
    imgTypeError: '',

    //image Crop
    src: null,
    crop: {
      unit: '%',
      width: 30,
      aspect: 1,
    },
    openPicDialog: false,
    dataSavedDialog: false,
    //reset password
    resetPwdBackdrop: false,
    passwordChanged: false,
  };

  componentDidMount() {
    this.props.userProfileData();
  }

  // props
  componentDidUpdate(prevProps, prevState) {
    if (this.props.userDetails !== prevProps.userDetails) {
      this.setState({ userProfile: this.props.userDetails });
    }
    if (this.props.editField !== prevProps.editField) {
      this.setState({ editField: this.props.editField });
    }
    if (
      this.props.userDetails !== prevProps.userDetails &&
      this.props.userDetails.status === true
    ) {
      this.setState({
        openPicDialog: false,
        dataSavedDialog: true,
      });
    }
    if (
      this.props.resetUserPassword !== prevProps.resetUserPassword &&
      this.props.resetUserPassword.status === false
    ) {
      this.setState({
        error: this.props.resetUserPassword.errorDescription,
        resetPwdBackdrop: false,
      });
    }
    if (
      this.props.resetUserPassword !== prevProps.resetUserPassword &&
      this.props.resetUserPassword.status === true
    ) {
      this.setState({
        error: '',
        resetPwdBackdrop: false,
        passwordChanged: true,
        open: false,
      });
    }
  }

  onEditField = (name) => {
    this.setState({
      editField: name,
    });
  };

  // save
  saveEdit = (key, value) => {
    if (value !== '') {
      const editData = [{ [key]: value }];
      this.props.updateUserProfile(editData);
      this.cancelEdit();
    }
  };

  // undo edit
  cancelEdit = () => {
    this.setState({ editField: 'none' });
    this.props.onEditField('');
  };

  // profile img upload
  //  uploadImage = (e) => {
  //     e.preventDefault();

  //     const reader = new FileReader();
  //     const file = e.target.files[0];

  //     // reader.readAsDataURL(file);
  //     reader.readAsArrayBuffer(file);
  //     reader.onload = (e) => {
  //       const arr = new Uint8Array(e.target.result).subarray(0, 4);
  //       let header = '';
  //       for (let i = 0; i < arr.length; i++) {
  //         header += arr[i].toString(16);
  //       }

  //       const imgType = this.imgfileType(header);
  //       if (imgType === true) {
  //         reader.readAsDataURL(file);
  //         reader.onload = (e) => {
  //           const editProfilePic = [
  //             {
  //               profile_pic: e.target.result,
  //             },
  //           ];
  //           this.props.updateUserProfile(editProfilePic);
  //           this.setState({ imgTypeError: '' });
  //         };
  //       } else {
  //         this.setState({
  //           imgTypeError: 'Please upload jpg, jpeg,bmp or png image only.',
  //         });
  //       }
  //     };
  //   };

  // validate image type , allow jpg,jpeg and png using magic numbers
  imgfileType = (header) => {
    switch (header) {
      case '89504e47':
        return true;

      case 'ffd8ffe0':
      case 'ffd8ffe1':
      case 'ffd8ffe2':
      case 'ffd8ffe3':
      case 'ffd8ffe8':
      case '42 4d':
      case '424db628': //here .bmp extension hex - '424d' won't work since in onSelect handler func
        //  we are appending and adding subarray, so have to use hex - '424db628'
        return true;

      case '47494638':
        return false;
      default:
        return false;
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  closeDialog = () => {
    this.setState({ open: false });
  };

  submitResetPassword = (e) => {
    // this.setState({ open: false, resetEmail:'' });
    // e.preventDefault();
    // if (this.state.email === '') {
    //   this.setState({
    //     error: 'Please enter your email.',
    //   });
    // } else {
    //   this.setState({ restrict: true });

    //   const email = {
    //     email: this.state.userProfile.email,
    //   };
    //   this.props.resetPassword(email);
    // }
    this.setState({
      resetPwdBackdrop: true,
      open: false,
    });
    const email = {
      email: this.state.userProfile.email_address,
    };
    this.props.resetPassword(email);
  };

  handleClosePwd = () => {
    this.setState({
      passwordChanged: false,
      open: false,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      error: '',
    });
  };

  // image crop v2.1
  handlePicDialog = () => {
    this.setState({
      openPicDialog: !this.state.openPicDialog,
      src: null,
      croppedImageUrl: null,
      imgTypeError: '',
    });
  };

  handleDataSavedDialog = () => {
    this.setState({ dataSavedDialog: false });
  };

  onSelectFile = (e) => {
    e.preventDefault();
    this.setState({ imgTypeError: '' });

    const reader = new FileReader();
    const file = e.target.files[0];

    // reader.readAsDataURL(file);
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      const arr = new Uint8Array(e.target.result).subarray(0, 4);
      let header = '';
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }

      const imgType = this.imgfileType(header);
      console.log(header);
      if (imgType === true) {
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const editProfilePic = [
            {
              profile_pic: e.target.result,
            },
          ];
          this.setState({
            src: reader.result,
            croppedImageUrl: editProfilePic,
            imgTypeError: '',
          });
        };
      } else {
        this.setState({
          imgTypeError: 'Please upload jpg, jpeg,bmp or png image only.',
          src: null,
          croppedImageUrl: null,
        });
      }
    };
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL('image/jpeg');

    // return new Promise((resolve, reject) => {
    //   canvas.toBlob((blob) => {
    //     if (!blob) {
    //       //reject(new Error('Canvas is empty'));
    //       console.error('Canvas is empty');
    //       return;
    //     }
    //     blob.name = fileName;
    //     window.URL.revokeObjectURL(this.fileUrl);
    //     this.fileUrl = window.URL.createObjectURL(blob);
    //     resolve(this.fileUrl);
    //   }, 'image/jpeg');
    // });

    return base64Image;
  }

  handleSaveProfilePic = () => {
    const editProfilePic = [
      {
        profile_pic: this.state.croppedImageUrl,
      },
    ];
    this.props.updateUserProfile(editProfilePic);
  };

  render() {
    const { classes } = this.props;
    const {
      editField,
      // error,
      imgTypeError,
      openPicDialog,
      src,
      crop,
      croppedImageUrl,
      dataSavedDialog,
      // email,
      resetPwdBackdrop,
      passwordChanged,
    } = this.state;
    const { profile_pic } = this.state.userProfile;

    let image = null;
    if (!profile_pic) {
      image = (
        <img src={defaultImg} alt='no img' className={classes.profilePicDiv} />
      );
    } else {
      image = (
        <img src={profile_pic} alt='no img' className={classes.profilePicDiv} />
      );
    }
    return (
      <Grid container style={{ marginTop: '1.5rem' }}>
        <Grid item xs={12} sm={4} md={4}>
          <Dialog
            open={this.state.open}
            onClose={this.closeDialog}
            fullWidth={true}
            maxWidth={'sm'}
          >
            <Grid container justify='flex-end'>
              <Typography
                variant='body2'
                className={classes.closeText}
                onClick={this.closeDialog}
                style={{ margin: '0.5rem' }}
              >
                Close <CloseIcon fontSize='large' />
              </Typography>
            </Grid>
            <Typography variant='h4' className={classes.saved}>
              Reset Password
            </Typography>

            <Typography variant='body2' className={classes.text}>
              We will send an email to {this.state.userProfile.email_address}.
              <br />
              Please Confirm &amp; check your inbox for instructions from us on
              how to reset your password.
            </Typography>

            <Grid item xs={12} className={classes.buttonGrid}>
              <StyledButton
                style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
                onClick={this.submitResetPassword}
              >
                Confirm
              </StyledButton>
            </Grid>
          </Dialog>

          <div className={classes.grid}>
            <div
              className={classes.borderBottom}
              style={{ textAlign: 'center' }}
            >
              <div className={classes.profileDiv}>{image}</div>
              {imgTypeError && (
                <Typography variant='caption' color='error'>
                  {imgTypeError}
                </Typography>
              )}
            </div>

            <div className={classes.textBottom}>
              <InputLabel htmlFor='imgUpload'>
                <Typography
                  variant='body1'
                  style={{ cursor: 'pointer', color: '#000000' }}
                  onClick={this.handlePicDialog}
                >
                  Change Picture
                </Typography>
              </InputLabel>

              {/* <Input
                id='imgUpload'
                type='file'
                accept='.jpg,.jpeg,.png'
                onChange={this.uploadImage}
                style={{ display: 'none' }}
                inputProps={{
                  accept: 'image/*',
                }}
              /> */}
            </div>

            <div
              className={classes.textBottom}
              onClick={this.handleClickOpen}
              style={{ cursor: 'pointer' }}
            >
              <Typography variant='body1'>Change Password</Typography>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={8} md={8}>
          <div className={classes.editField}>
            <div>
              {editField === 'full_name' ? (
                <div className={classes.borderBottom}>
                  <EditTextField
                    label='FULL NAME'
                    value={
                      this.state.userProfile.full_name === null
                        ? ''
                        : this.state.userProfile.full_name
                    }
                    id='full_name'
                    saveEdit={this.saveEdit}
                    cancelEdit={this.cancelEdit}
                  />
                </div>
              ) : (
                <React.Fragment>
                  <Typography variant='body1' className={classes.paddingTop}>
                    FULL NAME
                  </Typography>

                  <div className={`${classes.editDiv} ${classes.borderBottom}`}>
                    <Typography variant='body1'>
                      {this.state.userProfile.full_name === null
                        ? 'Not specified'
                        : this.state.userProfile.full_name}
                    </Typography>
                    <Button
                      variant='outlined'
                      onClick={() => this.onEditField('full_name')}
                    >
                      <Typography
                        variant='button'
                        className={classes.buttonText}
                      >
                        EDIT
                      </Typography>
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </div>

            <div>
              <Typography variant='body1' className={classes.paddingTop}>
                EMAIL ADDRESS
              </Typography>
              <Typography variant='body1' className={classes.borderBottom}>
                {this.state.userProfile.email_address}
              </Typography>
            </div>

            <div>
              <Typography variant='body1' className={classes.paddingTop}>
                ORGANIZATION
              </Typography>

              <Typography variant='body1' className={classes.borderBottom}>
                {this.state.userProfile.organization_name}
              </Typography>
            </div>
            <div>
              <Typography variant='body1' className={classes.paddingTop}>
                ROLE
              </Typography>

              <Typography variant='body1' className={classes.borderBottom}>
                {this.state.userProfile.role_name}
              </Typography>
            </div>

            <div>
              {editField === 'about' ? (
                <div className={classes.borderBottom}>
                  <EditTextField
                    label='ABOUT'
                    value={
                      this.state.userProfile.about === null
                        ? ''
                        : this.state.userProfile.about
                    }
                    id='about'
                    saveEdit={this.saveEdit}
                    cancelEdit={this.cancelEdit}
                  />
                </div>
              ) : (
                <React.Fragment>
                  <Typography variant='body1' className={classes.paddingTop}>
                    ABOUT
                  </Typography>
                  <div className={`${classes.editDiv} ${classes.borderBottom}`}>
                    <Typography variant='body1'>
                      {this.state.userProfile.about === null
                        ? 'Not Specified'
                        : this.state.userProfile.about}
                    </Typography>
                    <Button
                      variant='outlined'
                      onClick={() => this.onEditField('about')}
                    >
                      <Typography
                        variant='button'
                        className={classes.buttonText}
                      >
                        EDIT
                      </Typography>
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </div>

            <div>
              {editField === 'country' ? (
                <div className={classes.borderBottom}>
                  <EditTextField
                    label='COUNTRY'
                    value={
                      this.state.userProfile.country === null
                        ? ''
                        : this.state.userProfile.country
                    }
                    id='country'
                    saveEdit={this.saveEdit}
                    cancelEdit={this.cancelEdit}
                  />
                </div>
              ) : (
                <React.Fragment>
                  <Typography variant='body1' className={classes.paddingTop}>
                    COUNTRY
                  </Typography>
                  <div className={classes.editDiv}>
                    <Typography variant='body1'>
                      {this.state.userProfile.country === null
                        ? 'Not Specified'
                        : this.state.userProfile.country}
                    </Typography>
                    <Button
                      variant='outlined'
                      onClick={() => this.onEditField('country')}
                    >
                      <Typography
                        variant='button'
                        className={classes.buttonText}
                      >
                        EDIT
                      </Typography>
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </Grid>

        {/* v2.1 - to be able to crop the image on select */}
        <Dialog open={openPicDialog} fullWidth>
          <DialogContent>
            <Grid container justify='flex-end'>
              <Typography
                variant='body2'
                className={classes.closeText}
                onClick={this.handlePicDialog}
              >
                Close <CloseIcon fontSize='large' />
              </Typography>
            </Grid>
            <Typography
              variant='h5'
              className={classes.saved}
              style={{ paddingBottom: '2rem' }}
            >
              Select an Image
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <input
                  type='file'
                  accept='image/*'
                  onChange={this.onSelectFile}
                />
                {imgTypeError && (
                  <Typography variant='caption' color='error'>
                    {imgTypeError}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                style={{ padding: '1rem', textAlign: 'center' }}
              >
                {src && (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                    circularCrop={true}
                  />
                )}
                <br />
                <br />
                {croppedImageUrl && (
                  <React.Fragment>
                    <hr />
                    <Typography
                      variant='body2'
                      style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                    >
                      Preview :
                    </Typography>
                    <img
                      alt='PreviewImage'
                      style={{ width: '40%', borderRadius: '50%' }}
                      src={croppedImageUrl}
                    />
                  </React.Fragment>
                )}
              </Grid>

              {croppedImageUrl && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.buttonGrid}
                >
                  <Button
                    variant='contained'
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    className={classes.noButton}
                    onClick={this.handlePicDialog}
                  >
                    Discard
                  </Button>
                  <StyledButton
                    style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
                    onClick={this.handleSaveProfilePic}
                  >
                    Save
                  </StyledButton>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          open={dataSavedDialog}
          // onClose={this.handleDataSavedDialog}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DataSavedDialog
            icons={true}
            title='Profile Picture has been saved'
            handleClose={this.handleDataSavedDialog}
          />
        </Dialog>

        {/* Backdrop component */}
        <BackDrop />

        {/* Reset Password */}
        <Dialog open={passwordChanged} fullWidth={true} maxWidth={'sm'}>
          <DataSavedDialog
            icons={true}
            title='Email has been sent'
            subtitle='Please check your inbox'
            handleClose={this.handleClosePwd}
          />
        </Dialog>
        <ResetPwdBackdrop className={classes.backdrop} open={resetPwdBackdrop}>
          <CircularProgress color='primary' thickness={5} />
          <Typography variant='body1'>Please Wait...</Typography>
        </ResetPwdBackdrop>
      </Grid>
    );
  }
}

MyProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  updateField: state.user.updateField,
  userDetails: state.user.userDetails,
  resetUserPassword: state.user.resetUserPassword,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    // updateProfileField,
    userProfileData,
    updateUserProfile,
    resetPassword,
    // userLogout,
  })(withRouter(MyProfile))
);
