import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import clsx from 'classnames';

import QETable from './QETable';
import {
  fetchQMSData,
  fetchQuickSummary,
} from '../../../../containers/actions/common/qualityEventActions';

import { createTheme } from '@material-ui/core/styles';
// import { orange } from "@material-ui/core/colors";

// const theme = createTheme({
//   palette: {
//     secondary: "orange",
//   },
// });
const useStyles = makeStyles((theme) => ({
  hgText: {
    fontWeight: 500,
    paddingRight: theme.spacing(1),
  },
  outlined: {
    padding: theme.spacing(1),
    width: 210,
  },
  outlinedForPage: {
    padding: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    fontSize: '1.05rem',
  },
  formPerPage: {
    minWidth: 60,
    fontSize: '0.875rem',
  },
  pdTp: {
    paddingTop: '1.5rem',
  },
  searchFld: {
    display: 'flex',
    alignItems: 'center',
    // [theme.breakpoints.down('xs')]: {
    //     flexDirection: 'column',
    // },
  },
  flxEd: {
    justifyContent: 'flex-end',
  },
  pagination: {
    color: 'red',
  },
  // palette: {
  //   secondary: orange,
  // },
}));

const QualityEvents = (props) => {
  const classes = useStyles();
  const [noRowsPerPage, setRowsPerPage] = useState(5);
  //const [currentPage, setCurrentPage] = useState(1);
  // const [action, setAction] = useState("Filter by");
  // const [filterBy, setFilterBy] = useState("Filter by");
  const {
    currentPage,
    qualityEventsArray,
    lastPageNumber,
    status,
    handleStatusChange,
    event,
    priority,
    viewByCMO,
    startDate,
    endDate,
    searchQuery,
    CMOMember,
    handlePageChange,
    setCurrentPage,
  } = props;
  const dispatch = useDispatch();

  //let totalPages = Math.ceil(newTotalRecord / noRowsPerPage);
  // for now we are harding coding the IDS after that we ned to change the API data
  // const cmoListObj = [
  //   { name: "all", id: "all" },
  //   { name: "Zen Pharma", id: 3 },
  //   { name: "smilax", id: 6 },
  // ];
  useEffect(() => {
    dispatch(
      //fetchEventTypes()
      fetchQMSData({
        ///"viewByCMO": CMOMember === 'Zen Pharma'?2:'all',
        viewByCMO: CMOMember !== 'all' ? CMOMember.organizationId : CMOMember,
        eventTypeID: event,
        priority: priority,
        eventsOccurredFromDate: startDate,
        eventsOccurredToDate: endDate,
        searchBy: searchQuery,
        viewByStatus: status,
        pageNumber: currentPage,
        pageSize: noRowsPerPage,
      })
    );
    dispatch(
      fetchQuickSummary({
        viewByCMO: CMOMember !== 'all' ? CMOMember.organizationId : CMOMember,
        eventTypeID: event,
        priority: priority,
        eventsOccurredFromDate: startDate,
        eventsOccurredToDate: endDate,
      })
    );
  }, [
    dispatch,
    event,
    priority,
    //lastPageNumber,
    //searchBy,
    viewByCMO,
    currentPage,
    status,
    endDate ? startDate : '',
    startDate ? startDate : '',
    noRowsPerPage,
    searchQuery,
    //orderBy,
    //orderByField,
    // newTotalRecord,
    CMOMember,
  ]);
  const handleSetRows = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1);
    //getCurrentPageNumber(1);
    //setErrors({});
  };

  let eventTypes = useSelector((state) =>
    state.pcmp.common.QMS.fetchEventTypes
      ? state.pcmp.common.QMS.fetchEventTypes
      : []
  );

  let statusArray = [
    { status: 'Record Incomplete', statusId: 1 },
    { status: 'New', statusId: 2 },
    { status: 'Under Review', statusId: 3 },
    { status: 'Under Investigation', statusId: 4 },
    { status: 'Closed', statusId: 5 },
  ];

  return (
    <React.Fragment>
      <Grid container alignItems='center' className={classes.pdTp}>
        <Grid item xs={8} sm={6} className={classes.searchFld}>
          <FormControl className={classes.formControl}>
            <Select
              //placeholder='Filter By'
              labelId='cmo-filter'
              id='cmo-filter-id'
              value={status}
              defaultValue={'all'}
              onChange={(data) => {
                handleStatusChange(data);
              }}
              //label='Filter by'
              variant='outlined'
              classes={{
                outlined: classes.outlined,
              }}
            >
              <MenuItem value='all'>Filter By</MenuItem>
              {/* {eventTypes.map((qualityEvent, i) => (
                <MenuItem key={i} value={qualityEvent.event_type_id}>
                    {qualityEvent.event_type}
                </MenuItem>
                )) } */}
              {statusArray.map((statusObj, i) => (
                <MenuItem key={i} value={statusObj.statusId}>
                  {statusObj.status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={4}
          sm={6}
          className={clsx(classes.searchFld, classes.flxEd)}
        >
          <Typography variant='body2' className={classes.hgText}>
            PER PAGE
          </Typography>
          <FormControl className={classes.formPerPage}>
            <Select
              labelId='cmo-filter'
              id='cmo-filter-id'
              value={noRowsPerPage}
              onChange={handleSetRows}
              variant='outlined'
              classes={{
                outlined: classes.outlinedForPage,
              }}
            >
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <QETable
        qualityEventsArray={qualityEventsArray}
        // perpage = {handleSetRows}
        // CMOMember={CMOMember}
        // searchParameter={searchBy}
        // status={'all'}
        // eventTypeID = {eventTypeID}
        // priority = {priority}
      />

      {currentPage && (
        <Grid
          container
          alignItems='center'
          justify='center'
          style={{ paddingTop: '1rem' }}
        >
          <Pagination
            page={currentPage}
            // className={classes.pagination}
            color='primary'
            name='currentPage'
            onChange={(event, page) => handlePageChange(event, page)}
            count={lastPageNumber}
            shape='rounded'
          />
        </Grid>
      )}
    </React.Fragment>
  );
};

export default QualityEvents;
