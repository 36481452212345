import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export default function (ComposedClass) {
  const ClosedRouteForUser = (props) => {
    const [loading, setLoading] = useState(true);
    const getHistory = useHistory();   
    useEffect(() => {
      if (localStorage.getItem('authToken')) {
        const { search } = props.location;
        const returnPage = search ? search.substring(search.indexOf('/pcmp')) : search;
        if (returnPage) {
          getHistory.push(`/switch-ecosystem?return_url=${returnPage}`);
        } else {
          getHistory.goBack.length > 0
          ? getHistory.goBack()
          : getHistory.push('/');
        }
        
      } else {
        setLoading(false);
      }
    }, [props.history, props.location, getHistory]);

    if (loading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
          }}
        >
          <CircularProgress style={{ color: 'primary' }} thickness={7} />
        </div>
      );
    } else {
      return <ComposedClass {...props} />;
    }
  };
  return ClosedRouteForUser;
}
