import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CachedIcon from '@material-ui/icons/Cached';
import clsx from 'clsx';

//files
import Filters from './Filter';
import Search from './Search';
import SupplierPOTable from '../supplierPoGrn';
import BackDropComponent from '../../common/models/BackDrop';
import {
  source,
  offsetAddGRNFetchTable,
} from '../../../containers/actions/cmo/shortFallsActions';
import UploadExport from './UploadExport';
import { useStyles } from './grnStyles';
import { grStatusSource } from '../../../containers/actions/common/reportsActions';

import { SupplierPOContext } from './context';
import featuresAndValidations from '../models/featuresAccess/FeaturesAndValidations';
const GRNIndex = (props) => {
  const classes = useStyles();
  const { collabType, organizationName } = localStorage;
  const [CMOMember, selectCMO] = useState(
    collabType === 'Pharma Co' ? 'all' : organizationName
  );
  const [status, selectStatus] = useState('all');
  const [searchQuery, searchQueryInput] = useState('');
  const [tempSearch, tempSearchQury] = useState('');
  const [limit, setLimit] = useState(5);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('lastUpdatedDate');
  const [offset, setOffset] = useState(0);
  const [hasBeenSearched, setHasBeenSearched] = useState(false);
  let loading = true;

  const getLimitPerPage = (data) => {
    setLimit(data);
  };
  const getOrderAndColumnFinal = (data, dataBy) => {
    setOrder(data);
    setOrderBy(dataBy);
    setOffset(0);
  };
  const dispatch = useDispatch();

  const fetchGRLineItems = useCallback(() => {
    // if (collabType === "Pharma Co") {
    dispatch(
      offsetAddGRNFetchTable({
        offset: offset,
        limit: limit,
        sort: {
          orderByField: order,
          orderBy: orderBy,
        },
        filter: {
          searchText: searchQuery,
          viewByValue: status,
          filterByValue: CMOMember,
        },
      })
    );
  }, [dispatch, CMOMember, limit, offset, order, orderBy, searchQuery, status]);

  useEffect(() => {
    fetchGRLineItems();
    return () => {
      if (source) {
        source.cancel('leaving page');
      }
      if (grStatusSource) {
        grStatusSource.cancel('leaving page');
      }
    };
  }, [fetchGRLineItems]);

  let supplierPOs = useSelector((state) => {
    if (
      state.pcmp.cmo.shortFalls.fetchGRNData.data &&
      state.pcmp.cmo.shortFalls.fetchGRNData.status === true &&
      state.pcmp.cmo.shortFalls.fetchGRNData.data.message !== undefined
    ) {
      loading = false;
      return [];
    }
    if (
      state.pcmp.cmo.shortFalls.fetchGRNData.data &&
      state.pcmp.cmo.shortFalls.fetchGRNData.status === true &&
      state.pcmp.cmo.shortFalls.fetchGRNData.paging
    ) {
      loading = false;
      return state.pcmp.cmo.shortFalls.fetchGRNData.data;
    }

    if (
      state.pcmp.cmo.shortFalls.fetchGRNData.data &&
      state.pcmp.cmo.shortFalls.fetchGRNData.status === true
    ) {
      loading = false;
      return state.pcmp.cmo.shortFalls.fetchGRNData.data;
    }
    if (
      state.pcmp.cmo.shortFalls.fetchGRNData.data &&
      state.pcmp.cmo.shortFalls.fetchGRNData.status === true &&
      state.pcmp.cmo.shortFalls.fetchGRNData.data.length === 0
    ) {
      loading = false;
      return state.pcmp.cmo.shortFalls.fetchGRNData.data;
    }
    if (
      state.pcmp.cmo.shortFalls.fetchGRNData.data &&
      state.pcmp.cmo.shortFalls.fetchGRNData.status === false
    ) {
      loading = true;
    }

    return [];
  });

  let newTotalRecord = useSelector((state) => {
    if (
      state.pcmp.cmo.shortFalls.fetchGRNData.data &&
      state.pcmp.cmo.shortFalls.fetchGRNData.status === true &&
      state.pcmp.cmo.shortFalls.fetchGRNData.paging
    ) {
      return state.pcmp.cmo.shortFalls.fetchGRNData.paging.totalRecords;
    }
    return 0;
  });

  const handleSelectCMO = (event) => {
    selectCMO(event.target.value);
    setOffset(0);
  };

  const handleSelectStatus = (event) => {
    selectStatus(event.target.value);
    setOffset(0);
  };

  const handleSearchQuery = (event) => {
    let { value } = event.target;
    if (value === '' || value === null) {
      tempSearchQury(value);
      searchQueryInput('');
    } else {
      tempSearchQury(value);
    }
  };

  const handleClearSearch = (event) => {
    tempSearchQury('');
    searchQueryInput('');
    if (hasBeenSearched) {
      setOffset(0);
    }
    setHasBeenSearched(false);
  };

  const submitSearch = (event) => {
    event.preventDefault();
    searchQueryInput(tempSearch);
    setHasBeenSearched(true);
    if (tempSearch !== '') {
      setOffset(0);
    }
  };

  //debounce
  const debounce = (callback, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    };
  };

  const debouncedLog = debounce((data) => {
    setOffset((data - 1) * limit);
  }, 600);

  const getCurrentPageNumber = (data) => {
    // setOffset((data - 1) * limit);
    debouncedLog(data);
  };

  return (
    <SupplierPOContext.Provider
      value={{
        fetchGRLineItems,
      }}
    >
      <React.Fragment>
        <Grid container alignItems='center'>
          <Grid item xs={12} md={6}>
            <Filters
              cmoFilter={collabType === 'Pharma Co' ? true : false}
              CMOMember={CMOMember}
              status={status}
              cmoMembersList={[]}
              handleSelectCMO={handleSelectCMO}
              handleSelectStatus={handleSelectStatus}
            />
          </Grid>
          <UploadExport isUploadRMPMGRN={true} refreshFunc={fetchGRLineItems} />
        </Grid>
        <Divider className={classes.mgTp} />
        <Grid container alignItems='center' className={classes.pdTp}>
          <Grid item xs={12} sm={10} md={6}>
            <Search
              searchQuery={tempSearch}
              handleSearchQuery={handleSearchQuery}
              handleClearSearch={handleClearSearch}
              submitSearch={submitSearch}
              placeholder='Search using GRN/PO/STO/Material Name/Code'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              className={clsx(
                classes.dpFlx,
                classes.flxEndSmFlxStrt,
                classes.pdTponLr
              )}
            >
              <Typography variant='caption' className={clsx(classes.padR20)}>
                {`Last synced @ ${moment(new Date()).format(
                  'HH:mm:ss; DD MMMM, YYYY'
                )}`}
              </Typography>

              <Typography
                variant='caption'
                color='primary'
                onClick={fetchGRLineItems}
                className={clsx(
                  classes.dpFlx,
                  classes.algnCntr,
                  classes.pointer,
                  classes.onHvrUndrline
                )}
              >
                Refresh
                <CachedIcon color='primary' style={{ fontSize: 15 }} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <SupplierPOTable
          supplierPOs={supplierPOs}
          status={status}
          searchQuery={searchQuery}
          loading={loading}
          getLimit={getLimitPerPage}
          getOrderAndColumnFinal={getOrderAndColumnFinal}
          newTotalRecord={newTotalRecord}
          getCurrentPageNumber={getCurrentPageNumber}
          offset={offset}
          CMOMember={CMOMember}
          orderByField={order}
          orderBy={orderBy}
          refetchData={fetchGRLineItems}
        />

        {/* backDrop */}
        <BackDropComponent />
      </React.Fragment>
    </SupplierPOContext.Provider>
  );
};

export default featuresAndValidations(GRNIndex);
