import { TableRow, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  presentDate,
  presentFormatedData,
} from '../../../functionalComponents/dataFormatFunctions';
import CustomDatePickerMui from '../../../models/inputs/DateInput';
import CustomTableCell from '../../../models/table/CustomTableCell';
import AvailabilityButton from './AvailabilityButton';
import { useStyles } from '../styles';
import StatusDisplay from './StatusDisplay';
import moment from 'moment';
// import moment from 'moment';

const SingleRow = (props) => {
  const { data, handleExpand, handleDataChange, expandTable, availability } =
    props;
  const [row, setRow] = useState(data);
  const classes = useStyles();

  const handleToggle = (event, value) => {
    if (value !== null) {
      handleDataChange({
        ...row,
        materialStatusID: value,
        // lastUpdatedDate: presentDate(moment()),
      });
    }
    if (value === 0) {
      handleExpand();
    }
  };

  const handleDateChange = (date) => {
    if (date !== null) {
      handleDataChange({
        ...row,
        // lastUpdatedDate: presentDate(moment()),
        expectedDateOfAvailability: moment(date),
        dateError: false,
      });
    }
  };

  const handleBatchShortageChange = (event) => {
    if (event.target.value < 0) {
      handleDataChange({
        ...row,
        noOfBatchesShortage: event.target.value,
        // lastUpdatedDate: presentDate(moment()),
        shortageError: true,
      });
    } else {
      handleDataChange({
        ...row,
        noOfBatchesShortage: event.target.value,
        // lastUpdatedDate: presentDate(moment()),
        shortageError: false,
      });
    }
  };

  // useEffect(() => {
  //   handleDataChange(row);
  // }, [handleDataChange, row]);

  useEffect(() => {
    setRow(data);
  }, [data]);

  return (
    <React.Fragment>
      <TableRow className={classes.row}>
        <CustomTableCell align='center'>
          {presentFormatedData(row.materialName)}
        </CustomTableCell>

        <CustomTableCell align='center'>
          {presentFormatedData(row.materialType)}
        </CustomTableCell>

        <CustomTableCell align='center'>
          {localStorage.collabType === 'CMO' &&
          (availability === 'no' || availability === null) ? (
            <AvailabilityButton
              value={row.materialStatusID}
              handleToggle={handleToggle}
            />
          ) : (
            <StatusDisplay statusId={row.materialStatusID} />
          )}
        </CustomTableCell>
        {expandTable && (
          <>
            {row.materialStatusID === 0 &&
            (availability === 'no' || availability === null) ? (
              <React.Fragment>
                <CustomTableCell align='center'>
                  {localStorage.collabType === 'CMO' ? (
                    <CustomDatePickerMui
                      name='expectedDate'
                      value={
                        row.expectedDateOfAvailability === ''
                          ? null
                          : row.expectedDateOfAvailability
                      }
                      onChange={(date) => handleDateChange(date)}
                      style={{ maxWidth: 200 }}
                      error={row?.dateError}
                      helperText={row.dateError && 'Please enter the date.'}
                      minDate={new Date()}
                      FormHelperTextProps={{
                        style: {
                          margin: 0,
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        },
                      }}
                    />
                  ) : (
                    <React.Fragment>
                      {presentDate(row.expectedDateOfAvailability, 'NA')}
                    </React.Fragment>
                  )}
                </CustomTableCell>
                <CustomTableCell align='center'>
                  {localStorage.collabType === 'CMO' &&
                  (availability === 'no' || availability === null) ? (
                    <TextField
                      // error: { error }
                      className={classes.helperText}
                      variant='outlined'
                      type='number'
                      size='small'
                      name='noOfBatches'
                      value={
                        row.noOfBatchesShortage === null
                          ? ''
                          : row.noOfBatchesShortage
                      }
                      onChange={handleBatchShortageChange}
                      error={row?.shortageError}
                      helperText={
                        !row.shortageError
                          ? null
                          : row.noOfBatchesShortage < 0
                          ? 'Value cannot be less than zero.'
                          : null
                      }
                    />
                  ) : (
                    <React.Fragment>
                      {presentFormatedData(row.noOfBatchesShortage)}
                    </React.Fragment>
                  )}
                </CustomTableCell>
                {/* {localStorage.collabType !== 'CMO' && ( */}
                {/* <CustomTableCell align='center'>
                  {presentDate(row.lastUpdatedDate, '-NA-')}
                </CustomTableCell> */}
                {/* )} */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <>
                  <CustomTableCell align='center'>{'-NA-'}</CustomTableCell>
                  <CustomTableCell align='center'>{'-NA-'}</CustomTableCell>
                  {/* {localStorage.collabType !== 'CMO' && ( */}
                  {/* <CustomTableCell align='center'>{'-NA-'}</CustomTableCell> */}
                  {/* )} */}
                </>
              </React.Fragment>
            )}
          </>
        )}
        <CustomTableCell align='center'>
          {presentDate(row.lastUpdatedDate, '-NA-')}
        </CustomTableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default SingleRow;
