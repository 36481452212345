import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  hgText: {
    fontWeight: 500,
  },
  centerText: {
    fontWeight: 500,
    paddingBottom: 10,
    textAlign: 'center',
  },
  root: {
    '& .MuiFormControl-marginNormal': {
      marginTop: 0,
      marginBottom: 0,
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiInput-root']": {
      padding: '6px',
    },
  },
}));
