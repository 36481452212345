import React from 'react';

import RecordIncomplete from './status/RecordIncomplete';
import New from './status/New';
import UnderReview from './status/UnderReview';
import UnderInvestigation from './status/UnderInvestigation';
import Closed from './status/Closed';
import Reopened from './status/Reopened';
import ActionPlanning from './status/ActionPlanning';
import AwaitingApproval from './status/AwaitingApproval';
import Implementation from './status/Implementation';

const SASLStatus = (statusId) => {
  if (statusId === 1) {
    return <RecordIncomplete />;
  } else if (statusId === 2) {
    return <New />;
  } else if (statusId === 3) {
    return <UnderReview />;
  } else if (statusId === 4) {
    return <UnderInvestigation />;
  } else if (statusId === 5) {
    return <Closed />;
  } else if (statusId === 6) {
    return <Reopened />;
  } else if (statusId === 7) {
    return <ActionPlanning />;
  } else if (statusId === 8) {
    return <Implementation />;
  } else if (statusId === 9) {
    return <AwaitingApproval />;
  }
};

export default SASLStatus;
