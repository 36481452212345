/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Stupid Neetu
 * -----
 * File Description: CMO ALL Line Items Screen
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';

import BatchItemsTable from '../../common/tables/BatchItemsTable';
import ViewEditBatch from './viewEditBatch';

import { viewAllBatchItems, viewBatchItemSource } from '../../../containers/actions/common/dashboardAction';

class index extends Component {
  state = {
    allBatchItems: false,
    batchItem: {},
    openBatchDialog: false,
  };

  componentDidMount() {
    this.getAllBatchItems();
  }
  componentWillUnmount() {
    if (viewBatchItemSource) {
      viewBatchItemSource.cancel('leaving page')
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.allBatchItems !== this.props.allBatchItems) {
      this.setState({ allBatchItems: nextProps.allBatchItems });
    }
  }

  getAllBatchItems = () => {
    let data = {
      ...this.props.match.params,
      collabType: 'CMO',
    };
    this.setState({ allBatchItems: false });
    this.props.viewAllBatchItems(data);
  };

  // Batch dialog methods
  openBatchDialog = (ele) => {
    this.setState({ openBatchDialog: true, batchItem: ele });
  };

  toggleViewBatchScreen = () => {
    this.setState({ openBatchDialog: false });
  };

  render() {
    return (
      <Grid>
        <div
          style={{
            // margin: "-1rem -1.3rem 0rem -1.8rem",
            margin: '-16px -16px 0 -16px',
            backgroundColor: '#F5F5F5',
            flexDirection: 'row',
            padding: '1.2rem 0.5rem',
          }}
        >
          <Typography variant='h6' style={{ marginLeft: '3rem' }}>
            Total Batches -{' '}
            {!this.state.allBatchItems ? 0 : this.state.allBatchItems.length}
          </Typography>
        </div>
        <BatchItemsTable
          batchItems={this.state.allBatchItems}
          openBatchDialog={this.openBatchDialog}
          refreshList={this.getAllBatchItems}
        />
        <Dialog fullScreen open={this.state.openBatchDialog}>
          <ViewEditBatch
            toggleViewBatchScreen={this.toggleViewBatchScreen}
            params={this.props.match.params}
            batchItemDetails={this.state.batchItem}
            collabType='CMO'
            callBackAPIFunction={this.getAllBatchItems}
          />
        </Dialog>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  allBatchItems: state.pcmp.common.dashboard.allBatchItems,
});

export default connect(mapStateToProps, {
  viewAllBatchItems,
})(withRouter(index));
