import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import ShowFiles from '../updateGrRecord/ShowFiles';
import {
  presentDate,
  presentFormatedData,
} from '../functionalComponents/dataFormatFunctions';
import DropFilesAttach from './files/DropFilesAttach';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    fontSize: '1.5rem',
    textAlign: 'left',
    paddingBottom: '0.3rem',
  },
  font: {
    fontWeight: 500,
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
  },
  fontPadding: {
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
    overflow: 'auto',
  },
  button: {
    backgroundColor: 'transparent',
    textAlign: 'end',
    paddingTop: '1rem',
  },
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '1.6rem',
    backgroundColor: 'grey',
  },
  grid: {
    paddingBottom: '2rem',
    paddingTop: '1rem',
    paddingRight: '1.5rem',
  },
  fileIcon: {
    cursor: 'pointer',
    textAlign: 'end',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  closeText: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
  },
  document: {
    display: 'flex',
    alignItems: 'center',
  },
  files: {
    boder: '1px solid #707070',
    backgroundColor: '#F7F7F7',
    textAlign: 'center',
    padding: '2rem',
  },
}));

export default function InvoiceInfo(props) {
  const classes = useStyles();
  const { handleOpenDialog, invoiceInformation, isAddRecord } = props;
  const { invoiceNumber, invoiceDate } = props.invoiceInformation;
  const [invoiceFiles, setInvoiceFiles] = React.useState(
    invoiceInformation.invoiceFiles
  );

  const invoiceValidations = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.validations
        .invoiceInfo;
    }
    return {};
  });

  const fileHandling = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const newArr = {
          grFileData: reader.result,
          grFileName: file.name,
          grFileType: 'buffer',
          schrockenGRFileId: '',
          grFileHash: '',
          markForDelete: false,
        };

        props.handlingFiles(newArr, 'InvoiceFiles');
      };
    });
  };
  const handlingDeleteFile = (i) => {
    // for rec fromBE=> mfd as 1, for local filter out
    //check for filetype in file
    //if 'buffer' then follow filter logic
    // if it is not buffer edit that file data sayn mfd 1
    if (invoiceFiles[i].grFileType === 'buffer') {
      let newdel = invoiceFiles.filter(
        (file) => file.grFileName !== invoiceFiles[i].grFileName
      );
      setInvoiceFiles(newdel);
    } else {
      // invoiceFiles[i].markForDelete=1;
      let markDelFile = invoiceFiles.map((file) =>
        file.grFileHash === invoiceFiles[i].grFileHash
          ? { ...file, markForDelete: true }
          : file
      );
      setInvoiceFiles(markDelFile);
    }
  };

  return (
    <React.Fragment>
      <Grid container className={classes.child}>
        <Grid item xs={8} sm={6} md={6} className={classes.grid}>
          <Typography variant='h4' className={classes.title}>
            Invoice Information
          </Typography>
          <span className={classes.borders}></span>
        </Grid>

        {props.isEditExist === true ? (
          <Grid item xs={4} sm={6} md={6} className={classes.button}>
            <Button
              variant='contained'
              startIcon={<CreateIcon fontSize='small' />}
              style={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                border: '1px solid #000000de',
              }}
              size='small'
              onClick={() => handleOpenDialog('openInvoice')}
            >
              Edit
            </Button>
          </Grid>
        ) : (
          <Grid item xs={4} sm={6} md={6}></Grid>
        )}

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Invoice Number&nbsp;
            {!invoiceValidations.invoiceNumber?.required && (
              <span>(optional)</span>
            )}
          </Typography>

          <Typography
            variant='body2'
            className={classes.fontPadding}
            color={
              invoiceValidations.invoiceNumber?.required &&
              invoiceNumber?.trim() === ''
                ? 'error'
                : 'initial'
            }
          >
            {presentFormatedData(invoiceNumber, '-Not Provided-')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Invoice Date{' '}
            {!invoiceValidations.invoiceDate?.required && (
              <span>(optional)</span>
            )}
          </Typography>

          <Typography
            variant='body2'
            className={classes.fontPadding}
            color={
              invoiceValidations.invoiceDate?.required && !invoiceDate
                ? 'error'
                : 'initial'
            }
          >
            {presentDate(invoiceDate, '-Not Provided-')}
          </Typography>
        </Grid>
        {localStorage.collabType === 'CMO' && props.status === 3 && (
          <Grid container justify='center' style={{ paddingBottom: '2rem' }}>
            <Grid item xs={12} sm={12} md={12}>
              <DropFilesAttach
                title='Upload Invoice (optional)'
                handleUploadFiles={fileHandling}
              />
            </Grid>
          </Grid>
        )}

        {!isAddRecord && (
          <Grid
            container
            className={classes.padding}
            style={{ paddingBottom: '1rem' }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant='body2'
                className={classes.font}
                style={{ paddingBottom: '0.5rem' }}
              >
                Uploaded Document List
              </Typography>

              <ShowFiles
                files={invoiceInformation.invoiceFiles}
                handlingDeleteFile={props.parentHandlingDeleteFile}
                name='invoiceFiles'
                isUploadFile={
                  props.status === 3 &&
                  localStorage.collabType === 'CMO' &&
                  true
                }
              />
            </Grid>
          </Grid>
        )}

        {isAddRecord && (
          <Grid container className={classes.padding}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant='body2' className={classes.font}>
                Uploaded Document List
              </Typography>
              {invoiceFiles === undefined || invoiceFiles.length === 0 ? (
                <Typography variant='body2' className={classes.document}>
                  -No Documents Uploaded-
                </Typography>
              ) : (
                <Typography variant='body2'>
                  {invoiceFiles.length !== 0
                    ? invoiceFiles.map((file, i) => {
                        return (
                          <span
                            key={i}
                            className={classes.closeText}
                            style={{ paddingBottom: '0.5rem' }}
                          >
                            {file.grFileName}{' '}
                            {localStorage.collabType === 'CMO' && (
                              <CloseIcon
                                fontSize='small'
                                color='error'
                                style={{ paddingLeft: '0.5rem' }}
                                onClick={() => handlingDeleteFile(i)}
                              />
                            )}
                            <br />
                          </span>
                        );
                      })
                    : ''}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
