import React, { useState } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../../../../../common/models/Button';
import Dialog from '@material-ui/core/Dialog';
//files
import { useStyles } from '../updateBatchStyles';
import Header from '../../../../../common/sasl/models/Header';
import UpdateConsumptionInfo from './UpdateConsumptionInfo';

const ConsumptionInformation = (props) => {
  const classes = useStyles();

  const [consumptionUpdate, setConsumptionUpdate] = useState(false);

  const handleUpdate = () => {
    setConsumptionUpdate(true);
  };

  const handleCloseUpdate = () => {
    setConsumptionUpdate(false);

    if (props.consumptionScreen) {
      props.handleConsumptionDialog();
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Consumption Information Status
          </Typography>
          {props.batchDetails.bomConsumptionAddedIndicator === true ? (
            <Typography
              variant='body2'
              className={classes.fontPadding}
              style={{
                paddingTop: '0.25rem',
                fontSize: '1rem',
                color: 'green',
              }}
            >
              All details entered
            </Typography>
          ) : (
            <Typography
              variant='body2'
              className={classes.fontPadding}
              style={{
                paddingTop: '0.25rem',
                fontSize: '1rem',
                color: 'red',
              }}
            >
              -Not Updated yet -
            </Typography>
          )}
        </Grid>

        {localStorage.collabType !== 'CMO' ? (
          <Grid item xs={12} sm={12} md={6} className={classes.grid}>
            <StyledButton
              startIcon={
                localStorage.collabType === 'CMO' && (
                  <CreateIcon
                    style={{
                      fontSize: 15,
                    }}
                  />
                )
              }
              className={classes.export}
              onClick={handleUpdate}
            >
              View consumption details
            </StyledButton>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={6} className={classes.grid}>
            <StyledButton
              startIcon={
                localStorage.collabType === 'CMO' && (
                  <CreateIcon
                    style={{
                      fontSize: 15,
                    }}
                  />
                )
              }
              className={classes.export}
              onClick={handleUpdate}
            >
              {props.batchDetails.bomConsumptionAddedIndicator === false
                ? 'Update consumption details'
                : 'View consumption details'}
            </StyledButton>
          </Grid>
        )}
      </Grid>

      <Dialog open={props.consumptionScreen || consumptionUpdate} fullScreen>
        <Header title='Consumption Information' handleClose={handleCloseUpdate}>
          <UpdateConsumptionInfo
            {...props}
            handleClose={handleCloseUpdate}
            handleFetchCall={props.handleFetchCall}
          />
        </Header>
      </Dialog>
    </React.Fragment>
  );
};

export default ConsumptionInformation;
