import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CheckBoxButton from '../CheckboxAndButton';
import { Box } from '@material-ui/core';
import Accordion from '../RejectedRecordAccordion';
import Button from '@material-ui/core/Button';
import StyledButton from '../../../../common/models/Button';

const useStyles = makeStyles((theme) => ({
  itemGrid: {
    border: '2px solid #707070',
    padding: '1rem',
    // marginRight: '-3.75rem',
  },
  subHeading: {
    fontWeight: 500,
    textAlign: 'center',
  },
  subHeadingGrid: {
    padding: '2rem',
  },
  number: {
    fontSize: '2.4rem',
    textAlign: 'center',
    fontWeight: 500,
  },
  checkbox: {
    paddingTop: '2rem',
  },
  dropzone: {
    padding: '1.25rem',
  },
  buttonApprovalGrid: {
    paddingTop: '2rem',
    textAlign: 'center',
  },
  buttonReuploadGrid: {
    paddingTop: '2rem',
    textAlign: 'center',
  },
  reUploadButton: {
    textTransform: 'none',
    color: '#313645',
    textDecoration: 'underline',
    paddingLeft: '0rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
const ValidateRecord = (props) => {
  const classes = useStyles();
  const {
    verifyConsent,
    errors,
    handleVerifyConsent,
    validatedRecordData,
    disableSubmitButton,
    disableReuploadButton,
    reUpload,
    handleSubmitRecords,
  } = props;
  return (
    <React.Fragment>
      <Grid container justify='center'>
        {props.errors.errorDescription && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.dropzone}
          >
            <Typography
              variant='h6'
              style={{
                border: '1px solid #FF0000',
                backgroundColor: '#FFF0F0',
                opacity: '1',
                textAlign: 'center',
                padding: '0.8rem',
                fontSize: '0.875rem',
              }}
            >
              {props.errors.errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.subHeadingGrid}
        >
          <Typography variant='h5' className={classes.subHeading}>
            Validation Summary
          </Typography>
        </Grid>
        <Grid container justify='space-between'>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Box className={classes.itemGrid}>
              <Typography variant='body2' className={classes.subHeading}>
                Total Records
              </Typography>
              <Typography className={classes.number}>
                {validatedRecordData.validRecordsCount +
                  validatedRecordData.invalidRecordCount}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Box className={classes.itemGrid}>
              <Typography variant='body2' className={classes.subHeading}>
                Valid Records
              </Typography>
              <Typography className={classes.number}>
                {validatedRecordData.validRecordsCount}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Box className={classes.itemGrid}>
              <Typography variant='body2' className={classes.subHeading}>
                Rejected Records
              </Typography>
              <Typography className={classes.number}>
                {validatedRecordData.invalidRecordCount}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.checkbox} 
        >
          <Accordion validatedRecordData={validatedRecordData} />
          <CheckBoxButton
            status={0}
            errors={errors}
            verifyConsent={verifyConsent}
            handleVerifyConsent={handleVerifyConsent}
            noButtonsRequired={true}
          />
        </Grid>
        <Grid
          container
          justify='center'
          alignItems='center'
          style={{ marginBottom: '2rem' }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            className={classes.buttonReuploadGrid}
          >
            <Button
              disableRipple
              disableFocusRipple
              disableElevation
              disabled={disableReuploadButton}
              className={classes.reUploadButton}
              onClick={reUpload}
            >
              &lt;Re-upload File
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            className={classes.buttonApprovalGrid}
          >
            <StyledButton
              disabled={disableSubmitButton}
              size='small'
              onClick={handleSubmitRecords}
              style={{
                paddingLeft: '4rem',
                paddingRight: '4rem',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
              }}
            >
              Submit Records
            </StyledButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            className={classes.buttonApprovalGrid}
          ></Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default ValidateRecord;
