import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import TopBar from './TopBar';
import CardOne from '../../common/dashboard/BatchSummery';
import CardTwo from '../../common/dashboard/LineItemSummery';

import CardThree from '../../common/dashboard/Summery';
import BatchTable from '../../common/dashboard/BatchTable';
import LineTable from '../../common/dashboard/LineTable';

import {
  getBatchSummery,
  lineSummery,
  summery,
  viewApplicationSettings,
  batchSummarySource,
  lineSummarySource,
  getBatchSummarySource,
  summarySource
} from '../../../containers/actions/common/dashboardAction';

import AddNewPurchaseOrder from '../purchaseOrders/createPurchaseOrder/AddNewPurchaseOrder';

const date = new Date();

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='down' {...props} ref={ref} />
));

class index extends Component {
  state = {
    selectedDate: date,
    currentYear: date.getFullYear(),
    currentMonthNumber: date.getMonth() + 1,
    CMOMember: 'all',
    tableType: 'line',
    cardIndex: 2,
    rowNumber: 0,
    tableTitle: 'Overview',
    tableSubtitle: 'Dispatched',
    addPoDialogOpen: false,
    summaryData: false,
    lineItemSummaryData: false,
    batchSummeryData: false,
  };

  componentDidMount() {
    this.getCardsDetails();
    // let data = {
    //   ...this.props.match.params,
    //   collabType: 'Pharma Co',
    // };
    // this.props.viewApplicationSettings(data);
  }

  componentWillUnmount() {
    if (batchSummarySource) {
      batchSummarySource.cancel('leaving page')
    }
    if (lineSummarySource) {
      lineSummarySource.cancel('leaving page')
    }
    if (getBatchSummarySource) {
      getBatchSummarySource.cancel('leaving page')
    }
    if (summarySource) {
      summarySource.cancel('leaving page')
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.summaryData !== this.props.summaryData) {
      this.setState({ summaryData: nextProps.summaryData });
    }
    if (nextProps.lineItemSummaryData !== this.props.lineItemSummaryData) {
      this.setState({ lineItemSummaryData: nextProps.lineItemSummaryData });
    }
    if (nextProps.batchSummeryData !== this.props.batchSummeryData) {
      this.setState({ batchSummeryData: nextProps.batchSummeryData });
    }
  }

  componentDidUpdate(nextProps, prevState) {
    if (
      this.state.currentMonthNumber !== prevState.currentMonthNumber ||
      this.state.currentYear !== prevState.currentYear
    ) {
      this.getCardsDetails();
      this.setState({
        summaryData: false,
        lineItemSummaryData: false,
        batchSummeryData: false,
      });
    }
    if (this.state.CMOMember !== prevState.CMOMember) {
      this.getCardsDetails();
      this.setState({
        summaryData: false,
        lineItemSummaryData: false,
        batchSummeryData: false,
      });
    }
  }

  getCardsDetails = () => {
    let data = {
      ...this.props.match.params,
      collabType: 'Pharma Co',
      currentYear: this.state.currentYear,
      currentMonthNumber: this.state.currentMonthNumber,
      CMOMember: this.state.CMOMember,
    };
    this.props.getBatchSummery(data);
    this.props.lineSummery(data);
    this.props.summery(data);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleDateChange = (date) => {
    this.setState({
      selectedDate: new Date(date),
      currentYear: new Date(date).getFullYear(),
      currentMonthNumber: new Date(date).getMonth() + 1,
    });
  };

  renderTableData = (
    tableType,
    cardIndex,
    rowNumber,
    tableTitle,
    tableSubtitle
  ) => {
    this.setState({
      tableType,
      cardIndex,
      rowNumber,
      tableTitle,
      tableSubtitle,
    });
  };

  openDialog = () => {
    this.setState({ addPoDialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ addPoDialogOpen: false });
  };

  render() {
    const {
      selectedDate,
      tableType,
      rowNumber,
      cardIndex,
      currentMonthNumber,
      currentYear,
      addPoDialogOpen,
      CMOMember,
      tableTitle,
      tableSubtitle,
      summaryData,
      lineItemSummaryData,
      batchSummeryData,
    } = this.state;

    const month = selectedDate.toLocaleString('default', { month: 'long' });
    return (
      <Grid>
        <TopBar
          selectedDate={selectedDate}
          handleDateChange={this.handleDateChange}
          openDialog={this.openDialog}
          CMOMember={CMOMember}
          handleChange={this.handleChange}
          {...this.props}
        />
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
            <CardThree
              renderTableData={this.renderTableData}
              month={month}
              summaryData={summaryData}
            />
            <CardTwo
              renderTableData={this.renderTableData}
              lineItemSummaryData={lineItemSummaryData}
            />
            <CardOne
              renderTableData={this.renderTableData}
              batchSummeryData={batchSummeryData}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {tableType === 'batch' ? (
              <BatchTable
                rowNumber={rowNumber}
                cardIndex={cardIndex}
                currentMonthNumber={currentMonthNumber}
                currentYear={currentYear}
                collabType='Pharma Co'
                getCardsDetails={this.getCardsDetails}
                CMOMember={CMOMember}
                tableSubtitle={tableSubtitle}
                {...this.props}
              />
            ) : (
              <LineTable
                rowNumber={rowNumber}
                cardIndex={cardIndex}
                currentMonthNumber={currentMonthNumber}
                currentYear={currentYear}
                collabType='Pharma Co'
                CMOMember={CMOMember}
                tableTitle={tableTitle}
                tableSubtitle={tableSubtitle}
                {...this.props}
              />
            )}
          </Grid>
        </Grid>
        <Dialog
          fullScreen
          open={addPoDialogOpen}
          TransitionComponent={Transition}
        >
          <AddNewPurchaseOrder
            closeDialog={this.closeDialog}
            callBackAPIFunction={this.getCardsDetails}
          />
        </Dialog>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  summaryData: state.pcmp.common.dashboard.summary,
  lineItemSummaryData: state.pcmp.common.dashboard.lineItemSummary,
  batchSummeryData: state.pcmp.common.dashboard.batchSummery,
});
export default connect(mapStateToProps, {
  getBatchSummery,
  lineSummery,
  summery,
  viewApplicationSettings,
})(withRouter(index));
