import axios from 'axios';
import { appUrl, smlUrl, BaseURL } from '../../../../../utils/config';
//import { BaseURL } from '../../utils/config';

import {
  //CMO_MEMBERS_LIST,
  ALL_CMO_MEMBERS,
  FETCH_QUALITY_EVENTS,
  DESTROY_TABLE_LOAD,
  FETCH_EVENT_TYPES,
  FETCH_QUICK_SUMMARY,
  ADD_QUALITY_EVENT,
  FETCH_IMPACT_DETAILS,
  FETCH_FREQUENCY_DETAILS,
  FETCH_TEAM_MEMBERS,
  FETCH_FACILITY_LIST,
  FETCH_LOCATION_LIST,
  FETCH_PRODUCT_LIST,
  FETCH_BATCH_LIST,
  FETCH_REPORTEDBY_LIST,
  FETCH_SUPPLIER_LIST,
  FETCH_EVENT_DETAILS,
  UPDATE_QUALITY_EVENT,
  SAVE_QUALITY_EVENT_PARTIALLY,
  FETCH_EVENT_TYPES_DATA,
} from '../types';

export let cmoMembersSource;

export const cmoMembersList = (data) => (dispatch) => {
  const CancelToken = axios.CancelToken;
  cmoMembersSource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/fetchCMOMembers`,
      data,
      { cancelToken: cmoMembersSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ALL_CMO_MEMBERS,
          payload: res.data.CMOMembersWithId,
        });
      }
    })
    .catch((err) => console.log(err));
};
console.log(cmoMembersList, '===================================');

// export const fetchALLCMOMembersList = (data) => (dispatch) => {
//   axios
//     .post(`${BaseURL}/fetchAllCMOMembers`, data)
//     .then((res) => {
//       if (res.data.status) {
//         dispatch({
//           type: ALL_CMO_MEMBERS,
//           payload: res.data,
//         });
//       }
//     })
//     .catch((err) => console.log(err));
// };

export let QEMSource;
export const fetchQMSData = (data) => (dispatch) => {
  if (QEMSource !== undefined) {
    QEMSource.cancel('Request cancelled');
  }
  const CancelToken = axios.CancelToken;
  QEMSource = CancelToken.source();
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/executeFetchEventList`,
      data,
      {
        cancelToken: QEMSource.token,
      }
    )
    .then((res) => {
      dispatch({
        type: DESTROY_TABLE_LOAD,
      });
      if (res.data.status) {
        dispatch({
          type: FETCH_QUALITY_EVENTS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      // dispatch({
      //   type: DESTROY_TABLE_LOAD,
      // });
    });
};

export const fetchEventTypes = (data) => (dispatch) => {
  axios
    .get(`${smlUrl}/user/QEMEventTypes`)

    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_EVENT_TYPES,

          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const fetchEventTypesData = (data) => (dispatch) => {
  axios
    .get(`${smlUrl}/user/getQualityEventTypes`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_EVENT_TYPES_DATA,

          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};
export const fetchImpactDetails = (data) => (dispatch) => {
  // console.log('impact Details api');
  // console.log({ data });

  axios
    .get(`${smlUrl}/user/getImpactList`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_IMPACT_DETAILS,
          payload: res.data.impactDetails,
        });
      }
    })
    .catch((err) => console.log(err));
};

// get frequency

export const fetchFrequencyDetails = (data) => (dispatch) => {
  // console.log('frequency details api')
  // console.log({data})

  axios
    .get(`${smlUrl}/user/getFrequencyList`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_FREQUENCY_DETAILS,
          payload: res.data.frequencyDetails,
        });
      }
    })
    .catch((err) => console.log(err));
};

//get list of names for reported By
export const fetchReportedByList = (data) => (dispatch) => {
  axios
    .get(`${smlUrl}/user/getUsersbyOrgId`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_REPORTEDBY_LIST,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

//get facility list
export const fetchFacilityDetails = (data) => (dispatch) => {
  axios
    .post(`${smlUrl}/user/getFacility`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_FACILITY_LIST,
          payload: res.data.facilityDetails,
        });
      }
    })
    .catch((err) => console.log(err));
};

//get location list
export const fetchLocationDetails = (data) => (dispatch) => {
  axios
    .post(`${smlUrl}/user/getLocation`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_LOCATION_LIST,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

//get list of names for supplier
export const fetchSupplierDetails = (data) => (dispatch) => {
  if (data) {
    axios
      .post(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchSupplierDetails`,
        data
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: FETCH_SUPPLIER_LIST,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  } else {
    dispatch({
      type: FETCH_SUPPLIER_LIST,
      payload: [],
    });
  }
};

//product code and description in fetch product list API
export const fetchProductDetails = (data) => (dispatch) => {
  if (data) {
    axios
      .post(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchProductCodesList`,
        data
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: FETCH_PRODUCT_LIST,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  } else {
    console.log('in else condition');
    dispatch({
      type: FETCH_PRODUCT_LIST,
      payload: [],
    });
  }
};

export const fetchBatchDetails = (data) => (dispatch) => {
  if (data) {
    axios
      .post(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchBatchesList`,
        data
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: FETCH_BATCH_LIST,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  } else {
    console.log('in else condition');
    dispatch({
      type: FETCH_BATCH_LIST,
      payload: [],
    });
  }
};

//get team members and team leads

export let TeamMembersSource;
export const fetchTeamMembers = (data) => (dispatch) => {
  // console.log('Team members');
  // console.log({data});

  const CancelToken = axios.CancelToken;
  TeamMembersSource = CancelToken.source();

  axios
    .post(`${smlUrl}/user/getUsersByOrg`, data, {
      cancelToken: TeamMembersSource.token,
    })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_TEAM_MEMBERS,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export let QESummarySource;
export const fetchQuickSummary = (data) => (dispatch) => {
  //console.log('quick Summary api')

  const CancelToken = axios.CancelToken;
  QESummarySource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchQualityEventQuickSummary`,
      data,
      { cancelToken: QESummarySource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_QUICK_SUMMARY,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

//add quality event
export const addQualityEvent = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/addQualityEvent`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ADD_QUALITY_EVENT,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

// fetch quality events by eventID
export const eventDetailsByID = (eventID) => (dispatch) => {
  axios
    .get(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchQualityEventDetails/${eventID}`
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_EVENT_DETAILS,
          payload: {
            qualityEventRecordDetails: res.data.qualityEventRecordDetails,
            qeCustomFiles: res.data.qeCustomFiles,
          },
        });
      }
    })
    .catch((err) => console.log(err));
};

// update quality Events
export const updateQualityEventData = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/updateQualityEvent`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPDATE_QUALITY_EVENT,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

// Save quality event partially action
export const saveQualityEventPartiallyAction = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/saveQualityEventPartially`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: SAVE_QUALITY_EVENT_PARTIALLY,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};
