import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { style } from "../../../../../resources/css/styles";

const styles = style;

const LineItemStatus = props => {
  const { classes } = props;
  return (
    <Grid
      item
      md={2}
      className={classes.flexRow}
      style={{ backgroundColor: "#CDD7DB", alignItems: "center" }}
    >
      <div className={classes.flexColumn}>
        <Typography
          variant="caption"
          style={{ marginTop: "1.45rem", fontWeight: 500 }}
        >
          Status
        </Typography>
        <Typography variant="body2" style={{ fontWeight: 500 }}>
          {props.lineItemStatus}
        </Typography>
      </div>
    </Grid>
  );
};

export default withStyles(styles)(LineItemStatus);
