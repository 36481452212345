import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";

import ViewCmoNotes from "../../ViewCmoNotes";
import { style } from "../../../../../resources/css/styles";

const styles = style;

class PurchaseOrder extends Component {
  state = {
    toggleNotes: false,
    poDetails: this.props.poLineItems.poPreview.data
      ? this.props.poLineItems.poPreview.data
      : {}
  };

  toggleNotes = () => {
    this.setState({
      toggleNotes: !this.state.toggleNotes
    });
  };
  render() {
    const { classes } = this.props;
    const { poDetails, toggleNotes } = this.state;
    return (
      <div>
        <Dialog open={toggleNotes} maxWidth="md">
          <ViewCmoNotes
            toggleNotes={this.toggleNotes}
            CMONotes={poDetails.CMONotes}
          />
        </Dialog>
        <div className={classes.pobackground}>
          <div className={classes.wrapper1}>
            <div className={classes.flexRow}>
              <div className={classes.flexColumn}>
                <Typography variant="subtitle1" className={classes.textTitle}>
                  Purchase Order Number
                </Typography>
                <Typography variant="subtitle2" className={classes.textBold}>
                  {poDetails.purchaseOrderNumber}
                </Typography>
              </div>
              <div className={classes.flexColumn}>
                <Typography variant="subtitle1" className={classes.textTitle}>
                  Purchase Order Date
                </Typography>
                <Typography variant="subtitle2" className={classes.textBold}>
                  {poDetails.PODate}
                </Typography>
              </div>
            </div>
            <div>
              <div className={classes.flexColumn}>
                <Typography variant="subtitle1" className={classes.textTitle}>
                  Purchase Order Status
                </Typography>
                <Typography variant="subtitle2" className={classes.textBold}>
                  Draft
                </Typography>
              </div>
            </div>
          </div>

          <div className={classes.divider} />

          <div className={classes.wrapper1}>
            <div className={classes.flexColumn}>
              <Typography variant="caption" className={classes.textBold}>
                Created By
              </Typography>
              <Typography variant="caption" className={classes.textBold}>
                {poDetails.createdBy}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant="subtitle1" className={classes.textTitle}>
                Type of CMO
              </Typography>
              <Typography variant="subtitle2" className={classes.textBold}>
                {poDetails.CMOType}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant="subtitle1" className={classes.textTitle}>
                Vendor Code
              </Typography>
              <Typography variant="subtitle2" className={classes.textBold}>
                {poDetails.vendorCode}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant="caption" className={classes.textBold}>
                Important Notes
              </Typography>
              <Typography
                variant="caption"
                className={classes.textBold}
                onClick={this.toggleNotes}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
              >
                Notes for CMO
              </Typography>
            </div>
          </div>

          {/* Colored div ends */}
        </div>

        <div className={classes.wrapper2}>
          <Typography
            variant="h6"
            color="secondary"
            style={{ paddingLeft: "1.5rem", fontSize: "1rem" }}
          >
            Vendor Information
          </Typography>
          <div
            className={classes.flexRow}
            style={{ justifyContent: "space-between" }}
          >
            <div className={classes.flexColumn}>
              <Typography variant="caption">Vendor Tax Number</Typography>
              <Typography variant="body2">
                {poDetails.vendorTaxNumber}
              </Typography>
            </div>
            {/* <div className={classes.flexColumn}>
              <Typography variant="caption">Vendor Number</Typography>
              <Typography variant="body2">{poDetails.vendorNumber}</Typography>
            </div> */}
            <div className={classes.flexColumn}>
              <Typography variant="caption">Vendor Name</Typography>
              <Typography variant="body2">{poDetails.vendorName}</Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant="caption">Vendor City</Typography>
              <Typography variant="body2">{poDetails.vendorCity}</Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant="caption">Vendor State</Typography>
              <Typography variant="body2">{poDetails.vendorState}</Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant="caption">Vendor Country</Typography>
              <Typography variant="body2">{poDetails.vendorCountry}</Typography>
            </div>
            <div className={classes.flexColumn} style={{ width: "25%" }}>
              <Typography variant="caption">Vendor Address</Typography>
              <Typography
                variant="body2"
                style={{ width: "100%", wordWrap: "break-word" }}
              >
                {poDetails.vendorAddress}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.divider} />

        <div className={classes.wrapper2}>
          <Typography
            variant="h6"
            color="secondary"
            style={{ paddingLeft: "1.5rem", fontSize: "1rem" }}
          >
            Delivery Information
          </Typography>
          <div
            className={classes.flexRow}
            style={{ justifyContent: "space-between" }}
          >
            <div className={classes.flexColumn}>
              <Typography variant="caption">Site Manager</Typography>
              <Typography variant="body2">{poDetails.siteManager}</Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant="caption">Means of Transport</Typography>
              <Typography variant="body2">
                {poDetails.meansOfTransport}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant="caption">Delivery Location City</Typography>
              <Typography variant="body2">
                {poDetails.deliveryLocationCity}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant="caption">Delivery Location State</Typography>
              <Typography variant="body2">
                {poDetails.deliveryLocationState}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant="caption">
                Delivery Location Country
              </Typography>
              <Typography variant="body2">
                {poDetails.deliveryLocationCountry}
              </Typography>
            </div>
            <div className={classes.flexColumn} style={{ width: "25%" }}>
              <Typography variant="caption">
                Delivery Location Address
              </Typography>
              <Typography
                variant="body2"
                style={{ width: "100%", wordWrap: "break-word" }}
              >
                {poDetails.deliveryLocationAddress}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.divider} />
      </div>
    );
  }
}
PurchaseOrder.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  poLineItems: state.pcmp.pharmaCo.purchaseOrder
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    null
  )(withRouter(PurchaseOrder))
);
