import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { Box, Button } from '@material-ui/core';

import usePrevious from '../../../../../../helpers/previousProps';

import CloseEventScreen from './CloseEventDialog';

import { updateQualityEventData } from '../../../../../../containers/actions/common/qualityEventActions';

const CapaRequired = (props) => {
  const didMountRef = useRef(false);
  const { stateData, handleChangeData } = props;
  const [state, setState] = useState({
    closeEventDialog: false,
  });

  const toggleDialogScreen = (dialogName) => {
    setState((prevState) => ({
      ...prevState,
      [dialogName]: !state[dialogName],
    }));
  };

  const { updateQualityEvent } = props;
  const prevUpdateQualityEvent = usePrevious(updateQualityEvent);
  useEffect(() => {
    if (didMountRef.current) {
      if (updateQualityEvent !== prevUpdateQualityEvent) {
        if (stateData.updateEventType === 'closeEvent') {
          setState((prevState) => ({
            ...prevState,
            closeEventDialog: false,
          }));
        }
      }
    } else {
      didMountRef.current = true;
    }
  }, [updateQualityEvent, prevUpdateQualityEvent]);

  return (
    <>
      <Box marginTop='3rem'>
        <Button
          style={{
            width: '11rem',
            padding: '0.5rem 0rem',
            borderRadius: '1.5rem',
            textTransform: 'none',
          }}
          variant='outlined'
          disabled={!props.accepted}
          onClick={() => toggleDialogScreen('closeEventDialog')}
        >
          Close Event
        </Button>
      </Box>
      {state.closeEventDialog && (
        <CloseEventScreen
          openDialog={state.closeEventDialog}
          toggleDialog={toggleDialogScreen}
          stateData={stateData}
          handleChangeData={handleChangeData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  updateQualityEvent: state.pcmp.common.QMS.updateQualityEvent,
});

export default connect(mapStateToProps, { updateQualityEventData })(
  CapaRequired
);
