import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Information from "./Information";

const styles = (theme) => ({
  flexContainer: {
    justifyContent: "space-between",
    borderBottom: "1px solid #E5E5E5",
  },
  indicator: {
    backgroundColor: "black",
  },
  tabsContainer: {
    paddingRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "2rem",
    },
  },
});

class DisabledTabs extends Component {
  state = {
    value: 0,
    valueOne: 0,
    selectedDateFormat: "DD/MM/YYYY",
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value, valueOne } = this.state;
    const {
      selectedDateFormat,
      classes,
      params,
      onCancel,
      onSubmit,
      handleFormatChange,
      disableSaveButton,
    } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} sm={8} md={6}>
          <Tabs
            value={value}
            indicatorColor="primary"
            // textColor="primary"
            onChange={this.handleChange}
            aria-label="disabled tabs example"
            classes={{
              flexContainer: classes.flexContainer,
              indicator: classes.indicator,
            }}
          >
            <Tab label="Information" />
          </Tabs>
          {value === 0 && <Information />}
          <Tabs
            value={valueOne}
            indicatorColor="primary"
            // textColor="primary"
            onChange={this.handleChange}
            aria-label="disabled tabs example"
            classes={{
              flexContainer: classes.flexContainer,
              indicator: classes.indicator,
            }}
          >
            <Tab label="Ecosystem Settings" />
          </Tabs>
          {valueOne === 0 && (
            <React.Fragment>
              <Grid container style={{ margin: "1rem" }}>
                <Grid item xs={4}>
                  <Typography variant="body1">Date Format</Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "center" }}>
                  &#160; :&#160;
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="dateSetting">Select Date Format</InputLabel>
                    <Select
                      disabled={params.roleName === "Admin" ? false : true}
                      fullWidth
                      labelId="dateSetting"
                      id="selectedDateFormat"
                      name="selectedDateFormat"
                      value={selectedDateFormat}
                      onChange={handleFormatChange}
                      label="Select Date Format"
                    >
                      <MenuItem value="DD-MMM-YYYY">
                        DD-MMM-YYYY (Default)
                      </MenuItem>
                      <MenuItem value="DD-MM-YYYY">DD-MM-YYYY</MenuItem>
                      <MenuItem value="MM-DD-YYYY">MM-DD-YYYY</MenuItem>
                      <MenuItem value="DD/MMM/YYYY">DD/MMM/YYYY</MenuItem>
                      <MenuItem value="DD/MM/YYYY">DD/MM/YYYY</MenuItem>
                      <MenuItem value="MM/DD/YYYY">MM/DD/YYYY</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container justify="center" style={{ margin: "1rem" }}>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    disabled={params.roleName === "Admin" ? false : true}
                    onClick={onSubmit}
                    style={{
                      backgroundColor:
                        params.roleName === "Admin"
                          ? disableSaveButton
                            ? "grey"
                            : "green"
                          : "grey",
                      borderRadius: "1rem",
                      color: "#ffffff",
                    }}
                  >
                    Save Changes
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={params.roleName === "Admin" ? false : true}
                    onClick={onCancel}
                    style={{
                      borderRadius: "1rem",
                      marginLeft: "1rem",
                    }}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(DisabledTabs);
