import axios from 'axios';
import { appUrl } from '../../../../../utils/config';

import {
  CMO_LINE_BATCH_ITEMS,
  CMO_ADD_BATCH_ITEM,
  CMO_ADD_BATCH_WARNING,
  CMO_BATCH_ITEM_DATES,
  CMO_BATCH_STATUS_LIST,
  CMO_BATCH_UPDATE,
  OPEN_BACKDROP,
  DESTROY_BACKDROP,
  CMO_ADD_SASL_BATCH_ITEM
} from '../types';

export const cmoFetchLineBatchItems = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${localStorage.roleName}/fetchBatchLineItemsList`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_LINE_BATCH_ITEMS,
          payload: res.data.batchLineItems,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const cmoAddSASLBatchItem = (data) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText: 'Please Wait. While we add the Batch'
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${localStorage.roleName}/saveDSBatchFromUI`,
      data
    )
    .then((res) => {
      dispatch({
        type: DESTROY_BACKDROP,
      });
      if (res.data.status) {
        dispatch({
          type: CMO_ADD_SASL_BATCH_ITEM,
          payload: res.data
        })
      }
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: DESTROY_BACKDROP,
      });
      if (err.response.status === 400) {
        dispatch({
          type: CMO_ADD_SASL_BATCH_ITEM,
          payload: err.response.data,
        });
      }
    })
}

export const cmoAddBatchItem = (data) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText: 'Please Wait. While we add the Batch'
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${data.roleName}/addBatchLineItem`,
      data
    )
    .then((res) => {
      dispatch({
        type: DESTROY_BACKDROP,
      });
      if (res.data.status) {
        dispatch({
          type: CMO_ADD_BATCH_ITEM,
          payload: res.data,
        });
      } else if (res.data.status === false) {
        dispatch({
          type: CMO_ADD_BATCH_WARNING,
          payload: true,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: DESTROY_BACKDROP,
      });
      if (err.response.status === 400) {
        dispatch({
          type: CMO_ADD_BATCH_ITEM,
          payload: err.response.data,
        });
      }
    });
};

// Fetch Manfactured Date and Shipment dates
export const fetchBatchDates = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${data.roleName}/fetchBatchDates`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_BATCH_ITEM_DATES,
          payload: res.data.batchDates,
        });
      }
    })
    .catch((err) => console.log(err));
};

// Fetch batch item status options to update

export const fetchBatchItemStatusList = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/fetchBatchStatusList`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_BATCH_STATUS_LIST,
          payload: res.data.batchStatusList,
        });
      }
    })
    .catch((err) => console.log(err));
};

// Update Batch
export const updateBatchItem = (data, roleName) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/CMO/${roleName}/updateBatchDetails`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_BATCH_UPDATE,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};