import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

const useStyles = makeStyles({
  statusBox: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.2rem',
    borderRadius: '0.45rem',
    fontSize: '0.875rem',
    color: '#EAEAEA',
    width: '120px',
    backgroundColor: (props) => (props.statusId === 0 ? '#FF1919' : '#53D533'),
  },
});
const StatusDisplay = (props) => {
  const classes = useStyles(props);

  return (
    <div>
      <Typography className={classes.statusBox} style={{ margin: 'auto' }}>
        {props.statusId === 0 ? (
          <>
            <ReportProblemOutlinedIcon />
            Shortage
          </>
        ) : (
          <>
            <CheckOutlinedIcon />
            Available
          </>
        )}
      </Typography>
    </div>
  );
};
StatusDisplay.prototypes = {
  statusId: PropTypes.number.isRequired,
};
export default StatusDisplay;
