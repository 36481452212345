/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Harish D
 * -----
 * File Description: Component to dispaly Purchase Order Details
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import Dialog from '@material-ui/core/Dialog';

import ViewCmoNotes from './ViewCmoNotes';
import {
  getPurchaseOrderDetails,
  updatePurchaseOrder,
  getLineItemsInPO,
} from '../../../../../containers/actions/pharmaCo/purchaseOrderActions';
import { downloadPOPdf } from '../../../../../containers/actions/pharmaCo/filesActions';

import EditPurchaseOrderStatus from './EditPurchaseOrderStatus';

import { style } from '../../../../../resources/css/styles';

const styles = style;

class PurchaseOrder extends Component {
  state = {
    poDetails: {},
    editStatus: false,
    tempEditStatus: '',
    toggleNotes: false,
  };

  componentDidMount() {
    const data = { ...this.props.match.params, collabType: 'CMO' };
    this.props.getPurchaseOrderDetails(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.poDetails) {
      this.setState({
        poDetails: nextProps.poDetails,
      });
    }
    if (nextProps.poStatusUpdated) {
      const data = { ...this.props.match.params, collabType: 'CMO' };
      // setTimeout(() =>
      this.props.getPurchaseOrderDetails(data);
      // , 1000);
      // setTimeout(() =>
      this.props.getLineItemsInPO(data);
      // , 1200);
    }
  }

  editStatus = () => {
    this.setState({ editStatus: true });
  };

  cancelEdit = () => {
    this.setState({ editStatus: false });
  };

  saveStatus = (status, comment) => {
    const saveStatus = {
      ...this.props.match.params,
      PORejectionComments: comment,
      lineItems: this.state.poDetails.lineItems,
      statusCode: status,
      collabType: 'CMO',
    };
    this.props.updatePurchaseOrder(saveStatus);
    this.cancelEdit();
  };

  toggleNotes = () => {
    this.setState({
      toggleNotes: !this.state.toggleNotes,
    });
  };
  downloadPOPdf = () => {
    let data = {
      ...this.props.match.params,
      collabType: 'CMO',
      docType: 'purchaseOrderFile',
      schrockenPOID: this.state.poDetails.schrockenPOID,
    };
    this.props.downloadPOPdf(data);
  };

  render() {
    const { classes } = this.props;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    return (
      <div className={classes.linePoDivBackground}>
        <Dialog open={this.state.toggleNotes} maxWidth='md'>
          <ViewCmoNotes
            toggleNotes={this.toggleNotes}
            CMONotes={this.state.poDetails.CMONotes}
          />
        </Dialog>
        <div className={classes.pobackground}>
          <div className={classes.wrapper1}>
            <div className={classes.flexRow}>
              <div className={classes.flexColumn}>
                <Typography variant='subtitle1' className={classes.textTitle}>
                  Purchase Order Number
                </Typography>
                <Typography variant='subtitle2' className={classes.textBold}>
                  {this.state.poDetails.purchaseOrderNumber}
                </Typography>
              </div>
              <div className={classes.flexColumn}>
                <Typography variant='subtitle1' className={classes.textTitle}>
                  Purchase Order Date
                </Typography>
                <Typography variant='subtitle2' className={classes.textBold}>
                  {this.state.poDetails.poDate
                    ? Moment(this.state.poDetails.poDate).format(dateFormat)
                    : null}
                </Typography>
              </div>
              <div className={classes.flexColumn}>
                <Typography variant='subtitle1' className={classes.textTitle}>
                  Type of CMO
                </Typography>
                <Typography variant='subtitle2' className={classes.textBold}>
                  {this.state.poDetails.CMOType}
                </Typography>
              </div>
              <div className={classes.flexColumn}>
                <Typography variant='subtitle1' className={classes.textTitle}>
                  Vendor Code
                </Typography>
                <Typography variant='subtitle2' className={classes.textBold}>
                  {this.state.poDetails.vendorCode}
                </Typography>
              </div>
            </div>
            {this.state.editStatus ? (
              <EditPurchaseOrderStatus
                cancelEdit={this.cancelEdit}
                poStatus={this.state.poDetails.statusText}
                poStatusCode={this.state.poDetails.statusCode}
                poLines={this.state.poDetails.lineItems}
                saveStatus={this.saveStatus}
              />
            ) : (
              <div className={classes.flexRow} style={{ alignItems: 'center' }}>
                <div className={classes.flexColumn}>
                  <Typography variant='subtitle1' className={classes.textTitle}>
                    Purchase Order Status
                  </Typography>

                  <Typography variant='subtitle2' className={classes.textBold}>
                    {this.state.poDetails.statusText}
                  </Typography>
                </div>
                {this.state.poDetails.statusCode === 0 && (
                  <div
                    className={classes.flexRow}
                    style={{ alignItems: 'center', cursor: 'pointer' }}
                    onClick={this.editStatus}
                  >
                    <IconButton
                      disableRipple
                      style={{
                        color: 'white',
                        fontSize: '1.25rem',
                        paddingRight: '0.15rem',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <Typography
                      variant='caption'
                      style={{
                        color: 'white',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      Edit
                    </Typography>
                  </div>
                )}
              </div>
            )}

            {/* </div> */}
          </div>

          <div className={classes.divider} />

          <div className={classes.wrapper1}>
            <div className={classes.flexColumn}>
              <Typography variant='caption' className={classes.textBold}>
                Creation Date
              </Typography>
              <Typography variant='caption' className={classes.textBold}>
                {this.state.poDetails.creationDate
                  ? Moment(this.state.poDetails.creationDate).format(dateFormat)
                  : null}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant='caption' className={classes.textBold}>
                Created By
              </Typography>
              <Typography variant='caption' className={classes.textBold}>
                {this.state.poDetails.createdBy}
              </Typography>
            </div>

            <div className={classes.flexColumn}>
              <Typography variant='caption' className={classes.textBold}>
                Last Updated Date
              </Typography>
              <Typography variant='caption' className={classes.textBold}>
                {this.state.poDetails.lastUpdatedDate
                  ? Moment(this.state.poDetails.lastUpdatedDate).format(
                      dateFormat
                    )
                  : null}
              </Typography>
            </div>

            <div className={classes.flexColumn}>
              <Typography variant='caption' className={classes.textBold}>
                Last Updated By
              </Typography>
              <Typography variant='caption' className={classes.textBold}>
                {this.state.poDetails.lastUpdatedBy}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant='caption' className={classes.textBold}>
                PO Pdf
              </Typography>
              <Typography
                variant='caption'
                className={classes.textBold}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={this.downloadPOPdf}
              >
                Download
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant='caption' className={classes.textBold}>
                Important Notes
              </Typography>
              <Typography
                variant='caption'
                className={classes.textBold}
                onClick={this.toggleNotes}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                Notes for CMO
              </Typography>
            </div>
          </div>

          {/* Colored div ends */}
        </div>

        <div className={classes.wrapper2}>
          <Typography
            variant='h6'
            color='secondary'
            style={{ paddingLeft: '1.5rem', fontSize: '1rem' }}
          >
            Vendor Information
          </Typography>
          <div
            className={classes.flexRow}
            style={{ justifyContent: 'space-between' }}
          >
            <div className={classes.flexColumn}>
              <Typography variant='caption'>Vendor Tax Number</Typography>
              <Typography variant='body2'>
                {this.state.poDetails.vendorTaxNumber}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant='caption'>Vendor Name</Typography>
              <Typography variant='body2'>
                {this.state.poDetails.vendorName}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant='caption'>Vendor City</Typography>
              <Typography variant='body2'>
                {this.state.poDetails.vendorCity}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant='caption'>Vendor State</Typography>
              <Typography variant='body2'>
                {this.state.poDetails.vendorState}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant='caption'>Vendor Country</Typography>
              <Typography variant='body2'>
                {this.state.poDetails.vendorCountry}
              </Typography>
            </div>
            <div className={classes.flexColumn} style={{ width: '25%' }}>
              <Typography variant='caption'>Vendor Address</Typography>
              <Typography
                variant='body2'
                style={{ width: '100%', wordWrap: 'break-word' }}
              >
                {this.state.poDetails.vendorAddressLineOne}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.divider} />

        <div className={classes.wrapper2}>
          <Typography
            variant='h6'
            color='secondary'
            style={{ paddingLeft: '1.5rem', fontSize: '1rem' }}
          >
            Delivery Information
          </Typography>
          <div
            className={classes.flexRow}
            style={{ justifyContent: 'space-between' }}
          >
            <div className={classes.flexColumn}>
              <Typography variant='caption'>Site Manager</Typography>
              <Typography variant='body2'>
                {this.state.poDetails.siteManager}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant='caption'>Means of Transport</Typography>
              <Typography variant='body2'>
                {this.state.poDetails.meansOfTransport}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant='caption'>Delivery Location City</Typography>
              <Typography variant='body2'>
                {this.state.poDetails.deliveryLocationCity}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant='caption'>Delivery Location State</Typography>
              <Typography variant='body2'>
                {this.state.poDetails.deliveryLocationState}
              </Typography>
            </div>
            <div className={classes.flexColumn}>
              <Typography variant='caption'>
                Delivery Location Country
              </Typography>
              <Typography variant='body2'>
                {this.state.poDetails.deliveryLocationCountry}
              </Typography>
            </div>
            <div className={classes.flexColumn} style={{ width: '25%' }}>
              <Typography variant='caption'>
                Delivery Location Address
              </Typography>
              <Typography
                variant='body2'
                style={{ width: '100%', wordWrap: 'break-word' }}
              >
                {this.state.poDetails.deliveryLocationLineOne}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.divider} />
      </div>
    );
  }
}
PurchaseOrder.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  poDetails: state.pcmp.pharmaCo.purchaseOrder.poDetails,
  poStatusUpdated: state.pcmp.pharmaCo.purchaseOrder.poStatusUpdated,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    getPurchaseOrderDetails,
    updatePurchaseOrder,
    getLineItemsInPO,
    downloadPOPdf,
  })(withRouter(PurchaseOrder))
);
