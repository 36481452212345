import React, { Component } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { downloadGRZip } from '../../../containers/actions/cmo/shortFallsActions';

const useStyles = (theme) => ({
  multipleLogo: {
    border: '1px solid #7d7c7c',
    borderRadius: '4px',
    padding: '0.3rem',
    height: 'inherit',
    marginLeft: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
});

class DownloadSupplierPO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.backdropLoading !== prevProps.backdropLoading &&
      this.props.backdropLoading.status === true
    ) {
      this.setState({ loading: false });
    }
  }

  handleDownload = () => {
    this.setState({ loading:true })
    let backdropText = 'Please Wait. Downloading the contents'
    let data;
    if (this.props.expandableRow === true) {
      const GRArray = this.props.schrockenSupplierPOBatchID;
      const GRDetails = GRArray.map(
        (GRDetails) => GRDetails.schrockenSupplierPOBatchID
      );

      data = {
        schrockenSupplierPOBatchID: GRDetails,
        supplierPurchaseOrderNumber: this.props.supplierPurchaseOrderNumber,
      };
    } else {
      data = {
        schrockenSupplierPOBatchID: [this.props.schrockenSupplierPOBatchID],
        supplierPurchaseOrderNumber: this.props.supplierPurchaseOrderNumber,
      };
    }
    this.props.downloadGRZip(data,backdropText); 
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <GetAppIcon
          fontSize='small'
          className={classes.multipleLogo}
          onClick={this.handleDownload}
        />

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  backdropLoading: state.pcmp.cmo.shortFalls.downloadZip,
});

export default withStyles(useStyles)(
  connect(mapStateToProps, { downloadGRZip })(DownloadSupplierPO)
);
