/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Harish D
 * -----
 * File Description: Line Layout
 */

import React from "react";
import Grid from "@material-ui/core/Grid";
import BatchTable from "./BatchTable";
import LineItem from "./LineItem";
import Chat from "../chat";

const POLineLayout = () => (
  <Grid>
    <LineItem />
    <BatchTable />
    <Chat collabType="CMO" item="POLineItem" />
  </Grid>
);

export default POLineLayout;
