/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Harish D
 * -----
 * File Description: Function that orders table data in sorted format(asc or desc),
 * depending the field selected(columnToQuery) and to search the table.
 */
export const tableFilter = (data, columnToQuery, queryLowerCase, header) => {
  let searchColumnArray = [];
  for (let i = 0; i < header.length; i++) {
    searchColumnArray.push(header[i].id);
  }
  if (columnToQuery === "all") {
    for (let i = 0; i < searchColumnArray.length; i++) {
      let includesValue =
        data[searchColumnArray[i]] !== null &&
        data[searchColumnArray[i]] !== undefined
          ? data[searchColumnArray[i]]
              .toString()
              .toLowerCase()
              .includes(queryLowerCase)
          : false;
      if (includesValue) {
        return includesValue;
      }
    }
  } else {
    return data[columnToQuery] !== null
      ? data[columnToQuery].toString().toLowerCase().includes(queryLowerCase)
      : false;
  }
};
