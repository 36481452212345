import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllConfigurationFlags } from '../../../../containers/actions/common/reportsActions';

export default function featuresAndValidations(ComposedClass) {
  const GetFeatures = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getAllConfigurationFlags());
    });
    return <ComposedClass {...props} />;
  };
  return GetFeatures;
}
