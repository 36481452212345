import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import usePrevious from '../../../../../../helpers/previousProps';

import {
  fetchImpactDetails,
  fetchFrequencyDetails,
  fetchLocationDetails,
  fetchFacilityDetails,
  fetchProductDetails,
  fetchBatchDetails,
  fetchSupplierDetails,
} from '../../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    fontWeight: 500,
    marginBottom: '0.5rem',
  },
  title2: {
    textAlign: 'center',
    fontWeight: 500,
    marginBottom: '1rem',
    marginTop: '2rem',
  },
  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '3px',
  },
  inputLabel: {
    marginBottom: '0.5rem',
    fontSize: '85%',
    fontWeight: '500',
  },
  requiredStar: {
    color: 'red',
  },
  nextBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '2rem',
    borderRadius: '2.5rem',
    textTransform: 'capitalize',
    marginLeft: '4.5rem',
  },
  arrowIcon: {
    fontSize: 'small',
    paddingTop: '0.4rem',
  },
  clear: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textAlign: 'end',
  },
}));

const EventInformation = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const [state, setState] = useState({
    errors: {},
    loadingProducts: false,
  });

  const {
    CMOMember,
    impactOptions,
    selectedImpact,
    frequencyOptions,
    selectedFrequency,
    locationOptions,
    selectedLocation,
    facilityOptions,
    selectedFacility,
    productsList,
    selectedProduct,
    batchOptions,
    selectedBatches,
    supplierNames,
  } = props.stateData;

  const handleOnChangeData = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: null },
    }));

    props.handleChangeData(name, value);

    if (name === 'selectedLocation' && value) {
      props.fetchFacilityDetails({ locationId: value.locationID });
    }
    if (name === 'selectedProduct' && value) {
      if (value?.productCode && value?.productType) {
        props.fetchBatchDetails({
          productCode: value.productCode,
          productType: value.productType,
        });
      }
    }
    if (name === 'selectedLocation') {
      props.handleChangeData('selectedFacility', '');
    }
    if (name === 'selectedProduct') {
      props.handleChangeData('selectedBatches', []);
      props.handleChangeData('supplierNames', '');
    }
    if (name === 'selectedBatches') {
      props.handleChangeData('supplierNames', '');
    }

    if (name === 'selectedBatches') {
      const supplierData = [];

      value.map((data) => {
        const supplierList = {};
        supplierList.supplierPurchaseOrderNumber =
          data.supplierPurchaseOrderNumber;
        supplierList.batchNumber = data.batchNumber;
        supplierData.push(data);
      });

      props.fetchSupplierDetails({
        productCode: selectedProduct.productCode,
        supplierPOList: supplierData,
      });
    }
  };

  const handleSearchProducts = (value) => {
    if (value !== '') {
      setState((prevState) => ({
        ...prevState,
        loadingProducts: true,
      }));
      props.fetchProductDetails({ searchString: value });
    } else {
      handleOnChangeData('productsList', []);
    }
  };

  const { collabType } = localStorage;
  let cmoMember =
    collabType === 'Pharma Co' ? CMOMember.organizationId : CMOMember;
  const { reduxProductsList } = props;
  const prevReduxProductsList = usePrevious(reduxProductsList);
  useEffect(() => {
    if (didMountRef.current) {
      if (reduxProductsList !== prevReduxProductsList) {
        setState((prevState) => ({
          ...prevState,
          loadingProducts: false,
        }));
      }
    } else {
      props.fetchImpactDetails();
      props.fetchFrequencyDetails();
      props.fetchLocationDetails({ organizationId: cmoMember });
      didMountRef.current = true;
    }
  }, [reduxProductsList, prevReduxProductsList]);

  const handleValidations = () => {
    const errorsData = {};
    let isError = false;

    if (selectedImpact === '') {
      errorsData.impact = 'Please select impact';
      isError = true;
    }

    if (selectedFrequency === '') {
      errorsData.frequency = 'Please select frequency';
      isError = true;
    }
    if (selectedFrequency === '') {
      errorsData.facility = 'Please select facility';
      isError = true;
    }
    if (selectedLocation === '') {
      errorsData.location = 'Please select location';
      isError = true;
    }
    if (selectedProduct === '' || selectedProduct === null) {
      errorsData.selectedProduct = 'Please select product';
      isError = true;
    }
    if (selectedBatches === '' || selectedBatches === null) {
      errorsData.selectedBatches = 'Please select location';
      isError = true;
    }

    if (isError) {
      setState((prevState) => ({
        ...prevState,
        errors: errorsData,
      }));
    } else {
      props.handleScreenChange(2);
    }
  };
  const handleClearData = () => {
    props.handleChangeData('selectedImpact', '');
    props.handleChangeData('selectedFrequency', '');
    props.handleChangeData('selectedFacility', '');
    props.handleChangeData('selectedLocation', '');
    props.handleChangeData('selectedProduct', '');
    props.handleChangeData('selectedBatches', []);
    props.handleChangeData('supplierNames', '');
  };

  return (
    <>
      <Box width='40%' margin='auto'>
        <Box>
          <Typography variant='h5' className={classes.title}>
            Risk Assessment
          </Typography>
          <hr className={classes.hrLine} />
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Box marginTop={2} width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Impact <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              options={impactOptions}
              getOptionLabel={(option) => option.impactType || ''}
              getOptionSelected={(option) => option.impactType || ''}
              variant='outlined'
              value={selectedImpact}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Start typing to select Impact'
                  error={!!state.errors.impact}
                  helperText={state.errors.impact}
                />
              )}
              onChange={(event, value) => {
                handleOnChangeData('selectedImpact', value);
              }}
            />
          </Box>

          <Box marginTop={2} width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Frequency <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              options={frequencyOptions}
              getOptionLabel={(option) => option.frequencyType || ''}
              getOptionSelected={(option) => option.frequencyType || ''}
              value={selectedFrequency}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Start typing to select Frequency'
                  error={!!state.errors.frequency}
                  helperText={state.errors.frequency}
                />
              )}
              onChange={(event, value) => {
                handleOnChangeData('selectedFrequency', value);
              }}
            />
          </Box>
        </Box>

        <Box>
          <Typography variant='h5' className={classes.title2}>
            Facility and Location Details
          </Typography>
          <hr className={classes.hrLine} />
        </Box>

        <Box display='flex' justifyContent='space-between'>
          <Box marginTop={2} width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Location <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              options={locationOptions}
              getOptionLabel={(option) => `${option.locationName || ''}`}
              getOptionSelected={(option) => `${option.locationName || ''}`}
              name='location'
              value={selectedLocation}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Start typing to select Location'
                  error={!!state.errors.location}
                  helperText={state.errors.location}
                />
              )}
              onChange={(event, value) => {
                handleOnChangeData('selectedLocation', value);
              }}
            />
          </Box>

          <Box marginTop={2} width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Facility <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              name='facility'
              value={selectedFacility}
              options={facilityOptions}
              getOptionLabel={(option) => `${option.FacilityName || ''}`}
              getOptionSelected={(option) => `${option.FacilityName || ''}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  placeholder='Start typing to select Facility'
                  error={!!state.errors.facility}
                  helperText={state.errors.facility}
                />
              )}
              onChange={(name, value) => {
                handleOnChangeData('selectedFacility', value);
              }}
            />
          </Box>
        </Box>

        <Box>
          <Typography variant='h5' className={classes.title2}>
            Affected Product and Batches
          </Typography>
          <hr className={classes.hrLine} />
        </Box>

        <Box justifyContent='space-between'>
          <Box marginTop={2} width='100%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Select Product <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              loading={state.loadingProducts}
              value={selectedProduct}
              options={productsList}
              getOptionLabel={(option) =>
                option?.productCode
                  ? `${option.productCode}-${option.productDescription}`
                  : ''
              }
              getOptionSelected={(option) =>
                option?.productCode
                  ? `${option.productCode}-${option.productDescription}`
                  : ''
              }
              loadingText='Getting Products...'
              noOptionsText='No matching records found. Please try different search'
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Search using product code or Description'
                  error={!!state.errors.selectedProduct}
                  helperText={state.errors.selectedProduct}
                  onChange={(e) => {
                    handleSearchProducts(e.target.value);
                  }}
                />
              )}
              onChange={(name, value) => {
                handleOnChangeData('selectedProduct', value);
              }}
            />
          </Box>

          <Box marginTop={2} width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Select Batch <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              options={batchOptions}
              getOptionLabel={(option) => option.batchNumber}
              // getOptionSelected={(option) => option.batchNumber}
              name='batchNumber'
              value={selectedBatches}
              multiple={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  placeholder='Start typing to search and select'
                  error={!!state.errors.selectedBatches}
                  helperText={state.errors.selectedBatches}
                />
              )}
              onChange={(name, value) => {
                handleOnChangeData('selectedBatches', value);
              }}
            />
          </Box>
          <Box marginTop={2} width='100%'>
            <Typography variant='body2' className={classes.inputLabel}>
              RM & PM Supplier Name
              <span className={classes.requiredStar}>*</span>
            </Typography>
            <TextField
              name='affectedSupplier'
              value={supplierNames}
              disabled
              style={{ width: '100%' }}
              size='small'
              variant='outlined'
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
        </Box>

        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          marginTop='4rem'
          marginBottom='4rem'
        >
          <Box display='flex'>
            <Typography
              variant='subtitle1'
              onClick={() => props.handleScreenChange(0)}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontWeigh: '700',
                display: 'inline-flex',
              }}
            >
              <ArrowBackIosIcon className={classes.arrowIcon} />
              <span> Back</span>
            </Typography>
          </Box>
          <Box width='22%' textAlign='center'>
            <Button
              className={classes.nextBtn}
              variant='contained'
              color='primary'
              onClick={handleValidations}
              endIcon={<NavigateNextIcon />}
            >
              Next
            </Button>
          </Box>
          <Box marginTop='0.5rem'>
            <Typography
              variant='body2'
              className={classes.clear}
              onClick={handleClearData}
            >
              Clear all data
            </Typography>
            <Typography variant='caption'>
              (This will remove all data entered)
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  reduxProductsList: state.pcmp.common.QMS.fetchProductList,
});

export default connect(mapStateToProps, {
  fetchImpactDetails,
  fetchFrequencyDetails,
  fetchLocationDetails,
  fetchFacilityDetails,
  fetchProductDetails,
  fetchBatchDetails,
  fetchSupplierDetails,
})(EventInformation);
