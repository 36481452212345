import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import { Typography, TableRow, Fab } from '@material-ui/core';
import NoUnderLineTableCell from '../models/table/NoUnderLineTableCell';
import InputNumber from '../models/inputs/InputNumber';

const AddContainer = ({
  container,
  index,
  handleChange,
  handleAddContainer,
  handleRemoveContainer,
}) => {
  return (
    <TableRow>
      <NoUnderLineTableCell align='center'>
        <Typography variant='body2'>
          {container.containerSerialNumber}
        </Typography>
      </NoUnderLineTableCell>
      <NoUnderLineTableCell>
        <InputNumber
          name='numberOfContainers'
          value={container.numberOfContainers || ''}
          placeholder='No. of Containers'
          onChange={(event) => handleChange(event, container, index)}
          inputProps={{ min: 1, step: 'any' }}
        />
      </NoUnderLineTableCell>
      <NoUnderLineTableCell>
        <InputNumber
          name='quantityPerContainer'
          value={container.quantityPerContainer || ''}
          placeholder='Qty per Container'
          onChange={(event) => handleChange(event, container, index)}
          inputProps={{ min: 0.1, step: 'any' }}
        />
      </NoUnderLineTableCell>
      <NoUnderLineTableCell>
        {index === 0 ? (
          <Fab
            size='small'
            color='secondary'
            aria-label='Add new Container Data'
            onClick={() => handleAddContainer(index)}
          >
            <AddIcon />
          </Fab>
        ) : (
          <ClearSharpIcon
            fontSize='small'
            color='error'
            style={{ cursor: 'pointer', paddingLeft: '0.5rem' }}
            onClick={() => handleRemoveContainer(index)}
          />
        )}
      </NoUnderLineTableCell>
    </TableRow>
  );
};

AddContainer.propTypes = {
  container: PropTypes.object.isRequired,
  handleAddContainer: PropTypes.func.isRequired,
  handleRemoveContainer: PropTypes.func.isRequired,
};

export default AddContainer;
