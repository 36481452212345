import axios from 'axios';
import { appUrl, smlUrl } from '../../../../../utils/config';

import {
  FETCH_SASL_TABLE,
  VIEW_SL_DETAILS,
  FETCH_BOM_DETAILS,
  FETCH_BATCH_DETAILS,
  FETCH_BOMCONSUMPTION_INFO,
  FETCH_DELIVERY_SCHEDULE_BATCH_DETAILS,
  SASL_BATCH_UPDATE,
  UPDATE_BOM_CONSUMPTION_DETAILS,
  SASL_BATCH_APPROVE,
  NEXT_STATUS_LIST,
  DS_HEADER,
  ALL_DS_STATUS,
  //to show linear progress indicator on table for background data loading
  TABLE_LOAD,
  DESTROY_TABLE_LOAD,
  OPEN_BACKDROP,
  DESTROY_BACKDROP,
  SEND_SASL_BATCH_APPROVAL,
  UPLOAD_SASL_BATCHDATAFROMFILE,
  UPDATE_SASL_BATCHDATAFROMFILE,
  UPLOAD_SASL_CONSUMPTIONINFOFROMFILE,
  UPDATE_SASL_CONSUMPTIONINFOFROMFILE,
  REJECT_SASL_BATCH_APPROVAL,
  SASL_BATCH_AUDIT_REPORT,
  SA_AUDIT_REPORT,
  SL_AUDIT_REPORT,
  FETCH_SL_LINES,
  FETCH_DS,
  SASL_DS_AUDIT_REPORT,
  CLOSE_TABLE_LOADING,
  FETCH_THRESHOLD_DETAILS,
  FETCH_DELIVERY_SCHEDULE_BATCHES,
  FETCH_BATCH_PRODUCTS,
  QUICK_SUMMARY_STATUSES,
  BATCH_UPDATED,
  UPLOAD_RMPM_AVAILABILITY,
  UPDATE_RMPM_AVAILABILITY,
  IS_RMPM_DATA_AVAILABLE,
  INITIAL_RM,
  INITIAL_PM,
  RM_TABLE_DATA,
  MASTER_RM,
  PM_TABLE_DATA,
  MASTER_PM,
  EDIT_RM_TABLE_DATA,
  EDIT_PM_TABLE_DATA,
  UPDATE_RMPM,
  ALL_QA_STATUS,
  QA_APPROVAL,
} from '../types';

export let saslSource;

export const fetchSASLDetails = (data) => (dispatch) => {
  if (saslSource !== undefined) {
    saslSource.cancel('Request cancelled');
  }

  const CancelToken = axios.CancelToken;
  saslSource = CancelToken.source();
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchSASLdetails`,
      data,
      {
        cancelToken: saslSource.token,
      }
    )
    .then((res) => {
      dispatch({
        type: DESTROY_TABLE_LOAD,
      });
      if (res.data.status) {
        dispatch({
          type: FETCH_SASL_TABLE,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      // dispatch({
      //   type: DESTROY_TABLE_LOAD,
      // });
    });
};

//sasl pagination api
let offsetSource;
export const offSetfetchSASLDetails = (data) => (dispatch) => {
  if (offsetSource !== undefined) {
    offsetSource.cancel('Request cancelled');
  }

  const CancelToken = axios.CancelToken;
  offsetSource = CancelToken.source();
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/pagination/fetchSASLListDetails`,
      data,
      {
        cancelToken: offsetSource.token,
      }
    )
    .then((res) => {
      dispatch({
        type: DESTROY_TABLE_LOAD,
      });
      if (res.data.status) {
        dispatch({
          type: FETCH_SASL_TABLE,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      // dispatch({
      //   type: DESTROY_TABLE_LOAD,
      // });
    });
};

// to view SL details (eye button on home page of SASL)
export const viewSLDetails = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/viewSLDetails`,
      data
    )
    .then((res) => {
      dispatch({
        type: VIEW_SL_DETAILS,
        payload: res.data,
      });
      // console.log(res)
    })
    .catch((err) => console.log(err));
};

// to view BOM details (Assignment button on home page of SASL)
export const fetchBOMDetails = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchBOMDetails`,
      data
    )
    .then((res) => {
      dispatch({
        type: FETCH_BOM_DETAILS,
        payload: res.data,
      });
      // console.log(res)
    })
    .catch((err) => {
      // dispatch({
      //   type: FETCH_BATCH_DETAILS,
      //   payload: err.res.data,
      // });
      console.log(err);
    });
};

// Schedule Line Deatils table API
export let newbatchTable;
export const fetchBatchDetails = (data) => (dispatch) => {
  if (newbatchTable !== undefined) {
    newbatchTable.cancel('Request cancelled for batch SL table');
  }

  const CancelToken = axios.CancelToken;
  newbatchTable = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchAllDeliveryScheduleBatches`,
      data,
      { cancelToken: newbatchTable.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_BATCH_DETAILS,
          payload: res.data,
        });
        // destroy main table loading
        dispatch({
          type: CLOSE_TABLE_LOADING,
        });

        // destroy indicator
        dispatch({ type: DESTROY_TABLE_LOAD });
      }
    })
    .catch((err) => {
      // dispatch({
      //   type: CLOSE_TABLE_LOADING,
      // });
      console.log(err);
    });
};

// View Consumption Info table API(right next to eye icon)
export const fetchBOMConsumptionDetails = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchBOMConsumptionDetails`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_BOMCONSUMPTION_INFO,
          payload: res.data,
        });

        // destroy indicator
        dispatch({ type: DESTROY_TABLE_LOAD });
      }
    })
    .catch((err) => console.log(err));
};

export const fetchVarianceThresholdDetails = (data) => (dispatch) => {
  axios
    .get(`${smlUrl}/user/fetchMaterialTypeAndVarianceThresholdDetails`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_THRESHOLD_DETAILS,
          payload: res.data,
        });

        // destroy indicator
        dispatch({ type: DESTROY_TABLE_LOAD });
      }
    })
    .catch((err) => console.log(err));
};

export let dsBatchSource;
// View Batch Info table API( eye icon in ScheduleLine Page)
export const fetchDSBatchDetails = (data) => (dispatch) => {
  const CancelToken = axios.CancelToken;
  dsBatchSource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchDeliveryScheduleBatchDetails`,
      data,
      { cancelToken: dsBatchSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_DELIVERY_SCHEDULE_BATCH_DETAILS,
          payload: res.data,
        });

        // destroy indicator
        dispatch({ type: DESTROY_TABLE_LOAD });
      }
    })
    .catch((err) => console.log(err));
};

// Batch Update API
export const updateDSBatch = (data) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText: 'Please Wait. Updating ....',
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/saveDSBatchFromUI`,
      data
    )
    .then((res) => {
      dispatch({
        type: SASL_BATCH_UPDATE,
        payload: res.data,
      });

      dispatch({
        type: DESTROY_BACKDROP,
      });
    })
    .catch((err) => {
      dispatch({
        type: DESTROY_BACKDROP,
      });
      if (err.response.status === 400) {
        dispatch({
          type: SASL_BATCH_UPDATE,
          payload: err.response.data,
        });
        // console.log(err.response)
      }

      console.log(err);
    });
};

export const approveDSBatch = (data) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText: `Please don't refresh or close the tab while approval...`,
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fgGRNGeneration`,
      data
    )
    .then((res) => {
      dispatch({
        type: SASL_BATCH_APPROVE,
        payload: res.data,
      });

      dispatch({
        type: DESTROY_BACKDROP,
      });
    })
    .catch((err) => {
      dispatch({
        type: DESTROY_BACKDROP,
      });
      if (err.response.status === 400) {
        dispatch({
          type: SASL_BATCH_APPROVE,
          payload: err.response.data,
        });
        // console.log(err.response)
      } else if (err.response.status === 504 || err.response.status === 502) {
        let obj = {
          status: false,
          grnGenerated: false,
          errorDescription: {
            err: 'The previous transaction has timed out , Please check SAP data, before trying the transaction',
          },
        };
        dispatch({
          type: SASL_BATCH_APPROVE,
          payload: obj,
        });
      }

      console.log(err);
    });
};

// Batch Update API
export const updateConsumption = (data) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText: 'Please Wait. Updating ....',
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/updateBOMConsumptionDetails`,
      data
    )
    .then((res) => {
      dispatch({
        type: UPDATE_BOM_CONSUMPTION_DETAILS,
        payload: res.data,
      });

      dispatch({
        type: DESTROY_BACKDROP,
      });
    })
    .catch((err) => {
      dispatch({
        type: DESTROY_BACKDROP,
      });
      if (err.response.status === 400) {
        dispatch({
          type: UPDATE_BOM_CONSUMPTION_DETAILS,
          payload: err.response.data,
        });
        console.log(err.response);
      }

      console.log(err);
    });
};

export let nextBatchSASLSource;
// To get the next status - update flow
export const fetchNextStatusList = (data) => (dispatch) => {
  const CancelToken = axios.CancelToken;
  nextBatchSASLSource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/getNextSASLBatchStatus`,
      data,
      { cancelToken: nextBatchSASLSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: NEXT_STATUS_LIST,
          payload: res.data,
        });

        // destroy indicator
        dispatch({ type: DESTROY_TABLE_LOAD });
      }
    })
    .catch((err) => console.log(err));
};

export const rejectDSBatchApproval = (data) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText: 'Please Wait. Rejecting the record ....',
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/rejectDSBatch`,
      data
    )
    .then((res) => {
      dispatch({
        type: REJECT_SASL_BATCH_APPROVAL,
        payload: res.data,
      });

      dispatch({
        type: DESTROY_BACKDROP,
      });
    })
    .catch((err) => {
      dispatch({
        type: DESTROY_BACKDROP,
      });
      if (err.response.status === 400) {
        dispatch({
          type: REJECT_SASL_BATCH_APPROVAL,
          payload: err.response.data,
        });
      }

      console.log(err);
    });
};

export const sendDSBatchForApproval = (data) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText: 'Please Wait. Sending for approval ....',
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/saveDSBatchForApproval`,
      data
    )
    .then((res) => {
      dispatch({
        type: SEND_SASL_BATCH_APPROVAL,
        payload: res.data,
      });

      dispatch({
        type: DESTROY_BACKDROP,
      });
    })
    .catch((err) => {
      dispatch({
        type: DESTROY_BACKDROP,
      });
      if (err.response.status === 400) {
        dispatch({
          type: SEND_SASL_BATCH_APPROVAL,
          payload: err.response.data,
        });
      }

      console.log(err);
    });
};
export let headerSource;
//DS Header => Quick Summary - Schedule Line Details Page
export const deliveryScheduleHeader = (data) => (dispatch) => {
  // if (batchTableSource !== undefined) {
  //   batchTableSource.cancel("header table api got cancelled")
  // }

  const CancelToken = axios.CancelToken;
  headerSource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchDeliveryScheduleDetails`,
      data,
      { cancelToken: headerSource.token }
    )
    .then((res) => {
      dispatch({
        type: DS_HEADER,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

// File Handling  for DS Batches

export const uploadSaSlBatchDatafromFile = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/uploadSaSlBatchDatafromFile`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPLOAD_SASL_BATCHDATAFROMFILE,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 400) {
        dispatch({
          type: UPLOAD_SASL_BATCHDATAFROMFILE,
          payload: err.response.data,
        });
      }
    });
};

export const updateSaSlBatchDatafromFile =
  (data, backdropText) => (dispatch) => {
    dispatch({
      type: OPEN_BACKDROP,
      backdropText,
    });
    dispatch({
      type: TABLE_LOAD,
    });
    axios
      .post(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/updateSaSlBatchDatafromFile`,
        data
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: UPDATE_SASL_BATCHDATAFROMFILE,
            payload: res.data,
          });
          dispatch({
            type: DESTROY_BACKDROP,
          });
          dispatch({ type: DESTROY_TABLE_LOAD });
        }
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 400) {
          dispatch({
            type: UPDATE_SASL_BATCHDATAFROMFILE,
            payload: err.response.data,
          });
          dispatch({
            type: DESTROY_BACKDROP,
          });
          dispatch({ type: DESTROY_TABLE_LOAD });
        }
      });
  };

// File Handling  for Consumption Info

export const uploadSaSlConsumptionInfofromFile = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/uploadBatchConsumptionDatafromFile`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPLOAD_SASL_CONSUMPTIONINFOFROMFILE,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 400) {
        dispatch({
          type: UPLOAD_SASL_CONSUMPTIONINFOFROMFILE,
          payload: err.response.data,
        });
      }
    });
};

export const updateSaSlConsumptionInfofromFile =
  (data, backdropText) => (dispatch) => {
    dispatch({
      type: OPEN_BACKDROP,
      backdropText,
    });
    dispatch({
      type: TABLE_LOAD,
    });
    axios
      .post(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/updateBatchConsumptionDatafromFile`,
        data
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: UPDATE_SASL_CONSUMPTIONINFOFROMFILE,
            payload: res.data,
          });
          dispatch({
            type: DESTROY_BACKDROP,
          });
          dispatch({ type: DESTROY_TABLE_LOAD });
        }
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 400) {
          dispatch({
            type: UPDATE_SASL_CONSUMPTIONINFOFROMFILE,
            payload: err.response.data,
          });
          dispatch({
            type: DESTROY_BACKDROP,
          });
          dispatch({ type: DESTROY_TABLE_LOAD });
        }
      });
  };

export let batchTableSource;
export const getAllDSBatchStatusSequence = (data) => (dispatch) => {
  // if (batchTableSource !== undefined) {
  //   batchTableSource.cancel('Request cancelled')
  // }

  const CancelToken = axios.CancelToken;
  batchTableSource = CancelToken.source();

  axios
    .get(`${smlUrl}/sml/getAllSASLBatchStatusSequence`, {
      cancelToken: batchTableSource.token,
    })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ALL_DS_STATUS,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ALL_DS_STATUS,
        payload: [],
      });
      if (axios.isCancel(err)) {
        console.log('request cancelled for filter api', err);
      }
    });
};

// sasl audit
export const saslBatchAudit = (data, backdropText) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText,
  });

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchDeliveryScheduleBatchAuditReport`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: SASL_BATCH_AUDIT_REPORT,
          payload: res.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 400) {
        dispatch({
          type: SASL_BATCH_AUDIT_REPORT,
          payload: err.response.data,
        });
      }
      dispatch({
        type: DESTROY_BACKDROP,
      });
    });
};
//SA Audit
export const saAuditReport = (data, backdropText) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText,
  });

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchScheduleAgreementAuditReport`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: SA_AUDIT_REPORT,
          payload: res.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 400) {
        dispatch({
          type: SA_AUDIT_REPORT,
          payload: err.response.data,
        });
      }
      dispatch({
        type: DESTROY_BACKDROP,
      });
    });
};

export const slAuditReport = (data, backdropText) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText,
  });

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchScheduleLineAuditReport`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: SL_AUDIT_REPORT,
          payload: res.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 400) {
        dispatch({
          type: SL_AUDIT_REPORT,
          payload: err.response.data,
        });
      }
      dispatch({
        type: DESTROY_BACKDROP,
      });
    });
};

export const fetchslLines = (data, backdropText) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/validateScheduleAgreement`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_SL_LINES,
          payload: res.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 400) {
        dispatch({
          type: FETCH_SL_LINES,
          payload: err.response.data,
        });
      }
      dispatch({
        type: DESTROY_BACKDROP,
      });
    });
};

export const fetchDSList = (data, backdropText) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/validateDeliverySchedules`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_DS,
          payload: res.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 400) {
        dispatch({
          type: FETCH_DS,
          payload: err.response.data,
        });
      }
      dispatch({
        type: DESTROY_BACKDROP,
      });
    });
};

export const dsAuditReport = (data, backdropText) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText,
  });

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchDeliveryScheduleAuditReport`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: SASL_DS_AUDIT_REPORT,
          payload: res.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 400) {
        dispatch({
          type: SASL_DS_AUDIT_REPORT,
          payload: err.response.data,
        });
      }
      dispatch({
        type: DESTROY_BACKDROP,
      });
    });
};

export let batchStatusSource;
export const getAllDSBatchStatus = (data) => (dispatch) => {
  // if (batchTableSource !== undefined) {
  //   batchTableSource.cancel('Request cancelled')
  // }

  const CancelToken = axios.CancelToken;
  batchStatusSource = CancelToken.source();

  axios
    .get(`${smlUrl}/user/getAllBatchStatusSequence`, {
      cancelToken: batchStatusSource.token,
    })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ALL_DS_STATUS,
          payload: res.data.data.allStatuses,
        });
        dispatch({
          type: QUICK_SUMMARY_STATUSES,
          payload: res.data.data.quickSummaryStatuses,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      if (axios.isCancel(err)) {
        console.log('request cancelled for filter api', err);
      }
    });
};

// Schedule Line Deatils table API

// Delivery Schedule Batches table API
export let newbatchesTable;
export const fetchBatches = (data) => (dispatch) => {
  if (newbatchesTable !== undefined) {
    newbatchesTable.cancel('Request cancelled for batches table');
  }

  const CancelToken = axios.CancelToken;
  newbatchesTable = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchBatches`,
      data,
      { cancelToken: newbatchesTable.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_DELIVERY_SCHEDULE_BATCHES,
          payload: res.data,
        });
        // destroy main table loading
        dispatch({
          type: CLOSE_TABLE_LOADING,
        });

        // destroy indicator
        dispatch({ type: DESTROY_TABLE_LOAD });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export let newbatchProducts;
export const fetchBatcheProducts = (data) => (dispatch) => {
  if (newbatchProducts !== undefined) {
    newbatchProducts.cancel('Request cancelled for batch Products');
  }

  const CancelToken = axios.CancelToken;
  newbatchProducts = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchProducts`,
      data,
      { cancelToken: newbatchProducts.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FETCH_BATCH_PRODUCTS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const batchUpdated = () => (dispatch) => {
  dispatch({
    type: BATCH_UPDATED,
    payload: {
      status: true,
      message: 'updated the batch',
    },
  });
};

export const uploadRMPMAvailability = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/uploadRMPMAvailabilityFile`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPLOAD_RMPM_AVAILABILITY,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 400) {
        dispatch({
          type: UPLOAD_RMPM_AVAILABILITY,
          payload: err.response.data,
        });
      }
    });
};

export const updateRMPMAvailability = (data, backdropText) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText,
  });
  dispatch({
    type: TABLE_LOAD,
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/addRMPMAvailabilityDetails`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPDATE_RMPM_AVAILABILITY,
          payload: res.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
        dispatch({ type: DESTROY_TABLE_LOAD });
      }
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 400) {
        dispatch({
          type: UPDATE_RMPM_AVAILABILITY,
          payload: err.response.data,
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
        dispatch({ type: DESTROY_TABLE_LOAD });
      }
    });
};

export const isMasterRMPMDataAvailable = (data) => (dispatch) => {
  axios
    .get(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchRMPMMasterAvailabilityData/${data.productCode}/${data.materialType}/${data.vendorCode}`
    )
    .then((res) => {
      dispatch({
        type: IS_RMPM_DATA_AVAILABLE,
        payload: res.data.RMPMMasterAvailabilityRecFoundFlag,
      });
    })
    .catch((err) => console.log(err));
};

export let initialRM;
export const getInitialRMMaterials = (data) => (dispatch) => {
  if (initialRM !== undefined) {
    initialRM.cancel('Request cancelled for batch Products');
  }

  const CancelToken = axios.CancelToken;
  initialRM = CancelToken.source();
  axios
    .get(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchRMPMAvailabilityData/${data.schrockenDeliveryScheduleID}/RM`,
      { cancelToken: initialRM.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: INITIAL_RM,
          payload: res.data.rmpmAvailabilityDataList,
        });
      }
    })
    .catch((err) => console.log(err));
};

export let initialPM;
export const getInitialPMMaterials = (data) => (dispatch) => {
  if (initialPM !== undefined) {
    initialPM.cancel('Request cancelled for batch Products');
  }
  const CancelToken = axios.CancelToken;
  initialPM = CancelToken.source();
  axios
    .get(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchRMPMAvailabilityData/${data.schrockenDeliveryScheduleID}/PM`,
      { cancelToken: initialPM.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: INITIAL_PM,
          payload: res.data.rmpmAvailabilityDataList,
        });
      }
    })
    .catch((err) => console.log(err));
};

export let masterRM;
export const getMasterRM = (data) => (dispatch) => {
  if (masterRM !== undefined) {
    masterRM.cancel('Request cancelled for batch Products');
  }
  const CancelToken = axios.CancelToken;
  masterRM = CancelToken.source();
  axios
    .get(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchRMPMMasterAvailabilityData/${data.productCode}/RM/${data.vendorNumber}`,
      { cancelToken: masterRM.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: RM_TABLE_DATA,
          payload: res.data.rmpmMasterAvailabilityList,
        });
        dispatch({
          type: MASTER_RM,
          payload: res.data.rmpmMasterAvailabilityList,
        });
      }
    })
    .catch((err) => console.log(err));
};

export let masterPM;
export const getMasterPM = (data) => (dispatch) => {
  if (masterPM !== undefined) {
    masterPM.cancel('Request cancelled for batch Products');
  }
  const CancelToken = axios.CancelToken;
  masterPM = CancelToken.source();
  axios
    .get(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchRMPMMasterAvailabilityData/${data.productCode}/PM/${data.vendorNumber}`,
      { cancelToken: masterPM.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PM_TABLE_DATA,
          payload: res.data.rmpmMasterAvailabilityList,
        });
        dispatch({
          type: MASTER_PM,
          payload: res.data.rmpmMasterAvailabilityList,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const setRMTableData = (data) => (dispatch) => {
  dispatch({
    type: RM_TABLE_DATA,
    payload: data,
  });
};

export const setPMTableData = (data) => (dispatch) => {
  dispatch({
    type: PM_TABLE_DATA,
    payload: data,
  });
};

export const editRMTableData = (data) => (dispatch) => {
  dispatch({
    type: EDIT_RM_TABLE_DATA,
    payload: data,
  });
};

export const editPMTableData = (data) => (dispatch) => {
  dispatch({
    type: EDIT_PM_TABLE_DATA,
    payload: data,
  });
};

export const removeUpdateRMPM = () => (dispatch) => {
  dispatch({
    type: UPDATE_RMPM,
    payload: undefined,
  });
  dispatch({
    type: INITIAL_RM,
    payload: undefined,
  });
  dispatch({
    type: INITIAL_PM,
    payload: undefined,
  });
  dispatch({
    type: RM_TABLE_DATA,
    payload: [],
  });
  dispatch({
    type: PM_TABLE_DATA,
    payload: [],
  });
};

export const addRMPMDataAvailability = (data) => (dispatch) => {
  dispatch({
    type: OPEN_BACKDROP,
    backdropText: 'Please Wait. Updating ....',
  });
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/updateRMPMAvailabilityDetails`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPDATE_RMPM,
          payload: {
            message: 'RMPM availability data added or updated successfully',
            statusCode: 200,
            status: true,
          },
        });
        dispatch({
          type: DESTROY_BACKDROP,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: DESTROY_BACKDROP,
      });
      if (err.response.status === 401 || err.response.status === 400) {
        dispatch({
          type: UPDATE_RMPM,
          payload: err.response.data,
        });
      }
    });
};
export let batchQAStatusSource;
export const getBatchQAStatus = (data) => (dispatch) => {
  // if (batchTableSource !== undefined) {
  //   batchTableSource.cancel('Request cancelled')
  // }

  const CancelToken = axios.CancelToken;
  batchStatusSource = CancelToken.source();

  axios
    .get(`${smlUrl}/user/getAllFgQaStatus`, {
      cancelToken: batchStatusSource.token,
    })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ALL_QA_STATUS,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => {
      console.log(err); 
      if (axios.isCancel(err)) {
        console.log('request cancelled for filter api', err);
      }
    });
};

export const requestQAApproval =
  (data, isApproved, isReversed) => (dispatch) => {
    if (isApproved) {
      dispatch({
        type: OPEN_BACKDROP,
        backdropText: `Please don't refresh or close the tab while processing...`,
      });
    } else if (isReversed) {
      dispatch({
        type: OPEN_BACKDROP,
        backdropText: `Please don't refresh or close the tab while processing...`,
      });
    } else {
      dispatch({
        type: OPEN_BACKDROP,
        backdropText: 'Please Wait. Rejecting the record....',
      });
    }

    axios
      .post(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/approveQAForDSBatch`,
        data
      )
      .then((res) => {
        dispatch({
          type: QA_APPROVAL,
          payload: res.data,
        });

        dispatch({
          type: DESTROY_BACKDROP,
        });
      })
      .catch((err) => {
        console.log(err.response);
        dispatch({
          type: DESTROY_BACKDROP,
        });
        if (err.response.status === 400) {
          dispatch({
            type: QA_APPROVAL,
            payload: err.response.data,
          });
          // console.log(err.response)
        }
      });
  };
