export const styles = (theme) => ({
  headerGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#F7F7F7',
  },
  root: {
    flexGrow: 1,
    boxShadow: 'none',
  },
  paperHidden: {
    height: '48px',
    borderRadius: 0,
    borderBottom: '1px solid #A0A0A0',
    backgroundColor: '#F7F7F7',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  userInvite: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  inviteText: {
    textDecoration: 'underline',
    textDecorationColor: '#e36e39',
    marginLeft: '4px',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      padding: '0.5rem',
    },
  },
  join: {
    textAlign: 'center',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'start',
      padding: '0.5rem',
    },
  },
  closeText: {
    marginRight: '2rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  alignEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      padding: '0.5rem',
    },
  },
  //overwrite indicator color
  indicator: {
    backgroundColor: '#FFFFFF',
  },
  //overwrite selected tab style
  selected: {
    border: '1px solid #A0A0A0',
    backgroundColor: '#FFFFFF',
  },
  tabsOutline: {
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #A0A0A0',
  },
  tabText: {
    textTransform: 'none',
    fontSize: '1rem',
  },
  textPadding: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  setBorder: {
    [theme.breakpoints.up('md')]: {
      borderRight: '1px solid  #A0A0A0',
    },
  },
  inviteList: {
    padding: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
});
