import React from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 'xxx-large',
    color: 'green',
  },
  closeBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const SendForReviewResponse = (props) => {
  const classes = useStyles();
  const { openDialog, goToDashboard } = props;

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='sm'
    >
      <Box textAlign='center'>
        <Box marginTop='3rem'>
          <CheckCircleIcon className={classes.icon} />
        </Box>
        <Box marginTop='1rem'>
          <Typography
            variant='h5'
            style={{ fontWeight: 'bold', marginBottom: '1rem' }}
          >
            {props.updateEventType === 'deleteRecord'
              ? 'Deleted Successfully'
              : 'Successfully Saved'}
          </Typography>
          <Typography variant='body2'>
            {props.updateEventType === 'deleteRecord'
              ? 'The record has been deleted'
              : props.updateEventType === 'saveRecord'
              ? 'The record has been updated'
              : 'Event has been processed for under review'}
          </Typography>
        </Box>

        <Box margin='auto' marginTop='2rem' marginBottom='2rem'>
          <Button
            className={classes.closeBtn}
            variant='contained'
            color='primary'
            onClick={goToDashboard}
          >
            ok
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
export default SendForReviewResponse;
