import React from 'react';
import Button from '@material-ui/core/Button';
import POSelector from '../../../../common/selectors/POSelector';
import LineSelector from '../../../../common/selectors/LineSelector';
import BatchSelector from '../../../../common/selectors/BatchSelector';
import Grid from '@material-ui/core/Grid';

const SelectionBar = (props) => {
  return (
    <Grid>
      <div
        style={{
          // margin: '0rem -1.3rem 0rem -1.8rem',
          margin: '0px -16px 0 -16px',
          backgroundColor: '#F0F0F0',
          display: 'flex',
          alignItems: 'baseline',
          flexDirection: 'row',
          padding: '0rem 0.5rem 0.75rem 0.5rem',
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems='center'
          style={{ marginLeft: '0rem' }}
        >
          <Grid item xs={6} sm={3} md={3} lg={3}>
            <POSelector
              handleChange={props.handleChange}
              purchaseOrderNumber={props.purchaseOrderNumber}
              poError={props.poError}
              needFullWidth={true}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3}>
            <LineSelector
              handleChange={props.handleChange}
              purchaseOrderLineNumber={props.purchaseOrderLineNumber}
              purchaseOrderNumber={props.purchaseOrderNumber}
              needFullWidth={true}
              open={props.open}
              handleOpen={props.handleOpen}
              handleClose={props.handleClose}
              lineNumbers={props.lineNumbers}
              lineSelectDisabled={props.lineSelectDisabled}
              lineError={props.lineError}
            />
          </Grid> 
          <Grid item xs={6} sm={3} md={3} lg={3}>
            <BatchSelector
              handleChange={props.handleChange}
              batchSelectOpen={props.batchSelectOpen}
              needFullWidth={true}
              handleBatchSelectOpen={props.handleBatchSelectOpen}
              handleBatchSelectClose={props.handleBatchSelectClose}
              batchNumbers={props.batchNumbers}
              batchNumber={props.batchNumber}
              batchSelectDisabled={props.batchSelectDisabled}
              batchError={props.batchError}
            />
          </Grid>
          {/* <DateCustomRangeSelector /> */}
          <Grid item xs={6} sm={3} md={3} lg={3}>
            <Button
              variant='contained'
              onClick={props.fetchReport}
              color='secondary'
              style={{
                // marginLeft: '2rem',
                paddingBottom: '0.25rem',
              }}
            >
              View Report
            </Button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default SelectionBar;
