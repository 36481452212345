import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';
import moment from 'moment';
//Import custom table cell
import CustomTableCell from '../models/table/CustomTableCell';
import {
  presentDate,
  presentFormatedData,
} from '../functionalComponents/dataFormatFunctions';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  table: {
    overflowY: 'scroll',
    [theme.breakpoints.up('md')]: {
      overflowX: 'none',
    },
  },
  maintitle: {
    fontWeight: 500,
    fontSize: '1.2rem',
    textAlign: 'center',
    paddingBottom: '0.5rem',
  },
  child: {
    paddingTop: '4rem',
  },
  font: {
    fontWeight: 500,
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
  },
  fontPadding: {
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
    overflow: 'auto',
  },
  button: {
    backgroundColor: 'transparent',
    textAlign: 'end',
    paddingBottom: '2rem',
    paddingTop: '2rem',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'initial',
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'end',
      paddingTop: '2rem',
    },
  },
  grid: {
    paddingBottom: '2rem',
    paddingRight: '1.5rem',
    paddingTop: '2rem',
  },
  button2: {
    backgroundColor: 'transparent',
    textAlign: 'end',
    paddingBottom: '2rem',
    paddingTop: '2rem',
    [theme.breakpoints.up('sm')]: {
      // textAlign: 'initial',
      display: 'none',
    },
  },
  scrollContainer: {
    overflowX: 'scroll',
  },
}));

export default function POItemTable({ children, ...props }) {
  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD/MMM/YYYY';

  const classes = useStyles();
  const scheduleDateFlag = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.features
        .scheduledDeliveryDate;
    }
    return false;
  });

  const lorryReceiptDateEnabled = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.features
        .lorryReceiptDateEnabled;
    }
    return false;
  });

  const {
    errors,
    handleOpenDialog,
    isRecievedExits,
    isTableExists,
    POLineRecordDetails,
    POBatchRecordDetails,
  } = props;

  const { minShelfLife, shelfLifeUnitIndicator } = props.POLineRecordDetails;

  return (
    <React.Fragment>
      <Typography variant='h4' className={classes.maintitle}>
        {/* PO ITEM DETAILS */}
        {props.maintitle}
      </Typography>
      <TableContainer className={classes.scrollContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>PO/STO No.</TableCell>
              <TableCell>PO Date</TableCell>
              {scheduleDateFlag && <TableCell>Sch. Delivery Date</TableCell>}
              <TableCell>Material type</TableCell>

              {isTableExists ? (
                <TableCell>Material Code / Description</TableCell>
              ) : (
                <TableCell>Material Name / Description</TableCell>
              )}
              {isTableExists ? null : <TableCell>Code</TableCell>}
              <TableCell>UOM</TableCell>
              <TableCell align='right'>Quantity</TableCell>
              <TableCell>Delivery Tolerance</TableCell>
              <TableCell>Shelf Life</TableCell>
              <TableCell>Supplier Name</TableCell>

              {/* <TableCell>Vendor Address</TableCell> */}
              {isTableExists ? <TableCell>Current Status</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ border: '2px solid #C6C6C6' }}>
              <CustomTableCell align=''>
                {presentFormatedData(
                  POLineRecordDetails.supplierPurchaseOrderNumber,
                  '-'
                )}
              </CustomTableCell>
              <CustomTableCell>
                {presentDate(POLineRecordDetails.supplierPODate, '-')}
              </CustomTableCell>
              {scheduleDateFlag && (
                <CustomTableCell>
                  {presentDate(POLineRecordDetails.scheduledDeliveryDate, 'NA')}
                </CustomTableCell>
              )}
              <CustomTableCell>
                {POLineRecordDetails.materialType === 'ROH'
                  ? 'RM'
                  : POLineRecordDetails.materialType === 'VERP'
                  ? 'PM'
                  : POLineRecordDetails.materialType}
              </CustomTableCell>

              {isTableExists ? (
                <CustomTableCell>{`${POLineRecordDetails.materialCode} /${''}
                      ${POLineRecordDetails.materialName}`}</CustomTableCell>
              ) : (
                <CustomTableCell>
                  {POLineRecordDetails.materialName}
                </CustomTableCell>
              )}

              {isTableExists ? null : (
                <CustomTableCell>
                  {POLineRecordDetails.materialCode}
                </CustomTableCell>
              )}
              <CustomTableCell>
                {POLineRecordDetails.unitOfMeasurementOrdered}
              </CustomTableCell>
              <CustomTableCell>
                {POLineRecordDetails.quantityOrdered}
              </CustomTableCell>
              <CustomTableCell>
                {POLineRecordDetails.overDeliveryTolerance === '' ||
                POLineRecordDetails.overDeliveryTolerance === null ||
                POLineRecordDetails.overDeliveryTolerance === undefined
                  ? '-'
                  : POLineRecordDetails.overDeliveryTolerance}
              </CustomTableCell>
              <CustomTableCell>
                {minShelfLife
                  ? `${presentFormatedData(
                      minShelfLife
                    )}${' '}${presentFormatedData(shelfLifeUnitIndicator)}`
                  : 'NA'}
              </CustomTableCell>
              <CustomTableCell>
                {POLineRecordDetails.supplierName}
              </CustomTableCell>

              {/* <CustomTableCell>{POLineRecordDetails.vendorAddress}</CustomTableCell> */}
              {isTableExists ? (
                <CustomTableCell>
                  {POBatchRecordDetails.batchStatusText}
                </CustomTableCell>
              ) : null}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {isRecievedExits ? (
        <Grid container>
          {localStorage.getItem('collabType') !== 'Pharma Co' &&
          (props.status !== 3 || props.status === 13) ? (
            <Grid item xs={12} sm={4} md={8} className={classes.button2}>
              <Button
                variant='contained'
                startIcon={<CreateIcon fontSize='small' />}
                style={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  border: '1px solid #000000de',
                }}
                size='small'
                onClick={() => handleOpenDialog('openPOItem')}
              >
                Edit
              </Button>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6} md={3} className={classes.grid}>
            <Typography variant='body2' className={classes.font}>
              MRN Number
            </Typography>
            {POBatchRecordDetails.numberMRN === '' ||
            POBatchRecordDetails.numberMRN === null ? (
              <Typography
                variant='body2'
                className={classes.fontPadding}
                color='error'
              >
                -Not provided-
              </Typography>
            ) : (
              <Typography
                variant='body2'
                className={classes.fontPadding}
                color={errors.numberGRN ? 'error' : 'inherit'}
              >
                {POBatchRecordDetails.numberMRN}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3} className={classes.grid}>
            <Typography variant='body2' className={classes.font}>
              Date material was received
            </Typography>

            {POBatchRecordDetails.materialReceivedDate === null ? (
              <Typography
                variant='body2'
                className={classes.fontPadding}
                color='error'
              >
                -Not provided-
              </Typography>
            ) : (
              <Typography
                variant='body2'
                className={classes.fontPadding}
                color={errors.materialReceivedDate ? 'error' : 'inherit'}
              >
                {moment(POBatchRecordDetails.materialReceivedDate).format(
                  dateFormat
                )}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3} className={classes.grid}>
            <Typography variant='body2' className={classes.font}>
              Transporter Name (optional)
            </Typography>
            <Typography variant='body2' className={classes.fontPadding}>
              {POBatchRecordDetails.transporterName === ''
                ? '-Not Provided-'
                : POBatchRecordDetails.transporterName}
            </Typography>
          </Grid>

          {localStorage.getItem('collabType') !== 'Pharma Co' &&
          props.status !== 3 ? (
            <Grid item xs={12} sm={6} md={3} className={classes.button}>
              <Button
                variant='contained'
                startIcon={<CreateIcon fontSize='small' />}
                style={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  border: '1px solid #000000de',
                }}
                size='small'
                onClick={() => handleOpenDialog('openPOItem')}
              >
                Edit
              </Button>
            </Grid>
          ) : null}

          <Grid item xs={12} sm={8} md={3} className={classes.grid}>
            <Typography variant='body2' className={classes.font}>
              Lorry Receipt (LR) Number (optional)
            </Typography>
            <Typography variant='body2' className={classes.fontPadding}>
              {presentFormatedData(
                POBatchRecordDetails.lorryReceiptNumber,
                '-Not Provided-'
              )}
            </Typography>
          </Grid>
          {lorryReceiptDateEnabled && (
            <Grid item xs={12} sm={8} md={3} className={classes.grid}>
              <Typography variant='body2' className={classes.font}>
                Lorry Receipt (LR) Date (optional)
              </Typography>
              <Typography variant='body2' className={classes.fontPadding}>
                {presentDate(
                  POBatchRecordDetails.lorryReceiptDate,
                  '-Not Provided-'
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      ) : null}
    </React.Fragment>
  );
}
