import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Box,
  Button,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import CapaRequired from './CapaRequired';
import CloseEvent from './CloseEvent';
import Logo from '../../Logo';

import { updateQualityEventData } from '../../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    fontWeight: 500,
    margin: '2rem 0rem 0.5rem 0rem',
    fontWeight: 'bold',
  },
  hrLineTitle: {
    width: '3rem',
    height: '3px',
    border: 0,
    backgroundColor: 'orange',
  },
  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '3px',
    float: 'left',
    border: 0,
    marginTop: '0.2rem',
  },
  common: {
    textAlign: 'left',
    fontWeight: 500,
    fontSize: '0.9rem',
  },
  backBtn: {
    height: '2rem',
    textTransform: 'none',
  },
  hr: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  createBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    borderRadius: '2.5rem',
    backgroundColor: '#ff5500',
    textTransform: 'capitalize',
  },
  saveBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    borderRadius: '2.5rem',
    backgroundColor: 'white',
    color: 'black',
    textTransform: 'capitalize',
  },
  deleteIcon: {
    fontSize: 'medium',
    color: 'red',
  },
  textBorder: {
    border: '2px solid grey',
    padding: '7px',
    margin: 'auto',
  },
  borderBox: {
    border: '2px solid grey',
    padding: '1rem',
    margin: 'auto',
    marginTop: '2rem',
  },
  deleteBtn: {
    color: 'red',
    border: '1px solid black',
    textTransform: 'none',
    borderRadius: '2rem',
    paddingLeft: '12px',
  },
}));

const Resolve = (props) => {
  const classes = useStyles();
  const { toggleDialogScreen, stateData, handleChangeData } = props;
  console.log(stateData, 'stateData');

  const [state, setState] = useState({
    accepted: false,
    loading: false,
  });

  const handleCheckBox = (event) => {
    setState((prevState) => ({
      ...prevState,
      accepted: !state.accepted,
    }));
  };

  const handleLoadingScreen = () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
  };

  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD/MM/YYYY';

  return (
    <div style={{ position: 'relative' }}>
      {state.loading && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            width: '100%',
            backdropFilter: 'blur(0.8px)',
            zIndex: 9,
            top: 0,
          }}
        >
          <CircularProgress style={{ marginTop: '70%' }} thickness='5' />
        </div>
      )}
      <Box width='50%' margin='auto'>
        <Box textAlign='center'>
          <Typography variant='h5' className={classes.title}>
            Quick View
          </Typography>
          <hr className={classes.hrLineTitle} />
        </Box>
        <Box className={classes.borderBox}>
          <Box marginBottom='0.5rem'>
            <Typography variant='body1' style={{ fontWeight: 'bold' }}>
              EVENT DETAILS
            </Typography>
            <hr className={classes.hrLine} />
          </Box>
          <Box width='100%' display='flex' justifyContent='space-between'>
            <Box>
              <Typography className={classes.common}>Event ID</Typography>
              <Typography style={{ fontSize: '0.9rem' }}>
                {stateData.eventID}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.common}>Event Type</Typography>
              <Typography style={{ fontSize: '0.9rem' }}>
                {stateData.eventType}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.common}>Raised By</Typography>
              <Typography style={{ fontSize: '0.9rem' }}>
                {stateData.incidentRaisedBy}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.common}>Raised For</Typography>
              <Typography style={{ fontSize: '0.9rem' }}>
                {stateData.CMOMember}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box margin='auto' marginTop='3rem'>
          <Box
            display='flex'
            justifyContent='space-between'
            marginBottom='2.5rem'
          >
            <Box>
              <Typography variant='h5' className={classes.subTitle}>
                {stateData.eventType[0].toUpperCase() +
                  stateData.eventType.slice(1)}{' '}
                Details
              </Typography>
              <hr className={classes.hrLine} />
            </Box>
          </Box>
          <Box>
            <Typography className={classes.common}>Title</Typography>
            <Typography>{stateData.eventTitle}</Typography>
          </Box>
          <Box marginTop='1.5rem'>
            <Typography className={classes.common}>Description</Typography>
            <Typography>{stateData.eventDescription}</Typography>
          </Box>

          <Box
            width='93%'
            marginTop='1.5rem'
            display='flex'
            justifyContent='space-between'
          >
            <Box>
              <Typography className={classes.common}>
                Date event occurred
              </Typography>
              <Typography>
                {moment(stateData.eventOccurredDate).format('DD-MM-YYYY')}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.common}>
                Date event reported
              </Typography>
              <Typography>
                {moment(stateData.eventReportedDate).format('DD-MM-YYYY')}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.common}>Reported By</Typography>
              <Typography
                style={{
                  color: 'dodgerblue',
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    width: '2rem',
                    textAlign: 'center',
                  }}
                >
                  <Logo name={stateData.selectedReportedBy?.name} />
                </div>
                <u style={{ marginLeft: '1rem' }}>
                  {stateData.selectedReportedBy?.name}
                </u>
              </Typography>
            </Box>
          </Box>
        </Box>

        <hr className={classes.hr} />

        <Box margin='auto' marginTop='3rem'>
          <Box
            display='flex'
            justifyContent='space-between'
            marginBottom='2.5rem'
          >
            <Box>
              <Typography variant='h5' className={classes.subTitle}>
                Facility and location details
              </Typography>
              <hr className={classes.hrLine} />
            </Box>
          </Box>
          <Box width='80%' display='flex' justifyContent='space-between'>
            <Box>
              <Typography className={classes.common}>Facility</Typography>
              <Typography>
                {stateData.selectedFacility?.FacilityName}
              </Typography>
            </Box>
            <Box width='50%'>
              <Typography className={classes.common}>Location</Typography>
              <Typography>
                {stateData.selectedLocation?.locationName}
              </Typography>
            </Box>
          </Box>
        </Box>

        <hr className={classes.hr} />

        <Box margin='auto' marginTop='3rem'>
          <Box
            display='flex'
            justifyContent='space-between'
            marginBottom='2.5rem'
          >
            <Box>
              <Typography variant='h5' className={classes.subTitle}>
                Affected product and batches
              </Typography>
              <hr className={classes.hrLine} />
            </Box>
          </Box>
          <Box width='80%' display='flex' justifyContent='space-between'>
            <Box>
              <Typography className={classes.common}>
                Product effected
              </Typography>
              <Typography>
                {stateData.selectedProduct?.productCode}/
                {stateData.selectedProduct?.productDescription}
              </Typography>
            </Box>
            <Box width='50%'>
              <Typography className={classes.common}>Batch effected</Typography>
              <Typography>
                {stateData.selectedBatches.map((batch, i) => (
                  <p>{batch.batchNumber}</p>
                ))}
              </Typography>
            </Box>
          </Box>
        </Box>

        <hr className={classes.hr} />

        <Box width='100%' margin='auto' marginTop='3rem'>
          <Box marginTop='3rem'>
            <Box
              display='flex'
              justifyContent='space-between'
              marginBottom='2.5rem'
            >
              <Box>
                <Typography variant='h5' className={classes.subTitle}>
                  Upload related documents
                </Typography>
                <hr className={classes.hrLine} />
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography className={classes.common}>
              Upload documents list
            </Typography>
            {stateData.qeCustomFiles.map((qeCustomFiles, i) => (
              <Typography
                className={classes.previewData}
                style={{ color: 'dodgerblue' }}
                key={i}
              >
                <u>{qeCustomFiles.qeFileName}</u>
              </Typography>
            ))}
          </Box>
        </Box>

        <hr className={classes.hr} />

        <Box margin='auto' marginTop='3rem'>
          <Box
            display='flex'
            justifyContent='space-between'
            marginBottom='2.5rem'
          >
            <Box>
              <Typography variant='h5' className={classes.subTitle}>
                Resolution and action
              </Typography>
              <hr className={classes.hrLine} />
            </Box>
          </Box>

          <hr className={classes.hr} />

          <Box width='80%' display='flex' justifyContent='space-between'>
            <Box>
              <Typography className={classes.common}>
                Description of action
              </Typography>
              <Typography>{stateData.resolutionDescription}</Typography>
            </Box>
            <Box width='50%'>
              <Typography className={classes.common}>
                Estimate resolution date
              </Typography>
              <Typography>
                {moment(stateData.estimatedResolutionDate).format(dateFormat)}
              </Typography>
            </Box>
          </Box>
        </Box>

        <hr className={classes.hr} />

        <Box margin='auto' marginTop='3rem'>
          <Box
            display='flex'
            justifyContent='space-between'
            marginBottom='2.5rem'
          >
            <Box>
              <Typography variant='h5' className={classes.subTitle}>
                Risk Assessment
              </Typography>
              <hr className={classes.hrLine} />
            </Box>
            <Box display='flex'>
              <Button
                variant='outlined'
                className={classes.backBtn}
                startIcon={<EditIcon />}
                onClick={() => toggleDialogScreen('riskAssesmentScreen')}
              >
                Edit
              </Button>
            </Box>
          </Box>
          <Box width='88%' display='flex' justifyContent='space-between'>
            <Box>
              <Typography className={classes.common}>Impact</Typography>
              <Typography>{stateData.selectedImpact?.impactType}</Typography>
            </Box>
            <Box>
              <Typography className={classes.common}>Frequency</Typography>
              <Typography>
                {stateData.selectedFrequency?.frequencyType}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.common}>Priority</Typography>
              <Typography>{stateData.priority}</Typography>
            </Box>
          </Box>
        </Box>

        <hr className={classes.hr} />

        <Box margin='auto' marginTop='3rem' marginBottom='2rem'>
          <Box
            display='flex'
            justifyContent='space-between'
            marginBottom='2.5rem'
          >
            <Box>
              <Typography variant='h5' className={classes.subTitle}>
                Assign team for investigation
              </Typography>
              <hr className={classes.hrLine} />
            </Box>

            <Box display='flex'>
              <Button
                variant='outlined'
                className={classes.backBtn}
                startIcon={<EditIcon />}
                onClick={() => toggleDialogScreen('assignTeamScreen')}
              >
                Edit
              </Button>
            </Box>
          </Box>

          <Box width='80%' display='flex' justifyContent='space-between'>
            <Box>
              <Typography className={classes.common}>
                Quality assurance team lead
              </Typography>
              <Typography
                style={{
                  color: 'dodgerblue',
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    width: '2rem',
                    textAlign: 'center',
                  }}
                >
                  <Logo name={stateData.selectedTeamLead?.userName} />
                </div>
                <u style={{ marginLeft: '1rem' }}>
                  {stateData.selectedTeamLead?.userName}
                </u>
              </Typography>
            </Box>
            <Box width='50%'>
              <Typography className={classes.common}>
                Quality assurance team member
                {stateData.selectedTeamMembers.map((member, i) => (
                  <Typography style={{ color: 'dodgerblue', display: 'flex' }}>
                    <div
                      style={{
                        alignItems: 'center',
                        width: '2rem',
                        textAlign: 'center',
                      }}
                    >
                      <Logo name={member?.userName} />
                    </div>
                    <u style={{ marginLeft: '1rem', marginBottom: '0.5rem' }}>
                      {member?.userName}
                    </u>
                  </Typography>
                ))}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box margin='auto' textAlign='center' marginTop='3rem'>
          <Typography className={classes.textBorder}>
            <FormControlLabel
              value='end'
              control={
                <Checkbox
                  color='primary'
                  onChange={handleCheckBox}
                  name='accepted'
                  checked={stateData.accepted}
                />
              }
            />
            I verify that all the details in this record are correct.(Please
            note you can not change the submit details later)
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' marginBottom='5rem'>
          <CapaRequired
            handleLoadingScreen={handleLoadingScreen}
            handleChangeData={handleChangeData}
            stateData={stateData}
            accepted={state.accepted}
          />
          <CloseEvent
            stateData={stateData}
            accepted={state.accepted}
            handleChangeData={handleChangeData}
          />
        </Box>
      </Box>
    </div>
  );
};

export default connect(null, { updateQualityEventData })(Resolve);
