export const header = [
  {
    id: 'scheduleAgreementNumber',
    numeric: true,
    disablePadding: false,
    label: 'PO Number',
    varName: 'scheduleAgreementNumber',
    align: 'left',
    // width: '7%',
    isSortEnable: true,
  },
  {
    id: 'scheduledDeliveryMonth',
    numeric: true,
    disablePadding: false,
    label: 'Scheduled Month',
    varName: 'scheduledDeliveryMonth',
    align: 'left',
    // width: '3%',
    isSortEnable: true,
  },
  {
    id: 'deliveryDate',
    numeric: true,
    disablePadding: false,
    label: 'Delivery Date',
    varName: 'deliveryDate',
    align: 'left',
    // width: '5%',
    isSortEnable: true,
  },

  {
    id: 'scheduleLineItemNumber',
    numeric: false,
    disablePadding: false,
    label: 'Item',
    align: 'right',
    varName: 'scheduleLineItemNumber',
    // width: '0%',
    isSortEnable: true,
  },
  {
    id: 'fgMaterialCode',
    numeric: true,
    disablePadding: false,
    label: 'Material Code/ Description',
    varName: 'fgMaterialCode',
    // width: '5%',
    isSortEnable: true,
  },
  {
    id: 'deliveryScheduleUOM',
    numeric: true,
    disablePadding: false,
    label: 'UOM',
    varName: 'deliveryScheduleUOM',
    // width: '0%',
    isSortEnable: true,
  },

  {
    id: 'openTargetQuantity',
    numeric: false,
    disablePadding: false,
    label: 'Open Qty.',
    varName: 'openTargetQuantity',
    align: 'right',
    // width: '4%',
    isSortEnable: true,
  },
  {
    id: 'scheduledQuantity',
    numeric: false,
    disablePadding: false,
    label: 'Scheduled Qty.',
    varName: 'scheduledQuantity',
    align: 'right',
    // width: '3%',
    isSortEnable: true,
  },
  // {
  //   id: '15',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Batch (s)',
  //   varName: 'totalNumberOfBatches',
  // },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    varName: 'status',
    // width: '9%',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Actions',
    varName: 'actions',
    // width: '6%',
  },
];

export const BOMheader = [
  {
    id: 'materialNumber',
    numeric: true,
    disablePadding: false,
    label: 'Material Number ',
    varName: 'BomComponentCode',
  },
  {
    id: 'materialShortText',
    numeric: true,
    disablePadding: false,
    label: 'Material Name',
    varName: 'BomComponentName',
  },

  {
    id: 'unitOfMeasurement',
    numeric: true,
    disablePadding: false,
    label: 'Unit of Measurement (UOM)',
    varName: 'BomComponentRefUOM',
  },
  {
    id: 'consumedQuantity',
    numeric: true,
    disablePadding: false,
    label: 'Standard Quantity',
    varName: 'BomComponentRefQuantity',
  },
];

export const SAheader = (deliveryDate) => {
  let header = [
    {
      id: 'scheduleLineItemNumber',
      align: 'right',
      disablePadding: false,
      label: 'Item ',
      varName: 'scheduleLineItemNumber',
    },
    {
      id: 'fgMaterialCode',
      align: 'right',
      disablePadding: false,
      label: 'Material Number',
      varName: 'fgMaterialCode',
    },
    {
      id: 'fgMaterialName',
      align: 'center',
      disablePadding: false,
      label: 'Material Short text',
      varName: 'fgMaterialName',
    },

    {
      id: 'targetQuantityUOM',
      align: 'center',
      disablePadding: false,
      label: 'Unit of Measurement',
      varName: 'targetQuantityUOM',
    },
    {
      id: 'targetQuantity',
      align: 'right',
      disablePadding: false,
      label: 'Target Quantity',
      varName: 'targetQuantity',
    },
    {
      id: 'openTargetQuantity',
      align: 'right',
      disablePadding: false,
      label: 'Open Target Quantity',
      varName: 'openTargetQuantity',
    },

    {
      id: 'materialGroup',
      align: 'center',
      disablePadding: false,
      label: 'Material Group',
      varName: 'fgMaterialGroup',
    },
  ];
  if (deliveryDate) {
    header.push({
      id: 'deliveryDate',
      align: 'center',
      disablePadding: false,
      label: 'Delivery Date',
      varName: 'deliveryDate',
    });
    return header;
  } else {
    return header;
  }
};

export const ViewConsumptionHeader = [
  {
    id: 'materialNumber',
    numeric: true,
    disablePadding: false,
    align: 'left',
    label: 'Material Number',
    varName: 'BomComponentCode',
  },
  {
    id: 'materialShortText',
    numeric: true,
    disablePadding: false,
    align: 'left',
    label: 'Material Short Text',
    varName: 'BomComponentName',
  },
  {
    id: 'UOM',
    numeric: true,
    disablePadding: false,
    align: 'left',
    label: 'UOM',
    varName: 'BomComponentRefUOM ',
  },
  {
    id: 'batchNumber',
    numeric: true,
    disablePadding: false,
    align: 'left',
    label: 'Consumed Quantity',
    varName: 'BomComponentConsumedQuantity ',
  },
  {
    id: 'consumedQuantity',
    numeric: true,
    disablePadding: false,
    align: 'left',
    label: 'Batch Number',
    varName: 'componentBatchNumber',
  },
  // {
  //   id: 'action',
  //   numeric: false,
  //   disablePadding: false,
  //   align: 'center',
  //   label: 'Action',
  //   varName: 'action ',
  // },
];
