/*
 * Copyright - 2021 , Schrocken Inc
 * Author: Harish D
 * -----
 * File Description: Functions that fomat data for display
 */
import moment from 'moment';

const dateFormat = localStorage.selectedDateFormat
  ? localStorage.selectedDateFormat
  : 'DD/MMM/YYYY';

const presentFormatedData = (fieldValue, returnNA) => {
  //  !fieldValue ||
  if ( fieldValue === undefined || fieldValue === '' || fieldValue === null)
    return returnNA ? returnNA : '-';

  return fieldValue;
};

const presentDate = (date, returnNA) => {
  if (!date || date === '' || date === null) return returnNA ? returnNA : '-';
  let isValid = moment(date).isValid();

  return isValid ? moment(date).format(dateFormat) : returnNA
};
export { presentFormatedData, presentDate };
