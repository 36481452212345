/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Neetu
 * -----
 * File Description: CMO ALL Batch Items Screen
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import queryString from 'query-string';

import { Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import BatchItemsTable from '../tables/BatchTable';
import ViewEditBatchItems from '../../batchItems/viewEditBatch';

import { getExecutiveDashboardBatchItemsData } from '../../../../containers/actions/pharmaCo/dashboardActions';

class index extends Component {
  state = {
    batchItems: false,
    batchItem: {},
    openBatchDialog: false,
  };

  componentDidMount() {
    this.getExecBatchItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.batchItems !== this.props.batchItems) {
      this.setState({
        batchItems: nextProps.batchItems.overviewCardTotalBatchDetails,
      });
    }
  }

  getExecBatchItems = () => {
    const { organizationName, roleName } = this.props.match.params;
    const { startCommitDate, endCommitDate } = queryString.parse(
      this.props.location.search
    );
    const data = {
      organizationName: organizationName,
      startCommitDate: startCommitDate === 'null' ? null : startCommitDate,
      endCommitDate: endCommitDate === 'null' ? null : endCommitDate,
      cmoMember: 'All',
      roleName: roleName,
      collabType: 'Pharma Co',
      ...this.props.match.params,
    };
    this.props.getExecutiveDashboardBatchItemsData(data);
  };

  // Batch dialog methods
  openBatchDialog = (ele) => {
    this.setState({ openBatchDialog: true, batchItem: ele });
  };

  toggleViewBatchScreen = () => {
    this.setState({ openBatchDialog: false });
  };

  render() {
    return (
      <Grid>
        <div
          style={{
            // margin: '-1rem -1.3rem 0rem -1.8rem',
            margin: '-16px -16px 0 -16px',
            backgroundColor: '#F5F5F5',
            flexDirection: 'row',
            padding: '1.2rem 0.5rem',
          }}
        >
          <Typography variant='h6' style={{ marginLeft: '3rem' }}>
            Total Batch Items - {this.state.batchItems.length}
          </Typography>
        </div>
        <BatchItemsTable
          batchItems={this.state.batchItems}
          openBatchDialog={this.openBatchDialog}
          refreshList={this.getExecBatchItems}
        />
        <Dialog fullScreen open={this.state.openBatchDialog}>
          <ViewEditBatchItems
            toggleViewBatchScreen={this.toggleViewBatchScreen}
            params={this.props.match.params}
            batchItemDetails={this.state.batchItem}
            collabType='Pharma Co'
            callBackAPIFunction={this.getExecBatchItems}
          />
        </Dialog>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  batchItems: state.pcmp.pharmaCo.dashboard.execBatchItems,
});

export default connect(mapStateToProps, {
  getExecutiveDashboardBatchItemsData,
})(withRouter(index));
