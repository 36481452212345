import React, { Component } from "react";
import { connect } from "react-redux";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MenuItem from "@material-ui/core/MenuItem";

import { Grid, Button, Typography, TextField } from "@material-ui/core";

import { pharmaCoFetchPMAvailability } from "../../../../../containers/actions/pharmaCo/batchItemActions";

class ViewPMAvailability extends Component {
  state = {
    data: []
  };

  componentDidMount() {
    let data = {
      ...this.props.params,
      collabType: "Pharma Co"
    };
    this.props.pharmaCoFetchPMAvailability(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pmAvailable) {
      this.setState({ data: nextProps.pmAvailable });
    }
  }
  render() {
    let rmData = "";
    const { data } = this.state;
    if (data.length > 0) {
      rmData = data.map((ele, i) => (
        <Grid container spacing={1} key={i}>
          <Grid item xs={12} md={2}>
            <TextField
              label="Name"
              name="packagingMaterialName"
              fullWidth
              value={ele.packagingMaterialName}
              // onChange={this.handleChange}
              margin="normal"
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              label="Batch Size"
              name="units"
              fullWidth
              value={ele.units}
              onChange={this.handleChange}
              margin="normal"
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              label="Required Quantity"
              name="requiredQuantity"
              fullWidth
              value={ele.requiredQuantity}
              // onChange={this.handleChange}
              margin="normal"
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              label="Shortage"
              name="shortage"
              fullWidth
              value={ele.shortage}
              onChange={this.handleChange}
              margin="normal"
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              label="Status"
              select
              disabled
              fullWidth
              name="statusCode"
              value={ele.statusCode}
              // onChange={this.handleChange}
              margin="normal"
              variant="outlined"
            >
              <MenuItem value={ele.statusCode}>{ele.statusText}</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              label={
                <Typography variant="caption" style={{ fontSize: "0.65rem" }}>
                  Expected Days for Delivery
                </Typography>
              }
              select
              disabled
              fullWidth
              name="expectedDelivery"
              value={ele.expectedDelivery}
              // onChange={this.handleChange}
              margin="normal"
              variant="outlined"
            >
              <MenuItem value={ele.expectedDelivery}>
                {ele.expectedDelivery}
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
      ));
    } else {
      rmData = (
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} md={8} style={{ textAlign: "center" }}>
            <Typography variant="h6">No Records Available.</Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid
        container
        style={{
          justifyContent: "center"
        }}
      >
        <Grid
          item
          xs={12}
          sm={11}
          style={{
            backgroundColor: "#E7ECEE",
            minHeight: "100vh"
          }}
        >
          <Typography
            variant="h5"
            style={{ textAlign: "center", marginTop: "2rem" }}
          >
            PM Availability Details
          </Typography>
          <Card
            style={{
              margin: "2rem 4rem 0rem 4rem",
              border: "1px solid #ABABAB",
              boxShadow: "none"
            }}
          >
            <CardContent
              style={{
                margin: "1rem",
                marginBottom: 0
              }}
            >
              {rmData}
            </CardContent>
          </Card>

          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              alignItems: "center"
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={this.props.togglePMScreen}
            >
              Back to Line Item
            </Button>

            {/* <Typography
              variant="body1"
              onClick={this.props.togglePMScreen}
              style={{
                paddingLeft: "1rem",
                cursor: "pointer"
              }}
            >
              Close
            </Typography> */}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStatetoProps = state => ({
  pmAvailable: state.pcmp.pharmaCo.batchItems.pmAvailable
});

export default connect(mapStatetoProps, { pharmaCoFetchPMAvailability })(
  ViewPMAvailability
);
