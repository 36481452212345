import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Stepper, Step, StepLabel } from '@material-ui/core';

import usePrevious from '../../../../../../helpers/previousProps';

import PartialSaveScreen from './PartialSaveScreen';
import PartialSaveSuccessScreen from './PartialSaveScuuess';
import NewEventSuccessScreen from './NewEventSuccess';
import EventDetails from './EventDetails';
import EventInformation from './EventInformation';
import AssignEvent from './AssignEvent';
import Review from './Review';

import {
  eventDetailsByID,
  fetchReportedByList,
  fetchImpactDetails,
  fetchFrequencyDetails,
  fetchLocationDetails,
  fetchFacilityDetails,
  fetchProductDetails,
  fetchBatchDetails,
  fetchSupplierDetails,
  fetchQMSData,
} from '../../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '100%',
    padding: '1rem',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
    backgroundColor: '#F5F9FF',
  },
  title: {
    fontWeight: 700,
    lineHeight: '4rem',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
    marginRight: '3rem',
  },

  steeper: {
    width: '40%',
    margin: '0 auto',
    position: 'center',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const CreateNewQualityEvent = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const { openDialog, toggleDialog, eventID } = props;
  const [state, setState] = useState({
    eventData: {},
    partialSaveScreen: false,
    partialSaveSuccessScreen: false,
    newEventSuccessScreen: false,
    eventId: '',
    assignedTo: '',
    activeScreen: 0,
    // CMO Information
    CMOMember: '',
    CMOMemberOrgId: '',
    qualityEvent: '',
    // Screen 1
    mainEventID: eventID,
    eventTitle: '',
    eventDescription: '',
    eventOccurredDate: null,
    eventReportedDate: null,
    selectedReportedBy: '',
    eventStatusID: '',
    eventType: '',
    eventTypeID: '',
    priority: '',
    incidentRaisedBy: '',
    incidentRaisedByOrgID: '',
    // Screen 2 - Event Information
    impactOptions: [],
    selectedImpact: '',
    frequencyOptions: [],
    selectedFrequency: '',
    locationOptions: [],
    selectedLocation: '',
    facilityOptions: [],
    selectedFacility: '',
    productsList: [],
    selectedProduct: '',
    batchOptions: [],
    selectedBatches: [],
    supplierDataList: [],
    supplierNames: '',
    // Assign Events
    teamOptions: [],
    selectedTeamLead: '',
    selectedTeamMembers: [],
    customFiles: [],
    qeCustomFiles: [],
    resolutionDescription: '',
    estimatedResolutionDate: null,
  });

  const handleChangeData = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleScreenChange = (screenNumber) => {
    setState((prevState) => ({
      ...prevState,
      activeScreen: screenNumber,
    }));
  };

  const toggleDialogScreen = (screenName) => {
    setState((prevState) => ({
      ...prevState,
      [screenName]: !state[screenName],
    }));
  };

  const goToDashboard = () => {
    setState((prevState) => ({
      ...prevState,
      partialSaveScreen: false,
      partialSaveSuccessScreen: false,
    }));
    toggleDialog('recordIncompleteDialog');
  };

  const {
    eventDetails,
    reportedByList,
    impactDetails,
    frequencyDetails,
    localtionDetails,
    facilityDetails,
    productsList,
    batchDetails,
    supplierDetails,
    teamMembers,
    partialSaveEvent,
    updateQualityEvent,
  } = props;
  const prevEventDetails = usePrevious(eventDetails);
  const prevReportedByList = usePrevious(reportedByList);
  const prevImpactDetails = usePrevious(impactDetails);
  const prevFrequencyDetails = usePrevious(frequencyDetails);
  const prevLocaltionDetails = usePrevious(localtionDetails);
  const prevFacilityDetails = usePrevious(facilityDetails);
  const prevProductsList = usePrevious(productsList);
  const prevBatchDetails = usePrevious(batchDetails);
  const prevsupplierDetails = usePrevious(supplierDetails);
  const prevTeamMembers = usePrevious(teamMembers);
  const prevPartialSaveEvent = usePrevious(partialSaveEvent);
  const prevUpdateQualityEvent = usePrevious(updateQualityEvent);
  useEffect(() => {
    if (didMountRef.current) {
      if (eventDetails !== prevEventDetails) {
        const {
          qualityEventDetails,
          affectedBatchesList,
          supplierDataList,
          investigatingTeamLeadDetails,
          investigatingTeamList,
          eventReportedByDetails,
        } = eventDetails.qualityEventRecordDetails;
        setState((prevState) => ({
          ...prevState,
          eventData: eventDetails,
          CMOMember: qualityEventDetails?.incidentFor,
          CMOMemberOrgId: qualityEventDetails?.incidentForOrgID,
          incidentRaisedBy: qualityEventDetails?.incidentRaisedBy,
          incidentRaisedByOrgID: qualityEventDetails?.incidentRaisedByOrgID,
          eventTitle: qualityEventDetails?.eventTitle || '',
          eventDescription: qualityEventDetails?.eventDescription || '',
          eventOccurredDate: qualityEventDetails?.eventOccurredDate || null,
          eventReportedDate: qualityEventDetails?.eventReportedDate || null,
          eventType: qualityEventDetails?.eventType || '',
          eventTypeID: qualityEventDetails?.eventTypeID || '',
          priority: qualityEventDetails?.priority || '',

          selectedReportedBy: eventReportedByDetails
            ? {
                name: eventReportedByDetails.reportedByName,
                saasUserID: eventReportedByDetails.reportedBySaasUserID,
                emailID: eventReportedByDetails.reportedByEmailID,
                roleName: eventReportedByDetails.reportedByRoleName,
              }
            : '',
          selectedImpact: qualityEventDetails?.impactText
            ? {
                impactId: qualityEventDetails?.impact,
                impactType: qualityEventDetails?.impactText,
              }
            : '',
          selectedFrequency: qualityEventDetails?.frequencyText
            ? {
                frequencyId: qualityEventDetails?.frequency,
                frequencyType: qualityEventDetails?.frequencyText,
              }
            : '',
          selectedLocation: qualityEventDetails?.location
            ? { locationName: qualityEventDetails?.location }
            : '',
          selectedFacility: qualityEventDetails?.facility
            ? { FacilityName: qualityEventDetails?.facility }
            : '',
          selectedProduct: qualityEventDetails?.productCode
            ? {
                productCode: qualityEventDetails?.productCode,
                productDescription: qualityEventDetails?.productName,
              }
            : '',
          selectedBatches: affectedBatchesList ? affectedBatchesList : [],
          supplierDataList: supplierDataList ? supplierDataList : [],
          selectedTeamLead: investigatingTeamLeadDetails
            ? {
                emailAddress: investigatingTeamLeadDetails.teamLeadEmailID,
                userId: investigatingTeamLeadDetails.teamLeadSaasUserID,
                roleName: investigatingTeamLeadDetails.teamLeadRoleName,
                userName: investigatingTeamLeadDetails.teamLeadName,
              }
            : '',
          resolutionDescription:
            qualityEventDetails?.resolutionDescription || '',
          estimatedResolutionDate:
            qualityEventDetails?.estimatedResolutionDate || null,
          qeCustomFiles: eventDetails.qeCustomFiles || [],
          loading: false,
        }));

        if (supplierDataList) {
          let supplierNames = '';
          supplierDataList.map((supplier) => {
            supplierNames = supplierNames + supplier.supplierName + ', ';
          });
          supplierNames = supplierNames.slice(0, -2);
          setState((prevState) => ({
            ...prevState,
            supplierNames,
          }));
        }

        if (investigatingTeamList) {
          let selectedTeamMembers = [];
          investigatingTeamList.map((teamMember) => {
            const teamMemberObject = {};
            teamMemberObject.userName = teamMember.name || '';
            teamMemberObject.roleName = teamMember.roleName || '';
            teamMemberObject.emailAddress = teamMember.emailID;
            teamMemberObject.userId = teamMember.saasUserID;

            selectedTeamMembers.push(teamMemberObject);
          });
          setState((prevState) => ({
            ...prevState,
            selectedTeamMembers,
          }));
        }

        props.fetchReportedByList();
        props.fetchImpactDetails();
        props.fetchFrequencyDetails();
        props.fetchLocationDetails({
          organizationId: qualityEventDetails?.incidentForOrgID,
        });

        if (
          qualityEventDetails?.productCode &&
          qualityEventDetails?.productName
        ) {
          props.fetchBatchDetails({
            productCode: qualityEventDetails?.productCode,
            productType: qualityEventDetails?.productName,
          });
        }
      }
      if (reportedByList !== prevReportedByList) {
        setState((prevState) => ({
          ...prevState,
          reportedByOptions: reportedByList,
        }));
        // if (state.eventData?.qualityEventDetails?.eventReportedBy) {
        //   const reportedBy = reportedByList.filter(
        //     (item) =>
        //       item.emailID ===
        //       state.eventData?.qualityEventDetails?.eventReportedBy
        //   );
        //   setState((prevState) => ({
        //     ...prevState,
        //     reportedBy: reportedBy[0],
        //     eventData: {
        //       ...state.eventData,
        //       qualityEventDetails: {
        //         ...state.eventData.qualityEventDetails,
        //         eventReportedBy: null
        //       }
        //     }
        //   }));
        // }
      }
      if (impactDetails !== prevImpactDetails) {
        setState((prevState) => ({
          ...prevState,
          impactOptions: impactDetails,
        }));
      }
      if (frequencyDetails !== prevFrequencyDetails) {
        setState((prevState) => ({
          ...prevState,
          frequencyOptions: frequencyDetails,
        }));
      }
      if (localtionDetails !== prevLocaltionDetails) {
        setState((prevState) => ({
          ...prevState,
          locationOptions: localtionDetails,
        }));
      }
      if (facilityDetails !== prevFacilityDetails) {
        setState((prevState) => ({
          ...prevState,
          facilityOptions: facilityDetails,
        }));
      }
      if (productsList !== prevProductsList) {
        setState((prevState) => ({
          ...prevState,
          productsList,
        }));
      }
      if (batchDetails !== prevBatchDetails) {
        setState((prevState) => ({
          ...prevState,
          batchOptions: batchDetails,
        }));
      }
      if (supplierDetails !== prevsupplierDetails) {
        let supplierNames = '';
        supplierDetails.map((supplier) => {
          supplierNames = supplierNames + supplier.supplierName + ', ';
        });
        supplierNames = supplierNames.slice(0, -2);
        setState((prevState) => ({
          ...prevState,
          supplierDataList: supplierDetails,
          supplierNames,
        }));
      }
      if (teamMembers !== prevTeamMembers) {
        setState((prevState) => ({
          ...prevState,
          teamOptions: teamMembers,
        }));
      }
      if (partialSaveEvent !== prevPartialSaveEvent) {
        setState((prevState) => ({
          ...prevState,
          partialSaveScreen: false,
          partialSaveSuccessScreen: true,
          eventId: partialSaveEvent.eventID,
        }));
        props.fetchQMSData({
          viewByCMO: 'all',
          eventTypeID: 'all',
          priority: 'all',
          eventsOccurredFromDate: null,
          eventsOccurredToDate: null,
          searchBy: '',
          viewByStatus: 'all',
          pageNumber: 1,
          pageSize: 5,
        });
      }
      if (updateQualityEvent !== prevUpdateQualityEvent) {
        setState((prevState) => ({
          ...prevState,
          partialSaveScreen: false,
          partialSaveSuccessScreen: false,
          newEventSuccessScreen: true,
          eventId: state.mainEventID,
          assignedTo: updateQualityEvent.assignedTo,
        }));
        props.fetchQMSData({
          viewByCMO: 'all',
          eventTypeID: 'all',
          priority: 'all',
          eventsOccurredFromDate: null,
          eventsOccurredToDate: null,
          searchBy: '',
          viewByStatus: 'all',
          pageNumber: 1,
          pageSize: 5,
        });
      }
    } else {
      props.eventDetailsByID(eventID);
      didMountRef.current = true;
    }
  }, [
    eventDetails,
    prevEventDetails,
    reportedByList,
    prevReportedByList,
    impactDetails,
    prevImpactDetails,
    frequencyDetails,
    prevFrequencyDetails,
    localtionDetails,
    prevLocaltionDetails,
    facilityDetails,
    prevFacilityDetails,
    productsList,
    prevProductsList,
    batchDetails,
    prevBatchDetails,
    supplierDetails,
    prevsupplierDetails,
    teamMembers,
    prevTeamMembers,
    partialSaveEvent,
    prevPartialSaveEvent,
    updateQualityEvent,
    prevUpdateQualityEvent,
  ]);

  return (
    <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <Typography variant='h5' className={classes.title}>
            Add deviation details
          </Typography>
          <div
            className={classes.closeBtn}
            onClick={() => toggleDialogScreen('partialSaveScreen')}
          >
            <Typography
              variant='caption'
              style={{ lineHeight: 'normal', fontWeight: '700' }}
            >
              Cancel
            </Typography>
            <CloseIcon style={{ color: 'red', fontSize: '2.5rem' }} />
          </div>
        </div>
      </div>
      <div style={{ padding: '1rem' }}>
        <Stepper
          alternativeLabel
          activeStep={state.activeScreen}
          style={{
            width: '60%',
            margin: '0 auto',
            padding: '1rem 0 2rem 0',
            justifyContent: 'center',
          }}
        >
          <Step>
            <StepLabel>Event Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Event Information</StepLabel>
          </Step>
          <Step>
            <StepLabel>Assign Event</StepLabel>
          </Step>
          <Step>
            <StepLabel>Review and Create</StepLabel>
          </Step>
        </Stepper>

        {state.activeScreen === 0 && (
          <EventDetails
            handleChangeData={handleChangeData}
            handleScreenChange={handleScreenChange}
            stateData={state}
          />
        )}
        {state.activeScreen === 1 && (
          <EventInformation
            handleChangeData={handleChangeData}
            handleScreenChange={handleScreenChange}
            stateData={state}
          />
        )}

        {state.activeScreen === 2 && (
          <AssignEvent
            handleChangeData={handleChangeData}
            handleScreenChange={handleScreenChange}
            stateData={state}
          />
        )}

        {state.activeScreen === 3 && (
          <Review
            handleChangeData={handleChangeData}
            handleScreenChange={handleScreenChange}
            stateData={state}
          />
        )}
      </div>
      {state.partialSaveScreen && (
        <PartialSaveScreen
          openDialog={state.partialSaveScreen}
          toggleDialogScreen={toggleDialogScreen}
          goToDashboard={goToDashboard}
          stateData={state}
        />
      )}
      {state.partialSaveSuccessScreen && (
        <PartialSaveSuccessScreen
          openDialog={state.partialSaveSuccessScreen}
          eventId={state.eventId}
          goToDashboard={goToDashboard}
        />
      )}
      {state.newEventSuccessScreen && (
        <NewEventSuccessScreen
          openDialog={state.newEventSuccessScreen}
          eventId={state.eventId}
          eventType={state.eventType}
          assignedTo={state.assignedTo}
          goToDashboard={goToDashboard}
        />
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  eventDetails: state.pcmp.common.QMS.fetchEventDetails,
  reportedByList: state.pcmp.common.QMS.fetchReportedByList,
  impactDetails: state.pcmp.common.QMS.fetchImpactDetails,
  frequencyDetails: state.pcmp.common.QMS.fetchFrequencyDetails,
  localtionDetails: state.pcmp.common.QMS.fetchLocationList,
  facilityDetails: state.pcmp.common.QMS.fetchFacilityList,
  productsList: state.pcmp.common.QMS.fetchProductList,
  batchDetails: state.pcmp.common.QMS.fetchBatchList,
  supplierDetails: state.pcmp.common.QMS.fetchSupplierList,
  teamMembers: state.pcmp.common.QMS.fetchTeamMembers,
  partialSaveEvent: state.pcmp.common.QMS.partialSaveEvent,
  addQualityEvent: state.pcmp.common.QMS.addQualityEvent,
  updateQualityEvent: state.pcmp.common.QMS.updateQualityEvent,
});

export default connect(mapStateToProps, {
  eventDetailsByID,
  fetchReportedByList,
  fetchImpactDetails,
  fetchFrequencyDetails,
  fetchLocationDetails,
  fetchFacilityDetails,
  fetchProductDetails,
  fetchBatchDetails,
  fetchSupplierDetails,
  fetchQMSData,
})(CreateNewQualityEvent);
