import { CircularProgress, Dialog, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import DSLineItemDetails from './DSLineItemDetails';
import { useStyles } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  addRMPMDataAvailability,
  editPMTableData,
  editRMTableData,
  getInitialPMMaterials,
  getInitialRMMaterials,
  getMasterPM,
  getMasterRM,
  removeUpdateRMPM,
  setPMTableData,
  setRMTableData,
} from '../../../../../containers/actions/common/saslAction';
import StyledButton from '../../../models/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RMSection from './RMSection';
import PMSection from './PMSection';
import StyledDialog from '../../../../../../common/models/Dialog';
import BackDropLoading from '../../../models/BackDrop';

const RMPMAvailability = (props) => {
  const { schrockenDeliveryScheduleID, scheduleAgreementNumber, vendorNumber } =
    props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = useRef();
  const [rmAvailability, setRMAvailability] = useState(null);
  const [pmAvailability, setPMAvailability] = useState(null);
  const [initialRMPM, setInitialRMPM] = useState(false);
  const [resError, setResError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const DSLineDetails = useSelector(
    (state) => state.pcmp.common.sasl.deliveryHeader.data
  );

  const initialRMMaterials = useSelector(
    (state) => state.pcmp.common.sasl?.initialRawMaterials
  );

  const initialPMMaterials = useSelector(
    (state) => state.pcmp.common.sasl?.initialPackagingMaterials
  );

  const masterRM = useSelector((state) => state.pcmp.common.sasl?.masterRM);

  const masterPM = useSelector((state) => state.pcmp.common.sasl?.masterPM);
  const rmTableData = useSelector(
    (state) => state.pcmp.common.sasl?.rmTableData
  );

  const pmTableData = useSelector(
    (state) => state.pcmp.common.sasl?.pmTableData
  );

  let saveRMPM = useSelector((state) => state.pcmp.common.sasl?.updateRMPM);

  // const getInitialRMPM = useCallback(() => {
  //   let data = {
  //     schrockenDeliveryScheduleID: schrockenDeliveryScheduleID,
  //   };
  //   dispatch(getInitialRMMaterials(data));
  //   dispatch(getInitialPMMaterials(data));
  // }, [dispatch, schrockenDeliveryScheduleID]);

  useEffect(() => {
    let data = {
      schrockenDeliveryScheduleID: schrockenDeliveryScheduleID,
    };
    dispatch(getInitialRMMaterials(data));
    dispatch(getInitialPMMaterials(data));
  }, [dispatch, schrockenDeliveryScheduleID, open]);

  useEffect(() => {
    if (initialRMMaterials && initialPMMaterials) {
      if (initialRMMaterials.length !== 0 || initialPMMaterials.length !== 0) {
        if (initialRMMaterials.length !== 0) {
          dispatch(setRMTableData(initialRMMaterials));
        }
        if (initialPMMaterials.length !== 0) {
          dispatch(setPMTableData(initialPMMaterials));
        }
        setInitialRMPM(false);
        setRMAvailability('no');
        setPMAvailability('no');
      } else {
        setInitialRMPM(true);
        if (localStorage.collabType === 'CMO') {
          let data = {
            productCode: DSLineDetails.fgMaterialCode,
            vendorNumber: vendorNumber,
          };
          dispatch(getMasterRM(data));
          dispatch(getMasterPM(data));
        } else {
          setRMAvailability('yes');
          setPMAvailability('yes');
        }
      }
    }
  }, [
    dispatch,
    DSLineDetails.fgMaterialCode,
    vendorNumber,
    initialRMMaterials,
    initialPMMaterials,
  ]);

  useEffect(() => {
    if (saveRMPM && saveRMPM.status === true) {
      setResError(null);
      setOpen(true);
    }
    if (saveRMPM && saveRMPM.status === false) {
      setResError(saveRMPM?.errorDescription);
      setOpen(false);
    }
  }, [saveRMPM]);

  useEffect(() => {
    if (rmTableData.length !== 0 || pmTableData.length !== 0) {
      setLoading(false);
    }
    if (initialRMPM && localStorage.collabType !== 'CMO') {
      setLoading(false);
    }
  }, [initialRMPM, rmTableData, pmTableData]);

  const handleRMChange = (event) => {
    setRMAvailability(event.target.value);
  };
  const handlePMChange = (event) => {
    setPMAvailability(event.target.value);
  };

  const scrollToTop = () => {
    setTimeout(
      () => ref.current.scrollIntoView({ top: 0, behavior: 'smooth' }),
      100
    );
  };

  const validate = (data, type) => {
    let isValid = true;
    data.forEach((val) => {
      if (val.materialStatusID === 0) {
        if (
          val.expectedDateOfAvailability === null ||
          val.expectedDateOfAvailability === ''
        ) {
          if (val.noOfBatchesShortage < 0) {
            isValid = false;
            setResError(
              'Please enter the expected date of availability and no. of batches facing shortage for materials in shortage.'
            );
            if (type === 'RM') {
              dispatch(
                editRMTableData({
                  ...val,
                  dateError: true,
                  shortageError: true,
                })
              );
            }
            if (type === 'PM') {
              dispatch(
                editPMTableData({
                  ...val,
                  dateError: true,
                  shortageError: true,
                })
              );
            }
          } else {
            isValid = false;
            setResError('Please enter the expected date of availability.');
            if (type === 'RM') {
              dispatch(editRMTableData({ ...val, dateError: true }));
            }
            if (type === 'PM') {
              dispatch(editPMTableData({ ...val, dateError: true }));
            }
          }
        } else if (val.noOfBatchesShortage < 0) {
          isValid = false;
          setResError('Please enter no. of batches facing shortage.');
          if (type === 'RM') {
            dispatch(
              editRMTableData({
                ...val,
                shortageError: true,
              })
            );
          }
          if (type === 'PM') {
            dispatch(
              editPMTableData({
                ...val,
                shortageError: true,
              })
            );
          }
        }
      }
    });
    return isValid;
  };

  const updatedMaterials = (initialMaterials, savingMaterials) => {
    let updatedValues = [];
    for (let val in initialMaterials) {
      for (let res in savingMaterials) {
        if (
          savingMaterials[res].materialCode ===
          initialMaterials[val].materialCode
        ) {
          if (
            savingMaterials[res].materialStatusID !==
            initialMaterials[val].materialStatusID
          ) {
            if (
              savingMaterials[res].materialStatusID !==
                initialMaterials[val].materialStatusID ||
              savingMaterials[res].expectedDateOfAvailability !==
                initialMaterials[val].expectedDateOfAvailability ||
              savingMaterials[res].noOfBatchesShortage !==
                initialMaterials[val].noOfBatchesShortage
            ) {
              updatedValues = [...updatedValues, savingMaterials[res]];
            }
          } else {
            if (
              savingMaterials[res].materialStatusID === 0 &&
              (savingMaterials[res].expectedDateOfAvailability !==
                initialMaterials[val].expectedDateOfAvailability ||
                savingMaterials[res].noOfBatchesShortage !==
                  initialMaterials[val].noOfBatchesShortage)
            ) {
              updatedValues = [...updatedValues, savingMaterials[res]];
            }
          }
        }
      }
    }
    return updatedValues;
  };

  const onSubmit = () => {
    let validRM = !initialRMPM
      ? validate(rmTableData, 'RM')
      : rmAvailability === 'yes'
      ? validate(masterRM, 'RM')
      : validate(rmTableData, 'RM');
    let validPM = !initialRMPM
      ? validate(pmTableData, 'PM')
      : pmAvailability === 'yes'
      ? validate(masterPM, 'PM')
      : validate(pmTableData, 'PM');
    if (validRM && validPM) {
      setResError(null);
      let dataToSave;
      let data;
      if (initialRMPM) {
        if (rmAvailability === 'yes' && pmAvailability === 'yes') {
          dataToSave = masterRM.concat(masterPM);
        } else if (rmAvailability === 'yes' || pmAvailability === 'yes') {
          if (rmAvailability === 'yes')
            dataToSave = masterRM.concat(pmTableData);
          else dataToSave = rmTableData.concat(masterPM);
        } else {
          dataToSave = rmTableData.concat(pmTableData);
        }
      } else {
        let updatedRM = updatedMaterials(initialRMMaterials, rmTableData);
        let updatedPM = updatedMaterials(initialPMMaterials, pmTableData);
        dataToSave = updatedRM.concat(updatedPM);
      }
      data = {
        schrockenDeliveryScheduleID: schrockenDeliveryScheduleID,
        initialLoadFlag: initialRMPM,
        rmpmMaterialList: dataToSave.map((val) => {
          if (val.materialStatusID === 1) {
            return {
              ...val,
              expectedDateOfAvailability: null,
              noOfBatchesShortage: null,
            };
          } else {
            return val;
          }
        }),
      };
      dispatch(addRMPMDataAvailability(data));
    } else {
      scrollToTop();
    }
  };

  const handleCloseSave = () => {
    setOpen(false);
    dispatch(removeUpdateRMPM());
    // handleClose();
  };

  return (
    <React.Fragment>
      {loading === false ? (
        <React.Fragment>
          <BackDropLoading />
          <Grid
            ref={ref}
            container
            className={classes.padding}
            style={{ paddingBottom: '2.5rem' }}
          >
            <DSLineItemDetails
              dsDetails={DSLineDetails}
              scheduleAgreementNumber={scheduleAgreementNumber}
            />
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                padding: '1rem 0',
                display: resError === null ? 'none' : 'inherit',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant='h6'
                style={{
                  border: '1px solid #FF0000',
                  backgroundColor: '#FFF0F0',
                  opacity: '1',
                  textAlign: 'center',
                  padding: '0.8rem',
                  fontSize: '0.875rem',
                }}
              >
                {resError}
              </Typography>
            </Grid>

            {(rmTableData.length !== 0 ||
              (initialRMPM && localStorage.collabType !== 'CMO')) && (
              <RMSection
                batchCount={DSLineDetails.batchCount}
                initialRM={initialRMPM}
                rmAvailability={rmAvailability}
                handleRMChange={handleRMChange}
                rmTableData={
                  !initialRMPM
                    ? rmTableData
                    : rmAvailability === 'yes'
                    ? masterRM
                    : rmTableData
                }
              />
            )}

            {((rmTableData.length !== 0 && pmTableData.length !== 0) ||
              (initialRMPM && localStorage.collabType !== 'CMO')) && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ paddingTop: '1rem' }}
                // className={classes.paddingBottom}
              >
                <span
                  style={{
                    width: '90rem',
                    border: '1px solid #B4B4B4',
                    display: 'flex',
                    backgroundColor: '#B4B4B4',
                  }}
                />
              </Grid>
            )}

            {(pmTableData.length !== 0 ||
              (initialRMPM && localStorage.collabType !== 'CMO')) && (
              <PMSection
                batchCount={DSLineDetails.batchCount}
                initialPM={initialRMPM}
                pmAvailability={pmAvailability}
                handlePMChange={handlePMChange}
                pmTableData={
                  !initialRMPM
                    ? pmTableData
                    : pmAvailability === 'yes'
                    ? masterPM
                    : pmTableData
                }
              />
            )}

            {localStorage.collabType !== 'Pharma Co' &&
            (pmTableData.length !== 0 || rmTableData.length !== 0) ? (
              <Grid container justifyContent='center' className={classes.pdTp}>
                <Grid item>
                  <StyledButton
                    onClick={onSubmit}
                    style={{ width: '10rem' }}
                    endIcon={<ChevronRightIcon />}
                    disabled={
                      !initialRMPM
                        ? false
                        : rmTableData.length !== 0 && pmTableData.length !== 0
                        ? !(rmAvailability !== null && pmAvailability !== null)
                        : !(rmAvailability !== null || pmAvailability !== null)
                    }
                  >
                    Save
                  </StyledButton>
                  {/* CMO Saves the changes  */}
                  <Dialog open={open} fullWidth>
                    <StyledDialog
                      icons={true}
                      handleClose={() => handleCloseSave()}
                      title={'RMPM Availability data saved successfully.'}
                    />
                  </Dialog>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </React.Fragment>
      ) : (
        <Grid container justify='center'>
          <div
            style={{
              textAlign: 'center',
              marginTop: '25%',
              minWidth: '60vw',
            }}
          >
            <CircularProgress color='primary' thickness={7} />
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default RMPMAvailability;
