import React, { Component } from 'react';
import { connect } from 'react-redux';
import Body from '../models/Body';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { viewSLDetails } from '../../../../containers/actions/common/saslAction';
import { SAheader } from './header';
import { presentDate } from '../../functionalComponents/dataFormatFunctions';
import CustomTableHeaderCell from '../../models/table/CustomTableHeaderCell';

const useStyles = (theme) => ({
  padding: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.5rem',
  },
  table: {
    minWidth: 650,
  },
  title: {
    fontSize: '1rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  span: {
    width: '4rem',
    border: '2px solid #B4B4B4',
    display: 'flex',
    backgroundColor: '#B4B4B4',
  },
  paddingBottom: {
    paddingBottom: '1rem',
    paddingRight: '3rem',
  },
  font: {
    fontWeight: 500,
    textAlign: 'initial',
    paddingTop: '1rem',
    paddingBottom: '0.25rem',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  fontPadding: {
    textAlign: 'initial',
    fontSize: '1.5rem',
    fontWeight: '500',
  },
  textfield: {
    paddingTop: '1rem',
  },
  button: {
    padding: '1rem',
    paddingBottom: '3rem',
  },
  addRed: {
    color: 'red',
  },
  labelHeader: {
    flexDirection: 'initial',
    '&:hover': {
      color: 'black',
    },
    '&:active': {
      color: 'black',
    },
  },
});

class ViewScheduleAgreements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
      orderBy: '',
      order: 'asc',
    };
  }

  componentDidMount() {
    let data = {
      schrockenSAID: this.props.schrockenSAID,
      scheduleAgreementNumber: this.props.scheduleAgreementNumber,
    };
    this.props.viewSLDetails(data);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.agreementDetails !== prevProps.agreementDetails &&
      this.props.agreementDetails.status === true
    ) {
      this.setState({
        data: this.props.agreementDetails.data,
        loading: false,
      });
    }
  }

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };

  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  render() {
    const { classes } = this.props;
    const { loading, data, order, orderBy } = this.state;

    return (
      <React.Fragment>
        {loading === true ? (
          <Grid container justify='center' style={{ padding: '10rem' }}>
            <div
              style={{
                textAlign: 'center',
                minWidth: '60vw',
                padding: '7rem',
              }}
            >
              <CircularProgress color='primary' thickness={7} />
            </div>
          </Grid>
        ) : (
          <React.Fragment>
            <Body
              title='Current Status'
              subtitle='-None-'
              isScheduleAgreement={true}
              Details={data}
            />
            <Grid container className={classes.padding}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='body2' className={classes.title}>
                  Agreement details
                </Typography>
                <span className={classes.span}></span>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.textfield}
              >
                <TableContainer>
                  <Table className={classes.table} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        {SAheader(this.props.deliveryDateEnabled).map(
                          (headCell) => (
                            <CustomTableHeaderCell
                              key={headCell.id}
                              className={classes.inherited}
                              style={{
                                verticalAlign: 'top',
                                textAlign: headCell.numeric
                                  ? 'center'
                                  : 'right',
                              }}
                              order={this.state.order}
                              orderBy={this.state.orderBy}
                              cellData={headCell}
                              isSortEnable={true}
                              sortingFunction={(prop) =>
                                this.createSortHandler(prop)
                              }
                            />
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.stableSort(
                        data.scheduleLineDetails,
                        this.getComparator(order, orderBy)
                      ).map((row, i) => (
                        <TableRow key={i}>
                          <TableCell align='right'>
                            {row.scheduleLineItemNumber}
                          </TableCell>
                          <TableCell align='right'>
                            {row.fgMaterialCode}
                          </TableCell>
                          <TableCell align='center'>
                            {row.fgMaterialName}
                          </TableCell>
                          <TableCell align='center'>
                            {row.targetQuantityUOM}
                          </TableCell>
                          <TableCell align='right'>
                            {row.targetQuantity}
                          </TableCell>
                          <TableCell align='right'>
                            {row.openTargetQuantity}
                          </TableCell>
                          <TableCell align='center'>
                            {row.fgMaterialGroup}
                          </TableCell>
                          {this.props.deliveryDateEnabled && (
                            <TableCell align='center'>
                              {presentDate(row.deliveryDate, 'Not Provided')}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  agreementDetails: state.pcmp.common.sasl.slDetails,
  // Features and validations
  deliveryDateEnabled:
    state.pcmp.common.reports.rmpmGrnFeatures?.data.features?.FG.SL
      ?.deliveryDate,
});

export default connect(mapStateToProps, { viewSLDetails })(
  withStyles(useStyles)(ViewScheduleAgreements)
);
