import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AddGRNFetchTable } from '../../../containers/actions/cmo/shortFallsActions';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
//files import
import TableWrapper from '../wrappers/tableWrapper';
import { scheduledHeader } from './modules';
import SingleRow from './SingleRow';
import ExpandableRow from './ExpandableRow';
//Header import
import SupplierPoTableHeader from './SupplierPoTableHeader';

const styles = (theme) => ({
  paper: {
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #C6C6C6',
  },
  table: {
    minWidth: 1200,
    width: '100%',
  },
  hidden: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  inherited: {
    display: 'table-cell',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  labelHeader: {
    flexDirection: 'initial',
    '&:hover': {
      color: 'black',
    },
    '&:active': {
      color: 'black',
    },
  },
});
class SupplierPOsTable extends Component {
  state = {
    supplierPOs: [],
    loading: false,
    selected: [],
    checked: false,
    indeterminate: false,
    orderBy: 'lastUpdatedDate',
    order: 'desc',
    sampleSelect: [],
    totalLength: 0,
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.supplierPOs !== prevProps.supplierPOs) {
      this.setState({
        loading: this.props.loading,
        supplierPOs: this.props.supplierPOs,
      });
      let a = 0;
      this.props.supplierPOs.map((n) => {
        if (n.PODetails.GRDetails.length === 1) {
          return (a += 1);
        } else {
          return (a += n.PODetails.GRDetails.length);
        }
      });
      this.setState({
        totalLength: a,
      });
    }

    if (this.state.selected !== prevState.selected) {
      this.props.getSelectedBatchId(this.state.selected);
    }
  }

  handleChange = (event) => {
    this.setState({
      ...this.state,
      checked: event.target.checked,
    });
    if (event.target.checked) {
      let arr = [];
      let newSelecteds = this.state.supplierPOs.map((n) => {
        if (n.PODetails.GRDetails.length > 1) {
          arr = [].concat(
            ...n.PODetails.GRDetails.map((q) => {
              return q.schrockenSupplierPOBatchID.toString();
            })
          );
          return arr;
        } else {
          return n.PODetails.GRDetails[0].schrockenSupplierPOBatchID.toString();
        }
      });
      newSelecteds = [].concat(...newSelecteds);
      this.setState({
        selected: newSelecteds,
      });
      return;
    }
    this.setState({
      selected: [],
      sampleSelect: [],
    });
  };
  createSortHandler = (sort, property) => (event) => {
    this.handleRequestSort(event, sort, property);
  };
  handleRequestSort = (event, sort, property) => {
    const isAsc =
      this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isAsc ? 'asc' : 'desc',
      orderBy: property,
    });
    this.props.getOrderAndColumn(sort, property);
  };

  selectedArrayValue = (data) => {
    if (Array.isArray(data) === true) {
      data.map((d) => {
        return this.setState((prevState) => ({
          selected: prevState.selected.filter(
            (n, i) => n !== Object.keys(d)[0]
          ),
        }));
      });
    } else {
      if (Object.values(data)[0] === true) {
        if (this.state.selected.indexOf(Object.keys(data)[0]) === -1) {
          this.setState((prevState) => ({
            selected: [...prevState.selected, Object.keys(data)[0]],
          }));
        }
      } else if (Object.values(data)[0] === false) {
        this.setState((prevState) => ({
          selected: this.state.selected.filter(
            (n, i) => n !== Object.keys(data)[0]
          ),
        }));
      }
    }
  };
  render() {
    const { classes } = this.props;
    const { supplierPOs, loading, orderBy, order, totalLength, selected } =
      this.state;
    return (
      <TableWrapper>
        <TableContainer component={Paper} className={classes.paper}>
          <Table className={classes.table} aria-label='collapsible table'>
            <SupplierPoTableHeader
              order={order}
              orderBy={orderBy}
              totalLength={totalLength}
              selected={selected}
              sortingFunction={this.createSortHandler}
              tableLoading={this.props.tableLoading}
              handleChange={this.handleChange}
              scheduleDateFlag={this.state.scheduleDateFlag}
            />

            <TableBody>
              <React.Fragment>
                {(loading === true ||
                  (supplierPOs.length === 0 &&
                    this.props.supplierPOs.length !== 0)) && (
                  <TableRow style={{ height: 150 }}>
                    <TableCell colSpan={12}>
                      <div style={{ textAlign: 'center' }}>
                        <CircularProgress color='primary' thickness={7} />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {loading === false && this.props.supplierPOs.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={scheduledHeader().length}
                      align='center'
                    >
                      No Records Found
                    </TableCell>
                  </TableRow>
                ) : (
                  supplierPOs.map((row, i) => (
                    <Row
                      key={i}
                      row={row}
                      keys={i}
                      status={this.props.status}
                      checked={this.state.checked}
                      selected={this.state.selected}
                      supplierPOs={this.state.supplierPOs}
                      sampleSelect={this.state.sampleSelect}
                      selectedArrayValue={this.selectedArrayValue}
                      grnFeatures={this.props.grnFeatures}
                    />
                  ))
                )}
              </React.Fragment>
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
    );
  }
}

function Row(props) {
  const { PODetails } = props.row;
  const selectedArrayValue = (data) => {
    props.selectedArrayValue(data);
  };
  if (PODetails.GRDetails.length > 1) {
    return (
      <ExpandableRow
        row={PODetails}
        keys={props.keys}
        checked={props.checked}
        selected={props.selected}
        selectedArrayValue={selectedArrayValue}
        grnFeatures={props.grnFeatures}
      />
    );
  } else {
    return (
      <SingleRow
        row={PODetails}
        keys={props.keys}
        status={props.status}
        checked={props.checked}
        selected={props.selected}
        selectedArrayValue={selectedArrayValue}
        grnFeatures={props.grnFeatures}
      />
    );
  }
}

SupplierPOsTable.propTypes = {
  AddGRNFetchTable: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  fetchGRNData: state.pcmp.cmo.shortFalls.fetchGRNData,
  grnFeatures: state.pcmp.common.accessControl.featuresAccess.grnFeatures,
  tableLoading: state.pcmp.common.files.tableLoading,
});
export default withStyles(styles)(
  connect(mapStateToProps, { AddGRNFetchTable })(withRouter(SupplierPOsTable))
);
