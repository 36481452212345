import React, { Component } from 'react';
import { connect } from 'react-redux';
import BatchBody from '../models/BatchBody';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../../models/Button';
import BackDropComponent from '../../models/BackDrop';
import { withRouter } from 'react-router-dom';
import { cmoAddSASLBatchItem } from '../../../../containers/actions/cmo/addBatchItemActions';
import {
  fetchBatchDetails,
  deliveryScheduleHeader,
} from '../../../../containers/actions/common/saslAction';

import Dialog from '@material-ui/core/Dialog';
import StyledDialog from '../../../../../common/models/Dialog';
import { presentFormatedData } from '../../functionalComponents/dataFormatFunctions';
import InputNumber from '../../models/inputs/InputNumber';
import CustomDatePickerMui from '../../models/inputs/DateInput';
import MandatorySpan from '../models/MandatorySpan';
const Styles = {
  paddingBody: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  padding: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },
  title: {
    fontSize: '1rem',
    paddingTop: '1rem',
    paddingBottom: '0.5rem',
  },
  span: {
    width: '4rem',
    border: '2px solid #B4B4B4',
    display: 'flex',
    backgroundColor: '#B4B4B4',
  },
  paddingBottom: {
    paddingBottom: '1rem',
    paddingRight: '3rem',
  },
  font: {
    fontWeight: 500,
    textAlign: 'initial',
    paddingTop: '1rem',
    paddingBottom: '0.25rem',
  },
  fontPadding: {
    textAlign: 'initial',
    fontSize: '1.5rem',
    fontWeight: '500',
    padding: '0.3rem',
  },
  textfield: {
    paddingTop: '0.25rem',
  },
  button: {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    paddingLeft: '2.5rem',
  },
};
class AddBatchSASL extends Component {
  constructor(props) {
    super(props);
    // this.myRef = React.createRef()
    this.state = {
      submitRecord: false,
      disableSaveButton: false,
      resError: null,
      batchDetails: {
        vendorBatchNumber: '',
        batchSize: '',
        producedQuantity: 0,
        batchUOM: this.props.deliveryScheduleUOM,
        uomQA: this.props.uomQA,
        SASLBatchStatusID: 1,
        statusDisplayName: 'Scheduled',
        batchStartDate: null,
        batchEndDate: null,
        MRP: '',
      },
      errors: {
        vendorBatchNumber: '',
        batchSize: '',
        producedQuantity: '',
        batchUOM: false,
        batchStartDate: false,
        batchEndDate: false,
        MRP: '',
      },
      batchError: null,
    };
  }

  scrollToTop = () => {
    this.el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'batchSize' || name === 'MRP') {
      let parsedValue = value !== '' ? parseFloat(value) : '';
      this.setState((prevState) => ({
        batchDetails: {
          ...prevState.batchDetails,
          [name]: parsedValue,
        },
        errors: {
          vendorBatchNumber: '',
          batchSize: '',
          producedQuantity: '',
          batchUOM: '',
          batchStartDate: false,
          batchEndDate: false,
          MRP: '',
        },
        batchError: null,
      }));
    } else {
      this.setState((prevState) => ({
        batchDetails: {
          ...prevState.batchDetails,
          [name]: value,
        },
        errors: {
          vendorBatchNumber: '',
          batchSize: '',
          producedQuantity: '',
          batchUOM: '',
          batchStartDate: false,
          batchEndDate: false,
          MRP: '',
        },
        batchError: null,
      }));
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.batchAddSASL !== prevProps.batchAddSASL &&
      this.props.batchAddSASL.status === true
    ) {
      let data = {
        schrockenDeliveryScheduleID:
          this.props.match.params.schrockenDeliveryScheduleID,
      };
      this.setState({ submitRecord: true, disableSaveButton: false });

      this.props.fetchBatchDetails(data);
    }

    if (
      this.props.batchAddSASL !== prevProps.batchAddSASL &&
      this.props.batchAddSASL.errorDescription
    ) {
      if (typeof this.props.batchAddSASL.errorDescription === 'object') {
        let errorKey = Object.keys(this.props.batchAddSASL.errorDescription)[0];
        this.setState({
          batchError: this.props.batchAddSASL.errorDescription[errorKey],
          disableSaveButton: false,
        });
      } else {
        this.setState({
          batchError: this.props.batchAddSASL.errorDescription,
          disableSaveButton: false,
        });
      }
      this.scrollToTop();
    }
  }

  handleDateChange = (date, name) => {
    this.setState((prevState) => ({
      batchDetails: {
        ...prevState.batchDetails,
        [name]: date != null ? date.toISOString() : date,
      },
      errors: {
        ...prevState.errors,
        [name]: false,
      },
      batchError: null,
    }));
  };

  validate = () => {
    let isError = false;

    let { batchDetails } = this.state;

    let errors = {
      vendorBatchNumber: '',
      batchSize: '',
      producedQuantity: '',
      batchUOM: false,
      batchStartDate: false,
      batchEndDate: false,
      MRP: '',
    };

    if (batchDetails.vendorBatchNumber === '') {
      isError = true;
      errors.vendorBatchNumber = 'Please Enter Batch Number';
    } else if (batchDetails.batchSize === '') {
      isError = true;
      errors.batchSize = 'Please Enter Batch Size';
    } else if (batchDetails.batchSize <= 0) {
      isError = true;
      errors.batchSize = 'Please Enter valid Batch Size';
    } else if (batchDetails.MRP < 0) {
      isError = true;
      errors.MRP = 'Please Enter valid MRP';
    }

    this.setState({ errors });
    return isError;
  };

  onSubmit = () => {
    let error = this.validate();
    if (!error) {
      this.setState({ disableSaveButton: true });
      let data = {
        schrockenDeliveryScheduleID: parseInt(
          this.props.match.params.schrockenDeliveryScheduleID
        ),
        batchData: {
          ...this.state.batchDetails,
          bomConsumptionAddedIndicator: false,
        },
        addBatchFlag: true,
      };
      this.props.cmoAddSASLBatchItem(data);
    }
  };

  handleCloseRecord = () => {
    this.setState({ submitRecord: false });
    // calling the DS Header API
    let data = {
      schrockenDeliveryScheduleID:
        this.props.match.params.schrockenDeliveryScheduleID,
    };

    this.props.deliveryScheduleHeader(data);

    this.props.handleClose();
  };

  render() {
    const { classes } = this.props;
    const { batchDetails, errors, batchError } = this.state;
    const diffDays = (a, b) => {
      let a1 = new Date(a);
      let b1 = new Date(b);
      return Math.floor((a1 - b1) / (1000 * 60 * 60 * 24));
    };

    return (
      <React.Fragment>
        <div
          ref={(el) => {
            this.el = el;
          }}
          className={classes.paddingBody}
        >
          <BatchBody title='Current Status' subtitle='Scheduled' />
        </div>
        {batchError && (
          <Grid
            ref={(el) => {
              this.el = el;
            }}
            container
            justify='center'
            className={classes.padding}
            style={{ paddingTop: batchError !== null ? '1rem' : 0 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant='h6'
                style={{
                  border: '1px solid #FF0000',
                  backgroundColor: '#FFF0F0',
                  opacity: '1',
                  textAlign: 'center',
                  padding: '0.8rem',
                  fontSize: '0.875rem',
                }}
              >
                {batchError}
              </Typography>
            </Grid>
          </Grid>
        )}
        <form onSubmit={this.onSubmit}>
          <Grid container className={classes.padding}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              // className={classes.paddingBottom}
            >
              <Typography variant='body2' className={classes.title}>
                Batch Information
              </Typography>
              <span className={classes.span}></span>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.paddingBottom}
            >
              <Typography variant='body2' className={classes.font}>
                Enter Batch Number <MandatorySpan />
              </Typography>
              <InputNumber
                className={classes.textfield}
                placeholder='Enter Batch Number'
                name='vendorBatchNumber'
                type='text'
                value={batchDetails.vendorBatchNumber}
                onChange={this.handleChange}
                error={
                  this.state.errors.vendorBatchNumber === '' ? false : true
                }
                helperText={this.state.errors.vendorBatchNumber}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              // className={classes.paddingBottom}
            >
              <Typography variant='body2' className={classes.title}>
                Batch Quantity
              </Typography>
              <span className={classes.span}></span>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.paddingBottom}
            >
              <Typography variant='body2' className={classes.font}>
                Unit of measurement (UOM)
              </Typography>
              <Typography variant='body2' className={classes.fontPadding}>
                {batchDetails.batchUOM}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.paddingBottom}
            >
              <Typography variant='body2' className={classes.font}>
                Control Sample UOM
              </Typography>
              <Typography variant='body2' className={classes.fontPadding}>
                {presentFormatedData(this.props.uomQA, '-Not Provided-')}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.paddingBottom}
            >
              <Typography variant='body2' className={classes.font}>
                Enter Batch Size <MandatorySpan />
              </Typography>

              <InputNumber
                placeholder='Enter Batch Size'
                name='batchSize'
                className={classes.textfield}
                onChange={this.handleChange}
                value={batchDetails.batchSize}
                inputProps={{ min: 0, step: 10 }}
                onKeyPress={(event) => {
                  if (event.key === '.') {
                    // disable decimal values
                    event.preventDefault();
                  }
                }}
                error={this.state.errors.batchSize === '' ? false : true}
                helperText={this.state.errors.batchSize}
                required
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              md={4}
              lg={3}
              className={classes.paddingBottom}
            >
              <Typography variant='body2' className={classes.font}>
                Produced Quantity
              </Typography>
              <Typography variant='body2' className={classes.fontPadding}>
                {batchDetails.producedQuantity}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              // className={classes.paddingBottom}
            >
              <Typography variant='body2' className={classes.title}>
                Price Details
              </Typography>
              <span className={classes.span}></span>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              lg={3}
              className={classes.paddingBottom}
            >
              <Typography variant='body2' className={classes.font}>
                Max. Retail Price (MRP)
              </Typography>
              <InputNumber
                inputProps={{ min: 0, step: 1 }}
                placeholder='Enter Max Retail Price'
                name='MRP'
                value={batchDetails.MRP}
                onChange={this.handleChange}
                className={classes.textfield}
                error={this.state.errors.MRP === '' ? false : true}
                helperText={this.state.errors.MRP}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant='body2' className={classes.title}>
                Production Start &amp; End Schedule
              </Typography>
              <span className={classes.span}></span>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.paddingBottom}
            >
              <Typography variant='body2' className={classes.font}>
                Start Date
              </Typography>
              <CustomDatePickerMui
                name='materialReceivedStartDate'
                value={batchDetails.batchStartDate}
                onChange={(date) =>
                  this.handleDateChange(date, 'batchStartDate')
                }
                error={errors.batchStartDate}
                helperText={errors.batchStartDate ? errors.batchStartDate : ''}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.paddingBottom}
            >
              <Typography variant='body2' className={classes.font}>
                End Date
              </Typography>
              <CustomDatePickerMui
                name='materialReceivedEndDate'
                value={batchDetails.batchEndDate}
                onChange={(date) => this.handleDateChange(date, 'batchEndDate')}
                minDate={batchDetails.batchStartDate}
                disabled={batchDetails.batchStartDate === null ? true : false}
                error={errors.batchEndDate}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.paddingBottom}
            >
              <Typography variant='body2' className={classes.font}>
                Calculated Production duration (days)
              </Typography>
              <Typography variant='body2' className={classes.fontPadding}>
                {batchDetails.batchEndDate && batchDetails.batchStartDate
                  ? diffDays(
                      batchDetails.batchEndDate,
                      batchDetails.batchStartDate
                    )
                  : 0}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={12} className={classes.button}>
              <StyledButton
                onClick={this.onSubmit}
                disabled={this.state.disableSaveButton}
                style={{
                  backgroundColor: this.state.disableSaveButton
                    ? 'grey'
                    : 'green',
                  // borderRadius: '1rem',
                  color: '#ffffff',
                  paddingLeft: '2rem',
                  paddingRight: '2rem',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                }}
              >
                Add Batch
              </StyledButton>
            </Grid>
          </Grid>
        </form>
        <Dialog
          open={this.state.submitRecord}
          // onClose={this.handleCloseRecord}
          fullWidth
        >
          <StyledDialog
            handleClose={this.handleCloseRecord}
            icons={true}
            title='Details Saved'
            subtitle='Batch Details have been added.'
          />
        </Dialog>
        <BackDropComponent />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => ({
  batchAddSASL: state.pcmp.cmo.batchItems.batchSASLAdded,
});

export default withStyles(Styles)(
  connect(mapStatetoProps, {
    cmoAddSASLBatchItem,
    fetchBatchDetails,
    deliveryScheduleHeader,
  })(withRouter(AddBatchSASL))
);
