import React from 'react';
import Button from '@material-ui/core/Button';
import POSelector from '../../../../common/selectors/POSelector';
import Grid from '@material-ui/core/Grid';
const SelectionBar = (props) => {
  return (
    <Grid>
      <div
        style={{
          // margin: '0rem -1.3rem 0rem -1.8rem',
          margin: '0px -16px 0 -16px',
          backgroundColor: '#F0F0F0',
          display: 'flex',
          alignItems: 'baseline',
          flexDirection: 'row',
          padding: '0rem 0.5rem 0.75rem 0.5rem',
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems='center'
          style={{ marginLeft: '0rem' }}
        >
          <Grid item xs={6} sm={5} md={4} lg={5}>
            <POSelector
              handleChange={props.handleChange}
              purchaseOrderNumber={props.purchaseOrderNumber}
              poError={props.poError}
              needFullWidth={true}
            />
          </Grid>

          {/* <DateCustomRangeSelector /> */}
          <Grid item xs={5} sm={3} md={3} lg={3}>
            <Button
              // style={{ padding: '10px 23px 9px 10px' }}
              variant='contained'
              onClick={props.fetchReport}
              color='secondary'
              style={{
                paddingBottom: '0.25rem',
              }}
            >
              View Report
            </Button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default SelectionBar;
