import React, { Component } from "react";
import { connect } from "react-redux";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";

import Moment from "moment";
import SelectionBar from "./SelectionBar";
import ReportsTableWithPagination from "../../../common/tables/ReportsTableWithPagination";

import {
  viewReport,
  downloadReport,
} from "../../../../containers/actions/common/reportsActions";
import { cmoMembersList } from "../../../../containers/actions/pharmaCo/purchaseOrderActions";

const header = [
  {
    label: "Product Code",
    id: "productCode",
    align: "left",
    span: 0.2,
    numeric: false,
  },
  {
    label: "Product Name",
    id: "productName",
    align: "left",
    span: 0.2,
    numeric: false,
  },
  {
    label: "Batch Number",
    id: "batchNumber",
    align: "left",
    span: 0.2,
    numeric: false,
  },
  {
    label: "Batch Size",
    id: "batchSize",
    align: "right",
    span: 0.1,
    numeric: true,
  },
  {
    label: "Qty Dispatched",
    id: "producedQuantity",
    align: "right",
    span: 0.1,
    numeric: true,
  },
  {
    label: "Yield%",
    id: "yield",
    align: "right",
    span: 0.1,
    numeric: true,
  },
  {
    label: "Dispatch Month",
    id: "dispatchMonth",
    align: "right",
    span: 0.1,
    numeric: true,
    date: true,
    format: "MMM-YY",
  },
  // {
  //   label: "Dispatch Date",
  //   id: "dispatch",
  //   align: "right",
  //   span: 0.1,
  //   numeric: true,
  //   date: true,
  //   format: "MMM-YY"
  // }
];
class YieldReport extends Component {
  state = {
    header: header,
    data: [],
    selectedDate: null,
    currentMonthNumber: null,
    currentYear: null,
    CMOMember: "all",
    cmoMembersList: [],
    reportStartDate: null,
    reportEndDate: null,
    requestDownload: false,
    reportNumber: 1,
    page: 0,
    rowsPerPage: 5,
    showReport: false,
    reportError: null,
    errors: {
      monthError: false,
      startDateError: false,
      endDateError: false,
    },
  };

  componentDidMount() {
    if (this.props.collabType === "Pharma Co") {
      const data = {
        ...this.props.match.params,
        collabType: "Pharma Co",
      };
      this.props.cmoMembersList(data);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    if (
      nextProps.yieldReport !== this.props.yieldReport &&
      nextProps.yieldReport.status === true
    ) {
      this.setState({
        data: nextProps.yieldReport.reportData,
        requestedBy: nextProps.yieldReport.requestedBy,
        showReport: true,
      });
    }
    if (
      nextProps.yieldReport !== this.props.yieldReport &&
      nextProps.yieldReport.status === false
    ) {
      this.setState({
        reportError: nextProps.otifReport.errorDescription,
        showReport: true,
      });
    }
    if (nextProps.purchaseOrder.cmoMembersList !== this.props.cmoMembersList) {
      this.setState({ cmoMembersList: nextProps.purchaseOrder.cmoMembersList });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleDateChange = (date, name) => {
    if (name === "selectedDate") {
      this.setState({
        selectedDate: new Date(date),
        currentYear: new Date(date).getFullYear(),
        currentMonthNumber: new Date(date).getMonth() + 1,
        reportStartDate: null,
        reportEndDate: null,
        errors: {
          monthError: false,
          startDateError: false,
          endDateError: false,
        },
      });
    } else if (name === "reportStartDate") {
      this.setState({
        selectedDate: null,
        currentMonthNumber: null,
        currentYear: null,
        reportStartDate: new Date(date),
        errors: {
          monthError: false,
          startDateError: false,
          endDateError: false,
        },
      });
    } else if (name === "reportEndDate") {
      this.setState({
        selectedDate: null,
        currentMonthNumber: null,
        currentYear: null,
        reportEndDate: new Date(date),
        errors: {
          monthError: false,
          startDateError: false,
          endDateError: false,
        },
      });
    }
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  validate = () => {
    let error = false;
    let errors = {
      monthError: false,
      startDateError: false,
      endDateError: false,
    };
    const { selectedDate, reportStartDate, reportEndDate } = this.state;
    if (
      selectedDate === null &&
      reportStartDate === null &&
      reportEndDate === null
    ) {
      error = true;
      errors.monthError = true;
    } else if (reportStartDate === null && reportEndDate !== null) {
      error = true;
      errors.startDateError = true;
    } else if (reportStartDate !== null && reportEndDate === null) {
      error = true;
      errors.endDateError = true;
    }
    this.setState({ errors });
    return error;
  };

  fetchReport = () => {
    let err = this.validate();
    if (!err) {
      const {
        currentMonthNumber,
        currentYear,
        reportStartDate,
        reportEndDate,
        reportNumber,
        CMOMember,
      } = this.state;
      let data = {
        currentMonthNumber,
        currentYear,
        reportStartDate,
        reportEndDate,
        reportNumber,
        collabType: this.props.collabType,
        CMOMember:
          this.props.collabType === "CMO"
            ? this.props.match.params.organizationName
            : CMOMember,
        ...this.props.match.params,
      };

      this.props.viewReport(data);
      this.setState({ showReport: false });
    }
  };
  downloadReport = () => {
    let err = this.validate();
    if (!err) {
      const {
        reportNumber,
        currentMonthNumber,
        currentYear,
        reportStartDate,
        reportEndDate,
        CMOMember,
      } = this.state;
      let data = {
        reportNumber,
        currentMonthNumber,
        currentYear,
        reportStartDate,
        reportEndDate,
        collabType: this.props.collabType,
        CMOMember:
          this.props.collabType === "CMO"
            ? this.props.match.params.organizationName
            : CMOMember,
        ...this.props.match.params,
      };
      this.props.downloadReport(data);
    }
  };

  render() {
    const {
      selectedDate,
      reportStartDate,
      reportEndDate,
      CMOMember,
      cmoMembersList,
      header,
      data,
      showReport,
      errors,
      page,
      rowsPerPage,
      requestedBy,
      reportError,
    } = this.state;
    const tableBody =
      data.length > 0 ? (
        data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, i) => (
            <TableRow key={i}>
              <TableCell align="left">{row.productCode}</TableCell>
              <TableCell align="left">{row.productName}</TableCell>
              <TableCell align="left">{row.batchNumber}</TableCell>
              <TableCell align="right">{row.batchSize}</TableCell>
              <TableCell align="right">{row.producedQuantity}</TableCell>
              <TableCell align="right">{row.yield}</TableCell>
              <TableCell align="right">
                {row.dispatchDate !== "" && row.dispatchDate !== null
                  ? Moment(row.dispatchDate).format("MMM-YY")
                  : null}
              </TableCell>
            </TableRow>
          ))
      ) : (
        <TableRow>
          <TableCell colSpan={header.length} align="center">
            {reportError ? (
              <Typography variant="body2" color="error">
                {reportError}
              </Typography>
            ) : (
              <Typography variant="body2">No Records Found</Typography>
            )}
          </TableCell>
        </TableRow>
      );
    return (
      <div>
        <SelectionBar
          handleChange={this.handleChange}
          handleDateChange={this.handleDateChange}
          selectedDate={selectedDate}
          reportStartDate={reportStartDate}
          reportEndDate={reportEndDate}
          fetchReport={this.fetchReport}
          monthError={errors.monthError}
          startDateError={errors.startDateError}
          endDateError={errors.endDateError}
          collabType={this.props.collabType}
          CMOMember={CMOMember}
          cmoMembersList={cmoMembersList}
        />
        {showReport === true && (
          <ReportsTableWithPagination
            header={header}
            tableBody={tableBody}
            downloadReport={this.downloadReport}
            length={data.length}
            data={data}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            requestedBy={requestedBy}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  yieldReport: state.pcmp.common.reports.yieldReport,
  purchaseOrder: state.pcmp.pharmaCo.purchaseOrder,
});
export default connect(mapStateToProps, {
  viewReport,
  downloadReport,
  cmoMembersList,
})(YieldReport);
