import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
const useStyles = makeStyles((theme) => ({
  hgText: {
    fontWeight: 500,
    paddingRight: theme.spacing(1),
  },
  outlined: {
    padding: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    fontSize: '0.875rem',
  },
  formPerPage: {
    minWidth: 60,
    fontSize: '0.875rem',
  },
}));

const RowsPerPage = (props) => {
  const classes = useStyles();
  const { value, onChange } = props;
  return (
    <React.Fragment>
      <Typography variant='body2' className={classes.hgText}>
        PER PAGE
      </Typography>
      <FormControl className={classes.formPerPage}>
        <Select
          labelId='perpage-filter'
          id='perpage-filter-id'
          value={value}
          variant='outlined'
          onChange={onChange}
          classes={{
            outlined: classes.outlined,
          }}
        >
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={30}>30</MenuItem>
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

RowsPerPage.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RowsPerPage;
