/* eslint-disable no-console */
/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Harish D
 * -----
 * File Description: Table that shows all purchase orders
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import {
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  TextField,
  IconButton,
  Tooltip,
  Grid,
  TableFooter,
} from '@material-ui/core';

import TablePagination from '@material-ui/core/TablePagination';

import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SearchIcon from '@material-ui/icons/Search';

import orderBy from 'lodash/orderBy';
import Moment from 'moment';

import pgactions from '../../../../common/functionalComponents/PageActions';

import { tableFilter } from '../../../../common/functionalComponents/TableFilter';

import { getLineItemsInPO } from '../../../../../containers/actions/pharmaCo/purchaseOrderActions';

import { style } from '../../../../../resources/css/styles';

const tableDataFilter = tableFilter;
const TablePaginationActionsWrapped = pgactions;

const styles = style;

const invertDirection = {
  asc: 'desc',
  desc: 'asc',
};

const header = [
  { name: 'Status', id: 'statusText', align: 'left' },
  { name: 'Line', id: 'lineNumber', align: 'center' },
  { name: 'PO Number', id: 'purchaseOrderNumber', align: 'center' },
  { name: 'Product Code', id: 'productCode', align: 'center' },
  { name: 'Product Name', id: 'productName', align: 'center' },
  { name: 'Plant Code', id: 'CMOPlantCode', align: 'center' },
  { name: 'UOM', id: 'unitOfMeasurement', align: 'right' },
  { name: 'Quantity', id: 'quantity', align: 'right' },
  { name: 'Delivery Date', id: 'deliveryDate', align: 'right' },
  { name: 'Commit Date', id: 'poLineCommitDate', align: 'right' },
];

class PurchaseOrderTable extends Component {
  state = {
    currentStatusValue: 1,
    poStatus: '',
    data: [],
    header,
    displayFilter: false,
    query: '',
    columnToQuery: 'all',
    columnToSort: '',
    sortDirection: 'desc',
    fieldSelected: false,
    page: 0,
    rowsPerPage: 3,
  };

  componentDidMount() {
    const data = {
      ...this.props.match.params,
      collabType: this.props.collabType,
    };
    this.props.getLineItemsInPO(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.poLineItems) {
      this.setState({ data: nextProps.poLineItems });
    }
    if (nextProps.poStatus) {
      this.setState({
        poStatus: nextProps.poStatus.statusCode,
      });
    }
  }

  // show search fields
  showFilter = () => {
    this.setState({
      displayFilter: !this.state.displayFilter,
      columnToQuery: 'all',
      query: '',
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      fieldSelected: false,
    });

    if (this.state.columnToQuery === 'none') {
      if (event.target.name === 'query') {
        this.setState({ fieldSelected: true });
      }
    }
  };

  // handle sort
  handleSort = (columName) => {
    this.setState({
      columnToSort: columName,
      sortDirection:
        this.state.columnToSort === columName
          ? invertDirection[this.state.sortDirection]
          : 'asc',
    });
  };

  // refresh
  onRefresh = () => {
    const getdata = {
      ...this.props.match.params,
      collabType: this.props.collabType,
    };
    this.props.getLineItemsInPO(getdata);
  };

  // pagination
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    this.setState({ rowsPerPage: value });
  };

  openLineItem = (schrockenPOLineID, lineNumber) => {
    const { params } = this.props.match;
    if (this.state.poStatus !== 2) {
      this.props.history.push(
        `/pcmp/${this.props.collabType}/${params.roleName}/${params.organizationName}/${params.ecosystemId}/lineitem/${params.purchaseOrderNumber}/${schrockenPOLineID}/${lineNumber}`
      );
    } else {
      return null;
    }
  };

  render() {
    const { classes } = this.props;

    const { rowsPerPage, page, header, data } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    const queryData = orderBy(
      this.state.query
        ? data.filter((data) =>
            tableDataFilter(
              data,
              this.state.columnToQuery,
              this.state.query.toLowerCase(),
              this.state.header
            )
          )
        : data,
      this.state.columnToSort,
      this.state.sortDirection
    );
    return (
      <Grid item xs={12} md={12} className={classes.membersTable}>
        <Paper className={classes.root3}>
          <Toolbar>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Typography variant='subtitle1'>
                  Order Information (Order Line Items)
                </Typography>
              </div>
              {this.state.displayFilter && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Select
                    native
                    style={{ marginLeft: '1em', marginRight: '1.5rem' }}
                    value={this.state.columnToQuery}
                    name='columnToQuery'
                    onChange={this.handleChange}
                    error={this.state.fieldSelected}
                    className={classes.fontColor}
                  >
                    <option value='all' className={classes.fontColor}>
                      All
                    </option>
                    {header.map((ele, x) => (
                      <option
                        key={`opt-${x}`}
                        value={ele.id}
                        className={classes.fontColor}
                      >
                        {ele.name}
                      </option>
                    ))}
                  </Select>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />

                    <TextField
                      name='query'
                      placeholder='Search... '
                      value={this.state.query}
                      onChange={this.handleChange}
                      className={classes.fontColor}
                      inputProps={{ style: { fontSize: '0.75rem' } }}
                    />
                  </div>
                </div>
              )}

              <Tooltip title='Filter List'>
                <IconButton aria-label='Filter list' onClick={this.showFilter}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </div>
            <IconButton aria-label='Refresh list' onClick={this.onRefresh}>
              <RefreshIcon />
            </IconButton>
          </Toolbar>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {header.map((data, i) => (
                  <TableCell key={i} align={data.align}>
                    <Tooltip title='sort'>
                      <div
                        onClick={() => this.handleSort(data.id)}
                        style={{
                          display:
                            this.state.columnToSort === data.id
                              ? 'flex'
                              : 'initial',
                          // flexDirection: "row",
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <span>{data.name}</span>
                        {this.state.columnToSort === data.id ? (
                          this.state.sortDirection === 'asc' ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </div>
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {searchResults} */}
              {queryData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((line, i) => (
                  <React.Fragment key={`fragment-${i}`}>
                    <TableRow
                      key={`row-${i}`}
                      className={
                        line.statusCode !== 4 ? classes.tableRowHover : null
                      }
                      onClick={() =>
                        this.openLineItem(
                          line.schrockenPOLineID,
                          line.lineNumber
                        )
                      }
                    >
                      <TableCell align='left'>{line.statusText}</TableCell>
                      <TableCell align='center'>{line.lineNumber}</TableCell>
                      <TableCell align='center'>
                        {line.purchaseOrderNumber}
                      </TableCell>
                      <TableCell align='center'>{line.productCode}</TableCell>
                      <TableCell align='center'>{line.productName}</TableCell>
                      <TableCell align='center'>{line.CMOPlantCode}</TableCell>
                      <TableCell align='center'>
                        {line.unitOfMeasurement}
                      </TableCell>
                      <TableCell align='right'>{line.quantity}</TableCell>
                      <TableCell align='right'>
                        {Moment(line.deliveryDate).format(dateFormat)}
                      </TableCell>
                      <TableCell align='right'>
                        {line.poLineCommitDate
                          ? Moment(new Date(line.poLineCommitDate)).format(
                              dateFormat
                            )
                          : 'Not Specified'}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[3, 5, 10, 15]}
                  colSpan={header.length + 1}
                  count={queryData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                  classes={{
                    spacer: classes.TablePaginationAlignleft,
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Grid>
    );
  }
}

PurchaseOrderTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  poLineItems: state.pcmp.pharmaCo.purchaseOrder.poLineItems,
  poStatus: state.pcmp.pharmaCo.purchaseOrder.poDetails,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getLineItemsInPO })(withRouter(PurchaseOrderTable))
);
