import axios from 'axios';
import { appUrl } from '../../../../../utils/config';

import {
  PHARMA_DOWNLOAD_COA_FILE,
  PHARMA_DOWNLOAD_RconSheet_FILE,
  PHARMA_DOWNLOAD_PIR_FILE,
  PHARMA_DOWNLOAD_ShipperRecord_FILE,
  PHARMA_UPLOAD_GRN_FILE,
  PHARMA_DOWNLOAD_EWAY_FILE,
  PHARMA_DOWNLOAD_LR_FILE,
  PHARMA_DOWNLOAD_INVOICE_FILE,
} from '../types';

export const downloadPOPdf = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/downloadFile`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        let fileDataArray = res.data.fileData.data;
        var byteArray = new Uint8Array(fileDataArray);

        var a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        );
        a.download = res.data.fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });
};
export const pharmaDownloadQCFile = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/downloadBatchFile`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        let fileDataArray = res.data.fileData.data;
        var byteArray = new Uint8Array(fileDataArray);

        var a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        );
        a.download = res.data.fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        if (data.docType === 'reconciliationSheet') {
          dispatch({
            type: PHARMA_DOWNLOAD_RconSheet_FILE,
            payload: res.data.status,
          });
        } else if (data.docType === 'packInspectionRecord') {
          dispatch({
            type: PHARMA_DOWNLOAD_PIR_FILE,
            payload: res.data.status,
          });
        } else if (data.docType === 'COA') {
          dispatch({
            type: PHARMA_DOWNLOAD_COA_FILE,
            payload: res.data.status,
          });
        } else if (data.docType === 'shipperWeighingRecord') {
          dispatch({
            type: PHARMA_DOWNLOAD_ShipperRecord_FILE,
            payload: res.data.status,
          });
        } else if (data.docType === 'EWayBill') {
          dispatch({
            type: PHARMA_DOWNLOAD_EWAY_FILE,
            payload: res.data.status,
          });
        } else if (data.docType === 'LR') {
          dispatch({
            type: PHARMA_DOWNLOAD_LR_FILE,
            payload: res.data.status,
          });
        } else if (data.docType === 'invoice') {
          dispatch({
            type: PHARMA_DOWNLOAD_INVOICE_FILE,
            payload: res.data.status,
          });
        }
      }
    })
    .catch((err) => console.log(err));
};

export const pharmaUploadGRNFile = (data, roleName) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${roleName}/uploadFile`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PHARMA_UPLOAD_GRN_FILE,
          payload: res.data.status,
        });
      }
    })
    .catch((err) => console.log(err));
};
