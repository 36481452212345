import React, { useState } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';
//
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import QuickView from './QuickView';

export const useStyles = makeStyles((theme) => ({
  multipleLogo: {
    border: '1px solid #7d7c7c',
    borderRadius: '4px',
    padding: '0.3rem',
    height: 'inherit',
    marginLeft: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
  list: {
    maxWidth: '80vw',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vw',
    },
  },
  fullList: {
    width: 'auto',
  },
  received: {
    textAlign: 'left',
  },
}));

const ViewSupplierPO = (props) => {
   const [open, setOpen] = useState(false);
  const classes = useStyles();
  const setDialogOpen = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <VisibilityIcon
        fontSize='small'
        className={classes.multipleLogo}
        onClick={() => setOpen(!open)}
      />
      <SwipeableDrawer
        anchor={'right'}
        open={open}
        onClose={setDialogOpen}
        onOpen={setDialogOpen}
      >
        <QuickView setDialogOpen={setDialogOpen} {...props} />
      </SwipeableDrawer>
    </React.Fragment>
  );
};

export default ViewSupplierPO;