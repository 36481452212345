import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import StyledButton from '../../../../../../common/models/Button';
import Header from '../../../models/Header';
import FullWidthContent from '../../../wrappers/QuickViewWrapper';
import General from '../../batch/updateBatch/General';
import BatchInformation from '../../batch/updateBatch/BatchInformation';
// import UploadedDocuments from '../Documents';
import QualityAssuranceInformation from '../../batch/updateBatch/QCAndShipping/QualityAssuranceInformation';

//func
import { fetchDSBatchDetails } from '../../../../../containers/actions/common/saslAction';
import { downloadCustomUploadedFile } from '../../../../../containers/actions/common/fileActions';
import ShowFiles from '../../../models/files/ShowFiles';
import { getFeaturesAccess } from '../../../../../containers/actions/common/accessControl';
import ShippingInformation from '../../batch/updateBatch/QCAndShipping/ShippingInformation';

const useStyles = (theme) => ({
  list: {
    maxWidth: '100vw',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vw',
    },
  },
  fullList: {
    width: 'auto',
  },
  received: {
    textAlign: 'left',
  },
  grid: {
    //paddingTop: '0rem',
    paddingBottom: '2rem',
    paddingRight: '1.5rem',
  },
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '1.6rem',
    backgroundColor: 'grey',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5rem',
    textAlign: 'left',
  },
});

class QuickView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
    };
  }

  componentDidMount() {
    const data = {
      schrockenDeliveryScheduleBatchID:
        this.props.schrockenDeliveryScheduleBatchID,
    };
    if (this.props.fgBatchScreenAccess) this.props.fetchDSBatchDetails(data);
    if (!this.props.fgBatchFeatures)
      this.props.getFeaturesAccess(11, 'fgBatchFeatures'); // 11 is FG Batch screen ID
    // we can get the same screen ID from screen Access state
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.DsDetails && this.props.DsDetails !== prevProps.DsDetails) {
      this.setState({ data: this.props.DsDetails.data, loading: false });
    }
  }

  dialogClosClearData = () => {
    this.props.setDialogOpen();
    this.setState({ loading: true, data: null });
  };

  // to download custom files
  handleDownloadFile = (fileID) => {
    const data = {
      ...this.props.params,
      schrockenCustomFileID: fileID,
    };
    this.props.downloadCustomUploadedFile(data);
    // console.log("Download files")
  };

  render() {
    const { classes } = this.props;
    const { loading, data } = this.state;
    return (
      <div className={classes.list} role='presentation'>
        <Header
          title='View Batch Info'
          isDrawer={true}
          handleClose={this.dialogClosClearData}
        >
          <FullWidthContent>
            <Grid container>
              <Grid item xs={12} sm={12}>
                {loading === false ? (
                  <React.Fragment>
                    {this.props.fgBatchFeatures?.fgBatchGRInfo && (
                      <React.Fragment>
                        <General data={data} />
                        <hr />
                      </React.Fragment>
                    )}
                    {this.props.fgBatchFeatures?.fgBatchInfo && (
                      <BatchInformation batchDetails={data} quickView />
                    )}

                    <QualityAssuranceInformation
                      batchDetails={data}
                      handleDownloadFile={this.handleDownloadFile}
                      newFiles={[]}
                      existingFiles={data.existingCOAFiles || []}
                      quickView
                    />

                    {this.props.fgBatchFeatures?.fgBatchShippingInfo && (
                      <ShippingInformation batchDetails={data} quickView />
                    )}
                    <Grid
                      item
                      xs={8}
                      sm={12}
                      md={12}
                      className={classes.grid}
                      style={{ paddingTop: '1rem', paddingBottom: '0.7rem' }}
                    >
                      <Typography variant='h4' className={classes.title}>
                        Documents
                      </Typography>
                      <span className={classes.borders}></span>
                    </Grid>

                    <Grid item xs={5}>
                      <ShowFiles
                        files={this.props.DsDetails.data.existingCustomFiles}
                        downloadable={
                          this.props.fgBatchFeatures?.downloadFGDocuments
                        }
                        downloadFile={this.handleDownloadFile}
                      />
                    </Grid>

                    <Grid container justify='center'>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={2}
                        style={{
                          textAlign: 'center',
                          padding: '1rem',
                        }}
                      >
                        <StyledButton
                          onClick={this.dialogClosClearData}
                          fullWidth
                        >
                          Close
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <Grid container justify='center'>
                    <div
                      style={{
                        textAlign: 'center',
                        marginTop: '25%',
                        minWidth: '60vw',
                      }}
                    >
                      <CircularProgress color='primary' thickness={7} />
                    </div>
                  </Grid>
                )}
              </Grid>

              {/*
              <Grid item xs={12} sm={3}>
                {/* Chat Box here 
                Batch Chat for: {this.props.schrockenDeliveryScheduleBatchID}
                
                <ChatBox 
                schrockenDeliveryScheduleBatchID={this.props.schrockenDeliveryScheduleBatchID}
                item='POBatchItem'
                collabType={localStorage.collabType}
                DsDetails = {this.props.DsDetails}
                roleName={localStorage.roleName}
                />
  
              </Grid>
            */}
            </Grid>
          </FullWidthContent>
        </Header>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  DsDetails: state.pcmp.common.sasl.DsDetails,
  batchItemDetails: state.pcmp.common.sasl.DsDetails,
  fgBatchScreenAccess:
    state.pcmp.common.accessControl.screensAccess?.data.fgBatch.screenAccess,
  fgBatchFeatures:
    state.pcmp.common.accessControl.featuresAccess?.fgBatchFeatures,
});

export default withStyles(useStyles)(
  connect(mapStateToProps, {
    fetchDSBatchDetails,
    downloadCustomUploadedFile,
    getFeaturesAccess,
  })(QuickView)
);
