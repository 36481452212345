import React, { Component } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";

export class NotFound extends Component {
  render() {
    return (
      <div>
        <Typography
          style={{ marginTop: "17rem", marginLeft: "34rem" }}
          variant="h1"
          color="inherit"
          noWrap
        >
          404
        </Typography>
        <Typography
          style={{ marginTop: "1rem", marginLeft: "31rem" }}
          variant="h5"
        >
          Oops....page not found
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
