import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../../../../common/models/Button';
import ShowFiles from './ShowFiles';
import Dialog from '@material-ui/core/Dialog';
import DuplicateFilePrompt from './DuplicateFilePrompt';
import { useSelector } from 'react-redux';
import DropFilesAttach from '../models/files/DropFilesAttach';
import InputNumber from '../models/inputs/InputNumber';
import CustomDatePickerMui from '../models/inputs/DateInput';

const useStyles = makeStyles((theme) => ({
  font: {
    fontWeight: 500,
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  padding: {
    paddingBottom: '2rem',
  },
}));

const EditChallanInfo = (props) => {
  const classes = useStyles();
  const {
    challanInformation,
    handleChange,
    handleDateChange,
    isAddRecord,
    //parent delete
    parentHandlingDeleteFile,
    challanTempNumber,
  } = props;

  const [challanNumber, setChallanNumber] = React.useState(
    challanInformation.challanNumber
  );
  const [challanDate, setChallanDate] = React.useState(
    challanInformation.challanDate
  );
  const [ewayBillNumber, setEwayBillNumber] = React.useState(
    challanInformation.ewayBillNumber
  );
  const [ewayBillDate, setEwayBillDate] = React.useState(
    challanInformation.ewayBillDate
  );
  const [challanFiles, setChallanFiles] = React.useState(
    challanInformation.challanFiles
  );
  //file append
  const [tempNumber, setTempNumber] = React.useState(challanTempNumber);
  const [localTempNumber, setLocalTempNumber] =
    React.useState(challanTempNumber);

  const [dupFilePrompt, setDupFilePrompt] = React.useState(false);

  const ewayBillEnabled = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.features
        .ewayBillEnabled;
    }
    return false;
  });

  const handleChangeChallanNumber = (e) => {
    setChallanNumber(e.target.value);
  };

  const handleChangeChallanDate = (date) => {
    setChallanDate(date != null ? date.toISOString() : date);
  };

  const filePrompt = () => {
    setDupFilePrompt(true);
  };

  const handleFileCopy = () => {
    setDupFilePrompt(false);
  };

  const handleClose = () => {
    setDupFilePrompt(false);
  };

  const handleSaveChallan = () => {
    let data = {
      ...challanInformation,
      challanNumber,
      challanDate,
      ewayBillNumber,
      ewayBillDate,
      challanFiles,
    };
    props.handleSaveChallanInformationData(data, localTempNumber);
  };

  const handlingDeleteFile = (i) => {
    // for rec fromBE=> mfd as 1, for local filter out
    //check for filetype in file
    //if 'buffer' then follow filter logic
    // if it is not buffer edit that file data sayn mfd 1
    if (
      window.confirm(
        'You are about to delete a file ,would you like to proceed ?'
      )
    ) {
      if (challanFiles[i].grFileType === 'buffer') {
        let newdel = challanFiles.filter(
          (file) => file.grFileName !== challanFiles[i].grFileName
        );
        setChallanFiles(newdel);
      } else {
        // COAFiles[i].markForDelete=1;
        let markDelFile = challanFiles.map((file) =>
          file.grFileHash === challanFiles[i].grFileHash
            ? { ...file, markForDelete: true }
            : file
        );
        setChallanFiles(markDelFile);
      }
    }
  };

  const LocalFileHandling = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        let newFileName = file.name;

        let counter = challanFiles
          .map((file) => file.grFileName)
          .indexOf(file.name);

        if (counter > -1) {
          filePrompt();
          // counter > -1 means the name is already taken
          // We split with . to deal with the extension
          newFileName = `${file.name.split('.')[0]}_${localTempNumber + 1}.${
            file.name.split('.')[1]
          }`;
          setLocalTempNumber(localTempNumber + 1);
        }
        // Do whatever you want with the file contents
        const fileData = {
          grFileData: reader.result,
          grFileName: newFileName,
          grFileType: 'buffer',
          schrockenGRFileId: '',
          grFileHash: '',
          markForDelete: false,
        };
        setChallanFiles((prevFiles) => [...prevFiles, fileData]);
      };
    });
  };

  const fileHandling = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        let newFileName = file.name;

        let counter = challanInformation.challanFiles
          .map((file) => file.grFileName)
          .indexOf(file.name);

        if (counter > -1) {
          filePrompt();
          // counter > -1 means the name is already taken
          // We split with . to deal with the extension
          newFileName = `${file.name.split('.')[0]}_${tempNumber + 1}.${
            file.name.split('.')[1]
          }`;
          props.handleTempNumber('challanTempNumber');
          setTempNumber(tempNumber + 1);
        }
        // Do whatever you want with the file contents
        const newArr = {
          grFileData: reader.result,
          grFileName: newFileName,
          grFileType: 'buffer',
          schrockenGRFileId: '',
          grFileHash: '',
          markForDelete: false,
        };

        props.handlingFiles(newArr, 'challanFiles');
      };
    });
  };

  return (
    <React.Fragment>
      <Grid container justify='center' spacing={8} className={classes.padding}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Enter Challan Number (optional)
          </Typography>
          <InputNumber
            name='challanNumber'
            type='text'
            value={
              isAddRecord !== true
                ? challanInformation.challanNumber
                : challanNumber
            }
            onChange={
              isAddRecord !== true
                ? (e, name) => handleChange(e, 'challanNumber')
                : (e) => handleChangeChallanNumber(e)
            }
            placeholder='Challan Number'
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Select the Challan Date (optional)
          </Typography>

          <CustomDatePickerMui
            name='challanDate'
            allowKeyboardControl={false}
            value={
              isAddRecord !== true
                ? challanInformation.challanDate
                : challanDate
            }
            onChange={
              isAddRecord !== true
                ? (date) => handleDateChange(date, 'challanDate')
                : (date) => handleChangeChallanDate(date)
            }
            disableFuture={true}
          />
        </Grid>
      </Grid>
      {/**E way bill */}
      {ewayBillEnabled && (
        <Grid
          container
          justify='center'
          spacing={8}
          className={classes.padding}
        >
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='body2' className={classes.font}>
              Enter E-Way Bill Number (optional)
            </Typography>
            <InputNumber
              name='ewayBillNumber'
              type='text'
              placeholder='E-Way Bill Number'
              value={
                isAddRecord !== true
                  ? challanInformation.ewayBillNumber
                  : ewayBillNumber
              }
              onChange={
                isAddRecord !== true
                  ? (e, name) => handleChange(e, 'ewayBillNumber')
                  : (e) => setEwayBillNumber(e.target.value)
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='body2' className={classes.font}>
              Select E-Way Bill Date (optional)
            </Typography>

            <CustomDatePickerMui
              allowKeyboardControl={false}
              name='ewayBillDate'
              value={
                isAddRecord !== true
                  ? challanInformation.ewayBillDate
                  : ewayBillDate
              }
              onChange={
                isAddRecord !== true
                  ? (date) => handleDateChange(date, 'ewayBillDate')
                  : (date) =>
                      setEwayBillDate(date !== null ? date.toISOString() : date)
              }
              disableFuture={true}
            />
          </Grid>
        </Grid>
      )}
      <Grid container justify='center'>
        <Grid item xs={12} sm={12} md={12}>
          <DropFilesAttach
            title='Upload Challan (optional)'
            handleUploadFiles={
              isAddRecord !== true ? fileHandling : LocalFileHandling
            }
          />
        </Grid>
      </Grid>

      {!isAddRecord && (
        <Grid container className={classes.padding}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='body2' className={classes.font}>
              Uploaded Document List
            </Typography>

            <ShowFiles
              files={challanInformation.challanFiles}
              handlingDeleteFile={parentHandlingDeleteFile}
              name='challanFiles'
              isUploadFile={true}
            />
          </Grid>
        </Grid>
      )}

      {isAddRecord && (
        <Grid container className={classes.padding}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='body2' className={classes.font}>
              Uploaded Document List
            </Typography>

            <ShowFiles
              files={challanFiles}
              handlingDeleteFile={handlingDeleteFile}
              isUploadFile={true}
            />
          </Grid>
        </Grid>
      )}

      {isAddRecord && (
        <Grid container className={classes.padding}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ textAlign: 'center', paddingTop: '1rem' }}
          >
            <StyledButton
              onClick={handleSaveChallan}
              style={{ padding: '0.25rem 2rem' }}
            >
              Save
            </StyledButton>
          </Grid>
        </Grid>
      )}
      <Dialog open={dupFilePrompt} fullWidth>
        <DuplicateFilePrompt
          handleYesButton={handleFileCopy}
          handleClose={handleClose}
        />
      </Dialog>
    </React.Fragment>
  );
};
export default EditChallanInfo;
