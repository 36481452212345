import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  //1
  paper: {
    width: '100%',
    boxShadow: 'none',
  },
  //2
  table: {
    minWidth: 1200,
    border: '1px solid #C6C6C6',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  supplied: {
    textDecoration: 'underline',
    color: 'blue',
    paddingTop: '0.2rem',
  },
  statusBox: {
    textAlign: 'center',
    padding: '0.25rem',
    borderRadius: '0.45rem',
    fontSize: '0.875rem',
  },
  logo: {
    display: 'flex',
    border: '1px solid #000000',
    borderRadius: '8%',
    padding: '0.3rem',
  },
  logoHeight: {
    height: 'inherit',
  },
  multipleLogo: {
    border: '1px solid #7d7c7c',
    borderRadius: '4px',
    padding: '0.3rem',
    height: 'inherit',
    marginLeft: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
  multipleLogoDisable: {
    border: '1px solid #7d7c7c',
    borderRadius: '4px',
    padding: '0.3rem',
    height: 'inherit',
    background: '#DCDCDC',
    marginLeft: '0.5rem',
    cursor: 'pointer',
    // '&:hover': {
    //   backgroundColor: 'black',
    //   color: 'white',
    //   border: '1px solid #FFF',
    // },
  },
  emptyLogo: {
    padding: '0.3rem',
    height: 'inherit',
    marginLeft: '0.5rem',
    paddingLeft: '4rem',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  singleAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  nameLogo: {
    border: '1px solid #707070',
    borderRadius: '100%',
    width: 'max-content',
    padding: '0.3rem',
    backgroundColor: '#EFEFEF',
    fontSize: '0.75rem',
  },
  hidden: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  response: {
    width: '5%',
    maxWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  changingWidth: {
    width: '6%',
    // maxWidth: 0,
    [theme.breakpoints.between('sm', 'xl')]: {
      width: '2%',
    },
    // [theme.breakpoints.down('sm')]: {
    //   width: '6%',
    //   maxWidth: 0,
    // },
  },
  changeStatusWidth: {
    width: '5%',
    // maxWidth: 0,
    [theme.breakpoints.between('sm', 'xl')]: {
      width: '2%',
      maxWidth: '6.3rem',
    },
    // [theme.breakpoints.down('sm')]: {
    //   width: '6%',
    //   maxWidth: 0,
    // },
  },
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-end',
    '&:hover': {
      color: 'red',
    },
  },
}));
