import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MinimizeIcon from "@material-ui/icons/Minimize";

import ChatBox from "./ChatBox";

const styles = {
  gridAlign: {
    position: "fixed",
    bottom: 0,
    width: "20rem",
    right: "1.2rem",
    border: "1px solid #ded5d5",
    backgroundColor: "#E5E5E5"
  },
  chatHeader: {
    padding: "0.75rem",
    color: "#ffffff",
    backgroundColor: "#2196f3",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer"
  },
  minimizeIcon: {
    padding: 0,
    marginTop: "-1rem",
    color: "#ffffff"
  }
};

class index extends Component {
  state = {
    chatBoxOpen: false
  };

  openChat = () => {
    this.setState({ chatBoxOpen: !this.state.chatBoxOpen });
  };

  render() {
    const { chatBoxOpen } = this.state;
    const { classes } = this.props;
    const currentItem = this.props.match.params.lineItemNumber
      ? `Chat for Line ${this.props.match.params.lineItemNumber}`
      : `Chat for PO ${this.props.match.params.purchaseOrderNumber}`;
    return (
      <Grid item xs={12} sm={4} className={classes.gridAlign}>
        {chatBoxOpen === true ? (
          <ChatBox
            openChat={this.openChat}
            currentItem={currentItem}
            {...this.props}
          />
        ) : (
          <Grid className={classes.chatHeader} onClick={this.openChat}>
            <Typography variant="body2">{currentItem}</Typography>

            <MinimizeIcon className={classes.minimizeIcon} />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(index));
