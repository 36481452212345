import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
// import { useStyles } from '../../sasl/scheduleAgreements/saslStyles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import StyledButton from '../../models/Button';
import clsx from 'clsx';
import Pagination from '@material-ui/lab/Pagination';
// for radio
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BackDrop from '../../models/BackDrop';
import Parameters from './Parameters';
import {
  BatchAuditheader,
  DSauditHeader,
  SAauditHeader,
  SLauditHeader,
} from './headers';
import TableData from './TableData';
import {
  saslBatchAudit,
  saAuditReport,
  slAuditReport,
  fetchslLines,
  fetchDSList,
  dsAuditReport,
} from '../../../../containers/actions/common/saslAction';
import { getAllConfigurationFlags } from '../../../../containers/actions/common/reportsActions';
import RowsPerPage from '../../models/inputs/RowsPerPage';

const Styles = (theme) => ({
  mgTp: { marginTop: '1.5rem' },
  search: {
    padding: '2px 16px',
    backgroundColor: '#e36e39',
    color: 'white',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid black',
    },
  },
  searchFld: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  flxEd: {
    justifyContent: 'flex-end',
  },
  hgText: {
    fontWeight: 500,
    paddingRight: theme.spacing(1),
  },
  outlined: {
    padding: theme.spacing(1),
  },
});

const recordsArray = [
  { recordTypeName: 'Batch Audit', recordTypeID: 'vendorBatchNumber' },
  {
    recordTypeName: 'Delivery Schedule Audit',
    recordTypeID: 'saDeliverySchedule',
  },
  { recordTypeName: 'Schedule Agreement Audit', recordTypeID: 'sa' },
  { recordTypeName: 'Schedule Line Audit', recordTypeID: 'saLine' },
];

class SAAuditIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportType: 'vendorBatchNumber',
      auditTrailObj: {
        saNumber: '',
        saLine: 'null',
        ds: 'null',
        vendorBatchNumber: '',
      },
      data: [],
      showTable: false,
      resError: null,
      noRowsPerPage: 5,
      currentPage: 1,
      sLOptions: [],
      slHelperText: '',
      dsOptions: [],
      dsHelperText: 'Enter Batch Number',
    };
  }

  componentDidMount() {
    if (!this.props.qaApprovalFlag) {
      this.props.getAllConfigurationFlags();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // saAuditTableData
    if (
      this.props.saAuditTableData !== prevProps.saAuditTableData &&
      this.props.saAuditTableData.status === true
    ) {
      this.setState({
        showTable: true,
        resError: null,
        data: this.props.saAuditTableData.data,
      });
    }
    if (
      this.props.saAuditTableData !== prevProps.saAuditTableData &&
      this.props.saAuditTableData.status === false
    ) {
      if (typeof this.props.saAuditTableData.errorDescription === 'object') {
        let errorKey = Object.keys(
          this.props.saAuditTableData.errorDescription
        )[0];
        this.setState({
          showTable: false,
          resError: this.props.saAuditTableData.errorDescription[errorKey],
        });
      } else {
        this.setState({
          showTable: false,
          resError: this.props.saAuditTableData.errorDescription,
        });
      }
    }
    if (
      this.props.auditTableData !== prevProps.auditTableData &&
      this.props.auditTableData.status === true
    ) {
      this.setState({
        showTable: true,
        resError: null,
        data: this.props.auditTableData.data,
      });
    }
    if (
      this.props.auditTableData !== prevProps.auditTableData &&
      this.props.auditTableData.status === false
    ) {
      if (typeof this.props.auditTableData.errorDescription === 'object') {
        let errorKey = Object.keys(
          this.props.auditTableData.errorDescription
        )[0];
        this.setState({
          showTable: false,
          resError: this.props.auditTableData.errorDescription[errorKey],
        });
      } else {
        this.setState({
          showTable: false,
          resError: this.props.auditTableData.errorDescription,
        });
      }
    }
    //slLines
    if (
      this.props.slLines !== prevProps.slLines &&
      this.props.slLines.status === true
    ) {
      this.setState({
        sLOptions: this.props.slLines.data,
        slHelperText: '',
      });
    }
    if (
      this.props.slLines !== prevProps.slLines &&
      this.props.slLines.status === false
    ) {
      if (typeof this.props.slLines.errorDescription === 'object') {
        let errorKey = Object.keys(this.props.slLines.errorDescription)[0];
        this.setState({
          sLOptions: [],
          resError: this.props.slLines.errorDescription[errorKey],
        });
      } else {
        this.setState({
          sLOptions: [],
          resError: this.props.slLines.errorDescription,
        });
      }
    }
    //slTableData
    if (
      this.props.slAuditTableData !== prevProps.slAuditTableData &&
      this.props.slAuditTableData.status === true
    ) {
      this.setState({
        showTable: true,
        resError: null,
        data: this.props.slAuditTableData.data,
      });
    }
    if (
      this.props.slAuditTableData !== prevProps.slAuditTableData &&
      this.props.slAuditTableData.status === false
    ) {
      if (typeof this.props.slAuditTableData.errorDescription === 'object') {
        let errorKey = Object.keys(
          this.props.slAuditTableData.errorDescription
        )[0];
        this.setState({
          showTable: false,
          resError: this.props.slAuditTableData.errorDescription[errorKey],
        });
      } else {
        this.setState({
          showTable: false,
          resError: this.props.slAuditTableData.errorDescription,
        });
      }
    }
    if (
      this.props.fetchDS !== prevProps.fetchDS &&
      this.props.fetchDS.status === true
    ) {
      this.setState({
        dsOptions: this.props.fetchDS.data,
      });
    }
    if (
      this.props.fetchDS !== prevProps.fetchDS &&
      this.props.fetchDS.status === false
    ) {
      if (typeof this.props.fetchDS.errorDescription === 'object') {
        let errorKey = Object.keys(this.props.fetchDS.errorDescription)[0];
        this.setState({
          showTable: false,
          resError: this.props.fetchDS.errorDescription[errorKey],
          slHelperText: this.props.fetchDS.errorDescription[errorKey],
        });
      } else {
        this.setState({
          showTable: false,
          resError: this.props.fetchDS.errorDescription,
          slHelperText: this.props.fetchDS.errorDescription,
        });
      }
    }
    //dstableData
    if (
      this.props.dsAuditTableData !== prevProps.dsAuditTableData &&
      this.props.dsAuditTableData.status === true
    ) {
      this.setState({
        showTable: true,
        data: this.props.dsAuditTableData.data,
        resError: null,
        slHelperText: '',
      });
    }

    if (
      this.props.dsAuditTableData !== prevProps.dsAuditTableData &&
      this.props.dsAuditTableData.status === false
    ) {
      if (typeof this.props.dsAuditTableData.errorDescription === 'object') {
        let errorKey = Object.keys(
          this.props.dsAuditTableData.errorDescription
        )[0];
        this.setState({
          showTable: false,
          resError: this.props.dsAuditTableData.errorDescription[errorKey],
          slHelperText: '',
        });
      } else {
        this.setState({
          showTable: false,
          resError: this.props.dsAuditTableData.errorDescription,
          slHelperText: '',
        });
      }
    }
  }

  selectReportType = (event) => {
    this.setState({
      reportType: event.target.value,
      auditTrailObj: {
        saNumber: '',
        saLine: 'null',
        ds: 'null',
        vendorBatchNumber: '',
      },
      showTable: false,
      resError: null,
      sLOptions: [],
      slHelperText: '',
      dsOptions: [],
    });
  };

  handleChange = (e) => {
    this.setState({
      resError: null,
      auditTrailObj: {
        ...this.state.auditTrailObj,
        [e.target.name]: e.target.value,
        slHelperText: '',
        saLine: 'null',
        ds: 'null',
      },
      showTable: false,
      sLOptions: [],
      slHelperText: '',
      dsOptions: [],
    });

    if (
      (e.target.name === 'saNumber' &&
        e.target.value !== '' &&
        this.state.reportType === 'saLine') ||
      (e.target.name === 'saNumber' &&
        e.target.value !== '' &&
        this.state.reportType === 'saDeliverySchedule')
    ) {
      this.setState({
        sLOptions: [],
        slHelperText: '',
        dsOptions: [],
        showTable: false,
      });
      this.debouncedLog(e.target.value);
    }
  };

  handleTableHeaders = () => {
    let funcHeader = [];
    switch (this.state.reportType) {
      case 'vendorBatchNumber':
        return (funcHeader = BatchAuditheader(
          this.props.qaApprovalFlag.data.features.qualityApprovalEnabled
        ));

      case 'saDeliverySchedule':
        return (funcHeader = DSauditHeader);

      case 'sa':
        return (funcHeader = SAauditHeader);

      case 'saLine':
        return (funcHeader = SLauditHeader);

      default:
        return funcHeader;
    }
  };

  handleSubmit = () => {
    let { auditTrailObj, reportType } = this.state;
    switch (reportType) {
      case 'vendorBatchNumber':
        if (auditTrailObj.vendorBatchNumber !== '') {
          let data = {
            vendorBatchNumber: this.state.auditTrailObj.vendorBatchNumber,
          };
          return this.props.saslBatchAudit(data);
        }
        return this.setState({
          resError: 'Please Enter Batch Number',
        });
      case 'saDeliverySchedule':
        if (
          auditTrailObj.saNumber !== '' &&
          auditTrailObj.saLine !== 'null' &&
          auditTrailObj.ds !== 'null'
        ) {
          let saDeliveryData = {
            scheduleAgreementNumber: this.state.auditTrailObj.saNumber,
            schrockenDeliveryScheduleID: this.state.auditTrailObj.ds,
          };
          return this.props.dsAuditReport(saDeliveryData);
        }
        return this.setState({
          resError: 'Please Enter SA Number/Scheduled Line/Delivery Schedule',
        });
      case 'sa':
        if (auditTrailObj.saNumber !== '') {
          let data = {
            scheduleAgreementNumber: this.state.auditTrailObj.saNumber,
          };
          return this.props.saAuditReport(data);
        }
        return this.setState({
          resError: 'Please Enter SA Number',
        });
      case 'saLine':
        if (auditTrailObj.saNumber !== '' && auditTrailObj.saLine !== 'null') {
          let data = {
            scheduleAgreementNumber: this.state.auditTrailObj.saNumber,
            schrockenSLID: this.state.auditTrailObj.saLine,
          };
          return this.props.slAuditReport(data);
        }
        return this.setState({
          resError: 'Please Enter SA Number/Select Scheduled Line',
        });
      default:
        return null;
    }
  };

  handleSetRows = (event) => {
    this.setState({
      noRowsPerPage: event.target.value,
      currentPage: 1,
    });
  };

  handlePageChange = (event, page) => {
    this.setState({
      currentPage: page,
    });
  };

  //debounce based on SA number
  debounce = (callback, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    };
  };
  //api call to fetch line
  debouncedLog = this.debounce(
    (text) =>
      this.props.fetchslLines({
        scheduleAgreementNumber: text,
        getDataForDS: false,
      }),
    2000
  );

  //api call to fetch DS
  dSDebouncedLog = this.debounce(
    (text) =>
      this.props.fetchDSList({
        scheduleAgreementNumber: this.state.auditTrailObj.saNumber,
        getDataForDS: true,
        scheduleLineItemNumber: this.state.auditTrailObj.saLine,
      }),
    2000
  );

  handleSelectChange = (e) => {
    this.setState({
      resError: null,
      auditTrailObj: {
        ...this.state.auditTrailObj,
        [e.target.name]: e.target.value,
      },
      showTable: false,
      slHelperText: '',
    });
    if (
      e.target.name === 'saLine' &&
      e.target.value !== '' &&
      this.state.reportType === 'saDeliverySchedule'
    ) {
      this.setState({
        // dsOptions: [],
        // dsHelperText: '',
        showTable: false,
        slHelperText: '',
        ds: 'null',
        dsOptions: [],
      });
      this.dSDebouncedLog(e.target.value);
    }
  };

  render() {
    const { classes } = this.props;

    const {
      auditTrailObj,
      data,
      showTable,
      resError,
      noRowsPerPage,
      currentPage,
    } = this.state;

    let showPagination = data ? (data.length > 0 ? true : false) : false;

    let records = currentPage * noRowsPerPage;

    let filteredAuditData = data;

    let filteredData = filteredAuditData
      ? data.slice(records - noRowsPerPage, records)
      : [];

    let pages = filteredAuditData
      ? Math.ceil(filteredAuditData.length / noRowsPerPage)
      : null;

    let noPages = pages ? pages : 1;

    return (
      <React.Fragment>
        <BackDrop />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant='body2'
              style={{ fontSize: '1rem', fontWeight: 500 }}
            >
              Select Record Type:
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} lg={8} xl={6}>
            <FormControl component='fieldset'>
              <RadioGroup
                aria-label='report-filter'
                name='recordTypeID'
                row
                value={this.state.reportType}
                onChange={this.selectReportType}
              >
                {recordsArray.map((record, index) => (
                  <FormControlLabel
                    value={record.recordTypeID}
                    control={<Radio color='primary' />}
                    label={record.recordTypeName}
                    key={index}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Divider />
        <Grid container alignItems='center' className={classes.mgTp}>
          <Grid
            container
            justify='center'
            style={{
              padding: '1rem 0',
              display: resError === null ? 'none' : 'inherit',
            }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant='h6'
                style={{
                  border: '1px solid #FF0000',
                  backgroundColor: '#FFF0F0',
                  opacity: '1',
                  textAlign: 'center',
                  padding: '0.8rem',
                  fontSize: '0.875rem',
                }}
              >
                {resError}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant='body2'
              style={{
                fontSize: '1rem',
                fontWeight: 500,
                marginBottom: '1rem',
                paddingTop: '1rem',
              }}
            >
              Enter Record Details:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction='row' alignItems='center' spacing={2}>
              <Parameters
                recordTypeID={this.state.reportType}
                auditTrailObj={auditTrailObj}
                handleChange={this.handleChange}
                handleSelectChange={this.handleSelectChange}
                sLOptions={this.state.sLOptions}
                slHelperText={this.state.slHelperText}
                dsOptions={this.state.dsOptions}
                dsHelperText={this.state.dsHelperText}
              />
              <Grid item xs={6} sm={6} md={2}>
                <StyledButton
                  color='primary'
                  type='submit'
                  className={classes.search}
                  onClick={this.handleSubmit}
                  style={{ borderRadius: '2rem' }}
                >
                  View Report{' '}
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          {/* pagination */}
          {showTable === true && (
            <Grid
              item
              xs={4}
              sm={11}
              md={12}
              className={clsx(classes.searchFld, classes.flxEd)}
            >
              <RowsPerPage
                value={noRowsPerPage}
                onChange={this.handleSetRows}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '1rem' }}>
            {showTable === true && (
              <TableData
                header={this.handleTableHeaders()}
                data={filteredData}
                reportType={this.state.reportType}
                qaApprovalFlag={
                  this.props.qaApprovalFlag.data.features.qualityApprovalEnabled
                }
              />
            )}
          </Grid>

          {showTable === true && showPagination === true && (
            <Grid
              container
              alignItems='center'
              justify='center'
              style={{ paddingTop: '1rem' }}
            >
              <Pagination
                page={currentPage}
                name='currentPage'
                onChange={(event, page) => this.handlePageChange(event, page)}
                count={noPages}
                variant='outlined'
                shape='rounded'
              />
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auditTableData: state.pcmp.common.sasl.auditReport,
  saAuditTableData: state.pcmp.common.sasl.saAuditReport,
  slAuditTableData: state.pcmp.common.sasl.slAuditReport,
  slLines: state.pcmp.common.sasl.fetchSLLines,
  fetchDS: state.pcmp.common.sasl.fetchDS,
  dsAuditTableData: state.pcmp.common.sasl.dsAudit,
  qaApprovalFlag: state.pcmp.common.reports?.rmpmGrnFeatures,
});

export default connect(mapStateToProps, {
  saslBatchAudit,
  saAuditReport,
  slAuditReport,
  fetchslLines,
  fetchDSList,
  dsAuditReport,
  getAllConfigurationFlags,
})(withStyles(Styles)(SAAuditIndex));
