import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import jwtDecode from 'jwt-decode';

import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PublishIcon from '@material-ui/icons/Publish';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MomentUtils from '@date-io/moment';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloseIcon from '@material-ui/icons/Close';

import { fetchTeamMembers } from '../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    fontWeight: 500,
    marginBottom: '0.5rem',
  },
  title2: {
    textAlign: 'center',
    fontWeight: 500,
    marginBottom: '1rem',
    marginTop: '2rem',
  },
  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '3px',
  },
  inputLabel: {
    marginBottom: '0.5rem',
    fontSize: '85%',
    fontWeight: '500',
  },
  requiredStar: {
    color: 'red',
  },
  nextBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '2rem',
    borderRadius: '2.5rem',
    textTransform: 'capitalize',
    marginLeft: '4.5rem',
  },
  arrowIcon: {
    fontSize: 'small',
    paddingTop: '0.4rem',
  },
  fileIcon: {
    cursor: 'pointer',
    marginLeft: '33rem',
    fontSize: 'larger',
  },
  clear: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textAlign: 'end',
  },
  files: {
    boder: '1px solid #707070',
    backgroundColor: '#F7F7F7',
    textAlign: 'center',
    padding: '1rem',
  },
}));

const AssignEvent = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const [state, setState] = React.useState({
    errors: {},
  });

  const {
    CMOMember,
    teamOptions,
    selectedTeamLead,
    selectedTeamMembers,
    customFiles,
    resolutionDescription,
    estimatedResolutionDate,
    eventReportedDate,
  } = props.stateData;

  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD/MM/YYYY';

  const handleOnChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: null },
    }));
    props.handleChangeData(name, value);
  };

  const { collabType } = localStorage;
  const tokenData = jwtDecode(localStorage.getItem('authToken'));

  useEffect(() => {
    if (didMountRef.current) {
    } else {
      props.fetchTeamMembers({
        organizationId:
          collabType === 'CMO'
            ? tokenData.organization_id
            : CMOMember.organizationId,
      });
      didMountRef.current = true;
    }
  }, []);

  const handleFileUpload = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        let fileObject = {
          name: file.name,
          data: reader.result,
          customFileType: 'buffer',
          schrockenFileID: '',
          schrockenCustomFileID: '',
          customFileHash: '',
        };
        props.handleChangeData('customFiles', [...customFiles, fileObject]);
      };
    });
  };

  const handleFileDelete = (index) => {
    const edit_newFiles = customFiles.filter((file, i) => i !== index);
    props.handleChangeData('customFiles', edit_newFiles);
  };

  const handleValidations = () => {
    const errorsData = {};
    let isError = false;

    if (selectedTeamLead === '') {
      errorsData.teamLead = 'Please select team lead';
      isError = true;
    }

    if (selectedTeamMembers.length === 0) {
      errorsData.teamMembers = 'Please select team Members';
      isError = true;
    }
    if (estimatedResolutionDate === null) {
      errorsData.estimatedResolutionDate = 'Enter estimate resolution date';
      isError = true;
    }
    if (isError) {
      setState((prevState) => ({
        ...prevState,
        errors: errorsData,
      }));
    } else {
      props.handleScreenChange(3);
    }
  };
  const handleClearData = () => {
    props.handleChangeData('selectedTeamLead', '');
    props.handleChangeData('selectedTeamMembers', []);
    props.handleChangeData('customFiles', []);
    props.handleChangeData('resolutionDescription', '');
    props.handleChangeData('estimatedResolutionDate', null);
  };

  return (
    <>
      <Box width='40%' margin='auto'>
        <Box>
          <Typography variant='h5' className={classes.title}>
            Assign Team for Investigation
          </Typography>
          <hr className={classes.hrLine} />
        </Box>
        <Box justifyContent='space-between'>
          <Box marginTop={2} width='100%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Search or Select a team lead from the list{' '}
              <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              name='teamLead'
              options={teamOptions}
              getOptionLabel={(option) =>
                option.userName ? `${option.userName}(${option.roleName})` : ''
              }
              value={selectedTeamLead}
              onChange={(event, value) => {
                handleOnChange('selectedTeamLead', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Start typing to search and select'
                  error={!!state.errors.teamLead}
                  helperText={state.errors.teamLead}
                />
              )}
            />
          </Box>

          <Box marginTop={2} width='100%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Search or Select a team member from the list
              <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              name='teamMembers'
              multiple={true}
              options={teamOptions.filter(
                (member) =>
                  member.emailAddress !== selectedTeamLead?.emailAddress
              )}
              getOptionLabel={(option) =>
                option.userName ? `${option.userName}(${option.roleName})` : ''
              }
              value={selectedTeamMembers}
              onChange={(event, value) => {
                handleOnChange('selectedTeamMembers', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Start typing to search and select'
                  error={!!state.errors.teamMembers}
                  helperText={state.errors.teamMembers}
                />
              )}
            />
          </Box>
        </Box>

        <Box marginTop='2rem'>
          <Typography variant='h5' className={classes.title}>
            Upload related documents
          </Typography>
          <hr className={classes.hrLine} />
        </Box>

        <Box marginTop={2} width='100%'>
          <Typography variant='body2' className={classes.inputLabel}>
            Upload necessary files for this event
          </Typography>
          <Dropzone
            onDrop={(files) => {
              handleFileUpload(files);
            }}
            accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx,.zip'
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps({
                  className: 'dropzone',
                  onDrop: (event) => event.preventDefault(),
                })}
                className={classes.files}
                style={{ border: '1px solid #707070' }}
              >
                <input {...getInputProps()} />
                <Typography variant='body2' style={{ cursor: 'pointer' }}>
                  Drag &amp; drop or click here to upload files
                </Typography>
                <InputAdornment position='end' className={classes.fileIcon}>
                  <PublishIcon
                    fontSize='large'
                    style={{ marginBottom: '1rem' }}
                  />
                </InputAdornment>
              </div>
            )}
          </Dropzone>

          <Typography variant='body' className={classes.font}>
            {customFiles.map((file, i) => (
              <p style={{ color: 'blue' }}>
                <u>{file.name}</u>
                <span style={{ verticalAlign: 'sub' }}>
                  <CloseIcon
                    fontSize='small'
                    color='error'
                    style={{
                      paddingLeft: '0.5rem',
                      paddingTop: '0.5rem',
                      cursor: 'pointer',
                      width: '19px',
                    }}
                    onClick={() => handleFileDelete(i)}
                  />
                </span>
              </p>
            ))}
          </Typography>
        </Box>

        <Box marginTop='2rem'>
          <Typography variant='h5' className={classes.title}>
            Resolution action and date
          </Typography>
          <hr className={classes.hrLine} />
        </Box>
        <Box paddingTop={2}>
          <Typography variant='body2' className={classes.inputLabel}>
            Description of immediate actions (Optional)
          </Typography>
          <TextField
            rows={3}
            multiline
            fullWidth
            value={resolutionDescription}
            onChange={(e) => {
              handleOnChange('resolutionDescription', e.target.value);
            }}
            variant='outlined'
            actiondescription
            placeholder='Enter the immediate containment or remedial actions taken for this event'
          />
        </Box>
        <Box width='47%' marginTop={2}>
          <Typography variant='body2' className={classes.inputLabel}>
            Estimated resolution date
            <span className={classes.requiredStar}>*</span>
          </Typography>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              variant='inline'
              inputVariant='outlined'
              format={dateFormat}
              fullWidth
              placeholder={dateFormat}
              size='small'
              minDate={eventReportedDate}
              autoOk={true}
              name='estimatedResolutionDate'
              value={estimatedResolutionDate}
              onChange={(value) =>
                handleOnChange('estimatedResolutionDate', value)
              }
              error={!!state.errors.estimatedResolutionDate}
              helperText={state.errors.estimatedResolutionDate}
              InputProps={{
                endAdornment: (
                  <DateRangeIcon
                    style={{ color: '#0000008a', cursor: 'pointer' }}
                  />
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          marginTop='4rem'
          marginBottom='4rem'
        >
          <Box display='flex'>
            <Typography
              variant='subtitle1'
              onClick={() => props.handleScreenChange(1)}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontWeigh: '700',
                display: 'inline-flex',
              }}
            >
              <ArrowBackIosIcon className={classes.arrowIcon} />{' '}
              <span> Back</span>
            </Typography>
          </Box>
          <Box width='70%' textAlign='center'>
            <Button
              className={classes.nextBtn}
              variant='contained'
              color='primary'
              onClick={handleValidations}
              endIcon={<NavigateNextIcon />}
            >
              Next
            </Button>
          </Box>
          <Box marginTop='0.5rem'>
            <Typography
              variant='body2'
              className={classes.clear}
              onClick={handleClearData}
            >
              Clear all data
            </Typography>
            <Typography variant='caption' style={{ marginLeft: '-6rem' }}>
              (This will remove all data entered)
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default connect(null, {
  fetchTeamMembers,
})(AssignEvent);
