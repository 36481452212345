import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import EventTwoToneIcon from '@material-ui/icons/Event';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import usePrevious from '../../../../../helpers/previousProps';

import { fetchReportedByList } from '../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    fontWeight: 500,
    marginBottom: '0.5rem',
  },
  requiredStar: {
    color: 'red',
  },
  inputLabel: {
    marginBottom: '0.5rem',
    fontSize: '85%',
    fontWeight: '500',
  },
  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '3px',
  },
  nextBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '2rem',
    borderRadius: '2.5rem',
    textTransform: 'capitalize',
    marginTop: '2rem',
    marginRight: '2rem',
  },
  clear: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textAlign: 'end',
    marginTop: '2rem',
  },
}));

const EventDetails = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const [state, setState] = useState({
    errors: {},
    reportedByOptions: [],
  });
  const {
    eventTitle,
    eventDescription,
    eventOccurredDate,
    eventReportedDate,
    selectedReportedBy,
    qualityEvent,
  } = props.stateData;

  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD/MM/YYYY';

  const { reportedByList } = props;
  const prevReportedByList = usePrevious(reportedByList);
  useEffect(() => {
    if (didMountRef.current) {
      if (reportedByList !== prevReportedByList) {
        setState((prevState) => ({
          ...prevState,
          reportedByOptions: reportedByList,
        }));
      }
    } else {
      props.fetchReportedByList();
      didMountRef.current = true;
    }
  }, [reportedByList, prevReportedByList]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: null },
    }));
    props.handleChangeData(name, value);
  };

  const handleOnChangeData = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: null },
    }));
    props.handleChangeData(name, value);
  };

  const handleValidations = () => {
    const errorsData = {};
    let isError = false;
    if (eventTitle === '') {
      errorsData.eventTitle = 'Please add a title for the event';
      isError = true;
    }
    if (eventDescription === '') {
      errorsData.eventDescription = 'Please add event description';
      isError = true;
    }
    if (eventOccurredDate === null) {
      errorsData.eventOccurredDate = 'Please provide date of occurence.';
      isError = true;
    }
    if (eventReportedDate === null) {
      errorsData.eventReportedDate = 'Please provide the reported date  ';
      isError = true;
    }
    if (selectedReportedBy === '' || selectedReportedBy === null) {
      errorsData.selectedReportedBy =
        'Please select the user who reported the event.';
      isError = true;
    }
    if (isError) {
      setState((prevState) => ({
        ...prevState,
        errors: errorsData,
      }));
    } else {
      props.handleScreenChange(1);
    }
  };

  const handleClearData = () => {
    props.handleChangeData('eventTitle', '');
    props.handleChangeData('eventDescription', '');
    props.handleChangeData('eventOccurredDate', null);
    props.handleChangeData('eventReportedDate', null);
    props.handleChangeData('selectReportedBy', '');
  };

  return (
    <>
      <Box width='40%' margin='auto'>
        <Typography variant='h5' className={classes.title}>
          {qualityEvent.eventType + ' ' + `Details`}
        </Typography>
        <hr className={classes.hrLine} />
        <Box>
          <Typography variant='body2' className={classes.inputLabel}>
            Title <span className={classes.requiredStar}>*</span>
          </Typography>
          <TextField
            fullWidth
            name='eventTitle'
            value={eventTitle}
            onChange={handleOnChange}
            variant='outlined'
            size='small'
            placeholder='Add a title for the event (150 characters)'
            error={!!state.errors.eventTitle}
            helperText={state.errors.eventTitle}
            inputProps={{ maxLength: 150 }}
          />
        </Box>
        <Box paddingTop={2}>
          <Typography variant='body2' className={classes.inputLabel}>
            Description <span className={classes.requiredStar}>*</span>
          </Typography>
          <TextField
            minRows={4}
            multiline
            fullWidth
            name='eventDescription'
            value={eventDescription}
            onChange={handleOnChange}
            variant='outlined'
            placeholder='Enter the event description in as much details as possible (1250 characters)'
            error={!!state.errors.eventDescription}
            helperText={state.errors.eventDescription}
            inputProps={{ maxLength: 1250 }}
          />
        </Box>

        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          paddingTop={2}
        >
          <Box width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Select when the event occurred
              <span className={classes.requiredStar}>*</span>
            </Typography>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              style={{ width: '100%' }}
            >
              <DatePicker
                fullWidth
                variant='inline'
                inputVariant='outlined'
                format={dateFormat}
                name='eventOccurredDate'
                value={eventOccurredDate}
                onChange={(value) =>
                  handleOnChangeData('eventOccurredDate', value)
                }
                error={!!state.errors.eventOccurredDate}
                helperText={state.errors.eventOccurredDate}
                placeholder='Select date'
                size='small'
                autoOk={true}
                maxDate={new Date()}
                InputProps={{
                  endAdornment: (
                    <EventTwoToneIcon
                      style={{ color: '#0000008a', cursor: 'pointer' }}
                    />
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>

          <Box width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Select when the event was reported{' '}
              <span className={classes.requiredStar}>*</span>
            </Typography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                fullWidth
                variant='inline'
                inputVariant='outlined'
                format={dateFormat}
                name='eventReportedDate'
                value={eventReportedDate}
                onChange={(value) =>
                  handleOnChangeData('eventReportedDate', value)
                }
                error={!!state.errors.eventReportedDate}
                helperText={state.errors.eventReportedDate}
                placeholder='Select date'
                size='small'
                autoOk={true}
                minDate={eventOccurredDate}
                maxDate={new Date()}
                disabled={eventOccurredDate === null}
                InputProps={{
                  endAdornment: (
                    <EventTwoToneIcon
                      style={{ color: '#0000008a', cursor: 'pointer' }}
                    />
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Box>

        <Box marginTop={2} width='47%'>
          <Typography variant='body2' className={classes.inputLabel}>
            Reported By <span className={classes.requiredStar}>*</span>
          </Typography>
          <Autocomplete
            size='small'
            fullWidth
            variant='outlined'
            getOptionLabel={(option) =>
              option.name ? `${option.name} (${option.roleName})` : ''
            }
            name='selectedReportedBy'
            value={selectedReportedBy}
            getOptionSelected={(option) =>
              `${option.name} (${option.roleName})`
            }
            options={state.reportedByOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                placeholder='Start Typing to search and select'
                error={!!state.errors.selectedReportedBy}
                helperText={state.errors.selectedReportedBy}
              />
            )}
            onChange={(event, value) => {
              handleOnChangeData('selectedReportedBy', value);
            }}
          />
        </Box>

        <Box
          display='flex'
          justifyContent='end'
          marginTop='22px'
          marginBottom='4rem'
          alignItem='center'
        >
          <Box width='29%' textAlign='center'>
            <Button
              className={classes.nextBtn}
              variant='contained'
              color='primary'
              onClick={handleValidations}
              endIcon={<NavigateNextIcon />}
            >
              next
            </Button>
          </Box>
          <Box>
            <Typography
              variant='body2'
              className={classes.clear}
              onClick={handleClearData}
            >
              Clear all data
            </Typography>
            <Typography variant='caption'>
              (This will remove all data entered)
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  reportedByList: state.pcmp.common.QMS.fetchReportedByList,
});

export default connect(mapStateToProps, {
  fetchReportedByList,
})(EventDetails);
