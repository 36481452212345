/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Typography,
  IconButton,
  Button,
  Toolbar,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";

import PurchaseOrderData from "./PurchaseOrderData";
import LineItemsTable from "./LineItemsTable";

const styles = {
  header: {
    backgroundColor: "#000000",
    color: "#ffffff",
    textAlign: "center",
  },
  flexGrow: {
    flexGrow: 1,
  },
  addButton: {
    marginTop: "1rem",
    backgroundColor: "green",
    borderRadius: "2rem",
    marginBottom: "1rem",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  },
};

class index extends Component {
  state = {
    buttonDisable: false,
    loading: true,
    previewScreen: false,
    errorScreen: false,
    errorMessage: "",
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.poData.poPreview.status) {
      this.setState({ loading: false, previewScreen: true });
    } else if (!nextProps.poData.poPreview.status) {
      this.setState({
        loading: false,
        errorScreen: true,
        errorMessage: nextProps.poData.poPreview.errorDescription,
      });
    }
    if (
      nextProps.errors &&
      nextProps.errors !== this.props.errors &&
      nextProps.errors.errorCode === 500
    ) {
      this.setState({
        isLoading: false,
        errorScreen: true,
        errorMessage: "Something went wrong. Please try again",
      });
    }
  }

  handleClick = () => {
    this.setState({ buttonDisable: true });
    this.props.addPurchaseOrder();
  };

  render() {
    const { togglePreviewScreen, classes } = this.props;
    const {
      buttonDisable,
      loading,
      errorScreen,
      previewScreen,
      errorMessage,
    } = this.state;
    return (
      <React.Fragment>
        <Toolbar className={classes.header}>
          <Typography variant="h5" className={classes.flexGrow}>
            Preview Purchase Order
          </Typography>
          <Typography
            variant="body2"
            style={{ cursor: "pointer" }}
            onClick={togglePreviewScreen}
          >
            Cancel
          </Typography>
          <IconButton
            style={{ color: "#ffffff" }}
            onClick={togglePreviewScreen}
          >
            <ClearIcon />
          </IconButton>
        </Toolbar>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "90vh",
            }}
          >
            <CircularProgress style={{ color: "#2196F3" }} thickness={7} />
          </div>
        ) : errorScreen ? (
          <Grid
            container
            spacing={4}
            style={{
              minHeight: "90vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                textAlign: "center",
              }}
            >
              <Typography variant="h5" style={{ color: "red" }}>
                {errorMessage}
              </Typography>
            </Grid>
          </Grid>
        ) : // <div
        //   style={{
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center",
        //     minHeight: "90vh"
        //   }}
        // >
        //   <Typography variant="h5" style={{ color: "red" }}>
        //     {errorMessage}
        //   </Typography>
        // </div>
        previewScreen ? (
          <React.Fragment>
            <PurchaseOrderData />
            <LineItemsTable />
            <div style={{ textAlign: "center" }}>
              <Button
                disabled={buttonDisable}
                variant="contained"
                className={classes.addButton}
                onClick={this.handleClick}
              >
                Add Purchase Order
              </Button>
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  poData: state.pcmp.pharmaCo.purchaseOrder,
  errors: state.errors,
});

export default withStyles(styles)(connect(mapStateToProps, null)(index));
