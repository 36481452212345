import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import StyledButton from '../../../../common/models/Button';
import Button from '@material-ui/core/Button';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import DropFilesAttach from '../models/files/DropFilesAttach';

const useStyles = makeStyles((theme) => ({
  document: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.5rem',
    paddingTop: '0rem',
  },
  button: {
    textTransform: 'none',
    border: '1px solid #000000',
    borderRadius: '2rem',
    padding: '0.5rem 2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  },

  closeText: {
    display: 'flex',
    alignItems: 'center',
    color: 'blue',
    textDecoration: 'underline',
  },
  validate: {
    padding: '1rem',
  },
  buttonGrid: {
    paddingTop: '2rem',
    textAlign: 'center',
  },
  dropzone: {
    padding: '1.25rem',
  },
}));
const UploadRecordFiles = (props) => {
  const classes = useStyles();
  const {
    handleNext,
    disableNextButton,
    downloadSampleFile,
    errorMessageColor,
  } = props;
  const FileHandling = (files) => {
    files.forEach((file) => {
      props.handlingFiles(file);
    });
  };

  return (
    <React.Fragment>
      <Grid container justify='center'>
        {(props.errors.label === true ||
          props.errors.rowColumn === true ||
          props.errors.extension === true) && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.dropzone}
          >
            <Typography
              variant='h6'
              style={{
                border: '1px solid #FF0000',
                backgroundColor: '#FFF0F0',
                opacity: '1',
                textAlign: 'center',
                padding: '0.8rem',
                fontSize: '0.875rem',
              }}
            >
              {props.resError.errMsg}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.dropzone}
        >
          <DropFilesAttach handleUploadFiles={FileHandling} singleFile />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {props.fileName !== '' ? (
            <Typography variant='body2' className={classes.document}>
              <span
                key={props.fileName}
                className={classes.closeText}
                style={{ paddingBottom: '0.5rem' }}
              >
                {props.fileName}
                <CloseIcon
                  fontSize='small'
                  color='error'
                  style={{ paddingLeft: '0.5rem', cursor: 'pointer' }}
                  onClick={() => props.handleDeleteRecord()}
                />
                <br />
              </span>
            </Typography>
          ) : (
            <Typography
              variant='body2'
              style={{ color: errorMessageColor }}
              className={classes.document}
            >
              -No Documents Uploaded-
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.validate}
        >
          <Typography
            style={{ fontWeight: 500, paddingLeft: '1.2rem' }}
            variant='body1'
          >
            Validation Criteria
          </Typography>
          <ul>
            <li>
              <Typography variant='body1'>
                Only excel files accepted (File extensions .csv or .xls or
                .xlsx)
              </Typography>
            </li>
            <li>
              <Typography variant='body1'>
                1st row should have labels only (as provided in the sample .csv
                file)
              </Typography>
            </li>
            <li>
              <Typography variant='body1'>
                Should not contain blank row or columns
              </Typography>
            </li>
          </ul>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.buttonGrid}
        >
          <StyledButton
            size='small'
            onClick={handleNext}
            disabled={disableNextButton}
            style={{
              paddingLeft: '4rem',
              paddingRight: '4rem',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
            }}
          >
            Next
          </StyledButton>
        </Grid>
        {props.isUploadRMPMGRN && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.buttonGrid}
            style={{ marginBottom: '2rem' }}
          >
            <Typography variant='body2' style={{ padding: '0.5rem' }}>
              Need help?
            </Typography>
            <Button
              disableRipple
              disableElevation
              disableFocusRipple
              disableTouchRipple
              className={classes.button}
              startIcon={<SystemUpdateAltIcon />}
              onClick={downloadSampleFile}
            >
              <Typography variant='body2'>
                Download Sample (.csv ) file
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};
export default UploadRecordFiles;
