import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import { useDispatch } from 'react-redux';
import clsx from 'classnames';

//files
import SATable from './SATable';
import RowsPerPage from '../../models/inputs/RowsPerPage';
import PaginationInput from '../../models/pagination/PaginationInput';

const useStyles = makeStyles((theme) => ({
  hgText: {
    fontWeight: 500,
    paddingRight: theme.spacing(1),
  },
  outlined: {
    padding: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    fontSize: '0.875rem',
  },
  formPerPage: {
    minWidth: 60,
    fontSize: '0.875rem',
  },
  pdTp: {
    paddingTop: '1.5rem',
  },
  searchFld: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  flxEd: {
    justifyContent: 'flex-end',
  },
}));

const ScheduleAgreements = (props) => {
  const [noRowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const {
    scheduleAgreements,
    status,
    searchQuery,
    searchFilterFlag,
    getLimit,
    orderBy,
    orderByField,
    newTotalRecord,
    CMOMember,
    getCurrentPageNumber,
    offset,
  } = props;

  useEffect(() => {
    getLimit(noRowsPerPage);
    if (
      (props.status || searchQuery || CMOMember || orderBy || orderByField) &&
      offset === 0
    ) {
      setCurrentPage(1);
    }
  }, [
    props.status,
    searchQuery,
    noRowsPerPage,
    getLimit,
    CMOMember,
    offset,
    orderBy,
    orderByField,
  ]);

  let totalPages = Math.ceil(newTotalRecord / noRowsPerPage);

  const handleSetRows = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1);
    getCurrentPageNumber(1);
    setErrors({});
  };

  const handlePageChange = (data) => {
    setCurrentPage(data);
    getCurrentPageNumber(data);
  };

  let showPagination = scheduleAgreements
    ? scheduleAgreements.length > 0
      ? true
      : false
    : false;

  const getOrderAndColumn = (data, dataBy) => {
    props.getOrderAndColumnFinal(data, dataBy);
  };

  return (
    <React.Fragment>
      <Grid container alignItems='center' className={classes.pdTp}>
        <Grid item xs={8} sm={6} className={classes.searchFld}></Grid>
        <Grid
          item
          xs={4}
          sm={6}
          className={clsx(classes.searchFld, classes.flxEd)}
        >
          <RowsPerPage value={noRowsPerPage} onChange={handleSetRows} />
        </Grid>

        <Grid container>
          {errors.fileErr && (
            <Grid item xs={12} sm={10} md={9}>
              <br />
              <Typography
                variant='body1'
                style={{
                  color: 'red',
                }}
              >
                {errors.fileErr}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container alignItems='center' className={classes.pdTp}>
        <SATable
          loading={props.loading}
          status={status}
          scheduleAgreement={scheduleAgreements}
          searchFilterFlag={searchFilterFlag}
          searchQuery={searchQuery}
          getOrderAndColumn={getOrderAndColumn}
        />
      </Grid>
      {showPagination === true && (
        <PaginationInput
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          page={currentPage}
        />
      )}
    </React.Fragment>
  );
};

export default ScheduleAgreements;
