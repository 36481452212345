import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import SelectionBar from './SelectionBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RiskImage from '../../../resources/images/dashboard/AtRisk.svg';
import TrackImage from '../../../resources/images/dashboard/OnTrack.svg';
import BatchesImage from '../../../resources/images/dashboard/TotalBatches.svg';
import LineItem from '../../../resources/images/dashboard/TotalLineItems.svg';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import Moment from 'moment';

import DisplayCard from './Card';
import SectionCard from './SectionCard';
import CMOLineTable from './CMOLineTable';

import {
  getOverviewCardsData,
  getLineItemStatusOverview,
  getCmoOrderInformation,
  overviewCardSource,
  lineItemStatusOverviewSource,
  getCmoOrderInformationSource
} from '../../../containers/actions/pharmaCo/dashboardActions';

const styles = (theme) => ({
  '@keyframes blink': {
    '0%': {
      opacity: 0.2
    },
    '20%': {
      opacity: 1
    },
    '100%': {
      opacity: 0.2
    }
  },
  titletext: {
    fontSize: '0.65rem',
    marginTop: '0.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.5rem',
      marginTop: '0.5rem'
    }
  }
});

class ExecutiveDashboard extends Component {
  state = {
    allLineItems: true,
    selectRange: false,
    reportStartDate: null,
    reportEndDate: null,
    loading: true,
    overviewCards: {},
    lineItemsOverview: {},
    lineItemsCMO: false
  };

  componentDidMount() {
    this.fetchCardsData();
  }

  componentWillUnmount() {
    if (overviewCardSource) {
      overviewCardSource.cancel('leaving page')
    }
    if (lineItemStatusOverviewSource) {
      lineItemStatusOverviewSource.cancel('leaving page')
    }
    if (getCmoOrderInformationSource) {
      getCmoOrderInformationSource.cancel('leaving page')
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.overviewCards &&
      nextProps.overviewCards !== this.props.overviewCards
    ) {
      this.setState({
        overviewCards: nextProps.overviewCards,
        loading: false
      });
    }
    if (
      nextProps.lineItemsOverview &&
      nextProps.lineItemsOverview !== this.props.lineItemsOverview
    ) {
      this.setState({
        lineItemsOverview: nextProps.lineItemsOverview,
        loading: false
      });
    }
    if (
      nextProps.lineItemsCMO &&
      nextProps.lineItemsCMO !== this.props.lineItemsCMO
    ) {
      if (Object.keys(nextProps.lineItemsCMO[0]).length === 0) {
        this.setState({
          lineItemsCMO: 'empty',
          loading: false
        });
      } else {
        this.setState({
          lineItemsCMO: nextProps.lineItemsCMO,
          loading: false
        });
      }
    }
  }

  fetchCardsData = () => {
    const { reportStartDate, reportEndDate } = this.state;
    const data = {
      startCommitDate: reportStartDate
        ? Moment(reportStartDate).format('YYYY-MM-DD')
        : null,
      endCommitDate: reportEndDate
        ? Moment(reportEndDate).format('YYYY-MM-DD')
        : reportEndDate,
      collabType: 'Pharma Co',
      ...this.props.match.params
    };
    this.props.getOverviewCardsData(data);
    this.props.getLineItemStatusOverview(data);
    this.props.getCmoOrderInformation(data);
  };

  reloadCmoOrderInformation = () => {
    const { reportStartDate, reportEndDate } = this.state;
    const data = {
      startCommitDate: reportStartDate
        ? Moment(reportStartDate).format('YYYY-MM-DD')
        : null,
      endCommitDate: reportEndDate
        ? Moment(reportEndDate).format('YYYY-MM-DD')
        : reportEndDate,
      collabType: 'Pharma Co',
      ...this.props.match.params
    };
    this.setState({
      lineItemsCMO: false
    });
    this.props.getCmoOrderInformation(data);
  };

  fetchExecDashboardData = () => {
    this.setState({ loading: true });
    this.fetchCardsData();
  };

  handleCheck = (name) => (event) => {
    if (name === 'allLineItems') {
      this.setState({
        [name]: event.target.checked,
        selectRange: !event.target.checked,
        reportEndDate: null,
        reportStartDate: null
      });
    } else if ((name = 'selectRange')) {
      this.setState({
        [name]: event.target.checked,
        allLineItems: !event.target.checked
      });
    }
  };

  handleDateChange = (date, name) => {
    this.setState({
      [name]: new Date(date),
      allLineItems: false,
      selectRange: true
    });
  };

  render() {
    const {
      allLineItems,
      selectRange,
      reportStartDate,
      reportEndDate,
      loading,
      overviewCards,
      lineItemsOverview,
      lineItemsCMO
    } = this.state;
    const { classes } = this.props;
    return (
      <div
        style={{
          marginTop: '-1rem'
        }}
      >
        <SelectionBar
          allLineItems={allLineItems}
          selectRange={selectRange}
          startDate={reportStartDate}
          endDate={reportEndDate}
          handleCheck={this.handleCheck}
          handleDateChange={this.handleDateChange}
          fetchExecDashboardData={this.fetchExecDashboardData}
        />

        {loading === true ? (
          <div style={{ textAlign: 'center', marginTop: '15%' }}>
            <CircularProgress color="primary" thickness={7} />
          </div>
        ) : (
          <Grid container spacing={4} style={{ marginTop: '1rem' }}>
            <Grid item xs={12} sm={12} md={4}>
              <Grid container spacing={2}>
                <DisplayCard
                  cardName="Total Line Items"
                  cardCount={overviewCards.totalLineItems}
                  imgUrl={LineItem}
                  startCommitDate={
                    reportStartDate
                      ? Moment(reportStartDate).format('YYYY-MM-DD')
                      : reportStartDate
                  }
                  endCommitDate={
                    reportEndDate
                      ? Moment(reportEndDate).format('YYYY-MM-DD')
                      : reportEndDate
                  }
                  API="overviewCardTotalLineItemDetails"
                />

                <DisplayCard
                  cardName="On Track"
                  cardCount={overviewCards.onTrack}
                  imgUrl={TrackImage}
                  startCommitDate={
                    reportStartDate
                      ? Moment(reportStartDate).format('YYYY-MM-DD')
                      : reportStartDate
                  }
                  endCommitDate={
                    reportEndDate
                      ? Moment(reportEndDate).format('YYYY-MM-DD')
                      : reportEndDate
                  }
                  API="overviewCardOnTrackDetails"
                />

                <DisplayCard
                  cardName="At Risk"
                  cardCount={overviewCards.atRisk}
                  imgUrl={RiskImage}
                  startCommitDate={
                    reportStartDate
                      ? Moment(reportStartDate).format('YYYY-MM-DD')
                      : reportStartDate
                  }
                  endCommitDate={
                    reportEndDate
                      ? Moment(reportEndDate).format('YYYY-MM-DD')
                      : reportEndDate
                  }
                  API="overviewCardAtRiskDetails"
                />

                <DisplayCard
                  cardName="Total Batches"
                  cardCount={overviewCards.totalBatches}
                  imgUrl={BatchesImage}
                  startCommitDate={
                    reportStartDate
                      ? Moment(reportStartDate).format('YYYY-MM-DD')
                      : reportStartDate
                  }
                  endCommitDate={
                    reportEndDate
                      ? Moment(reportEndDate).format('YYYY-MM-DD')
                      : reportEndDate
                  }
                  screen="batch"
                />
              </Grid>
              <Grid>
                <Typography variant="body1" className={classes.titletext}>
                  *Risk = Line Item(s) pending to be Acknowledged for &gt; 1 Day
                  (or) Dispatched Date &gt; Delivery Date (or) RM/PM Not Available
                </Typography>

                <Typography variant="caption" className={classes.titletext}>
                  *Total Line Items = On Track Line Items + At Risk Line Items
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={8}>
              <Paper style={{ padding: '1rem', backgroundColor: '#F2F2F2' }}>
                <Typography
                  variant="h6"
                  style={{ marginBottom: '1rem', fontWeight: 400 }}
                >
                  Line Items Current Status
                </Typography>
                <Grid container spacing={4} style={{ padding: '1rem' }}>
                  <SectionCard
                    sectionTitle="PRE-PRODUCTION"
                    startCommitDate={
                      reportStartDate
                        ? Moment(reportStartDate).format('YYYY-MM-DD')
                        : reportStartDate
                    }
                    endCommitDate={
                      reportEndDate
                        ? Moment(reportEndDate).format('YYYY-MM-DD')
                        : reportEndDate
                    }
                    API="lineItemStatusDetails"
                    statusArray={[
                      {
                        statusName: 'Not Acknowledged',
                        count: lineItemsOverview['Created/Open']
                          ? lineItemsOverview['Created/Open']
                          : 0,
                        statusCode: 0,
                        background: '#f2f2f2'
                      },
                      {
                        statusName: 'RM/PM Available',
                        count: lineItemsOverview['RM/PM Available']
                          ? lineItemsOverview['RM/PM Available']
                          : 0,
                        statusCode: lineItemsOverview['RM/PM Available Code']
                          ? lineItemsOverview['RM/PM Available Code']
                          : null
                      },
                      {
                        statusName: 'RM/PM Not Available',
                        count: lineItemsOverview['RM/PM Not Available']
                          ? lineItemsOverview['RM/PM Not Available']
                          : 0,
                        statusCode: lineItemsOverview[
                          'RM/PM Not Available Code'
                        ]
                          ? lineItemsOverview['RM/PM Not Available Code']
                          : null,
                        background: '#f2f2f2'
                      },
                      {
                        background: '#ffffff'
                      },
                      {
                        background: '#f2f2f2'
                      }
                    ]}
                  />
                  <SectionCard
                    sectionTitle="PRODUCTION"
                    startCommitDate={
                      reportStartDate
                        ? Moment(reportStartDate).format('YYYY-MM-DD')
                        : reportStartDate
                    }
                    endCommitDate={
                      reportEndDate
                        ? Moment(reportEndDate).format('YYYY-MM-DD')
                        : reportEndDate
                    }
                    API="lineItemStatusDetails"
                    statusArray={[
                      {
                        statusName: 'Under Production',
                        count: lineItemsOverview['Under Production']
                          ? lineItemsOverview['Under Production']
                          : 0,
                        statusCode: lineItemsOverview['Under Production Code']
                          ? lineItemsOverview['Under Production Code']
                          : null,
                        background: '#f2f2f2'
                      },
                      {
                        statusName: 'Production Completed',
                        count: lineItemsOverview['Production Complete']
                          ? lineItemsOverview['Production Complete']
                          : 0,
                        statusCode: lineItemsOverview[
                          'Production Complete Code'
                        ]
                          ? lineItemsOverview['Production Complete Code']
                          : null
                      },
                      {
                        background: '#f2f2f2'
                      },
                      {
                        background: '#ffffff'
                      },
                      {
                        background: '#f2f2f2'
                      }
                    ]}
                  />
                  <SectionCard
                    sectionTitle="POST-PRODUCTION"
                    startCommitDate={
                      reportStartDate
                        ? Moment(reportStartDate).format('YYYY-MM-DD')
                        : reportStartDate
                    }
                    endCommitDate={
                      reportEndDate
                        ? Moment(reportEndDate).format('YYYY-MM-DD')
                        : reportEndDate
                    }
                    API="lineItemStatusDetails"
                    statusArray={[
                      {
                        statusName: 'Work in Progress',
                        count: lineItemsOverview['Work in Progress']
                          ? lineItemsOverview['Work in Progress']
                          : 0,
                        statusCode: lineItemsOverview['Work in Progress Code']
                          ? lineItemsOverview['Work in Progress Code']
                          : null,
                        background: '#f2f2f2'
                      },
                      {
                        statusName: 'QC Completed',
                        count: lineItemsOverview['QC Released']
                          ? lineItemsOverview['QC Released']
                          : 0,
                        statusCode: lineItemsOverview['QC Released Code']
                          ? lineItemsOverview['QC Released Code']
                          : null
                      },
                      {
                        statusName: 'Ready for Dispatch',
                        count: lineItemsOverview['Ready for Dispatch']
                          ? lineItemsOverview['Ready for Dispatch']
                          : 0,
                        statusCode: lineItemsOverview['Ready for Dispatch Code']
                          ? lineItemsOverview['Ready for Dispatch Code']
                          : null,
                        background: '#f2f2f2'
                      },
                      {
                        statusName: 'Dispatched',
                        count: lineItemsOverview['Dispatch']
                          ? lineItemsOverview['Dispatch']
                          : 0,
                        statusCode: lineItemsOverview['Dispatch Code']
                          ? lineItemsOverview['Dispatch Code']
                          : null
                      },
                      {
                        statusName: 'Goods Received',
                        count: lineItemsOverview['Goods Received']
                          ? lineItemsOverview['Goods Received']
                          : 0,
                        statusCode: lineItemsOverview['Goods Received Code']
                          ? lineItemsOverview['Goods Received Code']
                          : null,
                        background: '#f2f2f2'
                      }
                    ]}
                  />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <CMOLineTable
                startCommitDate={
                  reportStartDate
                    ? Moment(reportStartDate).format('YYYY-MM-DD')
                    : reportStartDate
                }
                endCommitDate={
                  reportEndDate
                    ? Moment(reportEndDate).format('YYYY-MM-DD')
                    : reportEndDate
                }
                API="lineItemStatusDetails"
                data={lineItemsCMO}
                refreshTable={this.reloadCmoOrderInformation}
              />
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  overviewCards: state.pcmp.pharmaCo.dashboard.overviewCards,
  lineItemsOverview: state.pcmp.pharmaCo.dashboard.lineItemsOverview,
  lineItemsCMO: state.pcmp.pharmaCo.dashboard.lineItemsCMO
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    getOverviewCardsData,
    getLineItemStatusOverview,
    getCmoOrderInformation
  })(withRouter(ExecutiveDashboard))
);
