import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

//files
import CMOFilter from '../../../grnReceipts/CMOFilter';
import { useStyles } from './Styles';
import FromToDateFilter from './FromToDateFilter';
import SearchSelect from './SearchSelect';

const Filter = (props) => {
  const classes = useStyles();
  const {
    cmoFilter,
    handleSelectCMO,
    CMOMember,
    productSearch,
    handleProductSearchQuery,
    startDate,
    endDate,
    handleDateChange,
    CMOMemberId,
  } = props;

  return (
    <Grid container spacing={5} style={{ alignItems: 'end' }}>
      {cmoFilter && (
        <Grid item xs={12} sm={3} style={{ alignItems: 'end' }}>
          <Typography variant='body2' className={classes.hgText}>
            Filter by:
          </Typography>
          <CMOFilter handleSelectCMO={handleSelectCMO} CMOMember={CMOMember} />
        </Grid>
      )}
      <Grid item xs={12} sm={4} style={{ alignItems: 'end' }}>
        <SearchSelect
          searchQuery={productSearch}
          handleSearchQuery={handleProductSearchQuery}
          CMOMember={CMOMemberId}
        />
      </Grid>
      <Grid item xs={12} sm={5} style={{ alignItems: 'end' }}>
        <FromToDateFilter
          startDate={startDate}
          endDate={endDate}
          handleDateChange={handleDateChange}
        />
      </Grid>
    </Grid>
  );
};

export default Filter;
