/* eslint-disable no-console */
/*
 * Copyright - 2022 , Schrocken Inc
 * Author: Harish D
 * -----
 * File Description: Wrapper function that runs logic to identify if any material in consumption exceeds allowed limit
 */

import axios from 'axios';
import { smlUrl, appUrl } from '../../../../../../utils/config';
import {
  OPEN_BACKDROP,
  DESTROY_BACKDROP,
} from '../../../../containers/actions/types';
import {
  getVariance,
  getVariancePercentage,
  getThreshold,
  getRefQty,
  getStdQty,
} from './variance';

const varianceAlertWrapper =
  ({
    schrockenDeliveryScheduleBatchID,
    schrockenDeliveryScheduleID,
    batchSize,
    bomConsumptionAddedIndicator,
  }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: OPEN_BACKDROP,
        backdropText: 'Please wait. checking for consumption variance...',
      });

      let series = [];
      let consumptionData = [];
      let bomData = [];
      let stdBatchSize = 0;
      const varianceValidator = () => {
        dispatch({
          type: OPEN_BACKDROP,
          backdropText: 'Validating consumption against standard qty ',
        });
        /**
         * Loop through consumption batches
         * find material type
         * identify threshold
         * calculate variance
         * compare and respond
         */
        for (let c = 0; c < consumptionData.length; c++) {
          let materialCode = consumptionData[c].BomComponentCode;
          // Identify the series
          let materialThreshold = getThreshold({ materialCode, series });
          let refQty = getRefQty({ materialCode, bomData });
          if (!materialThreshold) {
            if (c === consumptionData.length - 1) {
              dispatch({
                type: DESTROY_BACKDROP,
              });
              resolve({ exceed: false });
            } else {
              continue;
            }
            // There is no need to check for variance
            // continue;
            // materialThreshold =
            //   series[series.findIndex((s, index) => s.type === 'PM')]
            //     ?.threshold;
            // reject({
            //   status: false,
            //   errMessage: `Series & threshold data not found for material ${consumptionData[c].BomComponentCode}. Please contact support team`,
            // });
            // break;
          }
          // calculate variance

          let stdQty = getStdQty(batchSize, stdBatchSize, refQty);
          let variance = getVariance(
            consumptionData[c].consumptionBatchNumbers,
            stdQty
          );

          let variancePercentage = Math.abs(
            getVariancePercentage(variance, stdQty)
          );

          if (variancePercentage > materialThreshold) {
            dispatch({
              type: DESTROY_BACKDROP,
            });
            resolve({ exceed: true });
            break;
          } else if (c === consumptionData.length - 1) {
            dispatch({
              type: DESTROY_BACKDROP,
            });
            resolve({ exceed: false });
          } else {
            continue;
          }
        }
      };

      dispatch({
        type: OPEN_BACKDROP,
        backdropText: 'Getting consumption data',
      });
      axios
        .post(
          `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchBOMConsumptionDetails`,
          {
            schrockenDeliveryScheduleBatchID,
            schrockenDeliveryScheduleID,
            bomConsumptionAddedIndicator,
          }
        )
        .then((res) => {
          if (res.data.status) {
            consumptionData = res.data.data.bomConsumptionDetails;
            dispatch({
              type: OPEN_BACKDROP,
              backdropText: 'Getting BOM data',
            });
            axios
              .post(
                `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/fetchBOMDetails`,
                {
                  schrockenDeliveryScheduleID: parseInt(
                    schrockenDeliveryScheduleID
                  ),
                }
              )
              .then((res) => {
                if (res.data.status) {
                  bomData = res.data.data.bomComponentDetails;
                  stdBatchSize = res.data.data.fgRefQuantity;

                  dispatch({
                    type: OPEN_BACKDROP,
                    backdropText: 'Getting variance threshold data',
                  });
                  axios
                    .get(
                      `${smlUrl}/user/fetchMaterialTypeAndVarianceThresholdDetails`
                    )
                    .then((res) => {
                      if (res.data.status) {
                        // resolve(true);
                        series = res.data.data;
                        // let f = await
                        varianceValidator();
                      }
                    })
                    .catch((err) => {
                      dispatch({
                        type: DESTROY_BACKDROP,
                      });
                      reject({
                        status: false,
                        errMessage:
                          err.response?.data?.errDescription ||
                          'Failed to fetch series & threshold data',
                      });
                    });
                }
              })
              .catch((err) => {
                dispatch({
                  type: DESTROY_BACKDROP,
                });
                reject({
                  status: false,
                  errMessage:
                    err.response?.data?.errDescription ||
                    'Failed to fetch BOM data',
                });
              });
          }
        })
        .catch((err) => {
          dispatch({
            type: DESTROY_BACKDROP,
          });
          reject({
            status: false,
            errMessage:
              err.response?.data?.errDescription ||
              'Failed to fetch consumption data',
          });
        });

      // return true;
    });
  };
export default varianceAlertWrapper;
