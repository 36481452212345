import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import StyledButton from '../../../../common/models/Button';
import InputNumber from '../models/inputs/InputNumber';
import CustomDatePickerMui from '../models/inputs/DateInput';

const useStyles = makeStyles((theme) => ({
  font: {
    fontWeight: 500,
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  grid: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  padding: {
    paddingBottom: '2rem',
    // paddingTop: '2rem',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5rem',
    textAlign: 'left',
    // paddingTop: '2rem',
  },
  mandatory: {
    color: 'red',
  },
}));

const RecordDetails = (props) => {
  const classes = useStyles();
  const {
    POBatchRecordDetails,
    handleChange,
    handleDateChange,
    isAddRecord,
    status,
    POLineRecordDetails,
  } = props;

  const [materialReceivedDate, setRecordDate] = useState(
    POBatchRecordDetails.materialReceivedDate
  );
  const [transporterName, setTransporter] = useState(
    POBatchRecordDetails.transporterName
  );
  const [lorryReceiptNumber, setLorryReciept] = useState(
    POBatchRecordDetails.lorryReceiptNumber
  );
  const [lorryReceiptDate, setLorryReceiptDate] = useState(
    POBatchRecordDetails.lorryReceiptDate
  );
  const [numberMRN, setNumberMRN] = useState(POBatchRecordDetails.numberMRN);

  const lorryReceiptDateEnabled = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.features
        .lorryReceiptDateEnabled;
    }
    return false;
  });

  const handleChangeRecordDate = (date) => {
    setRecordDate(date != null ? date.toISOString() : date);
    // setPOBatchRecordDetails({...POBatchRecordDetails,})
  };

  const handleChangeTransporterName = (e) => {
    setTransporter(e.target.value);
    // setPOBatchRecordDetails({...POBatchRecordDetails,transporterName:e.target.value})
  };

  const handleChangeLorryReciept = (e) => {
    setLorryReciept(e.target.value);
    // setPOBatchRecordDetails({...POBatchRecordDetails,lorryReceiptNumber:e.target.value})
  };

  const handleChangeNumberMRN = (e) => {
    setNumberMRN(e.target.value);
  };

  const handleSaveRecordDetails = () => {
    props.handleSavePOItemData({
      ...POBatchRecordDetails,
      transporterName,
      lorryReceiptNumber,
      lorryReceiptDate,
      materialReceivedDate,
      numberMRN,
    });
  };

  return (
    <React.Fragment>
      {!isAddRecord && (
        <Grid container justify='center' className={classes.grid}>
          <Grid item xs={12} sm={10} md={10}>
            <Typography
              variant='h4'
              style={{ textAlign: 'center' }}
              className={classes.title}
            >
              Enter Record Details
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container justify='center' spacing={8} className={classes.padding}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Enter MRN Number <span className={classes.mandatory}>*</span>
          </Typography>
          <InputNumber
            name='numberMRN'
            type='text'
            value={
              isAddRecord !== true ? POBatchRecordDetails.numberMRN : numberMRN
            }
            onChange={
              isAddRecord !== true
                ? (e, name) => handleChange(e, 'numberMRN')
                : (e) => handleChangeNumberMRN(e)
            }
            placeholder='Enter MRN Number'
            disabled={
              status === 3 || status === 6 || status === 8 ? true : false
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Select the Date Material Was Received{' '}
            <span className={classes.mandatory}>*</span>
          </Typography>

          <CustomDatePickerMui
            name='materialReceivedDate'
            // value={materialReceivedDate}
            value={
              isAddRecord !== true
                ? POBatchRecordDetails.materialReceivedDate
                : materialReceivedDate
            }
            onChange={
              isAddRecord !== true
                ? (date) => handleDateChange(date, 'materialReceivedDate')
                : (date) => handleChangeRecordDate(date)
            }
            disabled={
              status === 3 || status === 6 || status === 8 ? true : false
            }
            disableFuture={true}
            minDate={POLineRecordDetails.supplierPODate}
          />
        </Grid>
      </Grid>

      <Grid container wrap='wrap' spacing={8} className={classes.padding}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Enter the Transporter Name (optional)
          </Typography>
          <InputNumber
            type='text'
            name='transporterName'
            className={classes.borders}
            value={
              isAddRecord !== true
                ? POBatchRecordDetails.transporterName
                : transporterName
            }
            onChange={
              isAddRecord !== true
                ? (e) => handleChange(e, 'transporterName')
                : (e) => handleChangeTransporterName(e)
            }
            placeholder='Transporter Name'
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='body2' className={classes.font}>
            Enter Lorry Receipt (LR) Number (optional)
          </Typography>
          <InputNumber
            type='text'
            name='lorryReceiptNumber'
            className={classes.borders}
            value={
              isAddRecord !== true
                ? POBatchRecordDetails.lorryReceiptNumber
                : lorryReceiptNumber
            }
            onChange={
              isAddRecord !== true
                ? (e) => handleChange(e, 'lorryReceiptNumber')
                : (e) => handleChangeLorryReciept(e)
            }
            placeholder='LR Number'
          />
        </Grid>
        {lorryReceiptDateEnabled && (
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant='body2' className={classes.font}>
              Lorry Receipt (LR) Date (optional)
            </Typography>

            <CustomDatePickerMui
              name='lorryReceiptDate'
              value={
                isAddRecord !== true
                  ? POBatchRecordDetails.lorryReceiptDate
                  : lorryReceiptDate
              }
              onChange={
                isAddRecord !== true
                  ? (date) => handleDateChange(date, 'lorryReceiptDate')
                  : (date) =>
                      setLorryReceiptDate(
                        date !== null ? date.toISOString() : null
                      )
              }
              disabled={
                status === 3 || status === 6 || status === 8 ? true : false
              }
              disableFuture={true}
            />
          </Grid>
        )}

        {isAddRecord && (
          <Grid item xs={12} sm={8} md={8} style={{ textAlign: 'center' }}>
            <StyledButton
              onClick={handleSaveRecordDetails}
              style={{ padding: '0.25rem 2rem' }}
            >
              Save
            </StyledButton>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default RecordDetails;
