import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { fetchQuickSummary } from '../../../containers/actions/common/qualityEventActions';

const styles = (theme) => ({
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '2rem',
    backgroundColor: 'grey',
  },
});
class QuickSummary extends Component {
  state = {
    quickSummaryData: {},
    qualityEventName: '',
  };
  // componentDidMount(){
  //     const data = {
  //         "viewByCMO":"all",
  //         "eventTypeID":'all',
  //         "priority":'all',
  //         "eventsOccurredFromDate":null,
  //         "eventsOccurredToDate": null,
  //     };
  //     this.props.fetchQuickSummary(data);
  // }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.quickSummaryData !== prevProps.quickSummaryData) {
      this.setState({
        quickSummaryData:
          this.props.quickSummaryData.data.qualityEventQuickSummary[0],
        qualityEventName: this.props.qualityEventName,
      });
    }
  }

  // let quickSummary = useSelector((state) =>
  //     state.pcmp.common.QMS.fetchQuickSummary
  //         ? state.pcmp.common.QMS.fetchQuickSummary
  //         : []
  // );

  // useEffect(() => {
  //     dispatch(
  //         fetchQuickSummary(

  //         )
  //     );

  // }, [dispatch]);

  render() {
    const { classes } = this.props;
    const { quickSummaryData, qualityEventName } = this.state;
    return (
      <Grid container justifyContent='space-between' spacing={1}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              paddingTop: '0.25rem',
              width: '100%',
              height: '8rem',
              backgroundColor: 'white',
              border: '1px solid black',
              borderRadius: '10px',
              padding: '20px',
            }}
          >
            <Typography
              variant='h6'
              style={{ paddingBottom: '0.5rem', paddingTop: '0.5rem' }}
            >
              By Status - {qualityEventName}
            </Typography>
            <span
              style={{
                border: '2px solid #707070',
                display: 'flex',
                width: '2rem',
                backgroundColor: 'grey',
              }}
            ></span>
            <Grid
              container
              justifyContent='space-evenly'
              alignItems='center'
              spacing={1}
              style={{ paddingTop: '0.6rem' }}
            >
              <Grid
                container
                justifyContent='center'
                item
                xs={12}
                sm={2}
                direction='column'
              >
                <Typography
                  variant='h4'
                  style={{ fontWeight: 600, textAlign: 'center' }}
                >
                  {this.state.quickSummaryData.totalEvents}
                </Typography>

                <Typography
                  variant='body2'
                  style={{
                    paddingTop: '0.75rem',
                    fontSize: '0.75rem',
                    textAlign: 'center',
                    fontWeight: 550,
                  }}
                >
                  Total
                </Typography>
              </Grid>
              <Divider
                orientation='vertical'
                flexItem
                style={{ marginRight: '-1px', height: '5rem', width: '3px' }}
              />
              <Grid
                container
                justifyContent='center'
                item
                xs={12}
                sm={2}
                direction='column'
              >
                <Typography
                  variant='h4'
                  style={{ fontWeight: 600, textAlign: 'center' }}
                >
                  {this.state.quickSummaryData.totalClosedEvents}
                </Typography>

                <Typography
                  variant='body2'
                  style={{
                    paddingTop: '0.75rem',
                    fontSize: '0.75rem',
                    textAlign: 'center',
                    fontWeight: 550,
                  }}
                >
                  Closed
                </Typography>
              </Grid>
              <Divider
                orientation='vertical'
                flexItem
                style={{ marginRight: '-1px', height: '5rem', width: '3px' }}
              />
              <Grid
                container
                justifyContent='center'
                item
                xs={12}
                sm={2}
                direction='column'
              >
                <Typography
                  variant='h4'
                  style={{ fontWeight: 600, textAlign: 'center' }}
                >
                  {this.state.quickSummaryData.totalPendingEvents}
                </Typography>

                <Typography
                  variant='body2'
                  style={{
                    paddingTop: '0.75rem',
                    fontSize: '0.75rem',
                    textAlign: 'center',
                    fontWeight: 550,
                  }}
                >
                  Total Pending
                </Typography>
              </Grid>
              <Divider
                orientation='vertical'
                flexItem
                style={{ marginRight: '-1px', height: '5rem', width: '3px' }}
              />
              <Grid
                container
                justifyContent='center'
                item
                xs={12}
                sm={2}
                direction='column'
              >
                <Typography
                  variant='h4'
                  style={{ fontWeight: 600, textAlign: 'center' }}
                >
                  {this.state.quickSummaryData.totalUnderInvestigationEvents}
                </Typography>

                <Typography
                  variant='body2'
                  style={{
                    paddingTop: '0.75rem',
                    fontSize: '0.75rem',
                    textAlign: 'center',
                    fontWeight: 550,
                  }}
                >
                  Under Investigation
                </Typography>
              </Grid>
              <Divider
                orientation='vertical'
                flexItem
                style={{ marginRight: '-1px', height: '5rem', width: '3px' }}
              />
              <Grid
                container
                justifyContent='center'
                item
                xs={12}
                sm={2}
                direction='column'
              >
                <Typography
                  variant='h4'
                  style={{ fontWeight: 600, textAlign: 'center' }}
                >
                  {this.state.quickSummaryData.totalAwaitingApprovalEvents}
                </Typography>

                <Typography
                  variant='body2'
                  style={{
                    paddingTop: '0.75rem',
                    fontSize: '0.75rem',
                    textAlign: 'center',
                    fontWeight: 550,
                  }}
                >
                  Awaiting Approval
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid container item xs={12} sm={6} justifyContent='flex-end'>
          <Box
            sx={{
              paddingTop: '0.25rem',
              width: '80%',
              height: '8rem',
              backgroundColor: '#fff8f8',
              border: '1px solid black',
              borderRadius: '10px',
              padding: '20px',
            }}
          >
            <Typography
              variant='h6'
              style={{ paddingBottom: '0.5rem', paddingTop: '0.5rem' }}
            >
              By Risk - {qualityEventName}
            </Typography>
            <span
              style={{
                border: '2px solid #707070',
                display: 'flex',
                width: '2rem',
                backgroundColor: 'grey',
              }}
            ></span>
            <Grid
              container
              direction='row'
              justifyContent='space-around'
              alignItems='center'
              spacing={1}
              style={{ paddingTop: '0.6rem' }}
            >
              <Grid
                container
                justifyContent='center'
                item
                xs={12}
                sm={3}
                direction='column'
              >
                <Typography
                  variant='h4'
                  style={{ fontWeight: 600, textAlign: 'center' }}
                >
                  {this.state.quickSummaryData.totalHighRiskEvents}
                </Typography>

                <Typography
                  variant='body2'
                  style={{
                    paddingTop: '0.75rem',
                    textAlign: 'center',
                    fontWeight: 550,
                    fontSize: '0.75rem',
                  }}
                >
                  High
                </Typography>
              </Grid>
              <Divider
                orientation='vertical'
                flexItem
                style={{ marginRight: '-1px', height: '5rem', width: '3px' }}
              />
              <Grid
                container
                justifyContent='center'
                item
                xs={12}
                sm={3}
                direction='column'
              >
                <Typography
                  variant='h4'
                  style={{ fontWeight: 600, textAlign: 'center' }}
                >
                  {this.state.quickSummaryData.totalMediumRiskEvents}
                </Typography>

                <Typography
                  variant='body2'
                  style={{
                    paddingTop: '0.75rem',
                    textAlign: 'center',
                    fontWeight: 550,
                    fontSize: '0.75rem',
                  }}
                >
                  Medium
                </Typography>
              </Grid>
              <Divider
                orientation='vertical'
                flexItem
                style={{ marginRight: '-1px', height: '5rem', width: '3px' }}
              />
              <Grid
                container
                justifyContent='center'
                item
                xs={12}
                sm={3}
                direction='column'
              >
                <Typography
                  variant='h4'
                  style={{ fontWeight: 600, textAlign: 'center' }}
                >
                  {this.state.quickSummaryData.totalLowRiskEvents}
                </Typography>

                <Typography
                  variant='body2'
                  style={{
                    paddingTop: '0.75rem',
                    textAlign: 'center',
                    fontWeight: 550,
                    fontSize: '0.75rem',
                  }}
                >
                  Low
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  quickSummaryData: state.pcmp.common.QMS.fetchQualityEventQuickSummary,
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    fetchQuickSummary({
      viewByCMO: 'all',
      eventTypeID: 'all',
      priority: 'all',
      eventsOccurredFromDate: null,
      eventsOccurredToDate: null,
    })
  )(withRouter(QuickSummary))
);
