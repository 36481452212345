import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PublishIcon from '@material-ui/icons/Publish';

import {
  Grid,
  Button,
  Typography,
  TextField,
  IconButton,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ClearIcon from '@material-ui/icons/Clear';

import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Moment from 'moment';

import {
  cmoAddShortFallsReport,
  cmoFetchShortFallsItems,
} from '../../../containers/actions/cmo/shortFallsActions';

const useStyles = {
  alignInRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textWidth: {
    width: '4.5rem',
  },
  addButton: {
    marginTop: '1rem',
    borderRadius: '2rem',
    padding: '0.3rem 3rem',
    marginBottom: '1rem',
    marginLeft: '4rem',
  },
};

class AddShortFalls extends Component {
  state = {
    CMSName: this.props.CMSName,
    shortFallsFileName: '',
    shortFallsFile: '',
    month: 'null',
    year: new Date().toISOString(),
    dateUploaded: null,
    disableAddButton: false,
    errors: {
      shortFallsFile: false,
      month: false,
      year: false,
    },
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shortFallsData.shortFallsReportAdded === true) {
      let data = {
        ...this.props.params,
        collabType: 'CMO',
      };
      setTimeout(() => this.props.cmoFetchShortFallsItems(data), 2000);
      this.props.closeDialog();
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: false,
      },
    }));
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleUploadFile = (event) => {
    const file = event.target.files[0];
    const name = event.target.files[0].name;
    var allowedExtensions = /(\.csv|\.xls|\.xlsx|\.pdf|\.jpeg|\.png|\.jpg|\.bmp|\.doc|\.docx)$/i 
    var filePath = event.target.value; 
    if (!allowedExtensions.exec(filePath)) { 
      alert('Invalid file type'); 
      return false; 
  } 
    this.setState((prevState) => ({
      shortFallsFile: file,
      shortFallsFileName: name,
      errors: {
        ...prevState.errors,
        shortFallsFile: false,
      },
    }));
    event.target.value = null;
  };

  validate = () => {
    let error = false;
    let { shortFallsFile, month, year } = this.state;
    let errors = {
      shortFallsFile: false,
      month: false,
      year: false,
    };
    if (month === 'null') {
      error = true;
      errors.month = true;
    } else if (year === null) {
      error = true;
      errors.year = true;
    } else if (shortFallsFile === '') {
      error = true;
      errors.shortFallsFile = true;
    }
    this.setState({ errors });
    return error;
  };

  onSubmit = () => {
    let isError = this.validate();

    if (!isError) {
      this.setState({
        disableAddButton: true,
      });

      const data = {
        month: this.state.month,
        year: Moment(this.state.year).year().toString(),
        dateUploaded: new Date().toISOString(),
        CMSName: this.state.CMSName,
      };
      const shortFallsFile = new FormData();
      shortFallsFile.append('shortFalls', this.state.shortFallsFile);
      shortFallsFile.append('shortFallsData', JSON.stringify(data));
      shortFallsFile.append('ecosystemId', this.props.params.ecosystemId);
      shortFallsFile.append('collabType', 'CMO');
      shortFallsFile.append('roleName', this.props.params.roleName);
      shortFallsFile.append(
        'organizationName',
        this.props.params.organizationName
      );
      this.props.cmoAddShortFallsReport(
        shortFallsFile,
        this.props.params.roleName
      );
    }
  };

  render() {
    const { closeDialog, classes } = this.props;
    const { month, year, errors, disableAddButton } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    return (
      <React.Fragment>
        <div onClick={closeDialog}>
          <IconButton
            style={{
              marginLeft: '34rem',
              backgroundColor: '#D0D0D0',
              borderRadius: 0,
            }}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <Grid container className={classes.alignInRow}>
          <Typography className={classes.textWidth}>Month</Typography>
          <Grid item xs={4}>
            <TextField
              select
              fullWidth
              name='month'
              variant='outlined'
              margin='normal'
              value={month}
              onChange={this.handleChange}
              error={errors.month}
            >
              <MenuItem disabled value='null'>
                Select Month
              </MenuItem>

              <MenuItem value='January'>January</MenuItem>
              <MenuItem value='February'>February</MenuItem>
              <MenuItem value='March'>March</MenuItem>
              <MenuItem value='April'>April</MenuItem>
              <MenuItem value='May'>May</MenuItem>
              <MenuItem value='June'>June</MenuItem>
              <MenuItem value='July'>July</MenuItem>
              <MenuItem value='August'>August</MenuItem>
              <MenuItem value='September'>September</MenuItem>
              <MenuItem value='October'>October</MenuItem>
              <MenuItem value='November'>November</MenuItem>
              <MenuItem value='December'>December</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid container className={classes.alignInRow}>
          <Typography className={classes.textWidth}>Year</Typography>
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                fullWidth
                disableToolbar
                style={{
                  marginTop: '0.5rem',
                }}
                variant='inline'
                inputVariant='outlined'
                //placeholder="Select Year"
                views={['year']}
                align='center'
                name='year'
                value={year}
                onChange={(date) => this.handleDateChange(date, 'year')}
                rightArrowIcon
                error={errors.year}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>

        <Grid container className={classes.alignInRow}>
          <Typography className={classes.textWidth}>CMS</Typography>
          <Grid item xs={4}>
            <TextField
              name='CMSName'
              disabled
              fullWidth
              value={this.state.CMSName}
              onChange={this.handleChange}
              margin='normal'
              variant='outlined'
            />
          </Grid>
        </Grid>

        <Grid container className={classes.alignInRow}>
          <Typography className={classes.textWidth}>Date</Typography>
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                fullWidth
                disabled
                style={{
                  marginTop: '0.5rem',
                }}
                variant='inline'
                inputVariant='outlined'
                format={dateFormat}
                value={new Date()}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(date) => this.handleDateChange(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>

        <Grid container className={classes.alignInRow}>
          <Typography className={classes.textWidth}>Report</Typography>

          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <input
              style={{
                display: 'none',
              }}
              id='upload-purchase-order-pdf'
              type='file'
              name='purchaseOrderPdfFileName'
              onChange={this.handleUploadFile}
              accept='.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
            />
            <label htmlFor='upload-purchase-order-pdf'>
              <Button
                variant='contained'
                color='primary'
                component='span'
                disableRipple
                style={{
                  backgroundColor: '#E36E39',
                  fontSize: '0.825 rem',
                  //color: "#282828",
                  marginTop: '0.9rem',
                  border: '0.05rem solid rgba(0, 0, 0, 0.27)',
                  boxShadow: 'none',
                }}
                fullWidth
              >
                Upload
                <PublishIcon />
              </Button>
            </label>
            {errors.shortFallsFile ? (
              <Typography variant='caption' color='error'>
                Please upload Report File
              </Typography>
            ) : (
              <Typography
                variant='caption'
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {this.state.shortFallsFileName}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid container className={classes.alignInRow}>
          {/* <Button
            onClick={this.onSubmit}
            disabled={disableAddButton}
            style={{
              backgroundColor: disableAddButton ? "grey" : "blue",
              borderRadius: 0,
              marginTop: "0.5rem",
              color: "#ffffff",
              width: 100,
              alignSelf: "center",
              marginBottom: "2rem"
            }}
          >
            Add
          </Button> */}
          <Button
            variant='contained'
            disabled={disableAddButton}
            disableRipple
            type='submit'
            className={classes.addButton}
            onClick={this.onSubmit}
            style={{
              backgroundColor: 'darkgreen',
              color: '#fff',
            }}
          >
            Add
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  shortFallsData: state.pcmp.cmo.shortFalls,
});

export default connect(mapStateToProps, {
  cmoAddShortFallsReport,
  cmoFetchShortFallsItems,
})(withStyles(useStyles)(AddShortFalls));
