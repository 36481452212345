import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';

import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

import usePrevious from '../../../../../helpers/previousProps';

import { saveQualityEventPartiallyAction } from '../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '100%',
    padding: '1rem',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
  },
  title: {
    textAlign: 'center',
    fontWeight: 600,
    marginTop: '1rem',
    fontSize: '2rem',
    marginBottom: '2rem',
  },
  subTitle: {
    textAlign: 'center',
  },
  dialogPaper: {
    padding: '3rem 0rem',
    position: 'relative',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
  },
  yesBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'capitalize',
  },
  noBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'capitalize',
    backgroundColor: 'white',
    color: 'black',
  },
  icon: {
    fontSize: 'xxx-large',
    color: 'green',
    marginLeft: '2rem',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const PartialSaveScreen = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const { openDialog, toggleDialogScreen, goToDashboard, stateData } = props;
  const [state, setState] = useState({
    loading: false,
  });

  const handleSavePartialData = () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const { collabType } = localStorage;
    const tokenData = jwtDecode(localStorage.getItem('authToken'));

    const investigatingTeamList = [];
    stateData.selectedTeamMembers.map((teamMember) => {
      const teamMemberObject = {};
      teamMemberObject.name = teamMember.userName || '';
      teamMemberObject.roleName = teamMember.roleName || '';
      teamMemberObject.emailID = teamMember.emailAddress;
      teamMemberObject.saasUserID = teamMember.userId;

      investigatingTeamList.push(teamMemberObject);
    });

    const affectedBatchesList = [];
    stateData.selectedBatches.map((batch) => {
      const batchObject = {};
      batchObject.batchNumber = batch.batchNumber;

      affectedBatchesList.push(batchObject);
    });

    const data = {
      qualityEventDetails: [
        {
          qualityEventDetails: {
            incidentRaisedBy: localStorage.organizationName,
            incidentRaisedByOrgID:
              collabType === 'CMO'
                ? tokenData.organization_id
                : stateData.CMOMember.organizationId,
            // CMO Information
            incidentFor: stateData.CMOMember.organizationName,
            incidentForOrgID: stateData.CMOMember.organizationId,
            eventType: stateData.qualityEvent.eventType,
            eventTypeID: stateData.qualityEvent.eventTypeId,
            eventTypeAbbreviation: stateData.qualityEvent.eventTypeAbbreviation,
            // Screen 1
            eventTitle: stateData.eventTitle,
            eventDescription: stateData.eventDescription,
            eventOccurredDate: stateData.eventOccurredDate || '',
            eventReportedDate: stateData.eventReportedDate || '',

            // Screen 2
            impact: stateData.selectedImpact?.impactType || '',
            frequency: stateData.selectedFrequency?.frequencyType || '',
            location: stateData.selectedLocation?.locationName || '',
            facility: stateData.selectedFacility?.FacilityName || '',
            productCode: stateData.selectedProduct?.productCode || '',
            productName: stateData.selectedProduct?.productDescription || '',
            // Screen 3
            customFiles: stateData.customFiles,
            resolutionDescription: stateData.resolutionDescription || '',
            estimatedResolutionDate: stateData.estimatedResolutionDate || '',
            // General Data
            eventStatusID: 1,
            eventStatusText: 'Record Incomplete',
          }, // screen 1
          eventReportedByDetails: {
            reportedByName: stateData.selectedReportedBy?.name || '',
            reportedBySaasUserID: stateData.selectedReportedBy?.saasUserID,
            reportedByEmailID: stateData.selectedReportedBy?.emailID,
            reportedByRoleName: stateData.selectedReportedBy?.roleName || '',
          },
          // Screen 2
          affectedBatchesList: affectedBatchesList,
          supplierDataList: stateData.supplierDataList,
          // Screen 3
          investigatingTeamLeadDetails: {
            teamLeadEmailID: stateData.selectedTeamLead.emailAddress || '',
            teamLeadSaasUserID: stateData.selectedTeamLead.userId || '',
            teamLeadRoleName: stateData.selectedTeamLead.roleName || '',
            teamLeadName: stateData.selectedTeamLead.userName || '',
          },
          investigatingTeamList: investigatingTeamList,
        },
      ],
    };

    props.saveQualityEventPartiallyAction(data);
  };

  const { reportedByList } = props;
  const prevReportedByList = usePrevious(reportedByList);
  useEffect(() => {
    if (didMountRef.current) {
      if (reportedByList !== prevReportedByList) {
        setState((prevState) => ({
          ...prevState,
          reportedByOptions: reportedByList,
        }));
      }
    } else {
      didMountRef.current = true;
    }
  }, [reportedByList, prevReportedByList]);

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='sm'
      classes={{ paper: classes.dialogPaper }}
    >
      {state.loading && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            width: '100%',
            backdropFilter: 'blur(0.8px)',
            zIndex: 9,
            top: 0,
          }}
        >
          <CircularProgress thickness='5' />
        </div>
      )}
      <div className={classes.headerContainer}>
        <div
          className={classes.closeBtn}
          onClick={() => toggleDialogScreen('partialSaveScreen')}
        >
          <Typography
            variant='caption'
            style={{ lineHeight: 'normal', fontWeight: '700' }}
          >
            Cancel
          </Typography>
          <CloseIcon style={{ color: 'red', fontSize: '2.5rem' }} />
        </div>
      </div>

      <Box margin='auto'>
        <Box marginLeft={9} marginTop='3rem'></Box>
        <Box>
          <Typography className={classes.title}>
            Do you want to save the Data?
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-evenly'>
          <Box
            margin='auto'
            marginLeft='8rem'
            marginTop={2}
            marginBottom='2rem'
          >
            <Button
              className={classes.yesBtn}
              variant='contained'
              color='primary'
              onClick={handleSavePartialData}
            >
              yes
            </Button>
          </Box>
          <Box
            margin='auto'
            marginLeft='5rem'
            marginTop={2}
            marginBottom='2rem'
            marginRight='8rem'
          >
            <Button
              className={classes.noBtn}
              variant='contained'
              color='primary'
              onClick={() => goToDashboard()}
            >
              No
            </Button>
          </Box>
        </Box>
        <Box>
          <Typography className={classes.subTitle}>
            If you don't save before you exit, all of the entered data will be
            lost.
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  reportedByList: state.pcmp.common.QMS.fetchReportedByList,
});

export default connect(mapStateToProps, {
  saveQualityEventPartiallyAction,
})(PartialSaveScreen);
