import React from 'react';

import StyledButton from '../../../../common/models/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
// Icons
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '0.5px solid red',
      color: '#e36e39',
    },
  },
  saved: {
    textAlign: 'center',
    padding: '0.5rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  text: {
    textAlign: 'center',
    fontSize: '1rem',
    margin: '1rem',
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
  },
  icon: {
    fontSize: '5rem',
    color: '#348feb',
  },
  buttonGrid: {
    textAlign: 'center',
    padding: '0.5rem',
    paddingBottom: '2rem',
  },
  noButton: {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
});

const StyledDialog = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <DialogContent>
      <Grid container justify='flex-end'>
        <Typography
          variant='body2'
          className={classes.closeText}
          onClick={props.handleClose}
        >
          Close <CloseIcon fontSize='large' />
        </Typography>
        {props.icons === true && (
          <Grid item xs={12} className={classes.iconGrid}>
            <CheckCircleIcon className={classes.icon} />
          </Grid>
        )}
        {props.alert === true && (
          <Grid item xs={12} className={classes.iconGrid}>
            <InfoOutlinedIcon className={classes.icon} />
          </Grid>
        )}
      </Grid>
      <Typography
        variant='h5'
        className={classes.saved}
        style={{ paddingTop: props.icons === false ? '3rem' : '0' }}
      >
        {props.title}
      </Typography>

      <Typography
        variant='body2'
        className={classes.text}
        style={{ paddingBottom: props.icons === false ? '2rem' : '0' }}
      >
        {props.subtitle}
      </Typography>

      {children}

      {props.hasMultipleButton ? (
        <Grid
          item
          xs={12}
          className={classes.buttonGrid}
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          <Button
            variant='contained'
            disableElevation
            disableRipple
            disableFocusRipple
            className={classes.noButton}
            onClick={props.handleClose}
          >
            No
          </Button>
          <StyledButton
            style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
            onClick={props.handleYesButton}
          >
            Yes
          </StyledButton>
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.buttonGrid}>
          <StyledButton
            style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
            onClick={props.handleClose}
          >
            OK
          </StyledButton>
        </Grid>
      )}
    </DialogContent>
  );
};

export default StyledDialog;
