import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mgTp: { marginTop: '0.75rem' },
  pdTp: { paddingTop: '1.5rem' },
  dpFlx: { display: 'flex' },
  algnCntr: { alignItems: 'center' },
  pointer: { cursor: 'pointer' },
  onHvrUndrline: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  hgText: {
    fontWeight: 500,
  },

  pdL10: {
    paddingLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },

  pdTponLr: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: '1rem',
    },
  },

  padR20: {
    paddingRight: 20,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },

  search: {
    padding: '2px 16px',
    backgroundColor: '#e36e39',
    color: 'white',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid black',
    },
  },

  placeholderTextSize: {
    '& input::placeholder': {
      fontSize: '0.695rem',
    },
  },

  flxEndSmFlxStrt: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      paddingTop: '1rem',
    },
  },

  //CMO filter
  outlined: {
    padding: theme.spacing(1),
  },

  formControl: {
    minWidth: 90,
  },

  //quality event raise button
  export: {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
    textTransform: 'none',
    borderRadius: '2rem',
    paddingLeft: '4px',
    '&:hover': {
      backgroundColor: '#173072',
      color: 'white',
      border: '1px solid black',
    },
  },
  search: {
    padding: '2px 16px',
    backgroundColor: '#e36e39',
    color: 'white',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid black',
    },
  },

  clear: {
    textTransform: 'capitalize',
    backgroundColor: '#e36e39',
    color: 'white',
    border: '1px solid',
    textTransform: 'none',
    borderRadius: '2rem',
    paddingLeft: '10px',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid black',
    },
  },

  //QuickSummary
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '2rem',
    backgroundColor: 'grey',
  },

  //dialog box styling
  dialogPaper: {
    minHeight: '55vh',
    maxHeight: '55vh',
    minWidth: '55vw',
    maxWidth: '55vw',
  },
}));
