import axios from 'axios';
import { appUrl, smlUrl } from '../../../../../utils/config';

import {
  YIELD_REPORT,
  AUDIT_REPORT,
  LINE_AUDIT_REPORT,
  OTIF_REPORT,
  PO_LINE_NUMBERS,
  BATCH_AUDIT_REPORT,
  PO_LINE_BATCH_NUMBERS,
  PO_NUMBER_ERROR,
  GRN_STATUSES,
  GRN_FEATURES,
} from '../types';

export const viewReport = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/fetchYieldReport`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: YIELD_REPORT,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      if (err.response.status === 400) {
        dispatch({
          type: YIELD_REPORT,
          payload: err.response.data,
        });
      }
    });
};

// Download Reports both CMO and CMS
export const downloadReport = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/downloadReports`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        let fileDataArray = res.data.fileData.data;
        var byteArray = new Uint8Array(fileDataArray);
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([byteArray], { type: 'application/octet-stream' })
        );
        a.download = res.data.fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    })
    .catch((err) => console.log(err));
};

export const viewAuditReport = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/fetchPOAuditTrailReport
      `,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: AUDIT_REPORT,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      if (err.response.status === 400) {
        dispatch({
          type: PO_NUMBER_ERROR,
          payload: err.response.data,
        });
      }
    });
};
export const viewLineAuditReport = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/fetchPOLineAuditTrailReport
      `,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: LINE_AUDIT_REPORT,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      if (err.response.status === 400) {
        dispatch({
          type: LINE_AUDIT_REPORT,
          payload: err.response.data,
        });
      }
    });
};

export const viewotifReport = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/fetchOTIFReport`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: OTIF_REPORT,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      if (err.response.status === 400) {
        dispatch({
          type: OTIF_REPORT,
          payload: err.response.data,
        });
      }
    });
};

export const fetchPOLines = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/fetchPurchaseOrderLineNumbers`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PO_LINE_NUMBERS,
          payload: res.data.lineNumbers,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 400) {
        dispatch({
          type: PO_NUMBER_ERROR,
          payload: err.response.data,
        });
      }
    });
};

export const fetchPOLineBatches = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/fetchPurchaseOrderBatchNumbers`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PO_LINE_BATCH_NUMBERS,
          payload: res.data.batchNumbers,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const viewBatchAuditReport = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/fetchBatchAuditTrailReport`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: BATCH_AUDIT_REPORT,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      if (err.response.status === 400) {
        dispatch({
          type: BATCH_AUDIT_REPORT,
          payload: err.response.data,
        });
      }
    });
};

//v2.0 dev actions for GR
export let grStatusSource;
export const fetchGrStatuses = (data) => (dispatch) => {
  const CancelToken = axios.CancelToken;
  grStatusSource = CancelToken.source();

  axios
    .get(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/getBatchStatusSequence`,
      { cancelToken: grStatusSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: GRN_STATUSES,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};
// get Feature Flags for RMPM GRN
export const getAllConfigurationFlags = () => (dispatch) => {
  axios
    .post(`${smlUrl}/sml/getAllConfigurationFlags`, {})
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: GRN_FEATURES,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log({ err }));
};
