import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#f5f9ff',
    color: 'black',
  },

  hide: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  closeText: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  icon: {
    paddingLeft:'1rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      fontSize: '1.7rem',
    },
  },
  grid: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '2px solid red',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'end',
    },
  },
  title: {
    fontWeight: 500,
    paddingTop:'1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.875rem',
    },
  },
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  children: {
    paddingTop: 40,
  },
}));

const NewHeader = ({ children, ...props }) => {
  const classes = useStyles();
  const { handleClose, title, subtitle } = props;

  return (
    <React.Fragment>
      <AppBar
        position='sticky'
        style={{ backgroundColor: '#f5f9ff', color: 'black' ,boxShadow:'none' }}
      >
        <Toolbar>
          <Grid container className={classes.root}>
            <Grid item xs={1} sm={1} md={1} className={classes.hide}></Grid>

            <Grid item xs={10} sm={10} md={10}>
              <Typography variant='h4' className={classes.title}>
                {/* Add New Record  */}
                {title}
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} className={classes.grid}>
              <Typography
                variant='body2'
                className={classes.closeText}
                onClick={handleClose}
              >
                Cancel{' '}
              </Typography>
              <CloseIcon
                fontSize='large'
                color='error'
                className={classes.icon}
                onClick={handleClose}
              />
            </Grid>

            <Grid item xs={10} sm={10} md={10} style={{ padding: '1rem',paddingTop:'0.5rem' }}>
              <Typography variant='body1' className={classes.subtitle}>
                {/* Raw Material (RM) / Packaging Material (PM) Record   */}
                {subtitle}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        className={classes.children}
        // style={{ paddingTop: props.padding === true ? 100 : 40 }}
      >
        {children}
      </Grid>
    </React.Fragment>
  );
};

export default NewHeader;
