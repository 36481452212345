import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Dialog, Slide, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { viewAllPurchaseOrders, viewPurchaseOrderSource } from '../../../containers/actions/common/dashboardAction';
import PurchaseOrdersTable from '../../common/tables/PurchaseOrdersTable';
import AddNewPurchaseOrder from './createPurchaseOrder/AddNewPurchaseOrder';
import {
  purchaseOrdersData,
  purchaseOrderStats
} from '../../../containers/actions/pharmaCo/dashboardActions';

const styles = {
  button: {
    borderRadius: '1rem',
    fontSize: '0.7rem'
  },
  flexend: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  text: {
    fontSize: '0.7rem',
    marginRight: '1.2rem',
    fontWeight: 'bold'
  }
};

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

class index extends Component {
  state = {
    dialogOpen: false,
    purchaseOrders: false
  };

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  componentDidMount() {
    this.getAllPurchaseOrders();
  }
  componentWillUnmount() {
    if (viewPurchaseOrderSource) {
      viewPurchaseOrderSource.cancel('leaving page')
    }
  }

  getAllPurchaseOrders = () => {
    let data = {
      ...this.props.match.params,
      collabType: 'Pharma Co'
    };
    this.props.viewAllPurchaseOrders(data);
    this.setState({ purchaseOrders: false })
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.allPurchaseOrders !== this.props.allPurchaseOrders) {
      this.setState({ purchaseOrders: nextProps.allPurchaseOrders });
    }
  }
  render() {
    const { classes } = this.props;
    const { dialogOpen, purchaseOrders } = this.state;
    return (
      <Grid container>
        <Grid item xs={12} className={classes.flexend}>
          <Typography variant="h6" style={{ marginLeft: '1rem' }}>
            Total Purchase Orders - {!!purchaseOrders && purchaseOrders.length}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.openDialog}
          >
            ADD NEW PURCHASE ORDER
          </Button>
          <Dialog fullScreen open={dialogOpen} TransitionComponent={Transition}>
            <AddNewPurchaseOrder
              closeDialog={this.closeDialog}
              callBackAPIFunction={this.getAllPurchaseOrders}
            />
          </Dialog>
        </Grid>
        <PurchaseOrdersTable
          purchaseOrders={purchaseOrders}
          collabType="Pharma Co"
          callBackAPIFunction={this.getAllPurchaseOrders}
        />
      </Grid>
    );
  }
}
const mapStateToProps = state => ({
  allPurchaseOrders: state.pcmp.common.dashboard.allPurchaseOrders
});
export default withStyles(styles)(
  connect(mapStateToProps, {
    purchaseOrdersData,
    purchaseOrderStats,
    viewAllPurchaseOrders
  })(index)
);
