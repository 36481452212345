import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import Moment from 'moment';

import SelectionBar from '../yield/SelectionBar';
import ReportsTableWithPagination from '../../../common/tables/ReportsTableWithPagination';
import {
  viewotifReport,
  downloadReport,
} from '../../../../containers/actions/common/reportsActions';
import { cmoMembersList, cmoMembersSource } from '../../../../containers/actions/pharmaCo/purchaseOrderActions';

const header = [
  {
    label: 'PO No',
    id: 'purchaseOrderNumber',
    align: 'left',
    span: 0.2,
    numeric: false,
  },
  {
    label: 'PO Date',
    id: 'PODate',
    align: 'left',
    span: 0.2,
    numeric: false,
  },
  {
    label: 'PO Line Item',
    id: 'purchaseOrderLineItem',
    align: 'left',
    span: 0.2,
    numeric: false,
  },
  {
    label: 'Product Code',
    id: 'productCode',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  {
    label: 'Product Name',
    id: 'productName',
    align: 'left',
    span: 0.1,
    numeric: true,
  },
  {
    label: 'Plant Code',
    id: 'plantCode',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  {
    label: 'Manufacturer',
    id: 'manufacturer',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  {
    label: 'UOM',
    id: 'UOM',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  {
    label: 'Order Qty',
    id: 'orderQuantity',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  {
    label: 'Receipt Qty',
    id: 'receiptQuantity',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  {
    label: 'Pending Qty',
    id: 'pendingQuantity',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  {
    label: 'Lead Time',
    id: 'leadTime',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  {
    label: 'Delivery Date',
    id: 'deliveryDate',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  {
    label: 'Committed Date',
    id: '',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  {
    label: '90% Completion Date',
    id: 'completionDate_90',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  {
    label: 'Dispatch Date',
    id: 'dispatchDate',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  {
    label: 'Receipt Date',
    id: 'receiptDate',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  {
    label: 'Site Manager',
    id: 'siteManagerResponsible',
    align: 'right',
    span: 0.1,
    numeric: true,
  },
  // {
  //   label: "Remarks",
  //   id: "remarks",
  //   align: "left",
  //   span: 0.2,
  //   numeric: false
  // },
  // {
  //   label: "AW/CDR REL DT ",
  //   id: "AW/CDR_REL_DT",
  //   align: "left",
  //   span: 0.2,
  //   numeric: false
  // },
  // {
  //   label: "Department",
  //   id: "department",
  //   align: "left",
  //   span: 0.2,
  //   numeric: false
  // },
  // {
  //   label: "Material Availability",
  //   id: "materialAvailability",
  //   align: "left",
  //   span: 0.2,
  //   numeric: false
  // }
];

class OTIFReport extends Component {
  state = {
    header: header,
    data: [],
    selectedDate: null,
    currentMonthNumber: null,
    currentYear: null,
    CMOMember: 'all',
    cmoMembersList: [],
    reportStartDate: null,
    reportEndDate: null,
    requestDownload: false,
    reportNumber: 0,
    rowsPerPage: 5,
    page: 0,
    showReport: false,
    reportError: null,
    errors: {
      monthError: false,
      startDateError: false,
      endDateError: false,
    },
  };

  componentDidMount() {
    if (this.props.collabType === 'Pharma Co') {
      const data = {
        ...this.props.match.params,
        collabType: 'Pharma Co',
      };
      this.props.cmoMembersList(data);
    }
  }
  componentWillUnmount() {
    if (cmoMembersSource) {
      cmoMembersSource.cancel('leaving page')
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    if (
      nextProps.purchaseOrder.cmoMembersList !==
      this.props.purchaseOrder.cmoMembersList
    ) {
      this.setState({
        cmoMembersList: nextProps.purchaseOrder.cmoMembersList,
      });
    }
    if (
      nextProps.otifReport !== this.props.otifReport &&
      nextProps.otifReport.status === true
    ) {
      this.setState({
        data: nextProps.otifReport.reportData,
        requestedBy: nextProps.otifReport.requestedBy,
        showReport: true,
      });
    }
    if (
      nextProps.otifReport !== this.props.otifReport &&
      nextProps.otifReport.status === false
    ) {
      this.setState({
        reportError: nextProps.otifReport.errorDescription,
        showReport: true,
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleDateChange = (date, name) => {
    if (name === 'selectedDate') {
      this.setState({
        selectedDate: new Date(date),
        currentYear: new Date(date).getFullYear(),
        currentMonthNumber: new Date(date).getMonth() + 1,
        reportStartDate: null,
        reportEndDate: null,
        errors: {
          monthError: false,
          startDateError: false,
          endDateError: false,
        },
      });
    } else if (name === 'reportStartDate') {
      this.setState({
        selectedDate: null,
        currentMonthNumber: null,
        currentYear: null,
        reportStartDate: new Date(date),
        errors: {
          monthError: false,
          startDateError: false,
          endDateError: false,
        },
      });
    } else if (name === 'reportEndDate') {
      this.setState({
        selectedDate: null,
        currentMonthNumber: null,
        currentYear: null,
        reportEndDate: new Date(date),
        errors: {
          monthError: false,
          startDateError: false,
          endDateError: false,
        },
      });
    }
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  validate = () => {
    let error = false;
    let errors = {
      monthError: false,
      startDateError: false,
      endDateError: false,
    };
    const { selectedDate, reportStartDate, reportEndDate } = this.state;
    if (
      selectedDate === null &&
      reportStartDate === null &&
      reportEndDate === null
    ) {
      error = true;
      errors.monthError = true;
    } else if (reportStartDate === null && reportEndDate !== null) {
      error = true;
      errors.startDateError = true;
    } else if (reportStartDate !== null && reportEndDate === null) {
      error = true;
      errors.endDateError = true;
    }
    this.setState({ errors });
    return error;
  };

  fetchReport = () => {
    let err = this.validate();
    if (!err) {
      const {
        currentMonthNumber,
        currentYear,
        reportStartDate,
        reportEndDate,
        reportNumber,
        CMOMember,
      } = this.state;
      let data = {
        currentMonthNumber,
        currentYear,
        reportStartDate,
        reportEndDate,
        reportNumber,
        collabType: this.props.collabType,
        CMOMember:
          this.props.collabType === 'CMO'
            ? this.props.match.params.organizationName
            : CMOMember,
        ...this.props.match.params,
      };

      this.props.viewotifReport(data);
      this.setState({ showReport: false });
    }
  };
  downloadReport = () => {
    let err = this.validate();
    if (!err) {
      const {
        reportNumber,
        currentMonthNumber,
        currentYear,
        reportStartDate,
        reportEndDate,
        CMOMember,
      } = this.state;
      let data = {
        reportNumber,
        currentMonthNumber,
        currentYear,
        reportStartDate,
        reportEndDate,
        collabType: this.props.collabType,
        CMOMember:
          this.props.collabType === 'CMO'
            ? this.props.match.params.organizationName
            : CMOMember,
        ...this.props.match.params,
      };
      this.props.downloadReport(data);
    }
  };

  render() {
    const {
      selectedDate,
      reportStartDate,
      reportEndDate,
      CMOMember,
      cmoMembersList,
      header,
      data,
      showReport,
      page,
      rowsPerPage,
      requestedBy,
      errors,
      reportError,
    } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD/MMM/YYYY';

    const tableBody =
      data.length > 0 ? (
        data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, i) => (
            <TableRow key={i}>
              <TableCell align='center'>{row.purchaseOrderNumber}</TableCell>
              <TableCell align='center'>
                {Moment(row.PODate).format(dateFormat)}
              </TableCell>
              <TableCell align='center'>{row.lineNumber}</TableCell>
              <TableCell align='right'>{row.productCode}</TableCell>
              <TableCell align='left'>{row.productName}</TableCell>
              <TableCell align='right'>{row.plantCode}</TableCell>
              <TableCell align='right'>{row.cmoMember}</TableCell>
              <TableCell align='right'>{row.unitOfMeasurement}</TableCell>
              <TableCell align='right'>{row.quantity}</TableCell>
              <TableCell align='right'>{row.producedQuantity}</TableCell>
              <TableCell align='right'>{row.pendingQuantity}</TableCell>
              <TableCell align='right'>{row.leadTime}</TableCell>
              <TableCell align='right'>
                {Moment(row.deliveryDate).format(dateFormat)}
              </TableCell>
              <TableCell align='right'>
                {row.poLineCommitDate !== '' && row.poLineCommitDate !== null
                  ? Moment(row.poLineCommitDate).format(dateFormat)
                  : null}
              </TableCell>
              <TableCell align='right'>
                {row.completionDate_90 !== '' && row.completionDate_90 !== null
                  ? Moment(row.completionDate_90).format(dateFormat)
                  : null}
              </TableCell>
              <TableCell align='right'>
                {row.dispatchDate !== '' && row.dispatchDate !== null
                  ? Moment(row.dispatchDate).format(dateFormat)
                  : null}
              </TableCell>
              <TableCell align='right'>
                {row.receiptDate !== '' && row.receiptDate !== null
                  ? Moment(row.receiptDate).format(dateFormat)
                  : null}
              </TableCell>
              <TableCell align='right'>{row.siteManager}</TableCell>
            </TableRow>
          ))
      ) : (
        <TableRow>
          <TableCell colSpan={header.length} align='center'>
            {reportError ? (
              <Typography variant='body2' color='error'>
                {reportError}
              </Typography>
            ) : (
              <Typography variant='body2'>No Records Found</Typography>
            )}
          </TableCell>
        </TableRow>
      );
    return (
      <div>
        <SelectionBar
          handleChange={this.handleChange}
          handleDateChange={this.handleDateChange}
          selectedDate={selectedDate}
          fetchReport={this.fetchReport}
          reportStartDate={reportStartDate}
          reportEndDate={reportEndDate}
          monthError={errors.monthError}
          startDateError={errors.startDateError}
          endDateError={errors.endDateError}
          collabType={this.props.collabType}
          CMOMember={CMOMember}
          cmoMembersList={cmoMembersList}
        />
        {showReport === true && (
          <ReportsTableWithPagination
            header={header}
            tableBody={tableBody}
            downloadReport={this.downloadReport}
            length={data.length}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            requestedBy={requestedBy}
            data={data}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  otifReport: state.pcmp.common.reports.otifReport,
  purchaseOrder: state.pcmp.pharmaCo.purchaseOrder,
});
export default connect(mapStateToProps, {
  viewotifReport,
  downloadReport,
  cmoMembersList,
})(OTIFReport);
