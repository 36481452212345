import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import {
  fetchLocationDetails,
  fetchFacilityDetails,
} from '../../../../../../../containers/actions/common/qualityEventActions';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
  },
  header: {
    width: '80%',
    padding: '1rem',
    textAlign: 'center',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    margin: '0 auto',
    fontWeight: 'bold',
    position: 'relative',
  },
  headTitle: {
    fontWeight: 700,
  },
  title: {
    textAlign: 'center',
    fontWeight: 500,
    marginTop: '3rem',
  },
  dialogPaper: {
    width: '40%',
    minHeight: '42vh',
    maxHeight: '42vh',
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '18px',
    right: '25px',
    cursor: 'pointer',
  },

  steeper: {
    width: '40%',
    margin: '0 auto',
    position: 'relative',
  },
  hrLine: {
    backgroundColor: 'grey',
    width: '2.5rem',
    height: '2px',
  },
  saveBtn: {
    paddingLeft: '2.5rem',
    paddingRight: '3rem',
    borderRadius: '2.5rem',
    textTransform: 'lowercase',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const FacilityAndLocation = (props) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const { openDialog, toggleDialog } = props;
  const [state, setState] = useState({
    errors: {},
  });

  const {
    CMOMember,
    locationOptions,
    selectedLocation,
    facilityOptions,
    selectedFacility,
  } = props.stateData;

  const handleOnChangeData = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: null },
    }));

    props.handleChangeData(name, value);

    if (name === 'selectedLocation' && value) {
      props.fetchFacilityDetails({ locationId: value.locationID });
    }
    if (name === 'selectedLocation') {
      props.handleChangeData('selectedFacility', '');
    }
  };

  const { collabType } = localStorage;
  let cmoMember =
    collabType === 'Pharma Co' ? CMOMember.organizationId : CMOMember;
  useEffect(() => {
    if (didMountRef.current) {
    } else {
      props.fetchLocationDetails({ organizationId: cmoMember });
      didMountRef.current = true;
    }
  }, []);

  const handleValidations = () => {
    const errorsData = {};
    let isError = false;

    if (selectedFacility === '' || selectedFacility === null) {
      errorsData.selectedFacility = 'Please select facility';
      isError = true;
    }
    if (selectedLocation === '' || selectedLocation === null) {
      errorsData.selectedLocation = 'Please select location';
      isError = true;
    }

    if (isError) {
      setState((prevState) => ({
        ...prevState,
        errors: errorsData,
      }));
    } else {
      props.toggleDialog('facilityAndLocationScreen');
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => toggleDialog('facilityAndLocationScreen')}
      TransitionComponent={Transition}
      fullWidth
      maxWidth='sm'
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.headerContainer}>
        <div
          className={classes.closeBtn}
          onClick={() => toggleDialog('facilityAndLocationScreen')}
        >
          <Typography
            variant='caption'
            style={{ lineHeight: 'normal', fontWeight: '700' }}
          >
            Cancel
          </Typography>
          <CloseIcon style={{ color: 'red' }} />
        </div>
      </div>
      <Box width='80%' margin='auto' marginTop='1rem'>
        <Box>
          <Typography variant='h5' className={classes.title}>
            Facility And Location Details
          </Typography>
          <hr className={classes.hrLine} />
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Box marginTop={2} width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Location <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              options={locationOptions}
              getOptionLabel={(option) => `${option.locationName || ''}`}
              getOptionSelected={(option) => `${option.locationName || ''}`}
              name='location'
              value={selectedLocation}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Start typing to select Location'
                  error={!!state.errors.selectedLocation}
                  helperText={state.errors.selectedLocation}
                />
              )}
              onChange={(event, value) => {
                handleOnChangeData('selectedLocation', value);
              }}
            />
          </Box>

          <Box marginTop={2} width='47%'>
            <Typography variant='body2' className={classes.inputLabel}>
              Facility <span className={classes.requiredStar}>*</span>
            </Typography>
            <Autocomplete
              size='small'
              fullWidth
              variant='outlined'
              name='facility'
              value={selectedFacility}
              options={facilityOptions}
              getOptionLabel={(option) => `${option.FacilityName || ''}`}
              getOptionSelected={(option) => `${option.FacilityName || ''}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  placeholder='Start typing to select Facility'
                  error={!!state.errors.selectedFacility}
                  helperText={state.errors.selectedFacility}
                />
              )}
              onChange={(name, value) => {
                handleOnChangeData('selectedFacility', value);
              }}
            />
          </Box>
        </Box>

        <Box
          width='70%'
          textAlign='center'
          marginTop={4}
          marginBottom='2rem'
          marginLeft={7}
        >
          <Button
            className={classes.saveBtn}
            variant='contained'
            color='primary'
            endIcon={<NavigateNextIcon />}
            onClick={handleValidations}
          >
            save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default connect(null, {
  fetchLocationDetails,
  fetchFacilityDetails,
})(FacilityAndLocation);
