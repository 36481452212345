import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  Grid,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  Button,
  Dialog
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import UploadPOFiles from "./UploadPOFiles";
import PreviewPurchaseOrder from "./previewPurchaseOrder";

import {
  cmoMembersList,
  getCMOtypes,
  previewPurchaseOrder,
  addPurchaseOrder
} from "../../../../containers/actions/pharmaCo/purchaseOrderActions";
import {
  purchaseOrdersData,
  purchaseOrderStats
} from "../../../../containers/actions/pharmaCo/dashboardActions";

class AddNewPurchaseOrder extends Component {
  state = {
    cmoMembersList: [],
    cmoTypesList: [],
    CMOMember: "",
    vendorCode: "",
    CMOType: "",
    purchaseOrderDescription: "",
    CMONotes: "",
    purchaseOrderCSV: "",
    purchaseOrderCSVFileName: "",
    previewScreen: false,
    purchaseOrderPdfFile: "",
    purchaseOrderPdfFileName: "",
    errors: {
      CMOMember: false,
      purchaseOrderDescription: false,
      purchaseOrderCSV: false,
      purchaseOrderPdf: false,
      vendorCode: false
    }
  };

  componentDidMount() {
    const data = {
      ...this.props.match.params,
      collabType: "Pharma Co"
    };
    this.props.cmoMembersList(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.purchaseOrder.cmoMembersList) {
      this.setState({ cmoMembersList: nextProps.purchaseOrder.cmoMembersList });
    }
    if (nextProps.purchaseOrder.cmoTypesList) {
      this.setState({ cmoTypesList: nextProps.purchaseOrder.cmoTypesList });
    }

    if (nextProps.purchaseOrder.newPo === true) {
      // Calling Purchase order data
      const data = {
        ...this.props.match.params,
        collabType: "Pharma Co"
      };
      // setTimeout(() =>
      this.props.callBackAPIFunction(data);
      // , 2000);

      // Closing all dialogs
      this.togglePreviewScreen();
      this.props.closeDialog();
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    if (name === "vendorCode") {
      const { cmoTypesList } = this.state;
      const type = cmoTypesList.filter(ele => ele.vendorCode === value);
      this.setState({
        [name]: value,
        CMOType: type[0].CMOType
      });
    } else if (name === "CMOMember") {
      this.setState({ [name]: value });
      let data = {
        CMOMember: value
      };
      this.props.getCMOtypes(data);
    } else {
      this.setState({ [name]: value });
    }
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: false
      }
    }));
  };

  handleUploadFile = event => {
    if (event.target.name === "purchaseOrderCSVFileName") {
      if (event.target.files) {
        let allowedExtensions = /(\.csv|\.xls|\.xlsx)$/i 
        let filePath = event.target.value; 
        if (!allowedExtensions.exec(filePath)) { 
          alert('Invalid file type'); 
          return false; 
      } 
        this.setState({ purchaseOrderCSVFileName: event.target.files[0].name });
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = e => {
          this.setState({ purchaseOrderCSV: e.target.result.split(",")[1] });
        };
        event.target.value = null;
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            purchaseOrderCSV: false
          }
        }));
      }
    }
    if (event.target.name === "purchaseOrderPdfFileName") {
      if (event.target.files[0]) {
        let allowedExtensions = /(\.pdf)$/i 
        let filePath = event.target.value; 
        if (!allowedExtensions.exec(filePath)) { 
          alert('Invalid file type'); 
          return false; 
      } 
        this.setState({
          purchaseOrderPdfFileName: event.target.files[0].name,
          purchaseOrderPdfFile: event.target.files[0]
        });
        event.target.value = null;
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            purchaseOrderPdf: false
          }
        }));
      } else {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            purchaseOrderPdf: true
          }
        }));
      }
    }
  };
  clearNotes = () => {
    this.setState({ CMONotes: "" });
  };

  removeFile = fileName => {
    if (fileName === "purchaseOrderCSVFileName") {
      this.setState({ purchaseOrderCSV: "", purchaseOrderCSVFileName: "" });
    }
    if (fileName === "purchaseOrderPdfFileName") {
      this.setState({ purchaseOrderPdf: "", purchaseOrderPdfFileName: "" });
    }
  };

  handleSubmit = () => {
    if (this.state.CMOMember === "") {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          CMOMember: true
        }
      }));
    } else if (this.state.vendorCode === "") {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          vendorCode: true
        }
      }));
    } else if (this.state.purchaseOrderDescription === "") {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          purchaseOrderDescription: true
        }
      }));
    } else if (
      this.state.purchaseOrderCSV === "" ||
      this.state.purchaseOrderCSVFileName === ""
    ) {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          purchaseOrderCSV: true
        }
      }));
    } else if (
      this.state.purchaseOrderPdfFileName === "" ||
      this.state.purchaseOrderPdfFile === ""
    ) {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          purchaseOrderPdf: true
        }
      }));
    } else {
      const data = {
        ...this.props.match.params,
        collabType: "Pharma Co",
        CMOMember: this.state.CMOMember,
        CMOType: this.state.CMOType,
        vendorCode: this.state.vendorCode,
        purchaseOrderDescription: this.state.purchaseOrderDescription,
        CMONotes: this.state.CMONotes,
        purchaseOrderCSV: this.state.purchaseOrderCSV,
        purchaseOrderCSVFileName: this.state.purchaseOrderCSVFileName,
        purchaseOrderPdfFileName: this.state.purchaseOrderPdfFileName
      };
      const formDataPO = new FormData();
      formDataPO.append(
        "purchaseOrderPdfFile",
        this.state.purchaseOrderPdfFile
      );
      formDataPO.append("POData", JSON.stringify(data));
      formDataPO.append("ecosystemId", this.props.match.params.ecosystemId);
      formDataPO.append("collabType", "Pharma Co");
      formDataPO.append("roleName", this.props.match.params.roleName);
      formDataPO.append(
        "organizationName",
        this.props.match.params.organizationName
      );
      this.props.previewPurchaseOrder(
        formDataPO,
        this.props.match.params.roleName
      );
      this.togglePreviewScreen();
    }
  };

  togglePreviewScreen = () => {
    this.setState({ previewScreen: !this.state.previewScreen });
  };

  addPurchaseOrder = () => {
    const data = {
      ...this.props.match.params,
      collabType: "Pharma Co",
      CMOMember: this.state.CMOMember,
      CMOType: this.state.CMOType,
      vendorCode: this.state.vendorCode,
      purchaseOrderDescription: this.state.purchaseOrderDescription,
      CMONotes: this.state.CMONotes,
      purchaseOrderCSV: this.state.purchaseOrderCSV,
      purchaseOrderCSVFileName: this.state.purchaseOrderCSVFileName,
      purchaseOrderPdfFileName: this.state.purchaseOrderPdfFileName
    };
    const addPO = new FormData();
    addPO.append("purchaseOrderPdfFile", this.state.purchaseOrderPdfFile);
    addPO.append("POData", JSON.stringify(data));
    addPO.append("ecosystemId", this.props.match.params.ecosystemId);
    addPO.append("collabType", "Pharma Co");
    addPO.append("roleName", this.props.match.params.roleName);
    addPO.append("organizationName", this.props.match.params.organizationName);
    this.props.addPurchaseOrder(addPO, this.props.match.params.roleName);
  };

  render() {
    const { closeDialog } = this.props;
    const {
      cmoMembersList,
      cmoTypesList,
      vendorCode,
      CMOMember,
      purchaseOrderDescription,
      purchaseOrderCSVFileName,
      purchaseOrderPdfFileName,
      previewScreen,
      errors
    } = this.state;
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: "3rem",
            marginTop: "1rem"
          }}
          onClick={closeDialog}
        >
          <Typography variant="body2" style={{ cursor: "pointer" }}>
            Cancel
          </Typography>
          <IconButton>
            <ClearIcon />
          </IconButton>
        </div>
        <Grid container style={{ justifyContent: "center" }}>
          <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
            <Typography variant="h5">Add New Purchase Order</Typography>
            <form autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={errors.CMOMember}
                    select
                    label="Select CMO Member"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    helperText="Select vendor name from the list."
                    name="CMOMember"
                    value={CMOMember}
                    onChange={this.handleChange}
                  >
                    {cmoMembersList.map(cmoMember => (
                      <MenuItem key={cmoMember} value={cmoMember}>
                        {cmoMember}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    label="Select CMO Type"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    error={errors.vendorCode}
                    helperText="Select vendor type."
                    name="vendorCode"
                    value={vendorCode}
                    onChange={this.handleChange}
                  >
                    {cmoTypesList.map(ele => (
                      <MenuItem key={ele.CMOType} value={ele.vendorCode}>
                        {ele.CMOType}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={errors.purchaseOrderDescription}
                    label="PO Description"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    helperText="Enter purchase order description."
                    name="purchaseOrderDescription"
                    value={purchaseOrderDescription}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>

              <UploadPOFiles
                handleUploadFile={this.handleUploadFile}
                purchaseOrderCSVFileName={purchaseOrderCSVFileName}
                purchaseOrderPdfFileName={purchaseOrderPdfFileName}
                removeFile={this.removeFile}
                errors={errors}
                handleChange={this.handleChange}
                CMONotes={this.state.CMONotes}
                clearNotes={this.clearNotes}
              />
              <Button
                variant="contained"
                style={{
                  marginTop: "1rem",
                  backgroundColor: "green",
                  borderRadius: "2rem",
                  marginBottom: "1rem",
                  color: "#ffffff"
                }}
                onClick={this.handleSubmit}
              >
                Create Purchase Order
              </Button>
            </form>
            <Dialog fullScreen open={previewScreen}>
              <PreviewPurchaseOrder
                togglePreviewScreen={this.togglePreviewScreen}
                addPurchaseOrder={this.addPurchaseOrder}
              />
            </Dialog>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  purchaseOrder: state.pcmp.pharmaCo.purchaseOrder
});

export default connect(mapStateToProps, {
  cmoMembersList,
  getCMOtypes,
  previewPurchaseOrder,
  addPurchaseOrder,
  purchaseOrdersData,
  purchaseOrderStats
})(withRouter(AddNewPurchaseOrder));
