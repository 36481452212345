import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import TableCell from '@material-ui/core/TableCell';
import { Grid, Typography } from '@material-ui/core';
//files
import TableWrapper from '../../wrappers/tableWrapper';
import CustomTableHeaderCell from '../../models/table/CustomTableHeaderCell';
import CustomTableCell from '../../models/table/CustomTableCell';
import {
  presentFormatedData,
  presentDate,
} from '../../functionalComponents/dataFormatFunctions';
import {
  // fetchBatchDetails,
  deliveryScheduleHeader,
  headerSource,
} from '../../../../containers/actions/common/saslAction';
import CustomDatePickerMui from '../../models/inputs/DateInput';
import axios from 'axios';
import { appUrl } from '../../../../../../utils/config';
// import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = (theme) => ({
  table: {
    overflow: 'hidden',
  },
  title: {
    paddingBottom: '0.5rem',
  },
});

const header = [
  {
    id: 'item',
    align: 'right',
    numeric: true,
    disablePadding: false,
    label: 'Item',
    varName: 'scheduleLineItemNumber',
  },

  {
    id: 'materialDesc',
    align: 'center',
    numeric: false,
    disablePadding: false,
    label: 'Material Code / Description',
    varName: 'fgMaterialCode',
  },

  {
    id: 'deliveryScheduleUOM',
    numeric: false,
    align: 'center',
    disablePadding: false,
    label: 'UOM',
    varName: 'deliveryScheduleUOM',
  },
  {
    id: 'scheduledQuantity',
    numeric: true,
    disablePadding: false,
    align: 'right',
    label: 'Scheduled Quantity',
    varName: 'scheduledQuantity',
  },
  // {
  //   id: 'GRQuantity',
  //   numeric: true,
  //   align: 'right',
  //   disablePadding: false,
  //   label: 'GR Quantity',
  //   varName: 'GRQuantity',
  // },
  {
    id: 'openTargetQuantity',
    numeric: true,
    align: 'right',
    disablePadding: false,
    label: 'Open Quantity',
    varName: 'openTargetQuantity',
  },
  {
    id: 'deliveryDate',
    numeric: false,
    disablePadding: false,
    label: 'Delivery Date',
    varName: 'deliveryDate',
  },
  {
    id: 'cmoCommittedDate',
    numeric: false,
    disablePadding: false,
    label: 'Committed Date',
    varName: 'cmoCommittedDate',
  },

  {
    id: 'batchCount',
    numeric: true,
    align: 'right',
    disablePadding: false,
    label: 'Total Batches',
    varName: 'batchCount',
  },
];

class TableDetails extends Component {
  state = {
    loading: false,
    dsData: {
      scheduleLineItemNumber: '',
      fgMaterialCode: '',
      fgMaterialName: '',
      deliveryScheduleUOM: '',
      scheduledQuantity: '',
      GRQuantity: '',
      openTargetQuantity: '',
      cmoCommittedDate: null,
      cmoCommittedQuantity: '',
      deliveryDate: '',
      batchCount: '',
    },
    dateError: '',
  };
  timer;

  componentDidMount() {
    let data = {
      schrockenDeliveryScheduleID: parseInt(
        this.props.schrockenDeliveryScheduleID
      ),
    };
    this.props.deliveryScheduleHeader(data);
    // setTimeout(() => { }, 1000); for testing
  }

  componentWillUnmount() {
    if (headerSource) {
      headerSource.cancel('table header api got cancelled ');
    }
    clearTimeout(this.timer);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.deliveryHeader !== prevProps.deliveryHeader &&
      this.props.deliveryHeader.status === true
    ) {
      this.setState({
        dsData: this.props.deliveryHeader.data,
        // loading: false,
      });

      let uomData = {
        deliveryScheduleUOM: this.props.deliveryHeader.data.deliveryScheduleUOM,
        uomQA: this.props.deliveryHeader.data.SKUUOM,
      };
      this.props.getUOM(uomData);
    }
  }

  clearError = () => {
    this.setState({
      dateError: '',
    });
  };
  handleDateChange = (date) => {
    if (date?.toISOString() !== this.state.dsData.cmoCommittedDate) {
      let prevValue = this.state.dsData.cmoCommittedDate;
      this.setState((prevState) => ({
        loading: true,
        dsData: {
          ...prevState.dsData,
          cmoCommittedDate: date.toISOString(),
          dateError: '',
        },
      }));
      // call Update API here.
      axios
        .post(
          `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/updateCommittedDate`,
          {
            schrockenDeliveryScheduleID: this.props.schrockenDeliveryScheduleID,
            cmoCommittedDate: date.toISOString(),
          }
        )
        .then((res) => {
          if (res.data.status) {
            this.setState({ loading: false });
          } else {
            this.setState((prevState) => ({
              loading: false,
              dsData: {
                ...prevState.dsData,
                cmoCommittedDate: prevValue,
              },
              dateError:
                res.data.errorDescription ||
                'Sorry, update failed. Please try later',
            }));

            this.timer = setTimeout(() => {
              this.clearError();
            }, 2000);
          }
        })
        .catch((err) => {
          console.log({ err });
          this.setState((prevState) => ({
            loading: false,
            dsData: {
              ...prevState.dsData,
              cmoCommittedDate: prevValue,
            },
            dateError:
              err.response.data.errorDescription ||
              'Sorry, update failed. Please try later',
          }));
          this.timer = setTimeout(() => {
            this.clearError();
          }, 2000);
        });
    }
  };
  render() {
    const { classes } = this.props;
    const { dsData } = this.state;

    return (
      <React.Fragment>
        <Grid container className={classes.title}>
          <Typography variant='h6' className={classes.title}>
            Delivery Schedule Details
          </Typography>
        </Grid>

        <TableWrapper>
          <TableContainer className={classes.paper}>
            <Table
              id='grnreceipts'
              className={classes.table}
              aria-label='collapsible table'
            >
              <TableHead>
                <TableRow>
                  {header.map((headCell) => (
                    <CustomTableHeaderCell
                      cellData={headCell}
                      key={headCell.id}
                      elementStyle={{
                        verticalAlign: 'top',
                        width: headCell.width ? headCell.width : 'auto',
                      }}
                    />
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow className={classes.root}>
                  <CustomTableCell align='right'>
                    {presentFormatedData(dsData.scheduleLineItemNumber)}
                  </CustomTableCell>

                  <CustomTableCell align='center'>
                    {presentFormatedData(dsData.fgMaterialCode)}
                    <br />
                    {presentFormatedData(dsData.fgMaterialName)}
                  </CustomTableCell>

                  <CustomTableCell align='center'>
                    {presentFormatedData(dsData.deliveryScheduleUOM)}
                  </CustomTableCell>
                  <CustomTableCell align='right'>
                    {presentFormatedData(dsData.scheduledQuantity)}
                  </CustomTableCell>
                  <CustomTableCell align='right'>
                    {presentFormatedData(
                      parseFloat(Number(dsData.openTargetQuantity).toFixed(3))
                    )}
                  </CustomTableCell>

                  <CustomTableCell align='center'>
                    {presentDate(dsData.deliveryDate, 'NA')}
                  </CustomTableCell>
                  <CustomTableCell align='center'>
                    {localStorage.collabType === 'CMO' ? (
                      <CustomDatePickerMui
                        name='cmoCommittedDate'
                        value={dsData.cmoCommittedDate}
                        onChange={(date) => this.handleDateChange(date)}
                        style={{ maxWidth: 200 }}
                        disabled={this.state.loading}
                        error={this.state.dateError || false}
                        helperText={this.state.dateError}
                        FormHelperTextProps={{
                          style: {
                            margin: 0,
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                          },
                        }}
                      />
                    ) : (
                      <React.Fragment>
                        {presentDate(dsData.cmoCommittedDate, 'NA')}
                      </React.Fragment>
                    )}
                  </CustomTableCell>

                  <CustomTableCell align='right'>
                    {presentFormatedData(dsData.batchCount)}
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </TableWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  deliveryHeader: state.pcmp.common.sasl.deliveryHeader,
});

export default connect(mapStateToProps, {
  deliveryScheduleHeader,
})(withStyles(useStyles)(TableDetails));
