import React, { Component } from "react";
import { connect } from "react-redux";

import { Grid, Typography, TextField, Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MenuItem from "@material-ui/core/MenuItem";

import UpdatePMAvailability from "./UpdatePMAvailability";
import { cmoFetchPMAvailability } from "../../../../../containers/actions/cmo/addRmPmActions";

class ViewAddedPM extends Component {
  state = {
    data: [],
    pmAvailabilityDetails: {},
    errors: {
      packagingMaterialName: "",
      units: "",
      requiredQuantity: "",
      shortage: "",
      statusCode: "",
      expectedDelivery: ""
    },
    editIndex: -1
  };

  componentDidMount() {
    let data = {
      ...this.props.params,
      collabType: "CMO"
    };
    this.props.cmoFetchPMAvailability(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pmAvailable) {
      this.setState({ data: nextProps.pmAvailable });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      rmAvailabilityDetails: {
        ...prevState.rmAvailabilityDetails,
        [name]: value
      },
      errors: {
        packagingMaterialName: "",
        units: "",
        requiredQuantity: "",
        shortage: "",
        statusCode: "",
        expectedDelivery: ""
      }
    }));
  };

  validate = () => {
    let isError = false;

    let { rmAvailabilityDetails } = this.state;

    let errors = {
      packagingMaterialName: "",
      units: "",
      requiredQuantity: "",
      shortage: "",

      statusCode: "",
      expectedDelivery: ""
    };

    if (rmAvailabilityDetails.packagingMaterialName === "") {
      isError = true;
      errors.packagingMaterialName = "Please Enter Material Name";
    } else if (rmAvailabilityDetails.units === "") {
      isError = true;
      errors.units = "Please Enter Units";
    } else if (rmAvailabilityDetails.requiredQuantity === "") {
      isError = true;
      errors.requiredQuantity = "Please Enter Required Quantity";
    } else if (rmAvailabilityDetails.shortage === "") {
      isError = true;
      errors.shortage = "Please Enter Shortage Quantity";
    } else if (rmAvailabilityDetails.statusCode === "") {
      isError = true;
      errors.status = "Please Select Status";
    } else if (rmAvailabilityDetails.expectedDelivery === "") {
      isError = true;
      errors.expectedDelivery = "Please Select Expected Delivery date";
    } else {
      this.setState({ alltrue: true });
    }
    this.setState({ errors });
    return isError;
  };

  onSubmit = () => {
    let error = this.validate();
    if (!error) {
      this.setState({ disableSaveButton: true });
    }
  };

  updatePM = (ele, index) => {
    this.setState({
      pmAvailabilityDetails: ele,
      editIndex: index
    });
  };

  closeUpdate = () => {
    this.setState({
      pmAvailabilityDetails: {},
      editIndex: -1
    });
  };

  render() {
    const { data } = this.state;
    let pmAvailable = null;

    if (data.length > 0) {
      pmAvailable = data.map((ele, i) => {
        const currentlyEditing = this.state.editIndex === i;
        return (
          <React.Fragment key={i}>
            {currentlyEditing ? (
              <UpdatePMAvailability
                pmAvailabilityDetails={this.state.pmAvailabilityDetails}
                closeUpdate={this.closeUpdate}
                params={this.props.params}
              />
            ) : (
              <Grid container spacing={1} key={i}>
                <Grid item xs={12} md={2}>
                  <TextField
                    label="Name"
                    name="packagingMaterialName"
                    disabled
                    fullWidth
                    value={ele.packagingMaterialName}
                    // onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <TextField
                    label="Batch Size"
                    name="units"
                    disabled
                    fullWidth
                    value={ele.units}
                    // onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <TextField
                    label="Required Quantity"
                    name="requiredQuantity"
                    fullWidth
                    value={ele.requiredQuantity}
                    // onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    error={
                      this.state.errors.requiredQuantity === "" ? false : true
                    }
                    helperText={this.state.errors.requiredQuantity}
                  />
                </Grid>

                <Grid item xs={12} md={1}>
                  <TextField
                    label="Shortage"
                    name="shortage"
                    fullWidth
                    value={ele.shortage}
                    // onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    error={this.state.errors.shortage === "" ? false : true}
                    helperText={this.state.errors.shortage}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <TextField
                    label="Status"
                    select
                    fullWidth
                    name="statusCode"
                    value={ele.statusCode}
                    // onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  >
                    <MenuItem value={ele.statusCode}>{ele.statusText}</MenuItem>
                    {/* <MenuItem value="open">Open</MenuItem>
                <MenuItem value="Acknowledge">Acknowledge</MenuItem> */}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={2}>
                  <TextField
                    label={
                      <Typography
                        variant="caption"
                        style={{ fontSize: "0.65rem" }}
                      >
                        Expected Days for Delivery
                      </Typography>
                    }
                    select
                    fullWidth
                    name="expectedDelivery"
                    value={ele.expectedDelivery}
                    // onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    error={
                      this.state.errors.expectedDelivery === "" ? false : true
                    }
                    helperText={this.state.errors.expectedDelivery}
                  >
                    <MenuItem value={ele.expectedDelivery}>
                      {ele.expectedDelivery}
                    </MenuItem>
                    {/* <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem> */}
                  </TextField>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.updatePM(ele, i)}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        );
      });
    } else {
      pmAvailable = (
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} md={8} style={{ textAlign: "center" }}>
            <Typography variant="h6">No Records Available.</Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} sm={12}>
        <Card
          style={{
            margin: "0 4rem 0 4rem"
          }}
        >
          <CardContent>{pmAvailable}</CardContent>
        </Card>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            alignItems: "center"
          }}
        >
          <Button
            variant="contained"
            // color="primary"
            style={{
              color: "#ffffff",
              backgroundColor: "green"
            }}
            onClick={this.props.togglePMScreen}
          >
            {/* Save All Changes */}
            Back to Line Item
          </Button>

          {/* <Typography
            variant="body1"
            onClick={this.props.togglePMScreen}
            style={{
              paddingLeft: "1rem",
              cursor: "pointer"
            }}
          >
            Cancel X
          </Typography> */}
        </Grid>
      </Grid>
    );
  }
}

const mapStatetoProps = state => ({
  pmAvailable: state.pcmp.cmo.rmpm.pmAvailable
});

export default connect(mapStatetoProps, { cmoFetchPMAvailability })(
  ViewAddedPM
);
