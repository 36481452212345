import React from "react";
import PropTypes from "prop-types";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles } from "@material-ui/core/styles";
import CustomTableCell from "./CustomTableCell";

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const CustomTableHeaderCell = (props) => {
  const {
    sortingFunction,
    cellData,
    orderBy,
    order,
    elementStyle,
    isSortEnable,
  } = props;
  const classes = useStyles();

  return (
    <CustomTableCell
      align={cellData.align}
      hideCell={cellData.enableOnlyOnLargeScreen}
      elementStyle={elementStyle}
    >
      {isSortEnable ? (
        <TableSortLabel
          active={orderBy === cellData.id}
          direction={orderBy === cellData.varName ? order : "asc"}
          onClick={sortingFunction(cellData.varName)}
        >
          {cellData.label}
          {orderBy === cellData.varName ? (
            <span className={classes.visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </span>
          ) : null}
        </TableSortLabel>
      ) : (
        cellData.label
      )}
    </CustomTableCell>
  );
};

CustomTableHeaderCell.propTypes = {
  cellData: PropTypes.object.isRequired,
  sortingFunction: PropTypes.func,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  elementStyle: PropTypes.object,
};

export default CustomTableHeaderCell;
