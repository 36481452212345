import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Grid,
  Button,
  Typography,
  Paper,
  Toolbar,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { updatePurchaseOrder } from '../../../../../containers/actions/pharmaCo/purchaseOrderActions';
import Moment from 'moment';

class LineCommitDate extends Component {
  state = {
    page: 0,
    rowsPerPage: 3,
    linesData: this.props.poLines,
    disableSaveButton: false,
    error: false,
  };

  handleDateChange = (date, name, index) => {
    let { linesData } = this.state;
    linesData[index].poLineCommitDate = date;
    this.setState({ linesData, error: false });
  };

  validate = () => {
    let error = false;
    let { linesData } = this.state;
    for (let i = 0; i < linesData.length; i++) {
      if (
        linesData[i].poLineCommitDate === null ||
        linesData[i].poLineCommitDate === ''
      ) {
        this.setState({ error: true });
        return true;
      }
    }
    return error;
  };

  onSubmit = () => {
    let isError = this.validate();
    if (!isError) {
      this.setState({ disableSaveButton: true });
      const data = {
        ...this.props.match.params,
        statusCode: 1,
        PORejectionComments: '',
        collabType: 'CMO',
        lineItems: this.state.linesData,
      };
      this.props.updatePurchaseOrder(data);
      this.props.cancelEdit();
    }
  };
  render() {
    const { linesData, error, disableSaveButton } = this.state;

    const dateFormat = localStorage.selectedDateFormat
      ? localStorage.selectedDateFormat
      : 'DD-MMM-YYYY';

    return (
      <React.Fragment>
        <Grid
          container
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#000000',
          }}
        >
          <DialogTitle
            id='poRejection'
            style={{
              color: '#ffffff',
            }}
          >
            {'Lines Commit Date'}
          </DialogTitle>
          <Typography
            variant='caption'
            style={{
              color: '#ffffff',
              textDecoration: 'underline',
              cursor: 'pointer',
              marginRight: '0.5rem',
            }}
            onClick={this.props.handleClose}
          >
            Cancel X
          </Typography>
        </Grid>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              {/* <Typography>Please Enter Commit Date for Each Line</Typography> */}
              <Paper
                style={{
                  // width: "100%",
                  marginTop: '1rem',
                  overflowX: 'auto',
                  border: '1px solid #E5E5E5',
                  boxShadow: 'none',
                }}
              >
                <Toolbar>
                  <Typography variant='body2'>
                    Please Enter Commit Date for Each Line
                  </Typography>
                </Toolbar>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ color: 'black' }}>
                        Line Number
                      </TableCell>
                      <TableCell style={{ color: 'black' }}>
                        Delivery Date
                      </TableCell>
                      <TableCell style={{ color: 'black' }}>
                        Commit Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {linesData.map((ele, i) => (
                      <TableRow key={`row-${i}`}>
                        <TableCell align='left'>{ele.lineNumber}</TableCell>
                        <TableCell align='left'>
                          {Moment(ele.deliveryDate).format(dateFormat)}
                        </TableCell>
                        <TableCell align='left'>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              autoOk
                              disableToolbar
                              variant='inline'
                              inputVariant='outlined'
                              // label="Commit Date"
                              format={dateFormat}
                              placeholder={dateFormat}
                              value={
                                ele.poLineCommitDate === ''
                                  ? null
                                  : ele.poLineCommitDate
                              }
                              minDate={ele.deliveryDate}
                              InputAdornmentProps={{ position: 'start' }}
                              onChange={(date) =>
                                this.handleDateChange(
                                  date,
                                  'poLineCommitDate',
                                  i
                                )
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={this.onSubmit}
                variant='contained'
                style={{
                  backgroundColor: disableSaveButton ? 'gray' : 'green',
                  color: '#ffffff',
                  borderRadius: '1.5rem',
                }}
                disabled={disableSaveButton}
              >
                Acknowledge &#38; Accept PO
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {error ? (
                <Typography variant='caption' color='error'>
                  Please Mention Commit Dates for All Line Items
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </DialogActions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { updatePurchaseOrder })(
  withRouter(LineCommitDate)
);
