import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const useStyles = makeStyles({
  table: {
    textAlign: 'center',
  },
  border: {
    border: '2px solid #C6C6C6',
  },
});

export default function EditTable(props) {
  const classes = useStyles();

  const dateFormat = localStorage.selectedDateFormat
    ? localStorage.selectedDateFormat
    : 'DD/MMM/YYYY';

  const { POLineRecordDetails } = props;

  return (
    <React.Fragment>
      <div className={classes.border}>
        <Typography variant='h6' className={classes.table}>
          {/* You are Editing the following Record */}
          {props.title}
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>PO/STO No.</TableCell>
                <TableCell>PO Date</TableCell>
                <TableCell>Material Type</TableCell>
                <TableCell>Material Code / Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component='th' scope='row'>
                  {POLineRecordDetails.supplierPurchaseOrderNumber}
                </TableCell>
                <TableCell>
                  {
                    POLineRecordDetails.supplierPODate === null ? ' -- ' : 
                    moment(POLineRecordDetails.supplierPODate).format(
                      dateFormat
                    )
                  }
                </TableCell>
                <TableCell>{POLineRecordDetails.materialType}</TableCell>
                <TableCell>{`${POLineRecordDetails.materialCode}/${POLineRecordDetails.materialName}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
  );
}
