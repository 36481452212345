import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import StyledDialog from '../../updateGrRecord/Dialog';

const RejectionRemarks = (props) => {
  const {
    open,
    onClose,
    textLabel,
    title,
    subtitle,
    helperText,
    disabled,
    onTextChange,
    error,
    handleAddReason,
  } = props;
  return (
    <Dialog open={open} onClose={handleAddReason} fullWidth>
      <StyledDialog
        icon={true}
        handleClose={handleAddReason}
        onClickClose={onClose}
        title={title}
        subtitle={subtitle}
        isButtonDisabled={disabled}
      >
        <form noValidate autoComplete='off'>
          <TextField
            // id='outlined-multiline-static'
            label={textLabel}
            multiline
            error={error}
            helperText={helperText}
            rows={4}
            onChange={onTextChange}
            variant='outlined'
            style={{ width: '34.5rem', height: '10rem' }}
          />
        </form>
      </StyledDialog>
    </Dialog>
  );
};

RejectionRemarks.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
  handleAddReason: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  textLabel: PropTypes.string,
  subtitle: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export { RejectionRemarks };
