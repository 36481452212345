/*
 * Copyright - 2019 , Schrocken Inc
 * Author: Neetu
 * -----
 * File Description: CMO ALL Line Items Screen
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import queryString from 'query-string';

import { Grid, Typography } from '@material-ui/core';

import LineItemsTable from '../tables/LineTable';

import { getExecutiveDashboardLineItemsData } from '../../../../containers/actions/pharmaCo/dashboardActions';

class EcecutiveDashboardLineItems extends Component {
  state = {
    lineItems: false,
    title: 'Line Items',
    statusCode: null,
  };

  componentDidMount() {
    this.getLineItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.lineItems !== this.props.lineItems) {
      // Total Line Items
      if (nextProps.lineItems.overviewCardTotalLineItemDetails) {
        this.setState({
          lineItems: nextProps.lineItems.overviewCardTotalLineItemDetails,
          title: `All Line Items (${nextProps.lineItems.overviewCardTotalLineItemDetails.length})`,
        });
      }
      // Line Items - On Track
      if (nextProps.lineItems.overviewCardOnTrackDetails) {
        this.setState({
          lineItems: nextProps.lineItems.overviewCardOnTrackDetails,
          title: `Line Items Current Status - On Track (${nextProps.lineItems.overviewCardOnTrackDetails.length})`,
        });
      }
      // Line Items - At Risk
      if (nextProps.lineItems.overviewCardAtRiskDetails) {
        this.setState({
          lineItems: nextProps.lineItems.overviewCardAtRiskDetails,
          title: `Line Items Current Status - At Risk (${nextProps.lineItems.overviewCardAtRiskDetails.length})`,
        });
      }
      // Line Itmes - Status
      if (nextProps.lineItems.lineItemStatusDetails) {
        const { statusCode } = this.state;
        this.setState({
          lineItems: nextProps.lineItems.lineItemStatusDetails,
          title: `Line Items Current Status - ${
            statusCode === '0'
              ? 'Not Acknowledged'
              : statusCode === '20'
              ? 'RM/PM Available'
              : statusCode === '21'
              ? 'RM/PM Not Available'
              : statusCode === '6'
              ? 'Under Production'
              : statusCode === '7'
              ? 'Production Complete'
              : statusCode === '8'
              ? 'Work in Progress'
              : statusCode === '9'
              ? 'QC Complete'
              : statusCode === '10'
              ? 'Ready for Dispatch'
              : statusCode === '11'
              ? 'Dispatched'
              : statusCode === '12'
              ? 'Goods Received'
              : ''
          } (${nextProps.lineItems.lineItemStatusDetails.length})`,
        });
      }
    }
  }

  getLineItems = () => {
    const { organizationName, roleName } = this.props.match.params;
    const {
      startCommitDate,
      endCommitDate,
      api,
      statusCode,
    } = queryString.parse(this.props.location.search);
    const data = {
      organizationName: organizationName,
      startCommitDate: startCommitDate === 'null' ? null : startCommitDate,
      endCommitDate: endCommitDate === 'null' ? null : endCommitDate,
      cmoMember: 'All',
      roleName: roleName,
      collabType: 'Pharma Co',
      api: api,
      ...this.props.match.params,
    };
    if (statusCode) {
      data.statusCode = parseInt(statusCode);
      this.setState({ statusCode });
    }
    this.props.getExecutiveDashboardLineItemsData(data);
  };

  render() {
    return (
      <Grid>
        <div
          style={{
            // margin: "-1rem -1.3rem 0rem -1.8rem",
            margin: '-16px -16px 0 -16px',
            backgroundColor: '#F5F5F5',
            flexDirection: 'row',
            padding: '1.2rem 0.5rem',
          }}
        >
          <Typography variant='h6' style={{ marginLeft: '3rem' }}>
            {this.state.title}
          </Typography>
        </div>
        <LineItemsTable
          lineItems={this.state.lineItems}
          getLineItems={this.getLineItems}
          collabType='Pharma Co'
          onRefresh={this.getLineItems}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  lineItems: state.pcmp.pharmaCo.dashboard.execLineItems,
});

export default connect(mapStateToProps, {
  getExecutiveDashboardLineItemsData,
})(withRouter(EcecutiveDashboardLineItems));
