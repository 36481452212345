import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import InputAdornment from '@material-ui/core/InputAdornment';
import PublishIcon from '@material-ui/icons/Publish';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  font: {
    fontWeight: 500,
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  files: {
    border: '1px solid #707070',
    backgroundColor: '#F7F7F7',
    textAlign: 'center',
    padding: '2rem',
    cursor: 'pointer',
  },
  fileIcon: {
    cursor: 'pointer',
    textAlign: 'end',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  dropzone: {
    padding: '1.25rem',
  },
  mandatory: {
    color: 'red',
  },
}));

const DropFilesAttach = (props) => {
  const {
    title,
    handleUploadFiles,
    acceptedFileExtns,
    dropText,
    titleComponent,
    required,
    singleFile,
  } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      {titleComponent ? (
        titleComponent
      ) : title ? (
        <Typography variant='body2' className={classes.font}>
          {title}
          {required && <span className={classes.mandatory}>&nbsp;*</span>}
        </Typography>
      ) : null}
      <Dropzone
        onDrop={(files) => {
          handleUploadFiles(files);
        }}
        accept={
          acceptedFileExtns ||
          '.csv,.xls,.xlsx,.pdf,.jpeg,.png,.jpg,.bmp,.doc,.docx'
        }
        multiple={!singleFile}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: 'dropzone',
              onDrop: (event) => event.preventDefault(),
            })}
            className={classes.files}
          >
            <input {...getInputProps()} />
            <Typography variant='body2' style={{ cursor: 'pointer' }}>
              {dropText || 'Drag & Drop file or click here to Upload'}
            </Typography>
            <InputAdornment position='end' className={classes.fileIcon}>
              <PublishIcon fontSize='large' />
            </InputAdornment>
          </div>
        )}
      </Dropzone>
    </React.Fragment>
  );
};

DropFilesAttach.propTypes = {
  title: PropTypes.string,
  titleComponent: PropTypes.element,
  handleUploadFiles: PropTypes.func.isRequired,
  acceptedFileExtns: PropTypes.string,
  dropText: PropTypes.string,
  required: PropTypes.bool,
  singleFile: PropTypes.bool,
};

export default DropFilesAttach;
