import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogContent } from '@material-ui/core';

import { useStyles } from './qualityEventStyles';
// import AddBOEvent from './raiseQualityEvent/addEvent_BO';
import CMOFilter from './CMOFilter';
import EventFilter from './EventFilter';
import StyledButton from '../../../../common/models/Button';
import usePrevious from '../../../helpers/previousProps';
//import usePrevious from '../../../../../helpers/previousProps';

const SelectEventType = (props) => {
  const { open, onClose } = props;

  const classes = useStyles();
  const { collabType, organizationName } = localStorage;
  const [CMOMember, selectCMO] = useState(
    collabType === 'Pharma Co' ? 'all' : organizationName
  );
  const [addBOEvent, setAddBOEvent] = useState(false);
  const [qualityEvent, selectQualityEvent] = useState('all');
  const [qualityEventName, setQualityEventName] = useState('All Events');
  const [offset, setOffset] = useState(0);
  const [buttonEnable, setButtonEnable] = useState(false);

  const isClosed = (data) => {
    setAddBOEvent(data);
  };

  const handleSelectCMO = (event) => {
    selectCMO(event.target.value);
    if (qualityEvent !== 'all') {
      setButtonEnable(true);
    }
    setOffset(0);
  };

  const handleSelectEvent = (event) => {
    console.log(event.target.value);
    selectQualityEvent(event.target.value);
    // setQualityEventName(
    //   event.target.value !== "all"
    //     ? event.target.name.find(
    //         (data) => data.event_type_id === event.target.value
    //       ).event_type
    //     : "All Events"
    // );
    if (CMOMember !== 'all') {
      setButtonEnable(true);
    }
    setOffset(0);
  };

  const handleCloseAll = () => {
    setAddBOEvent(false);
    onClose();
  };
  let toShowAllEvents = true;

  const { addQualityEventResponse } = props;
  const prevAddQualityEventResponse = usePrevious(addQualityEventResponse);
  useEffect(() => {
    if (prevAddQualityEventResponse !== addQualityEventResponse) {
      //setAddBOEvent(false);
      if (addQualityEventResponse.status === true) {
        //setFeedbackDialog(true);
        //setEventID(addQualityEventResponse.eventID);
        handleCloseAll();
      }
    }
  }, [addQualityEventResponse, prevAddQualityEventResponse]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent>
        <Grid container>
          <Grid
            item
            xs={11}
            sm={collabType === 'CMO' ? 11 : 11}
            md={collabType === 'CMO' ? 11 : 11}
          ></Grid>

          <Grid
            item
            xs={1}
            sm={collabType === 'CMO' ? 1 : 1}
            md={collabType === 'CMO' ? 1 : 1}
            className={classes.grid}
          >
            <Typography
              variant='body2'
              className={classes.closeText}
              onClick={onClose}
            >
              Close <CloseIcon fontSize='large' />
            </Typography>
          </Grid>

          <Grid
            container
            justifyContent='center'
            style={{ paddingTop: '2rem' }}
          >
            <Typography
              variant='h5'
              style={{ fontWeight: 500, padding: '0.5rem' }}
            >
              Create New Event
            </Typography>
          </Grid>

          <Grid
            container
            justifyContent='center'
            style={{ paddingTop: '3rem' }}
            spacing={3}
          >
            {collabType === 'Pharma Co' && (
              <Grid item sx={12} sm={5}>
                <Typography variant='h6' className={classes.hgText}>
                  Select CMO:
                </Typography>
                {/*<p>CMO box here</p>*/}
                <CMOFilter
                  toShowAllEvents={toShowAllEvents}
                  handleSelectCMO={handleSelectCMO}
                  CMOMember={CMOMember}
                />
              </Grid>
            )}

            <Grid item sx={12} sm={6}>
              <Typography variant='h6' className={classes.hgText}>
                Select Type and Quality Event
              </Typography>
              <EventFilter
                toShowAllEvents={toShowAllEvents}
                qualityEvent={qualityEvent}
                handleSelectEvent={handleSelectEvent}
                newEventScreen={true}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            sx={12}
            sm={12}
            justifyContent='center'
            style={{ paddingTop: '4rem' }}
          >
            <StyledButton
              style={{
                paddingLeft: '4rem',
                paddingRight: '4rem',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
              }}
              disabled={!buttonEnable}
              onClick={() => {
                // onClose();
                setAddBOEvent(true);
              }}
            >
              Next
            </StyledButton>
          </Grid>
        </Grid>
      </DialogContent>
      {/**Raise Quanlity event Dialog */}
      {/* <AddBOEvent
        open={addBOEvent}
        onClose={handleCloseAll}
        qualityEvent={qualityEvent}
        CMOMember={CMOMember}
        qualityEventName={qualityEventName}
      /> */}
    </Dialog>
  );
};

export default SelectEventType;
