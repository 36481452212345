import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { downloadExcelFile } from '../../../containers/actions/common/fileActions';
import clsx from 'classnames';
import PaginationInput from '../models/pagination/PaginationInput';
//files
import SupplierPOsTable from './SupplierPOsTable';
import RowsPerPage from '../models/inputs/RowsPerPage';

const useStyles = makeStyles((theme) => ({
  hgText: {
    fontWeight: 500,
    paddingRight: theme.spacing(1),
  },
  outlined: {
    padding: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    fontSize: '0.875rem',
  },
  formPerPage: {
    minWidth: 60,
    fontSize: '0.875rem',
  },
  pdTp: {
    paddingTop: '1.5rem',
  },
  searchFld: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  flxEd: {
    justifyContent: 'flex-end',
  },
}));

const SupplierPO = (props) => {
  const [noRowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const classes = useStyles();
  const [action, setAction] = useState('nil');
  const [selectedBatchID, setSelectedBatchID] = useState([]);
  const [errors, setErrors] = useState({});
  const {
    supplierPOs,
    status,
    searchQuery,
    getLimit,
    orderBy,
    orderByField,
    newTotalRecord,
    CMOMember,
    getCurrentPageNumber,
    offset,
  } = props;
  const dispatch = useDispatch();

  React.useEffect(() => {
    getLimit(noRowsPerPage);
    if (
      (props.status || searchQuery || CMOMember || orderBy || orderByField) &&
      offset === 0
    ) {
      setCurrentPage(1);
    }
  }, [
    props.status,
    searchQuery,
    noRowsPerPage,
    getLimit,
    CMOMember,
    offset,
    orderBy,
    orderByField,
  ]);
  let totalPages = Math.ceil(newTotalRecord / noRowsPerPage);
  const getSelectedBatchId = (data) => {
    setSelectedBatchID(data);
    setErrors({});
  };

  const handleSetRows = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1);
    getCurrentPageNumber(1);
    setErrors({});
  };

  const handlePageChange = (data) => {
    setCurrentPage(data);
    getCurrentPageNumber(data);
  };

  const handleAction = (event) => {
    setAction(event.target.value);
    if (event.target.value !== 'download') {
      setErrors({});
    }
  };

  const sendExcelData = () => {
    if (selectedBatchID.length === 0) {
      setErrors({ fileErr: 'Please select some files to download' });
    } else {
      setErrors({});
      const data = {
        schrockenSupplierPOBatchID: selectedBatchID,
      };
      dispatch(downloadExcelFile(data));
    }
  };

  const getOrderAndColumn = (data, dataBy) => {
    props.getOrderAndColumnFinal(data, dataBy);
  };

  let showPagination = supplierPOs
    ? supplierPOs.length > 0
      ? true
      : false
    : false;

  return (
    <React.Fragment>
      <Grid container alignItems='center' className={classes.pdTp}>
        <Grid item xs={8} sm={6} className={classes.searchFld}>
          <Typography variant='body2' className={classes.hgText}>
            SELECT ACTION
          </Typography>
          <FormControl className={classes.formControl}>
            <Select
              labelId='cmo-filter'
              id='cmo-filter-id'
              value={action}
              onChange={handleAction}
              variant='outlined'
              classes={{
                outlined: classes.outlined,
              }}
            >
              <MenuItem value={'nil'}>-select option-</MenuItem>
              <MenuItem value='download' onClick={sendExcelData}>
                Download selected
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={4}
          sm={6}
          className={clsx(classes.searchFld, classes.flxEd)}
        >
          <RowsPerPage value={noRowsPerPage} onChange={handleSetRows} />
        </Grid>

        <Grid container>
          {errors.fileErr && (
            <Grid item xs={12} sm={10} md={9}>
              <br />
              <Typography
                variant='body1'
                style={{
                  color: 'red',
                }}
              >
                {errors.fileErr}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container alignItems='center' className={classes.pdTp}>
        <SupplierPOsTable
          supplierPOs={supplierPOs}
          loading={props.loading}
          getSelectedBatchId={getSelectedBatchId}
          status={status}
          getOrderAndColumn={getOrderAndColumn}
        />
      </Grid>
      {showPagination === true && (
        <PaginationInput
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          page={currentPage}
        />
      )}
    </React.Fragment>
  );
};

export default SupplierPO;
