import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import GRNIndex from './index';

import { getFeaturesAccess } from '../../../containers/actions/common/accessControl';

class GrnAccess extends Component {
  state = { isLoading: true };
  componentDidMount() {
    if (this.props.grnFeatures) {
      this.setState({ isLoading: false });
    } else {
      this.props.getFeaturesAccess(
        this.props.grnReceiptsScreen.screenId,
        'grnFeatures'
      );
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.grnFeatures &&
      this.props.grnFeatures !== prevProps.grnFeatures
    ) {
      this.setState({ isLoading: false });
    }
  }
  render() {
    if (this.state.isLoading === true) {
      return (
        <div style={{ textAlign: 'center', marginTop: '25%' }}>
          <CircularProgress color='primary' thickness={7} />
        </div>
      );
    } else {
      return <GRNIndex />;
    }
  }
}

const mapStateToProps = (state) => ({
  grnReceiptsScreen:
    state.pcmp.common.accessControl.screensAccess.data.grnReceipts,
  grnFeatures: state.pcmp.common.accessControl.featuresAccess.grnFeatures,
});

export default connect(mapStateToProps, { getFeaturesAccess })(GrnAccess);