import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const POLineSelector = (props) => {
  const {
    purchaseOrderLineNumber,
    handleChange,
    open,
    handleOpen,
    handleClose,
    lineSelectDisabled,
    lineNumbers,
    lineError,
  } = props;
  return (
    <FormControl
      fullWidth={props.needFullWidth ? props.needFullWidth : false}
      // style={{ width: '9rem', marginRight: '2rem' }}
    >
      <InputLabel id='select-line'>Select Line</InputLabel>

      <Select
        labelId='select-line'
        variant='standard'
        name='purchaseOrderLineNumber'
        value={purchaseOrderLineNumber}
        placeholder='Select Line'
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        disabled={lineSelectDisabled}
        error={lineError}
      >
        {lineNumbers.map((ele) => (
          <MenuItem key={ele.schrockenPOLineID} value={ele.schrockenPOLineID}>
            {ele.lineNumber}
          </MenuItem>
        ))}
      </Select>
      {lineError && <FormHelperText error> Select Line</FormHelperText>}
    </FormControl>
  );
};

export default POLineSelector;
