import React from 'react';
import TextField from '@material-ui/core/TextField';
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const POSelector = (props) => {
  const { purchaseOrderNumber, handleChange, poError } = props;
  return (
    <TextField
      // style={{ marginRight: '3rem', marginLeft: '1.3rem' }}
      label='PO Number'
      variant='standard'
      name='purchaseOrderNumber'
      value={purchaseOrderNumber}
      placeholder='Enter PO Number'
      onChange={handleChange}
      fullWidth={props.needFullWidth ? props.needFullWidth : false}
      // disabled
      // InputProps={{
      //   endAdornment: <ArrowForwardIcon />
      // }}
      error={poError}
      helperText={poError === true ? 'Enter valid PO Number' : ''}
    />
  );
};

export default POSelector;
