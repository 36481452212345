import React from 'react';
// import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Dialog } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from './styles';
import Button from '@material-ui/core/Button';
//files
import Header from '../models/Header';
import AddBatch from './AddBatchSASL';

function UploadExport(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const setDialogOpen = () => {
    setOpen(!open);
  };

  return (
    <Grid item>
      <Grid container className={classes.flxEnd}>
        {localStorage.collabType === 'CMO' && (
          <Button
            startIcon={<AddIcon />}
            onClick={() => setDialogOpen(!open)}
            className={classes.upload}
          >
            Add new Batch
          </Button>
        )}
      </Grid>

      <Dialog open={open} fullScreen>
        {' '}
        {/*//onOpen={setDialogOpen}*/}
        <Header title='Add New Batch' handleClose={setDialogOpen} />
        <AddBatch
          handleClose={setDialogOpen}
          deliveryScheduleUOM={props.deliveryScheduleUOM}
          uomQA={props.uomQA}
        />
      </Dialog>
    </Grid>
  );
}

export default UploadExport;
