import axios from 'axios';
import { appUrl } from '../../../../../utils/config';

import {
  PO_STATS,
  CMO_STATS,
  SHIPMENT_STATS,
  PO_DATA,
  EXEC_CARDS,
  EXEC_LINES_OVERVIEW,
  EXEC_LINES_CMO,
  EXEC_LINE_ITEMS,
  EXEC_BATCH_ITEMS,
  ON_TRACK_CMO,
} from '../types';

export const purchaseOrderStats = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/purchaseOrderCount`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PO_STATS,
          payload: res.data.purchaseOrderStatusesCount,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const cmoOrderStats = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/CMOOrderStatus`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: CMO_STATS,
          payload: res.data.CMOOrderStatusesCount,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const shipmentStats = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/fetchShipmentStatusCount`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: SHIPMENT_STATS,
          payload: res.data.shipmentStatusesCount,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const purchaseOrdersData = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/fetchAllPurchaseOrdersList`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PO_DATA,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export let overviewCardSource;
// R1.5 exec dashboard action
export const getOverviewCardsData = (data) => (dispatch) => {
  const CancelToken = axios.CancelToken;
  overviewCardSource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/overviewCards`,
      data, { cancelToken: overviewCardSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EXEC_CARDS,
          payload: res.data.overviewCards,
        });
      }
    })
    .catch((err) => console.log(err));
};
export let lineItemStatusOverviewSource;
export const getLineItemStatusOverview = (data) => (dispatch) => {

  const CancelToken = axios.CancelToken;
  lineItemStatusOverviewSource = CancelToken.source();
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/lineItemStatusOverview`,
      data, { cancelToken: lineItemStatusOverviewSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EXEC_LINES_OVERVIEW,
          payload: res.data.lineItemStatusOverview,
        });
      }
    })
    .catch((err) => console.log(err));
};

export let getCmoOrderInformationSource;

export const getCmoOrderInformation = (data) => (dispatch) => {
  const CancelToken = axios.CancelToken;
  getCmoOrderInformationSource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/cmoOrderInformation`,
      data, { cancelToken: getCmoOrderInformationSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EXEC_LINES_CMO,
          payload: res.data.cmoOrderInformation,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getExecutiveDashboardLineItemsData = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/${data.api}`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EXEC_LINE_ITEMS,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getExecutiveDashboardBatchItemsData = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/overviewCardTotalBatchDetails`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EXEC_BATCH_ITEMS,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getOnTrackData = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/Pharma Co/${data.roleName}/overviewCardOnTrackDetails`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ON_TRACK_CMO,
          payload: res.data.overviewCardOnTrackDetails,
        });
      }
    })
    .catch((err) => console.log(err));
};
