export const ViewConsumptionHeader = [
  {
    id: 'materialNumber',
    numeric: true,
    disablePadding: false,
    align: 'left',
    label: 'Material Number',
    varName: 'BomComponentCode',
  },
  {
    id: 'materialShortText',
    numeric: true,
    disablePadding: false,
    align: 'left',
    label: 'Material Short Text',
    varName: 'BomComponentName',
  },
  {
    id: 'UOM',
    numeric: true,
    disablePadding: false,
    align: 'left',
    label: 'UOM',
    varName: 'BomComponentRefUOM ',
  },
  {
    id: 'fgRefQuantity',
    numeric: true,
    disablePadding: false,
    align: 'right',
    label: 'Standard Quantity',
    varName: 'fgRefQuantity',
  },
  {
    id: 'fgStdQuantity',
    numeric: true,
    disablePadding: true,
    align: 'right',
    label: 'Standard Quantity (As per batch size)',
    varName: 'fgStdQuantity',
  },

  {
    id: 'BomComponentConsumedQuantity',
    numeric: true,
    disablePadding: false,
    align: 'right',
    label: 'Consumed Quantity',
    varName: 'BomComponentConsumedQuantity ',
  },

  {
    id: 'componentBatchNumber',
    numeric: true,
    disablePadding: false,
    align: 'center',
    label: 'Batch Number',
    varName: 'componentBatchNumber',
  },
];
