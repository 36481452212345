import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import StyledButton from '../../../../../../common/models/Button';
import { appUrl } from '../../../../../../../utils/config';
import {
  OPEN_BACKDROP,
  DESTROY_BACKDROP,
} from '../../../../../containers/actions/types';
import StyledDialog from '../../../../../../common/models/Dialog';

const GenerateBD = (props) => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('initial');
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const onGenerateSto = () => {
    setDisabled(true);
    dispatch({
      type: OPEN_BACKDROP,
      backdropText: 'Please Wait. Sending record for STO/OBD generation ....',
    });
    const { data } = props;
    axios
      .post(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/generateStoObd`,
        { data }
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: DESTROY_BACKDROP,
          });
          setOpen(true);
          setAction('approved');
        } else {
          dispatch({
            type: DESTROY_BACKDROP,
          });
          setDisabled(false);
          props.onError(res.data.errorDescription);
        }
      })
      .catch((err) => {
        console.log(err);
        setDisabled(false);
        dispatch({
          type: DESTROY_BACKDROP,
        });
        props.onError(err.response.data.errorDescription);
      });
  };
  const onApprove = () => {
    setOpen(false);
    if (action === 'initial') {
      onGenerateSto();
    } else {
      props.onSuccess();
    }
  };
  const onClose = () => {
    setOpen(false);
    if (action === 'approved') {
      props.onSuccess();
    }
  };
  return (
    <React.Fragment>
      <StyledButton
        onClick={() => setOpen(true)}
        disabled={disabled}
        color='primary'
      >
        {props.customBtnText || 'Generate STO/OBD'}
      </StyledButton>

      <Dialog open={open} fullWidth>
        <StyledDialog
          icons={true}
          handleClose={onApprove}
          title={
            action === 'initial'
              ? 'Approving for STO/OBD'
              : 'Your record has been added in queue for STO/OBD generation'
          }
          subtitle={
            action === 'initial' ? 'Confirm approval of STO/OBD.' : null
          }
          hasMultipleButton={action === 'initial' ? true : false}
          handleNoButton={onClose}
        />
      </Dialog>
    </React.Fragment>
  );
};

GenerateBD.propTypes = {
  data: PropTypes.array.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  customBtnText: PropTypes.string,
};

export default GenerateBD;
