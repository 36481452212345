import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DateRangeSelector from '../../common/selectors/DateRangeSelector';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const SelectionBar = (props) => {
  const {
    allLineItems,
    selectRange,
    startDate,
    endDate,
    handleCheck,
    fetchExecDashboardData,
  } = props;
  return (
    <div
      style={{
        // margin: "0rem -1.3rem 0rem -1.8rem",
        marginRight: -16,
        marginLeft: -16,
        backgroundColor: '#F0F0F0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: ' 0.5rem',
        paddingBottom: '0.5rem',
        overflow: 'hidden',
      }}
    >
      <FormGroup
        row
        style={{
          alignItems: 'center',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={allLineItems}
              onChange={handleCheck('allLineItems')}
              value='allLineItems'
              color='primary'
              size='small'
            />
          }
          label='All Line Items'
          style={{
            marginRight: 8,
          }}
        />
        <Typography
          style={{
            marginRight: '0.5rem',
          }}
        >
          (OR)
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectRange}
              onChange={handleCheck('selectRange')}
              value='selectRange'
              color='primary'
              size='small'
            />
          }
          label='Select Date Range'
        />
      </FormGroup>
      <div
        style={{
          display: 'inline',
        }}
      >
        <DateRangeSelector
          handleDateChange={props.handleDateChange}
          reportStartDate={startDate}
          reportEndDate={endDate}
          startDateError={props.startDateError}
          endDateError={props.endDateError}
        />

        <Button
          variant='contained'
          color='secondary'
          onClick={fetchExecDashboardData}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default SelectionBar;
