import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../common/models/Button';
import Avatar from '@material-ui/core/Avatar';
import { smlUrl } from '../../utils/config';
import axios from 'axios';
import { setAuthToken } from '../../utils/setAuthToken';
// import querystring from 'query-string';
import { LinearProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { SCREENS_ACCESS } from '../pcmp/containers/actions/types';

const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: 'center',
    paddingRight: '10rem',
    paddingLeft: '10rem',
    paddingTop: '1rem',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: '0.3rem',
  },
  text: {
    fontWeight: '500',
  },
  root: {
    minWidth: 275,
    padding: '1rem',
  },
  textfield: {
    marginLeft: '8rem',
  },
  btns: {
    textAlign: 'center',
  },
  helperTextFontSize: {
    fontSize: '0.65rem',
  },
}));

const MultiDashboardCards = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [ecosystem, setEcosystems] = useState([]);
  const [disableAction, setDisableAction] = useState(false);
  const [loading, setLoading] = useState(true);
  const { search } = location;
  // const returnPage = querystring.parse(search).return_url;
  const returnPage = search ? search.substring(search.indexOf('/pcmp')) : search;
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`${smlUrl}/sml/getUserEcosystems`)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setEcosystems(res.data.userEcosystems);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // User selects an ecosystem to switch
  const switchEcosystem = (ecosystemId) => {
    // check if the selction is previous ecosystem
    if (ecosystemId === parseInt(localStorage.ecosystemId)) {
      history.push(returnPage || '/pcmp/mosymphony/useraccess');
    } else {
      setDisableAction(true);
      dispatch({
        type: SCREENS_ACCESS,
        payload: {},
      });
      axios
        .post(`${smlUrl}/user/switchEcosystem`, { ecosystemId })
        .then((res) => {
          if (res.data.status) {
            //replace the auth token in Axios Headers
            setAuthToken(res.data.data.authToken);
            //store the particular ecosystem details
            localStorage.setItem('authToken', res.data.data.authToken);
            localStorage.setItem(
              'collabType',
              res.data.data.collaboratorTypeName
            );
            localStorage.setItem('roleName', res.data.data.roleName);
            localStorage.setItem('ecosystemId', ecosystemId);
            setDisableAction(false);
            //   if(returnPage){
            //   history.push(`/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${ecosystemId}/${returnPage.split('/')[6]}`);
            // }else{
            /*history.push(
              `/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${ecosystemId}/p2p`
            );*/
            history.push('/pcmp/mosymphony/useraccess');
          }
          // }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <Grid container>
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        style={{
          backgroundColor: '#F2F2F2',
          // width: '100%',
          // height: '100%',
          // position: 'fixed',
        }}
      >
        <Card className={classes.root}>
          {(disableAction || loading) && <LinearProgress color='secondary' />}
          <CardContent>
            <Grid item xs={12} className={classes.header}>
              <Typography variant='h4' className={classes.text}>
                Select Your Ecosystem
              </Typography>
            </Grid>
            <br />
            <br />
            <Grid item>
              {ecosystem.map((ecosystem, index) => (
                <Grid
                  container
                  key={`Ecosystem-${index}`}
                  style={{ padding: '1rem' }}
                >
                  <Grid item xs={2} style={{ textAlign: '-webkit-center' }}>
                    <Avatar
                      className={classes.large}
                      src={ecosystem.img || '../img'}
                      alt={ecosystem.ecosystem_name[0]}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ padding: '0.6rem' }}>
                    <Typography style={{ fontWeight: 500 }}>
                      {ecosystem.ecosystem_name}
                    </Typography>
                    <Typography variant='body2'>
                      <span>Type:</span> {ecosystem.collaborator_type_name}
                    </Typography>
                    <Typography variant='body2'> </Typography>
                    <Typography variant='body2'>
                      <span>Role:</span> {ecosystem.role_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ padding: '0.8rem' }}>
                    <StyledButton
                      variant='contained'
                      color='primary'
                      size='medium'
                      onClick={() => switchEcosystem(ecosystem.ecosystem_id)}
                      disabled={disableAction}
                    >
                      View Ecosystem
                    </StyledButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MultiDashboardCards;
